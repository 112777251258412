import React from "react";

const FormButton = ({
  disabled,
  className,
  label,
  onClick,
  icon,
  logout,
}: {
  disabled: boolean;
  className: string;
  label: any;
  onClick?: () => void;
  icon?: string;
  logout?: boolean;
}) => {
  const defaultColor = logout
    ? "disabled:bg-disable bg-red hover:bg-red-alphaC0"
    : "disabled:bg-disable bg-agitalks hover:bg-agitalks-alphaC0";

  return (
    <div className={className}>
      <button
        type="submit"
        disabled={disabled}
        onClick={onClick}
        className={`disabled:cursor-default disabled:text-black-alpha55 text-md rounded-[20px] border-none text-white inline-flex justify-center items-center w-full h-[36px] hover:cursor-pointer hover:text-white ${defaultColor}`}
      >
        {icon ? <i className={icon} /> : null}
        {label}
      </button>
    </div>
  );
};

FormButton.defaultProps = {
  onClick: undefined,
  icon: undefined,
  logout: false,
};

export default FormButton;
