import {
  IOfflinemessage,
  INewOfflinemessage,
  IOfflinemessageType,
} from "../slices/offlinemessages.slice";
import instances from "./instances";

const offlinemessage = async ({ _id }: { _id: string }) => {
  try {
    const response = await instances.instanceOfflinemessages.get(`/${_id}`);
    if (response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const register = async ({
  offlinemessage: _offlinemessage,
  offlinecontent,
  user,
  message,
  team,
}: {
  offlinemessage: string;
  offlinecontent: IOfflinemessageType[];
  user: string;
  message: string;
  team: string;
}) => {
  try {
    const data = {
      offlinemessage: _offlinemessage,
      offlinecontent,
      user,
      message,
      team,
    };
    const response = await instances.instanceOfflinemessages.post(
      "/message",
      data
    );
    if (response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const update = async ({
  _offlinemessage,
}: {
  _offlinemessage: IOfflinemessage;
}) => {
  try {
    const data = _offlinemessage;
    const response = await instances.instanceOfflinemessages.patch(
      `${_offlinemessage._id}`,
      data
    );
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const deleteOfflinemessages = async ({ ids }: { ids: string[] }) => {
  try {
    const data = { id: ids };
    const response = await instances.instanceOfflinemessages.delete("", {
      data,
    });
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const create = async (data: INewOfflinemessage) => {
  try {
    const response = await instances.instanceOfflinemessages.post("", data);
    if (
      response &&
      response.data &&
      typeof response?.data !== "undefined" &&
      [200, 201].includes(response.status)
    ) {
      return response.data;
    }
    return response.data.message;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const search = async ({
  skip,
  limit,
  filter,
  deleted,
  allStatus,
}: {
  skip?: number;
  limit?: number;
  filter: string;
  deleted: boolean;
  allStatus?: boolean;
}) => {
  try {
    const _filters =
      typeof filter === "object" ? JSON.parse(JSON.stringify(filter)) : {};

    if (typeof filter === "string" && typeof _filters?.content === "undefined")
      _filters.content = filter;
    if (allStatus) _filters.active = false;
    if (deleted) _filters.deleted = true;

    const response = await instances.instanceOfflinemessages.get("", {
      params: {
        skip: skip || 0,
        limit: limit || 10,
        filter: _filters,
        // fields:
        //   "active,created_at,created_by,default,deleted,enablelink,name,offlinemessages,teams._id,teams.name,teams.active,tenant,updated_at,updated_by",
      },
    });
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const offlinemessagesService = {
  offlinemessage,
  register,
  update,
  deleteOfflinemessages,
  create,
  search,
};

export default offlinemessagesService;
