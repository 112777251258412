import React from "react";

const LoadingDots = ({
  className,
  large,
  medium,
  centerPage,
}: {
  className?: string;
  large?: boolean;
  medium?: boolean;
  centerPage?: boolean;
}) => {
  const defaultClass =
    "float-left my-0 mx-1 bg-agitalks rounded-full opacity-0 animate-dots-pulse";
  let size = "w-2 h-2";
  if (medium) {
    size = "w-6 h-6";
  }
  if (large) {
    size = "w-10 h-10";
  }

  return (
    <div
      className={
        centerPage
          ? "flex justify-center items-center absolute top-0 bottom-0 right-0 left-0 h-screen"
          : className
      }
    >
      <div
        className={`${defaultClass} ${size}`}
        style={{ animationDelay: "0s" }}
      />
      <div
        className={`${defaultClass} ${size}`}
        style={{ animationDelay: "0.2s" }}
      />
      <div
        className={`${defaultClass} ${size}`}
        style={{ animationDelay: "0.4s" }}
      />
    </div>
  );
};

LoadingDots.defaultProps = {
  large: false,
  medium: false,
  centerPage: false,
  className: "",
};

export default LoadingDots;
