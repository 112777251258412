/* eslint-disable no-case-declarations */
import { combineReducers, configureStore, Middleware } from "@reduxjs/toolkit";
import { io, Socket } from "socket.io-client";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import {
  IMessage,
  MessageType,
  SenderType,
  UpdateStatusPayload,
} from "../slices/messages.slice";
// import { ITeam, teamsChatsAssignment } from "../slices/teams.slice";
import { ITeam } from "../slices/teams.slice";
import {
  authReducer,
  chatsReducer,
  customersReducer,
  usersReducer,
  messagesReducer,
  teamsReducer,
  settingsReducer,
  externalappsReducer,
  dashboardReducer,
  surveyRatingsReducer,
  surveysReducer,
  campaignsReducer,
  campaignBatchesReducer,
  officehoursReducer,
  holidaysReducer,
  offlinemessagesReducer,
  preferencesReducer,
  tagsReducer,
  appkeyReducer,
  commenttemplatesReducer,
} from "../slices";
import { messagesService, teamsService, campaignsService } from "../services";
import { ITypingListener } from "../shared/models/interfaces/typinglistener.interface";
import {
  IChangeStatusUser,
  IChangeUser,
  IUser,
  UserStatus,
  UserType,
} from "../slices/users.slice";

import {
  ChatReselectPayload,
  ChatStatus,
  ChatType,
  IChat,
} from "../slices/chats.slice";
import notificationService from "../services/notification.service";
import stripTags from "../shared/utils/stripTags";
import removeActiveChat from "../shared/utils/reassignment/removeActiveChat";
import populateExternalAppToChat from "../shared/utils/populateExternalAppToChat";
import instances from "../services/instances";
import { IChatPreferences, IParsedThumb } from "../slices/preferences.slice";
import tenantAllowed from "../shared/utils/tenantAllowed";

const appMiddleware: Middleware = ({ dispatch, getState }) => {
  const socket: Socket = io(process.env.REACT_APP_SOCKET_URL, {
    withCredentials: true,
    // reconnectionAttempts: 100,
    closeOnBeforeunload: false,
    transports: ["websocket"],
  });

  let widgetType = UserType.NONE;

  // ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓ ON EVENTS ↓↓↓↓↓↓↓↓↓↓↓↓↓↓↓
  window.addEventListener("beforeunload", () => {
    if (
      getState().auth.user?._id &&
      getState().auth?.tenantID &&
      widgetType === UserType.NONE
    ) {
      const _updateAt = new Date();
      dispatch({
        type: "auth/reselectUser",
        payload: {
          ...getState().auth.user,
          updated_at: _updateAt.toISOString(),
          status: UserStatus.OFFLINE,
        },
      });
      socket.emit("updateStatus", {
        _id: getState().auth.user?._id,
        status: UserStatus.OFFLINE,
        updated_at: _updateAt.toISOString(),
        beforeunload: true,
        tenant: getState().auth?.tenantID,
      });
    }
  });

  window.addEventListener("message", async (event) => {
    if (typeof event.data === "string" && event.data.length > 0) {
      const widgetInfo = event.data.split("|");
      if (widgetInfo.length > 1) {
        if (
          widgetInfo[1] === UserType.AGENT ||
          widgetInfo[1] === UserType.CONTACT
        ) {
          widgetType = widgetInfo[1] || UserType.CONTACT;
        }
      }
    }
  });

  const getContentMessage = (msg: IMessage) => {
    let _msg = msg?.content || "";
    if (msg?.chat?.groupchat && typeof msg?.content !== "undefined") {
      // eslint-disable-next-line no-useless-escape
      const regex = /[^\[]+(?=\])/g;
      const matched = _msg.match(regex);

      if (typeof matched !== "undefined" && matched && matched.length > 0) {
        matched.forEach((i) => {
          const __user = msg?.chat?.groupsettings?.find((u) =>
            typeof u.user?._id !== "undefined"
              ? u.user?._id === i
              : (u.user as any) === i
          );
          if (
            typeof __user?.user?.name !== "undefined" &&
            typeof __user?.user?._id !== "undefined"
          ) {
            const _value =
              __user?.user?._id === getState()?.auth?.user?._id
                ? "você"
                : __user?.user?.name;
            _msg = _msg.replace(`[${i}]`, _value);
          }
        });
        const _startsCapital = _msg[3].toUpperCase();
        return _msg.replace(_msg[3], _startsCapital);
      }
      return _msg || "";
    }
    return _msg || "";
  };

  socket?.on("connect", () => {
    if (
      typeof getState().auth?.user?._id !== "undefined"
      // &&
      // widgetType !== UserType.CONTACT
    ) {
      socket.emit("setup", { _id: getState().auth.user._id });
    }
  });
  socket?.on("userAssigned", (chat: IChat) => {
    dispatch({
      type: "chats/reselectChat",
      payload: {
        sessionId: getState()?.auth?.user?._id,
        prevStatus: ChatStatus.WAIT,
        chat,
      },
    });
    dispatch({
      type: "chats/removeFromBacklog",
      payload: {
        sessionId: getState()?.auth?.user?._id,
        chat,
      },
    });
  });
  socket?.on("updateMessageStatus", (message: UpdateStatusPayload) => {
    if (getState()?.chats?.selectedChat?._id === message?.chat?._id) {
      dispatch({
        type: "messages/changeStatus",
        payload: message,
      });
    }
  });
  socket?.on("startTyping", (typeMode: ITypingListener) => {
    dispatch({
      type: "chats/startTyping",
      payload: typeMode,
    });
  });
  // socket?.on("blockChat", (typeMode: any) => {
  //     dispatch({
  //       type: "chats/startBlock",
  //       payload: typeMode,
  //     });
  // });
  socket?.on("stopTyping", (typeMode: ITypingListener) => {
    dispatch({
      type: "chats/stopTyping",
      payload: typeMode,
    });
  });
  socket?.on("updateDraft", (data: IChatPreferences) => {
    dispatch({
      type: "preferences/updateDraft",
      payload: data,
    });
  });
  socket?.on("statusChange", (userChanged: IChangeStatusUser) => {
    if (
      typeof getState()?.auth?.user?._id !== "undefined" &&
      tenantAllowed({
        sessionTenant:
          getState()?.auth?.user?.tenant || getState()?.auth?.tenantID || "",
        receivedTenant: userChanged?.tenant || "",
      }) &&
      getState()?.auth?.isAuthenticated === true
    ) {
      if (
        widgetType !== UserType.CONTACT &&
        getState().chats?.backlogChats &&
        getState().chats?.backlogChats.length > 0
      ) {
        getState().chats.backlogChats.map(() => {
          dispatch({
            type: "chats/changeStatusChat",
            payload: userChanged,
          });
          return dispatch({
            type: "users/changeStatusUser",
            payload: userChanged,
          });
        });
      }
      if (
        getState().chats?.mineChats &&
        getState().chats?.mineChats.length > 0
      ) {
        getState().chats.mineChats.map(() => {
          dispatch({
            type: "chats/changeStatusChat",
            payload: userChanged,
          });
          if (getState().messages.messages.length > 0) {
            dispatch({
              type: "messages/changeUserMessage",
              payload: userChanged,
            });
          }
          return dispatch({
            type: "users/changeStatusUser",
            payload: userChanged,
          });
        });
      }
    }
  });
  socket?.on("userChange", (userChanged: IChangeUser) => {
    if (
      typeof getState()?.auth?.user?._id !== "undefined" &&
      tenantAllowed({
        sessionTenant:
          getState()?.auth?.user?.tenant || getState()?.auth?.tenantID || "",
        receivedTenant: userChanged?.tenant || "",
      }) &&
      getState()?.auth?.isAuthenticated === true
    ) {
      dispatch({
        type: "chats/changeUserChat",
        payload: userChanged,
      });
      dispatch({
        type: "messages/changeUserMessage",
        payload: userChanged,
      });
      dispatch({
        type: "users/changeUser",
        payload: userChanged,
      });
    }
  });
  socket?.on("messageReceived", async (message: IMessage) => {
    try {
      // console.log("============");
      // console.log("MSG", message);
      // console.log("============");
      const _getIfChatExists = () => {
        let chatWasFoundBacklog = false;
        let chatWasFoundMine = false;
        let chatWasFoundTeams = false;
        if (
          getState().chats &&
          getState().chats.backlogChats &&
          getState().chats.backlogChats.length > 0
        ) {
          chatWasFoundBacklog = getState().chats.backlogChats.some(
            (chat: IChat) => {
              if (chat && chat._id === message.chat._id) {
                dispatch({
                  type: "chats/first",
                  payload: {
                    chat,
                    type: "backlog",
                  },
                });
                return true;
              }
              return false;
            }
          );
        }

        if (
          getState().chats &&
          getState().chats.mineChats &&
          getState().chats.mineChats.length > 0
        ) {
          chatWasFoundMine = getState().chats.mineChats.some((chat: IChat) => {
            if (chat && chat._id === message.chat._id) {
              dispatch({
                type: "chats/first",
                payload: {
                  chat,
                  type: "mine",
                },
              });
              return true;
            }
            return false;
          });
        }

        if (
          getState().chats &&
          getState().chats.teamsChats &&
          getState().chats.teamsChats.length > 0
        ) {
          chatWasFoundTeams = getState().chats.teamsChats.some(
            (chat: IChat) => {
              if (chat && chat._id === message.chat._id) {
                dispatch({
                  type: "chats/first",
                  payload: {
                    chat,
                    type: "teams",
                  },
                });
                return true;
              }
              return false;
            }
          );
        }
        return chatWasFoundBacklog || chatWasFoundMine || chatWasFoundTeams;
      };

      const _getIfChatsIsInternal = () => {
        let isInternal = false;
        if (message?.chat?.type === ChatType.INTERNAL) {
          isInternal = true;
        }
        return isInternal;
      };

      const _getIfChatsIsExternalApp = () => {
        let isExternalApp = false;
        if (
          message?.sendertype === SenderType.CONTACT &&
          message?.chat?.type &&
          [ChatType.WHATSAPP, ChatType.BOTMAKER].includes(
            message?.chat?.type
          ) &&
          (message?.chat?.status === ChatStatus.ACTIVE ||
            message?.chat?.status === ChatStatus.WAIT ||
            message?.chat?.status === ChatStatus.WAITREPLY ||
            message?.chat?.status === ChatStatus.WAITCAMPAIGN ||
            message?.internal)
        ) {
          isExternalApp = true;
        }
        return isExternalApp;
      };

      const _getIfChatsIsWaitingCampaign = () => {
        let isWaitingCampaign = false;
        if (
          typeof message?.chat?.campaign !== "undefined" &&
          // !message?.internal &&
          message?.chat?.status === ChatStatus.WAITCAMPAIGN &&
          message?.sendertype !== SenderType.CONTACT
        ) {
          isWaitingCampaign = true;
        }
        return isWaitingCampaign;
      };

      const _getIfChatIsSelected = () => {
        let isSelected = false;
        if (
          getState().chats &&
          getState().chats.selectedChat &&
          getState().chats.selectedChat._id &&
          getState().chats.selectedChat._id === message.chat._id
        ) {
          isSelected = true;
        }
        return isSelected;
      };

      const _getIfChatIsSCrolled = () => {
        let isScrolled = false;
        if (_getIfChatIsSelected() && getState().chats.scrolling) {
          isScrolled = true;
        }
        return isScrolled;
      };

      const _getIfIsChatAgentFromChatTeam = () => {
        let wasChatTeamAgent = false;
        const memberOfChatTeam: ITeam | undefined =
          getState().auth?.user?.teams?.find((_team: ITeam) => {
            if (_team._id === message.chat.team) {
              _team.chatusers?.forEach((_user) => {
                if (_user.user === getState().auth.user._id) {
                  wasChatTeamAgent = true;
                }
              });
              return _team;
            }
            return undefined;
          });
        return {
          _boolean: wasChatTeamAgent && typeof memberOfChatTeam !== "undefined",
          _team: memberOfChatTeam,
        };
      };

      const _getIfMessageBelongsToTenant = () => {
        let belongs = false;
        if (
          (widgetType !== UserType.CONTACT &&
            message?.sender?.tenant === getState()?.auth?.tenantID &&
            message?.sender?.tenant === getState()?.auth?.user?.tenant) ||
          (widgetType === UserType.CONTACT &&
            message?.sender?.tenant === getState()?.auth?.user?.tenant)
        ) {
          belongs = true;
        }
        return belongs;
      };

      const _geyIfIsValidChat = () => {
        let isValid = false;
        // console.log("===========================================");
        // console.log("Message", message);
        // console.log(
        //   "_getIfChatsIsInternal()",
        //   "OU",
        //   "(",
        //   "!_getIfChatsIsInternal()",
        //   "E",
        //   "_getIfIsChatAgentFromChatTeam()._boolean",
        //   "E",
        //   "!_getIfChatsIsWaitingCampaign()",
        //   ")",
        //   "OU",
        //   "widgetType === UserType.CONTACT",
        //   "OU",
        //   "_getIfChatsIsExternalApp()"
        // );
        // console.log(
        //   _getIfChatsIsInternal(),
        //   "OU",
        //   "(",
        //   !_getIfChatsIsInternal(),
        //   "E",
        //   _getIfIsChatAgentFromChatTeam()._boolean,
        //   "E",
        //   !_getIfChatsIsWaitingCampaign(),
        //   ")",
        //   "OU",
        //   widgetType === UserType.CONTACT,
        //   "OU",
        //   _getIfChatsIsExternalApp()
        // );
        // console.log("===========================================");
        if (
          _getIfChatsIsInternal() ||
          (!_getIfChatsIsInternal() &&
            _getIfIsChatAgentFromChatTeam()._boolean &&
            !_getIfChatsIsWaitingCampaign()) ||
          widgetType === UserType.CONTACT ||
          _getIfChatsIsExternalApp()
        ) {
          isValid = true;
        }
        return isValid;
      };

      const _getIfMessageIsInternal = () => {
        let isInternal = false;
        if (message.internal) {
          isInternal = true;
        }
        return isInternal;
      };

      const _getIfReceiverIsOffline = () => {
        let isOffline = false;
        if (getState().auth?.user?.status === UserStatus.OFFLINE) {
          isOffline = true;
        }
        return isOffline;
      };

      const _getIfWidgetIsClosed = () => {
        let isClosed = false;
        if (widgetType !== UserType.NONE && getState().chats.isClosing) {
          isClosed = true;
        }
        return isClosed;
      };

      const conditions = {
        chatExists: _getIfChatExists(),
        chatIsInternal: _getIfChatsIsInternal(),
        chatIsSelected: _getIfChatIsSelected(),
        chatIsScrolled: _getIfChatIsSCrolled(),
        isChatAgentFromChatTeam: _getIfIsChatAgentFromChatTeam(),
        isValidChat: _getIfMessageBelongsToTenant() && _geyIfIsValidChat(),
        messageIsInternal: _getIfMessageIsInternal(),
        receiverIsOffline: _getIfReceiverIsOffline(),
        widgetIsClosed: _getIfWidgetIsClosed(),
      };

      const getNeedNotification = () => {
        const _internalGroupMessage =
          conditions?.messageIsInternal &&
          message?.chat?.groupchat &&
          message?.chat?.groupsettings?.filter((_groupuser) =>
            typeof _groupuser?.user?._id !== "undefined"
              ? _groupuser?.user._id === getState()?.auth?.user?._id
              : _groupuser?.user === getState()?.auth?.user?._id &&
                _groupuser?.active === true
          )[0]?.active === true;

        const _internalTransfer =
          conditions?.messageIsInternal &&
          message?.chat?.transferred &&
          message?.sendertype === SenderType.USER &&
          message?.type === MessageType.USER;

        const _internalWidgetContact =
          conditions?.messageIsInternal &&
          widgetType === UserType.CONTACT &&
          message?.sendertype === SenderType.USER;

        const _conditionInternal =
          !conditions?.messageIsInternal ||
          _internalWidgetContact ||
          _internalGroupMessage ||
          _internalTransfer;

        const _conditionExternal =
          !conditions.chatIsSelected ||
          conditions.chatIsScrolled ||
          conditions.receiverIsOffline ||
          conditions.widgetIsClosed ||
          !document.hasFocus();

        return (_conditionInternal && _conditionExternal) || false;
      };

      const needPushNotification =
        widgetType === UserType.NONE &&
        (!conditions.messageIsInternal ||
          (!document.hasFocus() &&
            message.chat._id === getState()?.chats?.selectedChat?._id)) &&
        !conditions.receiverIsOffline;

      const messageWithoutNotification = !conditions.isChatAgentFromChatTeam
        ._boolean
        ? message
        : {
            ...message,
            team: conditions.isChatAgentFromChatTeam._team,
          };

      const messageWithNotification = !conditions.isChatAgentFromChatTeam
        ._boolean
        ? { ...message, read: false }
        : {
            ...message,
            read: false,
            team: conditions.isChatAgentFromChatTeam._team,
          };

      const _createNewChat = async () => {
        let _sender = messageWithNotification.sender;
        if (
          typeof _sender.customers !== "undefined" &&
          _sender.customers.length > 0 &&
          typeof _sender.customers[0]?.name === "undefined" &&
          typeof _sender.customers[0]?._id === "undefined" &&
          typeof _sender.customers[0] === "string"
        ) {
          const _customer = await instances.instanceCustomers.get(
            `/${_sender.customers[0]}`
          );
          if (
            typeof _customer?.data !== "undefined" &&
            _customer?.data !== null
          ) {
            _sender.customers.shift();
            _sender.customers.unshift(_customer.data);
            _sender = {
              ..._sender,
              customers: _sender.customers,
            };
          }
        }
        const _usersBacklog = [_sender];
        const _usersMine = [_sender, getState().auth.user];

        const _getUsers = () => {
          if (messageWithNotification?.chat?.status === ChatStatus.ACTIVE) {
            if (!messageWithNotification?.chat?.groupchat) {
              return _usersMine;
            }
            return messageWithNotification?.chat?.users;
          }
          if (
            typeof messageWithNotification?.chat?.status !== "undefined" &&
            [ChatStatus.WAITREPLY, ChatStatus.WAITCAMPAIGN].includes(
              messageWithNotification?.chat?.status
            ) &&
            messageWithNotification?.type === MessageType.ALL
          ) {
            return messageWithNotification?.chat?.users;
          }
          return _usersBacklog;
        };

        const _status =
          messageWithNotification?.chat?.status === ChatStatus.WAITCAMPAIGN &&
          messageWithNotification?.chat.users?.length === 1
            ? ChatStatus.WAIT
            : ChatStatus.ACTIVE;

        let createdChat: IChat = {
          ...messageWithNotification?.chat,
          status:
            typeof messageWithNotification?.chat?.campaign !== "undefined" &&
            messageWithNotification?.chat?.status === ChatStatus.WAITCAMPAIGN
              ? _status
              : messageWithNotification?.chat?.status,
          team: conditions.isChatAgentFromChatTeam._team,
          lastmessage: messageWithNotification,
          users: _getUsers(),
          notifications: 0,
          created_at: messageWithNotification.created_at,
          updated_at: messageWithNotification.updated_at,
          deleted: false,
        };
        createdChat = await populateExternalAppToChat({ chat: createdChat });
        // console.log("1");
        // console.log(createdChat);
        dispatch({
          type: "chats/addChats",
          payload: {
            chat: createdChat,
            type:
              createdChat.status === ChatStatus.ACTIVE ||
              createdChat.status === ChatStatus.WAITREPLY ||
              createdChat.status === ChatStatus.WAITCAMPAIGN
                ? "mine"
                : "backlog",
          },
        });
        if (_status === ChatStatus.WAIT) await teamsService.reassignment();
      };

      const _validateSenderInSelectedChat = ({
        _message,
      }: {
        _message: IMessage;
      }) => {
        // console.log("_validateSenderInSelectedChat");
        let validatesender = false;
        const _sender = _message?.sender;
        const _chatusers = getState()?.chats?.selectedChat?.users;

        if (typeof _sender?._id !== "undefined") {
          if (typeof _chatusers !== "undefined" && _chatusers.length > 0) {
            _chatusers.forEach((_chatuser: IUser) => {
              if (typeof _chatuser._id !== "undefined") {
                if (_chatuser._id === _sender._id && !validatesender) {
                  // console.log("_sender", _sender);
                  // console.log("_chatusers", _chatusers);
                  validatesender = true;
                }
              }
            });
          }
        }
        // console.log("validatesender", validatesender);
        return validatesender;
      };

      const _addMessageWithNotification = async ({
        _message,
      }: {
        _message?: IMessage;
      }) => {
        // if (
        //   !conditions.messageIsInternal ||
        //   (conditions.messageIsInternal &&
        //     _message?.chat.groupchat &&
        //     _message.chat?.groupsettings?.filter(
        //       (_groupuser) =>
        //         _groupuser.user === getState()?.auth?.user?._id &&
        //         _groupuser.active === true
        //     )[0]?.active === true)
        // ) {
        // await messagesService.read({ _id: _message?._id || "", read: false });
        const _messageWithNotification =
          typeof _message !== "undefined" ? _message : messageWithNotification;
        // console.log("_addMessageWithNotification");
        // console.log(
        //   "IF =",
        //   conditions.chatIsScrolled ||
        //     (!document.hasFocus() &&
        //       messageWithNotification.chat._id ===
        //         getState()?.chats?.selectedChat?._id)
        // );
        if (
          conditions.chatIsScrolled ||
          (!document.hasFocus() &&
            messageWithNotification.chat._id ===
              getState()?.chats?.selectedChat?._id)
        ) {
          if (
            _validateSenderInSelectedChat({
              _message: _messageWithNotification,
            })
          ) {
            dispatch({
              type: "messages/addMessage",
              payload: {
                ..._messageWithNotification,
                content: getContentMessage(_messageWithNotification),
              },
            });
          }

          let _chat = getState().chats?.selectedChat;
          if (widgetType === UserType.CONTACT) {
            _chat = _messageWithNotification.chat;
            if (_messageWithNotification?.chat?.status === ChatStatus.FINISH) {
              _chat = {
                ..._chat,
                users: getState().chats?.selectedChat?.users,
              };
            }
          }
          dispatch({
            type: "chats/changeChat",
            payload: {
              chat: {
                ..._chat,
                lastmessage: {
                  ..._messageWithNotification,
                  content: getContentMessage(_messageWithNotification),
                },
                notifications:
                  (getState().chats?.selectedChat?.notifications || 0) + 1,
              },
            },
          });
        }

        dispatch({
          type: "chats/updateLastmessageWithNotification",
          payload: {
            message: {
              ..._messageWithNotification,
              content: getContentMessage(_messageWithNotification),
            },
            type:
              _messageWithNotification.chat.status === ChatStatus.ACTIVE ||
              _messageWithNotification.chat.status === ChatStatus.FINISH ||
              _messageWithNotification.chat.status === ChatStatus.WAITREPLY ||
              _messageWithNotification.chat.status === ChatStatus.WAITCAMPAIGN
                ? "mine"
                : "backlog",
          },
        });
        await messagesService.read({ _id: _message?._id || "", read: false });
        // }
      };

      const _addMessage = async ({ _message }: { _message?: IMessage }) => {
        // await messagesService.read({ _id: _message?._id || "", read: true });
        // console.log("_addMessage");
        const _messageWithoutNotification =
          typeof _message !== "undefined"
            ? _message
            : messageWithoutNotification;
        if (
          _validateSenderInSelectedChat({
            _message: _messageWithoutNotification,
          })
        ) {
          dispatch({
            type: "messages/addMessage",
            payload: {
              ..._messageWithoutNotification,
              content: getContentMessage(_messageWithoutNotification),
            },
          });
        }
        if (
          // !conditions.messageIsInternal ||
          !_message?.chat.groupchat ||
          (conditions.messageIsInternal &&
            _message?.chat.groupchat &&
            _message.chat?.groupsettings?.filter((_groupuser) =>
              typeof _groupuser.user?._id !== "undefined"
                ? _groupuser.user._id === getState()?.auth?.user?._id
                : _groupuser.user === getState()?.auth?.user?._id &&
                  _groupuser.active === true
            )[0]?.active === true)
        ) {
          dispatch({
            type: "chats/updateLastmessage",
            payload: {
              message: _messageWithoutNotification,
              type:
                _messageWithoutNotification.chat.status === ChatStatus.ACTIVE ||
                _messageWithoutNotification.chat.status === ChatStatus.FINISH ||
                _messageWithoutNotification.chat.status ===
                  ChatStatus.WAITREPLY ||
                _messageWithoutNotification.chat.status ===
                  ChatStatus.WAITCAMPAIGN
                  ? "mine"
                  : "backlog",
            },
          });
        }
        // if (_messageWithoutNotification.chat.status === ChatStatus.FINISH) {
        //   dispatch({
        //     type: "chats/removeFromMine",
        //     payload: _messageWithoutNotification.chat,
        //   });
        // }
        await messagesService.read({ _id: _message?._id || "", read: true });
      };

      const _sendPushNotification = async () => {
        const getBody = () => {
          if (message?.chat?.type === ChatType.BOTMAKER)
            return "Enviou uma mensagem";
          if (message?.files && message.files.length > 0) return "Anexo";
          if (message?.content && message?.content.length > 0) {
            if (message?.content && message?.content.length > 40) {
              return stripTags({
                value: `${getContentMessage(message).substring(0, 40)}...`,
              });
            }
            return stripTags({
              value: getContentMessage(message),
            });
          }
          return "Enviou uma mensagem";
        };

        const URL = process.env.REACT_APP_FRONTEND_URL;

        await notificationService.send({
          title: message?.sender?.name || "",
          link: `${URL}/chat/${message?.chat?._id}`,
          body: getBody(),
        });
      };

      const _messageReceivedFlow = async () => {
        // console.log("_messageReceivedFlow");
        // console.log(
        //   "!conditions.chatExists && widgetType !== UserType.CONTACT && !message.internal"
        // );
        // console.log(
        //   "!",
        //   conditions.chatExists,
        //   "&&",
        //   widgetType !== UserType.CONTACT,
        //   "&&",
        //   !message.internal
        // );
        if (
          !conditions.chatExists &&
          widgetType !== UserType.CONTACT &&
          (!message.internal ||
            (message.internal && message.chat.groupchat) ||
            (message.internal &&
              message?.chat?.transferred &&
              message?.sendertype === SenderType.USER &&
              message?.type === MessageType.USER))
        ) {
          await _createNewChat();
        }

        if (needPushNotification) {
          _sendPushNotification();
        }

        if (
          widgetType !== UserType.CONTACT ||
          (widgetType === UserType.CONTACT && conditions.chatIsSelected)
        ) {
          const _status =
            messageWithNotification?.chat?.status === ChatStatus.WAITCAMPAIGN &&
            messageWithNotification?.chat.users?.length === 1
              ? ChatStatus.WAIT
              : ChatStatus.ACTIVE;
          if (getNeedNotification()) {
            let _msgWithNotification = messageWithNotification;
            if (
              typeof _msgWithNotification.chat.status !== "undefined" &&
              [ChatStatus.WAITREPLY, ChatStatus.WAITCAMPAIGN].includes(
                _msgWithNotification.chat.status
              ) &&
              _msgWithNotification.sendertype === SenderType.CONTACT
            ) {
              _msgWithNotification = {
                ..._msgWithNotification,
                chat: {
                  ..._msgWithNotification.chat,
                  status: _status,
                },
              };
            }
            await _addMessageWithNotification({
              _message: _msgWithNotification,
            });
          } else {
            let _msgWithoutNotification = messageWithoutNotification;
            if (
              typeof _msgWithoutNotification.chat.status !== "undefined" &&
              [ChatStatus.WAITREPLY, ChatStatus.WAITCAMPAIGN].includes(
                _msgWithoutNotification.chat.status
              ) &&
              _msgWithoutNotification.sendertype === SenderType.CONTACT
            ) {
              _msgWithoutNotification = {
                ..._msgWithoutNotification,
                chat: {
                  ..._msgWithoutNotification.chat,
                  status: _status,
                },
              };
            }
            await _addMessage({ _message: _msgWithoutNotification });
          }
        }
      };
      // console.log("conditions.isValidChat", conditions.isValidChat);
      if (conditions.isValidChat) _messageReceivedFlow();
    } catch (error) {
      // console.log("[CATCH - ERROR]", error);
    }
  });
  // eslint-disable-next-line consistent-return
  socket?.on("chatChange", async (chatChanged: ChatReselectPayload) => {
    if (
      typeof getState()?.auth?.user?._id !== "undefined" &&
      tenantAllowed({
        sessionTenant:
          getState()?.auth?.user?.tenant || getState()?.auth?.tenantID || "",
        receivedTenant:
          typeof chatChanged?.chat?.users !== "undefined"
            ? chatChanged?.chat?.users[0]?.tenant || ""
            : "",
      }) &&
      getState()?.auth?.isAuthenticated === true
    ) {
      // if (chatChanged.chat.groupchat) {
      //   const _chat = chatChanged.chat;
      //   const validUsers = _chat.groupsettings
      //     ?.filter((_gs) => _gs.active === true)
      //     ?.map((_u) => _u.user);
      //   if (!validUsers?.includes(getState().auth.user._id)) {
      //     return false;
      //   }
      // }
      let _chatPayload = await populateExternalAppToChat({
        chat: chatChanged.chat,
      });

      if (_chatPayload.type !== ChatType.INTERNAL) {
        let _contact = _chatPayload?.users?.shift();
        if (typeof _contact !== "undefined") {
          if (
            typeof _contact.customers !== "undefined" &&
            _contact.customers.length > 0 &&
            typeof _contact.customers[0]?.name === "undefined" &&
            typeof _contact.customers[0]?._id === "undefined" &&
            typeof _contact.customers[0] === "string"
          ) {
            const _customer = await instances.instanceCustomers.get(
              `/${_contact.customers[0]}`
            );
            if (
              typeof _customer?.data !== "undefined" &&
              _customer?.data !== null
            ) {
              _contact.customers.shift();
              _contact.customers.unshift(_customer.data);
              _contact = {
                ..._contact,
                customers: _contact.customers,
              };
            }
          }
          const _arrContact = [_contact];
          const _arrUsers = _chatPayload.users;
          const _users = [..._arrContact, ...(_arrUsers as IUser[])];
          _chatPayload = {
            ..._chatPayload,
            users: _users,
          };
        }
      }

      const chatWasFoundBacklog: boolean =
        getState()?.chats?.backlogChats?.length > 0
          ? getState().chats?.backlogChats?.some(
              (chat: IChat) => chat?._id === chatChanged?.chat?._id
            )
          : false;
      const chatWasFoundMine: boolean =
        getState()?.chats?.mineChats?.length > 0
          ? getState()?.chats?.mineChats?.some(
              (chat: IChat) => chat?._id === chatChanged?.chat?._id
            )
          : false;
      const chatWasFoundTeams: boolean =
        getState()?.chats?.teamsChats?.length > 0
          ? getState()?.chats?.teamsChats?.some(
              (chat: IChat) => chat?._id === chatChanged?.chat?._id
            )
          : false;
      if (chatWasFoundBacklog || chatWasFoundMine || chatWasFoundTeams) {
        if (chatChanged.changedTags) {
          dispatch({
            type: "chats/reselectChat",
            payload: {
              chat: _chatPayload,
            },
          });
        }

        if (
          (typeof chatChanged.prevStatus !== "undefined" &&
            ![ChatStatus.WAITREPLY, ChatStatus.WAITCAMPAIGN].includes(
              chatChanged.prevStatus
            )) ||
          chatChanged?.chat?.groupchat
        ) {
          dispatch({
            type: "chats/changeChat",
            payload: {
              prevStatus: chatChanged.prevStatus,
              chat: _chatPayload,
              widgetContact:
                widgetType === UserType.CONTACT &&
                typeof chatChanged?.prevStatus !== "undefined" &&
                chatChanged?.prevStatus === ChatStatus.WAIT &&
                typeof _chatPayload?.status !== "undefined" &&
                _chatPayload?.status === ChatStatus.FINISH
                  ? true
                  : undefined,
              // inactivetime: chatChanged.inactivetime,
            },
          });
        } else if (
          typeof chatChanged.prevStatus !== "undefined" &&
          [ChatStatus.WAITREPLY, ChatStatus.WAITCAMPAIGN].includes(
            chatChanged.prevStatus
          ) &&
          _chatPayload.status === ChatStatus.ACTIVE &&
          widgetType !== UserType.CONTACT
        ) {
          dispatch({
            type: "chats/reselectChat",
            payload: {
              sessionId: getState().auth?.user?._id,
              chat: _chatPayload,
            },
          });
          const _updatechat: IChat | undefined =
            getState().chats?.mineChats?.find(
              (chat: IChat) => chat?._id === chatChanged?.chat?._id
            );

          if (
            typeof _updatechat !== "undefined" &&
            _updatechat.type === ChatType.WHATSAPP
          ) {
            dispatch({
              type: "chats/changeChat",
              payload: {
                prevStatus: chatChanged.prevStatus,
                chat: {
                  ..._chatPayload,
                  lastmessage: _updatechat.lastmessage,
                  status: _chatPayload.status,
                },
              },
            });
          } else if (_chatPayload.type === ChatType.BOTMAKER) {
            dispatch({
              type: "chats/changeChat",
              payload: {
                prevStatus: chatChanged.prevStatus,
                chat: _chatPayload,
              },
            });
          }
        }
        if (
          typeof chatChanged.prevStatus !== "undefined" &&
          [ChatStatus.WAITREPLY, ChatStatus.WAITCAMPAIGN].includes(
            chatChanged.prevStatus
          ) &&
          _chatPayload.status === ChatStatus.FINISH &&
          widgetType !== UserType.CONTACT
        ) {
          dispatch({
            type: "chats/removeFromMine",
            payload: _chatPayload,
          });
        }
        if (
          typeof chatChanged.agentid !== "undefined" &&
          getState()?.auth?.user?._id === chatChanged.agentid
        ) {
          // await reassignment({ chatAssignment: chatChanged.chat });
          await removeActiveChat({
            team: _chatPayload.team,
          });
          dispatch({
            type: "chats/removeFromMine",
            payload: _chatPayload,
          });
          await teamsService.reassignment();
        }
      } else if (
        _chatPayload?.transferred &&
        _chatPayload?.status &&
        ![ChatStatus.FINISH, ChatStatus.CANCELED, ChatStatus.SUSPEND].includes(
          _chatPayload?.status
        )
      ) {
        if (_chatPayload.lastmessage) {
          _chatPayload.lastmessage = {
            ..._chatPayload.lastmessage,
            read: false,
          };
          _chatPayload.notifications = 1;
          _chatPayload.firstnotread = _chatPayload.lastmessage;
        }

        const _sendPushNotification = async () => {
          const message = { ..._chatPayload.lastmessage, read: false };
          const getBody = () => {
            if (message?.chat?.type === ChatType.BOTMAKER)
              return "Enviou uma mensagem";
            if (message?.files && message.files.length > 0) return "Anexo";
            if (message?.content && message?.content.length > 0) {
              if (message?.content && message?.content.length > 40) {
                return stripTags({
                  value: `${getContentMessage(message as IMessage).substring(
                    0,
                    40
                  )}...`,
                });
              }
              return stripTags({
                value: getContentMessage(message as IMessage),
              });
            }
            return "Enviou uma mensagem";
          };

          const URL = process.env.REACT_APP_FRONTEND_URL;

          await notificationService.send({
            title: message?.sender?.name || "",
            link: `${URL}/chat/${message?.chat?._id}`,
            body: getBody(),
          });
        };

        // const audio = new Audio("/sound-test.wav");
        // audio.play();
        const _users = _chatPayload.users;
        const _team = _chatPayload.team;
        const usersIncludeMe: boolean =
          _users?.some((_u) => _u._id === getState().auth?.user?._id) || false;
        const teamIncludeMine: boolean =
          getState().auth?.user?.teams?.some(
            (_t: ITeam) =>
              _t._id === _team ||
              (typeof _team?._id !== "undefined" && _t._id === _team?._id)
          ) || false;
        const chatusersTeamIncludeMe: boolean =
          _team?.chatusers?.some(
            (_u) => _u.user === getState().auth?.user?._id
          ) || false;
        if (
          typeof chatChanged.prevStatus !== "undefined" &&
          chatChanged.prevStatus === ChatStatus.ACTIVE &&
          _chatPayload.status === ChatStatus.ACTIVE &&
          usersIncludeMe &&
          chatusersTeamIncludeMe
        ) {
          if (
            widgetType === UserType.NONE &&
            getState()?.auth?.user.status === UserStatus.ONLINE
          ) {
            _sendPushNotification();
          }
          // console.log("2");
          // console.log(_chatPayload);
          dispatch({
            type: "chats/addChats",
            payload: {
              chat: _chatPayload,
              type: "mine",
            },
          });
        } else if (
          typeof chatChanged.prevStatus !== "undefined" &&
          chatChanged.prevStatus === ChatStatus.ACTIVE &&
          _chatPayload.status === ChatStatus.WAIT &&
          teamIncludeMine &&
          chatusersTeamIncludeMe
        ) {
          if (
            widgetType === UserType.NONE &&
            getState()?.auth?.user.status === UserStatus.ONLINE
          ) {
            _sendPushNotification();
          }
          // console.log("3");
          // console.log(_chatPayload);
          dispatch({
            type: "chats/addChats",
            payload: {
              chat: _chatPayload,
              type: "backlog",
            },
          });
        }
      }
    }
  });
  socket?.on("updateCampaignBatch", (campaignUpdated: any) => {
    dispatch({
      type: "campaignbatches/updateBatchStatus",
      payload: campaignUpdated,
    });
  });

  socket?.on("updateCampaignBatchStatus", async (campaignUpdated: any) => {
    if (
      typeof campaignUpdated !== "undefined" &&
      typeof campaignUpdated.campaign !== "undefined"
    ) {
      const filter = JSON.parse(
        JSON.stringify({ filter: { campaign: campaignUpdated.campaign } })
      );
      const campaign = await campaignsService.searchBatches(filter);

      dispatch({
        type: "campaigns/reselectCampaignBatch",
        payload: campaign,
      });
    }
  });

  // ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑ ON EVENTS ↑↑↑↑↑↑↑↑↑↑↑↑↑↑↑

  return (next) => {
    return async (action) => {
      switch (action.type) {
        case "auth/login/fulfilled":
        case "auth/agidesklogin/fulfilled":
          if (action?.payload !== null) {
            socket.emit("updateStatus", {
              _id: action.payload._id,
              status: action.payload.status,
              updated_at: action.payload.updated_at,
              tenant: action.payload.tenant,
            });
            socket.emit("setup", { _id: action.payload._id });
          }
          break;
        // case "chats/search/fulfilled":
        //   socket.emit("setup", { _id: getState().auth?.user?._id });
        //   break;
        case "chats/reselectChat":
          socket.emit("updateChat", action.payload);
          break;
        case "messages/search/fulfilled":
          // socket.emit("setup", { _id: getState().auth?.user?._id });
          socket.emit("joinChat", action.meta.arg);
          break;
        case "chats/create/fulfilled":
          socket.emit("joinChat", action.payload._id);
          break;
        case "auth/setupsocket":
          socket.emit("setup", { _id: action.payload._id });
          break;
        case "messages/sendsocket":
          // eslint-disable-next-line no-case-declarations
          const __msg = {
            ...action?.payload?.message,
            viewers: action?.payload?.message?.viewers?.map((_viewer: any) =>
              typeof _viewer?._id !== "undefined" ? _viewer?._id : _viewer
            ),
          };
          if (
            typeof __msg?._id !== "undefined" &&
            typeof action?.payload?.chat?._id !== "undefined"
          ) {
            socket.emit("sendMessage", {
              chat: action.payload.chat,
              message: __msg,
            });
          }
          break;
        // case "chats/blockChat":
        //   if (
        //     typeof action?.payload?.chat?._id !== "undefined" &&
        //     typeof action?.payload?.block !== "undefined"
        //   ) {
        //     dispatch({
        //       type: "chats/chatIsBlocked",
        //       payload:  action?.payload?.chat?.id
        //     });

        //     console.log("chats/blockChat")
        //     socket.emit("blockChat", {
        //       chat: action.payload.chat,
        //       block: action.payload.block
        //     });
        //   }
        //   break;
        case "auth/changeStatus/fulfilled":
          socket.emit("updateStatus", {
            _id: action?.payload?._id,
            status: action?.meta?.arg?.status,
            updated_at: action?.payload?.updated_at,
            tenant: action?.payload?.tenant,
          });
          break;
        case "auth/uploadAvatar/fulfilled":
          socket.emit("updateUser", {
            _id: getState().auth?.user?._id,
            avatar: getState().auth?.user?.avatar,
            name: getState().auth?.user?.name,
            phone: getState().auth?.user?.phone,
            updated_at: getState().auth?.user?.updated_at,
            tenant: getState().auth?.user?.tenant,
          });
          break;
        case "auth/update/fulfilled":
        case "auth/changePassword/fulfilled":
          socket.emit("updateUser", {
            _id: action?.payload?._id,
            avatar: action?.payload?.avatar,
            name: action?.payload?.name,
            phone: action?.payload?.phone,
            updated_at: action?.payload?.updated_at,
            tenant: action?.payload?.tenant,
          });
          break;
        case "chats/closeChat":
          dispatch({
            type: "messages/selectMessages",
            payload: [],
          });
          dispatch({
            type: "messages/selectTotalMessages",
            payload: 0,
          });
          break;
        case "messages/create/fulfilled":
          try {
            const userIsOffline = action.meta?.arg?.chat?.users?.some(
              (user: IUser) => {
                if (user._id !== action?.payload?.sender?._id) {
                  if (user.status === UserStatus.OFFLINE) return true;
                  return false;
                }
                return false;
              }
            );
            // if (userIsOffline) {
            //   await messagesService.read({
            //     _id: action.payload?._id || "",
            //     read: false,
            //   });
            // }
            if (getState().chats.selectedChat.notifications > 0) {
              dispatch({
                type: "chats/clearNotifications",
                payload: "mine",
              });
            }
            // console.log("ACTION-messages", action);
            const _msg = {
              ...action.payload,
              viewers: action?.payload?.viewers?.map((_viewer: any) =>
                typeof _viewer?._id !== "undefined" ? _viewer?._id : _viewer
              ),
            };
            socket.emit("sendMessage", {
              chat: action.meta.arg.chat,
              message: _msg,
              inactivetime: action.meta.arg.inactivetime
                ? action.meta.arg.inactivetime
                : undefined,
            });
            if (
              (!action.payload.internal ||
                (action.payload.internal && action.payload.chat.groupchat)) &&
              widgetType !== UserType.CONTACT
            ) {
              dispatch({
                type: "chats/updateLastmessage",
                payload: {
                  message: action.payload,
                  type:
                    action.meta.arg.chat.status === ChatStatus.ACTIVE ||
                    action.meta.arg.chat.status === ChatStatus.FINISH ||
                    action.meta.arg.chat.status === ChatStatus.WAITREPLY ||
                    action.meta.arg.chat.status === ChatStatus.WAITCAMPAIGN
                      ? "mine"
                      : "backlog",
                },
              });
            }
            if (widgetType !== UserType.CONTACT) {
              const _chat: IChat = {
                ...action.meta.arg.chat,
                lastmessage: action.payload,
              };
              dispatch({
                type: "chats/first",
                payload: {
                  chat: _chat,
                  type:
                    action.meta.arg.chat.status === ChatStatus.ACTIVE ||
                    action.meta.arg.chat.status === ChatStatus.WAITREPLY ||
                    action.meta.arg.chat.status === ChatStatus.WAITCAMPAIGN
                      ? "mine"
                      : "backlog",
                },
              });
            }
            dispatch({
              type: "messages/disableChatCard",
              payload: {
                disble: false,
                chat: action.meta.arg.chat._id,
              },
            });
            if (userIsOffline) {
              await messagesService.read({
                _id: action.payload?._id || "",
                read: false,
              });
            }
          } catch (error) {
            // console.log(error);
          }
          break;
        case "preferences/storeUserChatPreferences":
          const _parsedThumbs: IParsedThumb[] = [];
          const _thumbs = action.payload.thumbs;
          if (typeof _thumbs !== "undefined" && _thumbs.length > 0) {
            _thumbs.forEach((obj: IParsedThumb) => {
              _parsedThumbs.push({
                name: obj.name,
                preview: obj.preview,
                lastModified: obj.lastModified,
                lastModifiedDate: obj.lastModifiedDate,
                size: obj.size,
                type: obj.type,
                webkitRelativePath: obj.webkitRelativePath,
              });
            });
          }
          socket.emit("updateDraft", {
            _id: action.payload._id,
            chat: action.payload.chat,
            sender: action.payload.user,
            type: action.payload.type,
            draftMessage: action.payload.draftMessage,
            thumbs: _thumbs,
            parsedThumbs: _parsedThumbs,
          });

          break;
        case "messages/typingMessage":
          if (
            action.payload.message.length > 0 ||
            action.payload.action === "start"
          ) {
            socket.emit("onTyping", {
              chat: action.payload.chat,
              sender: action.payload.sender,
              type: action.payload.type,
            });
          } else
            socket.emit("offTyping", {
              chat: action.payload.chat,
              sender: action.payload.sender,
              type: action.payload.type,
            });
          break;
        case "auth/refreshLogout":
          dispatch({
            type: "users/logout",
          });
          dispatch({
            type: "auth/logout",
          });
          dispatch({
            type: "messages/logout",
          });
          dispatch({
            type: "chats/logout",
          });
          dispatch({
            type: "teams/logout",
          });
          dispatch({
            type: "externalapps/logout",
          });
          // dispatch({
          //   type: "dashboard/logout",
          // });
          dispatch({
            type: "officehours/logout",
          });
          dispatch({
            type: "holidays/logout",
          });
          dispatch({
            type: "offlinemessages/logout",
          });
          // socket.off();
          break;
        case "auth/refreshContactLogout":
          dispatch({
            type: "users/logout",
          });
          dispatch({
            type: "messages/logout",
          });
          dispatch({
            type: "chats/logout",
          });
          break;
        case "auth/logout/fulfilled":
          socket.emit("updateStatus", {
            _id: action.payload._id,
            status: action.payload.status,
            updated_at: action.payload.updated_at,
            tenant: action?.payload?.tenant,
          });
          dispatch({
            type: "users/logout",
          });
          if (getState()?.chats?.recentChats?.length > 0) {
            window.parent.postMessage("toggleRecent", "*");
          }
          dispatch({
            type: "auth/logout",
          });
          dispatch({
            type: "chats/logout",
          });
          dispatch({
            type: "messages/logout",
          });
          // socket.off();
          break;
        case "chats/changeChat":
          try {
            const condition =
              action.payload.chat.status !== action.payload.prevStatus &&
              action.payload.chat.status === ChatStatus.CANCELED &&
              action.payload.prevStatus === ChatStatus.WAIT &&
              widgetType === UserType.CONTACT;
            if (condition) {
              dispatch({
                type: "auth/refreshContactLogout",
              });
            }
            // console.log(action.payload);
            const _chatPayload: IChat = await populateExternalAppToChat({
              chat: action.payload.chat,
            });
            if (
              action.payload.prevStatus === ChatStatus.WAIT &&
              _chatPayload.status &&
              [
                ChatStatus.ACTIVE,
                ChatStatus.CANCELED,
                ChatStatus.SUSPEND,
                ChatStatus.FINISH,
              ].includes(_chatPayload.status) &&
              widgetType !== UserType.CONTACT
            ) {
              if (
                action.payload.prevStatus === ChatStatus.WAIT &&
                _chatPayload.status === ChatStatus.SUSPEND
              ) {
                dispatch({
                  type: "chats/clearCancelNotifications",
                  payload: _chatPayload,
                });
              }
              dispatch({
                type: "chats/removeFromBacklog",
                payload: {
                  chat: _chatPayload,
                  sessionId: getState().auth?.user?._id,
                },
              });
              dispatch({
                type: "chats/reselectChat",
                payload: {
                  // prevStatus: ChatStatus.WAIT,
                  sessionId: getState().auth?.user?._id,
                  chat: _chatPayload,
                },
              });
            } //  else if (
            //   action.payload.prevStatus === ChatStatus.ACTIVE &&
            //   _chatPayload.status === ChatStatus.FINISH &&
            //   widgetType !== UserType.CONTACT
            // ) {
            //   dispatch({
            //     type: "chats/removeFromMine",
            //     payload: _chatPayload,
            //   });
            // }
            // else if (
            //   action.payload.prevStatus === ChatStatus.WAITREPLY &&
            //   _chatPayload.status === ChatStatus.ACTIVE &&
            //   widgetType !== UserType.CONTACT
            // ) {
            // dispatch({
            //   type: "chats/reselectChat",
            //   payload: {
            //     sessionId: getState().auth?.user?._id,
            //     chat: _chatPayload,
            //   },
            // });
            // }
          } catch (error) {
            // console.log(error);
          }
          break;
        case "messages/triggerChatInactive":
          socket.emit("triggerChatInactive", {
            chat: action.payload.chat,
            inactivetime: action.payload.inactivetime || "23:59",
          });
          break;
        default:
          break;
      }
      const result = next(action);
      return result;
    };
  };
};

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: ["auth", "preferences"],
};

const reducer = combineReducers({
  auth: authReducer,
  users: usersReducer,
  chats: chatsReducer,
  messages: messagesReducer,
  teams: teamsReducer,
  settings: settingsReducer,
  customers: customersReducer,
  externalapps: externalappsReducer,
  dashboard: dashboardReducer,
  surveyRatings: surveyRatingsReducer,
  surveys: surveysReducer,
  officehours: officehoursReducer,
  holidays: holidaysReducer,
  offlinemessages: offlinemessagesReducer,
  preferences: preferencesReducer,
  tags: tagsReducer,
  campaigns: campaignsReducer,
  campaignBatches: campaignBatchesReducer,
  appkey: appkeyReducer,
  commenttemplates: commenttemplatesReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(appMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
