import React from "react";

interface ActionButtonProps extends React.HTMLProps<HTMLButtonElement> {
  type: "goto" | "copy" | "delete" | "rotate" | "showhide";
  hide?: boolean;
}

interface customObject {
  [key: string]: string;
}

const ActionButton = (props: ActionButtonProps) => {
  const { type, hide, onClick, title } = props;

  const className: customObject = {
    goto: "mb-4",
    delete: "!text-red-500 hover:!text-white !border-red-500 hover:!bg-red-500",
    rotate:
      "!text-green-500 hover:!text-white !border-green-500 hover:!bg-green-500",
    copy: "",
    showhide: "",
  };

  const icon: customObject = {
    goto: "las la-pencil-alt",
    copy: "las la-copy",
    delete: "las la-trash",
    rotate: "las la-sync",
    showhide:
      typeof hide !== "undefined" && !hide ? "las la-eye" : "las la-eye-slash",
  };

  return (
    <button
      type="button"
      title={title}
      onClick={onClick}
      className={`hover:cursor-pointer min-h-[38.5px] rounded-[4px] w-[38.5px] border-[1px] border-[#22242626] hover:border-[#22242659] flex items-center justify-center ${className[type]}`}
    >
      <i className={`${icon[type]} self-center text-[16px]`} />
    </button>
  );
};

ActionButton.defaultProps = {
  hide: false,
};

export default ActionButton;
