import React, { useState, useRef, useEffect } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import BlockUi from "react-block-ui";
import { OnboardingWizard } from "../features/auth/components";
import Conversation from "../features/chats/components/Conversation.component";
import {
  ICustomer,
  selectCustomers,
  selectTotalCustomers,
} from "../slices/customers.slice";
import { useAppDispatch, useAppSelector } from "../hooks/redux/hooks";
import { Navbar, SideMenu } from "../shared/components/Sidebars";
import Instances from "../shared/components/Instances.component";
import { ITeam, selectTeams, selectTotalTeams } from "../slices/teams.slice";
import {
  IUser,
  selectAgents,
  selectContacts,
  selectTotalAgents,
  selectTotalContacts,
  UserType,
} from "../slices/users.slice";
import {
  ExternalAppType,
  IExternalAppAgidesk,
  IExternalAppBotmaker,
  IExternalAppVtex,
  IExternalAppWhatsApp,
  selectExternalappsAgidesk,
  selectExternalappsBotmaker,
  selectExternalappsVtex,
  selectExternalappsWhatsApp,
  selectTotalExternalappsAgidesk,
  selectTotalExternalappsBotmaker,
  selectTotalExternalappsVtex,
  selectTotalExternalappsWhatsApp,
} from "../slices/externalapps.slice";
import {
  IOfficehour,
  selectOfficehours,
  selectTotalOfficehours,
} from "../slices/officehours.slice";
import {
  IHoliday,
  selectHolidays,
  selectTotalHolidays,
} from "../slices/holidays.slice";
import {
  ITag,
  TagType,
  selectChattags,
  selectContacttags,
  selectTags,
  selectTotalChattags,
  selectTotalContacttags,
  selectTotalTags,
} from "../slices/tags.slice";
import {
  IOfflinemessage,
  selectOfflinemessages,
  selectTotalOfflinemessages,
} from "../slices/offlinemessages.slice";
import {
  ISurvey,
  selectSurveys,
  selectTotalSurveys,
} from "../slices/surveys.slice";
import {
  ICommenttemplate,
  selectCommenttemplates,
  selectTotalCommenttemplates,
} from "../slices/commenttemplates.slice";
import {
  ICampaign,
  selectCampaigns,
  selectTotalCampaigns,
} from "../slices/campaigns.slice";
import {
  customersService,
  externalappsService,
  holidaysService,
  officehoursService,
  offlinemessagesService,
  teamsService,
  usersService,
  surveysService,
  campaignsService,
  commenttemplatesService,
  tagsService,
} from "../services";
import {
  ChatPage,
  InstancesPage,
  SettingsPage,
  GeneralPage,
  ProfilePage,
  ContactPage,
  CustomerPage,
  TeamPage,
  UserPage,
  IntegrationsPage,
  AgideskPage,
  WhatsappPage,
  BotmakerPage,
  VtexPage,
  PrivacyPage,
  DashboardPage,
  LoginTenantPage,
  SurveyratingPage,
  SurveyPage,
  OfficehourPage,
  HolidayPage,
  TagPage,
  OfflinemessagePage,
  PublichoursPage,
  SurveyResultsPage,
  CampaignPage,
  CommenttemplatePage,
} from "./index";
import botAllowed from "../shared/utils/botAllowed";
import useUserIsAdmin from "../hooks/useUserIsAdmin";
import CampaignBatchesPage from "./CampaignBatches.page";
import LoadingDots from "../shared/components/LoadingDots.component";

const LayoutPage = ({
  widgetType,
  disable,
  setDisable,
}: {
  widgetType: UserType;
  disable: boolean;
  setDisable: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { isAdmin } = useUserIsAdmin();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [showCards, setShowCards] = useState(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activePage, setActivePage] = useState<number>(1);
  const [skip, setSkip] = useState<number>(0);
  const [limit, setLimit] = useState<number>(30);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [renderData, setRenderData] = useState<
    | IExternalAppAgidesk[]
    | IExternalAppWhatsApp[]
    | IExternalAppBotmaker[]
    | IExternalAppVtex[]
    | IUser[]
    | ITeam[]
    | ICustomer[]
    | IOfficehour[]
    | IHoliday[]
    | ITag[]
    | IOfflinemessage[]
    | ISurvey[]
    | ICampaign[]
    | ICommenttemplate[]
  >([]);
  const [_renderData, _setRenderData] = useState<{
    externalAppAgidesks: IExternalAppAgidesk[];
    externalAppWhatsApps: IExternalAppWhatsApp[];
    externalAppBotmakers: IExternalAppBotmaker[];
    externalAppVtexs: IExternalAppVtex[];
    users: IUser[];
    contacts: IUser[];
    teams: ITeam[];
    customers: ICustomer[];
    officehours: IOfficehour[];
    holidays: IHoliday[];
    contacttags: ITag[];
    chattags: ITag[];
    offlinemessages: IOfflinemessage[];
    surveys: ISurvey[];
    campaigns: ICampaign[];
    commenttemplates: ICommenttemplate[];
  }>({
    externalAppAgidesks: [],
    externalAppWhatsApps: [],
    externalAppBotmakers: [],
    externalAppVtexs: [],
    users: [],
    contacts: [],
    teams: [],
    customers: [],
    officehours: [],
    holidays: [],
    contacttags: [],
    chattags: [],
    offlinemessages: [],
    surveys: [],
    campaigns: [],
    commenttemplates: [],
  });

  const [instancesPerPage, setInstancesPerPage] = useState<number>(10);
  const [toggleSidebar, setToggleSidebar] = useState<boolean>(false);
  const [showDeleted, setShowDeleted] = useState<boolean>(false);
  const [showSpam, setShowSpam] = useState<boolean>(false);
  const [showHistory, setShowHistory] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [showUsersList, setShowUsersList] = useState<boolean>(false);
  const [toggleList, setToggleList] = useState<string>("mine");
  const [userToggleList, setUserToggleList] = useState<string>("users");
  // const [tagToggleList, setTagToggleList] = useState<TagType>(TagType.ALL);
  const scrollRef = useRef<null | HTMLDivElement>(null);
  const [keyword, setKeyword] = useState("");

  useEffect(() => {
    scrollRef.current?.scrollTo(0, 0);
  }, []);

  const { user, isAuthenticated } = useAppSelector((state) => state.auth);
  const { totalContacts, contacts, totalUsers, users } = useAppSelector(
    (state) => state.users
  );
  const {
    totalExternalAppWhatsApp,
    externalAppWhatsApp,
    totalExternalAppBotmaker,
    externalAppBotmaker,
    totalExternalAppAgidesk,
    externalAppAgidesk,
    totalExternalAppVtex,
    externalAppVtex,
  } = useAppSelector((state) => state.externalapps);
  const { totalTeams, teams } = useAppSelector((state) => state.teams);
  const { totalCustomers, customers } = useAppSelector(
    (state) => state.customers
  );
  const { totalOfficehours, officehours } = useAppSelector(
    (state) => state.officehours
  );
  const { totalHolidays, holidays } = useAppSelector((state) => state.holidays);
  const {
    // totalTags,
    // tags,
    chattags,
    contacttags,
    totalChattags,
    totalContacttags,
  } = useAppSelector((state) => state.tags);
  const { totalOfflinemessages, offlinemessages } = useAppSelector(
    (state) => state.offlinemessages
  );

  const { totalSurveys, surveys } = useAppSelector((state) => state.surveys);
  const { totalCommenttemplates, commenttemplates } = useAppSelector(
    (state) => state.commenttemplates
  );
  const { totalCampaigns, campaigns, isImportingTargets } = useAppSelector(
    (state) => state.campaigns
  );

  useEffect(() => {
    if (showCards) {
      setRenderData([]);
      _setRenderData({
        campaigns: [],
        commenttemplates: [],
        customers: [],
        externalAppAgidesks: [],
        externalAppBotmakers: [],
        externalAppVtexs: [],
        externalAppWhatsApps: [],
        holidays: [],
        officehours: [],
        offlinemessages: [],
        surveys: [],
        chattags: [],
        contacttags: [],
        contacts: [],
        teams: [],
        users: [],
      });
      setSkip(0);
      setLimit(30);
      setHasMore(true);
    }
  }, [showCards]);

  useEffect(() => {
    if (
      !location.pathname.includes("chat") ||
      location.pathname.includes("chattags")
    ) {
      setRenderData([]);
      _setRenderData({
        campaigns: [],
        commenttemplates: [],
        customers: [],
        externalAppAgidesks: [],
        externalAppBotmakers: [],
        externalAppVtexs: [],
        externalAppWhatsApps: [],
        holidays: [],
        officehours: [],
        offlinemessages: [],
        surveys: [],
        chattags: [],
        contacttags: [],
        contacts: [],
        teams: [],
        users: [],
      });
      setSkip(0);
      setLimit(showCards ? 30 : 10);
      setActivePage(1);
      setIsLoading(true);
      setShowCards(true);
      setKeyword("");
      setShowDeleted(false);
      setShowSpam(false);
      dispatch(selectTotalAgents(0));
      dispatch(selectTotalContacts(0));
      dispatch(selectTotalTeams(0));
      dispatch(selectTotalCustomers(0));
      dispatch(selectTotalOfficehours(0));
      dispatch(selectTotalHolidays(0));
      dispatch(selectTotalTags(0));
      dispatch(selectTotalChattags(0));
      dispatch(selectTotalContacttags(0));
      dispatch(selectTotalOfflinemessages(0));
      dispatch(selectTotalExternalappsAgidesk(0));
      dispatch(selectTotalExternalappsBotmaker(0));
      dispatch(selectTotalExternalappsVtex(0));
      dispatch(selectTotalExternalappsWhatsApp(0));
      dispatch(selectTotalSurveys(0));
      dispatch(selectTotalCampaigns(0));
      dispatch(selectTotalCommenttemplates(0));
      dispatch(selectAgents([]));
      dispatch(selectContacts([]));
      dispatch(selectTeams([]));
      dispatch(selectCustomers([]));
      dispatch(selectOfficehours([]));
      dispatch(selectTags([]));
      dispatch(selectChattags([]));
      dispatch(selectContacttags([]));
      dispatch(selectOfflinemessages([]));
      dispatch(selectExternalappsAgidesk([]));
      dispatch(selectExternalappsBotmaker([]));
      dispatch(selectExternalappsVtex([]));
      dispatch(selectExternalappsWhatsApp([]));
      dispatch(selectSurveys([]));
      dispatch(selectCampaigns([]));
      dispatch(selectCommenttemplates([]));
      // dispatch(selectCampaigns([]));
      // dispatch(
      //   selectTemplate({
      //     template: null,
      //   })
      // );
    }
    if (location.pathname === "/" && isAuthenticated && user?._id) {
      navigate("/chat");
    }
  }, [location.pathname]);

  const scroll = {
    agidesk: async () => {
      const payload = await externalappsService.searchExternalApps({
        skip,
        limit,
        filter: {
          "fields.name": keyword,
          deleted: showDeleted ? true : undefined,
          active: false,
          type: ExternalAppType.AGIDESK,
        },
      });
      if (typeof payload?.results !== "undefined") {
        setIsLoading(false);
      }
      return payload;
    },
    whatsapp: async () => {
      const payload = await externalappsService.searchExternalApps({
        skip,
        limit,
        filter: {
          "fields.name": keyword,
          deleted: showDeleted ? true : undefined,
          active: false,
          type: ExternalAppType.WHATSAPP,
        },
      });
      if (typeof payload?.results !== "undefined") {
        setIsLoading(false);
      }
      return payload;
    },
    botmaker: async () => {
      const payload = await externalappsService.searchExternalApps({
        skip,
        limit,
        filter: {
          "fields.name": keyword,
          deleted: showDeleted ? true : undefined,
          active: false,
          type: ExternalAppType.BOTMAKER,
        },
      });
      if (typeof payload?.results !== "undefined") {
        setIsLoading(false);
      }
      return payload;
    },
    vtex: async () => {
      const payload = await externalappsService.searchExternalApps({
        skip,
        limit,
        filter: {
          "fields.name": keyword,
          deleted: showDeleted ? true : undefined,
          active: false,
          type: ExternalAppType.VTEX,
        },
      });
      if (typeof payload?.results !== "undefined") {
        setIsLoading(false);
      }
      return payload;
    },
    contacts: async () => {
      const payload = await usersService.searchContacts({
        skip,
        limit,
        filter: keyword,
        deleted: showDeleted,
        spam: showSpam,
        allStatus: true,
      });
      if (typeof payload?.results !== "undefined") {
        setIsLoading(false);
      }
      return payload;
    },
    users: async () => {
      const payload = await usersService.searchAgents({
        skip,
        limit,
        filter: keyword,
        deleted: showDeleted,
        allStatus: true,
      });
      if (typeof payload?.results !== "undefined") {
        setIsLoading(false);
      }
      return payload;
    },
    teams: async () => {
      const payload = await teamsService.search({
        skip,
        limit,
        filter: keyword,
        deleted: showDeleted,
        allStatus: true,
      });
      if (typeof payload?.results !== "undefined") {
        setIsLoading(false);
      }
      return payload;
    },
    customers: async () => {
      const payload = await customersService.search({
        skip,
        limit,
        filter: keyword,
        deleted: showDeleted,
        allStatus: true,
      });
      if (typeof payload?.results !== "undefined") {
        setIsLoading(false);
      }
      return payload;
    },
    officehours: async () => {
      const payload = await officehoursService.search({
        skip,
        limit,
        filter: keyword,
        deleted: showDeleted,
        allStatus: true,
      });
      if (typeof payload?.results !== "undefined") {
        setIsLoading(false);
      }
      return payload;
    },
    contacttags: async () => {
      const payload = await tagsService.search({
        skip,
        limit,
        filter: {
          content: keyword,
          type: [TagType.CONTACT],
          teams: isAdmin
            ? undefined
            : user?.teams
                ?.map((_t) => {
                  if (typeof _t === "string") return _t;
                  if (_t.active === true) return _t._id as string;
                  return "";
                })
                .filter(
                  (arrayItem) =>
                    typeof arrayItem === "string" && arrayItem.length > 0
                ),
        },
        deleted: showDeleted,
        allStatus: true,
      });
      if (typeof payload?.results !== "undefined") {
        setIsLoading(false);
      }
      return payload;
    },
    chattags: async () => {
      const payload = await tagsService.search({
        skip,
        limit,
        filter: {
          content: keyword,
          type: [TagType.CHAT],
          teams: isAdmin
            ? undefined
            : user?.teams
                ?.map((_t) => {
                  if (typeof _t === "string") return _t;
                  if (_t.active === true) return _t._id as string;
                  return "";
                })
                .filter(
                  (arrayItem) =>
                    typeof arrayItem === "string" && arrayItem.length > 0
                ),
        },
        deleted: showDeleted,
        allStatus: true,
      });
      if (typeof payload?.results !== "undefined") {
        setIsLoading(false);
      }
      return payload;
    },
    holidays: async () => {
      const payload = await holidaysService.search({
        skip,
        limit,
        filter: keyword,
        deleted: showDeleted,
        allStatus: true,
      });
      if (typeof payload?.results !== "undefined") {
        setIsLoading(false);
      }
      return payload;
    },
    offlinemessages: async () => {
      const payload = await offlinemessagesService.search({
        skip,
        limit,
        filter: keyword,
        deleted: showDeleted,
        allStatus: true,
      });
      if (typeof payload?.results !== "undefined") {
        setIsLoading(false);
      }
      return payload;
    },
    surveys: async () => {
      const payload = await surveysService.search({
        skip,
        limit,
        filter: keyword,
        deleted: showDeleted,
        allStatus: true,
      });
      if (typeof payload?.results !== "undefined") {
        setIsLoading(false);
      }
      return payload;
    },
    campaigns: async () => {
      const payload = await campaignsService.search({
        skip,
        limit,
        filter: keyword,
        deleted: showDeleted,
        allStatus: true,
      });
      if (typeof payload?.results !== "undefined") {
        setIsLoading(false);
      }
      return payload;
    },
    commenttemplates: async () => {
      const payload = await commenttemplatesService.search({
        skip,
        limit,
        filter: keyword,
        deleted: showDeleted,
        allStatus: true,
        teams: isAdmin
          ? undefined
          : user?.teams
              ?.map((_t) => {
                if (typeof _t === "string") return _t;
                if (_t.active === true) return _t._id as string;
                return "";
              })
              .filter(
                (arrayItem) =>
                  typeof arrayItem === "string" && arrayItem.length > 0
              ),
      });
      if (typeof payload?.results !== "undefined") {
        setIsLoading(false);
      }
      return payload;
    },
  };

  useEffect(() => {
    if (keyword.length > 0) return;
    (async () => {
      let payload: {
        results:
          | IExternalAppAgidesk[]
          | IExternalAppWhatsApp[]
          | IExternalAppBotmaker[]
          | IExternalAppVtex[]
          | IUser[]
          | ITeam[]
          | ICustomer[]
          | IOfficehour[]
          | IHoliday[]
          | ITag[]
          | IOfflinemessage[]
          | ISurvey[]
          | ICampaign[]
          | ICommenttemplate[];
        count: number;
      } = { results: [], count: 0 };
      switch (location.pathname) {
        case "/settings/integration/agidesk":
          payload = await scroll.agidesk();
          if (typeof payload?.results !== "undefined") {
            if (skip + 10 >= payload.count || !showCards) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
            const _results = payload.results as IExternalAppAgidesk[];
            if (skip > 0 && showCards) {
              setRenderData(
                (renderData as IExternalAppAgidesk[]).concat(_results)
              );
              const _current: IExternalAppAgidesk[] =
                _renderData.externalAppAgidesks;
              _setRenderData((prevState) => ({
                ...prevState,
                externalAppAgidesks: _current.concat(_results),
              }));
              dispatch(
                selectExternalappsAgidesk(
                  (renderData as IExternalAppAgidesk[]).concat(_results)
                )
              );
            } else {
              setRenderData(_results);
              const _current: IExternalAppAgidesk[] = _results;
              _setRenderData((prevState) => ({
                ...prevState,
                externalAppAgidesks: _current,
              }));
              dispatch(selectExternalappsAgidesk(_results));
            }
            dispatch(selectTotalExternalappsAgidesk(payload.count));
          } else {
            setHasMore(false);
            setRenderData([]);
            const _current: IExternalAppAgidesk[] = [];
            _setRenderData((prevState) => ({
              ...prevState,
              externalAppAgidesks: _current,
            }));
            setIsLoading(false);
          }
          break;
        case "/settings/integration/agibot":
          payload = await scroll.botmaker();
          if (typeof payload?.results !== "undefined") {
            if (skip + 10 >= payload.count || !showCards) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
            const _results = payload.results as IExternalAppBotmaker[];
            if (skip > 0 && showCards) {
              setRenderData(
                (renderData as IExternalAppBotmaker[]).concat(_results)
              );
              const _current: IExternalAppBotmaker[] =
                _renderData.externalAppBotmakers;
              _setRenderData((prevState) => ({
                ...prevState,
                externalAppBotmakers: _current.concat(_results),
              }));
              dispatch(
                selectExternalappsBotmaker(
                  (renderData as IExternalAppBotmaker[]).concat(_results)
                )
              );
            } else {
              setRenderData(_results);
              const _current: IExternalAppBotmaker[] = _results;
              _setRenderData((prevState) => ({
                ...prevState,
                externalAppBotmakers: _current,
              }));
              dispatch(selectExternalappsBotmaker(_results));
            }
            dispatch(selectTotalExternalappsBotmaker(payload.count));
          } else {
            setHasMore(false);
            setRenderData([]);
            const _current: IExternalAppBotmaker[] = [];
            _setRenderData((prevState) => ({
              ...prevState,
              externalAppBotmakers: _current,
            }));
            setIsLoading(false);
          }
          break;
        case "/settings/integration/vtex":
          payload = await scroll.vtex();
          if (typeof payload?.results !== "undefined") {
            if (skip + 10 >= payload.count || !showCards) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
            const _results = payload.results as IExternalAppVtex[];
            if (skip > 0 && showCards) {
              setRenderData(
                (renderData as IExternalAppVtex[]).concat(_results)
              );
              const _current: IExternalAppVtex[] = _renderData.externalAppVtexs;
              _setRenderData((prevState) => ({
                ...prevState,
                externalAppVtexs: _current.concat(_results),
              }));
              dispatch(
                selectExternalappsVtex(
                  (renderData as IExternalAppVtex[]).concat(_results)
                )
              );
            } else {
              setRenderData(_results);
              const _current: IExternalAppVtex[] = _results;
              _setRenderData((prevState) => ({
                ...prevState,
                externalAppVtexs: _current,
              }));
              dispatch(selectExternalappsVtex(_results));
            }
            dispatch(selectTotalExternalappsVtex(payload.count));
          } else {
            setHasMore(false);
            setRenderData([]);
            const _current: IExternalAppVtex[] = [];
            _setRenderData((prevState) => ({
              ...prevState,
              externalAppVtexs: _current,
            }));
            setIsLoading(false);
          }
          break;
        case "/settings/integration/whatsapp":
          payload = await scroll.whatsapp();
          if (typeof payload?.results !== "undefined") {
            if (skip + 10 >= payload.count || !showCards) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
            const _results = payload.results as IExternalAppWhatsApp[];
            if (skip > 0 && showCards) {
              setRenderData(
                (renderData as IExternalAppWhatsApp[]).concat(_results)
              );
              const _current: IExternalAppWhatsApp[] =
                _renderData.externalAppWhatsApps;
              _setRenderData((prevState) => ({
                ...prevState,
                externalAppWhatsApps: _current.concat(_results),
              }));
              dispatch(
                selectExternalappsWhatsApp(
                  (renderData as IExternalAppWhatsApp[]).concat(_results)
                )
              );
            } else {
              setRenderData(_results);
              const _current: IExternalAppWhatsApp[] = _results;
              _setRenderData((prevState) => ({
                ...prevState,
                externalAppWhatsApps: _current,
              }));
              dispatch(selectExternalappsWhatsApp(_results));
            }
            dispatch(selectTotalExternalappsWhatsApp(payload.count));
          } else {
            setHasMore(false);
            setRenderData([]);
            const _current: IExternalAppWhatsApp[] = [];
            _setRenderData((prevState) => ({
              ...prevState,
              externalAppWhatsApps: _current,
            }));
            setIsLoading(false);
          }
          break;
        case "/settings/officehours":
          payload = await scroll.officehours();
          if (typeof payload?.results !== "undefined") {
            if (skip + 10 >= payload.count || !showCards) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
            const _results = payload.results as IOfficehour[];
            if (skip > 0 && showCards) {
              setRenderData((renderData as IOfficehour[]).concat(_results));
              const _current: IOfficehour[] = _renderData.officehours;
              _setRenderData((prevState) => ({
                ...prevState,
                officehours: _current.concat(_results),
              }));
              dispatch(
                selectOfficehours(
                  (renderData as IOfficehour[]).concat(_results)
                )
              );
            } else {
              setRenderData(_results);
              const _current: IOfficehour[] = _results;
              _setRenderData((prevState) => ({
                ...prevState,
                officehours: _current,
              }));
              dispatch(selectOfficehours(_results));
            }
            dispatch(selectTotalOfficehours(payload.count));
          } else {
            setHasMore(false);
            setRenderData([]);
            const _current: IOfficehour[] = [];
            _setRenderData((prevState) => ({
              ...prevState,
              officehours: _current,
            }));
            setIsLoading(false);
          }
          break;
        case "/settings/tags/chattags":
          payload = await scroll.chattags();
          if (typeof payload?.results !== "undefined") {
            if (skip + 10 >= payload.count || !showCards) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
            const _results = payload.results as ITag[];
            if (skip > 0 && showCards) {
              setRenderData((renderData as ITag[]).concat(_results));
              const _current: ITag[] = _renderData.chattags;
              _setRenderData((prevState) => ({
                ...prevState,
                chattags: _current.concat(_results),
              }));
              dispatch(selectChattags((renderData as ITag[]).concat(_results)));
            } else {
              setRenderData(_results);
              const _current: ITag[] = _results;
              _setRenderData((prevState) => ({
                ...prevState,
                chattags: _current,
              }));
              dispatch(selectChattags(_results));
            }
            dispatch(selectTotalChattags(payload.count));
          } else {
            setHasMore(false);
            setRenderData([]);
            const _current: ITag[] = [];
            _setRenderData((prevState) => ({
              ...prevState,
              chattags: _current,
            }));
            setIsLoading(false);
          }
          break;
        case "/settings/tags/contacttags":
          payload = await scroll.contacttags();
          if (typeof payload?.results !== "undefined") {
            if (skip + 10 >= payload.count || !showCards) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
            const _results = payload.results as ITag[];
            if (skip > 0 && showCards) {
              setRenderData((renderData as ITag[]).concat(_results));
              const _current: ITag[] = _renderData.contacttags;
              _setRenderData((prevState) => ({
                ...prevState,
                contacttags: _current.concat(_results),
              }));
              dispatch(
                selectContacttags((renderData as ITag[]).concat(_results))
              );
            } else {
              setRenderData(_results);
              const _current: ITag[] = _results;
              _setRenderData((prevState) => ({
                ...prevState,
                contacttags: _current,
              }));
              dispatch(selectContacttags(_results));
            }
            dispatch(selectTotalContacttags(payload.count));
          } else {
            setHasMore(false);
            setRenderData([]);
            const _current: ITag[] = [];
            _setRenderData((prevState) => ({
              ...prevState,
              contacttags: _current,
            }));
            setIsLoading(false);
          }
          break;
        case "/settings/holidays":
          payload = await scroll.holidays();
          if (typeof payload?.results !== "undefined") {
            if (skip + 10 >= payload.count || !showCards) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
            const _results = payload.results as IHoliday[];
            if (skip > 0 && showCards) {
              setRenderData((renderData as IHoliday[]).concat(_results));
              const _current: IHoliday[] = _renderData.holidays;
              _setRenderData((prevState) => ({
                ...prevState,
                holidays: _current.concat(_results),
              }));
              dispatch(
                selectHolidays((renderData as IHoliday[]).concat(_results))
              );
            } else {
              setRenderData(_results);
              const _current: IHoliday[] = _results;
              _setRenderData((prevState) => ({
                ...prevState,
                holidays: _current,
              }));
              dispatch(selectHolidays(_results));
            }
            dispatch(selectTotalHolidays(payload.count));
          } else {
            setHasMore(false);
            setRenderData([]);
            const _current: IHoliday[] = [];
            _setRenderData((prevState) => ({
              ...prevState,
              holidays: _current,
            }));
            setIsLoading(false);
          }
          break;
        case "/settings/offlinemessages":
          payload = await scroll.offlinemessages();
          if (typeof payload?.results !== "undefined") {
            if (skip + 10 >= payload.count || !showCards) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
            const _results = payload.results as IOfflinemessage[];
            if (skip > 0 && showCards) {
              setRenderData((renderData as IOfflinemessage[]).concat(_results));
              const _current: IOfflinemessage[] = _renderData.offlinemessages;
              _setRenderData((prevState) => ({
                ...prevState,
                offlinemessages: _current.concat(_results),
              }));
              dispatch(
                selectOfflinemessages(
                  (renderData as IOfflinemessage[]).concat(_results)
                )
              );
            } else {
              setRenderData(_results);
              const _current: IOfflinemessage[] = _results;
              _setRenderData((prevState) => ({
                ...prevState,
                offlinemessages: _current,
              }));
              dispatch(selectOfflinemessages(_results));
            }
            dispatch(selectTotalOfflinemessages(payload.count));
          } else {
            setHasMore(false);
            setRenderData([]);
            const _current: IOfflinemessage[] = [];
            _setRenderData((prevState) => ({
              ...prevState,
              offlinemessages: _current,
            }));
            setIsLoading(false);
          }
          break;
        case "/settings/users":
          payload = await scroll.users();
          if (typeof payload?.results !== "undefined") {
            if (skip + 10 >= payload.count || !showCards) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
            const _results = payload.results as IUser[];
            if (skip > 0 && showCards) {
              setRenderData((renderData as IUser[]).concat(_results));
              const _current: IUser[] = _renderData.users;
              _setRenderData((prevState) => ({
                ...prevState,
                users: _current.concat(_results),
              }));
              dispatch(selectAgents((renderData as IUser[]).concat(_results)));
            } else {
              setRenderData(_results);
              const _current: IUser[] = _results;
              _setRenderData((prevState) => ({
                ...prevState,
                users: _current,
              }));
              dispatch(selectAgents(_results));
            }
            dispatch(selectTotalAgents(payload.count));
          } else {
            setHasMore(false);
            setRenderData([]);
            const _current: IUser[] = [];
            _setRenderData((prevState) => ({
              ...prevState,
              users: _current,
            }));
            setIsLoading(false);
          }
          break;
        case "/settings/contacts":
          payload = await scroll.contacts();
          if (typeof payload?.results !== "undefined") {
            if (skip + 10 >= payload.count || !showCards) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
            const _results = payload.results as IUser[];
            if (skip > 0 && showCards) {
              setRenderData((renderData as IUser[]).concat(_results));
              const _current: IUser[] = _renderData.contacts;
              _setRenderData((prevState) => ({
                ...prevState,
                contacts: _current.concat(_results),
              }));
              dispatch(
                selectContacts((renderData as IUser[]).concat(_results))
              );
            } else {
              setRenderData(_results);
              const _current: IUser[] = _results;
              _setRenderData((prevState) => ({
                ...prevState,
                contacts: _current,
              }));
              dispatch(selectContacts(_results));
            }
            dispatch(selectTotalContacts(payload.count));
          } else {
            setHasMore(false);
            setRenderData([]);
            const _current: IUser[] = [];
            _setRenderData((prevState) => ({
              ...prevState,
              contacts: _current,
            }));
            setIsLoading(false);
          }
          break;
        case "/settings/teams":
          payload = await scroll.teams();
          if (typeof payload?.results !== "undefined") {
            if (skip + 10 >= payload.count || !showCards) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
            const _results = payload.results as ITeam[];
            if (skip > 0 && showCards) {
              setRenderData((renderData as ITeam[]).concat(_results));
              const _current: ITeam[] = _renderData.teams;
              _setRenderData((prevState) => ({
                ...prevState,
                teams: _current.concat(_results),
              }));
              dispatch(selectTeams((renderData as ITeam[]).concat(_results)));
            } else {
              setRenderData(_results);
              const _current: ITeam[] = _results;
              _setRenderData((prevState) => ({
                ...prevState,
                teams: _current,
              }));
              dispatch(selectTeams(_results));
            }
            dispatch(selectTotalTeams(payload.count));
          } else {
            setHasMore(false);
            setRenderData([]);
            const _current: ITeam[] = [];
            _setRenderData((prevState) => ({
              ...prevState,
              teams: _current,
            }));
            setIsLoading(false);
          }
          break;
        case "/settings/customers":
          payload = await scroll.customers();
          if (typeof payload?.results !== "undefined") {
            if (skip + 10 >= payload.count || !showCards) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
            const _results = payload.results as ICustomer[];
            if (skip > 0 && showCards) {
              setRenderData((renderData as ICustomer[]).concat(_results));
              const _current: ICustomer[] = _renderData.customers;
              _setRenderData((prevState) => ({
                ...prevState,
                customers: _current.concat(_results),
              }));
              dispatch(
                selectCustomers((renderData as ICustomer[]).concat(_results))
              );
            } else {
              setRenderData(_results);
              const _current: ICustomer[] = _results;
              _setRenderData((prevState) => ({
                ...prevState,
                customers: _current,
              }));
              dispatch(selectCustomers(_results));
            }
            dispatch(selectTotalCustomers(payload.count));
          } else {
            setHasMore(false);
            setRenderData([]);
            const _current: ICustomer[] = [];
            _setRenderData((prevState) => ({
              ...prevState,
              customers: _current,
            }));
            setIsLoading(false);
          }
          break;
        case "/settings/surveys":
          payload = await scroll.surveys();
          if (typeof payload?.results !== "undefined") {
            if (skip + 10 >= payload.count || !showCards) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
            const _results = payload.results as ISurvey[];
            if (skip > 0 && showCards) {
              setRenderData((renderData as ISurvey[]).concat(_results));
              const _current: ISurvey[] = _renderData.surveys;
              _setRenderData((prevState) => ({
                ...prevState,
                surveys: _current.concat(_results),
              }));
              dispatch(
                selectSurveys((renderData as ISurvey[]).concat(_results))
              );
            } else {
              setRenderData(_results);
              const _current: ISurvey[] = _results;
              _setRenderData((prevState) => ({
                ...prevState,
                surveys: _current,
              }));
              dispatch(selectSurveys(_results));
            }
            dispatch(selectTotalSurveys(payload.count));
          } else {
            setHasMore(false);
            setRenderData([]);
            const _current: ISurvey[] = [];
            _setRenderData((prevState) => ({
              ...prevState,
              surveys: _current,
            }));
            setIsLoading(false);
          }
          break;
        case "/settings/campaigns":
          payload = await scroll.campaigns();
          if (typeof payload?.results !== "undefined") {
            if (skip + 10 >= payload.count || !showCards) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
            const _results = payload.results as ICampaign[];
            if (skip > 0 && showCards) {
              setRenderData((renderData as ICampaign[]).concat(_results));
              const _current: ICampaign[] = _renderData.campaigns;
              _setRenderData((prevState) => ({
                ...prevState,
                campaigns: _current.concat(_results),
              }));
              dispatch(
                selectCampaigns((renderData as ICampaign[]).concat(_results))
              );
            } else {
              setRenderData(_results);
              const _current: ICampaign[] = _results;
              _setRenderData((prevState) => ({
                ...prevState,
                campaigns: _current,
              }));
              dispatch(selectCampaigns(_results));
            }
            dispatch(selectTotalCampaigns(payload.count));
          } else {
            setHasMore(false);
            setRenderData([]);
            const _current: ICampaign[] = [];
            _setRenderData((prevState) => ({
              ...prevState,
              campaigns: _current,
            }));
            setIsLoading(false);
          }
          break;
        case "/settings/commenttemplates":
          payload = await scroll.commenttemplates();
          if (typeof payload?.results !== "undefined") {
            if (skip + 10 >= payload.count || !showCards) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
            const _results = payload.results as ICommenttemplate[];
            if (skip > 0 && showCards) {
              setRenderData(
                (renderData as ICommenttemplate[]).concat(_results)
              );
              const _current: ICommenttemplate[] = _renderData.commenttemplates;
              _setRenderData((prevState) => ({
                ...prevState,
                commenttemplates: _current.concat(_results),
              }));
              dispatch(
                selectCommenttemplates(
                  (renderData as ICommenttemplate[]).concat(_results)
                )
              );
            } else {
              setRenderData(_results);
              const _current: ICommenttemplate[] = _results;
              _setRenderData((prevState) => ({
                ...prevState,
                commenttemplates: _current,
              }));
              dispatch(selectCommenttemplates(_results));
            }
            dispatch(selectTotalCommenttemplates(payload.count));
          } else {
            setHasMore(false);
            setRenderData([]);
            const _current: ICommenttemplate[] = [];
            _setRenderData((prevState) => ({
              ...prevState,
              commenttemplates: _current,
            }));
            setIsLoading(false);
          }
          break;
        default:
          break;
      }
    })();
  }, [
    location.pathname,
    location.hash,
    skip,
    keyword,
    showDeleted,
    showSpam,
    activePage,
    limit,
  ]);

  useEffect(() => {
    if (keyword.length === 0) return;
    (async () => {
      let payload: {
        results:
          | IExternalAppAgidesk[]
          | IExternalAppWhatsApp[]
          | IExternalAppBotmaker[]
          | IExternalAppVtex[]
          | IUser[]
          | ITeam[]
          | ICustomer[]
          | IOfficehour[]
          | IHoliday[]
          | ITag[]
          | IOfflinemessage[]
          | ISurvey[]
          | ICampaign[]
          | ICommenttemplate[];
        count: number;
      } = { results: [], count: 0 };
      switch (location.pathname) {
        case "/settings/integration/agidesk":
          payload = await scroll.agidesk();
          if (typeof payload?.results !== "undefined") {
            if (skip + 10 >= payload.count || !showCards) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
            const _results = payload.results as IExternalAppAgidesk[];
            if (skip > 0 && showCards) {
              setRenderData(
                (renderData as IExternalAppAgidesk[]).concat(_results)
              );
              const _current: IExternalAppAgidesk[] =
                _renderData.externalAppAgidesks;
              _setRenderData((prevState) => ({
                ...prevState,
                externalAppAgidesks: _current.concat(_results),
              }));
              dispatch(
                selectExternalappsAgidesk(
                  (renderData as IExternalAppAgidesk[]).concat(_results)
                )
              );
            } else {
              setRenderData(_results);
              const _current: IExternalAppAgidesk[] = _results;
              _setRenderData((prevState) => ({
                ...prevState,
                externalAppAgidesks: _current,
              }));
              dispatch(selectExternalappsAgidesk(_results));
            }
            dispatch(selectTotalExternalappsAgidesk(payload.count));
          } else {
            setHasMore(false);
            setRenderData([]);
            const _current: IExternalAppAgidesk[] = [];
            _setRenderData((prevState) => ({
              ...prevState,
              externalAppAgidesks: _current,
            }));
            setIsLoading(false);
          }
          break;
        case "/settings/integration/agibot":
          payload = await scroll.botmaker();
          if (typeof payload?.results !== "undefined") {
            if (skip + 10 >= payload.count || !showCards) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
            const _results = payload.results as IExternalAppBotmaker[];
            if (skip > 0 && showCards) {
              setRenderData(
                (renderData as IExternalAppBotmaker[]).concat(_results)
              );
              const _current: IExternalAppBotmaker[] =
                _renderData.externalAppBotmakers;
              _setRenderData((prevState) => ({
                ...prevState,
                externalAppBotmakers: _current.concat(_results),
              }));
              dispatch(
                selectExternalappsBotmaker(
                  (renderData as IExternalAppBotmaker[]).concat(_results)
                )
              );
            } else {
              setRenderData(_results);
              const _current: IExternalAppBotmaker[] = _results;
              _setRenderData((prevState) => ({
                ...prevState,
                externalAppBotmakers: _current,
              }));
              dispatch(selectExternalappsBotmaker(_results));
            }
            dispatch(selectTotalExternalappsBotmaker(payload.count));
          } else {
            setHasMore(false);
            setRenderData([]);
            const _current: IExternalAppBotmaker[] = [];
            _setRenderData((prevState) => ({
              ...prevState,
              externalAppBotmakers: _current,
            }));
            setIsLoading(false);
          }
          break;
        case "/settings/integration/vtex":
          payload = await scroll.vtex();
          if (typeof payload?.results !== "undefined") {
            if (skip + 10 >= payload.count || !showCards) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
            const _results = payload.results as IExternalAppVtex[];
            if (skip > 0 && showCards) {
              setRenderData(
                (renderData as IExternalAppVtex[]).concat(_results)
              );
              const _current: IExternalAppVtex[] = _renderData.externalAppVtexs;
              _setRenderData((prevState) => ({
                ...prevState,
                externalAppVtexs: _current.concat(_results),
              }));
              dispatch(
                selectExternalappsVtex(
                  (renderData as IExternalAppVtex[]).concat(_results)
                )
              );
            } else {
              setRenderData(_results);
              const _current: IExternalAppVtex[] = _results;
              _setRenderData((prevState) => ({
                ...prevState,
                externalAppVtexs: _current,
              }));
              dispatch(selectExternalappsVtex(_results));
            }
            dispatch(selectTotalExternalappsVtex(payload.count));
          } else {
            setHasMore(false);
            setRenderData([]);
            const _current: IExternalAppVtex[] = [];
            _setRenderData((prevState) => ({
              ...prevState,
              externalAppVtexs: _current,
            }));
            setIsLoading(false);
          }
          break;
        case "/settings/integration/whatsapp":
          payload = await scroll.whatsapp();
          if (typeof payload?.results !== "undefined") {
            if (skip + 10 >= payload.count || !showCards) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
            const _results = payload.results as IExternalAppWhatsApp[];
            if (skip > 0 && showCards) {
              setRenderData(
                (renderData as IExternalAppWhatsApp[]).concat(_results)
              );
              const _current: IExternalAppWhatsApp[] =
                _renderData.externalAppWhatsApps;
              _setRenderData((prevState) => ({
                ...prevState,
                externalAppWhatsApps: _current.concat(_results),
              }));
              dispatch(
                selectExternalappsWhatsApp(
                  (renderData as IExternalAppWhatsApp[]).concat(_results)
                )
              );
            } else {
              setRenderData(_results);
              const _current: IExternalAppWhatsApp[] = _results;
              _setRenderData((prevState) => ({
                ...prevState,
                externalAppWhatsApps: _current,
              }));
              dispatch(selectExternalappsWhatsApp(_results));
            }
            dispatch(selectTotalExternalappsWhatsApp(payload.count));
          } else {
            setHasMore(false);
            setRenderData([]);
            const _current: IExternalAppWhatsApp[] = [];
            _setRenderData((prevState) => ({
              ...prevState,
              externalAppWhatsApps: _current,
            }));
            setIsLoading(false);
          }
          break;
        case "/settings/officehours":
          payload = await scroll.officehours();
          if (typeof payload?.results !== "undefined") {
            if (skip + 10 >= payload.count || !showCards) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
            const _results = payload.results as IOfficehour[];
            if (skip > 0 && showCards) {
              setRenderData((renderData as IOfficehour[]).concat(_results));
              const _current: IOfficehour[] = _renderData.officehours;
              _setRenderData((prevState) => ({
                ...prevState,
                officehours: _current.concat(_results),
              }));
              dispatch(
                selectOfficehours(
                  (renderData as IOfficehour[]).concat(_results)
                )
              );
            } else {
              setRenderData(_results);
              const _current: IOfficehour[] = _results;
              _setRenderData((prevState) => ({
                ...prevState,
                officehours: _current,
              }));
              dispatch(selectOfficehours(_results));
            }
            dispatch(selectTotalOfficehours(payload.count));
          } else {
            setHasMore(false);
            setRenderData([]);
            const _current: IOfficehour[] = [];
            _setRenderData((prevState) => ({
              ...prevState,
              officehours: _current,
            }));
            setIsLoading(false);
          }
          break;
        case "/settings/tags/chattags":
          payload = await scroll.chattags();
          if (typeof payload?.results !== "undefined") {
            if (skip + 10 >= payload.count || !showCards) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
            const _results = payload.results as ITag[];
            if (skip > 0 && showCards) {
              setRenderData((renderData as ITag[]).concat(_results));
              const _current: ITag[] = _renderData.chattags;
              _setRenderData((prevState) => ({
                ...prevState,
                chattags: _current.concat(_results),
              }));
              dispatch(selectChattags((renderData as ITag[]).concat(_results)));
            } else {
              setRenderData(_results);
              const _current: ITag[] = _results;
              _setRenderData((prevState) => ({
                ...prevState,
                chattags: _current,
              }));
              dispatch(selectChattags(_results));
            }
            dispatch(selectTotalChattags(payload.count));
          } else {
            setHasMore(false);
            setRenderData([]);
            const _current: ITag[] = [];
            _setRenderData((prevState) => ({
              ...prevState,
              chattags: _current,
            }));
            setIsLoading(false);
          }
          break;
        case "/settings/tags/contacttags":
          payload = await scroll.contacttags();
          if (typeof payload?.results !== "undefined") {
            if (skip + 10 >= payload.count || !showCards) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
            const _results = payload.results as ITag[];
            if (skip > 0 && showCards) {
              setRenderData((renderData as ITag[]).concat(_results));
              const _current: ITag[] = _renderData.contacttags;
              _setRenderData((prevState) => ({
                ...prevState,
                contacttags: _current.concat(_results),
              }));
              dispatch(
                selectContacttags((renderData as ITag[]).concat(_results))
              );
            } else {
              setRenderData(_results);
              const _current: ITag[] = _results;
              _setRenderData((prevState) => ({
                ...prevState,
                contacttags: _current,
              }));
              dispatch(selectContacttags(_results));
            }
            dispatch(selectTotalContacttags(payload.count));
          } else {
            setHasMore(false);
            setRenderData([]);
            const _current: ITag[] = [];
            _setRenderData((prevState) => ({
              ...prevState,
              contacttags: _current,
            }));
            setIsLoading(false);
          }
          break;
        case "/settings/holidays":
          payload = await scroll.holidays();
          if (typeof payload?.results !== "undefined") {
            if (skip + 10 >= payload.count || !showCards) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
            const _results = payload.results as IHoliday[];
            if (skip > 0 && showCards) {
              setRenderData((renderData as IHoliday[]).concat(_results));
              const _current: IHoliday[] = _renderData.holidays;
              _setRenderData((prevState) => ({
                ...prevState,
                holidays: _current.concat(_results),
              }));
              dispatch(
                selectHolidays((renderData as IHoliday[]).concat(_results))
              );
            } else {
              setRenderData(_results);
              const _current: IHoliday[] = _results;
              _setRenderData((prevState) => ({
                ...prevState,
                holidays: _current,
              }));
              dispatch(selectHolidays(_results));
            }
            dispatch(selectTotalHolidays(payload.count));
          } else {
            setHasMore(false);
            setRenderData([]);
            const _current: IHoliday[] = [];
            _setRenderData((prevState) => ({
              ...prevState,
              holidays: _current,
            }));
            setIsLoading(false);
          }
          break;
        case "/settings/offlinemessages":
          payload = await scroll.offlinemessages();
          if (typeof payload?.results !== "undefined") {
            if (skip + 10 >= payload.count || !showCards) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
            const _results = payload.results as IOfflinemessage[];
            if (skip > 0 && showCards) {
              setRenderData((renderData as IOfflinemessage[]).concat(_results));
              const _current: IOfflinemessage[] = _renderData.offlinemessages;
              _setRenderData((prevState) => ({
                ...prevState,
                offlinemessages: _current.concat(_results),
              }));
              dispatch(
                selectOfflinemessages(
                  (renderData as IOfflinemessage[]).concat(_results)
                )
              );
            } else {
              setRenderData(_results);
              const _current: IOfflinemessage[] = _results;
              _setRenderData((prevState) => ({
                ...prevState,
                offlinemessages: _current,
              }));
              dispatch(selectOfflinemessages(_results));
            }
            dispatch(selectTotalOfflinemessages(payload.count));
          } else {
            setHasMore(false);
            setRenderData([]);
            const _current: IOfflinemessage[] = [];
            _setRenderData((prevState) => ({
              ...prevState,
              offlinemessages: _current,
            }));
            setIsLoading(false);
          }
          break;
        case "/settings/surveys":
          payload = await scroll.surveys();
          if (typeof payload?.results !== "undefined") {
            if (skip + 10 >= payload.count || !showCards) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
            const _results = payload.results as ISurvey[];
            if (skip > 0 && showCards) {
              setRenderData((renderData as ISurvey[]).concat(_results));
              const _current: ISurvey[] = _renderData.surveys;
              _setRenderData((prevState) => ({
                ...prevState,
                surveys: _current.concat(_results),
              }));
              dispatch(
                selectSurveys((renderData as ISurvey[]).concat(_results))
              );
            } else {
              setRenderData(_results);
              const _current: ISurvey[] = _results;
              _setRenderData((prevState) => ({
                ...prevState,
                surveys: _current,
              }));
              dispatch(selectSurveys(_results));
            }
            dispatch(selectTotalSurveys(payload.count));
          } else {
            setHasMore(false);
            setRenderData([]);
            const _current: ISurvey[] = [];
            _setRenderData((prevState) => ({
              ...prevState,
              surveys: _current,
            }));
            setIsLoading(false);
          }
          break;
        case "/settings/campaigns":
          payload = await scroll.campaigns();
          if (typeof payload?.results !== "undefined") {
            if (skip + 10 >= payload.count || !showCards) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
            const _results = payload.results as ICampaign[];
            if (skip > 0 && showCards) {
              setRenderData((renderData as ICampaign[]).concat(_results));
              const _current: ICampaign[] = _renderData.campaigns;
              _setRenderData((prevState) => ({
                ...prevState,
                campaigns: _current.concat(_results),
              }));
              dispatch(
                selectCampaigns((renderData as ICampaign[]).concat(_results))
              );
            } else {
              setRenderData(_results);
              const _current: ICampaign[] = _results;
              _setRenderData((prevState) => ({
                ...prevState,
                campaigns: _current,
              }));
              dispatch(selectCampaigns(_results));
            }
            dispatch(selectTotalCampaigns(payload.count));
          } else {
            setHasMore(false);
            setRenderData([]);
            const _current: ICampaign[] = [];
            _setRenderData((prevState) => ({
              ...prevState,
              campaigns: _current,
            }));
            setIsLoading(false);
          }
          break;
        case "/settings/users":
          payload = await scroll.users();
          if (typeof payload?.results !== "undefined") {
            if (skip + 10 >= payload.count || !showCards) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
            const _results = payload.results as IUser[];
            if (skip > 0 && showCards) {
              setRenderData((renderData as IUser[]).concat(_results));
              const _current: IUser[] = _renderData.users;
              _setRenderData((prevState) => ({
                ...prevState,
                users: _current.concat(_results),
              }));
              dispatch(selectAgents((renderData as IUser[]).concat(_results)));
            } else {
              setRenderData(_results);
              const _current: IUser[] = _results;
              _setRenderData((prevState) => ({
                ...prevState,
                users: _current,
              }));
              dispatch(selectAgents(_results));
            }
            dispatch(selectTotalAgents(payload.count));
          } else {
            setHasMore(false);
            setRenderData([]);
            const _current: IUser[] = [];
            _setRenderData((prevState) => ({
              ...prevState,
              users: _current,
            }));
            setIsLoading(false);
          }
          break;
        case "/settings/contacts":
          payload = await scroll.contacts();
          if (typeof payload?.results !== "undefined") {
            if (skip + 10 >= payload.count || !showCards) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
            const _results = payload.results as IUser[];
            if (skip > 0 && showCards) {
              setRenderData((renderData as IUser[]).concat(_results));
              const _current: IUser[] = _renderData.contacts;
              _setRenderData((prevState) => ({
                ...prevState,
                contacts: _current.concat(_results),
              }));
              dispatch(
                selectContacts((renderData as IUser[]).concat(_results))
              );
            } else {
              setRenderData(_results);
              const _current: IUser[] = _results;
              _setRenderData((prevState) => ({
                ...prevState,
                contacts: _current,
              }));
              dispatch(selectContacts(_results));
            }
            dispatch(selectTotalContacts(payload.count));
          } else {
            setHasMore(false);
            setRenderData([]);
            const _current: IUser[] = [];
            _setRenderData((prevState) => ({
              ...prevState,
              contacts: _current,
            }));
            setIsLoading(false);
          }
          break;
        case "/settings/teams":
          payload = await scroll.teams();
          if (typeof payload?.results !== "undefined") {
            if (skip + 10 >= payload.count || !showCards) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
            const _results = payload.results as ITeam[];
            if (skip > 0 && showCards) {
              setRenderData((renderData as ITeam[]).concat(_results));
              const _current: ITeam[] = _renderData.teams;
              _setRenderData((prevState) => ({
                ...prevState,
                teams: _current.concat(_results),
              }));
              dispatch(selectTeams((renderData as ITeam[]).concat(_results)));
            } else {
              setRenderData(_results);
              const _current: ITeam[] = _results;
              _setRenderData((prevState) => ({
                ...prevState,
                teams: _current,
              }));
              dispatch(selectTeams(_results));
            }
            dispatch(selectTotalTeams(payload.count));
          } else {
            setHasMore(false);
            setRenderData([]);
            const _current: ITeam[] = [];
            _setRenderData((prevState) => ({
              ...prevState,
              teams: _current,
            }));
            setIsLoading(false);
          }
          break;
        case "/settings/customers":
          payload = await scroll.customers();
          if (typeof payload?.results !== "undefined") {
            if (skip + 10 >= payload.count || !showCards) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
            const _results = payload.results as ICustomer[];
            if (skip > 0 && showCards) {
              setRenderData((renderData as ICustomer[]).concat(_results));
              const _current: ICustomer[] = _renderData.customers;
              _setRenderData((prevState) => ({
                ...prevState,
                customers: _current.concat(_results),
              }));
              dispatch(
                selectCustomers((renderData as ICustomer[]).concat(_results))
              );
            } else {
              setRenderData(_results);
              const _current: ICustomer[] = _results;
              _setRenderData((prevState) => ({
                ...prevState,
                customers: _current,
              }));
              dispatch(selectCustomers(_results));
            }
            dispatch(selectTotalCustomers(payload.count));
          } else {
            setHasMore(false);
            setRenderData([]);
            const _current: ICustomer[] = [];
            _setRenderData((prevState) => ({
              ...prevState,
              customers: _current,
            }));
            setIsLoading(false);
          }
          break;
        case "/settings/commenttemplates":
          payload = await scroll.commenttemplates();
          if (typeof payload?.results !== "undefined") {
            if (skip + 10 >= payload.count || !showCards) {
              setHasMore(false);
            } else {
              setHasMore(true);
            }
            const _results = payload.results as ICommenttemplate[];
            if (skip > 0 && showCards) {
              setRenderData(
                (renderData as ICommenttemplate[]).concat(_results)
              );
              const _current: ICommenttemplate[] = _renderData.commenttemplates;
              _setRenderData((prevState) => ({
                ...prevState,
                commenttemplates: _current.concat(_results),
              }));
              dispatch(
                selectCommenttemplates(
                  (renderData as ICommenttemplate[]).concat(_results)
                )
              );
            } else {
              setRenderData(_results);
              const _current: ICommenttemplate[] = _results;
              _setRenderData((prevState) => ({
                ...prevState,
                commenttemplates: _current,
              }));
              dispatch(selectCommenttemplates(_results));
            }
            dispatch(selectTotalCommenttemplates(payload.count));
          } else {
            setHasMore(false);
            setRenderData([]);
            const _current: ICommenttemplate[] = [];
            _setRenderData((prevState) => ({
              ...prevState,
              commenttemplates: _current,
            }));
            setIsLoading(false);
          }
          break;
        default:
          break;
      }
    })();
  }, [keyword]);

  const getInstanceComponent = (type: string) => {
    const renderList: {
      [key: string]:
        | IExternalAppAgidesk[]
        | IExternalAppBotmaker[]
        | IExternalAppVtex[]
        | IExternalAppWhatsApp[]
        | IUser[]
        | ITeam[]
        | ICustomer[]
        | IOfficehour[]
        | IHoliday[]
        | ITag[]
        | IOfflinemessage[]
        | ISurvey[]
        | ICampaign[]
        | ICommenttemplate[];
    } = {
      agidesk: externalAppAgidesk,
      botmaker: externalAppBotmaker,
      vtex: externalAppVtex,
      whatsapp: externalAppWhatsApp,
      users,
      contacts,
      teams,
      customers,
      officehours,
      holidays,
      contacttags,
      chattags,
      offlinemessages,
      surveys,
      campaigns,
      commenttemplates,
    };
    const total: {
      [key: string]: number;
    } = {
      agidesk: totalExternalAppAgidesk,
      botmaker: totalExternalAppBotmaker,
      vtex: totalExternalAppVtex,
      whatsapp: totalExternalAppWhatsApp,
      users: totalUsers,
      contacts: totalContacts,
      teams: totalTeams,
      customers: totalCustomers,
      officehours: totalOfficehours,
      holidays: totalHolidays,
      chattags: totalChattags,
      contacttags: totalContacttags,
      offlinemessages: totalOfflinemessages,
      surveys: totalSurveys,
      campaigns: totalCampaigns,
      commenttemplates: totalCommenttemplates,
    };
    const title: {
      [key: string]: string;
    } = {
      agidesk: "Agidesk",
      botmaker: "Agibot",
      vtex: "VTEX",
      whatsapp: "WhatsApp",
      users: "Agentes",
      contacts: "Contatos",
      teams: "Equipes",
      customers: "Clientes",
      officehours: "Horários de atendimento",
      holidays: "Feriados",
      contacttags: "Marcadores de contato",
      chattags: "Marcadores de conversa",
      offlinemessages: "Perfis de atendimento fora de horário",
      surveys: "Pesquisas de satisfação",
      campaigns: "Campanhas",
      commenttemplates: "Modelos de respostas",
    };
    const plus: {
      [key: string]: string;
    } = {
      agidesk: "Criar nova integração",
      botmaker: "Criar nova integração",
      vtex: "Criar nova integração",
      whatsapp: "Criar novo número",
      users: "Criar novo agente",
      contacts: "Criar novo contato",
      teams: "Criar nova equipe",
      customers: "Criar novo cliente",
      officehours: "Criar novo horário",
      holidays: "Criar novo feriado",
      contacttags: "Criar novo marcador",
      chattags: "Criar novo marcador",
      offlinemessages: "Criar novo perfil de atendimento fora de horário",
      surveys: "Criar nova pesquisa",
      campaigns: "Criar nova campanha",
      commenttemplates: "Criar novo modelo de resposta",
    };
    const icon: {
      [key: string]: string;
    } = {
      agidesk: "agidesk",
      botmaker: "las la-robot",
      vtex: "las la-shopping-cart",
      whatsapp: "lab la-whatsapp",
      users: "las la-user",
      contacts: "las la-address-book",
      teams: "las la-user-friends",
      customers: "las la-building",
      officehours: "las la-calendar-check",
      holidays: "las la-calendar-times",
      contacttags: "las la-user-tag",
      chattags: "las la-tags",
      offlinemessages: "las la-hourglass-end",
      surveys: "las la-smile",
      campaigns: "las la-bullhorn",
      commenttemplates: "las la-comment",
    };
    const modalTitle: {
      [key: string]: string;
    } = {
      agidesk: "Nova integração",
      botmaker: "Nova integração",
      vtex: "Nova integração",
      whatsapp: "Novo número",
      users: "Novo agente",
      contacts: "Novo contato",
      teams: "Nova equipe",
      customers: "Novo cliente",
      officehours: "Novo horário",
      contacttags: "Novo marcador",
      chattags: "Novo marcador",
      holidays: "Novo feriado",
      offlinemessages: "Novo perfil de atendimento fora de horário",
      surveys: "Nova pesquisa",
      campaigns: "Nova campanha",
      commenttemplates: "Novo modelo de resposta",
    };

    const gridData: {
      [key: string]: any[];
    } = {
      agidesk: _renderData.externalAppAgidesks,
      botmaker: _renderData.externalAppBotmakers,
      vtex: _renderData.externalAppVtexs,
      whatsapp: _renderData.externalAppWhatsApps,
      users: _renderData.users,
      contacts: _renderData.contacts,
      teams: _renderData.teams,
      customers: _renderData.customers,
      officehours: _renderData.officehours,
      contacttags: _renderData.contacttags,
      chattags: _renderData.chattags,
      holidays: _renderData.holidays,
      offlinemessages: _renderData.offlinemessages,
      surveys: _renderData.surveys,
      campaigns: _renderData.campaigns,
      commenttemplates: _renderData.commenttemplates,
    };

    const instanceComponent = () => (
      <Instances
        hasMore={hasMore}
        setHasMore={setHasMore}
        keyword={keyword}
        setKeyword={setKeyword}
        showDeleted={showDeleted}
        setShowDeleted={setShowDeleted}
        showSpam={showSpam}
        setShowSpam={setShowSpam}
        isLoading={isLoading}
        renderData={gridData[type]}
        renderList={renderList[type]}
        total={total[type]}
        title={title[type]}
        plus={plus[type]}
        icon={icon[type]}
        modalTitle={modalTitle[type]}
        activePage={activePage}
        setActivePage={setActivePage}
        instancesPerPage={instancesPerPage}
        setInstancesPerPage={setInstancesPerPage}
        skip={skip}
        setSkip={setSkip}
        limit={limit}
        setLimit={setLimit}
        showCards={showCards}
        setShowCards={setShowCards}
      />
    );

    if (type === "chat") {
      return (
        <Conversation
          toggleList={toggleList}
          setToggleList={setToggleList}
          disable={disable}
          setDisable={setDisable}
          setShowHistory={setShowHistory}
        />
      );
    }
    if (
      [
        "agidesk",
        "whatsapp",
        "botmaker",
        "vtex",
        "campaigns",
        "commenttemplates",
      ].includes(type)
    ) {
      return (
        <div className="overflow-y-scroll h-full">
          <div className="container items-center my-auto mx-auto lg:px-44 mt-10 py-4 md:px-12 px-8">
            {instanceComponent()}
          </div>
        </div>
      );
    }
    return instanceComponent();
  };

  const paths: { [key: string]: string } = {
    root: "/",
    chats: "/chat",
    chat: "/chat/:_id",
    contacts: "/settings/contacts",
    contact: "/settings/contact/:_id",
    customers: "/settings/customers",
    customer: "/settings/customer/:_id",
    users: "/settings/users",
    user: "/settings/user/:_id",
    teams: "/settings/teams",
    team: "/settings/team/:_id",
    settings: "/settings",
    general: "/settings/general",
    profile: "/settings/profile",
    officehours: "/settings/officehours",
    officehour: "/settings/officehour/:_id",
    chattags: "/settings/tags/chattags",
    chattag: "/settings/tags/chattag/:_id",
    contacttags: "/settings/tags/contacttags",
    contacttag: "/settings/tags/contacttag/:_id",
    holidays: "/settings/holidays",
    holiday: "/settings/holiday/:_id",
    offlinemessages: "/settings/offlinemessages",
    offlinemessage: "/settings/offlinemessage/:_id",
    integrations: "/settings/integrations",
    agidesks: "/settings/integration/agidesk",
    agidesk: "/settings/integration/agidesk/:_id",
    whatsapps: "/settings/integration/whatsapp",
    whatsapp: "/settings/integration/whatsapp/:_id",
    dashboards: "/dashboards",
    botmakers: "/settings/integration/agibot",
    botmaker: "/settings/integration/agibot/:_id",
    vtexs: "/settings/integration/vtex",
    vtex: "/settings/integration/vtex/:_id",
    privacy: "/privacy",
    publichours: "/schedule/officehours/:tenant",
    surveyresults: "/settings/surveyresults",
    surveys: "/settings/surveys",
    survey: "/settings/survey/:_id",
    surveyratings: "/surveyrating",
    surveyrating: "/surveyrating/:_id",
    campaigns: "/settings/campaigns",
    campaign: "/settings/campaign/:_id",
    campaignbatches: "/settings/campaign/:_id/batches",
    commenttemplates: "/settings/commenttemplates",
    commenttemplate: "/settings/commenttemplate/:_id",
    logout: "/logout",
    others: "/*",
  };
  const elements: { [key: string]: JSX.Element | null } = {
    root: isAuthenticated ? <Navigate to="/chat" /> : <LoginTenantPage />,
    instances: (
      <div className="overflow-y-scroll h-full">
        <InstancesPage
          toggleSidebar={toggleSidebar}
          disable={disable}
          setDisable={setDisable}
        />
      </div>
    ),
    chats: (
      <ChatPage
        showUsersList={showUsersList}
        setShowUsersList={setShowUsersList}
        widgetType={widgetType}
        toggleList={toggleList}
        setToggleList={setToggleList}
        userToggleList={userToggleList}
        setUserToggleList={setUserToggleList}
        showHistory={showHistory}
        setShowHistory={setShowHistory}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
      />
    ),
    chat: getInstanceComponent("chat"),
    contacts: getInstanceComponent("contacts"),
    contact: (
      <div className="overflow-y-scroll h-[calc(100%-47px)]">
        <ContactPage
          toggleSidebar={toggleSidebar}
          disable={disable}
          setDisable={setDisable}
          // icon="las la-building"
          // title="Novo cliente"
        />
      </div>
    ),
    customers: getInstanceComponent("customers"),
    customer: (
      <div className="overflow-y-scroll h-[calc(100%-47px)]">
        <CustomerPage
          toggleSidebar={toggleSidebar}
          disable={disable}
          setDisable={setDisable}
          icon="las la-address-book"
          title="Novo contato"
        />
      </div>
    ),
    users: getInstanceComponent("users"),
    user: (
      <div className="overflow-y-scroll h-[calc(100%-47px)]">
        <UserPage
          toggleSidebar={toggleSidebar}
          disable={disable}
          setDisable={setDisable}
          icon="las la-user-friends"
          title="Nova equipe"
        />
      </div>
    ),
    teams: getInstanceComponent("teams"),
    team: (
      <div className="overflow-y-scroll h-[calc(100%-47px)]">
        <TeamPage
          toggleSidebar={toggleSidebar}
          disable={disable}
          setDisable={setDisable}
          icon="las la-user"
          title="Novo agente"
        />
      </div>
    ),
    settings: (
      <div className="overflow-y-scroll h-[calc(100%-47px)]">
        <SettingsPage toggleSidebar={toggleSidebar} />
      </div>
    ),
    general: (
      <div className="overflow-y-scroll h-[calc(100%-47px)]">
        <GeneralPage
          toggleSidebar={toggleSidebar}
          disable={disable}
          setDisable={setDisable}
        />
      </div>
    ),
    profile: (
      <div className="overflow-y-scroll h-[calc(100%-47px)]">
        <ProfilePage
          toggleSidebar={toggleSidebar}
          disable={disable}
          setDisable={setDisable}
          icon="las la-user-friends"
          title="Nova equipe"
        />
      </div>
    ),
    officehours: getInstanceComponent("officehours"),
    officehour: (
      <div className="overflow-y-scroll h-[calc(100%-47px)]">
        <OfficehourPage
          toggleSidebar={toggleSidebar}
          disable={disable}
          setDisable={setDisable}
          icon="las la-user-friends"
          title="Nova equipe"
        />
      </div>
    ),
    holidays: getInstanceComponent("holidays"),
    holiday: (
      <div className="overflow-y-scroll h-[calc(100%-47px)]">
        <HolidayPage
          toggleSidebar={toggleSidebar}
          disable={disable}
          setDisable={setDisable}
          icon="las la-user-friends"
          title="Nova equipe"
        />
      </div>
    ),
    contacttags: getInstanceComponent("contacttags"),
    chattags: getInstanceComponent("chattags"),
    tag: (
      <div className="overflow-y-scroll h-[calc(100%-47px)]">
        <TagPage
          toggleSidebar={toggleSidebar}
          disable={disable}
          setDisable={setDisable}
        />
      </div>
    ),
    offlinemessages: getInstanceComponent("offlinemessages"),
    offlinemessage: (
      <div className="overflow-y-scroll h-[calc(100%-47px)]">
        <OfflinemessagePage
          toggleSidebar={toggleSidebar}
          disable={disable}
          setDisable={setDisable}
          icon=""
          title=""
        />
      </div>
    ),
    integrations: <IntegrationsPage toggleSidebar={toggleSidebar} />,
    agidesks: getInstanceComponent("agidesk"),
    agidesk: (
      <div className="overflow-y-scroll h-[calc(100%-47px)]">
        <AgideskPage
          toggleSidebar={toggleSidebar}
          disable={disable}
          setDisable={setDisable}
        />
      </div>
    ),
    whatsapps: getInstanceComponent("whatsapp"),
    whatsapp: (
      <div className="overflow-y-scroll h-[calc(100%-47px)]">
        <WhatsappPage
          toggleSidebar={toggleSidebar}
          disable={disable}
          setDisable={setDisable}
          icon="las la-user-friends"
          title="Nova equipe"
        />
      </div>
    ),
    dashboards: (
      <div className="overflow-y-scroll h-[calc(100%-47px)]">
        <DashboardPage toggleSidebar={toggleSidebar} />
      </div>
    ),
    botmakers: getInstanceComponent("botmaker"),
    botmaker: (
      <div className="overflow-y-scroll h-[calc(100%-47px)]">
        <BotmakerPage
          toggleSidebar={toggleSidebar}
          disable={disable}
          setDisable={setDisable}
          icon="las la-user-friends"
          title="Nova equipe"
        />
      </div>
    ),
    vtexs: getInstanceComponent("vtex"),
    vtex: (
      <div className="overflow-y-scroll h-[calc(100%-47px)]">
        <VtexPage
          toggleSidebar={toggleSidebar}
          disable={disable}
          setDisable={setDisable}
          icon="las la-user-friends"
          title="Nova equipe"
        />
      </div>
    ),
    surveyresults: (
      <div className="overflow-y-scroll h-[calc(100%-47px)]">
        <SurveyResultsPage toggleSidebar={toggleSidebar} />
      </div>
    ),
    surveys: getInstanceComponent("surveys"),
    survey: (
      <div className="overflow-y-scroll h-[calc(100%-47px)]">
        <SurveyPage
          toggleSidebar={toggleSidebar}
          disable={disable}
          setDisable={setDisable}
          icon="las la-smile"
          title="Nova pesquisa"
        />
      </div>
    ),
    surveyratings: <SurveyratingPage />,
    surveyrating: <SurveyratingPage />,
    campaigns: getInstanceComponent("campaigns"),
    campaign: <CampaignPage />,
    campaignbatches: <CampaignBatchesPage toggleSidebar={toggleSidebar} />,
    commenttemplates: getInstanceComponent("commenttemplates"),
    commenttemplate: (
      <div className="overflow-y-scroll h-[calc(100%-47px)]">
        <CommenttemplatePage
          toggleSidebar={toggleSidebar}
          disable={disable}
          setDisable={setDisable}
        />
      </div>
    ),
    privacy: <PrivacyPage />,
    publichours: <PublichoursPage />,
    logout: null,
    others: <Navigate to="/chat" />,
  };

  return (
    <BlockUi
      tag="div"
      blocking={isImportingTargets}
      loader={
        <div className="flex justify-center items-center">
          <LoadingDots medium />
        </div>
      }
    >
      <div className="flex bg-background md:flex-row flex-col h-screen transition-height duration-75 ease-out">
        {user?.onboarding === "start" && <OnboardingWizard />}
        <Navbar
          toggleSidebar={toggleSidebar}
          setToggleSidebar={setToggleSidebar}
          setToggleList={setToggleList}
          setShowHistory={setShowHistory}
          setShowFilter={setShowFilter}
          setShowUsersList={setShowUsersList}
        />
        <div
          id="sidemenu"
          className="hidden md:flex h-screen flex-initial z-[200] overflow-y-auto"
        >
          <SideMenu
            navbar={false}
            toggleSidebar={toggleSidebar}
            setToggleSidebar={setToggleSidebar}
            setToggleList={setToggleList}
            setShowHistory={setShowHistory}
            setShowFilter={setShowFilter}
            setShowUsersList={setShowUsersList}
          />
        </div>
        <div className="flex-1 h-screen overflow-y-auto" ref={scrollRef}>
          <div className="h-full flex flex-col flex-1">
            <Routes>
              <Route path={paths.root} element={elements.root} />
              <Route path={paths.chats} element={elements.chats}>
                <Route path={paths.chat} element={elements.chat} />
              </Route>
              <Route element={elements.instances}>
                <Route path={paths.contacts} element={elements.contacts} />
                <Route path={paths.customers} element={elements.customers} />
                <Route path={paths.users} element={elements.users} />
                <Route path={paths.teams} element={elements.teams} />
              </Route>
              <Route path={paths.contact} element={elements.contact} />
              <Route path={paths.customer} element={elements.customer} />
              <Route path={paths.user} element={elements.user} />
              <Route path={paths.team} element={elements.team} />
              {/* {isAdmin ? ( */}
              <Route path={paths.settings} element={elements.settings} />
              {/* ) : null} */}
              {isAdmin ? (
                <Route path={paths.general} element={elements.general} />
              ) : null}
              <Route path={paths.profile} element={elements.profile} />
              <Route path={paths.contacts} element={elements.contacts} />
              <Route path={paths.customers} element={elements.customers} />
              <Route path={paths.users} element={elements.users} />
              <Route path={paths.teams} element={elements.teams} />
              <Route
                path={paths.commenttemplates}
                element={elements.commenttemplates}
              />
              <Route
                path={paths.commenttemplate}
                element={elements.commenttemplate}
              />
              <Route element={elements.instances}>
                <Route path={paths.chattags} element={elements.chattags} />
                <Route
                  path={paths.contacttags}
                  element={elements.contacttags}
                />
              </Route>
              <Route path={paths.chattags} element={elements.chattags} />
              <Route path={paths.contacttags} element={elements.contacttags} />
              <Route path={paths.chattag} element={elements.tag} />
              <Route path={paths.contacttag} element={elements.tag} />
              <Route element={elements.instances}>
                {isAdmin ? (
                  <Route
                    path={paths.officehours}
                    element={elements.officehours}
                  />
                ) : null}
                {isAdmin ? (
                  <Route path={paths.holidays} element={elements.holidays} />
                ) : null}
                {isAdmin ? (
                  <Route
                    path={paths.offlinemessages}
                    element={elements.offlinemessages}
                  />
                ) : null}
              </Route>
              {isAdmin ? (
                <Route path={paths.officehour} element={elements.officehour} />
              ) : null}
              {isAdmin ? (
                <Route path={paths.holiday} element={elements.holiday} />
              ) : null}
              {isAdmin ? (
                <Route
                  path={paths.offlinemessage}
                  element={elements.offlinemessage}
                />
              ) : null}
              {isAdmin ? (
                <Route
                  path={paths.surveyresults}
                  element={elements.surveyresults}
                />
              ) : null}
              <Route element={elements.instances}>
                {isAdmin ? (
                  <Route path={paths.surveys} element={elements.surveys} />
                ) : null}
              </Route>
              {isAdmin ? (
                <Route path={paths.survey} element={elements.survey} />
              ) : null}
              {isAdmin ? (
                <Route path={paths.campaigns} element={elements.campaigns} />
              ) : null}
              <Route element={elements.instances}>
                {isAdmin ? (
                  <Route path={paths.campaign} element={elements.campaign} />
                ) : null}
                {isAdmin ? (
                  <Route
                    path={paths.campaignbatches}
                    element={elements.campaignbatches}
                  />
                ) : null}
              </Route>
              {isAdmin ? (
                <Route
                  path={paths.integrations}
                  element={elements.integrations}
                />
              ) : null}
              {isAdmin ? (
                <Route path={paths.agidesks} element={elements.agidesks} />
              ) : null}
              {isAdmin ? (
                <Route path={paths.agidesk} element={elements.agidesk} />
              ) : null}
              {isAdmin ? (
                <Route path={paths.whatsapps} element={elements.whatsapps} />
              ) : null}
              {isAdmin ? (
                <Route path={paths.whatsapp} element={elements.whatsapp} />
              ) : null}
              <Route path="/dashboards" element={elements.dashboards} />
              {isAdmin && botAllowed() ? (
                <Route path={paths.botmakers} element={elements.botmakers} />
              ) : null}
              {isAdmin && botAllowed() ? (
                <Route path={paths.botmaker} element={elements.botmaker} />
              ) : null}
              {isAdmin && botAllowed() ? (
                <Route path={paths.vtexs} element={elements.vtexs} />
              ) : null}
              {isAdmin && botAllowed() ? (
                <Route path={paths.vtex} element={elements.vtex} />
              ) : null}
              <Route path={paths.privacy} element={elements.privacy} />
              <Route path={paths.publichours} element={elements.publichours} />
              <Route
                path={paths.surveyratings}
                element={elements.surveyratings}
              >
                <Route
                  path={paths.surveyrating}
                  element={elements.surveyrating}
                />
              </Route>
              <Route path={paths.logout} element={elements.logout} />
              <Route path={paths.others} element={elements.others} />
            </Routes>
          </div>
        </div>
      </div>
    </BlockUi>
  );
};

export default LayoutPage;
