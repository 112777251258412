/* eslint-disable react/destructuring-assignment */
import React, { memo } from "react";

import { Form } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

import { IDropdownComponentProps } from "./@types/dropdown.types";
import ActionButton from "./Buttons/ActionButton.component";
import removeDuplicates from "../utils/removeDuplicates";
import useDebounce from "../../hooks/useDebounce";

const Dropdown = (props: IDropdownComponentProps) => {
  const { t } = useTranslation();

  const getDefaultValue = () => {
    if (props.multiple) {
      if (props.defaultValue) return removeDuplicates(props.defaultValue);
      return [];
    }
    return props.defaultValue;
  };

  const debouncedSearch = props.onSearchChange
    ? useDebounce(props.onSearchChange, 800)
    : undefined;

  const dropdownComponent = () => {
    const noResultsMessage = props.loading
      ? "Buscando resultados..."
      : t("no-result-message") || "no-result-message";

    const hasValue =
      typeof props.defaultValue !== "undefined" ||
      typeof props.value === "undefined";

    const label =
      typeof props.gotoClick !== "undefined" ? undefined : props.label;

    const adtionalLabel = () =>
      typeof props.additionLabel !== "undefined" ? (
        <span className="text-blue-link">{`${props.additionLabel} `}</span>
      ) : undefined;

    if (hasValue)
      return (
        <Form.Dropdown
          onClose={props.onBlur}
          clearable={props.clearable}
          key={props.id}
          id={props.id}
          selectOnBlur={false}
          loading={props.loading}
          error={props.error}
          openOnFocus
          noResultsMessage={noResultsMessage}
          className={props.className}
          label={label}
          placeholder={props.placeholder}
          required={props.required}
          fluid={props.fluid}
          search={props.search}
          multiple={props.multiple}
          selection={props.selection}
          options={removeDuplicates(props.options, "key")}
          allowAdditions={props.allowAdditions}
          additionLabel={adtionalLabel()}
          onAddItem={props.onAddItem}
          onChange={props.onChange}
          onSearchChange={debouncedSearch}
          onOpen={props.onOpen}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          upward={props.upward}
          defaultValue={getDefaultValue()}
          disabled={props.disabled}
          width={props.width}
          deburr
          readOnly={props.readOnly}
        />
      );
    return (
      <Form.Dropdown
        onClose={props.onBlur}
        clearable={props.clearable}
        key={props.id}
        id={props.id}
        selectOnBlur={false}
        loading={props.loading}
        error={props.error}
        noResultsMessage={noResultsMessage}
        className={props.className}
        label={label}
        placeholder={props.placeholder}
        required={props.required}
        fluid={props.fluid}
        search={props.search}
        multiple={props.multiple}
        selection={props.selection}
        options={removeDuplicates(props.options, "key")}
        onChange={props.onChange}
        onSearchChange={debouncedSearch}
        onOpen={props.onOpen}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        upward={props.upward}
        value={props.value}
        disabled={props.disabled}
        width={props.width}
        readOnly={props.readOnly}
        deburr
      />
    );
  };

  if (typeof props.gotoClick !== "undefined") {
    return (
      <React.Fragment key={props.id}>
        <label htmlFor={props.id} className="flex">
          {props.label}
          {props.required ? <h2 className="text-red ml-[2px]">*</h2> : null}
        </label>
        <div className="flex w-full">
          {dropdownComponent()}
          <ActionButton
            type="goto"
            title={props.gotoTitle}
            onClick={props.gotoClick}
          />
        </div>
      </React.Fragment>
    );
  }
  return <React.Fragment key={props.id}>{dropdownComponent()}</React.Fragment>;
};

export default memo(Dropdown);
