/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import useAbrevName from "../../../../hooks/useAbrevName";
import {
  IAgideskTeam,
  IAgideskUser,
} from "../../../../slices/externalapps.slice";

const UserAdmin = ({
  teamuser,
  results,
  setResults,
}: {
  teamuser: any;
  results: any[];
  setResults: React.Dispatch<React.SetStateAction<any[]>>;
}) => {
  const [admin, setAdmin] = useState<boolean>(teamuser.admin);
  const [invite, setInvite] = useState<boolean>(teamuser.invite);

  const getIconAdmin = () => {
    if (teamuser.admin) {
      return "las la-check-square";
    }
    return "las la-stop";
  };

  const getIconInvite = () => {
    if (teamuser.invite) {
      return "las la-check-square";
    }
    return "las la-stop";
  };

  const saveAdmin = (checked: boolean) => {
    const _newList: IAgideskTeam[] = [];
    for (const _team of results) {
      if (_team.users && _team.users.length > 0) {
        const _newTeam = { ..._team, users: [] as IAgideskUser[] };
        for (const _user of _team.users) {
          if (_user.id === teamuser.id) {
            const _newUser: IAgideskUser = { ..._user, admin: checked };
            _newTeam.users.push(_newUser);
          } else {
            _newTeam.users.push(_user);
          }
        }
        _newList.push(_newTeam);
      } else {
        _newList.push(_team);
      }
    }

    setResults(_newList);
  };

  const saveInvite = (checked: boolean) => {
    const _newList: IAgideskTeam[] = [];
    for (const _team of results) {
      if (_team.users && _team.users.length > 0) {
        const _newTeam = { ..._team, users: [] as IAgideskUser[] };
        for (const _user of _team.users) {
          if (_user.id === teamuser.id) {
            const _newUser: IAgideskUser = {
              ..._user,
              invite: teamuser.new === true ? checked : false,
            };
            _newTeam.users.push(_newUser);
          } else {
            _newTeam.users.push(_user);
          }
        }
        _newList.push(_newTeam);
      } else {
        _newList.push(_team);
      }
    }

    setResults(_newList);
  };

  return (
    <div
      id={teamuser.id}
      title={teamuser.title}
      className="px-3 flex w-full items-center justify-between rounded-lg mx-auto my-0 h-10 cursor-pointer mb-3"
    >
      <div className="flex items-center w-1/2">
        <div
          key={teamuser.id}
          className="flex items-center justify-start cursor-pointer"
        >
          <div
            title={teamuser.title}
            className="w-8 h-8 bg-agitalks flex items-center justify-center rounded-full border border-agitalks shadow"
          >
            <p className="text-[10px] text-white">
              {useAbrevName(teamuser.title || "")}
            </p>
          </div>
          <div className="ml-2">
            <p className="font-semibold text-[11px]">{teamuser.title}</p>
            <p className="text-gray-999 text-[9px]">{teamuser.teams}</p>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-around w-1/2">
        <div className="flex items-center justify-center w-1/2">
          <i
            role="presentation"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              saveAdmin(!admin);
              setAdmin((prevState) => !prevState);
            }}
            className={`${getIconAdmin()} text-[20px] cursor-pointer mr-2`}
          />
          <label className="mb-[1px] text-[11px]">Sim</label>
        </div>
        <div className="flex items-center justify-center w-1/2">
          {teamuser.new === true ? (
            <>
              <i
                role="presentation"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  saveInvite(!invite);
                  setInvite((prevState) => !prevState);
                }}
                className={`${getIconInvite()} text-[20px] cursor-pointer mr-2`}
              />
              <label className="mb-[1px] text-[11px]">Sim</label>
            </>
          ) : (
            <label className="mb-[1px] text-[11px]">Já tem acesso</label>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserAdmin;
