import React from "react";
import { useAppSelector } from "../../../hooks/redux/hooks";
import LoadingDots from "../../../shared/components/LoadingDots.component";
import logo from "../../../assets/images/logo.png";
import userPhoto from "../../../assets/images/user.png";
import Button from "../../../shared/components/Buttons/Button.component";
import Avatar from "../../../shared/components/Avatar.component";

const WaitingRoom = ({
  setShowModal,
}: {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { waitingTempAgent } = useAppSelector((state) => state.chats);
  // const URL = process.env.REACT_APP_API_BASE_URL;

  return (
    <div className="flex items-center flex-col w-full my-auto">
      <div className="w-full h-full bg-white dark:bg-gray-800 dark:border-gray-700 items-center">
        <img
          className="w-[50%] mx-auto"
          src={logo}
          alt="Agitalks"
          loading="lazy"
        />
        <div className="px-6 pb-6 flex flex-col">
          <span className="text-[22px] text-center">Sala de espera</span>
          <br />
          <LoadingDots className="flex items-center justify-center" />
        </div>

        <div className="flex flex-col items-center pb-10 pt-4">
          {typeof waitingTempAgent?.avatar !== "undefined" &&
          waitingTempAgent.avatar ? (
            <Avatar
              path={waitingTempAgent.avatar?.path}
              name={waitingTempAgent.avatar?.name}
              extraClass="shadow-sm"
              title="Agente virtual"
            />
          ) : (
            // <div
            //   className="shadow-sm w-24 h-24 rounded-full bg-cover"
            //   style={{
            //     backgroundImage: `url(${`${URL}/api/files/${waitingTempAgent?.avatar.path}/${waitingTempAgent?.avatar.name}`})`,
            //   }}
            //   title="Agente virtual"
            // />
            <div className="shadow-sm w-24 h-24 rounded-full flex items-center justify-center bg-gray-333 border-gray-333">
              <img className="avatar" src={userPhoto} alt="Agente virtual" />
            </div>
          )}
          <h5 className="mt-3 mb-1 text-xl font-medium text-gray-900 dark:text-white">
            {waitingTempAgent?.name || "Agente virtual"}
          </h5>
          <span className="text-sm text-gray-500 dark:text-gray-400">
            Agente virtual
          </span>

          <div className="my-4 py-4 px-10 max-w-sm bg-white border border-gray-200 rounded-b-lg rounded-tr-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
            <span className="text-md">
              {waitingTempAgent?.welcomemsg ||
                "Você está na fila de espera, aguarde um momento para ser atendido."}
            </span>
          </div>
          <Button
            minWidth
            label="Cancelar"
            icon="las la-times"
            onClick={() => setShowModal(true)}
            inverted
            red
          />
        </div>
      </div>
    </div>
  );
};

export default WaitingRoom;
