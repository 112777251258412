/* eslint-disable no-case-declarations */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { chatsService, teamsService } from "../services";
import { AsyncState } from "../shared/models/interfaces/asyncstate.interface";
import {
  IChangeStatusUser,
  IUser,
  IChangeUser,
  Users,
  UserStatus,
  RoleUser,
} from "./users.slice";
import { IMessage } from "./messages.slice";

import { ITypingListener } from "../shared/models/interfaces/typinglistener.interface";
import { ITeam } from "./teams.slice";
import { DefaultBot } from "./settings.slice";
import { ddOptions } from "../shared/models/interfaces/dropdownOptions.interface";
import { IFile } from "../shared/models/interfaces/file.interface";
import customToast from "../shared/utils/customToast";
import { IToastType } from "../shared/models/types/Toast.type";
import { ITag } from "./tags.slice";

export enum ChatType {
  INTERNAL = "INTERNAL",
  EXTERNAL = "EXTERNAL",
  WHATSAPP = "WHATSAPP",
  // FACEBOOK = "FACEBOOK",
  BOTMAKER = "BOTMAKER",
}

export enum ChatStatus {
  ACTIVE = "ACTIVE",
  FINISH = "FINISH",
  WAIT = "WAIT",
  WAITREPLY = "WAITREPLY",
  WAITCAMPAIGN = "WAITCAMPAIGN",
  SUSPEND = "SUSPEND",
  CANCELED = "CANCELED",
}

export enum ChatGroupRole {
  ADMIN = "ADMIN",
  READER = "READER",
}

export interface ChatAdmin {
  active?: boolean;
  user?: IUser;
  role?: ChatGroupRole[];
  created_by?: IUser;
  created_at?: string;
  deleted_by?: IUser;
  deleted_at?: string;
  chatname?: string | null;
  chatavatar?: IFile | null;
}

export interface IChat {
  _id?: string;
  avatar?: IFile;
  tenant?: string;
  deleted?: boolean;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
  created_by?: IUser | null;
  updated_by?: IUser | null;
  deleted_by?: IUser | null;
  name?: string;
  groupchat?: boolean;
  offline?: boolean;
  users?: IUser[];
  groupsettings?: ChatAdmin[];
  lastmessage?: IMessage;
  notifications?: number;
  firstnotread?: IMessage | null;
  type?: ChatType;
  team?: ITeam;
  started_at?: string;
  started_by?: IUser;
  istyping?: boolean;
  // isblocked?: boolean;
  isrecordaudio?: boolean;
  whoIsTalking?: string;
  status?: ChatStatus;
  externalapp?: any;
  transferred?: boolean;
  transferredteams?: ITeam[];
  externalcode?: string;
  campaign?: any;
  tags?: ITag[];
}

export interface IChatCard {
  chat: IChat;
  recents?: boolean;
  notifications?: number;
  disabled?: boolean;
  historyMode?: boolean;
  disabledClick?: boolean;
}

export type Chats = IChat[];

export interface INewChat {
  name?: string;
  groupchat?: boolean;
  userId?: string;
  users: string[];
  groupsettings?: ChatAdmin[];
  type: ChatType;
  team?: string | null;
  status: ChatStatus;
  externalapp?: string;
  externalcodephone?: string;
}

export interface ChatTypePayload {
  chat: IChat;
  type: string;
}

export interface ChatReselectPayload {
  chat: IChat;
  prevStatus?: ChatStatus;
  sessionId?: string;
  inactivetime?: boolean;
  agentid?: string;
  widgetContact?: boolean;
  tenant?: string;
  changedTags?: boolean;
}

export interface UpdateLastmessagePayload {
  message: IMessage;
  type: string;
}

export interface IChatFilters {
  teams?: ITeam[];
  users?: IUser[];
  period?: {
    startdate: string;
    enddate: string;
  };
  types?: ChatType[];
}

interface ChatsState extends AsyncState {
  isLoadingChats: boolean;
  isWaitingAgent: boolean;
  isClosing: boolean;
  isTransfering: boolean;
  // blocked: string;
  waitingTempAgent: DefaultBot | null;
  // chats: Chats;
  searchChats: IChat[];
  totalSearchChats: number;
  recentChats: IChat[];
  // totalChats: number;
  backlogChats: IChat[];
  totalBacklogChats: number;
  teamsChats: IChat[];
  totalTeamsChats: number;
  mineChats: IChat[];
  totalMineChats: number;
  selectedChat: IChat | null;
  checkingChatMessages: {
    selectedMessageIDs: string[];
    selectedMessages: IMessage[];
    checking: boolean;
  };
  selectedHistoryContact: ddOptions | null;
  scrolling: boolean;
  searchFilters?: IChatFilters;
  isLoadingFilters?: boolean;
}

const initialState: ChatsState = {
  // chats: [],
  searchChats: [],
  totalSearchChats: 0,
  recentChats: [],
  // totalChats: 0,
  backlogChats: [],
  totalBacklogChats: 0,
  teamsChats: [],
  totalTeamsChats: 0,
  mineChats: [],
  totalMineChats: 0,
  searchFilters: {},
  isLoadingFilters: false,
  isClosing: false,
  isTransfering: false,
  // blocked: "",
  selectedChat: null,
  checkingChatMessages: {
    checking: false,
    selectedMessages: [],
    selectedMessageIDs: [],
  },
  selectedHistoryContact: null,
  isWaitingAgent: true,
  waitingTempAgent: null,
  scrolling: false,
  isLoadingChats: false,
  isSuccess: false,
  isError: false,
};

export interface IFilterProps {
  deleted?: boolean;
  transferred?: boolean;
  groupchat?: boolean;
  name?: string;
  content?: string;
  status?: ChatStatus[];
  type?: ChatType[] | string[];
  types?: ChatType[] | string[];
  users?: string[];
  surveys?: string[];
  customer?: string[];
  team?: string[];
  teams?: string[];
  contacttags?: string[];
  contacts?: string[];
  tags?: string[];
  campaigns?: string[];
  campaignchat?: boolean;
  externalapp?: string[];
  period?: string;
  grouptype?: string;
}

export interface IFilterChat {
  skip: number;
  limit: number;
  filter?: IFilterProps;
  deleted: boolean;
  history?: boolean;
  code?: boolean;
}

export const spamContactChats = createAsyncThunk(
  "chats/spam",
  async (ids: string[], thunkAPI) => {
    try {
      return await chatsService.spamContactChats({ ids });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const inactiveContactChats = createAsyncThunk(
  "chats/inactive",
  async (ids: string[], thunkAPI) => {
    try {
      return await chatsService.inactiveContactChats({ ids });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getSearchChats = createAsyncThunk(
  "chats/search",
  async (filterChat: IFilterChat, thunkAPI) => {
    try {
      return await chatsService.search(filterChat);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAdvancedSearchChats = createAsyncThunk(
  "chats/advancedSearch",
  async (filterChat: IFilterChat, thunkAPI) => {
    try {
      return await chatsService.advancedSearch(filterChat);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const selectChat = createAsyncThunk(
  "chats/selectChat",
  async ({ _id }: { _id: string }, thunkAPI) => {
    try {
      return await chatsService.chat({ _id });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createChat = createAsyncThunk(
  "chats/create",
  async (newChat: INewChat, thunkAPI) => {
    try {
      const _newChat = await chatsService.create(newChat);
      if (_newChat?.team && typeof _newChat.team === "string") {
        const _team = await teamsService.team({
          _id: _newChat.team,
        });
        const _newChatWithTeam = {
          ..._newChat,
          team: _team,
        };
        return _newChatWithTeam;
      }
      return _newChat;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteAll = createAsyncThunk(
  "chats/deleteall",
  async (_, thunkAPI) => {
    try {
      return await chatsService.deleteAll();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateChat = createAsyncThunk(
  "chats/update",
  async (_chat: any, thunkAPI) => {
    try {
      return await chatsService.update({ _chat });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const transferChat = createAsyncThunk(
  "chats/transfer",
  async (
    {
      _id,
      users,
      to,
      team,
      auto,
      status,
    }: {
      _id: string;
      users: string[];
      to?: string;
      team: string;
      auto?: boolean;
      status: ChatStatus;
    },
    thunkAPI
  ) => {
    try {
      return await chatsService.transfer({
        _id,
        users,
        to,
        team,
        auto,
        status,
      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const chatsSlice = createSlice({
  name: "chats",
  initialState,
  reducers: {
    logout() {
      return initialState;
    },
    selectBacklogChats(state, action: PayloadAction<IChat[]>) {
      state.backlogChats = action.payload || [];
    },
    checkingMessages(
      state,
      action: PayloadAction<{
        checking: boolean;
        selectedMessages: IMessage[];
      }>
    ) {
      const _chronologicalOrder = action.payload.selectedMessages.sort(
        (a, b) =>
          Date.parse(a.created_at || "") - Date.parse(b.created_at || "")
      );
      state.checkingChatMessages = {
        ...action.payload,
        selectedMessages: _chronologicalOrder,
        selectedMessageIDs: _chronologicalOrder.map((_msg) => _msg._id),
      };
    },
    selectTotalBacklogChats(state, action: PayloadAction<number>) {
      state.totalBacklogChats = action.payload || 0;
    },
    selectMineChats(state, action: PayloadAction<IChat[]>) {
      state.mineChats = action.payload || [];
    },
    selectTotalMineChats(state, action: PayloadAction<number>) {
      state.totalMineChats = action.payload || 0;
    },
    selectSearchChats(state, action: PayloadAction<IChat[]>) {
      state.searchChats = action.payload || [];
    },
    selectTotalSearchChats(state, action: PayloadAction<number>) {
      state.totalSearchChats = action.payload || 0;
    },
    selectTeamsChats(state, action: PayloadAction<IChat[]>) {
      state.teamsChats = action.payload || [];
    },
    selectTotalTeamsChats(state, action: PayloadAction<number>) {
      state.totalTeamsChats = action.payload || 0;
    },
    reselectChat(state, action: PayloadAction<ChatReselectPayload>) {
      // const isGroupChat = action?.payload?.chat?.groupchat === true;
      const prevStatusIsNotWait =
        typeof action?.payload?.prevStatus !== "undefined" &&
        action?.payload?.prevStatus !== ChatStatus.WAIT;
      // const chatStatusIsSuspend =
      //   typeof action?.payload?.chat?.status !== "undefined" &&
      //   [ChatStatus.SUSPEND].includes(action?.payload?.chat?.status);
      const finishBacklogChatByInactivity =
        typeof action?.payload?.prevStatus !== "undefined" &&
        action?.payload?.prevStatus === ChatStatus.WAIT &&
        typeof action?.payload?.chat?.status !== "undefined" &&
        action?.payload?.chat?.status === ChatStatus.FINISH;
      const usersIncludeMe: boolean =
        (action.payload?.sessionId &&
          action?.payload?.chat?.users?.some(
            (_u) => _u?._id === action?.payload?.sessionId
          )) ||
        false;
      if (
        (prevStatusIsNotWait ||
          // chatStatusIsSuspend || // TODO
          finishBacklogChatByInactivity ||
          usersIncludeMe ||
          typeof action?.payload?.sessionId === "undefined") &&
        typeof state.selectedChat?.status !== "undefined" &&
        state.selectedChat != null &&
        state.selectedChat?.status !== ChatStatus.ACTIVE
      ) {
        state.selectedChat = action.payload.chat;
      }
      if (
        typeof action.payload.widgetContact === "undefined" ||
        !action.payload.widgetContact
      ) {
        // if (isGroupChat && !state.selectedChat) {
        //   state.selectedChat = action.payload.chat;
        // }
        if (action.payload.chat.status === ChatStatus.ACTIVE) {
          state.isWaitingAgent = false;
        }
      }
    },
    changeChat(state, action: PayloadAction<ChatReselectPayload>) {
      const _chatToBeUpdated = action.payload.chat;
      const _changeTags = (_chat: IChat) => {
        const _newTags = _chatToBeUpdated.tags;
        if (typeof _newTags !== "undefined") {
          const _tags: ITag[] = [];
          _newTags.forEach((_tag) => {
            if (
              typeof _tag !== "string" &&
              typeof _tag._id &&
              typeof _tag.name !== "undefined"
            ) {
              _tags.push(_tag);
            } else if (typeof _tag === "string") {
              const _tagFound = _chat.tags?.find(
                (_curTag) => _curTag._id === _tag
              );
              if (typeof _tagFound !== "undefined" && _tagFound) {
                _tags.push(_tagFound);
              }
            }
          });
          return _tags;
        }
        return _chat.tags;
      };
      const newStateBacklog = state.backlogChats.map((_chat: IChat) => {
        if (_chat._id === _chatToBeUpdated._id) {
          const newChat: IChat = {
            ..._chat,
            name: _chatToBeUpdated.name ? _chatToBeUpdated.name : _chat.name,
            avatar: _chatToBeUpdated.avatar
              ? _chatToBeUpdated.avatar
              : _chat.avatar,
            groupchat: _chatToBeUpdated.groupchat
              ? _chatToBeUpdated.groupchat
              : _chat.groupchat,
            offline: _chatToBeUpdated.offline
              ? _chatToBeUpdated.offline
              : _chat.offline,
            groupsettings: _chatToBeUpdated.groupsettings
              ? _chatToBeUpdated.groupsettings
              : _chat.groupsettings,
            users: _chatToBeUpdated.users
              ? _chatToBeUpdated.users
              : _chat.users,
            lastmessage: _chatToBeUpdated.lastmessage
              ? _chatToBeUpdated.lastmessage
              : _chat.lastmessage,
            deleted_at: _chatToBeUpdated.deleted_at
              ? _chatToBeUpdated.deleted_at
              : _chat.deleted_at,
            deleted: _chatToBeUpdated.deleted
              ? _chatToBeUpdated.deleted
              : _chat.deleted,
            notifications: _chatToBeUpdated.notifications
              ? _chatToBeUpdated.notifications
              : _chat.notifications,
            firstnotread: _chatToBeUpdated.firstnotread
              ? _chatToBeUpdated.firstnotread
              : _chat.firstnotread,
            type: _chatToBeUpdated.type ? _chatToBeUpdated.type : _chat.type,
            team: _chatToBeUpdated.team ? _chatToBeUpdated.team : _chat.team,
            created_at: _chatToBeUpdated.created_at
              ? _chatToBeUpdated.created_at
              : _chat.created_at,
            updated_at: _chatToBeUpdated.updated_at
              ? _chatToBeUpdated.updated_at
              : _chat.updated_at,
            updated_by: _chatToBeUpdated.updated_by
              ? _chatToBeUpdated.updated_by
              : _chat.updated_by,
            started_at: _chatToBeUpdated.started_at
              ? _chatToBeUpdated.started_at
              : _chat.started_at,
            started_by: _chatToBeUpdated.started_by
              ? _chatToBeUpdated.started_by
              : _chat.started_by,
            istyping: _chatToBeUpdated.istyping
              ? _chatToBeUpdated.istyping
              : _chat.istyping,
            // isblocked: _chatToBeUpdated.isblocked
            //   ? _chatToBeUpdated.isblocked
            //   : _chat.isblocked,
            isrecordaudio: _chatToBeUpdated.isrecordaudio
              ? _chatToBeUpdated.isrecordaudio
              : _chat.isrecordaudio,
            status: _chatToBeUpdated.status
              ? _chatToBeUpdated.status
              : _chat.status,
            externalapp: _chatToBeUpdated.externalapp
              ? _chatToBeUpdated.externalapp
              : _chat.externalapp,
            transferred: _chatToBeUpdated.transferred
              ? _chatToBeUpdated.transferred
              : _chat.transferred,
            tags: _changeTags(_chat),
          };
          return newChat;
        }
        return _chat;
      });
      let newStateMine = state.mineChats.map((_chat: IChat) => {
        if (_chat._id === _chatToBeUpdated._id) {
          const newChat = {
            ..._chat,
            avatar:
              typeof _chatToBeUpdated.avatar !== "undefined"
                ? _chatToBeUpdated.avatar
                : _chat.avatar,
            name: _chatToBeUpdated.name ? _chatToBeUpdated.name : _chat.name,
            groupchat: _chatToBeUpdated.groupchat
              ? _chatToBeUpdated.groupchat
              : _chat.groupchat,
            offline: _chatToBeUpdated.offline
              ? _chatToBeUpdated.offline
              : _chat.offline,
            groupsettings: _chatToBeUpdated.groupsettings
              ? _chatToBeUpdated.groupsettings
              : _chat.groupsettings,
            users: _chatToBeUpdated.users
              ? _chatToBeUpdated.users
              : _chat.users,
            lastmessage: _chatToBeUpdated.lastmessage
              ? _chatToBeUpdated.lastmessage
              : _chat.lastmessage,
            deleted_at: _chatToBeUpdated.deleted_at
              ? _chatToBeUpdated.deleted_at
              : _chat.deleted_at,
            deleted: _chatToBeUpdated.deleted
              ? _chatToBeUpdated.deleted
              : _chat.deleted,
            notifications: _chatToBeUpdated.notifications
              ? _chatToBeUpdated.notifications
              : _chat.notifications,
            firstnotread: _chatToBeUpdated.firstnotread
              ? _chatToBeUpdated.firstnotread
              : _chat.firstnotread,
            type: _chatToBeUpdated.type ? _chatToBeUpdated.type : _chat.type,
            team: _chatToBeUpdated.team ? _chatToBeUpdated.team : _chat.team,
            created_at: _chatToBeUpdated.created_at
              ? _chatToBeUpdated.created_at
              : _chat.created_at,
            updated_at: _chatToBeUpdated.updated_at
              ? _chatToBeUpdated.updated_at
              : _chat.updated_at,
            updated_by: _chatToBeUpdated.updated_by
              ? _chatToBeUpdated.updated_by
              : _chat.updated_by,
            started_at: _chatToBeUpdated.started_at
              ? _chatToBeUpdated.started_at
              : _chat.started_at,
            started_by: _chatToBeUpdated.started_by
              ? _chatToBeUpdated.started_by
              : _chat.started_by,
            istyping: _chatToBeUpdated.istyping
              ? _chatToBeUpdated.istyping
              : _chat.istyping,
            // isblocked: _chatToBeUpdated.isblocked
            //   ? _chatToBeUpdated.isblocked
            //   : _chat.isblocked,
            isrecordaudio: _chatToBeUpdated.isrecordaudio
              ? _chatToBeUpdated.isrecordaudio
              : _chat.isrecordaudio,
            status: _chatToBeUpdated.status
              ? _chatToBeUpdated.status
              : _chat.status,
            externalapp: _chatToBeUpdated.externalapp
              ? _chatToBeUpdated.externalapp
              : _chat.externalapp,
            transferred: _chatToBeUpdated.transferred
              ? _chatToBeUpdated.transferred
              : _chat.transferred,
            tags: _changeTags(_chat),
          };
          return newChat;
        }
        return _chat;
      });
      const newStateTeams = state.teamsChats.map((_chat: IChat) => {
        if (_chat._id === _chatToBeUpdated._id) {
          const newChat = {
            ..._chat,
            avatar: _chatToBeUpdated.avatar
              ? _chatToBeUpdated.avatar
              : _chat.avatar,
            groupsettings: _chatToBeUpdated.groupsettings
              ? _chatToBeUpdated.groupsettings
              : _chat.groupsettings,
            name: _chatToBeUpdated.name ? _chatToBeUpdated.name : _chat.name,
            groupchat: _chatToBeUpdated.groupchat
              ? _chatToBeUpdated.groupchat
              : _chat.groupchat,
            offline: _chatToBeUpdated.offline
              ? _chatToBeUpdated.offline
              : _chat.offline,
            users: _chatToBeUpdated.users
              ? _chatToBeUpdated.users
              : _chat.users,
            lastmessage: _chatToBeUpdated.lastmessage
              ? _chatToBeUpdated.lastmessage
              : _chat.lastmessage,
            deleted_at: _chatToBeUpdated.deleted_at
              ? _chatToBeUpdated.deleted_at
              : _chat.deleted_at,
            deleted: _chatToBeUpdated.deleted
              ? _chatToBeUpdated.deleted
              : _chat.deleted,
            notifications: _chatToBeUpdated.notifications
              ? _chatToBeUpdated.notifications
              : _chat.notifications,
            firstnotread: _chatToBeUpdated.firstnotread
              ? _chatToBeUpdated.firstnotread
              : _chat.firstnotread,
            type: _chatToBeUpdated.type ? _chatToBeUpdated.type : _chat.type,
            team: _chatToBeUpdated.team ? _chatToBeUpdated.team : _chat.team,
            created_at: _chatToBeUpdated.created_at
              ? _chatToBeUpdated.created_at
              : _chat.created_at,
            updated_at: _chatToBeUpdated.updated_at
              ? _chatToBeUpdated.updated_at
              : _chat.updated_at,
            updated_by: _chatToBeUpdated.updated_by
              ? _chatToBeUpdated.updated_by
              : _chat.updated_by,
            started_at: _chatToBeUpdated.started_at
              ? _chatToBeUpdated.started_at
              : _chat.started_at,
            started_by: _chatToBeUpdated.started_by
              ? _chatToBeUpdated.started_by
              : _chat.started_by,
            istyping: _chatToBeUpdated.istyping
              ? _chatToBeUpdated.istyping
              : _chat.istyping,
            // isblocked: _chatToBeUpdated.isblocked
            //   ? _chatToBeUpdated.isblocked
            //   : _chat.isblocked,
            isrecordaudio: _chatToBeUpdated.isrecordaudio
              ? _chatToBeUpdated.isrecordaudio
              : _chat.isrecordaudio,
            status: _chatToBeUpdated.status
              ? _chatToBeUpdated.status
              : _chat.status,
            externalapp: _chatToBeUpdated.externalapp
              ? _chatToBeUpdated.externalapp
              : _chat.externalapp,
            transferred: _chatToBeUpdated.transferred
              ? _chatToBeUpdated.transferred
              : _chat.transferred,
            tags: _changeTags(_chat),
          };
          return newChat;
        }
        return _chat;
      });
      if (
        state.selectedChat?._id &&
        action.payload.chat._id === state.selectedChat._id
      ) {
        if (
          newStateMine.filter((_c) => _c._id === state.selectedChat?._id)
            .length > 0
        ) {
          const _newSelected = newStateMine.filter(
            (_c) => _c._id === state.selectedChat?._id
          )[0];
          state.selectedChat = {
            ...state.selectedChat,
            ..._newSelected,
            tags: _changeTags(state.selectedChat),
          };
        } else if (
          newStateBacklog.filter((_c) => _c._id === state.selectedChat?._id)
            .length > 0
        ) {
          const _newSelected = newStateBacklog.filter(
            (_c) => _c._id === state.selectedChat?._id
          )[0];
          state.selectedChat = {
            ...state.selectedChat,
            ..._newSelected,
            tags: _changeTags(state.selectedChat),
          };
        }
      }
      if (
        typeof action.payload.widgetContact === "undefined" ||
        !action.payload.widgetContact
      ) {
        if (action.payload.chat.status === ChatStatus.ACTIVE) {
          state.isWaitingAgent = false;
        }
      }

      if (_chatToBeUpdated.status === ChatStatus.FINISH) {
        const existsInMine = newStateMine?.some(
          (mineChat) => mineChat?._id === _chatToBeUpdated?._id
        );
        if (existsInMine) {
          newStateMine = newStateMine?.filter(
            (mineChat) => mineChat?._id !== _chatToBeUpdated?._id
          );
          state.totalMineChats -= 1;
        }
      }

      state.backlogChats = newStateBacklog;
      state.mineChats = newStateMine;
      state.teamsChats = newStateTeams;
    },
    selectHistoyContact(state, action: PayloadAction<ddOptions | null>) {
      state.selectedHistoryContact = action.payload;
    },
    openChat(state, action: PayloadAction<IChat>) {
      state.selectedChat = action.payload;
      // state.isClosing = false;
    },
    chatIsScrolling(state, action: PayloadAction<boolean>) {
      state.scrolling = action.payload;
    },
    chatIsTransfering(state, action: PayloadAction<boolean>) {
      state.isTransfering = action.payload;
    },
    // chatIsBlocked(state, action: PayloadAction<string>) {
    //   console.log(action.payload)
    //   state.blocked = action.payload;
    // },
    closeWidget(state, action: PayloadAction<boolean>) {
      state.isClosing = action.payload;
    },
    closeChat(state) {
      state.selectedChat = null;
      state.scrolling = false;
      state.checkingChatMessages.checking = false;
      state.checkingChatMessages.selectedMessageIDs = [];
      state.checkingChatMessages.selectedMessages = [];
      // state.isClosing = true;
    },
    addChats(state, action: PayloadAction<ChatTypePayload>) {
      let newState = [];
      const _newChat = action.payload.chat;
      if (action.payload.type === "backlog") {
        const existsInBacklog = state.backlogChats.indexOf(_newChat) > -1;
        if (!existsInBacklog) {
          newState = state.backlogChats;
          newState.push(_newChat);
          // state.backlogChats = removeDuplicates(newState, "_id");
          state.backlogChats = newState;
          state.totalBacklogChats += 1;
        }
      } else {
        const existsInMine = state.mineChats.indexOf(_newChat) > -1;
        if (!existsInMine) {
          newState = state.mineChats;
          newState.unshift(_newChat);
          // state.mineChats = removeDuplicates(newState, "_id");
          state.mineChats = newState;
          state.totalMineChats += 1;
        }
      }
    },
    addToRecents(state, action: PayloadAction<IChat>) {
      if (state.recentChats.length === 0)
        window.parent.postMessage("toggleRecent", "*");
      const existsInRecents = state.recentChats.indexOf(action.payload) > -1;
      if (!existsInRecents) {
        if (state.recentChats.length >= 7) state.recentChats.pop();
        state.recentChats.unshift(action.payload);
      }
    },
    removeFromBacklog(state, action: PayloadAction<ChatReselectPayload>) {
      const chatIsOpened: boolean =
        action?.payload?.chat?._id === state?.selectedChat?._id;
      const usersIncludeMe: boolean =
        (action?.payload?.sessionId &&
          action?.payload?.chat?.users?.some(
            (_u) => _u?._id === action?.payload?.sessionId
          )) ||
        false;
      const agent: IUser | undefined = action?.payload?.chat?.users?.find(
        (_u) => !_u?.roles?.includes(RoleUser.CONTACT)
      );
      const existsInBacklog = state?.backlogChats?.some(
        (backlogChat) => backlogChat?._id === action?.payload?.chat?._id
      );
      if (existsInBacklog) {
        state.backlogChats = state?.backlogChats?.filter(
          (backlogChat) => backlogChat?._id !== action?.payload?.chat?._id
        );
        state.totalBacklogChats -= 1;
        if (usersIncludeMe) {
          const _mineChats = state?.mineChats;
          _mineChats?.unshift(action?.payload?.chat);
          state.mineChats = _mineChats;
          state.totalMineChats += 1;
        }
        if (chatIsOpened && !usersIncludeMe) {
          state.selectedChat = null;
          if (typeof agent?.name !== "undefined") {
            customToast({
              type: IToastType.WARNING,
              message: `${agent?.name} já iniciou esta conversa.`,
            });
          }
        }
      }
    },
    removeFromMine(state, action: PayloadAction<IChat>) {
      const existsInMine = state?.mineChats?.some(
        (mineChat) => mineChat?._id === action?.payload?._id
      );
      if (existsInMine) {
        state.mineChats = state?.mineChats?.filter(
          (mineChat) => mineChat?._id !== action?.payload?._id
        );
        state.totalMineChats -= 1;
      }
    },
    removeFromRecents(state, action: PayloadAction<IChat>) {
      const existsInRecents = state?.recentChats?.some(
        (recents) => recents?._id === action?.payload?._id
      );
      if (existsInRecents) {
        state.recentChats =
          state?.recentChats?.filter(
            (recentChat) => recentChat?._id !== action?.payload?._id
          ) || [];
        if (state?.recentChats?.length === 0)
          window.parent.postMessage("toggleRecent", "*");
      }
    },
    clearRecents(state) {
      state.recentChats = initialState.recentChats;
    },
    readyToChat(state) {
      state.isWaitingAgent = false;
    },
    saveWaitingRoomTempAgent(state, action: PayloadAction<DefaultBot>) {
      state.waitingTempAgent = action.payload;
    },
    first(state, action: PayloadAction<ChatTypePayload>) {
      let newState: Chats = [];
      if (action.payload.type === "backlog") {
        newState = state.backlogChats;
      } else if (action.payload.type === "mine") {
        newState = state.mineChats;
      } else if (action.payload.type === "teams") {
        newState = state.teamsChats;
      }
      const foundIdx = newState.findIndex(
        (el: IChat) => el._id === action.payload.chat._id
      );
      if (foundIdx > -1) {
        const itemToFind = newState.splice(foundIdx, 1)[0];
        newState.unshift(itemToFind);
        if (action.payload.type === "backlog") {
          state.backlogChats = newState;
        } else if (action.payload.type === "mine") {
          state.mineChats = newState;
        } else {
          state.teamsChats = newState;
        }
      }
    },
    clearCancelNotifications(state, action: PayloadAction<IChat>) {
      state.backlogChats.map((chat: IChat) => {
        if (chat._id === action.payload._id) {
          if (state.selectedChat && chat._id === state.selectedChat._id) {
            state.selectedChat.firstnotread = null;
            state.selectedChat.notifications = 0;
          }
          chat.firstnotread = null;
          chat.notifications = 0;
          return true;
        }
        return false;
      });
    },
    clearNotifications(state, action: PayloadAction<string>) {
      if (action.payload === "backlog") {
        state.backlogChats.map((chat: IChat) => {
          if (state.selectedChat && chat._id === state.selectedChat._id) {
            state.selectedChat.firstnotread = null;
            chat.firstnotread = null;
            state.selectedChat.notifications = 0;
            chat.notifications = 0;
            return true;
          }
          return false;
        });
      }
      if (action.payload === "mine") {
        state.mineChats.map((chat: IChat) => {
          if (state.selectedChat && chat._id === state.selectedChat._id) {
            state.selectedChat.firstnotread = null;
            chat.firstnotread = null;
            state.selectedChat.notifications = 0;
            chat.notifications = 0;
            return true;
          }
          return false;
        });
      }
      if (action.payload === "teams") {
        state.teamsChats.map((chat: IChat) => {
          if (chat._id === state.selectedChat?._id) {
            chat.firstnotread = null;
            chat.notifications = 0;
            return true;
          }
          return false;
        });
      }
      state.recentChats.map((chat: IChat) => {
        if (chat._id === state.selectedChat?._id) {
          chat.firstnotread = null;
          chat.notifications = 0;
          return true;
        }
        return false;
      });
    },
    updateLastmessage(state, action: PayloadAction<UpdateLastmessagePayload>) {
      let newState: Chats = [];
      if (action.payload.type === "backlog") {
        newState = state.backlogChats;
      } else if (action.payload.type === "mine") {
        newState = state.mineChats;
      }
      if (action.payload.type === "backlog") {
        for (const chat of newState) {
          if (chat._id === action.payload.message.chat._id) {
            chat.lastmessage = action.payload.message;
            chat.status = action.payload.message.chat.status;
            if (chat._id === state.selectedChat?._id) {
              state.selectedChat = {
                ...state.selectedChat,
                lastmessage: action.payload.message,
              };
            }
          }
        }
        state.backlogChats = newState;
      }
      if (action.payload.type === "mine") {
        for (const chat of newState) {
          if (chat._id === action.payload.message.chat._id) {
            chat.lastmessage = action.payload.message;
            chat.status = action.payload.message.chat.status;
            if (chat._id === state.selectedChat?._id) {
              state.selectedChat = {
                ...state.selectedChat,
                lastmessage: action.payload.message,
                status: action.payload.message.chat.status,
              };
            }
          }
        }
        state.mineChats = newState;
      }
      const _recents = state.recentChats;
      for (const chat of _recents) {
        if (chat._id === action.payload.message.chat._id) {
          chat.lastmessage = action.payload.message;
          chat.status = action.payload.message.chat.status;
        }
        state.recentChats = _recents;
      }
    },
    updateLastmessageWithNotification(
      state,
      action: PayloadAction<UpdateLastmessagePayload>
    ) {
      let newState: Chats = [];
      if (action.payload.type === "backlog") {
        newState = state.backlogChats;
      } else if (action.payload.type === "mine") {
        newState = state.mineChats;
      }
      if (action.payload.type === "backlog") {
        for (const chat of newState) {
          if (chat._id === action.payload.message.chat._id) {
            chat.lastmessage = action.payload.message;
            if (
              (typeof chat?.firstnotread === "undefined" ||
                chat.firstnotread === null) &&
              action.payload.message.read === false
            ) {
              chat.firstnotread = action.payload.message;
            }
            if (chat.notifications) chat.notifications += 1;
            else chat.notifications = 1;
            if (chat._id === state.selectedChat?._id) {
              state.selectedChat = {
                ...state.selectedChat,
                ...action.payload.message.chat,
              };
            }
            state.backlogChats = newState;
          }
        }
      }
      if (action.payload.type === "mine") {
        for (const chat of newState) {
          if (chat._id === action.payload.message.chat._id) {
            chat.lastmessage = action.payload.message;
            if (
              (typeof chat?.firstnotread === "undefined" ||
                chat.firstnotread === null) &&
              action.payload.message.read === false
            ) {
              chat.firstnotread = action.payload.message;
            }
            if (chat.notifications) chat.notifications += 1;
            else chat.notifications = 1;
            if (chat._id === state.selectedChat?._id) {
              state.selectedChat = {
                ...state.selectedChat,
                ...action.payload.message.chat,
              };
            }
            state.mineChats = newState;
          }
        }
      }
      const _recents = state.recentChats;
      for (const chat of _recents) {
        if (chat._id === action.payload.message.chat._id) {
          chat.lastmessage = action.payload.message;
          if (
            typeof chat?.firstnotread === "undefined" ||
            chat.firstnotread === null
          )
            chat.firstnotread = action.payload.message;
          if (chat.notifications) chat.notifications += 1;
          else chat.notifications = 1;
        }
        state.recentChats = _recents;
      }
    },
    changeUserChat(state, action: PayloadAction<IChangeUser>) {
      const _userToBeUpdated: IChangeUser = action.payload;
      const updateState = (users: Users) => {
        const _newState = users.map((user) => {
          if (user._id === _userToBeUpdated._id)
            return {
              ...user,
              spam: _userToBeUpdated.spam,
              avatar: _userToBeUpdated.avatar,
              tags: _userToBeUpdated?.tags,
              code: _userToBeUpdated?.code || undefined,
              name: _userToBeUpdated.name || "",
              phone: _userToBeUpdated.phone,
              updated_at: _userToBeUpdated.updated_at,
            };
          return user;
        });
        return _newState;
      };

      const updateGroupsettingsState = (gs: ChatAdmin[]) => {
        const _newState = gs.map((_gs) => {
          if (_gs?.user?._id === _userToBeUpdated._id) {
            return {
              ..._gs,
              user: {
                ..._gs.user,
                avatar: _userToBeUpdated.avatar,
                tags: _userToBeUpdated?.tags,
                name: _userToBeUpdated.name || "",
                phone: _userToBeUpdated.phone,
              },
            };
          }
          return _gs;
        });
        return _newState;
      };

      const newStateMine = state.mineChats.map((chat) => {
        // if (chat.lastmessage) {
        chat.users = updateState(chat.users || []);
        chat.groupsettings = updateGroupsettingsState(chat.groupsettings || []);
        if (chat._id === state.selectedChat?._id)
          state.selectedChat = { ...state.selectedChat, ...chat };
        // }
        return chat;
      });
      const _newStateRecentsMine = state.recentChats.map((chat) => {
        // if (chat.lastmessage) {
        chat.users = updateState(chat.users || []);
        chat.groupsettings = updateGroupsettingsState(chat.groupsettings || []);
        if (chat._id === state.selectedChat?._id)
          state.selectedChat = { ...state.selectedChat, ...chat };
        // }
        return chat;
      });
      const _newStateSearch = state.searchChats.map((chat) => {
        // if (chat.lastmessage) {
        chat.users = updateState(chat.users || []);
        // chat.groupsettings = updateGroupsettingsState(chat.groupsettings || []);
        if (chat._id === state.selectedChat?._id)
          state.selectedChat = { ...state.selectedChat, ...chat };
        // }
        return chat;
      });
      state.mineChats = newStateMine;
      state.recentChats = _newStateRecentsMine;
      state.searchChats = _newStateSearch;
    },
    changeStatusChat(state, action: PayloadAction<IChangeStatusUser>) {
      const _userToBeUpdated: IChangeStatusUser = action.payload;
      const updateState = (users: Users) => {
        const _newState = users.map((user) => {
          if (user._id === _userToBeUpdated._id)
            return {
              ...user,
              status: _userToBeUpdated.status || UserStatus.OFFLINE,
              updated_at: _userToBeUpdated.updated_at,
            };
          return user;
        });
        return _newState;
      };

      const updateGroupsettingsState = (gs: ChatAdmin[]) => {
        const _newState = gs.map((_gs) => {
          if (_gs?.user?._id === _userToBeUpdated._id) {
            return {
              ..._gs,
              user: {
                ..._gs.user,
                status: _userToBeUpdated.status || UserStatus.OFFLINE,
              },
            };
          }
          return _gs;
        });
        return _newState;
      };

      const newStateMine = state.mineChats.map((chat) => {
        // if (chat.lastmessage) {
        chat.users = updateState(chat.users || []);
        chat.groupsettings = updateGroupsettingsState(chat.groupsettings || []);
        if (chat._id === state.selectedChat?._id)
          state.selectedChat = { ...state.selectedChat, ...chat };
        // }
        return chat;
      });
      const _newStateRecentsMine = state.recentChats.map((chat) => {
        // if (chat.lastmessage) {
        chat.users = updateState(chat.users || []);
        chat.groupsettings = updateGroupsettingsState(chat.groupsettings || []);
        if (chat._id === state.selectedChat?._id)
          state.selectedChat = { ...state.selectedChat, ...chat };
        // }
        return chat;
      });
      state.mineChats = newStateMine;
      state.recentChats = _newStateRecentsMine;
    },
    // startBlock(state, action: PayloadAction<any>){

    //     if(state.selectedChat?._id == action.payload.chatid){
    //       if(state.selectedChat && typeof state.selectedChat?._id != 'undefined')
    //         {
    //           console.log("vai blouqear")

    //         state.selectedChat.isblocked = action.payload.block;
    //     }
    //   }
    // },
    startTyping(state, action: PayloadAction<ITypingListener>) {
      const _backlog = state.backlogChats;
      const _mine = state.mineChats;
      const _teams = state.teamsChats;
      const _recents = state.recentChats;

      const chatTypingBacklog = _backlog.find(
        (c: IChat) => c._id === action.payload._id
      );
      if (chatTypingBacklog?._id) {
        if (action.payload.type === "text") chatTypingBacklog.istyping = true;
        if (action.payload.type === "audio")
          chatTypingBacklog.isrecordaudio = true;
        if (
          typeof action.payload.sender !== "undefined" &&
          chatTypingBacklog?.groupchat
        ) {
          const userInGroup = chatTypingBacklog?.users?.find(
            (_gs) => _gs._id === action.payload.sender
          );
          chatTypingBacklog.whoIsTalking = userInGroup?.name;
        }
      }

      const chatTypingMine = _mine.find(
        (c: IChat) => c._id === action.payload._id
      );
      if (chatTypingMine?._id) {
        if (action.payload.type === "text") chatTypingMine.istyping = true;
        if (action.payload.type === "audio")
          chatTypingMine.isrecordaudio = true;
        if (
          typeof action.payload.sender !== "undefined" &&
          chatTypingMine?.groupchat
        ) {
          const userInGroup = chatTypingMine?.users?.find(
            (_gs) => _gs._id === action.payload.sender
          );
          chatTypingMine.whoIsTalking = userInGroup?.name;
        }
      }

      const chatTypingTeams = _teams.find(
        (c: IChat) => c._id === action.payload._id
      );
      if (chatTypingTeams?._id) {
        if (action.payload.type === "text") chatTypingTeams.istyping = true;
        if (action.payload.type === "audio")
          chatTypingTeams.isrecordaudio = true;
        if (
          typeof action.payload.sender !== "undefined" &&
          chatTypingTeams?.groupchat
        ) {
          const userInGroup = chatTypingTeams?.users?.find(
            (_gs) => _gs._id === action.payload.sender
          );
          chatTypingTeams.whoIsTalking = userInGroup?.name;
        }
      }

      const chatTypingRecents = _recents.find(
        (c: IChat) => c._id === action.payload._id
      );
      if (chatTypingRecents?._id) {
        if (action.payload.type === "text") chatTypingRecents.istyping = true;
        if (action.payload.type === "audio")
          chatTypingRecents.isrecordaudio = true;
        if (
          typeof action.payload.sender !== "undefined" &&
          chatTypingRecents?.groupchat
        ) {
          const userInGroup = chatTypingRecents?.users?.find(
            (_gs) => _gs._id === action.payload.sender
          );
          chatTypingRecents.whoIsTalking = userInGroup?.name;
        }
      }
    },
    stopTyping(state, action: PayloadAction<ITypingListener>) {
      const chatTypingBacklog = state.backlogChats.find(
        (c: IChat) => c._id === action.payload._id
      );
      if (chatTypingBacklog) {
        if (action.payload.type === "text") chatTypingBacklog.istyping = false;
        if (action.payload.type === "audio")
          chatTypingBacklog.isrecordaudio = false;
        if (chatTypingBacklog?.groupchat) {
          chatTypingBacklog.whoIsTalking = undefined;
        }
      }

      const chatTypingMine = state.mineChats.find(
        (c: IChat) => c._id === action.payload._id
      );
      if (chatTypingMine) {
        if (action.payload.type === "text") chatTypingMine.istyping = false;
        if (action.payload.type === "audio")
          chatTypingMine.isrecordaudio = false;
        if (chatTypingMine?.groupchat) {
          chatTypingMine.whoIsTalking = undefined;
        }
      }

      const chatTypingTeams = state.teamsChats.find(
        (c: IChat) => c._id === action.payload._id
      );
      if (chatTypingTeams) {
        if (action.payload.type === "text") chatTypingTeams.istyping = false;
        if (action.payload.type === "audio")
          chatTypingTeams.isrecordaudio = false;
        if (chatTypingTeams?.groupchat) {
          chatTypingTeams.whoIsTalking = undefined;
        }
      }

      const chatTypingRecents = state.recentChats.find(
        (c: IChat) => c._id === action.payload._id
      );
      if (chatTypingRecents) {
        if (action.payload.type === "text") chatTypingRecents.istyping = false;
        if (action.payload.type === "audio")
          chatTypingRecents.isrecordaudio = false;
        if (chatTypingRecents?.groupchat) {
          chatTypingRecents.whoIsTalking = undefined;
        }
      }
    },
    // blockChat(
    //   state,
    //   action: PayloadAction<{ chat: IChat; block: boolean }>
    // ) {
    //   //
    // },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAdvancedSearchChats.pending, (state, action) => {
        if (action.meta.arg.limit === 10) {
          state.isLoadingChats = true;
        }
      })
      .addCase(getAdvancedSearchChats.fulfilled, (state) => {
        state.isLoadingChats = false;
        state.isSuccess = true;
      })
      .addCase(getAdvancedSearchChats.rejected, (state) => {
        state.isLoadingChats = false;
        state.isError = true;
      })
      // CHATS
      .addCase(getSearchChats.pending, (state, action) => {
        if (action.meta.arg.limit === 10) {
          state.isLoadingChats = true;
        }
      })
      .addCase(getSearchChats.fulfilled, (state, action) => {
        try {
          const validChats = action.payload.results;
          // const validChatsCount = validChats.length;
          const requestType = action.meta.arg.filter?.grouptype;
          if (validChats.length > 0) {
            switch (requestType) {
              case "me":
                const _validMeChats =
                  typeof validChats !== "undefined" && validChats.length > 0
                    ? [...validChats]?.sort((a: IChat, b: IChat) =>
                        (b?.lastmessage?.created_at || b?.updated_at || "") >
                        (a?.lastmessage?.created_at || a?.updated_at || "")
                          ? 1
                          : (a?.lastmessage?.created_at ||
                              a?.updated_at ||
                              "") >
                            (b?.lastmessage?.created_at || b?.updated_at || "")
                          ? -1
                          : 0
                      )
                    : [];
                state.mineChats = _validMeChats;
                // state.totalMineChats = validChatsCount;
                break;
              case "backlog":
                const _validBacklogChats =
                  typeof validChats !== "undefined" && validChats.length > 0
                    ? [...validChats]?.sort((a: IChat, b: IChat) =>
                        (b?.created_at || "") > (a?.created_at || "")
                          ? -1
                          : (a?.created_at || "") > (b?.created_at || "")
                          ? 1
                          : 0
                      )
                    : [];
                state.backlogChats = _validBacklogChats;
                // .sort((a: IChat, b: IChat) =>
                //   (b?.lastmessage?.created_at || b?.updated_at || "") >
                //   (a?.lastmessage?.created_at || a?.updated_at || "")
                //     ? 1
                //     : (a?.lastmessage?.created_at || a?.updated_at || "") >
                //       (b?.lastmessage?.created_at || b?.updated_at || "")
                //     ? -1
                //     : 0
                // );
                // state.totalBacklogChats = validChatsCount;
                break;
              case "teams":
                const _validTeamsChats =
                  typeof validChats !== "undefined" && validChats.length > 0
                    ? [...validChats]?.sort((a: IChat, b: IChat) =>
                        (b?.lastmessage?.created_at || b?.updated_at || "") >
                        (a?.lastmessage?.created_at || a?.updated_at || "")
                          ? 1
                          : (a?.lastmessage?.created_at ||
                              a?.updated_at ||
                              "") >
                            (b?.lastmessage?.created_at || b?.updated_at || "")
                          ? -1
                          : 0
                      )
                    : [];
                state.teamsChats = _validTeamsChats;
                // state.totalTeamsChats = validChatsCount;
                break;
              default:
                const _validChats =
                  typeof validChats !== "undefined" && validChats.length > 0
                    ? [...validChats]?.sort((a: IChat, b: IChat) =>
                        (b?.lastmessage?.created_at || b?.updated_at || "") >
                        (a?.lastmessage?.created_at || a?.updated_at || "")
                          ? 1
                          : (a?.lastmessage?.created_at ||
                              a?.updated_at ||
                              "") >
                            (b?.lastmessage?.created_at || b?.updated_at || "")
                          ? -1
                          : 0
                      )
                    : [];
                state.mineChats = _validChats;
                // state.totalMineChats = validChatsCount;
                break;
            }
          }
          state.isSuccess = true;
          state.isLoadingChats = false;
        } catch (error) {
          // console.log(error);
        }
      })
      .addCase(getSearchChats.rejected, (state) => {
        state.isError = true;
        // state.chats = [];
        state.isLoadingChats = false;
      })
      // UPDATE
      .addCase(updateChat.pending, () => {
        // state.isLoadingChats = true;
      })
      .addCase(updateChat.fulfilled, (state) => {
        state.isSuccess = true;
        state.isLoadingChats = false;
      })
      .addCase(updateChat.rejected, (state) => {
        state.isError = true;
        state.selectedChat = {};
        state.isLoadingChats = false;
      })
      // TRANSFER
      .addCase(transferChat.pending, (state) => {
        state.isLoadingChats = true;
      })
      .addCase(transferChat.fulfilled, (state) => {
        state.isSuccess = true;
        state.isLoadingChats = false;
      })
      .addCase(transferChat.rejected, (state) => {
        state.isError = true;
        state.selectedChat = {};
        state.isLoadingChats = false;
      })
      // SHOW USER
      .addCase(selectChat.pending, (state) => {
        state.isLoadingChats = true;
      })
      .addCase(selectChat.fulfilled, (state, action) => {
        state.isSuccess = true;
        if (action.payload) {
          state.selectedChat = action.payload || {};
        }
        state.isLoadingChats = false;
      })
      .addCase(selectChat.rejected, (state) => {
        state.isError = true;
        state.selectedChat = {};
        state.isLoadingChats = false;
      })
      // CREATE
      .addCase(createChat.pending, (state) => {
        state.isLoadingChats = true;
      })
      .addCase(createChat.fulfilled, (state, action) => {
        state.isSuccess = true;
        if (action.payload !== null) {
          const newState = state.mineChats;
          const foundIdx = newState.findIndex(
            (el: IChat) => el._id === action.payload?._id
          );
          if (foundIdx > -1) {
            const itemToFind = newState.splice(foundIdx, 1)[0];
            newState.unshift(itemToFind);
            state.selectedChat = itemToFind;
          } else {
            newState.unshift(action.payload);
            state.selectedChat = action.payload;
            state.totalMineChats += 1;
          }
          state.mineChats = newState;
          if (
            [ChatStatus.ACTIVE, ChatStatus.WAITREPLY].includes(
              action.meta.arg.status
            )
          ) {
            state.isWaitingAgent = false;
          }
        }
        state.isLoadingChats = false;
      })
      .addCase(createChat.rejected, (state) => {
        state.isError = true;
        state.isLoadingChats = false;
      })
      // DELETE
      .addCase(deleteAll.pending, (state) => {
        state.isLoadingChats = true;
      })
      .addCase(deleteAll.fulfilled, (state) => {
        state.isSuccess = true;
        // state.chats = [];
        state.mineChats = [];
        state.backlogChats = [];
        state.teamsChats = [];
        state.selectedChat = {};
        state.isLoadingChats = false;
      })
      .addCase(deleteAll.rejected, (state) => {
        state.isError = true;
        state.isLoadingChats = false;
      });
  },
});

export default chatsSlice.reducer;
export const {
  addChats,
  first: chatFirst,
  checkingMessages,
  selectHistoyContact,
  reselectChat,
  changeStatusChat,
  changeChat,
  startTyping,
  stopTyping,
  openChat,
  closeChat,
  closeWidget,
  logout,
  clearCancelNotifications,
  clearNotifications,
  changeUserChat,
  chatIsScrolling,
  chatIsTransfering,
  addToRecents,
  removeFromRecents,
  clearRecents,
  readyToChat,
  saveWaitingRoomTempAgent,
  removeFromBacklog,
  removeFromMine,
  selectBacklogChats,
  selectMineChats,
  selectTeamsChats,
  selectTotalBacklogChats,
  selectTotalMineChats,
  selectTotalTeamsChats,
  selectSearchChats,
  selectTotalSearchChats,
  // blockChat,
  // chatIsBlocked
} = chatsSlice.actions;
