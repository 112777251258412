/* eslint-disable no-shadow */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AsyncState } from "../shared/models/interfaces/asyncstate.interface";
import { IUser } from "./users.slice";
import campaignsService from "../services/campaigns.service";
import customToast from "../shared/utils/customToast";
import { IToastType } from "../shared/models/types/Toast.type";
import campaignBatchesService from "../services/campaignBatches.service";
import { IFile } from "../shared/models/interfaces/file.interface";
import { CampaignBatchStatus } from "./campaignbatches.slice";
import { IChat } from "./chats.slice";

export enum CampaignType {
  WHATSAPP = "WHATSAPP",
  SURVEY = "SURVEY",
}

export interface ICampaign {
  _id?: string;
  name?: string;
  active?: boolean;
  type?: CampaignType;
  externalapp?: any;
  template?: string | null;
  templatecontent?: string | null;
  templatejson?: string | null;
  templatevars?: string | null;
  templatefiles?: string[];
  templatephone?: string;
  user?: any;
  team?: any;
  sendemail?: boolean;
  email?: string;
  // maxsend?: number;
  createchat?: boolean;
  deleted?: boolean;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
  created_by?: IUser | null;
  updated_by?: IUser | null;
  deleted_by?: IUser | null;
}

export interface ITarget {
  _id?: string;
  name?: string;
  email?: string;
  avatar?: IFile;
  phone?: string;
  customers?: object[];
  targets?: object[];
  chat?: IChat;
  status?: string;
}

export interface ICampaignTarget {
  _id?: string;
  campaign?: {
    _id?: string;
    name?: string;
  };
  target?: ITarget[];
  active?: boolean;
  deleted?: boolean;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
  created_by?: IUser | null;
  updated_by?: IUser | null;
  deleted_by?: IUser | null;
}

export interface ICampaignBatch {
  _id?: string;
  name?: string;
  active?: boolean;
  deleted?: boolean;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
  created_by?: IUser | null;
  updated_by?: IUser | null;
  deleted_by?: IUser | null;
  batchtarget?: object[];
  scheduledate?: string;
  pending?: number;
  success?: number;
  targets?: object[];
  status?: CampaignBatchStatus;
  statusmessage?: string;
}

export interface ICampaignBatchSearchFilter {
  period: object | [];
  status: CampaignBatchStatus[] | string[];
  content: string;
}

export interface ICampaignStats {
  created: number;
  sended: number;
  pending: number;
}

export interface IFilterProps {
  deleted?: boolean;
  name?: string;
  campaigns?: string[];
}

export interface IFilterCampaignBatches {
  id: string;
  skip: number;
  limit: number;
  filter?: string;
  deleted?: boolean;
}

export interface IFilterCampaign {
  skip: number;
  limit: number;
  filter: string;
  deleted: boolean;
  allStatus?: boolean;
}

export interface IFilterCampaignTargets {
  _id: string;
  skip: number;
  limit: number;
  filter: string;
}

export interface INewCampaign {
  name: string;
  type: CampaignType;
  externalapp: string;
  // maxsend: number;
}

export type Campaigns = ICampaign[];

interface CampaignsState extends AsyncState {
  isLoadingCampaigns: boolean;
  isLoadingCampaignBatches: boolean;
  isLoadingDropdownCampaigns: boolean;
  isCreatingCampaigns: boolean;
  isImportingTargets: boolean;
  isRemoving: boolean;
  campaigns: ICampaign[];
  totalCampaigns: number;
  totalCampaignBatches: number;
  // selectedCampaigns: ICampaign | null;
  selectedCampaign: ICampaign | null;
  selectedCampaignTargets: ITarget[];
  selectedTotalCampaignTargets: number;
  isLoadingCampaignTargets: boolean;
  selectedLocalCampaign: ICampaign | null;
  selectedCampaignBatches: any;
  selectedCampaignBatchesStats: any;
  disableStartCampaign: boolean;
}

const initialState: CampaignsState = {
  campaigns: [],
  isLoadingCampaigns: false,
  isLoadingCampaignBatches: false,
  isRemoving: false,
  isImportingTargets: false,
  isLoadingDropdownCampaigns: false,
  isCreatingCampaigns: false,
  totalCampaigns: 0,
  totalCampaignBatches: 0,
  selectedCampaignBatches: [],
  selectedCampaignBatchesStats: [],
  selectedCampaignTargets: [],
  selectedTotalCampaignTargets: 0,
  isLoadingCampaignTargets: false,
  // selectedCampaigns: null,
  selectedCampaign: null,
  selectedLocalCampaign: null,
  isSuccess: false,
  isError: false,
  disableStartCampaign: true,
};

export const getSearchCampaigns = createAsyncThunk(
  "campaigns/search",
  async (filterCampaign: IFilterCampaign, thunkAPI) => {
    try {
      return await campaignsService.search(filterCampaign);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getSearchCampaignsBatches = createAsyncThunk(
  "campaigns/searchBatches",
  async (filterCampaign: IFilterCampaign, thunkAPI) => {
    try {
      return await campaignsService.searchBatches(filterCampaign);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getTargetsByCampaignId = createAsyncThunk(
  "campaigns/searchTargets",
  async (filterCampaignTargets: IFilterCampaignTargets, thunkAPI) => {
    try {
      return await campaignsService.getTargetsByCampaignId(
        filterCampaignTargets
      );
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const addTargetsToCampaign = createAsyncThunk(
  "campaigns/addTargets",
  async (
    importCampaignTargets: {
      _id: string;
      customer?: string;
      tag?: string;
      target?: string;
      file?: File;
    },
    thunkAPI
  ) => {
    try {
      return await campaignsService.addTargetsToCampaign(importCampaignTargets);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const selectCampaign = createAsyncThunk(
  "campaigns/selectCampaign",
  async ({ _id }: { _id: string }, thunkAPI) => {
    try {
      return await campaignsService.campaign({ _id });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateCampaign = createAsyncThunk(
  "campaigns/update",
  async (
    { _campaign, noToast }: { _campaign: any; noToast?: boolean },
    thunkAPI
  ) => {
    try {
      return await campaignsService.update({ _campaign });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteCampaign = createAsyncThunk(
  "campaigns/delete",
  async (ids: string[], thunkAPI) => {
    try {
      return await campaignsService.deleteCampaigns({ ids });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createCampaign = createAsyncThunk(
  "campaigns/create",
  async (newCampaign: INewCampaign, thunkAPI) => {
    try {
      return await campaignsService.create(newCampaign);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const campaignsSlice = createSlice({
  name: "campaigns",
  initialState,
  reducers: {
    logout() {
      return initialState;
    },
    reselectCampaign(state, action: PayloadAction<ICampaign | null>) {
      state.selectedCampaign = action.payload;
      state.isLoadingCampaignBatches = false;
    },
    toggleStartCampaign(state, action: PayloadAction<boolean>) {
      state.disableStartCampaign = action.payload;
    },
    reselectCampaignBatch(state, action: PayloadAction<any | null>) {
      state.selectedCampaignBatches = action.payload.results;
      state.selectedCampaignBatchesStats = action.payload.stats || [];
      state.totalCampaignBatches = action.payload.count;
    },
    reselectLocalCampaign(state, action: PayloadAction<ICampaign | null>) {
      state.selectedLocalCampaign = action.payload;
    },
    selectCampaigns(state, action: PayloadAction<ICampaign[]>) {
      state.campaigns = action.payload || [];
      if (action.payload.length === 0) {
        state.selectedCampaign = null;
      }
    },
    selectTotalCampaigns(state, action: PayloadAction<number>) {
      state.totalCampaigns = action.payload || 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSearchCampaigns.pending, (state, action) => {
        if (action.meta.arg.limit === 10) {
          state.isLoadingCampaigns = true;
        }
        state.isLoadingDropdownCampaigns = true;
      })
      .addCase(getSearchCampaigns.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.campaigns = action.payload.results || [];
        // if (state.totalCampaigns <= action.payload.count)
        state.totalCampaigns = action.payload.count;
        state.isLoadingCampaigns = false;
        state.isLoadingDropdownCampaigns = false;
      })
      .addCase(getSearchCampaigns.rejected, (state) => {
        state.isError = true;
        state.campaigns = [];
        state.isLoadingCampaigns = false;
        state.isLoadingDropdownCampaigns = false;
      })
      .addCase(getSearchCampaignsBatches.pending, (state, action) => {
        state.isLoadingCampaignBatches = true;
      })
      .addCase(getSearchCampaignsBatches.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.selectedCampaignBatches = action.payload.results;
        state.selectedCampaignBatchesStats = action.payload.stats || [];
        state.totalCampaignBatches = action.payload.count;
        state.isLoadingCampaignBatches = false;
      })
      .addCase(getSearchCampaignsBatches.rejected, (state) => {
        state.isError = true;
        state.selectedCampaignBatches = [];
        state.isLoadingCampaignBatches = false;
      })
      .addCase(getTargetsByCampaignId.pending, () => {
        // state.isLoadingCampaignTargets = true;
      })
      .addCase(getTargetsByCampaignId.fulfilled, (state, action) => {
        if (typeof action?.payload?.results !== "undefined") {
          state.isSuccess = true;
          state.selectedCampaignTargets = action.payload.results;
          state.selectedTotalCampaignTargets = action.payload.count;
          // state.isLoadingCampaignTargets = false;
        } else {
          state.isError = true;
          // state.isLoadingCampaignTargets = false;
        }
      })
      .addCase(getTargetsByCampaignId.rejected, (state) => {
        state.isError = true;
        state.isLoadingCampaignTargets = false;
      })
      .addCase(addTargetsToCampaign.pending, (state) => {
        state.isImportingTargets = true;
      })
      .addCase(addTargetsToCampaign.fulfilled, (state, action) => {
        if (typeof action?.payload?.results !== "undefined") {
          state.isSuccess = true;
        } else {
          state.isError = true;
        }
        state.isImportingTargets = false;
      })
      .addCase(addTargetsToCampaign.rejected, (state) => {
        state.isError = true;
        state.isImportingTargets = false;
      })
      .addCase(selectCampaign.pending, (state) => {
        state.isLoadingCampaigns = true;
      })
      .addCase(selectCampaign.fulfilled, (state, action) => {
        state.isSuccess = true;
        if (action.payload?._id) {
          state.selectedCampaign = action.payload;
          // state.selectedLocalCampaign = action.payload;
        }
        state.isLoadingCampaigns = false;
      })
      .addCase(selectCampaign.rejected, (state) => {
        state.isError = true;
        state.selectedCampaign = null;
        state.isLoadingCampaigns = false;
      })
      // UPDATE CUSTOMER
      .addCase(updateCampaign.pending, (/* state */) => {
        // state.isLoadingCampaigns = true;
      })
      .addCase(updateCampaign.fulfilled, (state, action) => {
        if (
          action?.payload !== null &&
          typeof action?.payload?._id !== "undefined"
        ) {
          state.isSuccess = true;
          if (!action.meta.arg.noToast || action.meta.arg.noToast !== true) {
            customToast({
              type: IToastType.SUCCESS,
              message: `Alteração salva com sucesso!`,
            });
          }
        } else {
          if (!action.meta.arg.noToast || action.meta.arg.noToast !== true) {
            customToast({
              type: IToastType.ERROR,
              message: `Algo deu errado!`,
            });
          }
          state.isError = true;
        }
        state.isLoadingCampaigns = false;
      })
      .addCase(updateCampaign.rejected, (state) => {
        state.isError = true;
        state.selectedCampaign = null;
        state.isLoadingCampaigns = false;
      })
      .addCase(createCampaign.pending, (state) => {
        state.isCreatingCampaigns = true;
      })
      .addCase(createCampaign.fulfilled, (state, action) => {
        if (typeof action?.payload?._id !== "undefined") {
          state.isSuccess = true;
          customToast({
            type: IToastType.SUCCESS,
            message: `Campanha criada com sucesso!`,
          });
          const newState = state.campaigns;
          newState.push(action.payload);
          state.campaigns = newState;
        } else {
          state.isError = true;
          if (
            typeof action?.payload === "string" &&
            action?.payload.includes("duplicate")
          ) {
            customToast({
              type: IToastType.ERROR,
              message: `Opa! Já existe uma campanha com esse nome!`,
            });
          } else {
            customToast({
              type: IToastType.ERROR,
              message: `Algo deu errado! Tente novamente.`,
            });
          }
        }
        state.isCreatingCampaigns = false;
      })
      .addCase(createCampaign.rejected, (state) => {
        state.isError = true;
        state.isCreatingCampaigns = false;
      })
      .addCase(deleteCampaign.pending, (state) => {
        state.isRemoving = true;
      })
      .addCase(deleteCampaign.fulfilled, (state, action) => {
        if (
          action.payload !== null &&
          typeof action.payload !== "undefined" &&
          typeof action.payload?.message !== "string"
        ) {
          state.isSuccess = true;
          customToast({
            type: IToastType.SUCCESS,
            message: `Remoção realizada com sucesso!`,
          });
          // } else if (
          //   typeof action.payload?.message === "string" &&
          //   action.payload.message.includes("Error on delete default office hour")
          // ) {
          //   customToast({
          //     type: IToastType.WARNING,
          //     message: "Horário definido como padrão!",
          //   });
        } else {
          state.isError = true;
          customToast({
            type: IToastType.ERROR,
            message: `Algo deu errado!`,
          });
        }
        state.isRemoving = false;
      })
      .addCase(deleteCampaign.rejected, (state) => {
        state.isError = true;
        state.isRemoving = false;
      });
  },
});

export default campaignsSlice.reducer;
export const {
  logout,
  reselectCampaign,
  reselectLocalCampaign,
  reselectCampaignBatch,
  selectCampaigns,
  selectTotalCampaigns,
  toggleStartCampaign,
} = campaignsSlice.actions;
