import React, { useCallback, useEffect, useState } from "react";
import { DropdownSearchInputProps, Form } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../../../../../hooks/redux/hooks";
import Toggle from "../../../../../../shared/components/Toggle.component";
import FilesArea from "../../../../../../shared/components/Files/FilesArea.component";
import { ddOptions } from "../../../../../../shared/models/interfaces/dropdownOptions.interface";
import {
  IAgideskTicket,
  IAgideskTicketFilter,
  IAgideskUpdateTicket,
} from "../../../../../../slices/externalapps.slice";
import { externalappsService } from "../../../../../../services";
import LoadingDots from "../../../../../../shared/components/LoadingDots.component";
import Dropdown from "../../../../../../shared/components/Dropdown.component";

const UpdateTicket = ({
  searchTicketParameters,
  tickets,
  ticket,
  setTicket,
  files,
  setFiles,
}: {
  searchTicketParameters: IAgideskTicketFilter;
  tickets: IAgideskTicket[];
  ticket: IAgideskUpdateTicket;
  setTicket: React.Dispatch<React.SetStateAction<IAgideskUpdateTicket>>;
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { selectedChat, checkingChatMessages } = useAppSelector(
    (state) => state.chats
  );
  const { isUpdatingTicket } = useAppSelector((state) => state.externalapps);

  const [optionsTicket, setOptionsTicket] = useState<ddOptions[]>(
    tickets?.map((_ticket) => {
      const _obj = {
        key: _ticket.id as string,
        text: `[${_ticket.searchid}] - ${_ticket.title}`,

        value: _ticket.id as string,
      };
      return _obj;
    })
  );

  const [focusId, setFocusId] = useState<string>("");
  const [isLoadingDropdown, setIsLoadingDropdown] = useState<boolean>(false);
  const _value = checkingChatMessages.selectedMessageIDs.length;

  useEffect(() => {
    if (location.hash === "#updateticket") {
      const _update = {
        ...ticket,
        messages: checkingChatMessages.selectedMessageIDs,
      };
      setTicket(_update);
    }
  }, [_value]);

  const getOptions = useCallback(() => {
    document.querySelectorAll("i.dropdown.icon.clear").forEach((el: any) => {
      el.click();
    });
    const _update: IAgideskUpdateTicket = {
      ...ticket,
      tasks: "",
      tasktitle: "",
    };
    setTicket(_update);
    return tickets?.map((_ticket) => {
      const _obj = {
        key: _ticket.id as string,
        text: `[${_ticket.searchid}] - ${_ticket.title}`,
        value: _ticket.id as string,
      };
      return _obj;
    });
  }, [tickets]);

  useEffect(() => {
    const _v: any = document.getElementById("register");
    const _update: IAgideskUpdateTicket = {
      ...ticket,
      htmlcontent: ticket.tasks.length === 0 ? "" : _v?.value || "",
    };
    setTicket(_update);
  }, [ticket.tasks]);

  const createCombo = useCallback(() => {
    return (
      <Dropdown
        id="dropdown-update-ticket"
        loading={isLoadingDropdown && focusId === "dropdown-update-ticket"}
        className="mt-6 w-full"
        label={t("form.label.issue") || "form.label.issue"}
        placeholder={t("form.placeholder.issue") || "form.placeholder.issue"}
        required
        clearable
        fluid
        search
        selection
        defaultValue={ticket.tasks}
        options={getOptions()}
        onChange={(e, { value }) => {
          if (value) {
            setTicket({
              ...ticket,
              internal:
                tickets.find((_ticket) => _ticket.id === value.toString())
                  ?.internal === "1",
              tasks: value.toString(),
              tasktitle:
                optionsTicket
                  ?.find((_op) => _op.key === value)
                  ?.text?.split(" - ")[1] || "",
            });
          } else {
            setTicket({
              ...ticket,
              internal: false,
              tasks: "",
              tasktitle: "",
            });
          }
        }}
        onSearchChange={async (e: DropdownSearchInputProps) => {
          const _tickets: IAgideskTicketFilter = {
            ...searchTicketParameters,
            filter: e.target.value,
            skip: 0,
            limit: 10,
          };
          const _optionsTickets: ddOptions[] = [];
          setIsLoadingDropdown(true);
          const payload: IAgideskTicket[] =
            await externalappsService.searchAgideskTicket(_tickets);
          setIsLoadingDropdown(false);
          if (payload) {
            payload?.forEach((_ticket) => {
              if (
                _optionsTickets.find(
                  (_opticket) => _opticket.key === _ticket.id
                )
              ) {
                return false;
              }
              _optionsTickets.push({
                key: _ticket.id as string,
                text: `[${_ticket.searchid}] - ${_ticket.title}`,
                value: _ticket.id as string,
              });
              return true;
            });
          }
          setOptionsTicket(_optionsTickets);
        }}
        onFocus={() => {
          setFocusId("dropdown-update-ticket");
        }}
        onBlur={() => {
          setFocusId("");
        }}
      />
    );
  }, [tickets]);

  const [JSXCombo, setJSXCombo] = useState<React.ReactNode>(null);

  useEffect(() => {
    setJSXCombo(createCombo());
  }, [tickets]);

  return (
    <div className="bg-white pt-2 pb-5">
      <Form>
        {isUpdatingTicket ? (
          <LoadingDots medium className="flex justify-center items-center" />
        ) : (
          <>
            {JSXCombo}
            {ticket.tasks.length > 0 ? (
              <>
                <Form.TextArea
                  id="register"
                  style={{ resize: "none" }}
                  label={t("form.label.register")}
                  placeholder={t("form.placeholder.register")}
                  maxLength={1024}
                  defaultValue={ticket.htmlcontent}
                  type="text"
                  required
                  onChange={(e, { value }) => {
                    if (value) {
                      setTicket({
                        ...ticket,
                        htmlcontent: value.toString(),
                      });
                    } else {
                      setTicket({
                        ...ticket,
                        htmlcontent: "",
                      });
                    }
                  }}
                />
                <Toggle
                  containerClass="flex w-full mt-3"
                  defaultChecked={ticket.addchat || false}
                  mainClass=""
                  onChange={(_, { checked }) => {
                    setTicket({
                      ...ticket,
                      addchat: checked || false,
                    });
                  }}
                  labelClass="ml-4 mb-5"
                  label={t("toggle.chat-agidesk.addchat")}
                  simple
                />
                <Toggle
                  containerClass="flex w-full -mt-1"
                  mainClass=""
                  onChange={(_, { checked }) => {
                    setTicket({
                      ...ticket,
                      internal: checked || false,
                    });
                  }}
                  labelClass="ml-4 mb-5"
                  label={t("toggle.chat-agidesk.internal")}
                  simple
                />
                <div className="-mt-3">
                  {selectedChat && typeof selectedChat._id !== "undefined" ? (
                    <FilesArea
                      chat={selectedChat}
                      files={files}
                      setFiles={setFiles}
                    />
                  ) : null}
                </div>
                {!ticket.addchat ? (
                  <label
                    className="mt-3"
                    title={
                      _value > 0
                        ? `${
                            _value === 1 ? "Será incluída" : "Serão incluídas"
                          } ${_value} ${
                            _value === 1 ? "mensagem" : "mensagens"
                          } ao atendimento selecionado`
                        : "Nenhuma mensagem da conversa será incluída ao atendimento selecionado"
                    }
                  >
                    {_value > 0 && !ticket.addchat
                      ? `${
                          _value === 1 ? "Será incluída" : "Serão incluídas"
                        } ${_value} ${
                          _value === 1 ? "mensagem" : "mensagens"
                        } ao atendimento selecionado`
                      : "Nenhuma mensagem da conversa será incluída ao atendimento selecionado"}
                  </label>
                ) : null}
              </>
            ) : null}
          </>
        )}
      </Form>
    </div>
  );
};

export default UpdateTicket;
