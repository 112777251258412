import { ICustomer } from "../slices/customers.slice";
import { RoleUser } from "../slices/users.slice";
import instances from "./instances";

const customer = async ({ _id }: { _id: string }) => {
  try {
    let _customer = null;
    const response = await instances.instanceCustomers.get(`/${_id}`);
    if (response.data) {
      const _contacts = await instances.instanceUsers.get(`/customer/${_id}`);
      _customer = response.data;
      _customer.contacts = _contacts.data;
      return _customer;
    }
    return null;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const update = async ({ _customer }: { _customer: ICustomer }) => {
  try {
    const data = _customer;
    const response = await instances.instanceCustomers.patch(
      `${_customer._id}`,
      data
    );
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const updateContacts = async ({
  _customer,
  contacts,
}: {
  _customer: string;
  contacts: string[];
}) => {
  try {
    const data = { contacts };
    const response = await instances.instanceCustomers.patch(
      `${_customer}/contacts`,
      data
    );
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const deleteCustomers = async ({ ids }: { ids: string[] }) => {
  try {
    const data = { id: ids };
    const response = await instances.instanceCustomers.delete("", { data });
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const create = async ({ name }: { name: string }) => {
  try {
    const data = {
      name,
    };
    const response = await instances.instanceCustomers.post("", data);
    if (response && response.data && !response.data.message) {
      return response.data;
    }
    return response.data.message;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const search = async ({
  skip,
  limit,
  filter,
  deleted,
  allStatus,
}: {
  skip?: number;
  limit?: number;
  filter: string;
  deleted: boolean;
  allStatus?: boolean;
}) => {
  try {
    const _filters =
      typeof filter === "object" ? JSON.parse(JSON.stringify(filter)) : {};

    if (typeof filter === "string" && typeof _filters?.name === "undefined")
      _filters.name = filter;
    if (allStatus) _filters.active = false;
    if (deleted) _filters.deleted = true;

    // _filters.roles = `${RoleUser.CONTACT}`;

    const response = await instances.instanceCustomers.get("", {
      params: {
        skip: skip || 0,
        limit: limit || 10,
        filter: _filters,
      },
    });
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const customersService = {
  customer,
  update,
  deleteCustomers,
  updateContacts,
  create,
  search,
};

export default customersService;
