import { useAppSelector } from "./redux/hooks";

const useReplaceVarsText = () => {
  const { user, tenantID } = useAppSelector((state) => state.auth);
  const { selectedChat } = useAppSelector((state) => state.chats);
  const { selectedSettings } = useAppSelector((state) => state.settings);

  const replaceVarsContent = (content: string) => {
    const _contact = selectedChat?.users?.find((_u) => _u._id !== user?._id);
    const _teamChat = selectedChat?.team;
    const _company = selectedSettings?.company;
    let _text = content;
    _text = _text
      .replace(/{{contact.name}}/g, _contact?.name || "{{contact.name}}")
      .replace(
        /{{contact.customers}}/g,
        typeof _contact?.customers !== "undefined" &&
          _contact.customers.length > 0
          ? _contact?.customers[0].name || "(Cliente não informado)"
          : "(Cliente não informado)"
      )
      .replace(
        /{{contact.phone}}/g,
        _contact?.phone || "(Telefone não informado)"
      )
      .replace(/{{company.name}}/g, _company?.name || tenantID || "-")
      .replace(/{{user.name}}/g, user?.name || "-")
      .replace(/{{user.email}}/g, user?.email || "-")
      .replace(/{{chat.team.name}}/g, _teamChat?.name || "-");
    return _text;
  };

  return { replaceVarsContent };
};

export default useReplaceVarsText;
