/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/hooks";
import SearchBox from "../../../shared/components/SearchBox.component";
import {
  addToRecents,
  ChatStatus,
  ChatType,
  closeChat,
  IChat,
  IFilterChat,
  IFilterProps,
  openChat,
  removeFromRecents,
  selectBacklogChats,
  selectMineChats,
  selectSearchChats,
  selectTeamsChats,
  selectTotalBacklogChats,
  selectTotalMineChats,
  selectTotalSearchChats,
  selectTotalTeamsChats,
} from "../../../slices/chats.slice";
import { UserType } from "../../../slices/users.slice";
import ChatCard from "./ChatCard.component";
import LoadingDots from "../../../shared/components/LoadingDots.component";
import useOnScreen from "../../../hooks/useOnScreen";
import NotificationBadge from "./NotificationBadge.component";
import LinkButton from "../../../shared/components/Buttons/LinkButton.component";
import { chatsService } from "../../../services";
import removeDuplicates from "../../../shared/utils/removeDuplicates";
import { updateTenant } from "../../../services/instances";
import { disableChatCard } from "../../../slices/messages.slice";
import useLGPD from "../../../hooks/useLGPD";
import {
  IChatSearchFilters,
  IFiltersDropdownInstances,
} from "../../../shared/components/@types/searchBarFilter.types";
import { InboxProps } from "./@types/Inbox.types";

const Inbox = ({
  clearFilters,
  searchFiltersSelected,
  searchFiltersOptions,
  showFilter,
  setShowFilter,
  widgetType,
  setShow,
  toggleList,
  skipSearch,
  setSkipSearch,
  setToggleList,
}: InboxProps) => {
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [skipBacklog, setSkipBacklog] = useState<number>(0);
  const [skipMine, setSkipMine] = useState<number>(0);
  const [skipTeams, setSkipTeams] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [hasMoreBacklog, setHasMoreBacklog] = useState(false);
  const [hasMoreMine, setHasMoreMine] = useState(false);
  const [hasMoreTeams, setHasMoreTeams] = useState(false);
  const [hasMoreSearch, setHasMoreSearch] = useState(false);

  const scrollRef = useRef<null | HTMLDivElement>(null);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user, isWidgetAgent, tenantID } = useAppSelector(
    (state) => state.auth
  );
  const { chatdisable } = useAppSelector((state) => state.messages);
  const { showCode } = useLGPD();
  const {
    selectedChat,
    // isClosing,
    backlogChats,
    totalBacklogChats,
    mineChats,
    totalMineChats,
    teamsChats,
    totalTeamsChats,
    searchChats,
    totalSearchChats,
  } = useAppSelector((state) => state.chats);

  const countSelectedFilters = () => {
    let selectedFilters = 0;
    if (typeof searchFiltersSelected !== "undefined") {
      for (const instance of Object.keys(searchFiltersSelected)) {
        const instanceValues =
          searchFiltersSelected[instance as keyof IChatSearchFilters];
        if (
          typeof instanceValues !== "undefined" &&
          (instanceValues !== "" ||
            (Array.isArray(instanceValues) && instanceValues.length > 0))
        ) {
          selectedFilters +=
            typeof instanceValues === "string" ||
            typeof instanceValues === "boolean"
              ? instanceValues
                ? 1
                : 0
              : instanceValues.length;
        }
      }
    }
    return selectedFilters;
  };

  const getHasMore = () => {
    if (toggleList === "backlog") return hasMoreBacklog;
    if (toggleList === "mine") return hasMoreMine;
    if (toggleList === "search") return hasMoreSearch;
    return hasMoreTeams;
  };

  const getSetSkip = () => {
    if (toggleList === "backlog") return setSkipBacklog;
    if (toggleList === "mine") return setSkipMine;
    if (toggleList === "search") return setSkipSearch;
    return setSkipTeams;
  };

  const [listRef] = useOnScreen({
    hasMore: getHasMore(),
    isLoading,
    setSkip: getSetSkip(),
  });
  const [renderDataBacklog, setRenderDataBacklog] = useState<IChat[]>([]);
  const [renderDataMine, setRenderDataMine] = useState<IChat[]>([]);
  const [renderDataTeams, setRenderDataTeams] = useState<IChat[]>([]);
  const [renderDataSearch, setRenderDataSearch] = useState<IChat[]>([]);

  const getFilter = () => {
    const _name = searchKeyword.length > 0 ? searchKeyword : undefined;
    const _groupchat =
      typeof searchFiltersSelected?.groupchat !== "undefined" &&
      searchFiltersSelected.groupchat.length > 0
        ? searchFiltersSelected.groupchat[0]
        : false;
    if (toggleList === "search") {
      return {
        ...searchFiltersSelected,
        content: _name,
        groupchat: _groupchat,
      } as unknown as IFilterProps;
    }
    const _status = [];
    if (toggleList === "backlog") _status.push(ChatStatus.WAIT);
    if (toggleList !== "backlog") _status.push(ChatStatus.ACTIVE);
    if (toggleList === "mine") _status.push(ChatStatus.WAITREPLY);

    return {
      name: _name,
      status: _status,
      grouptype: toggleList === "mine" ? "me" : toggleList,
    };
  };

  const conditions = {
    backlog: () => toggleList === "backlog" || searchKeyword.length > 0,
    mine: () => toggleList === "mine" || searchKeyword.length > 0,
    teams: () => toggleList === "teams" || searchKeyword.length > 0,
    search: () => toggleList === "search" || searchKeyword.length > 0,
  };

  const formatFilters = () => {
    const currentFilters = { ...searchFiltersSelected };

    const filterProps = {
      groupchat:
        typeof currentFilters?.groupchat !== "undefined" &&
        currentFilters.groupchat.length > 0
          ? currentFilters.groupchat[0]
          : undefined,
      content:
        typeof searchKeyword !== "undefined" && searchKeyword.length > 0
          ? searchKeyword
          : typeof currentFilters.content !== "undefined" &&
            currentFilters.content.length > 0
          ? currentFilters.content[0]
          : undefined,
      users:
        typeof currentFilters.users !== "undefined" &&
        currentFilters.users.length > 0
          ? currentFilters.users
          : undefined,
      team:
        typeof currentFilters.teams !== "undefined" &&
        currentFilters.teams.length > 0
          ? currentFilters.teams
          : undefined,
      customer:
        typeof currentFilters.customers !== "undefined" &&
        currentFilters.customers.length > 0
          ? currentFilters.customers
          : undefined,
      status:
        typeof currentFilters.status !== "undefined" &&
        currentFilters.status.length > 0
          ? currentFilters.status
          : undefined,
      type:
        typeof currentFilters.types !== "undefined" &&
        currentFilters.types.length > 0
          ? currentFilters.types
          : undefined,
      period:
        typeof currentFilters.period !== "undefined" &&
        // Array.isArray(currentFilters.period) &&
        currentFilters.period !== null
          ? currentFilters.period
          : undefined,
      externalapp:
        typeof currentFilters.externalapps !== "undefined" &&
        currentFilters.externalapps.length > 0
          ? currentFilters.externalapps
          : undefined,
      contacttags:
        typeof currentFilters.contacttags !== "undefined" &&
        currentFilters.contacttags.length > 0
          ? currentFilters.contacttags
          : undefined,
      contacts:
        typeof currentFilters.contacts !== "undefined" &&
        currentFilters.contacts.length > 0
          ? currentFilters.contacts
          : undefined,
      tags:
        typeof currentFilters.tags !== "undefined" &&
        currentFilters.tags.length > 0
          ? currentFilters.tags
          : undefined,
      campaigns:
        typeof currentFilters.campaigns !== "undefined" &&
        currentFilters.campaigns.length > 0
          ? currentFilters.campaigns
          : undefined,
      campaignchat:
        typeof currentFilters?.campaignchat !== "undefined"
          ? currentFilters.campaignchat
          : undefined,
    } as IFilterProps;

    const _transferred =
      typeof currentFilters.status !== "undefined" &&
      currentFilters.status.length > 0 &&
      typeof currentFilters.status !== "undefined" &&
      currentFilters.status.indexOf("TRANSFERRED") > 0;

    if (_transferred) {
      filterProps.transferred = _transferred;
    }

    const _types = filterProps.type;
    const _widgetType = "WIDGET" as ChatType;

    if (typeof _types !== "undefined") {
      if (
        _types.includes(ChatType.EXTERNAL) &&
        !(
          _types.includes(ChatType.BOTMAKER) ||
          _types.includes(ChatType.WHATSAPP) ||
          _types.includes(_widgetType)
        )
      ) {
        _types.push(ChatType.BOTMAKER, ChatType.WHATSAPP, _widgetType);
      }

      if (
        _types.includes(_widgetType) &&
        !(
          _types.includes(ChatType.BOTMAKER) ||
          _types.includes(ChatType.WHATSAPP)
        )
      ) {
        _types.push(ChatType.EXTERNAL);
      }

      filterProps.type = _types as string[];
    }

    const filter = filterProps as IFilterChat;

    Object.keys(filter).forEach((key) => {
      const currentValue = JSON.stringify(filter[key as keyof IFilterChat]);
      if (
        currentValue === "[]" ||
        currentValue === undefined ||
        currentValue === ""
      ) {
        delete filter[key as keyof IFilterChat];
      }
    });

    return filter;
  };

  useEffect(() => {
    setSearchKeyword("");
    setShowFilter(false);
  }, [selectedChat?._id]);

  useEffect(() => {
    // if (selectedChat === null || typeof selectedChat?._id === "undefined") {
    setSearchKeyword("");
    setShowFilter(false);
    // setIsLoading(true);
    if (totalBacklogChats > 10) {
      setSkipBacklog(0);
      setHasMoreBacklog(true);
    }
    if (totalMineChats > 10) {
      setSkipMine(0);
      setHasMoreMine(true);
    }
    if (totalTeamsChats > 10) {
      setSkipTeams(0);
      setHasMoreTeams(true);
    }
    if (totalSearchChats > 10) {
      setSkipSearch(0);
      setHasMoreSearch(true);
    }
    // }
  }, [toggleList]);

  useEffect(() => {
    if (toggleList === "backlog") {
      if (skipBacklog + 10 >= totalBacklogChats) {
        setHasMoreBacklog(false);
      } else {
        setHasMoreBacklog(true);
      }
    }
    const _backlog = backlogChats;
    if (typeof _backlog !== "undefined" && _backlog.length > 0) {
      setRenderDataBacklog(
        [..._backlog]?.sort((a: IChat, b: IChat) =>
          (b?.created_at || "") > (a?.created_at || "")
            ? -1
            : (a?.created_at || "") > (b?.created_at || "")
            ? 1
            : 0
        )
      );
    } else {
      setRenderDataBacklog([]);
      setIsLoading(false);
    }
  }, [backlogChats]);

  useEffect(() => {
    if (toggleList === "mine") {
      if (skipMine + 10 >= totalMineChats) {
        setHasMoreMine(false);
      } else {
        setHasMoreMine(true);
      }
    }
    const _mine = mineChats;
    if (typeof _mine !== "undefined" && _mine.length > 0) {
      setRenderDataMine(_mine);
    } else {
      setRenderDataMine([]);
      setIsLoading(false);
    }
  }, [mineChats]);

  useEffect(() => {
    if (renderDataTeams.length > 0 && toggleList === "teams") {
      if (skipTeams + 10 >= totalTeamsChats) {
        setHasMoreTeams(false);
      } else {
        setHasMoreTeams(true);
      }
    }
    const _teams = teamsChats;
    if (typeof _teams !== "undefined" && _teams.length > 0) {
      setRenderDataTeams(_teams);
    } else {
      setRenderDataTeams([]);
      setIsLoading(false);
    }
  }, [teamsChats]);

  useEffect(() => {
    if (renderDataSearch.length > 0 && toggleList === "search") {
      if (skipSearch + 10 >= totalSearchChats) {
        setHasMoreSearch(false);
      } else {
        setHasMoreSearch(true);
      }
    }
    const _search = searchChats;
    if (typeof _search !== "undefined" && _search.length > 0) {
      setRenderDataSearch(_search);
    } else {
      setRenderDataSearch([]);
      setIsLoading(false);
    }
  }, [searchChats]);

  useEffect(() => {
    (async () => {
      const filters = formatFilters();
      if (toggleList === "search" && Object.keys(filters).length === 0) {
        setToggleList("mine");
        return false;
      }
      if (toggleList === "search" && Object.keys(filters).length > 0) {
        if (
          isWidgetAgent &&
          typeof tenantID !== "undefined" &&
          tenantID.length > 0
        ) {
          updateTenant(tenantID, "agent");
        }
        const payload = await chatsService.advancedSearch({
          skip: skipSearch,
          limit: 10,
          filter: filters,
          code: showCode,
        });
        let _results = [];
        if (
          typeof payload?.results !== "undefined" &&
          payload?.results?.length > 0
        ) {
          _results = payload?.results
            // ?.filter((_chat: IChat) => _chat?.lastmessage)
            ?.sort((a: IChat, b: IChat) =>
              (b?.lastmessage?.created_at || b?.updated_at || "") >
              (a?.lastmessage?.created_at || a?.updated_at || "")
                ? 1
                : (a?.lastmessage?.created_at || a?.updated_at || "") >
                  (b?.lastmessage?.created_at || b?.updated_at || "")
                ? -1
                : 0
            );
        }
        setIsLoading(false);
        if (skipSearch + 10 >= payload?.count) {
          setHasMoreSearch(false);
        } else {
          setHasMoreSearch(true);
        }
        if (skipSearch > 0) {
          setRenderDataSearch(renderDataSearch.concat(_results));
          dispatch(selectSearchChats(renderDataSearch.concat(_results)));
        } else {
          setRenderDataSearch(_results);
          dispatch(selectSearchChats(_results));
        }
        if (typeof payload?.count !== "undefined") {
          dispatch(selectTotalSearchChats(payload?.count));
        }
      }
    })();
  }, [searchFiltersSelected, skipSearch, searchKeyword, toggleList]);

  useEffect(() => {
    (async () => {
      if (conditions.backlog() && toggleList === "backlog") {
        if (
          isWidgetAgent &&
          typeof tenantID !== "undefined" &&
          tenantID.length > 0
        ) {
          updateTenant(tenantID, "agent");
        }
        const payload = await chatsService.search({
          skip: skipBacklog,
          limit: 10,
          filter: getFilter(),
          code: showCode,
        });
        let _results = [];
        if (
          typeof payload?.results !== "undefined" &&
          payload?.results?.length > 0
        ) {
          _results = payload?.results
            // ?.filter((_chat: IChat) => _chat?.lastmessage)
            ?.sort((a: IChat, b: IChat) =>
              (b?.created_at || "") > (a?.created_at || "")
                ? -1
                : (a?.created_at || "") > (b?.created_at || "")
                ? 1
                : 0
            );
        }
        setIsLoading(false);
        if (skipBacklog + 10 >= payload?.count) {
          setHasMoreBacklog(false);
        } else {
          setHasMoreBacklog(true);
        }
        if (skipBacklog > 0) {
          setRenderDataBacklog(renderDataBacklog.concat(_results));
          dispatch(selectBacklogChats(renderDataBacklog.concat(_results)));
        } else {
          setRenderDataBacklog(_results);
          dispatch(selectBacklogChats(_results));
        }
        if (typeof payload?.count !== "undefined") {
          dispatch(selectTotalBacklogChats(payload?.count));
        }
      }
    })();
  }, [skipBacklog, searchKeyword, toggleList]);

  useEffect(() => {
    (async () => {
      if (conditions.mine() && toggleList === "mine") {
        if (
          isWidgetAgent &&
          typeof tenantID !== "undefined" &&
          tenantID.length > 0
        ) {
          updateTenant(tenantID, "agent");
        }
        const payload = await chatsService.search({
          skip: skipMine,
          limit: 10,
          filter: getFilter(),
          code: showCode,
        });
        let _results = [];
        if (
          typeof payload?.results !== "undefined" &&
          payload?.results?.length > 0
        ) {
          _results = payload?.results
            // ?.filter((_chat: IChat) => _chat?.lastmessage)
            ?.sort((a: IChat, b: IChat) =>
              (b?.lastmessage?.created_at || b?.updated_at || "") >
              (a?.lastmessage?.created_at || a?.updated_at || "")
                ? 1
                : (a?.lastmessage?.created_at || a?.updated_at || "") >
                  (b?.lastmessage?.created_at || b?.updated_at || "")
                ? -1
                : 0
            );
        }
        setIsLoading(false);
        if (skipMine + 10 >= payload?.count) {
          setHasMoreMine(false);
        } else {
          setHasMoreMine(true);
        }
        if (skipMine > 0) {
          setRenderDataMine(renderDataMine.concat(_results));
          dispatch(selectMineChats(renderDataMine.concat(_results)));
        } else {
          setRenderDataMine(_results);
          dispatch(selectMineChats(_results));
        }
        if (typeof payload?.count !== "undefined") {
          dispatch(selectTotalMineChats(payload?.count));
        }
      }
    })();
  }, [skipMine, searchKeyword, toggleList /* , isClosing */]);

  useEffect(() => {
    (async () => {
      if (conditions.teams() && toggleList === "teams") {
        const payload = await chatsService.search({
          skip: skipTeams,
          limit: 10,
          filter: getFilter(),
          code: showCode,
        });
        let _results = [];
        if (
          typeof payload.results !== "undefined" &&
          payload.results.length > 0
        ) {
          _results = payload?.results
            // ?.filter((_chat: IChat) => _chat?.lastmessage)
            ?.sort((a: IChat, b: IChat) =>
              (b?.lastmessage?.created_at || b?.updated_at || "") >
              (a?.lastmessage?.created_at || a?.updated_at || "")
                ? 1
                : (a?.lastmessage?.created_at || a?.updated_at || "") >
                  (b?.lastmessage?.created_at || b?.updated_at || "")
                ? -1
                : 0
            );
        }
        setIsLoading(false);
        if (skipTeams + 10 >= payload?.count) {
          setHasMoreTeams(false);
        } else {
          setHasMoreTeams(true);
        }
        if (skipTeams > 0) {
          setRenderDataTeams(renderDataTeams.concat(_results));
          dispatch(selectTeamsChats(renderDataTeams.concat(_results)));
        } else {
          setRenderDataTeams(_results);
          dispatch(selectTeamsChats(_results));
        }
        if (typeof payload?.count !== "undefined") {
          dispatch(selectTotalTeamsChats(payload?.count));
        }
      }
    })();
  }, [skipTeams, searchKeyword, toggleList]);

  useEffect(() => {
    scrollRef.current?.scrollTo(0, 0);
  });

  const handleClick = async (chat: IChat) => {
    const users = chat?.users || null;
    if (users) {
      dispatch(removeFromRecents(chat));
      if (selectedChat?._id && widgetType !== UserType.CONTACT) {
        dispatch(addToRecents(selectedChat));
        dispatch(closeChat());
      }
      dispatch(disableChatCard({ disable: true, chat: chat?._id }));
      navigate(`/chat/${chat._id}`);
      setShow(false);
      dispatch(openChat(chat));
    }
  };

  interface noChatsObject {
    [key: string]: string;
  }

  const noChats: noChatsObject = {
    mine: "Você não possui conversas.",
    teams: "A equipe não possui conversas.",
    backlog: "Nenhuma conversa sem responsável.",
    research: "Nenhuma conversa encontrada.",
  };

  const emptyList = (content: noChatsObject[string]) => {
    return (
      <div className="flex relative mt-[20px]">
        <h2 className="my-0 mx-auto mt-[50px]">{content}</h2>
      </div>
    );
  };

  const listBehavior = ({ list }: { list: IChat[] }) => {
    return (
      <ul
        className={`overflow-x-hidden overflow-y-scroll flex-1 relative pl-4 pr-2 ${
          widgetType === UserType.AGENT ? "widget" : ""
        }`}
      >
        <li>
          <div ref={scrollRef} />
        </li>
        {list.map((chat, index) => {
          if (user) {
            return (
              <li
                ref={index === list.length - 1 ? listRef : null}
                aria-hidden
                key={chat?._id}
                onClick={() => {
                  if (chat._id === selectedChat?._id) return;
                  if (!chatdisable) {
                    handleClick(chat);
                  }
                }}
              >
                <ChatCard
                  disabledClick={chatdisable}
                  chat={chat}
                  notifications={
                    !["teams", "search"].includes(toggleList)
                      ? chat?.notifications
                      : 0
                  }
                />
                <div className="w-full border-t border-gray-ddd my-1 -ml-1 mr-1" />
              </li>
            );
          }
          return null;
        })}
      </ul>
    );
  };

  const getListLengthCondition = () => {
    if (typeof renderDataSearch !== "undefined" && toggleList === "search") {
      return renderDataSearch.length > 0;
    }
    if (toggleList === "backlog") {
      return renderDataBacklog.length > 0;
    }
    if (toggleList === "mine") {
      return renderDataMine.length > 0;
    }
    return renderDataTeams.length > 0;
  };

  const getListCondition = () => {
    if (toggleList === "search") {
      return removeDuplicates(renderDataSearch, "_id") as IChat[];
    }
    if (toggleList === "backlog") {
      return removeDuplicates(renderDataBacklog, "_id");
    }
    if (toggleList === "mine") {
      return removeDuplicates(renderDataMine, "_id");
    }
    return removeDuplicates(renderDataTeams, "_id");
  };

  const getList = () => {
    if (getListLengthCondition()) {
      return listBehavior({
        list: getListCondition(),
      });
    }
    return emptyList(
      toggleList === "search" || searchKeyword.length > 0
        ? noChats.research
        : noChats[toggleList]
    );
  };
  const showAppliedFilters = () => {
    if (countSelectedFilters() <= 0 || toggleList !== "search") {
      return "";
    }

    const showFilters: string[] = [];

    if (
      typeof searchFiltersSelected !== "undefined" &&
      typeof searchFiltersOptions !== "undefined"
    ) {
      for (const instance of Object.keys(searchFiltersSelected)) {
        const instanceValues =
          typeof searchFiltersSelected[
            instance as keyof IFiltersDropdownInstances
          ] !== "undefined"
            ? searchFiltersSelected[instance as keyof IFiltersDropdownInstances]
            : [];

        const instanceOptions =
          typeof searchFiltersOptions[
            instance as keyof IFiltersDropdownInstances
          ] !== "undefined"
            ? searchFiltersOptions[instance as keyof IFiltersDropdownInstances]
            : [];

        if (
          typeof instanceValues !== "undefined" &&
          instanceValues.length > 0
        ) {
          if (
            Array.isArray(instanceValues) &&
            typeof instanceValues === "object"
          ) {
            if (instance === "content") {
              showFilters.push(`${instanceValues.join(", ")}`);
            }
            if (["groupchat"].includes(instance) && instanceValues[0]) {
              showFilters.unshift(`Exibir somente chats de grupo`);
            } else {
              const items =
                typeof instanceOptions === "object" &&
                instanceOptions.length > 0
                  ? instanceValues.map((value) => {
                      if (instance === "content") return value;
                      const result = instanceOptions.find(
                        (item: any) => item.key === value
                      );
                      if (
                        typeof value !== "undefined" &&
                        typeof value !== "boolean"
                      )
                        return result?.text;
                    })
                  : [];

              showFilters.push(`${removeDuplicates(items).join(", ")}`);
            }
          }
        } else if (
          Array.isArray(instanceOptions) &&
          instanceOptions.length > 0
        ) {
          const result = instanceOptions.find(
            (item: any) => item.key === instanceValues
          );
          if (typeof result?.text !== "undefined") {
            // showFilters.push(`${getDropdownLabel(instance)} : ${result?.text}`)
            showFilters.push(`${result?.text}`);
          }
        } else if (
          ["campaignchat"].includes(instance) &&
          typeof instanceValues === "boolean" &&
          instanceValues
        ) {
          showFilters.unshift(`Exibir somente chats de campanha`);
        }
      }
    }
    const _filters: string = showFilters.join("; ");
    if (!_filters) return "";
    return (
      <div className="ml-3 pb-3 text-[12px] text-break">
        {typeof searchFiltersSelected !== "undefined" ? (
          <div className="flex">
            <h2 className="font-semibold mr-2">Filtrando por:</h2>
            <h2>{`${
              _filters.length > 200
                ? _filters.substring(0, 200).concat("...")
                : _filters
            }`}</h2>
          </div>
        ) : null}
        <div className="w-full -ml-2">
          <LinkButton
            color
            label="Limpar"
            leftIcon="las la-eraser mr-1"
            onClick={
              typeof clearFilters !== "undefined" ? clearFilters : () => null
            }
            extraClass="text-[12px]"
          />
        </div>
      </div>
    );
  };
  const getLoadingCondition = () =>
    isLoading &&
    ((renderDataBacklog.length === 0 && toggleList === "backlog") ||
      (renderDataMine.length === 0 && toggleList === "mine") ||
      (renderDataSearch?.length === 0 && toggleList === "search") ||
      (renderDataTeams.length === 0 && toggleList === "teams"));

  return (
    <>
      <div className="flex">
        <div
          className={`${
            widgetType === UserType.AGENT ? "w-[90%]" : "w-[88%]"
          } justify-items-start`}
        >
          <SearchBox
            placeholder="Pesquisar conversas..."
            _escFunction={() => {
              if (toggleList === "backlog") {
                setSkipBacklog(0);
              } else if (toggleList === "mine") {
                setSkipMine(0);
              } else if (toggleList === "search") {
                setSkipSearch(0);
              } else {
                setSkipTeams(0);
              }
              setSearchKeyword("");
            }}
            keyword={searchKeyword}
            listFilter={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (toggleList === "backlog") {
                setSkipBacklog(0);
              } else if (toggleList === "mine") {
                setSkipMine(0);
              } else if (toggleList === "search") {
                setSkipSearch(0);
              } else {
                setSkipTeams(0);
              }
              setSearchKeyword(e.target.value);
            }}
          />
        </div>
        <div className="rounded-none max-h-[70px] flex items-center justify-start ">
          {setShowFilter ? (
            <>
              <i
                tabIndex={0}
                className={`cursor-pointer text-gray-bench hover:text-gray-bench-alphaA0 las ${
                  showFilter ? "la-times" : "la-filter"
                } rounded-full text-[24px] relative`}
                role="button"
                onClick={() => {
                  setShowFilter((prevState) => !prevState);
                }}
                onKeyDown={() => {
                  // setShowFilter((prevState) => !prevState);
                }}
                title="Exibir filtros"
                aria-label="Exibir filtros"
              />
              {typeof searchFiltersSelected !== "undefined" &&
              countSelectedFilters() > 0 &&
              toggleList === "search" ? (
                <div
                  role="presentation"
                  onClick={() => {
                    setShowFilter((prevState) => !prevState);
                  }}
                  // onKeyDown={() => {
                  //   setShowFilter((prevState) => !prevState);
                  // }}
                  className="absolute ml-5 mt-5"
                >
                  <NotificationBadge
                    title="filtro(s)"
                    badge={countSelectedFilters()}
                  />
                </div>
              ) : null}
            </>
          ) : null}
        </div>
      </div>
      <div className="pr-0 w-full">{showAppliedFilters()}</div>

      {getLoadingCondition() ? (
        <LoadingDots className="flex flex-1 justify-center items-center" />
      ) : (
        <>{getList()}</>
      )}
    </>
  );
};

Inbox.defaultProps = {
  widgetType: UserType.NONE,
};

export default Inbox;
