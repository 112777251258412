import React from "react";
import { useAppSelector } from "../../../hooks/redux/hooks";

const SelectPerPage = ({
  value,
  onChange,
  pagination,
}: {
  value: number;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  pagination?: number;
}) => {
  const { selectedCampaign } = useAppSelector((state) => state.campaigns);

  let pages =
    typeof selectedCampaign?._id !== "undefined"
      ? [5, 10, 25, 50, 100]
      : [10, 25, 50, 100];
  if (typeof pagination !== "undefined" && pagination < 10) {
    pages = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 4; i++) {
      pages.push(pagination + pagination * i * i);
    }
  }
  return (
    <div className="flex items-center justify-start">
      <select
        className="!border-none !bg-transparent"
        value={value}
        onChange={onChange}
      >
        {pages.map((_pageSize) => (
          <option key={_pageSize} value={_pageSize}>
            {_pageSize}
          </option>
        ))}
      </select>
    </div>
  );
};

SelectPerPage.defaultProps = {
  pagination: undefined,
};

export default SelectPerPage;
