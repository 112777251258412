/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../../hooks/redux/hooks";
import { IUser, RoleUser } from "../../../../../slices/users.slice";
import NoAvatar from "../../../../../shared/components/NoAvatar.component";
import Avatar from "../../../../../shared/components/Avatar.component";
import { handleMaskRegex } from "../../../../../shared/utils/handleMaskLength";

const ChatDetailsCard = ({
  chatContact,
  setLastUrl,
}: {
  chatContact: IUser | null | undefined;
  setLastUrl: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { t } = useTranslation();
  // const URL = process.env.REACT_APP_API_BASE_URL;
  const { user } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();

  const getBorder = () => {
    if (chatContact?.deleted) return "border-l-agitalks-alpha30";
    if (chatContact?.active) return "border-l-green";
    return "border-l-red";
  };

  return (
    <div className="flex flex-col pb-4">
      <label>
        {chatContact?.roles?.includes(RoleUser.AGENT)
          ? t("chatdetails.card.title-user")
          : t("chatdetails.card.title-contact")}
      </label>
      <div
        className={`flex items-center justify-between shadow-md radius p-4 border-2 border-l-4 ${getBorder()} rounded-md`}
      >
        <div className="flex items-center">
          <Link
            to={
              chatContact?.roles &&
              chatContact?._id &&
              chatContact?._id !== user?._id
                ? `/settings/${
                    chatContact.roles.includes(RoleUser.AGENT)
                      ? "user"
                      : "contact"
                  }/${chatContact._id}`
                : "/settings/profile"
            }
            target="_blank"
            rel="noopener noreferrer"
            className="w-[45px] h-[45px] cursor-pointer justify-center flex items-center"
          >
            {chatContact?.avatar &&
            chatContact?.avatar?.path &&
            chatContact?.avatar?.name ? (
              <Avatar
                path={chatContact?.avatar?.path}
                name={chatContact?.avatar?.name}
                extraClass="shadow-sm"
                title={chatContact?.name}
              />
            ) : (
              // <div className="shadow-sm w-full h-full rounded-full flex items-center justify-center bg-gray-333 border-gray-333">
              //   <img
              //     className="avatar"
              //     alt={chatContact?.avatar?.name}
              //     src={`${URL}/api/files/${chatContact.avatar.path}/${chatContact.avatar.name}`}
              //   />
              // </div>
              <NoAvatar
                displaytext={chatContact?.name?.trim() || "--"}
                elementClass="p-0"
              />
            )}
          </Link>
          <div className="flex items-center justify-center">
            <div className="mx-4 flex flex-col text-[12px]">
              <h1 className="font-semibold">{t("chatdetails.card.name")}</h1>
              <h1 className="font-semibold">{t("chatdetails.card.email")}</h1>
              <h1 className="font-semibold">{t("chatdetails.card.phone")}</h1>
            </div>
            <div className="mr-2 flex flex-col text-[12px] max-w-[130px]">
              <h1 className="whitespace-nowrap text-ellipsis overflow-hidden">
                {chatContact?.name}
              </h1>
              <h1 className="whitespace-nowrap text-ellipsis overflow-hidden">
                {chatContact?.email}
              </h1>
              <h1 className="whitespace-nowrap text-ellipsis overflow-hidden">
                {handleMaskRegex(chatContact?.phone) || t("no-phone")}
              </h1>
            </div>
          </div>
        </div>
        <i
          role="presentation"
          onClick={() => {
            setLastUrl(`${location.pathname}${location.hash}`);
            navigate(`${location.pathname}#infos`);
          }}
          className="las la-edit text-agitalks hover:text-agitalks-alphaC0 cursor-pointer"
        />
      </div>
    </div>
  );
};
export default ChatDetailsCard;
