/* eslint-disable react/no-danger */
import React from "react";
import { Label } from "semantic-ui-react";
import useFormatText from "../../../../hooks/useFormatText";

const ErrorLabel = ({
  error,
  label,
  warning,
  pointing,
}: {
  error: boolean | undefined;
  label: string;
  warning?: boolean;
  pointing?: boolean;
}) => {
  return (
    <div
      style={
        error && typeof pointing === "undefined" ? { marginTop: "-16px" } : {}
      }
    >
      {error ? (
        <Label
          basic
          pointing={typeof pointing === "undefined"}
          className={`${warning ? "max-w-full w-fit orange" : "red"}`}
        >
          <span
            className="break-break"
            dangerouslySetInnerHTML={{
              __html: useFormatText(label),
            }}
          />
        </Label>
      ) : null}
    </div>
  );
};

ErrorLabel.defaultProps = {
  warning: false,
  pointing: undefined,
};

export default ErrorLabel;
