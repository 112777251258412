/* eslint-disable import/no-duplicates */
import React from "react";
import { format, parseISO, formatDistanceToNow } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { useTranslation } from "react-i18next";
import NoAvatar from "../../../../../../shared/components/NoAvatar.component";
import { IAgideskTicket } from "../../../../../../slices/externalapps.slice";

const TicketCard = ({ ticket }: { ticket: IAgideskTicket }) => {
  const { t } = useTranslation();
  const getDateFormat = (time: string) => {
    const dateString = format(parseISO(time), "yyyy-MM-dd'T'HH:mm:ss.sss'Z'");
    const dateStringISO = dateString.replace(/([+\\-]\d\d)(\d\d)$/, "$1:$2");
    const timestamp = new Date(dateStringISO).getTime() + 10800000;
    return formatDistanceToNow(timestamp, {
      includeSeconds: true,
      locale: ptBR,
    });
  };
  const getFormat = (time: string) => {
    const dateString = format(parseISO(time), "dd/MM/yyy HH:mm");
    return dateString;
  };

  return (
    <div className="flex w-full mb-2 hover:bg-agidesk-alpha30 group">
      <div
        className="w-full flex-col cursor-pointer flex justify-between shadow-sm radius px-4 py-2 border-2 border-l-gray-999 border-l-4 rounded-md"
        style={{
          borderLeftColor: ticket?.status?.color || undefined,
        }}
      >
        {/* TITLE */}
        <div className="flex items-center justify-between">
          <span
            title={`[${ticket?.searchid}] ${ticket?.title}`}
            className="font-semibold text-black group-hover:text-black mr-1 word-wrap-2"
          >
            [{ticket?.searchid}] {ticket?.title}
          </span>
          {ticket?.internal === "1" ? (
            <i className="las la-lock text-gray-999" title="Demanda interna" />
          ) : null}
        </div>

        {/* FIRST LINE */}
        <div className="flex items-center justify-between">
          <div className="flex items-center my-1 text-gray-999 group-hover:text-black">
            <div
              className="flex items-center"
              title={`${t("chat-agidesk.ticket.duedate")} ${
                typeof ticket?.duedate !== "undefined" && ticket?.duedate
                  ? getFormat(ticket.duedate)
                  : t("chat-agidesk.ticket.no-duedate")
              }`}
            >
              <i className="las la-clock mr-1" />
              <span className="mr-2 text-[10px]">
                {typeof ticket?.duedate !== "undefined" && ticket?.duedate
                  ? getFormat(ticket.duedate)
                  : t("chat-agidesk.ticket.no-duedate")}
              </span>
            </div>
            <div
              className="flex items-center"
              title={`${t("chat-agidesk.ticket.comments")} ${
                ticket?.metrics?.comments || 0
              }`}
            >
              <i className="las la-comments mr-1" />
              <span className="text-[10px]">
                {ticket?.metrics?.comments || 0}
              </span>
            </div>
          </div>

          <div
            title={`${t("chat-agidesk.ticket.updated_at")} ${
              ticket?.updated_at ? getDateFormat(ticket?.updated_at) : "-"
            }`}
            className="flex items-center max-w-1/2 text-end"
          >
            {ticket?.updated_at ? (
              <span className="text-[10px] justify-end flex text-gray-999 group-hover:text-black">
                {`${t("chat-agidesk.ticket.updated_at")} ${
                  ticket?.updated_at ? getDateFormat(ticket?.updated_at) : "-"
                }`}
              </span>
            ) : null}
          </div>
        </div>

        {/* SECOND LINE */}
        <div className="flex items-center justify-between">
          {/* STATUS / PRIORITY */}
          <div className="flex flex-col max-w-1/2">
            <div
              title={`${t("chat-agidesk.ticket.status")} ${
                ticket?.status?.title
              }`}
              style={{
                backgroundColor: ticket?.status?.color || undefined,
              }}
              className="mb-[1px] flex items-center justify-center w-[80px] py-[1px] px-2 rounded-[4px] bg-gray-999 text-white text-[9px]"
            >
              {ticket?.status?.title}
            </div>
            <div
              title={`${t("chat-agidesk.ticket.priority")} ${
                ticket?.priority?.title || t("chat-agidesk.ticket.no-priority")
              }`}
              style={{
                backgroundColor: ticket?.priority?.color || undefined,
              }}
              className="mb-[1px] flex items-center justify-center w-[80px] py-[1px] px-2 rounded-[4px] bg-gray-999 text-white text-[9px]"
            >
              {ticket?.priority?.title || t("chat-agidesk.ticket.no-priority")}
            </div>
          </div>
          {/* CONTACT / CUSTOMER */}
          <div className="flex items-center justify-end text-end w-1/2">
            <div
              title={`${t("chat-agidesk.ticket.contact")} ${
                ticket?.contact
              }\n${t("chat-agidesk.ticket.customer")} ${ticket?.customer}`}
              className="flex flex-col items-end -mr-2"
            >
              <span
                title={`${t("chat-agidesk.ticket.contact")} ${ticket?.contact}`}
                className="text-[10px] text-end flex text-gray-999 group-hover:text-black word-wrap-1"
              >
                {ticket?.contact}
              </span>
              <span
                title={`${t("chat-agidesk.ticket.customer")} ${
                  ticket?.customer
                }`}
                className="text-[10px] text-end flex text-gray-999 group-hover:text-black word-wrap-1"
              >
                {ticket?.customer}
              </span>
            </div>
            <div
              title={`${t("chat-agidesk.ticket.contact")} ${ticket?.contact}`}
              className="min-h-[40px] min-w-[40px] h-[40px] w-[40px]"
            >
              <NoAvatar
                displaytext={ticket?.contact || "--"}
                elementClass="p-2 ml-2"
                labelClass="text-[10px]"
              />
            </div>
          </div>
        </div>

        {/* THIRD LINE */}
        <div className="flex items-center justify-between text-gray-999 group-hover:text-black -mb-2">
          {/* RESPONSIBLE / TEAM */}
          <div className="flex items-center w-1/2">
            <div
              title={`${t("chat-agidesk.ticket.responsible")} ${
                ticket?.responsible?.fulltitle ||
                t("chat-agidesk.ticket.no-responsible")
              }`}
              className="min-h-[40px] min-w-[40px] h-[40px] w-[40px]"
            >
              <NoAvatar
                displaytext={
                  ticket?.responsible?.fulltitle ||
                  ticket?.team?.fulltitle ||
                  "--"
                }
                elementClass="p-2 -ml-2"
                labelClass="text-[10px]"
              />
            </div>
            <div
              title={`${t("chat-agidesk.ticket.responsible")} ${
                ticket?.responsible?.fulltitle ||
                t("chat-agidesk.ticket.no-responsible")
              }\n${t("chat-agidesk.ticket.team")} ${ticket?.team?.fulltitle}`}
              className="flex flex-col -ml-2"
            >
              <span
                title={`${t("chat-agidesk.ticket.responsible")} ${
                  ticket?.responsible?.fulltitle ||
                  t("chat-agidesk.ticket.no-responsible")
                }`}
                className="text-[10px] flex"
              >
                {ticket?.responsible?.fulltitle ||
                  t("chat-agidesk.ticket.no-responsible")}
              </span>
              <span
                title={`${t("chat-agidesk.ticket.team")} ${
                  ticket?.team?.fulltitle
                }`}
                className="text-[10px] flex"
              >
                {ticket?.team?.fulltitle}
              </span>
            </div>
          </div>
          {/* LASTCOMMENT */}
          <div
            title={`${t("chat-agidesk.ticket.lastcomment.title")} ${
              ticket?.lastcomment?.content
            }`}
            className="flex items-end text-end text-[10px] w-1/2"
          >
            <span className="word-wrap-2">
              {`${
                ticket?.lastcomment?.author
                  ? ticket?.lastcomment?.author?.firstname
                  : ""
              } (${
                ticket?.lastcomment?.privacy_id &&
                ticket?.lastcomment?.privacy_id === "3"
                  ? t("chat-agidesk.ticket.lastcomment.contact")
                  : t("chat-agidesk.ticket.lastcomment.user")
              }),\n${t("chat-agidesk.ticket.lastcomment.at")} ${
                ticket?.updated_at ? getDateFormat(ticket?.updated_at) : "-"
              }: ${ticket?.lastcomment?.content}`}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketCard;
