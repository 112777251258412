import React, { useEffect } from "react";
import { DropdownSearchInputProps, Form } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import Dropdown from "../../../shared/components/Dropdown.component";
import useCampaigns from "../../../hooks/features/useCampaigns";
import useUserIsAdmin from "../../../hooks/useUserIsAdmin";
import { useAppSelector } from "../../../hooks/redux/hooks";
import { WhatsAppTemplate } from "../../messages/components/WhatsApp";
import PopupComponent from "../../../shared/components/Popup.component";

const TemplateSection = () => {
  const navigate = useNavigate();
  const { dropdown, template, effect } = useCampaigns();
  const { isAdmin } = useUserIsAdmin();
  const { selectedCampaign, selectedLocalCampaign } = useAppSelector(
    (state) => state.campaigns
  );
  const { selectedTemplate } = useAppSelector((state) => state.messages);

  useEffect(() => {
    effect.template();
  }, [effect.template]);

  return (
    <Form>
      <PopupComponent
        className="flex items-center mb-1"
        label="Número"
        required
        content={
          <h1 className="font-semibold text-[12px]">
            Confira o limite de mensagens na sua conta META
          </h1>
        }
      />
      <Dropdown
        className="w-full"
        id="dropdown-campaign-externalapp"
        placeholder="Número"
        // required
        fluid
        search
        selection
        options={dropdown.options.externalapp}
        clearable
        onChange={(e, { value }) => {
          dropdown.onChange.externalapp(e, { value });
        }}
        onSearchChange={(e: DropdownSearchInputProps) => {
          dropdown.onSearch.externalapp(e.target.value);
        }}
        value={dropdown.value.externalapp}
        onOpen={dropdown.onOpen.externalapp}
        onFocus={dropdown.onFocus.externalapp}
        onBlur={dropdown.onBlur}
        gotoTitle={
          typeof selectedLocalCampaign?.externalapp?.fields?.name !==
          "undefined"
            ? `Ir para ${selectedLocalCampaign?.externalapp?.fields?.name}`
            : undefined
        }
        gotoClick={
          typeof selectedLocalCampaign?.externalapp?.fields?.name !==
          "undefined"
            ? () => {
                navigate(
                  `/settings/integration/whatsapp/${selectedLocalCampaign?.externalapp?._id}`
                );
              }
            : undefined
        }
      />
      <Dropdown
        id="dropdown-campaign-templates"
        loading={dropdown.isLoading.template}
        label="Templates"
        fluid
        search
        required
        clearable
        selection
        placeholder="Templates"
        disabled={
          selectedLocalCampaign?.deleted ||
          !selectedLocalCampaign?.externalapp ||
          !isAdmin
        }
        options={dropdown.options.template}
        value={dropdown.value.template}
        onOpen={dropdown.onOpen.template}
        onFocus={dropdown.onFocus.template}
        onBlur={dropdown.onBlur}
        onChange={(e, { value }) => {
          dropdown.onChange.template(e, { value });
        }}
      />
      {typeof selectedTemplate?.id !== "undefined" ? (
        <WhatsAppTemplate
          campaign={
            typeof selectedCampaign?._id !== "undefined"
              ? selectedCampaign
              : undefined
          }
          contactChat={undefined}
          results={selectedTemplate}
          files={template.files}
          setFiles={template.setFiles}
          setTemplateButtons={template.setButtons}
        />
      ) : null}
    </Form>
  );
};

export default TemplateSection;
