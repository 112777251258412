import React from "react";

const Avatar = ({
  path,
  name,
  title,
  extraClass,
}: {
  path: string;
  name: string;
  title?: string;
  extraClass?: string;
}) => {
  const URL = process.env.REACT_APP_API_BASE_URL;

  return (
    <div
      className={`${extraClass} w-full h-full rounded-full bg-cover`}
      style={{
        backgroundImage: `url(${`${URL}/api/files/${path}/${name}`})`,
      }}
      title={title}
    />
  );
};

Avatar.defaultProps = {
  title: undefined,
  extraClass: "",
};

export default Avatar;
