const tenantAllowed = ({
  sessionTenant,
  receivedTenant,
}: {
  sessionTenant: string;
  receivedTenant: string;
}) => {
  const _sessionTenant = sessionTenant || "";
  const _receivedTenant = receivedTenant || "";

  return (
    _sessionTenant.length > 0 &&
    _receivedTenant.length > 0 &&
    _sessionTenant === _receivedTenant
  );
};

export default tenantAllowed;
