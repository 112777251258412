import React from "react";
import { useTranslation } from "react-i18next";
import NotificationBadge from "../NotificationBadge.component";

const ToggleInbox = ({
  toggleList,
  setToggleList,
  notificationsBacklog,
  notificationsMe,
  notificationsTeams,
}: {
  toggleList: string;
  setToggleList: React.Dispatch<React.SetStateAction<string>>;
  notificationsBacklog?: number;
  notificationsMe?: number;
  notificationsTeams?: number;
}) => {
  const { t } = useTranslation();
  return (
    <div className="cursor-pointer flex items-center ml-2">
      <div
        role="presentation"
        className={`group h-[28px] w-[58px] border rounded-tl-[20px] rounded-bl-[20px] px-[10px] py-[6px] relative text-center flex items-center justify-center hover:border-gray-bench hover:bg-agitalks-alphaC0
        ${
          toggleList === "backlog"
            ? "bg-agitalks-alphaC0 border-gray-bench"
            : "bg-white border-gray-bench border-r-0"
        } 
          `}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setToggleList("backlog");
        }}
      >
        <div
          className={`border-none flex self-center active:text-white group-active:text-white group-hover:text-white ${
            toggleList === "backlog" ? "text-white" : "text-agitalks"
          } ${
            notificationsBacklog && notificationsBacklog > 0 ? "mr-[5px]" : ""
          }
          `}
          title={t("toggle-selector.backlog") || ""}
        >
          <i className="las la-comment-dots text-[18px] hover:cursor-auto" />
        </div>
        {notificationsBacklog && notificationsBacklog > 0 ? (
          <NotificationBadge badge={notificationsBacklog} />
        ) : null}
      </div>
      <div
        role="presentation"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setToggleList("mine");
        }}
        className={`group h-[28px] w-[58px] border px-[2px] py-[6px] relative text-center flex items-center justify-center hover:border-gray-bench hover:bg-agitalks-alphaC0
        ${
          toggleList === "mine"
            ? "bg-agitalks-alphaC0 border-gray-bench"
            : "bg-white border-gray-bench"
        } 
        `}
      >
        <div
          className={`border-none flex self-center active:text-white group-active:text-white group-hover:text-white ${
            toggleList === "mine" ? "text-white" : "text-agitalks"
          }  ${notificationsMe && notificationsMe > 0 ? "mr-[5px]" : ""}
          `}
          title={t("toggle-selector.me") || ""}
        >
          <i className="las la-inbox text-[18px] hover:cursor-auto" />
        </div>
        {notificationsMe && notificationsMe > 0 ? (
          <NotificationBadge badge={notificationsMe} />
        ) : null}
      </div>
      <div
        role="presentation"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setToggleList("teams");
        }}
        className={`group h-[28px] w-[58px] border rounded-tr-[20px] rounded-br-[20px] px-[2px] py-[6px] relative text-center flex items-center justify-center
        hover:border-gray-bench hover:bg-agitalks-alphaC0
        ${
          toggleList === "teams"
            ? "bg-agitalks-alphaC0 border-gray-bench"
            : "bg-white border-gray-bench border-l-0"
        } 
        `}
      >
        <div
          className={`border-none flex self-center  active:text-white  group-active:text-white group-hover:text-white ${
            toggleList === "teams" ? "text-white" : "text-agitalks"
          }  ${notificationsTeams && notificationsTeams > 0 ? "mr-[5px]" : ""}
          `}
          title={t("toggle-selector.teams") || ""}
        >
          <i className="las la-users text-[18px] hover:cursor-auto" />
        </div>
        {notificationsTeams && notificationsTeams > 0 ? (
          <NotificationBadge badge={notificationsTeams} />
        ) : null}
      </div>
    </div>
  );
};

ToggleInbox.defaultProps = {
  notificationsBacklog: 0,
  notificationsMe: 0,
  notificationsTeams: 0,
};

export default ToggleInbox;
