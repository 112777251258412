/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useDropzone } from "react-dropzone";
import { Sidebar } from "semantic-ui-react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useInView } from "react-intersection-observer";
import {
  changeUserMessage,
  createMessage,
  finishChatBotmaker,
  IFinishChatBotmaker,
  IMessage,
  INewMessage,
  INewMessageBotmaker,
  INewMessageWpp,
  InternalMessageType,
  Messages,
  MessageType,
  readAll,
  selectMessages,
  selectTotalMessages,
  sendMessageBotmaker,
  sendMessageWpp,
  typingMessage,
  disableChatCard,
} from "../../../slices/messages.slice";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/hooks";
import { IAcceptedFiles } from "../../../shared/models/interfaces/acceptedfile.interface";
import {
  chatIsScrolling,
  IChat,
  ChatStatus,
  updateChat,
  reselectChat,
  clearNotifications,
  ChatType,
  changeChat,
  ChatGroupRole,
  ChatAdmin,
  changeUserChat,
  spamContactChats,
} from "../../../slices/chats.slice";
import SearchBox from "../../../shared/components/SearchBox.component";
import LoadingDots from "../../../shared/components/LoadingDots.component";
import ChatHeader from "../../messages/components/ChatHeader.component";
import MessagesList from "../../messages/components/MessagesList.component";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { SidebarComponent } from "../../../shared/components/Sidebars";
import ChatDetails from "./Sidebar/Details/ChatDetails.component";
import { Reply, SendBox, Thumbs } from "../../messages/components/Sendbox";
import WaitingRoom from "../../widgets/components/WaitingRoom.component";
import ConfirmationModal from "../../../shared/components/Modals/ConfirmationModal.component";
import {
  IUser,
  RoleUser,
  UserStatus,
  UserType,
  changeUser,
} from "../../../slices/users.slice";
// import useOnScreen from "../../../hooks/useOnScreen";
import NotificationBadge from "./NotificationBadge.component";
import removeDuplicates from "../../../shared/utils/removeDuplicates";
import Button from "../../../shared/components/Buttons/Button.component";
import ChatTransfer from "./Sidebar/ChatTransfer.component";
import ChatAgidesk from "./Sidebar/Agidesk/ChatAgidesk.component";
import ChatGroupDetails from "./Sidebar/Details/ChatGroupDetails.component";
import customToast from "../../../shared/utils/customToast";
import { IToastType } from "../../../shared/models/types/Toast.type";
import removeActiveChat from "../../../shared/utils/reassignment/removeActiveChat";
import { teamsChatsAssignment } from "../../../slices/teams.slice";
import populateExternalAppToChat from "../../../shared/utils/populateExternalAppToChat";
import { chatsService, messagesService, usersService } from "../../../services";
import useContactLogout from "../../../hooks/useContactLogout";
import RelationModal from "../../../shared/components/Modals/RelationModal.component";
import useSendInternalMessage from "../../../hooks/useSendInternalMessage";
import {
  IChatPreferences,
  prepareDraftThumbs,
  storeUserChatPreferences,
} from "../../../slices/preferences.slice";
import useUserIsAdmin from "../../../hooks/useUserIsAdmin";

// Todo: prevent clearing preferences on first launch
function useFirstRender() {
  const firstRender = useRef(true);

  useEffect(() => {
    firstRender.current = false;
  }, []);

  return firstRender.current;
}

const Conversation = ({
  toggleList,
  setToggleList,
  widgetType,
  disable,
  setDisable,
  setShowHistory,
}: {
  disable: boolean;
  setDisable: React.Dispatch<React.SetStateAction<boolean>>;
  toggleList: string;
  setToggleList: React.Dispatch<React.SetStateAction<string>>;
  widgetType?: UserType;
  setShowHistory?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const firstRender = useFirstRender();

  const { isAdmin } = useUserIsAdmin();
  const { sendInternalMessage } = useSendInternalMessage();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { height } = useWindowDimensions();
  const { logoutContact } = useContactLogout();

  const { messages, totalMessages, isReading } = useAppSelector(
    (state) => state.messages
  );
  const {
    selectedChat,
    scrolling,
    isWaitingAgent,
    mineChats,
    selectedHistoryContact,
  } = useAppSelector((state) => state.chats);
  const { user } = useAppSelector((state) => state.auth);
  const { preferences } = useAppSelector((state) => state.preferences);

  const [chatContact, setChatContact] = useState<IUser | undefined>();
  const [renderData, setRenderData] = useState<Messages>([]);
  const [skip, setSkip] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingMessages, setIsLoadingMessages] = useState<boolean>(true);
  const [hasBefore, setHasBefore] = useState<boolean>(false);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [visible, setVisible] = useState<boolean>(false);
  const [ticketsActions, setTicketsActions] = useState<{
    show: boolean;
    update: boolean;
    newTicket: boolean;
  }>({ show: true, update: false, newTicket: false });
  const [lastUrl, setLastUrl] = useState<string>("");

  const [whoIsTalking, setWhoIsTalking] = useState<string>();
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [isReplying, setIsReplying] = useState<boolean>(false);
  const [messageSent, setMessageSent] = useState<boolean>(false);
  const [messageReplied, setMessageReplied] = useState<IMessage | null>(null);
  const [thumbs, setThumbs] = useState<IAcceptedFiles>([]);

  const [blockSidebar, setBlockSidebar] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showSuspendModal, setShowSuspendModal] = useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [showSpamModal, setShowSpamModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showTagsModal, setShowTagsModal] = useState<boolean>(false);
  const [showLeaveModal, setShowLeaveModal] = useState<boolean>(false);
  const [showDeleteGroupModal, setShowDeleteGroupModal] =
    useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [chatPreferences, setChatPreferences] =
    useState<IChatPreferences | null>(null);
  const { ref, inView } = useInView();
  // const [listRef] = useOnScreen({
  //   hasMore: hasBefore,
  //   isLoading,
  //   setSkip,
  // });
  const endRef = useRef<null | HTMLDivElement>(null);

  const getFocus = () => {
    setVisible(false);
    window.setTimeout(() => {
      if (
        searchKeyword?.length > 0 ||
        document.activeElement === document.getElementById("searchbox-messages")
      ) {
        document.getElementById("searchbox-messages")?.focus();
      } else {
        document.getElementById("message-textarea")?.focus();
      }
    }, 0);
  };

  const storeChatPreferences = () => {
    if (!firstRender) {
      dispatch(
        storeUserChatPreferences({
          user: user?._id,
          _id: selectedChat?._id,
          chat: selectedChat || undefined,
          draftMessage: message,
          thumbs,
        })
      );
    }
  };

  const handleDraftThumbs = () => {
    if (typeof chatPreferences !== "undefined" && chatPreferences !== null) {
      const preparedThumbs = prepareDraftThumbs(chatPreferences);
      return typeof preparedThumbs !== "undefined" && preparedThumbs.length > 0
        ? preparedThumbs
        : undefined;
    }
    return undefined;
  };

  const stopTyping = () => {
    if (!selectedChat) return;
    dispatch(
      typingMessage({
        chat: selectedChat,
        sender: user?._id || "",
        message: "",
        type: "text",
      })
    );
  };

  const applyChatPreferences = () => {
    if (!selectedChat) return;
    const _currentMessage =
      typeof chatPreferences !== "undefined" &&
      typeof chatPreferences?.draftMessage !== "undefined"
        ? chatPreferences.draftMessage
        : undefined;
    const applyDraft = typeof _currentMessage !== "undefined";
    setMessage(applyDraft ? _currentMessage : "");
    setIsFocused(applyDraft);
    setTimeout(() => stopTyping(), 300);

    // Check thumbs
    const applyThumbs = handleDraftThumbs();
    setThumbs(typeof applyThumbs !== "undefined" ? applyThumbs : []);
  };

  const loadChatPreferences = () => {
    const userIndex = preferences?.findIndex(
      (object) => object.user === user?._id
    );

    const userPreferences =
      typeof preferences !== "undefined" &&
      typeof userIndex !== "undefined" &&
      userIndex !== -1
        ? preferences[userIndex].chats
        : undefined;

    const chatIndex =
      typeof userPreferences !== "undefined"
        ? userPreferences.findIndex(
            (object) => object._id === selectedChat?._id
          )
        : undefined;

    if (
      typeof userPreferences !== "undefined" &&
      typeof chatIndex !== "undefined" &&
      chatIndex !== -1
    ) {
      setChatPreferences(userPreferences[chatIndex]);
      return true;
    }
    setThumbs([]);
    setMessage("");
    return false;
  };
  useEffect(() => {
    applyChatPreferences();
  }, [chatPreferences]);

  useEffect(() => {
    if (blockSidebar && !showModal) {
      navigate(`${location.pathname}#group`);
      setVisible(true);
    }
  }, [showModal]);

  useEffect(() => {
    if (
      widgetType === UserType.CONTACT &&
      typeof selectedChat?.status !== "undefined" &&
      selectedChat?.status === ChatStatus.FINISH &&
      typeof selectedChat?.users !== "undefined" &&
      selectedChat?.users?.length === 1
    ) {
      (async () => {
        await logoutContact({});
      })();
    }
  }, [selectedChat?.status]);

  useEffect(() => {
    setRenderData([]);
    setIsLoading(true);
    setSkip(0);
    setDisable(false);
    loadChatPreferences();
    setTicketsActions({
      show: true,
      update: false,
      newTicket: false,
    });
  }, [selectedChat?._id]);

  useEffect(() => {
    if (skip + 10 >= totalMessages) {
      setHasBefore(false);
    } else {
      setHasBefore(true);
    }
    setDisable(false);
    setRenderData(messages);
  }, [messages]);

  const getChatContact = () => {
    const _users = selectedChat?.users;
    if (typeof _users !== "undefined") {
      if (_users?.length === 1) {
        return _users.find((_user) => _user._id !== user?._id);
      }
      if (
        _users?.length === 2 &&
        typeof selectedChat?.users?.find((_user) => _user._id === user?._id) !==
          "undefined"
      ) {
        return _users.find((_user) => _user._id !== user?._id);
      }
      return _users.find(
        (_user) =>
          _user?._id !== user?._id &&
          typeof _user?.roles !== "undefined" &&
          _user?.roles?.length === 1 &&
          _user?.roles?.includes(RoleUser.CONTACT)
      );
    }
    return undefined;
  };

  useEffect(() => {
    if (isLoading || skip > 0) {
      setDisable(false);
      setChatContact(getChatContact());
      getFocus();
      const _getSkip = () => (totalMessages === 0 && skip + 10 > 10 ? 0 : skip);

      if (
        typeof getChatContact()?._id !== "undefined" &&
        typeof selectedHistoryContact?.key !== "undefined" &&
        getChatContact()?._id === selectedHistoryContact?.key
        // &&
        // typeof selectedChat?.transferred !== "undefined" &&
        // selectedChat?.transferred &&
        // searchKeyword?.length === 0
      ) {
        setRenderData([]);
        (async () => {
          if (typeof selectedChat?._id !== "undefined") {
            setIsLoadingMessages(true);
            const payload = await messagesService.search({
              _id: selectedChat?._id || "",
              skip: _getSkip(),
              limit: 10,
              filter:
                searchKeyword?.length > 0
                  ? `{"content":"${searchKeyword}"}`
                  : "",
              history: true,
            });
            const _items =
              typeof payload?.results !== "undefined" &&
              payload?.results?.length > 0
                ? payload.results
                : [];
            const _results =
              _items.length > 0
                ? _items?.sort((a: IMessage, b: IMessage) =>
                    (b?.created_at || "") > (a?.created_at || "")
                      ? -1
                      : (a?.created_at || "") > (b?.created_at || "")
                      ? 1
                      : 0
                  )
                : [];
            setIsLoading(false);
            setHasBefore(false);
            setRenderData(_results);
            setIsLoadingMessages(false);
          }
        })();
      } else {
        (async () => {
          if (typeof selectedChat?._id !== "undefined") {
            setIsLoadingMessages(true);
            const payload = await messagesService.search({
              _id: selectedChat?._id || "",
              skip: _getSkip(),
              limit: 10,
              filter:
                searchKeyword?.length > 0
                  ? `{"content":"${searchKeyword}"}`
                  : "",
              history: false,
            });
            const _results =
              typeof payload?.results !== "undefined"
                ? payload?.results?.reverse()
                : [];
            setIsLoading(false);
            if (_getSkip() + 10 >= payload?.count) {
              setHasBefore(false);
            } else {
              setHasBefore(true);
            }
            if (_getSkip() > 0) {
              setRenderData(_results.concat(renderData));
              dispatch(selectMessages(_results.concat(renderData)));
            } else {
              setRenderData(_results);
              dispatch(selectMessages(_results));
            }
            dispatch(selectTotalMessages(payload?.count || 0));
          }
          setTimeout(() => {
            dispatch(
              disableChatCard({ disable: false, chat: selectedChat?._id })
            );
          }, 250);
          setIsLoadingMessages(false);
        })();
      }
    }
  }, [skip, isLoading, searchKeyword]);

  useEffect(() => {
    if (inView && hasBefore) {
      setSkip((prevState) => prevState + 10);
    }
  }, [inView]);

  useEffect(() => {
    storeChatPreferences();
  }, [thumbs?.length]);

  useEffect(() => {
    setDisable(false);
    if (!selectedChat) return;
    dispatch(
      typingMessage({
        chat: selectedChat,
        sender: user?._id || "",
        message,
        type: "text",
      })
    );
    storeChatPreferences();
  }, [message?.length]);

  useEffect(() => {
    if (!selectedChat) return;
    if (!isFocused) stopTyping();
  }, [isFocused]);

  useEffect(() => {
    if (
      mineChats.find((c: IChat) => c._id === selectedChat?._id)?.istyping ===
      true
    )
      setIsTyping(true);
    else setIsTyping(false);
  });

  useEffect(() => {
    if (
      mineChats.find((c: IChat) => c._id === selectedChat?._id)
        ?.isrecordaudio === true
    )
      setIsRecording(true);
    else setIsRecording(false);
  });

  useEffect(() => {
    if (
      typeof mineChats.find((c: IChat) => c._id === selectedChat?._id)
        ?.whoIsTalking !== "undefined"
    ) {
      setWhoIsTalking(
        mineChats.find((c: IChat) => c._id === selectedChat?._id)?.whoIsTalking
      );
    }
  });

  useEffect(
    () => () => {
      window.URL = window.URL || window.webkitURL;
      thumbs.forEach((file) => window.URL.revokeObjectURL(file.preview || ""));
    },
    [thumbs]
  );
  useEffect(() => {
    setDisable(false);
    setTimeout(async () => {
      if (selectedChat?.notifications && selectedChat?.notifications > 0)
        if (
          !scrolling &&
          user?.status === UserStatus.ONLINE &&
          document.hasFocus()
        ) {
          await dispatch(readAll(selectedChat?._id || ""));
          dispatch(
            clearNotifications(toggleList === "backlog" ? "backlog" : "mine")
          );
        }
    }, 2000);
  }, [selectedChat, scrolling, document.hasFocus()]);

  const onDrop = useCallback(
    (
      acceptedFiles: IAcceptedFiles
      // fileRejections: FileRejection[],
      // event: DropEvent
    ) => {
      window.URL = window.URL || window.webkitURL;
      acceptedFiles.map((file) => {
        setThumbs((oldArray) => [
          Object.assign(file, {
            preview: window.URL.createObjectURL(file),
          }),
          ...oldArray,
        ]);
        return true;
      });
    },
    []
  );

  const accept = {
    "image/*": [],
    "video/*": [],
    "audio/*": [],
    "text/html": [".htm", ".html"],
    "text/plain": [".txt"],
    "text/csv": [".csv"],
    "application/msword": [".doc"],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
      ".docx",
    ],
    "application/gzip": [".gz"],
    "application/vnd.oasis.opendocument.presentation": [".odp"],
    "application/vnd.oasis.opendocument.spreadsheet": [".ods"],
    "application/vnd.oasis.opendocument.text": [".odt"],
    "application/pdf": [".pdf"],
    "application/vnd.ms-powerpoint": [".ppt"],
    "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      [".pptx"],
    "application/vnd.rar": [],
    "application/rtf": [".rtf"],
    "application/vnd.ms-excel": [".xls"],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
      ".xlsx",
    ],
    "application/x-zip-compressed": [".zip"],
    "application/x-7z-compressed": [".7z"],
  };

  const acceptWhatsApp = {
    "image/*": [".jpeg", ".png", ".webp"],
    "video/*": [".mp4", ".3gp"],
    // audio: [".acc", ".mp4", ".mpeg", ".amr", ".ogg", ".oga"],
    "audio/*": [".acc", ".mpeg", ".ogg"],
    "text/plain": [".txt"],
    "application/pdf": [".pdf"],
    "application/vnd.ms-powerpoint": [".ppt"],
    "application/msword": [".doc"],
    "application/vnd.ms-excel": [".xls"],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
      ".docx",
    ],
    "application/vnd.openxmlformats-officedocument.presentationml.presentation":
      [".pptx"],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
      ".xlsx",
    ],
  };

  const activeStyle = {
    borderColor: "#2196F3",
  };

  const acceptStyle = {
    borderColor: "#00E676",
  };

  const rejectStyle = {
    borderColor: "#DB2828",
  };

  const baseStyle = {
    display: "flex",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "transparent",
    borderStyle: "dashed",
    transition: "border .3s ease-in-out",
  };

  const {
    getRootProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    getInputProps,
  } = useDropzone({
    onDrop,
    onDropRejected(fileRejections) {
      const errors: string[] = [];
      for (const _fileRejection of fileRejections) {
        if (_fileRejection.errors?.length > 0) {
          _fileRejection.errors.forEach((_error) => {
            if (!errors.includes(_error.code)) errors.push(_error.code);
            // Codes: file-too-large, too-many-files, file-invalid-type
          });
        }
      }
      if (errors?.length > 0) {
        errors.forEach((_error) => {
          customToast({
            type: IToastType.WARNING,
            message: t(`toast.error.files.${_error}`),
          });
        });
      }
    },
    minSize: 1,
    maxFiles: 10,
    maxSize: 20000000,
    accept: selectedChat?.type === ChatType.WHATSAPP ? acceptWhatsApp : accept,
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const scrollHandler = (event: React.UIEvent<HTMLDivElement>) => {
    const startScrolling = 100;
    const showingMomentum =
      event.currentTarget.scrollHeight -
      event.currentTarget.clientHeight -
      startScrolling;

    dispatch(chatIsScrolling(event.currentTarget.scrollTop <= showingMomentum));
  };

  const sendSuspendInternalMessage = async () => {
    if (!selectedChat) return false;
    const newMessage: INewMessage = {
      chat: selectedChat,
      content: "O contato cancelou a solicitação",
      files: [],
      parent: null,
      internal: true,
      read: true,
    };
    const { payload } = await dispatch(createMessage(newMessage));
    if (payload) {
      if (widgetType === UserType.CONTACT) {
        setDisable(false);
        setShowSuspendModal(false);
        await logoutContact({});
      }
      return true;
    }
    return false;
  };

  const deleteGroupChat = async () => {
    let _message: IMessage | undefined | null;
    if (typeof selectedChat?._id !== "undefined") {
      _message = await sendInternalMessage({
        type: InternalMessageType.REMOVE_GROUP,
        message: {
          chat: { ...selectedChat, status: ChatStatus.FINISH },
          content: `<b>[${user?._id}]</b> excluiu o grupo.`,
        },
      });
    }
    if (typeof _message !== "undefined" && _message) {
      const _groupUsers = selectedChat?.groupsettings?.map(
        (_groupsetting: ChatAdmin) => {
          const _setting = {
            ..._groupsetting,
            active: false,
            role: [ChatGroupRole.READER],
          };
          return _setting;
        }
      );
      if (typeof selectedChat?._id !== "undefined") {
        const _chat = await chatsService.updateGroup({
          _chat: {
            _id: selectedChat?._id,
            avatar: selectedChat?.avatar?._id,
            name: selectedChat?.name,
            users: [],
            groupsettings: _groupUsers,
          },
        });
        setShowDeleteGroupModal(false);
        if (typeof _chat !== "undefined" && _chat !== null) {
          dispatch(
            reselectChat({
              chat: {
                ..._chat,
                lastmessage: _message,
                status: ChatStatus.FINISH,
                users: selectedChat?.users,
                updated_at: _chat.updated_at,
                updated_by: _chat.updated_by,
              },
            })
          );
          dispatch(
            changeChat({
              chat: {
                ..._chat,
                lastmessage: _message,
                status: ChatStatus.FINISH,
                users: selectedChat?.users,
                updated_at: _chat.updated_at,
                updated_by: _chat.updated_by,
              },
            })
          );
          // customToast({
          //   message: "Grupo excluído com sucesso!",
          //   type: IToastType.SUCCESS,
          // });
        } else {
          customToast({
            message: "Algo deu errado!",
            type: IToastType.ERROR,
          });
        }
      }
    }
  };

  const leaveGroupChat = async () => {
    const _myUserOnGroup = selectedChat?.groupsettings?.filter(
      (_g: ChatAdmin) =>
        typeof _g.user?._id !== "undefined"
          ? _g.user?._id === user?._id
          : _g.user === user?._id
    )[0];
    const _groupUsersWithoutMe = selectedChat?.groupsettings?.filter(
      (_g: ChatAdmin) =>
        typeof _g.user?._id !== "undefined"
          ? _g.user?._id !== user?._id
          : _g.user !== user?._id
    );
    _groupUsersWithoutMe?.push({
      ..._myUserOnGroup,
      chatavatar: _myUserOnGroup?.chatavatar || null,
      chatname: _myUserOnGroup?.chatname || null,
      active: false,
      role: [ChatGroupRole.READER],
    });
    let _message: IMessage | undefined | null;
    if (typeof selectedChat?._id !== "undefined") {
      _message = await sendInternalMessage({
        type: InternalMessageType.LEAVE_GROUP,
        message: {
          chat: selectedChat,
          content: `<b>[${user?._id}]</b> saiu.`,
        },
      });
      if (typeof _message !== "undefined" && _message) {
        const _chat = await chatsService.updateGroup({
          _chat: {
            _id: selectedChat?._id,
            avatar: selectedChat?.avatar?._id,
            name: selectedChat?.name,
            users: selectedChat?.users
              ?.filter((_user: IUser) => _user?._id !== user?._id)
              .map((_u: IUser) => _u._id as string),
            groupsettings: _groupUsersWithoutMe,
          },
        });
        setShowLeaveModal(false);
        if (typeof _chat !== "undefined" && _chat !== null) {
          dispatch(
            reselectChat({
              chat: {
                ..._chat,
                lastmessage: _message,
                users: selectedChat?.users?.filter(
                  (_user: IUser) => _user?._id !== user?._id
                ),
                updated_at: _chat.updated_at,
                updated_by: _chat.updated_by,
              },
            })
          );
          dispatch(
            changeChat({
              chat: {
                ..._chat,
                lastmessage: _message,
                users: selectedChat?.users?.filter(
                  (_user: IUser) => _user?._id !== user?._id
                ),
                updated_at: _chat.updated_at,
                updated_by: _chat.updated_by,
              },
            })
          );
          // customToast({
          //   message: "Alteração salva com sucesso!",
          //   type: IToastType.SUCCESS,
          // });
        } else {
          customToast({
            message: "Algo deu errado!",
            type: IToastType.ERROR,
          });
        }
      }
    }
  };

  const suspendChat = async () => {
    let _users =
      selectedChat?.users && selectedChat?.users?.length === 2
        ? selectedChat.users
        : selectedChat?.users && selectedChat?.users?.length === 1 && user?._id
        ? [...selectedChat.users, user]
        : [];

    _users = removeDuplicates(_users, "_id");

    const _updatedUsers = _users.map((_user: IUser) => _user._id);

    const _chat = {
      ...selectedChat,
      _id: selectedChat?._id,
      status: ChatStatus.SUSPEND,
      users: _updatedUsers,
    };
    const { payload } = await dispatch(updateChat(_chat));
    if (payload) {
      const _ret = await sendSuspendInternalMessage();
      if (_ret) {
        dispatch(
          reselectChat({
            prevStatus: ChatStatus.WAIT,
            widgetContact: true,
            chat: {
              ...payload,
              users: _users,
              isrecordaudio: false,
              istyping: false,
              whoIsTalking: undefined,
            },
          })
        );
        dispatch(
          changeChat({
            prevStatus: ChatStatus.WAIT,
            widgetContact: true,
            chat: {
              ...payload,
              users: _users,
              isrecordaudio: false,
              istyping: false,
              whoIsTalking: undefined,
            },
          })
        );
      }
      return true;
    }
    return false;
  };

  const contactFinishChat = async () => {
    if (!selectedChat) return;
    const _lastMessage = selectedChat?.lastmessage?._id
      ? selectedChat?.lastmessage
      : undefined;
    let _users =
      selectedChat?.users && selectedChat?.users?.length === 2
        ? selectedChat.users
        : selectedChat?.users && selectedChat?.users?.length === 1 && user?._id
        ? [...selectedChat.users, user]
        : [];

    _users = removeDuplicates(_users, "_id");

    const _updatedUsers = _users.map((_user: IUser) => _user._id);

    const _chat = {
      _id: selectedChat?._id,
      status: ChatStatus.FINISH,
      users: _updatedUsers,
      isrecordaudio: false,
      istyping: false,
    };
    const { payload } = await dispatch(updateChat(_chat));
    if (payload) {
      const newMessage: INewMessage = {
        chat: selectedChat,
        type: MessageType.USER,
        content: "O contato encerrou a conversa",
        files: [],
        parent: null,
        internal: true,
        read: true,
        origin: user?._id,
      };
      await dispatch(createMessage(newMessage));
      dispatch(
        reselectChat({
          prevStatus: ChatStatus.ACTIVE,
          widgetContact: true,
          agentid: _users?.filter(
            (_user) => !_user.roles?.includes(RoleUser.CONTACT)
          )[0]?._id,
          chat: {
            ...payload,
            lastmessage: _lastMessage,
            users: _users,
            isrecordaudio: false,
            istyping: false,
            whoIsTalking: undefined,
          },
        })
      );
      dispatch(
        changeChat({
          prevStatus: ChatStatus.ACTIVE,
          widgetContact: true,
          agentid: _users?.filter(
            (_user) => !_user.roles?.includes(RoleUser.CONTACT)
          )[0]?._id,
          chat: {
            ...payload,
            lastmessage: _lastMessage,
            users: _users,
            isrecordaudio: false,
            istyping: false,
            whoIsTalking: undefined,
          },
        })
      );
      // await logoutContact({});
    }
  };

  const userFinishChat = async () => {
    if (!selectedChat) return;
    const _lastMessage = selectedChat?.lastmessage?._id
      ? selectedChat?.lastmessage
      : undefined;
    let _users =
      selectedChat?.users && selectedChat?.users?.length === 2
        ? selectedChat.users
        : selectedChat?.users && selectedChat?.users?.length === 1 && user?._id
        ? [...selectedChat.users, user]
        : [];

    _users = removeDuplicates(_users, "_id");

    const _updatedUsers = _users.map((_user: IUser) => _user._id);

    const _chat = {
      _id: selectedChat?._id,
      status: ChatStatus.FINISH,
      users: _updatedUsers,
    };
    const { payload } = await dispatch(updateChat(_chat));

    if (payload) {
      const newMessage: INewMessage = {
        chat: selectedChat,
        content: `Agente <b>${user?.name}</b> encerrou a conversa.`,
        files: [],
        parent: null,
        internal: true,
        read: true,
      };
      const _action = await dispatch(createMessage(newMessage));
      if (
        _action &&
        _action.payload &&
        _action.payload?.chat?.type === ChatType.BOTMAKER
      ) {
        const newMessageBotmaker: INewMessageBotmaker = {
          externalapp: _action?.payload?.chat?.externalapp,
          externalcode: _action?.payload?.chat?.externalcode,
          externalcodephone: _action?.payload?.chat?.externalcodephone,
          phone: chatContact?.phone,
          message_id: _action?.payload?._id,
          messaging_product: "botmaker",
          to:
            `${
              selectedChat?.users?.find(
                (_user: IUser) => _user._id !== user?._id
              )?.phone
            }` || "",
          type: "text",
          recipient_type: "individual",
          message: {
            preview_url: true,
            text: `Agente *${user?.name}* encerrou a conversa.`,
          },
        };
        await dispatch(sendMessageBotmaker(newMessageBotmaker));

        const botmakerPars: IFinishChatBotmaker = {
          externalapp: _action?.payload?.chat?.externalapp,
          externalcode: _action?.payload?.chat?.externalcode,
          externalcodephone: _action?.payload?.chat?.externalcodephone,
          phone: chatContact?.phone,
        };
        await dispatch(finishChatBotmaker(botmakerPars));
      }
      if (
        _action &&
        _action.payload &&
        _action.payload?.chat?.type === ChatType.WHATSAPP
      ) {
        const newMessageWpp: INewMessageWpp = {
          externalapp:
            _action?.payload?.chat?.externalapp?._id ||
            _action?.payload?.chat?.externalapp ||
            undefined,
          message_id: _action?.payload?._id,
          messaging_product: "whatsapp",
          to:
            `${
              selectedChat?.users?.find(
                (_user: IUser) => _user._id !== user?._id
              )?.phone
            }` || "",
          type: "text",
          recipient_type: "individual",
          text: {
            preview_url: true,
            body: `Agente *${user?.name}* encerrou a conversa.`,
          },
        };
        await dispatch(sendMessageWpp(newMessageWpp));
      }
      const _chatPayload: IChat = await populateExternalAppToChat({
        chat: payload,
      });
      dispatch(
        reselectChat({
          prevStatus: ChatStatus.ACTIVE,
          chat: {
            ..._chatPayload,
            lastmessage: _lastMessage,
            users: _users,
            isrecordaudio: false,
            istyping: false,
            whoIsTalking: undefined,
          },
        })
      );
      dispatch(
        changeChat({
          prevStatus: ChatStatus.ACTIVE,
          chat: {
            ..._chatPayload,
            lastmessage: _lastMessage,
            users: _users,
            isrecordaudio: false,
            istyping: false,
            whoIsTalking: undefined,
          },
        })
      );
      await removeActiveChat({
        team: payload.team,
      });
      dispatch({
        type: "chats/removeFromMine",
        payload: _chatPayload,
      });
      await dispatch(teamsChatsAssignment());
    }
    setDisable(false);
  };

  const userCancelChat = async () => {
    if (!selectedChat) return;
    const _lastMessage = selectedChat?.lastmessage?._id
      ? selectedChat?.lastmessage
      : undefined;
    let _users =
      selectedChat?.users && selectedChat?.users?.length === 2
        ? selectedChat.users
        : selectedChat?.users && selectedChat?.users?.length === 1 && user?._id
        ? [...selectedChat.users, user]
        : [];

    _users = removeDuplicates(_users, "_id");

    const _updatedUsers = _users.map((_user: IUser) => _user._id);

    const _chat = {
      _id: selectedChat?._id,
      status: ChatStatus.CANCELED,
      users: _updatedUsers,
    };
    const { payload } = await dispatch(updateChat(_chat));

    if (payload) {
      const newMessage: INewMessage = {
        chat: selectedChat,
        content: `Agente <b>${user?.name}</b> cancelou a conversa.`,
        files: [],
        parent: null,
        internal: true,
        read: true,
        type: MessageType.USER,
      };
      await dispatch(createMessage(newMessage));
      const _chatPayload: IChat = await populateExternalAppToChat({
        chat: payload,
      });
      dispatch(
        reselectChat({
          prevStatus: _chatPayload?.status || ChatStatus.ACTIVE,
          chat: {
            ..._chatPayload,
            lastmessage: _lastMessage,
            users: _users,
            isrecordaudio: false,
            istyping: false,
            whoIsTalking: undefined,
          },
        })
      );
      dispatch(
        changeChat({
          prevStatus: _chatPayload?.status || ChatStatus.ACTIVE,
          chat: {
            ..._chatPayload,
            lastmessage: _lastMessage,
            users: _users,
            isrecordaudio: false,
            istyping: false,
            whoIsTalking: undefined,
          },
        })
      );
      await removeActiveChat({
        team: payload.team,
      });
      dispatch({
        type: "chats/removeFromMine",
        payload: _chatPayload,
      });
      await dispatch(teamsChatsAssignment());
    }
    setDisable(false);
  };

  const userSpamChat = async () => {
    if (
      typeof selectedChat?._id === "undefined" ||
      typeof chatContact?._id === "undefined"
    )
      return;
    const ids: string[] = [chatContact?._id];
    const payload = await usersService.spamContacts({
      ids,
      spam: true,
    });
    setShowSpamModal(false);
    if (
      payload !== null &&
      typeof payload !== "undefined" &&
      typeof payload?.message !== "string" &&
      payload.length > 0 &&
      typeof payload[0]._id !== "undefined"
    ) {
      const _contact = payload[0];
      dispatch(changeUserChat({ ..._contact }));
      dispatch(changeUserMessage({ ..._contact }));
      dispatch(changeUser({ ..._contact }));
      setChatContact({ ..._contact, spam: true });
      await dispatch(spamContactChats(ids));
      if (
        !selectedChat ||
        (typeof selectedChat?.status !== "undefined" &&
          [ChatStatus.CANCELED, ChatStatus.SUSPEND, ChatStatus.FINISH].includes(
            selectedChat.status
          ))
      )
        return;
      const _lastMessage = selectedChat?.lastmessage?._id
        ? selectedChat?.lastmessage
        : undefined;

      let _chat: IChat = {
        ...selectedChat,
        _id: selectedChat?._id,
        status: ChatStatus.CANCELED,
      };
      _chat = await populateExternalAppToChat({
        chat: _chat,
      });
      dispatch(
        reselectChat({
          prevStatus: _chat.status,
          chat: {
            ..._chat,
            lastmessage: _lastMessage,
            isrecordaudio: false,
            istyping: false,
            whoIsTalking: undefined,
          },
        })
      );
      dispatch(
        changeChat({
          prevStatus: _chat.status,
          chat: {
            ..._chat,
            lastmessage: _lastMessage,
            isrecordaudio: false,
            istyping: false,
            whoIsTalking: undefined,
          },
        })
      );
      if (typeof _chat?.users !== "undefined" && _chat.users.length > 1) {
        await removeActiveChat({
          team: _chat.team,
        });
        dispatch({
          type: "chats/removeFromMine",
          payload: _chat,
        });
      } else {
        dispatch({
          type: "chats/removeFromBacklog",
          payload: _chat,
        });
      }
      await dispatch(teamsChatsAssignment());
    }
    // dispatch(closeChat());
    setDisable(false);
  };

  const backFromDetails = () => {
    setDisable(false);
    if (lastUrl && !lastUrl.includes("infos")) {
      navigate(-1);
    } else {
      setVisible(false);
      navigate(location.pathname);
      setLastUrl("");
    }
  };
  const back = () => {
    setDisable(false);
    setVisible(false);
    navigate(location.pathname);
    setLastUrl("");
  };

  const getContent = () => {
    if (location.hash.includes("#group"))
      return (
        <ChatGroupDetails
          disable={disable}
          setDisable={setDisable}
          setVisible={setVisible}
          setChatContact={setChatContact}
          setLastUrl={setLastUrl}
          goBack={back}
          setBlockSidebar={setBlockSidebar}
          setShowModal={setShowModal}
          setShowDeleteModal={setShowDeleteGroupModal}
        />
      );
    if (location.hash.includes("#infos"))
      return (
        <ChatDetails
          disable={disable}
          setDisable={setDisable}
          setVisible={setVisible}
          chatContact={
            selectedChat?.type === ChatType.INTERNAL
              ? chatContact
              : getChatContact()
          }
          setChatContact={setChatContact}
          back={backFromDetails}
          setShowSpamModal={setShowSpamModal}
        />
      );
    if (location.hash.includes("#transfer"))
      return (
        <ChatTransfer
          disable={disable}
          setDisable={setDisable}
          setVisible={setVisible}
          chatContact={chatContact}
          setLastUrl={setLastUrl}
          setThumbs={setThumbs}
          goBack={back}
        />
      );
    if (
      location.hash.includes("#tickets") ||
      location.hash.includes("#newticket") ||
      location.hash.includes("#updateticket")
    ) {
      return (
        <ChatAgidesk
          visible={visible}
          disable={disable}
          setDisable={setDisable}
          setVisible={setVisible}
          chatContact={chatContact}
          setLastUrl={setLastUrl}
          actions={ticketsActions}
          setActions={setTicketsActions}
        />
      );
    }
    return null;
  };

  const showSendBox = () => {
    const isMeUserGroupActive =
      selectedChat?.groupchat &&
      selectedChat?.groupsettings?.filter(
        (_groupuser) => _groupuser.user === user?._id
      )[0]?.active === false;
    const isWaitingContactWpp = selectedChat?.status === ChatStatus.WAITREPLY;
    const isWaitingCampaign = selectedChat?.status === ChatStatus.WAITCAMPAIGN;
    const isMeChatuser =
      selectedChat?.users &&
      selectedChat?.users.some((_user) => _user._id === user?._id);
    const isBacklogChat =
      selectedChat?.type &&
      [ChatType.EXTERNAL, ChatType.WHATSAPP, ChatType.BOTMAKER].includes(
        selectedChat?.type
      ) &&
      selectedChat?.status === ChatStatus.WAIT &&
      selectedChat?.users?.length === 1;
    if (isBacklogChat) return true;
    if (isMeUserGroupActive) return false;
    if (isWaitingContactWpp || isWaitingCampaign || !isMeChatuser) return false;
    if (
      typeof getChatContact()?._id !== "undefined" &&
      typeof selectedHistoryContact?.key !== "undefined" &&
      getChatContact()?._id === selectedHistoryContact?.key
    )
      return false;
    return true;
  };

  return (
    <>
      <SidebarComponent
        visible={visible}
        blockSidebar={blockSidebar}
        setVisible={setVisible}
        content={getContent()}
        setLastUrl={setLastUrl}
        setShowTickets={setTicketsActions}
        // setUpdateTickets={setUpdate}
      />
      <Sidebar.Pusher
        className={`overflow-x-hidden border-r-0 transition-all ${
          widgetType !== UserType.NONE ? "w-[450px]" : ""
        } relative flex flex-1 flex-col ${
          isWaitingAgent && widgetType === UserType.CONTACT
            ? "bg-white"
            : "bg-background"
        } max-md:max-h-[${`${
          height - 44
        }px`}] min-2xl:rounded-l-xl max-md:border-l`}
      >
        {isLoading || isReading ? (
          <LoadingDots className="flex h-full justify-center items-center" />
        ) : isWaitingAgent && widgetType === UserType.CONTACT ? (
          <WaitingRoom setShowModal={setShowSuspendModal} />
        ) : (
          <>
            <ChatHeader
              setShowHistory={setShowHistory}
              widgetType={widgetType || UserType.NONE}
              whoIsTalking={whoIsTalking}
              isTyping={isTyping}
              isRecording={isRecording}
              setShowDeleteModal={setShowDeleteModal}
              setShowTagsModal={setShowTagsModal}
              setShowCancelModal={setShowCancelModal}
              setShowLeaveModal={setShowLeaveModal}
              setThumbs={setThumbs}
              setVisible={setVisible}
              toggleList={toggleList}
              chatContact={getChatContact()}
              setSkip={setSkip}
              historyMode={
                typeof getChatContact()?._id !== "undefined" &&
                typeof selectedHistoryContact?.key !== "undefined" &&
                getChatContact()?._id === selectedHistoryContact?.key
              }
            />
            <SearchBox
              id="searchbox-messages"
              placeholder="Pesquisar mensagens..."
              _escFunction={() => {
                setSearchKeyword("");
                setSkip(0);
              }}
              keyword={searchKeyword}
              listFilter={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchKeyword(e.target.value);
                setSkip(0);
              }}
            />
            <div
              id="messages-area"
              onScroll={scrollHandler}
              {...getRootProps({
                style,
                onClick: (event) => event.stopPropagation(),
                className:
                  "bg-gray-ef border-t-0 border-b-0 my-[5px] mx-0 transition-all flex-1 py-[15px] px-[25px] overflow-y-auto overflow-x-hidden relative flex flex-col relative",
              })}
            >
              <input {...getInputProps()} />
              {isLoading ? (
                <LoadingDots className="flex justify-center items-center" />
              ) : renderData?.length === 0 && searchKeyword?.length > 0 ? (
                <div
                  className="flex relative"
                  style={{ marginTop: 20, flex: 1 }}
                >
                  <h2 className="mx-auto my-0 mt-[50px]">
                    Nenhuma mensagem encontrada.
                  </h2>
                </div>
              ) : (
                <MessagesList
                  skip={skip}
                  isLoading={isLoading}
                  isLoadingMessages={isLoadingMessages}
                  widgetType={widgetType || UserType.NONE}
                  listRef={ref}
                  keyword={searchKeyword}
                  setKeyword={setSearchKeyword}
                  setIsReplying={setIsReplying}
                  setMessageReplied={setMessageReplied}
                  isTyping={isTyping}
                  isRecording={isRecording}
                  messages={removeDuplicates(renderData, "_id")}
                  messageSent={messageSent}
                  setMessageSent={setMessageSent}
                  endRef={endRef}
                  historyMode={
                    typeof getChatContact()?._id !== "undefined" &&
                    typeof selectedHistoryContact?.key !== "undefined" &&
                    getChatContact()?._id === selectedHistoryContact?.key
                  }
                />
              )}
            </div>
            <Button
              minWidth={false}
              onClick={() =>
                endRef.current?.scrollIntoView({ behavior: "smooth" })
              }
              extraClass={`py-6 px-6 mr-4 border-[2px] rounded-full flex absolute right-4 transition-all z-10 
              ${
                isReplying || thumbs?.length > 0
                  ? "bottom-[120px]"
                  : "bottom-[60px]"
              } 
              ${
                scrolling
                  ? "min-w-[40px] w-[40px] h-[45px] opacity-100"
                  : "w-0 h-0 opacity-0 !hidden"
              }
        `}
              icon="las la-angle-down text-[22px]"
              inverted
            />
            <div className="flex absolute right-7 bottom-16 transition-all z-10">
              {scrolling &&
              selectedChat?.notifications &&
              selectedChat?.notifications > 0 ? (
                <NotificationBadge badge={selectedChat?.notifications} />
              ) : null}
            </div>
            {showSendBox() ? (
              <>
                {thumbs?.length > 0 ? (
                  <Thumbs thumbs={thumbs} setThumbs={setThumbs} />
                ) : null}
                {isReplying ? (
                  <Reply
                    setIsReplying={setIsReplying}
                    messageReplied={messageReplied}
                    setMessageReplied={setMessageReplied}
                  />
                ) : null}
              </>
            ) : null}
            {(typeof getChatContact()?._id !== "undefined" &&
              typeof selectedHistoryContact?.key !== "undefined" &&
              getChatContact()?._id === selectedHistoryContact?.key) ||
            !showSendBox() ? null : (
              <SendBox
                widgetType={widgetType}
                keyword={searchKeyword}
                setKeyword={setSearchKeyword}
                thumbs={thumbs}
                setThumbs={setThumbs}
                message={message}
                setMessage={setMessage}
                isFocused={isFocused}
                setIsFocused={setIsFocused}
                isReplying={isReplying}
                setIsReplying={setIsReplying}
                messageReplied={messageReplied}
                setMessageReplied={setMessageReplied}
                setMessageSent={setMessageSent}
                setToggleList={setToggleList}
              />
            )}
          </>
        )}
        {showDeleteModal ? (
          <ConfirmationModal
            disabled={disable}
            widgetType={widgetType}
            action={async () => {
              setDisable(true);
              setThumbs([]);
              if (user?.roles?.includes(RoleUser.AGENT) || isAdmin) {
                await dispatch(readAll(selectedChat?._id || ""));
                dispatch(
                  clearNotifications(
                    toggleList === "backlog" ? "backlog" : "mine"
                  )
                );
              }
              setShowDeleteModal(false);
              if (
                widgetType &&
                widgetType === UserType.CONTACT &&
                ![UserType.AGENT, UserType.NONE].includes(widgetType)
              ) {
                await contactFinishChat();
                setDisable(false);
              } else {
                await userFinishChat();
                setDisable(false);
              }
              // CLOSE MODAL
              setDisable(false);
              // setShowDeleteModal(false);
            }}
            setShowModal={setShowDeleteModal}
            title="Deseja encerrar esta conversa?"
          />
        ) : null}
        {showCancelModal ? (
          <ConfirmationModal
            disabled={disable}
            widgetType={widgetType}
            action={async () => {
              setDisable(true);
              setThumbs([]);
              if (user?.roles?.includes(RoleUser.AGENT) || isAdmin) {
                await dispatch(readAll(selectedChat?._id || ""));
                dispatch(
                  clearNotifications(
                    toggleList === "backlog" ? "backlog" : "mine"
                  )
                );
              }
              setShowCancelModal(false);
              await userCancelChat();
              // CLOSE MODAL
              setDisable(false);
            }}
            setShowModal={setShowCancelModal}
            title="Deseja cancelar a conversa com este contato?"
          />
        ) : null}
        {showSpamModal ? (
          <ConfirmationModal
            disabled={disable}
            widgetType={widgetType}
            action={async () => {
              setDisable(true);
              setThumbs([]);
              if (user?.roles?.includes(RoleUser.AGENT) || isAdmin) {
                await dispatch(readAll(selectedChat?._id || ""));
                dispatch(
                  clearNotifications(
                    toggleList === "backlog" ? "backlog" : "mine"
                  )
                );
              }
              await userSpamChat();
              setVisible(false);
              // CLOSE MODAL
              setDisable(false);
            }}
            setShowModal={setShowSpamModal}
            title="Deseja marcar este contato como SPAM?"
            content={
              typeof selectedChat?.status !== "undefined" &&
              [
                ChatStatus.CANCELED,
                ChatStatus.SUSPEND,
                ChatStatus.FINISH,
              ].includes(selectedChat.status)
                ? "<label class='ml-12 text-[14px] font-normal'>As conversas deste contato serão canceladas.</label>"
                : "<label class='ml-12 text-[14px] font-normal'>Esta e outras conversas deste contato serão canceladas.</label>"
            }
          />
        ) : null}
        {showSuspendModal ? (
          <ConfirmationModal
            disabled={disable}
            widgetType={widgetType}
            action={async () => {
              setDisable(true);
              await suspendChat();
              setDisable(false);
            }}
            setShowModal={setShowSuspendModal}
            title="Deseja cancelar esta conversa?"
          />
        ) : null}
        {showTagsModal ? (
          <RelationModal
            title="Gerenciar marcadores"
            icon="las la-tags"
            setShowModal={setShowTagsModal}
          />
        ) : null}
        {showLeaveModal ? (
          <ConfirmationModal
            disabled={disable}
            widgetType={widgetType}
            action={async () => {
              setDisable(true);
              await leaveGroupChat();
              setDisable(false);
            }}
            setShowModal={setShowLeaveModal}
            title="Você não receberá mais mensagens deste grupo."
            content="<label class='ml-5 text-[14px] font-normal'>Tem certeza que deseja sair do grupo?</label>"
          />
        ) : null}
        {blockSidebar && showModal ? (
          <RelationModal
            title="Gerenciar participantes"
            icon="las la-user-cog"
            setShowModal={setShowModal}
            setBlockSidebar={setBlockSidebar}
          />
        ) : null}
        {blockSidebar && showDeleteGroupModal ? (
          <ConfirmationModal
            disabled={disable}
            widgetType={widgetType}
            action={async () => {
              setDisable(true);
              await deleteGroupChat();
              setDisable(false);
            }}
            setShowModal={setShowDeleteGroupModal}
            title="Todos os participantes serão removidos"
            content="<label class='ml-5 text-[14px] font-normal'>Tem certeza que deseja excluir o grupo?</label>"
          />
        ) : null}
      </Sidebar.Pusher>
    </>
  );
};

Conversation.defaultProps = {
  widgetType: UserType.NONE,
  setShowHistory: undefined,
};

export default Conversation;
