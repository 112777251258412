import React from "react";
import { DropdownProps, SemanticWIDTHS } from "semantic-ui-react";
import Dropdown from "../Dropdown.component";
import { ddOptions } from "../../models/interfaces/dropdownOptions.interface";

const MonthsDropdown = ({
  id,
  className,
  disabled,
  defaultValue,
  onChange,
  fluid,
  required,
  width,
}: {
  id?: string;
  className?: string;
  disabled?: boolean;
  defaultValue?: string;
  onChange?:
    | ((
        event: React.SyntheticEvent<HTMLElement, Event>,
        data: DropdownProps
      ) => void)
    | undefined;
  fluid?: boolean;
  required?: boolean;
  width?: SemanticWIDTHS;
}) => {
  const monthOptions: ddOptions[] = [
    {
      key: "-1",
      text: "Todos os meses",
      value: "-1",
    },
  ];

  monthOptions.push(
    ...Array.from({ length: 12 }, (_, i) => {
      const months = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ];
      const _opt = {
        key: (i + 1).toString(),
        text: months[i],
        value: (i + 1).toString(),
      };
      return _opt;
    })
  );

  return (
    <Dropdown
      id={id || "dropdown-months"}
      className={className || "w-full md:w-full"}
      disabled={disabled || false}
      placeholder="Mês"
      label="Mês"
      fluid={fluid}
      width={width}
      required={required}
      selection
      search
      defaultValue={defaultValue}
      onChange={onChange}
      options={monthOptions}
    />
  );
};

MonthsDropdown.defaultProps = {
  id: undefined,
  className: undefined,
  disabled: undefined,
  defaultValue: undefined,
  onChange: undefined,
  fluid: undefined,
  required: undefined,
  width: undefined,
};

export default MonthsDropdown;
