/* eslint-disable no-restricted-syntax */
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import { Sidebar } from "semantic-ui-react";
import { Range } from "react-date-range";
import HeaderPageInfo from "../shared/components/HeaderPageInfo.component";
import FooterPageInfo from "../shared/components/FooterPageInfo.component";
import { useAppDispatch, useAppSelector } from "../hooks/redux/hooks";
import {
  DashcardType,
  IFilterDashboard,
  getSearchDashboard,
} from "../slices/dashboard.slice";
import LoadingDots from "../shared/components/LoadingDots.component";
import DashPie from "../features/dashboards/components/DashPie.component";
import DashArea from "../features/dashboards/components/DashArea.component";
import NotificationBadge from "../features/chats/components/NotificationBadge.component";
import Button from "../shared/components/Buttons/Button.component";
import { SidebarComponent } from "../shared/components/Sidebars";
import CustomDateRangePicker from "../shared/components/DateRangePicker.component";
import LinkButton from "../shared/components/Buttons/LinkButton.component";
import { ChatType, IFilterProps } from "../slices/chats.slice";
import SearchbarFilters from "../shared/components/SearchbarFilters.component";
import {
  IStorableFilters,
  dashboardDefault,
  storeDashboardFilterOptions,
  storeDashboardFilterSelected,
} from "../slices/preferences.slice";
import useContainerDimensions from "../hooks/useContainerDimensions";
import removeDuplicates from "../shared/utils/removeDuplicates";

const DashboardPage = ({ toggleSidebar }: { toggleSidebar: boolean }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const componentRef = useRef<HTMLDivElement>(null);
  const { width } = useContainerDimensions(componentRef);

  const pieCharts = [
    DashcardType.CHAT_OPEN,
    DashcardType.CHAT_WAITING_CONTACT,
    DashcardType.CHAT_WAITING_AGENT,
    DashcardType.MESSAGE_RECEIVED,
    DashcardType.MESSAGE_SENDED,
    DashcardType.CHAT_FINISHED_INACTIVITY,
  ];

  const areaCharts = [
    DashcardType.CHAT_HOURS_TOTAL,
    DashcardType.CHAT_TEAM_AGENT,
    DashcardType.CHAT_FIRST_MESSAGE,
    DashcardType.CHAT_BACKLOG_TIME,
    // DashcardType.CHAT_BY_CONTACT,
    DashcardType.CHAT_BY_BUSY_HOUR,
    DashcardType.CHAT_BY_AVG_TIME,
  ];

  interface tooltipsObject {
    [key: string]: string;
  }

  const tooltips: tooltipsObject = {
    [DashcardType.CHAT_OPEN]:
      "Total de conversas do tipo externo em atendimento",
    [DashcardType.CHAT_WAITING_CONTACT]:
      "Total de conversas em atendimento em que a última mensagem enviada foi do agente",
    [DashcardType.CHAT_WAITING_AGENT]:
      "Total de conversas em atendimento em que a última mensagem enviada foi do contato",
    [DashcardType.MESSAGE_RECEIVED]:
      "Quantidade total de mensagens recebidas pelos agentes em conversas com contatos",
    [DashcardType.MESSAGE_SENDED]:
      "Quantidade total de mensagens enviadas pelos agentes em conversas com contatos",
    [DashcardType.CHAT_FINISHED_INACTIVITY]:
      "Quantidade de chats encerrados devido a falta de retorno do contato",
    [DashcardType.CHAT_HOURS_TOTAL]:
      "Tempo, em horas, do período que as conversas ficaram em atendimento",
    [DashcardType.CHAT_TEAM_AGENT]: "Quantidade de conversas do tipo externo",
    [DashcardType.CHAT_FIRST_MESSAGE]:
      "Tempo, em horas, da primeira mensagem do agente ao iniciar uma conversa que estava na fila de espera",
    [DashcardType.CHAT_BACKLOG_TIME]:
      "Tempo, em horas, que as conversas permaneceram na fila de espera",
    // [DashcardType.CHAT_BY_CONTACT]: "Quantidade total de conversas por contato",
    [DashcardType.CHAT_BY_BUSY_HOUR]:
      "Quantidade total de conversas por hora de pico no dia",
    [DashcardType.CHAT_BY_AVG_TIME]:
      "Tempo médio, em horas, do período que as conversas ficaram em atendimento",
  };

  const { isLoadingDashboard, dashboard } = useAppSelector(
    (state) => state.dashboard
  );

  const { user } = useAppSelector((state) => state.auth);
  const dashboardsFilters = useAppSelector((state) => {
    const { preferences } = state.preferences;
    const userIndex = preferences?.findIndex(
      (object) => object.user === user?._id
    );
    const userPreferences =
      typeof preferences !== "undefined" &&
      typeof userIndex !== "undefined" &&
      userIndex !== -1
        ? preferences[userIndex].dashboards
        : undefined;
    if (typeof userPreferences !== "undefined") return userPreferences;

    return {
      options: dashboardDefault,
      selected: dashboardDefault,
    };
  });

  const dashboardFilterOptions = dashboardsFilters?.options;
  const dashboardFilterSelected = dashboardsFilters?.selected;

  const defaultDashFilters: IFilterDashboard = {
    users: [],
    types: [],
    teams: [],
    tags: [],
    period: {
      startdate: format(new Date(), "yyyy-MM-dd"),
      enddate: format(new Date(), "yyyy-MM-dd"),
    },
  };

  const todayRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  };

  const checkStoragedPeriod = (_returnRange?: boolean) => {
    let returnRange = _returnRange;
    const storagePeriod: any =
      typeof dashboardFilterSelected !== "undefined" &&
      typeof dashboardFilterSelected.period !== "undefined" &&
      "startdate" in dashboardFilterSelected.period
        ? dashboardFilterSelected.period
        : undefined;

    returnRange = returnRange || false;

    const period =
      typeof storagePeriod !== "undefined"
        ? storagePeriod
        : defaultDashFilters.period;

    const storagedRange = {
      startDate: new Date(`${period.startdate} 00:00:00`),
      endDate: new Date(`${period.enddate} 23:59:59`),
      key: "selection",
    };

    if (returnRange) {
      return typeof storagePeriod !== "undefined" ? storagedRange : todayRange;
    }

    return typeof period !== "undefined" ? period : defaultDashFilters.period;
  };

  const [searchFiltersSelected, setSearchFiltersSelected] =
    useState<IFilterDashboard>(
      dashboardFilterSelected
        ? { ...dashboardFilterSelected, period: checkStoragedPeriod() }
        : defaultDashFilters
    );

  const setFiltersByDatePicker = (newFilter: IFilterDashboard) => {
    setSearchFiltersSelected({
      ...searchFiltersSelected,
      period: newFilter.period,
    });
  };

  const setFiltersBySearchbar = (newFilter: IFilterDashboard) => {
    const _period = searchFiltersSelected.period;
    setSearchFiltersSelected({
      ...newFilter,
      period: _period,
    });
  };

  const [visible, setVisible] = useState<boolean>(false);

  const [filteredRange, setFilteredRange] = useState<Range>(
    checkStoragedPeriod(true) as Range
  );

  const [searchFiltersOptions, setSearchFiltersOptions] =
    useState<IFilterDashboard>(dashboardFilterOptions || defaultDashFilters);
  const [showFilter, setShowFilter] = useState<boolean>(false);

  const goBack = () => {
    navigate(-1);
  };

  const countSelectedFilters = () => {
    let selectedFilters = 0;
    if (typeof searchFiltersSelected !== "undefined") {
      for (const _instance of Object.keys(searchFiltersSelected)) {
        // eslint-disable-next-line no-continue
        if (["period"].includes(_instance)) continue;
        const instanceValues =
          searchFiltersSelected[_instance as keyof IFilterDashboard];
        if (
          typeof instanceValues !== "undefined" &&
          Array.isArray(instanceValues) &&
          instanceValues.length > 0
        ) {
          selectedFilters += Array.isArray(instanceValues)
            ? instanceValues.length
            : 1;
        }
      }
    }
    return selectedFilters;
  };

  const clearStoragedFilters = () => {
    dispatch(storeDashboardFilterSelected({} as IStorableFilters));
    dispatch(storeDashboardFilterOptions({} as IStorableFilters));
    setFilteredRange(todayRange);
  };

  const clearFilters = ({ _instance }: { _instance?: string }) => {
    if (typeof _instance !== "undefined" && ["users"].includes(_instance)) {
      document
        .querySelectorAll(".dropdown-filters-users .clear")
        .forEach((el: any) => el.click());
    } else {
      document
        .querySelectorAll(".dropdown-filters .clear")
        .forEach((el: any) => el.click());

      clearStoragedFilters();

      document
        .querySelectorAll(".dropdown-filters-users .clear")
        .forEach((el: any) => el.click());
      if (typeof setSearchFiltersSelected !== "undefined")
        setSearchFiltersSelected(defaultDashFilters);
      if (typeof setSearchFiltersOptions !== "undefined")
        setSearchFiltersOptions(defaultDashFilters);
    }
  };

  const storageFilters = (storeFilter: IFilterDashboard) => {
    const _storeFilter = {
      ...storeFilter,
    };
    if (typeof user?._id !== "undefined" && user) {
      dispatch(
        storeDashboardFilterSelected({
          _id: user?._id as string,
          dashboards: _storeFilter,
        })
      );
    }
  };

  const storageOptions = () => {
    if (typeof user?._id !== "undefined" && user) {
      // TODO: Avoiding crashing content property
      if (typeof searchFiltersOptions.users !== "undefined") {
        searchFiltersOptions.users.forEach((option: any) => {
          delete option.content;
        });
      }

      dispatch(
        storeDashboardFilterOptions({
          _id: user?._id as string,
          dashboards: searchFiltersOptions,
        })
      );
    }
  };

  const showAppliedFilters = () => {
    if (countSelectedFilters() <= 0) {
      return "";
    }
    const showFilters: string[] = [];
    if (
      typeof searchFiltersSelected !== "undefined" &&
      typeof searchFiltersOptions !== "undefined"
    ) {
      for (const instance of Object.keys(searchFiltersSelected)) {
        const instanceValues =
          searchFiltersSelected[instance as keyof IFilterDashboard] || [];
        const instanceOptions =
          searchFiltersOptions[instance as keyof IFilterDashboard] || [];

        if (
          typeof instanceValues !== "undefined" &&
          Array.isArray(instanceValues) &&
          instanceValues.length > 0
        ) {
          if (instanceValues.length > 0 && Array.isArray(instanceOptions)) {
            const items = instanceValues.map((_value) => {
              let value = _value;
              if (instance === "content") return value;
              const result = instanceOptions.find((item: any) => {
                if (instance === "types") {
                  value = value === "EXTERNAL" ? "WIDGET" : value;
                }
                return item.key === value;
              });
              return result?.text;
            });
            // showFilters.push(`${getDropdownLabel(instance)} : ${items.join(', ')}`)
            showFilters.push(`${removeDuplicates(items).join(", ")}`);
            // showFilters.push(`${items.join(", ")}`);
          }
        } else if (
          Array.isArray(instanceOptions) &&
          instanceOptions.length > 0
        ) {
          const result = instanceOptions.find(
            (item) => item.key === instanceValues
          );
          if (typeof result?.text !== "undefined") {
            // showFilters.push(`${getDropdownLabel(instance)} : ${result?.text}`)
            showFilters.push(`${result?.text}`);
          }
        }
      }
    }

    const _filters: string = showFilters.join("; ");
    return (
      <div className="ml-1 pb-3 text-[12px] text-break">
        {typeof searchFiltersSelected !== "undefined" ? (
          <div className="flex">
            <h2 className="font-semibold mr-2">Filtrando por:</h2>
            <h2>{` ${
              _filters.length > 200
                ? _filters.substring(0, 200).concat("...")
                : _filters
            }`}</h2>
          </div>
        ) : null}
        <div className="w-full -ml-2">
          <LinkButton
            color
            label="Limpar"
            leftIcon="las la-eraser mr-1"
            onClick={
              typeof clearFilters !== "undefined" ? clearFilters : () => null
            }
            extraClass="text-[12px]"
          />
        </div>
      </div>
    );
  };

  const formatFilters = () => {
    const currentFilters = {
      ...searchFiltersSelected,
    };

    const filterProps = {
      users:
        typeof currentFilters.users !== "undefined" &&
        currentFilters.users.length > 0
          ? currentFilters.users
          : undefined,
      teams:
        typeof currentFilters.teams !== "undefined" &&
        currentFilters.teams.length > 0
          ? currentFilters.teams
          : undefined,
      types:
        typeof currentFilters.types !== "undefined" &&
        currentFilters.types.length > 0
          ? currentFilters.types
          : undefined,
      tags:
        typeof currentFilters.tags !== "undefined" &&
        currentFilters.tags.length > 0
          ? currentFilters.tags
          : undefined,
    } as IFilterProps;

    const _types = filterProps.types;
    const _widgetType = "WIDGET" as ChatType;

    if (typeof _types !== "undefined") {
      if (_types.includes(_widgetType)) {
        _types.push(ChatType.EXTERNAL);
        const index = _types.indexOf(_widgetType);
        if (index > -1) _types.splice(index, 1);
      }

      filterProps.types = _types as string[];
    }

    const filter = filterProps;

    Object.keys(filter).forEach((key) => {
      const currentValue = JSON.stringify(filter[key as keyof IFilterProps]);
      if (
        currentValue === "[]" ||
        currentValue === undefined ||
        currentValue === ""
      ) {
        delete filter[key as keyof IFilterProps];
      }
    });

    return filter;
  };

  useEffect(() => {
    (async () => {
      if (
        Array.isArray(searchFiltersSelected.period) &&
        searchFiltersSelected.period.length === 0
      ) {
        return;
      }
      const filters = formatFilters();
      const _filters = {
        ...filters,
        period: searchFiltersSelected.period,
      } as IFilterDashboard;

      storageFilters(_filters);
      storageOptions();
      await dispatch(getSearchDashboard(_filters));
    })();
  }, [searchFiltersSelected]);

  return (
    <div
      id="dashboards"
      className="container items-center my-auto mx-auto lg:px-44 mt-10 py-4 md:px-12 px-4 sm:px-8"
    >
      <SidebarComponent
        visible={visible}
        setVisible={setVisible}
        setShowFilter={setShowFilter}
        content={
          <div className="flex-container pl-[5px]">
            <div className="header relative bg-white rounded-md py-8 px-10">
              <h1 className="flex items-center justify-center font-semibold">
                <i className="las la-filter text-3xl" />

                <p className="text-xl ml-3">Filtrar resultados</p>
              </h1>
            </div>
            <SearchbarFilters
              clearFilters={clearFilters}
              searchFiltersOptions={searchFiltersOptions}
              searchFiltersSelected={searchFiltersSelected}
              setSearchFiltersOptions={setSearchFiltersOptions}
              setSearchFiltersSelected={setFiltersBySearchbar}
              setShowFilter={setShowFilter}
              setVisible={setVisible}
              defaultFilters={{
                teams: [],
                users: [],
                types: [],
                tags: [],
              }}
              searchInstanceName="dashboards"
            />
          </div>
        }
      />
      <Sidebar.Pusher>
        <HeaderPageInfo
          created={null}
          updated={null}
          deleted={null}
          title="Resultados"
          imported={false}
          icon="las la-chart-bar"
        />
        <div className="px-4 py-5 flex items-center justify-between md:p-0 w-full">
          <div className="md:flex items-center">
            {setVisible ? (
              <div>
                <Button
                  minWidth
                  extraClass="w-[90px] relative"
                  inverted
                  icon={showFilter ? "las la-times" : "las la-filter"}
                  label={showFilter ? "Fechar" : "Filtros"}
                  onClick={() => {
                    if (setVisible && setShowFilter) {
                      setVisible((prevState) => !prevState);
                      setShowFilter((prevState) => !prevState);
                      if (!location.hash.includes("#filters")) {
                        navigate(`${location.pathname}#filters`);
                      } else {
                        navigate(`${location.pathname}`);
                      }
                    }
                  }}
                />
                {typeof searchFiltersSelected !== "undefined" &&
                countSelectedFilters() > 0 ? (
                  <div className="relative pl-32 -top-3  ">
                    <NotificationBadge
                      title="filtro(s)"
                      badge={countSelectedFilters()}
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
            <div className="ml-4">{showAppliedFilters()}</div>
          </div>
          <div className="">
            <CustomDateRangePicker
              range={filteredRange}
              setRange={setFilteredRange}
              filter={searchFiltersSelected}
              setFilter={setFiltersByDatePicker}
            />
          </div>
        </div>

        {isLoadingDashboard ? (
          <LoadingDots
            className="flex justify-center items-center absolute top-0 bottom-0 right-0 left-0 h-screen"
            large
          />
        ) : (
          <div
            id="dash-block"
            key="dash-block"
            className="flex flex-col w-full"
            ref={componentRef}
          >
            <div className="dashboard-grid mb-4">
              {dashboard.length > 0
                ? dashboard?.map((item: any) => {
                    if (
                      typeof item?.dashcard?.type !== "undefined" &&
                      pieCharts.includes(item?.dashcard?.type)
                    ) {
                      return (
                        <div
                          className="w-full flex items-center justify-center rounded-md"
                          key={t(`dashboards.type.${item?.dashcard?.type}`)}
                        >
                          <DashPie
                            tooltip={tooltips[item?.dashcard?.type]}
                            title={t(`dashboards.type.${item?.dashcard?.type}`)}
                            options={item.dashcard?.data?.labels}
                            seriesPie={item.dashcard?.data?.series}
                          />
                        </div>
                      );
                    }
                    return null;
                  })
                : null}
            </div>
            {dashboard.length > 0
              ? dashboard?.map((item: any) => {
                  if (
                    typeof item?.dashcard?.type !== "undefined" &&
                    areaCharts.includes(item?.dashcard?.type)
                  ) {
                    return (
                      <div
                        className="w-full flex mb-4 bg-white rounded-md"
                        key={t(`dashboards.type.${item?.dashcard?.type}`)}
                      >
                        <DashArea
                          width={width}
                          tooltip={tooltips[item?.dashcard?.type]}
                          type={item?.dashcard?.type}
                          title={t(`dashboards.type.${item?.dashcard?.type}`)}
                          series={
                            item?.dashcard?.data?.series || [{ data: [] }]
                          }
                          options={
                            item?.dashcard?.data?.xaxis?.categories || []
                          }
                        />
                      </div>
                    );
                  }
                  return null;
                })
              : null}
          </div>
        )}
        <div className="mb-20" />
        <FooterPageInfo
          toggleSidebar={toggleSidebar}
          back={goBack}
          remove={goBack}
          save={() => null}
        />
      </Sidebar.Pusher>
    </div>
  );
};

export default DashboardPage;
