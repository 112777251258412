import { store } from "../../../app/store";
import { teamsService } from "../../../services";
import { ITeam } from "../../../slices/teams.slice";

const removeActiveChat = async ({ team }: { team?: ITeam | string }) => {
  if (typeof team !== "undefined") {
    let _team: ITeam | undefined;
    if (team && typeof team === "string") {
      _team = await teamsService.team({
        _id: team as string,
      });
    } else if (team && typeof team !== "undefined") {
      _team = team as ITeam;
    }
    if (
      typeof _team !== "undefined" &&
      _team?._id &&
      typeof _team._id !== "undefined" &&
      _team._id.length > 0
    ) {
      await teamsService.chatUsers({
        _team: _team._id,
        user: store.getState()?.auth?.user?._id || "",
        action: "delete",
      });
    }
  }
};

export default removeActiveChat;
