import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/redux/hooks";
import {
  reselectLocalCommenttemplate,
  reselectCommenttemplate,
  selectCommenttemplate,
} from "../slices/commenttemplates.slice";
import useGetIdPage from "../hooks/useGetIdPage";
import useCommenttemplates from "../hooks/features/useCommenttemplates";
import DividerInfoPages from "../shared/components/DividerInfoPages.component";
import Section from "../shared/components/Section.component";
import DefaultPage from "./Default.page";
import Infos from "../features/commenttemplates/components/Infos.section";
import Teams from "../features/commenttemplates/components/Teams.section";
import Content from "../features/commenttemplates/components/Content.section";
// import useUserIsAdmin from "../hooks/useUserIsAdmin";

const CommenttemplatePage = ({
  disable,
  setDisable,
  toggleSidebar,
}: {
  toggleSidebar: boolean;
  disable: boolean;
  setDisable: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { getId } = useGetIdPage();
  // const { isAdmin } = useUserIsAdmin();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { isLoadingCommenttemplates, selectedCommenttemplate } = useAppSelector(
    (state) => state.commenttemplates
  );
  const { sectionDetails, header, footer } = useCommenttemplates({
    disable,
    setDisable,
    toggleSidebar,
  });

  useEffect(() => {
    const _id = getId();
    if (location.pathname === `/settings/commenttemplate/${_id}`) {
      (async () => {
        dispatch(reselectCommenttemplate(null));
        dispatch(reselectLocalCommenttemplate(null));
        await dispatch(selectCommenttemplate({ _id }));
      })();
    }
  }, []);

  return (
    <div className="container items-center my-auto mx-auto lg:px-44 mt-10 py-4 md:px-12 px-4 sm:px-8">
      <DefaultPage
        isLoading={
          isLoadingCommenttemplates && selectedCommenttemplate === null
        }
        header={header}
        content={
          <>
            <Section
              icon={sectionDetails.infos.icon}
              title={sectionDetails.infos.title}
              description={sectionDetails.infos.description}
              component={<Infos />}
            />
            {/* {isAdmin ? (
              <> */}
            <DividerInfoPages />
            <Section
              icon={sectionDetails.team.icon}
              title={sectionDetails.team.title}
              description={sectionDetails.team.description}
              component={<Teams />}
            />
            {/* </>
            ) : null} */}
            <DividerInfoPages />
            <Section
              icon={sectionDetails.content.icon}
              title={sectionDetails.content.title}
              description={sectionDetails.content.description}
              fullarea
              component={<Content />}
            />
          </>
        }
        footer={footer}
      />
    </div>
  );
};

export default CommenttemplatePage;
