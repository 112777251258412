/* eslint-disable no-restricted-syntax */
type MaskProps = {
  [key: string]: string;
};
type MaskRegexProps = {
  [key: string]: {
    regex: RegExp;
    format: string;
  };
};

const handleMaskLength = ({ value }: { value: string | undefined }) => {
  const phoneMasks: MaskProps = {
    "55": "+55 (99) 99999-9999", // Brasil
    "1": "+1 (999) 999-9999", // Estados Unidos, Canadá
    "33": "+33 99 99 99 99 99", // França
    "61": "+61 9999 999 999", // Austrália
    "81": "+81 99-9999-9999", // Japão
    "49": "+49 9999 999999", // Alemanha
    "91": "+91 99999-99999", // Índia
    "86": "+86 999 9999 9999", // China
    "27": "+27 999 999 9999", // África do Sul
    "7": "+7 (999) 999-99-99", // Rússia
    "39": "+39 999 999 9999", // Itália
    "52": "+52 99 99 99 99 99", // México
    "44": "+44 99 9999 9999", // Reino Unido
    "34": "+34 999 99 99 99", // Espanha
    "54": "+54 99 9999-9999", // Argentina
    "56": "+56 99 9999 9999", // Chile
    "57": "+57 (99) 999-9999", // Colômbia
    "234": "+234 999 999 9999", // Nigéria
    "20": "+20 999 999 9999", // Egito
    "82": "+82 999-9999-9999", // Coreia do Sul
    "90": "+90 (999) 999 9999", // Turquia
    "966": "+966 999 999 9999", // Arábia Saudita
    "46": "+46 999-999 99 99", // Suécia
    "31": "+31 99 999 9999", // Países Baixos
    "32": "+32 999 99 99 99", // Bélgica
    "41": "+41 99 999 99 99", // Suíça
    "43": "+43 999 999 9999", // Áustria
    "351": "+351 999 999 999", // Portugal
    "30": "+30 99 9999 9999", // Grécia
    "48": "+48 999 999 999", // Polônia
    "36": "+36 99 999 9999", // Hungria
    "420": "+420 999 999 999", // República Tcheca
    "40": "+40 999 999 999", // Romênia
    "47": "+47 99 99 99 99", // Noruega
    "45": "+45 99 99 99 99", // Dinamarca
    "358": "+358 999 999 999", // Finlândia
    "353": "+353 999 999 999", // Irlanda
    "64": "+64 99 999 9999", // Nova Zelândia
    "65": "+65 9999 9999", // Singapura
    "60": "+60 999-999 9999", // Malásia
    "66": "+66 99 999 9999", // Tailândia
    "63": "+63 999 999 9999", // Filipinas
    "84": "+84 999 999 9999", // Vietnã
    "62": "+62 999-9999-9999", // Indonésia
    "92": "+92 999 9999999", // Paquistão
    "880": "+880 99999-999999", // Bangladesh
    "98": "+98 999 999 9999", // Irã
    "964": "+964 999 999 9999", // Iraque
    "972": "+972 99-999-9999", // Israel
    "380": "+380 999 999 9999", // Ucrânia
    "375": "+375 999 999 9999", // Bielorrússia
    "998": "+998 999 999 9999", // Uzbequistão
    "93": "+93 999 999 9999", // Afeganistão
    "94": "+94 999 999 9999", // Sri Lanka
    "977": "+977 999-9999999", // Nepal
    "95": "+95 99 999 9999", // Mianmar
    "855": "+855 999 999 999", // Camboja
    "856": "+856 999 999 999", // Laos
    "976": "+976 999 999 999", // Mongólia
    "975": "+975 999 999 999", // Butão
    "960": "+960 999 999 999", // Maldivas
    "673": "+673 999 999 999", // Brunei
    "670": "+670 999 999 999", // Timor-Leste
    "675": "+675 999 999 999", // Papua Nova Guiné
    "679": "+679 999 999 999", // Fiji
    "677": "+677 999 999 999", // Ilhas Salomão
    "678": "+678 999 999 999", // Vanuatu
    "687": "+687 999 999 999", // Nova Caledônia
    "689": "+689 999 999 999", // Polinésia Francesa
    "685": "+685 999 999 999", // Samoa
    "676": "+676 999 999 999", // Tonga
    "688": "+688 999 999 999", // Tuvalu
    "686": "+686 999 999 999", // Kiribati
    "674": "+674 999 999 999", // Nauru
    "691": "+691 999 999 999", // Micronésia
    "692": "+692 999 999 999", // Ilhas Marshall
    "680": "+680 999 999 999", // Palau
    "1684": "+1684 999 999 999", // Samoa Americana
    "1671": "+1671 999 999 999", // Guam
    "1670": "+1670 999 999 999", // Ilhas Marianas do Norte
    "1340": "+1340 999 999 999", // Ilhas Virgens Americanas
    "1787": "+1787 999 999 999", // Porto Rico
    "1868": "+1868 999 999 999", // Trinidad e Tobago
    "1246": "+1246 999 999 999", // Barbados
    "1876": "+1876 999 999 999", // Jamaica
    "1242": "+1242 999 999 999", // Bahamas
    "501": "+501 999 999 999", // Belize
    "506": "+506 999 999 999", // Costa Rica
    "507": "+507 999 999 999", // Panamá
    "504": "+504 999 999 999", // Honduras
    "503": "+503 999 999 999", // El Salvador
    "505": "+505 999 999 999", // Nicarágua
    "502": "+502 999 999 999", // Guatemala
    "509": "+509 999 999 999", // Haiti
    "1809": "+1809 999 999 999", // República Dominicana
    "53": "+53 999 999 999", // Cuba
    "58": "+58 999 999 999", // Venezuela
    "598": "+598 999 999 999", // Uruguai
    "595": "+595 999 999 999", // Paraguai
    "591": "+591 999 999 999", // Bolívia
    "51": "+51 999 999 999", // Peru
    "593": "+593 999 999 999", // Equador
    "592": "+592 999 999 999", // Guiana
    "597": "+597 999 999 999", // Suriname
    "594": "+594 999 999 999", // Guiana Francesa
    "1268": "+1268 999 999 999", // Antígua e Barbuda
    "1767": "+1767 999 999 999", // Dominica
    "1473": "+1473 999 999 999", // Granada
    "1869": "+1869 999 999 999", // São Cristóvão e Nevis
    "1758": "+1758 999 999 999", // Santa Lúcia
    "1784": "+1784 999 999 999", // São Vicente e Granadinas
  };

  const countryCodes = Object.keys(phoneMasks);
  for (const code of countryCodes) {
    if (value && value.startsWith(code)) {
      return phoneMasks[code];
    }
  }
  return value?.replace(/d/g, "9") ?? "9999999999999";
};

export const handleMaskRegex = (value?: string) => {
  const phoneMasksRegex: MaskRegexProps = {
    "55": {
      regex: /(\d{2})(\d{2})(\d{5}|\d{4})(\d{4})/,
      format: "+$1 ($2) $3-$4",
    }, // Brasil
    "1": { regex: /(\d{1})(\d{3})(\d{3})(\d{4})/, format: "+$1 ($2) $3-$4" }, // Estados Unidos, Canadá
    "33": {
      regex: /(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/,
      format: "+$1 $2 $3 $4 $5",
    }, // França
    "61": { regex: /(\d{2})(\d{4})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Austrália
    "81": { regex: /(\d{2})(\d{2})(\d{4})(\d{4})/, format: "+$1 $2-$3-$4" }, // Japão
    "49": { regex: /(\d{2})(\d{4})(\d{6})/, format: "+$1 $2 $3" }, // Alemanha
    "91": { regex: /(\d{2})(\d{5})(\d{5})/, format: "+$1 $2-$3" }, // Índia
    "86": { regex: /(\d{2})(\d{3})(\d{4})(\d{4})/, format: "+$1 $2 $3 $4" }, // China
    "27": { regex: /(\d{2})(\d{3})(\d{3})(\d{4})/, format: "+$1 $2 $3 $4" }, // África do Sul
    "7": {
      regex: /(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/,
      format: "+$1 ($2) $3-$4-$5",
    }, // Rússia
    "39": { regex: /(\d{2})(\d{3})(\d{3})(\d{4})/, format: "+$1 $2 $3 $4" }, // Itália
    "52": {
      regex: /(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/,
      format: "+$1 $2 $3 $4 $5 $6",
    }, // México
    "44": { regex: /(\d{2})(\d{2})(\d{4})(\d{4})/, format: "+$1 $2 $3 $4" }, // Reino Unido
    "34": {
      regex: /(\d{2})(\d{3})(\d{2})(\d{2})(\d{2})/,
      format: "+$1 $2 $3 $4",
    }, // Espanha
    "54": { regex: /(\d{2})(\d{2})(\d{4})(\d{4})/, format: "+$1 $2-$3" }, // Argentina
    "56": { regex: /(\d{2})(\d{2})(\d{4})(\d{4})/, format: "+$1 $2 $3 $4" }, // Chile
    "57": { regex: /(\d{2})(\d{2})(\d{3})(\d{4})/, format: "+$1 ($2) $3-$4" }, // Colômbia
    "234": { regex: /(\d{3})(\d{3})(\d{3})(\d{4})/, format: "+$1 $2 $3 $4" }, // Nigéria
    "20": { regex: /(\d{2})(\d{3})(\d{3})(\d{4})/, format: "+$1 $2 $3 $4" }, // Egito
    "82": { regex: /(\d{2})(\d{3})(\d{4})(\d{4})/, format: "+$1 $2-$3-$4" }, // Coreia do Sul
    "90": { regex: /(\d{2})(\d{3})(\d{3})(\d{4})/, format: "+$1 ($2) $3 $4" }, // Turquia
    "966": { regex: /(\d{3})(\d{3})(\d{3})(\d{4})/, format: "+$1 $2 $3 $4" }, // Arábia Saudita
    "46": {
      regex: /(\d{2})(\d{3})(\d{3})(\d{2})(\d{2})/,
      format: "+$1 $2-$3 $4 $5",
    }, // Suécia
    "31": { regex: /(\d{2})(\d{2})(\d{3})(\d{4})/, format: "+$1 $2 $3 $4" }, // Países Baixos
    "32": {
      regex: /(\d{2})(\d{3})(\d{2})(\d{2})(\d{2})/,
      format: "+$1 $2 $3 $4 $5",
    }, // Bélgica
    "41": {
      regex: /(\d{2})(\d{2})(\d{3})(\d{2})(\d{2})/,
      format: "+$1 $2 $3 $4 $5",
    }, // Suíça
    "43": { regex: /(\d{2})(\d{3})(\d{3})(\d{4})/, format: "+$1 $2 $3 $4" }, // Áustria
    "351": { regex: /(\d{3})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Portugal
    "30": { regex: /(\d{2})(\d{2})(\d{4})(\d{4})/, format: "+$1 $2 $3 $4" }, // Grécia
    "48": { regex: /(\d{2})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Polônia
    "36": { regex: /(\d{2})(\d{2})(\d{3})(\d{4})/, format: "+$1 $2 $3 $4" }, // Hungria
    "420": { regex: /(\d{3})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // República Tcheca
    "40": { regex: /(\d{2})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Romênia
    "47": {
      regex: /(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/,
      format: "+$1 $2 $3 $4 $5",
    }, // Noruega
    "45": {
      regex: /(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/,
      format: "+$1 $2 $3 $4 $5",
    }, // Dinamarca
    "358": { regex: /(\d{3})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Finlândia
    "353": { regex: /(\d{3})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Irlanda
    "64": { regex: /(\d{2})(\d{2})(\d{3})(\d{4})/, format: "+$1 $2 $3 $4" }, // Nova Zelândia
    "65": { regex: /(\d{2})(\d{4})(\d{4})/, format: "+$1 $2 $3" }, // Singapura
    "60": { regex: /(\d{2})(\d{3})(\d{3})(\d{4})/, format: "+$1 $2-$3 $4" }, // Malásia
    "66": { regex: /(\d{2})(\d{2})(\d{3})(\d{4})/, format: "+$1 $2 $3 $4" }, // Tailândia
    "63": { regex: /(\d{2})(\d{3})(\d{3})(\d{4})/, format: "+$1 $2 $3 $4" }, // Filipinas
    "84": { regex: /(\d{2})(\d{3})(\d{3})(\d{4})/, format: "+$1 $2 $3 $4" }, // Vietnã
    "62": { regex: /(\d{2})(\d{3})(\d{4})(\d{4})/, format: "+$1 $2-$3-$4" }, // Indonésia
    "92": { regex: /(\d{2})(\d{3})(\d{7})/, format: "+$1 $2 $3" }, // Paquistão
    "880": { regex: /(\d{3})(\d{5})(\d{6})/, format: "+$1 $2-$3" }, // Bangladesh
    "98": { regex: /(\d{2})(\d{3})(\d{3})(\d{4})/, format: "+$1 $2 $3 $4" }, // Irã
    "964": { regex: /(\d{3})(\d{3})(\d{3})(\d{4})/, format: "+$1 $2 $3 $4" }, // Iraque
    "972": { regex: /(\d{3})(\d{2})(\d{3})(\d{4})/, format: "+$1 $2-$3-$4" }, // Israel
    "380": { regex: /(\d{3})(\d{3})(\d{3})(\d{4})/, format: "+$1 $2 $3 $4" }, // Ucrânia
    "375": { regex: /(\d{3})(\d{3})(\d{3})(\d{4})/, format: "+$1 $2 $3 $4" }, // Bielorrússia
    "998": { regex: /(\d{3})(\d{3})(\d{3})(\d{4})/, format: "+$1 $2 $3 $4" }, // Uzbequistão
    "93": { regex: /(\d{2})(\d{3})(\d{3})(\d{4})/, format: "+$1 $2 $3 $4" }, // Afeganistão
    "94": { regex: /(\d{2})(\d{3})(\d{3})(\d{4})/, format: "+$1 $2 $3 $4" }, // Sri Lanka
    "977": { regex: /(\d{3})(\d{3})(\d{7})/, format: "+$1 $2-$3" }, // Nepal
    "95": { regex: /(\d{2})(\d{2})(\d{3})(\d{4})/, format: "+$1 $2 $3 $4" }, // Mianmar
    "855": { regex: /(\d{3})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Camboja
    "856": { regex: /(\d{3})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Laos
    "976": { regex: /(\d{3})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Mongólia
    "975": { regex: /(\d{3})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Butão
    "960": { regex: /(\d{3})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Maldivas
    "673": { regex: /(\d{3})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Brunei
    "670": { regex: /(\d{3})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Timor-Leste
    "675": { regex: /(\d{3})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Papua Nova Guiné
    "679": { regex: /(\d{3})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Fiji
    "677": { regex: /(\d{3})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Ilhas Salomão
    "678": { regex: /(\d{3})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Vanuatu
    "687": { regex: /(\d{3})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Nova Caledônia
    "689": { regex: /(\d{3})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Polinésia Francesa
    "685": { regex: /(\d{3})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Samoa
    "676": { regex: /(\d{3})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Tonga
    "688": { regex: /(\d{3})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Tuvalu
    "686": { regex: /(\d{3})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Kiribati
    "674": { regex: /(\d{3})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Nauru
    "691": { regex: /(\d{3})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Micronésia
    "692": { regex: /(\d{3})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Ilhas Marshall
    "680": { regex: /(\d{3})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Palau
    "1684": { regex: /(\d{4})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Samoa Americana
    "1671": { regex: /(\d{4})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Guam
    "1670": { regex: /(\d{4})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Ilhas Marianas do Norte
    "1340": { regex: /(\d{4})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Ilhas Virgens Americanas
    "1787": { regex: /(\d{4})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Porto Rico
    "1868": { regex: /(\d{4})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Trinidad e Tobago
    "1246": { regex: /(\d{4})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Barbados
    "1876": { regex: /(\d{4})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Jamaica
    "1242": { regex: /(\d{4})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Bahamas
    "501": { regex: /(\d{3})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Belize
    "506": { regex: /(\d{3})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Costa Rica
    "507": { regex: /(\d{3})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Panamá
    "504": { regex: /(\d{3})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Honduras
    "503": { regex: /(\d{3})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // El Salvador
    "505": { regex: /(\d{3})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Nicarágua
    "502": { regex: /(\d{3})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Guatemala
    "509": { regex: /(\d{3})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Haiti
    "1809": { regex: /(\d{4})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // República Dominicana
    "53": { regex: /(\d{2})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Cuba
    "58": { regex: /(\d{2})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Venezuela
    "598": {
      regex: /(\d{3})(\d{3})(\d{3})(\d{3}|\d{2})/,
      format: "+$1 $2 $3 $4",
    }, // Uruguai
    "595": { regex: /(\d{3})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Paraguai
    "591": { regex: /(\d{3})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Bolívia
    "51": { regex: /(\d{2})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Peru
    "593": { regex: /(\d{3})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Equador
    "592": { regex: /(\d{3})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Guiana
    "597": { regex: /(\d{3})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Suriname
    "594": { regex: /(\d{3})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Guiana Francesa
    "1268": { regex: /(\d{4})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Antígua e Barbuda
    "1767": { regex: /(\d{4})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Dominica
    "1473": { regex: /(\d{4})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Granada
    "1869": { regex: /(\d{4})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // São Cristóvão e Nevis
    "1758": { regex: /(\d{4})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // Santa Lúcia
    "1784": { regex: /(\d{4})(\d{3})(\d{3})(\d{3})/, format: "+$1 $2 $3 $4" }, // São Vicente e Granadinas
  };

  const countryCodes = Object.keys(phoneMasksRegex);
  for (const code of countryCodes) {
    if (value && value.startsWith(code)) {
      const _value = value.replace(
        phoneMasksRegex[code].regex,
        phoneMasksRegex[code].format
      );
      return _value;
    }
  }
  return undefined;
};

export const validNumberLength = ({ value }: { value: string }) => {
  if (value.startsWith("86")) {
    return value.length >= 13;
  }
  if (value.startsWith("258") || value.startsWith("55")) {
    return value.length >= 12;
  }
  if (
    value.startsWith("91") ||
    value.startsWith("1") ||
    value.startsWith("7") ||
    value.startsWith("52") ||
    value.startsWith("81") ||
    value.startsWith("49") ||
    value.startsWith("44") ||
    value.startsWith("39") ||
    value.startsWith("57")
  ) {
    return value.length >= 10;
  }
  if (
    value.startsWith("27") ||
    value.startsWith("34") ||
    value.startsWith("51") ||
    value.startsWith("61") ||
    value.startsWith("56") ||
    value.startsWith("593") ||
    value.startsWith("31") ||
    value.startsWith("32")
  ) {
    return value.length >= 9;
  }
  if (
    value.startsWith("234") ||
    value.startsWith("227") ||
    value.startsWith("502") ||
    value.startsWith("852") ||
    value.startsWith("45") ||
    value.startsWith("47") ||
    value.startsWith("506") ||
    value.startsWith("507")
  ) {
    return value.length >= 8;
  }
  if (value.startsWith("960") || value.startsWith("58")) {
    return value.length >= 7;
  }
  return value.length >= 10;
};

export default handleMaskLength;
