import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/redux/hooks";
import HeaderPageInfo from "../shared/components/HeaderPageInfo.component";
import FooterPageInfo from "../shared/components/FooterPageInfo.component";
import { getTypes } from "../slices/externalapps.slice";
import LoadingDots from "../shared/components/LoadingDots.component";
import CustomCard from "../shared/components/CustomCard.component";
import useUserIsAdmin from "../hooks/useUserIsAdmin";

const IntegrationsPage = ({ toggleSidebar }: { toggleSidebar: boolean }) => {
  const { isAdmin } = useUserIsAdmin();
  const { selectedTypes, isLoadingExternalapps } = useAppSelector(
    (state) => state.externalapps
  );
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const goBack = () => {
    navigate(-1);
  };

  const goSave = async () => {
    return null;
  };

  useEffect(() => {
    (async () => {
      await dispatch(getTypes());
    })();
  }, []);

  if (isLoadingExternalapps)
    return (
      <LoadingDots
        className="flex justify-center items-center absolute top-0 bottom-0 right-0 left-0 h-screen"
        large
      />
    );
  return (
    <div
      id="integrations"
      className="container items-center my-auto mx-auto lg:px-44 mt-10 py-4 md:px-12 px-8"
    >
      <HeaderPageInfo
        created={null}
        updated={null}
        deleted={null}
        title="Integrações"
        imported={false}
        icon="las la-code"
      />
      <div className="flex flex-wrap -mx-1 lg:-mx-4">
        {selectedTypes &&
          selectedTypes.map((item) => {
            if (!isAdmin) {
              return null;
            }
            return (
              <div
                key={item._id}
                className="my-1 px-1 w-full md:w-1/2 lg:my-4 lg:px-4 lg:w-1/3 flex-wrap"
              >
                <CustomCard
                  element={{
                    name: item.title,
                  }}
                  icon={item.icon}
                  url={item.url}
                />
              </div>
            );
          })}
      </div>
      <div className="mb-20" />
      <FooterPageInfo
        toggleSidebar={toggleSidebar}
        back={goBack}
        remove={goBack}
        save={goSave}
      />
    </div>
  );
};

export default IntegrationsPage;
