import { ExternalAppType } from "../slices/externalapps.slice";
import { INewMessageWpp } from "../slices/messages.slice";
import externalappsService from "./externalapps.service";
import instances from "./instances";

const createWpp = async (newMessage: INewMessageWpp) => {
  try {
    const data = newMessage;
    const filter = { _id: data.externalapp as string };
    const externalapp = await externalappsService.externalapps(filter);
    let response;
    switch (externalapp.type) {
      case ExternalAppType.WHATSAPP:
        response = await instances.instanceWhatsapp.post("/send", data);
        break;
      case ExternalAppType.BOTMAKER:
        // return await botmakerService.templateWpp({ externalappid });
        // const _data = {
        //     externalappid: data.externalapp,
        //     team,
        //     contact,
        //     template: template.name
        // }

        const _data = { ...data, externalappid: data.externalapp };
        response = await instances.instanceBotmaker.post("/sendWppChat", _data);
        break;
    }

    return response?.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const templateWpp = async ({ externalappid }: { externalappid: string }) => {
  try {
    const response = await instances.instanceWhatsapp.get("/templates", {
      params: {
        externalappid: externalappid.length > 0 ? externalappid : undefined,
      },
    });
    if (response?.status && [200, 201].includes(response.status)) {
      return response.data;
    }
    return response?.data || null;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const messagesService = {
  createWpp,
  templateWpp,
};

export default messagesService;
