import React, { useState } from "react";
import { Form, Modal } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/hooks";
import useStep from "../../../hooks/useStep";
import { onboarding } from "../../../slices/auth.slice";
import { Step1Component, Step2Component } from "./OnboardingWizard";
import Button from "../../../shared/components/Buttons/Button.component";

const OnboardingWizard = () => {
  const { t } = useTranslation();
  const { user } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const [currentStep, helpers] = useStep(2);
  const { canGoToPrevStep, canGoToNextStep, goToNextStep, goToPrevStep } =
    helpers;
  const [nameInput, setName] = useState(user?.name || "");
  const [validName, validateName] = useState(true);
  const [passInput, setPass] = useState("");
  const [validPass, validatePass] = useState(!(passInput.length > 0));
  const [passConfirmInput, setPassConfirm] = useState("");
  const [validPassConfirm, validatePassConfirm] = useState(
    !(passConfirmInput.length > 0)
  );

  const onSubmit = async () => {
    if (validName && validPass && validPassConfirm) {
      dispatch(
        onboarding({
          _id: user?._id || "",
          tenant: user?.tenant || "",
          name: nameInput,
          email: user?.email || "",
          password: passInput,
          roles: user?.roles || [],
        })
      );
    }
  };

  return (
    <Modal open className="rounded-2xl max-w-lg">
      <Modal.Content className="rounded-2xl">
        <div className="flex items-center">
          <i className="las la-user-cog text-4xl" />
          <div className="ml-3">
            <b className="text-xl">{t("modal.onboarding.title")}</b>
            <br />
            <p>{t("modal.onboarding.subtitle")}</p>
          </div>
        </div>
        <Form className="w-full mt-3" onSubmit={onSubmit}>
          {currentStep === 1 ? (
            <Step1Component
              nameInput={nameInput}
              setName={setName}
              validName={validName}
              validateName={validateName}
              tenantInput={user?.tenant || ""}
              emailInput={user?.email || ""}
            />
          ) : (
            <Step2Component
              passInput={passInput}
              setPass={setPass}
              validPass={validPass}
              validatePass={validatePass}
              passConfirmInput={passConfirmInput}
              setPassConfirm={setPassConfirm}
              validPassConfirm={validPassConfirm}
              validatePassConfirm={validatePassConfirm}
            />
          )}
        </Form>
        <div className="flex w-full justify-between mt-[30px] mb-[5px] mx-0 items-center">
          <Button
            minWidth
            disabled={!canGoToPrevStep}
            onClick={goToPrevStep}
            icon="las la-arrow-left"
            label={t("formbutton.label.back") || ""}
            inverted
          />
          <Button
            minWidth
            disabled={
              !(
                (currentStep === 1 && validName) ||
                (currentStep === 2 &&
                  passInput.length > 0 &&
                  passConfirmInput.length > 0 &&
                  validPass &&
                  validPassConfirm)
              )
            }
            onClick={canGoToNextStep ? goToNextStep : onSubmit}
            icon={`las ${currentStep === 2 ? "la-save" : "la-arrow-right"}`}
            label={
              currentStep === 2
                ? t("formbutton.label.onboarding") || ""
                : t("formbutton.label.next") || ""
            }
          />
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default OnboardingWizard;
