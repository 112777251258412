import React from "react";
import { DropdownProps, SemanticWIDTHS } from "semantic-ui-react";
import Dropdown from "../Dropdown.component";
import { SurveyType } from "../../../slices/surveys.slice";

const SurveyTypes = ({
  id,
  className,
  disabled,
  defaultValue,
  onChange,
  fluid,
  required,
  width,
}: {
  id?: string;
  className?: string;
  disabled?: boolean;
  defaultValue?: string;
  onChange?:
    | ((
        event: React.SyntheticEvent<HTMLElement, Event>,
        data: DropdownProps
      ) => void)
    | undefined;
  fluid?: boolean;
  required?: boolean;
  width?: SemanticWIDTHS;
}) => {
  const surveytypeOptions = [
    {
      key: 0,
      text: "Aprovação",
      value: SurveyType.APPROVAL,
      icon: "thumbs up outline",
    },
    {
      key: 1,
      text: "Sentimento",
      value: SurveyType.FEELING,
      icon: "smile outline",
    },
    {
      key: 2,
      text: "NPS",
      value: SurveyType.NPS,
      icon: "signal",
    },
    {
      key: 3,
      text: "Qualidade",
      value: SurveyType.QUALITY,
      icon: "star outline",
    },
  ];
  return (
    <Dropdown
      id={id || "dropdown-surveytype"}
      className={className || "w-full md:w-full"}
      disabled={disabled || false}
      placeholder="Formato de avaliação"
      label="Formato de avaliação"
      fluid={fluid}
      width={width}
      required={required}
      selection
      search
      defaultValue={defaultValue}
      onChange={onChange}
      options={surveytypeOptions}
    />
  );
};

SurveyTypes.defaultProps = {
  id: undefined,
  className: undefined,
  disabled: undefined,
  defaultValue: undefined,
  onChange: undefined,
  fluid: undefined,
  required: undefined,
  width: undefined,
};

export default SurveyTypes;
