import React from "react";

const NotificationBadge = ({
  badge,
  title,
}: {
  title?: string;
  badge: number;
}) => {
  const _badge = badge >= 10 ? "9+" : badge;
  const _title = title || `nova(s)`;

  return (
    <div
      title={`${_badge} ${_title}`}
      className="flex items-center justify-center text-[9px] w-[16px] h-[16px] bg-red rounded-full cursor-pointer text-white text-center"
    >
      {_badge}
    </div>
  );
};

NotificationBadge.defaultProps = {
  // badge: undefined,
  title: undefined,
};

export default NotificationBadge;
