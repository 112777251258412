// import { CancelToken } from "axios";
// import DOMPurify from "dompurify";
import { INewMessage, IReadMessage } from "../slices/messages.slice";
import instances from "./instances";

const search = async ({
  _id,
  skip,
  limit,
  filter,
  history,
}: {
  // cancelToken,
  _id: string;
  skip?: number;
  limit?: number;
  filter: string;
  history?: boolean;
  // cancelToken?: CancelToken;
}) => {
  try {
    // const response = await instances.instanceMessages.get(`${_id}`, {
    //   // cancelToken,
    //   params: {
    //     skip: skip || 0,
    //     limit: limit || 10,
    //     filter,
    //     fields: "",
    //   },
    // });
    const response = await instances.instanceMessages.get(
      `${_id}${typeof history !== "undefined" && history ? "/history" : ""}`,
      {
        params: {
          skip:
            typeof history !== "undefined" && history ? undefined : skip || 0,
          limit:
            typeof history !== "undefined" && history ? undefined : limit || 10,
          filter,
          fields: "",
        },
      }
    );
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const replyTime = async ({
  contactId,
  externalapp,
}: {
  contactId: string;
  externalapp: string;
}) => {
  try {
    const data = { _id: contactId, externalapp };
    const response = await instances.instanceMessages.post(`/replytime`, data);
    return response?.data;
  } catch (error) {
    // console.log("error readAll", error);
    return null;
  }
};

const create = async (newMessage: INewMessage) => {
  try {
    const data = {
      chat: newMessage.chat._id,
      // content: DOMPurify.sanitize(newMessage.content, {
      //   USE_PROFILES: { html: true },
      // }),
      content: newMessage.content,
      files: newMessage.files,
      parent: newMessage.parent,
      origin: newMessage.origin,
      internal: newMessage.internal,
      read: newMessage.read,
      inactivetime: newMessage.inactivetime,
      type: newMessage.type,
      sendertype: newMessage.sendertype,
      commenttemplate: newMessage.commenttemplate,
    };
    const response = await instances.instanceMessages.post("", data);
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const read = async (data: IReadMessage) => {
  try {
    const response = await instances.instanceMessages.post(
      "/readmessage",
      data
    );
    return response?.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};
const readAll = async (chatId: string) => {
  try {
    const data = { _id: chatId };
    const response = await instances.instanceMessages.post(
      `/readallmessage`,
      data
    );
    return response?.data;
  } catch (error) {
    // console.log("error readAll", error);
    return null;
  }
};

const deleteAll = async () => {
  try {
    const response = await instances.instanceMessages.delete("");
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const messagesService = {
  search,
  create,
  read,
  readAll,
  deleteAll,
  replyTime,
};

export default messagesService;
