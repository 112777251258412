/* eslint-disable no-shadow */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { settingsService } from "../services";
import { AsyncState } from "../shared/models/interfaces/asyncstate.interface";
import { DropdownTeam } from "./teams.slice";
import { ISurvey } from "./surveys.slice";
import { IOfficehour } from "./officehours.slice";
import { IUser, RoleUser } from "./users.slice";
import customToast from "../shared/utils/customToast";
import { IToastType } from "../shared/models/types/Toast.type";
import { IOfflinemessage } from "./offlinemessages.slice";
import { IFile } from "../shared/models/interfaces/file.interface";

export interface DefaultBot {
  name?: string;
  welcomemsg?: string;
  unavailablemsg?: string;
  userofflinemsg?: string;
  avatar?: any;
}
// export interface MessageTemplateComponent {
//   type?: string;
//   format?: string;
//   text?: string;
// }
// export interface MessageTemplate {
//   id: string;
//   name: string;
//   language: string;
//   status: string;
//   category: string;
//   components: MessageTemplateComponent[];
// }

// export interface SettingWhatsappInteractive {
//   header: string;
//   body: string;
//   button: string;
// }

// export interface SettingWhatsApp {
//   phone: string;
//   wabaid: string;
//   key: string;
//   verifytoken: string;
//   starttemplate?: string;
//   interactivemsg: SettingWhatsappInteractive;
//   validate: boolean;
// }

export interface DropdownUser {
  _id: string;
  name: string;
  email?: string;
}

export enum AuthTypes {
  WHATSAPP = "WHATSAPP",
  AGIDESK = "AGIDESK",
  FACEBOOK = "FACEBOOK",
  GOOGLE = "GOOGLE",
  APPLE = "APPLE",
  MICROSOFT = "MICROSOFT",
}

export interface SettingAuthTypes {
  source: AuthTypes;
  active: boolean;
}

export enum CompanySegment {
  INDUSTRY = "INDUSTRY",
  SERVICE = "SERVICE",
  BUSINESS = "BUSINESS",
}
export interface SettingCompany {
  name: string;
  code: string;
  address: string;
  phone: string;
  segment: CompanySegment;
  contactemail: string;
  contactphone: string;
  avatar?: IFile;
}

export interface SettingLgpd {
  field: string;
  roles: RoleUser[];
  active: boolean;
}

export interface ISettings {
  _id?: string;
  tenant?: string;
  deleted?: boolean;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
  created_by?: IUser | null;
  updated_by?: IUser | null;
  deleted_by?: IUser | null;
  company?: SettingCompany;
  defaultteam?: DropdownTeam[] | [];
  defaultagent?: DropdownUser | null;
  defaultbot?: DefaultBot;
  widget?: string;
  authtypes?: SettingAuthTypes[] | [];
  officehour?: IOfficehour;
  offlinemessage?: IOfflinemessage;
  // officehours?: SettingOfficehours[];
  isopen?: boolean;
  sendsurvey?: boolean;
  inactivetime?: string;
  appkey?: string;
  lastappkey?: string;
  survey?: ISurvey;
  lgpd?: SettingLgpd[];
}

export type Settings = ISettings[];

interface SettingsState extends AsyncState {
  isLoadingSettings: boolean;
  settings: ISettings[];
  totalSettings: number;
  selectedSettings: ISettings | null;
}

const initialState: SettingsState = {
  settings: [],
  selectedSettings: null,
  totalSettings: 0,
  isLoadingSettings: false,
  isSuccess: false,
  isError: false,
};

export const getSettings = createAsyncThunk(
  "settings/search",
  async ({ widget }: { widget?: string }, thunkAPI) => {
    try {
      if (typeof widget !== "undefined") {
        return await settingsService.settingsWidget();
      }
      return await settingsService.settings();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateSettings = createAsyncThunk(
  "settings/update",
  async ({ _settings }: { _settings: any }, thunkAPI) => {
    try {
      return await settingsService.update({ _settings });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    logout() {
      return initialState;
    },
    reselectSettings(state, action: PayloadAction<ISettings | null>) {
      state.selectedSettings = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // TEAMS
      .addCase(getSettings.pending, (state) => {
        state.isLoadingSettings = true;
      })
      .addCase(getSettings.fulfilled, (state, action) => {
        state.isSuccess = true;
        const _arrayPayload = [];
        if (action.payload && action.payload._id) {
          _arrayPayload.push(action.payload);
        }
        state.settings = _arrayPayload;
        if (_arrayPayload[0] && _arrayPayload[0]?._id) {
          const _payload = _arrayPayload[0];
          state.selectedSettings = _payload;
          if (!_payload.company || typeof _payload.company === "undefined") {
            _payload.company = {
              name: "",
              code: "",
              address: "",
              phone: "",
              segment: null,
              contactemail: "",
              contactphone: "",
            };
          }
          if (!_payload.lgpd || typeof _payload.lgpd === "undefined") {
            _payload.lgpd = [
              {
                field: "code",
                active: false,
                roles: [RoleUser.ADMINISTRATOR],
              },
            ];
          }
          if (_payload.defaultbot && !_payload.defaultbot.name) {
            _payload.defaultbot.name = "";
          }
          if (_payload.defaultbot && !_payload.defaultbot.welcomemsg) {
            _payload.defaultbot.welcomemsg = "";
          }
          if (_payload.defaultbot && !_payload.defaultbot.unavailablemsg) {
            _payload.defaultbot.unavailablemsg = "";
          }
          if (_payload.defaultbot && !_payload.defaultbot.userofflinemsg) {
            _payload.defaultbot.userofflinemsg = "";
          }
          if (_payload.defaultbot && !_payload.defaultbot.avatar) {
            _payload.defaultbot.avatar = "";
          }
          state.selectedSettings = _payload;
        } else state.selectedSettings = null;

        if (state.selectedSettings?._id) {
          // const _officehours = state.selectedSettings;
          // _officehours?.officehours?.forEach((officehour, index) => {
          //   officehour._id = index.toString();
          //   return officehour;
          // });
          // state.selectedSettings = _officehours;
          state.isLoadingSettings = false;
        }
        state.isLoadingSettings = false;
      })
      .addCase(getSettings.rejected, (state) => {
        state.isError = true;
        state.settings = [];
        state.isLoadingSettings = false;
      })
      // UPDATE SETTINGS
      .addCase(updateSettings.pending, () => {
        // state.isLoadingSettings = true;
      })
      .addCase(updateSettings.fulfilled, (state, action) => {
        if (action.payload !== null) {
          state.isSuccess = true;
          customToast({
            type: IToastType.SUCCESS,
            message: `Alteração salva com sucesso!`,
          });
        } else {
          customToast({
            type: IToastType.ERROR,
            message: `Algo deu errado!`,
          });
          state.isError = true;
        }
        state.isLoadingSettings = false;
      })
      .addCase(updateSettings.rejected, (state) => {
        state.isError = true;
        state.selectedSettings = null;
        state.isLoadingSettings = false;
      });
  },
});

export default settingsSlice.reducer;
export const { logout, reselectSettings } = settingsSlice.actions;
