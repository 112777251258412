/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { onHoverStyled, onRating } from "../SurveyElements.component";
import { IRatingData, IRatingResultData } from "../../../slices/surveys.slice";

const standardCss = "icon outline big";

interface Feelings {
  id: number;
  label: string;
  value: number;
  color: string;
  icon: string;
}

const feelings: Feelings[] = [
  {
    id: 0,
    label: "Muito insatisfeito",
    value: 0,
    color: "red",
    icon: "frown",
  },
  { id: 1, label: "Insatisfeito", value: 1, color: "orange", icon: "frown" },
  { id: 2, label: "Normal", value: 2, color: "yellow", icon: "meh" },
  { id: 3, label: "Satisfeito", value: 3, color: "olive", icon: "smile" },
  {
    id: 4,
    label: "Muito satisfeito",
    value: 4,
    color: "green",
    icon: "smile",
  },
];

const FeelingComponent = ({
  rating,
  setRating,
}: {
  rating: IRatingData;
  setRating: React.Dispatch<React.SetStateAction<IRatingData>>;
}) => {
  const actionIn = (value: number) => {
    onHoverStyled({ value, type: "feeling" }, rating);
  };
  const actionOut = (value: number) => {
    onHoverStyled({ value, type: "feeling", action: "out" }, rating);
  };

  const renderItems = feelings.map((feeling) => (
    <div
      role="presentation"
      key={feeling.id}
      id={`div-feeling-icon-${feeling.value}`}
      title={`Avaliar com: ${feeling.label}`}
      className={`ratingoptions ${
        window.innerWidth > 480 ? "w-[100px]" : "w-[75px]"
      } cursor-pointer`}
      onClick={() => {
        onRating({ value: feeling.value, type: "feeling" }, rating, setRating);
      }}
      onFocus={() => {
        actionIn(feeling.value);
      }}
      onMouseOver={() => {
        actionIn(feeling.value);
      }}
      onBlur={() => {
        actionOut(feeling.value);
      }}
      onMouseOut={() => {
        actionOut(feeling.value);
      }}
    >
      <i
        role="presentation"
        id={`feeling-icon-${feeling.value}`}
        onClick={() => {
          onRating(
            { value: feeling.value, type: "feeling" },
            rating,
            setRating
          );
        }}
        className={`${feeling.icon} ${standardCss} ${feeling.color}`}
      />
      <label>{feeling.label}</label>
    </div>
  ));
  return <div className="grid grid-cols-5 gap-3 my-5">{renderItems}</div>;
};

export const singleFeelingItem = (resultData: IRatingResultData) => {
  const index = resultData.value as number;
  const feeling =
    typeof feelings[index] !== "undefined" ? feelings[index] : ({} as Feelings);

  if (typeof feeling !== "undefined") {
    return (
      <>
        <i
          role="presentation"
          id={`feeling-icon-${feeling.value}`}
          className={`las la-${feeling.icon} text-5xl text-${feeling.color}`}
        />
        <label>{feeling.label}</label>
      </>
    );
  }
  return "-";
};

export default FeelingComponent;
