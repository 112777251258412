/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-danger */
/* eslint-disable no-plusplus */
import React, { useEffect, useState } from "react";
import { useAppDispatch } from "../../hooks/redux/hooks";
import ApprovalThumb, {
  singleApprovalItem,
} from "./Surveys/ApprovalThumb.component";
import FeelingComponent, {
  singleFeelingItem,
} from "./Surveys/Feeling.component";
import NpsComponent, { singleNpsItem } from "./Surveys/Nps.component";
import QualityComponent, {
  singleQualityItem,
} from "./Surveys/Quality.component";
import {
  IRatingData,
  IRatingResultData,
  IRatingSelected,
  ISurveyRating,
} from "../../slices/surveys.slice";
import customToast from "../utils/customToast";
import { IToastType } from "../models/types/Toast.type";
import { updateSurveyRating } from "../../slices/surveyRatings.slice";

export const onHoverStyled = (
  ratingSelected: IRatingSelected,
  rating: IRatingData
) => {
  if (
    typeof rating.finished_at !== "undefined" &&
    typeof rating.result !== "undefined"
  ) {
    return;
  }
  const { value, type, action } = ratingSelected || null;

  const hover = (_value: number) => {
    const element = document.getElementById(`${type}-icon-${_value}`);
    const parentDiv = document.getElementById(`div-${type}-icon-${_value}`);
    if (action === "out") {
      element?.classList.add("outline");
      parentDiv?.classList.remove("hover");
    } else {
      element?.classList.remove("outline");
      parentDiv?.classList.add("hover");
    }
  };

  if (type === "quality") {
    for (let i = 0; i <= value; i++) {
      hover(i);
    }
  } else {
    hover(value);
  }
};

export const onRating = async (
  ratingSelected: IRatingSelected,
  rating: IRatingData,
  setRating: React.Dispatch<React.SetStateAction<IRatingData>>
) => {
  const { value } = ratingSelected;
  if (
    typeof rating.result === "undefined" &&
    typeof rating.rating !== "undefined"
  ) {
    onHoverStyled(ratingSelected, rating);
    setRating({
      ...rating,
      result: [
        { id: null, value: value.toString(), type: ratingSelected.type },
      ],
    });
  }
};

export const getRatingElement = (ratingResult: IRatingResultData) => {
  const singleElements: any = {
    nps: singleNpsItem(ratingResult),
    quality: singleQualityItem(ratingResult),
    feeling: singleFeelingItem(ratingResult),
    approval: singleApprovalItem(ratingResult),
  };

  let resultComponent = "-";

  if (typeof ratingResult.type !== "undefined") {
    resultComponent =
      typeof singleElements[ratingResult.type] !== "undefined"
        ? singleElements[ratingResult.type]
        : "";
  }

  return resultComponent;
};

export const surveyBody = ({
  surveyRating,
  preview,
  setAnswered,
}: {
  surveyRating?: ISurveyRating | any;
  preview?: boolean;
  setAnswered?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const dispatch = useAppDispatch();

  const [rating, setRating] = useState<IRatingData>({});

  const {
    survey,
    expire_at: expireAt,
    finished_at: finishedAt,
    _id,
    code,
    user,
  } = surveyRating ?? {};

  const { type: surveyType, content } = survey ?? {};

  const messages = {
    not_founded: "Pesquisa de satisfação não encontrada",
    expired: "Pesquisa de satisfação expirada",
    rated: "Pesquisa de satisfação já respondida. Obrigado.",
    success: "Pesquisa de satisfação respondida!! Obrigado por sua avaliação.",
  };

  const errorTreatments = (message: string | undefined) => {
    if (typeof message !== "undefined") {
      const currentDate = new Date().getTime();
      const expired =
        !preview &&
        (message.indexOf("expired") !== -1 ||
          (typeof expireAt !== "undefined" &&
            currentDate > new Date(expireAt)?.getTime()));
      const rated =
        !preview &&
        (message.indexOf("answered") !== -1 ||
          typeof finishedAt !== "undefined");
      const surveyMessage = expired
        ? messages.expired
        : rated
        ? messages.rated
        : messages.not_founded;

      return surveyMessage;
    }
    return undefined;
  };

  const submitRating = () => {
    (async () => {
      if (preview) {
        return;
      }

      if (
        typeof rating?.result !== "undefined" &&
        typeof rating?.rating !== "undefined"
      ) {
        const { payload } = await dispatch(
          updateSurveyRating({
            ...rating,
            result: rating.result,
          })
        );

        if (typeof payload.message === "undefined") {
          setRating({
            ...rating,
            finished_at: new Date(),
          });
          customToast({
            type: IToastType.SUCCESS,
            message: messages.success,
          });
          if (setAnswered) setAnswered(true);
        } else {
          customToast({
            type: IToastType.WARNING,
            message:
              errorTreatments(payload.message) || "Algo inesperado ocorreu.",
          });
          if (setAnswered) setAnswered(false);
        }
      }
    })();
  };

  // Loading rating data
  useEffect(() => {
    setRating({
      ...rating,
      rating: _id,
      code,
      user,
    });
  }, [_id]);

  useEffect(() => {
    if (
      typeof rating?.result !== "undefined" &&
      typeof rating?.code !== "undefined" &&
      typeof rating.rating !== "undefined" &&
      typeof rating.finished_at === "undefined"
    ) {
      submitRating();
    }
  }, [rating]);

  const surveyComponents: any = {
    QUALITY: <QualityComponent rating={rating} setRating={setRating} />,
    NPS: <NpsComponent rating={rating} setRating={setRating} />,
    FEELING: <FeelingComponent rating={rating} setRating={setRating} />,
    APPROVAL: <ApprovalThumb rating={rating} setRating={setRating} />,
  };

  let _surveyBody = (
    <h1 className="text-[18px] font-semibold whitespace-nowrap text-ellipsis overflow-hidden">
      <>{errorTreatments(surveyRating?.message)}</>
    </h1>
  );

  if (typeof surveyType !== "undefined") {
    const surveyComponent =
      typeof surveyComponents[surveyType] !== "undefined"
        ? surveyComponents[surveyType]
        : "";
    if (typeof surveyComponent !== "undefined") {
      _surveyBody = (
        <>
          <div
            className="pb-2"
            dangerouslySetInnerHTML={{
              __html: typeof content !== "undefined" ? content : "",
            }}
          />
          <div className="p-2 flex justify-center items-center text-center">
            {surveyComponent}
          </div>
        </>
      );
    }
  }

  return <div id="survey-body-content">{_surveyBody}</div>;
};
