import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DropdownSearchInputProps } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SectionPageProps } from "../../pages/Default.page";
import {
  ICommenttemplate,
  deleteCommenttemplate,
  reselectCommenttemplate,
  reselectLocalCommenttemplate,
  updateCommenttemplate,
} from "../../slices/commenttemplates.slice";
import { ITeam } from "../../slices/teams.slice";
import { ddOptions } from "../../shared/models/interfaces/dropdownOptions.interface";
import removeDuplicates from "../../shared/utils/removeDuplicates";
import { authService, teamsService } from "../../services";
import customToast from "../../shared/utils/customToast";
import { IToastType } from "../../shared/models/types/Toast.type";
import { Files, IFile } from "../../shared/models/interfaces/file.interface";
import { reselectTemplateFiles } from "../../slices/messages.slice";

const useCommenttemplates = ({
  disable,
  setDisable,
  toggleSidebar,
}: {
  toggleSidebar?: boolean;
  disable?: boolean;
  setDisable?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  interface sectionDetailsObject {
    [key: string]: SectionPageProps;
  }
  type onChangeEvent = React.SyntheticEvent<HTMLElement, Event>;
  type onChangeValue =
    | string
    | number
    | boolean
    | (string | number | boolean)[]
    | undefined;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const { selectedCommenttemplate, selectedLocalCommenttemplate } =
    useAppSelector((state) => state.commenttemplates);
  const { selectedTemplateFiles, selectedTemplateFilesChanged } =
    useAppSelector((state) => state.messages);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showReactiveModal, setShowReactiveModal] = useState<boolean>(false);
  const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);

  const teamsValue = useRef<string[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [teamsOptions, setTeamsOptions] = useState<ddOptions[]>([]);
  const [focusId, setFocusId] = useState<string>("");
  const [isLoadingDropdown, setIsLoadingDropdown] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const commenttemplateCallback = useCallback(() => {
    if (
      selectedCommenttemplate !== null &&
      typeof selectedCommenttemplate?._id !== "undefined"
    ) {
      setFiles([]);
      dispatch(reselectLocalCommenttemplate(selectedCommenttemplate));
    }
  }, [selectedCommenttemplate]);

  const teamsCallback = useCallback(() => {
    const _options: ddOptions[] = teamsOptions;
    const _value: string[] = [];
    selectedLocalCommenttemplate?.teams?.forEach((_team) => {
      if (_team.active)
        _options.push({
          key: _team._id,
          text: _team.name,
          value: _team._id,
        });
    });
    if (
      selectedLocalCommenttemplate?.teams &&
      selectedLocalCommenttemplate?.teams.length > 0
    ) {
      selectedLocalCommenttemplate?.teams.forEach((_team) => {
        if (typeof _team?._id !== "undefined") {
          _value.push(_team._id);
        }
      });
    }
    const options = removeDuplicates(_options, "key");
    const defaultValue = removeDuplicates(_value);
    return { options, defaultValue };
  }, []);

  const sectionDetails: sectionDetailsObject = {
    infos: {
      id: "commenttemplate-info",
      icon: "las la-info-circle",
      title: "Informações",
      description: "Edite as informações exibidas",
    },
    team: {
      id: "commenttemplate-teams",
      icon: "las la-user-friends",
      title: "Equipes",
      description:
        "Defina quais equipes terão acesso a esse modelo de resposta.",
    },
    content: {
      id: "commenttemplate-content",
      icon: "las la-comment",
      title: "Modelo de resposta",
      description: "Configure o modelo de resposta",
    },
  };

  const header: {
    icon?: string;
    title?: string;
    created?: string;
    updated?: string;
    deleted?: string;
  } = {
    created: selectedCommenttemplate?.created_at,
    updated: selectedCommenttemplate?.updated_at,
    deleted: selectedCommenttemplate?.deleted_at,
    title: selectedCommenttemplate?.name,
    icon: "las la-comment",
  };

  const back = () => {
    navigate("/settings/commenttemplates");
  };

  const remove = async () => {
    if (setDisable) setDisable(true);
    setShowDeleteModal(false);
    if (selectedCommenttemplate?._id) {
      const { payload } = await dispatch(
        deleteCommenttemplate([selectedCommenttemplate._id])
      );
      if (setDisable) setDisable(false);
      if (
        payload !== null &&
        typeof payload !== "undefined" &&
        typeof payload?.message !== "string"
      ) {
        navigate("/settings/commenttemplates");
      }
    }
  };

  const reactive = async () => {
    if (setDisable) setDisable(true);
    setShowReactiveModal(false);
    const _commenttemplate = {
      ...selectedLocalCommenttemplate,
      teams: selectedLocalCommenttemplate?.teams?.map((_t: ITeam | string) => {
        if (typeof _t === "string") return _t;
        return _t._id;
      }),
      active: true,
      deleted: false,
      deleted_by: null,
      deleted_at: "",
    };
    const { payload } = await dispatch(
      updateCommenttemplate({
        _commenttemplate: _commenttemplate as ICommenttemplate,
      })
    );
    if (setDisable) setDisable(false);
    if (typeof payload._id !== "undefined") {
      navigate(-1);
    }
  };

  const getMessageError = (_files: string) => {
    if (_files.toLowerCase().includes("unsupported")) {
      return t("toast.error.files.unsupported");
    }
    if (_files.toLowerCase().includes("too large"))
      return t("toast.error.files.too-large");
    return t("toast.error.files.upload");
  };

  const save = async () => {
    if (setDisable) setDisable(true);
    let _files: Files | string | null = [];
    let fileIds: string[] = [];
    const _filesLocalId =
      typeof selectedLocalCommenttemplate?.files !== "undefined" &&
      selectedLocalCommenttemplate.files.length > 0
        ? (selectedLocalCommenttemplate?.files as IFile[])?.map(
            (_file) => _file?._id as string
          )
        : [];
    if (
      selectedTemplateFilesChanged === true &&
      selectedTemplateFiles.length > 0
    )
      _files = await authService.uploadFile(selectedTemplateFiles);

    if (typeof _files === "string") {
      customToast({
        type: IToastType.ERROR,
        message: getMessageError(_files),
      });
      return;
    }

    if (_files && _files !== null && _files?.length > 0) {
      fileIds = [..._filesLocalId, ..._files.map((file) => file?._id || "")];
    } else {
      fileIds = [..._filesLocalId];
    }
    setFiles([]);

    const _commenttemplate = {
      ...selectedLocalCommenttemplate,
      _id: selectedLocalCommenttemplate?._id,
      name: selectedLocalCommenttemplate?.name,
      active: selectedLocalCommenttemplate?.active,
      content: selectedLocalCommenttemplate?.content,
      files: removeDuplicates(fileIds),
      teams: selectedLocalCommenttemplate?.teams?.map((_t: ITeam) => {
        if (typeof _t === "string") return _t;
        return _t._id;
      }),
    } as ICommenttemplate;
    const { payload } = await dispatch(
      updateCommenttemplate({ _commenttemplate })
    );
    dispatch(reselectCommenttemplate(payload));
    dispatch(
      reselectTemplateFiles({
        files: [],
        currentChanged: false,
      })
    );
    setFiles([]);
    if (setDisable) setDisable(false);
  };

  const updateCommenttemplateData = ({
    field,
    value,
  }: {
    field: string;
    value:
      | undefined
      | null
      | string
      | boolean
      | number
      | string[]
      | {
          _id: string;
          name: string;
        }
      | {
          _id: string;
          name: string;
        }[];
  }) => {
    if (selectedLocalCommenttemplate) {
      dispatch(
        reselectLocalCommenttemplate({
          ...selectedLocalCommenttemplate,
          [field]: value,
        })
      );
    } else {
      dispatch(reselectLocalCommenttemplate(null));
    }
  };

  const infos = {
    active: {
      disabled: selectedCommenttemplate?.deleted,
    },
    disabled: selectedLocalCommenttemplate?.deleted,
    readonly: selectedLocalCommenttemplate?.deleted,
    onChange: {
      name: async (e: onChangeEvent, { value }: { value: onChangeValue }) => {
        if (typeof value === "string") {
          updateCommenttemplateData({
            field: "name",
            value,
          });
        }
      },
      active: async (e: onChangeEvent, { value }: { value: onChangeValue }) => {
        try {
          if (typeof value === "boolean") {
            updateCommenttemplateData({
              field: "active",
              value,
            });
          }
        } catch (error) {
          // console.log(error);
        }
      },
    },
  };

  const teams = {
    dropdown: {
      defaultValue:
        teamsValue.current.length === 0
          ? teamsCallback().defaultValue
          : teamsValue.current,
      loading: isLoadingDropdown && focusId === "dropdown-relation-teams",
      setIsLoadingDropdown,
      setOptions: setTeamsOptions,
      onFocus: () => {
        setFocusId("dropdown-relation-teams");
      },
      onChange: (value: string[]) => {
        teamsValue.current = removeDuplicates(value);
      },
      onBlur: () => {
        setFocusId("");
      },
      onOpen: async () => {
        setIsLoadingDropdown(true);
        const payload = await teamsService.search({
          skip: 0,
          limit: 10,
          filter: "",
          deleted: false,
        });
        setIsLoadingDropdown(false);
        const _optionsTeams: object[] = [];
        payload.results.map((_team: ITeam) =>
          _optionsTeams.push({
            key: _team._id,
            text: _team?.name,
            value: _team._id,
          })
        );
        setTeamsOptions(_optionsTeams);
      },
      onSearch: async (e: DropdownSearchInputProps) => {
        setIsLoadingDropdown(true);
        const payload = await teamsService.search({
          skip: 0,
          limit: 10,
          filter: e.target.value,
          deleted: false,
        });
        setIsLoadingDropdown(false);
        const _optionsTeams: ddOptions[] = teamsOptions;
        payload.results.map((_team: ITeam) => {
          if (_optionsTeams.filter((_opTeam) => _opTeam.key === _team._id)[0]) {
            return false;
          }
          _optionsTeams.push({
            key: _team._id,
            text: _team?.name,
            value: _team._id,
          });
          return true;
        });
        setTeamsOptions(_optionsTeams);
      },
      options:
        teamsOptions.length === 0 ? teamsCallback().options : teamsOptions,
    },
    modal: {
      show: showModal,
      setShow: setShowModal,
      onCancel: () => {
        setShowModal(false);
      },
      onSave: async () => {
        setShowModal(false);
      },
      disabled:
        teamsValue.current.length === 0 ||
        selectedCommenttemplate?.deleted ||
        false,
    },
    relation: {
      onClickRelation: () => {
        setShowModal(true);
      },
    },
  };

  const content = {
    id: "commenttemplate-content",
    label: "Configure o modelo de resposta",
    initialValue: selectedLocalCommenttemplate?.content,
    onChange: updateCommenttemplateData,
    showPreviewModal,
    setShowPreviewModal,
    disabled: selectedCommenttemplate?.deleted || false,
    files: selectedTemplateFiles,
    setFiles,
  };

  const footer = {
    disabled:
      disable ||
      selectedLocalCommenttemplate?.deleted ||
      typeof selectedLocalCommenttemplate?.name === "undefined" ||
      selectedLocalCommenttemplate?.content?.length === 0 ||
      selectedLocalCommenttemplate?.name?.length < 2,
    deleted: selectedLocalCommenttemplate?.deleted,
    back,
    reactive,
    remove,
    save,
    toggleSidebar: toggleSidebar || false,
    modaltitle: selectedLocalCommenttemplate?.deleted
      ? "Deseja reativar este modelo de resposta?"
      : "Deseja remover  este modelo de resposta?",
    showDeleteModal,
    setShowDeleteModal,
    showReactiveModal,
    setShowReactiveModal,
  };

  return {
    commenttemplateCallback,
    header,
    footer,
    infos,
    teams,
    content,
    sectionDetails,
  };
};

export default useCommenttemplates;
