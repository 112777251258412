/* eslint-disable no-shadow */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AsyncState } from "../shared/models/interfaces/asyncstate.interface";
import { IUser } from "./users.slice";
import customToast from "../shared/utils/customToast";
import { IToastType } from "../shared/models/types/Toast.type";
import campaignBatchesService from "../services/campaignBatches.service";

export interface ICampaignBatch {
  _id?: string;
  name?: string;
  active?: boolean;
  deleted?: boolean;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
  finished_at?: string;
  created_by?: IUser | null;
  updated_by?: IUser | null;
  deleted_by?: IUser | null;
  batchtarget?: object[];
  scheduledate?: string;
  content?: string;
  pending?: number;
  success?: number;
  targets?: object[];
  status?: CampaignBatchStatus;
}

export enum CampaignBatchStatus {
  CANCELED = "CANCELED",
  SUCCESS = "SUCCESS",
  PENDING = "PENDING",
  SENDING = "SENDING",
}

export interface ICampaignBatchSearchFilter {
  period: object | [];
  status: CampaignBatchStatus[] | string[];
  content: string;
}

interface CampaignBatchesState extends AsyncState {
  isLoadingCampaignBatches: boolean;
  selectedCampaignBatches: any;
  isRemoving: boolean;
}

const initialState: CampaignBatchesState = {
  isLoadingCampaignBatches: false,
  selectedCampaignBatches: null,
  isSuccess: false,
  isError: false,
  isRemoving: false,
};

export const updateCampaignBatch = createAsyncThunk(
  "campaignbatch/update",
  async (
    { _batch, noToast }: { _batch: ICampaignBatch; noToast?: boolean },
    thunkAPI
  ) => {
    try {
      return await campaignBatchesService.update({ _batch });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteTargets = createAsyncThunk(
  "campaignbatches/deletetargets",
  async (
    {
      campaignbatch,
      ids,
    }: {
      campaignbatch: string;
      ids: string[];
    },
    thunkAPI
  ) => {
    try {
      return await campaignBatchesService.deleteTargets({ campaignbatch, ids });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const sendBatch = createAsyncThunk(
  "campaignbatches/sendbatch",
  async (
    {
      id,
    }: {
      id: string;
    },
    thunkAPI
  ) => {
    try {
      return await campaignBatchesService.sendBatch(id);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const campaignbatchsSlice = createSlice({
  name: "campaignbatches",
  initialState,
  reducers: {
    logout() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      // OFFICEHOURS
      .addCase(deleteTargets.pending, (state) => {
        state.isRemoving = true;
      })
      .addCase(deleteTargets.fulfilled, (state, action) => {
        if (
          action.payload !== null &&
          typeof action.payload !== "undefined" &&
          typeof action.payload?.message !== "string"
        ) {
          state.isSuccess = true;
          customToast({
            type: IToastType.SUCCESS,
            message: `Remoção realizada com sucesso!`,
          });
        } else {
          state.isError = true;
          customToast({
            type: IToastType.ERROR,
            message: `Algo deu errado!`,
          });
        }
      })
      .addCase(deleteTargets.rejected, (state) => {
        state.isError = true;
        state.isRemoving = false;
      })
      .addCase(updateCampaignBatch.pending, (/* state */) => {})
      .addCase(updateCampaignBatch.fulfilled, (state, action) => {
        if (
          action?.payload !== null &&
          typeof action?.payload?._id !== "undefined"
        ) {
          state.isSuccess = true;
          customToast({
            type: IToastType.SUCCESS,
            message: `Alteração realizada com sucesso!`,
          });
        }
      })
      .addCase(updateCampaignBatch.rejected, (state) => {
        state.isError = true;
      })
      .addCase(sendBatch.pending, (state) => {
        // state.isLoadingCampaignBatches = true;
      })
      .addCase(sendBatch.fulfilled, (state, action) => {
        if (
          action?.payload !== null &&
          typeof action?.payload?._id !== "undefined"
        ) {
          state.isSuccess = true;
          customToast({
            type: IToastType.SUCCESS,
            message: `Envio inicializado com sucesso!`,
          });

          // state.isLoadingCampaignBatches = false;
        }
      })
      .addCase(sendBatch.rejected, (state) => {
        state.isError = true;
      });
  },
});

export default campaignbatchsSlice.reducer;
export const { logout } = campaignbatchsSlice.actions;
