/* eslint-disable no-restricted-syntax */
import React from "react";
import { useAppSelector } from "../../../../hooks/redux/hooks";
import { RoleUser } from "../../../../slices/users.slice";

const SecondaryOptions = ({
  handleClick,
  handleEmojiClick,
  handleCommenttemplateClick,
  openAttachsPicker,
  openPicker,
}: {
  handleEmojiClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleCommenttemplateClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  handleClick: () => void;
  openAttachsPicker: boolean;
  openPicker: boolean;
}) => {
  const { user } = useAppSelector((state) => state.auth);
  const items = [
    {
      onClick: handleEmojiClick,
      icon: "la-grin",
      title: "Emojis",
    },
    {
      onClick: handleClick,
      icon: "la-paperclip",
      title: "Anexos",
    },

    // {
    //   onClick: handleClick,
    //   icon: "la-lock",
    //   title: "Interno",
    // },
  ];

  if (!user?.roles?.includes(RoleUser.CONTACT)) {
    items.push({
      onClick: handleCommenttemplateClick,
      icon: "la-comment",
      title: "Modelos de respostas",
    });
  }

  const showItems = () => {
    const jsx = [];
    for (const item of items) {
      jsx.push(
        <button
          key={item.title}
          type="button"
          onClick={item.onClick}
          className={`las ${
            item.icon
          } text-[24px] w-[40px] h-[40px] border-0 hover:text-agitalks-alphaC0 ${
            openPicker && item.icon === "la-grin"
              ? "text-agitalks"
              : "text-gray-text hover:!text-agitalks"
          }`}
          title={item.title}
          aria-label={item.title}
        />
      );
    }
    return jsx;
  };

  return (
    <div
      className={`${
        !openAttachsPicker
          ? "hidden border-t-[1px] border-t-gray-ddd"
          : "border-y-[1px] border-y-gray-ddd"
      } flex relative items-center`}
    >
      {showItems()}
    </div>
  );
};

export default SecondaryOptions;
