import React from "react";

const SvgComponent = (props: any) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} {...props}>
    <path
      d="M550.02 217.002c-34.146-3.296-72.115 10.151-87.144 40.87-15.03 30.717-28.74 54.315-43.506 83.451-14.897 29.136-29.004 54.976-43.638 83.452-14.633 28.477-29.53 55.767-43.9 83.98-14.371 28.213-29.532 55.107-43.902 83.188-14.37 28.081-28.872 55.503-43.638 83.32-14.634 27.95-28.608 55.767-43.506 83.453-15.03 27.817-37.573 62.226-28.08 94.658 9.36 32.3 28.476 52.998 57.216 69.741 28.74 16.743 71.587 21.753 101.91 6.592 30.19-15.03 38.759-45.615 53.92-73.037 15.161-27.422 28.872-56.426 43.901-83.98 15.03-27.553 28.477-55.898 43.638-83.452 15.03-27.553 28.345-55.898 43.374-83.584 15.161-27.685 28.477-55.503 43.506-83.452 15.03-27.95 29.004-55.635 44.033-83.98 15.161-28.344 27.95-54.316 43.901-83.188 15.82-28.872 7.515-70.005-15.293-93.735-22.939-23.862-50.756-41.265-82.792-44.297ZM676.978 512.974c-37.837-3.692-72.378 12.26-99.273 36.782-26.763 24.521-37.837 65.259-21.49 97.031 16.348 31.773 26.632 61.7 42.056 92.944 15.293 31.246 26.895 62.754 42.715 93.208 15.952 30.586 20.699 63.94 46.802 88.33 25.972 24.39 74.224 24.127 105.469 10.02 31.377-14.106 55.635-33.09 67.368-67.632 11.865-34.54-12.26-72.378-25.84-103.491-13.579-30.981-28.872-61.172-42.056-92.944-13.051-31.773-30.058-59.986-41.923-92.945-11.997-33.09-37.574-57.744-73.828-61.303Zm0 0"
      style={{
        fill: "none",
        strokeWidth: 0.3,
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        stroke: "#000",
        strokeOpacity: 1,
        strokeMiterlimit: 4,
      }}
      transform="rotate(.298) scale(.02963)"
    />
    <path
      d="M16.297 6.43c-1.012-.098-2.137.3-2.582 1.21-.445.91-.852 1.61-1.29 2.473-.44.864-.859 1.63-1.292 2.473-.434.844-.875 1.652-1.301 2.488-.426.836-.875 1.633-1.3 2.465a184.83 184.83 0 0 1-1.294 2.469c-.433.828-.847 1.652-1.289 2.472-.445.825-1.113 1.844-.832 2.805.278.957.844 1.57 1.696 2.067.851.496 2.12.644 3.019.195.895-.445 1.148-1.352 1.598-2.164.449-.813.855-1.672 1.3-2.488.446-.817.844-1.657 1.293-2.473.446-.817.84-1.656 1.286-2.477.449-.82.843-1.644 1.289-2.472.445-.828.859-1.649 1.304-2.489.45-.84.828-1.609 1.301-2.464.469-.856.223-2.075-.453-2.778-.68-.707-1.504-1.222-2.453-1.312ZM20.059 15.2c-1.122-.11-2.145.363-2.942 1.09-.793.726-1.12 1.933-.637 2.874.485.941.79 1.828 1.247 2.754.453.926.796 1.86 1.265 2.762.473.906.613 1.894 1.387 2.617.77.723 2.2.715 3.125.297.93-.418 1.648-.98 1.996-2.004.352-1.024-.363-2.145-.766-3.067-.402-.918-.855-1.812-1.246-2.753-.386-.942-.89-1.778-1.242-2.754-.355-.98-1.113-1.711-2.187-1.817Zm0 0"
      style={{
        stroke: "none",
        fillRule: "nonzero",
        fill: "#000",
        fillOpacity: 1,
      }}
    />
  </svg>
);
export default SvgComponent;
