import React from "react";
import { Form } from "semantic-ui-react";
import { useAppSelector } from "../../../hooks/redux/hooks";
import RelatedInstance from "../../../shared/components/RelatedInstance.component";
import RelatedButtons from "../../../shared/components/RelatedButtons.component";
import RelationModal from "../../../shared/components/Modals/RelationModal.component";
import useTags from "../../../hooks/features/useTags";
import Toggle from "../../../shared/components/Toggle.component";

const Teams = () => {
  const { teams } = useTags({});
  const { selectedLocalTag } = useAppSelector((state) => state.tags);
  return (
    <>
      <Form>
        <Form.Group widths="equal">
          <Toggle
            disabled={teams.readOnly}
            readOnly={teams.readOnly}
            containerClass="flex w-full"
            defaultChecked={!selectedLocalTag?.global}
            mainClass={`items-center flex ${
              !selectedLocalTag?.global ? "" : "mb-4"
            }`}
            onChange={(_, { checked }) => {
              teams.onChange({ checked: checked || false });
            }}
            labelClass="ml-4"
            label="Retringir as equipes selecionadas"
          />
        </Form.Group>
        {typeof selectedLocalTag?.global !== "undefined" &&
        !selectedLocalTag.global ? (
          <>
            <div className="flex flex-col w-full text-justify">
              <label>Equipes relacionadas</label>
              <div
                id="sidemenu"
                className={`flex flex-wrap max-h-[500px] overflow-y-scroll pb-1 ${
                  selectedLocalTag?.teams?.length ? "" : "justify-center"
                }`}
              >
                {selectedLocalTag?.teams?.length ? (
                  selectedLocalTag?.teams?.map((_team: any) => {
                    if (typeof _team._id !== "undefined") {
                      return (
                        <RelatedInstance
                          key={`${_team._id}team`}
                          type="team"
                          instance={_team}
                          avatar={false}
                        />
                      );
                    }
                    return null;
                  })
                ) : (
                  <div className="my-4 text-center">Nenhuma equipe</div>
                )}
              </div>
            </div>
            {!selectedLocalTag?.deleted ? (
              <RelatedButtons
                disabled={teams.readOnly}
                onClickRelation={teams.relation.onClickRelation}
              />
            ) : null}
          </>
        ) : null}
      </Form>
      {teams.modal.show ? (
        <RelationModal
          title="Relacionar equipes"
          icon="las la-user-friends"
          setShowModal={teams.modal.setShow}
        />
      ) : null}
    </>
  );
};

export default Teams;
