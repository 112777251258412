import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux/hooks";
import { changeStatus } from "../../slices/auth.slice";
import NoAvatar from "./NoAvatar.component";
import { UserStatus } from "../../slices/users.slice";
// import reassignment from "../utils/reassignment";
// import { teamsService } from "../../services";
import { teamsChatsAssignment } from "../../slices/teams.slice";
import Avatar from "./Avatar.component";

const UserProfileStatus = () => {
  // const URL = process.env.REACT_APP_API_BASE_URL;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const status = user?.status;

  const [_status, setStatus] = useState(status);

  useEffect(() => {
    setStatus(user?.status);
  }, [user]);

  const handleChangeStatus = async () => {
    dispatch(
      changeStatus({
        status:
          _status === UserStatus.OFFLINE
            ? UserStatus.ONLINE
            : UserStatus.OFFLINE,
        origin: user?._id,
        manual: true,
      })
    );
    if (status === UserStatus.ONLINE) {
      setStatus(UserStatus.OFFLINE);
    } else if (status === UserStatus.OFFLINE) {
      setStatus(UserStatus.ONLINE);
      // await reassignment({});
      await dispatch(teamsChatsAssignment());
    }
  };

  return (
    <div
      role="presentation"
      title={user?.name}
      onClick={(e) => {
        e.preventDefault();
        navigate("/settings/profile");
      }}
      className="w-10 h-12 flex justify-center items-center relative cursor-pointer"
    >
      <div className="w-[32px] h-[32px]">
        {user && user?.avatar && user.avatar?.path && user?.avatar?.name ? (
          <Avatar
            path={user?.avatar?.path}
            name={user?.avatar?.name}
            title="Foto do perfil"
          />
        ) : (
          <NoAvatar
            displaytext={user?.name || "--"}
            elementClass="p-0"
            labelClass="text-[12px]"
          />
        )}
      </div>
      <div
        title="Trocar status"
        // aria-label="status-change"
        role="presentation"
        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
          e.preventDefault();
          e.stopPropagation();
          handleChangeStatus();
        }}
        className={`flex absolute w-[13px] h-[13px] ${
          _status === UserStatus.ONLINE ? "bg-green" : "bg-red"
        } rounded-full bottom-0 right-0 cursor-pointer`}
      />
    </div>
  );
};

export default UserProfileStatus;
