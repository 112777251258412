import React, { useState, useEffect, useRef } from "react";
import { useReactMediaRecorder } from "react-media-recorder";
import { typingMessage } from "../../../../slices/messages.slice";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux/hooks";

const AudioRecorder = ({
  extraActions,
  setSending,
}: {
  extraActions?: (blobUrl: string, blob: Blob) => Promise<void>;
  setSending?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [isStopped, setIsStopped] = useState(false);
  const [timer, setTimer] = useState(0);

  const currentMediaBlobUrl = useRef<string>("");
  const currentMediaBlob = useRef<Blob>(null);

  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const { selectedChat } = useAppSelector((state) => state.chats);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isRecording && !isPaused) {
      interval = setInterval(() => {
        setTimer((prev) => prev + 1);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isRecording, isPaused]);

  const getIcon = () => {
    if (isRecording && !isPaused) {
      return "las la-pause-circle text-gray-text";
    }
    if (isRecording && isPaused) {
      return "las la-microphone text-red";
    }
    return "las la-microphone text-gray-text";
  };
  const getLabel = () => {
    if (isRecording && !isPaused) {
      return "Pausar áudio";
    }
    if (isRecording && isPaused) {
      return "Continuar áudio";
    }
    return "Gravar áudio";
  };

  const {
    status,
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
    clearBlobUrl,
  } = useReactMediaRecorder({
    audio: true,
    stopStreamsOnStop: true,
    blobPropertyBag: {
      type: "audio/mpeg",
    },
    onStop: async (blobUrl, blob) => {
      currentMediaBlobUrl.current = blobUrl;
      currentMediaBlob.current = blob;
    },
  });

  const handleMicClick = async () => {
    clearBlobUrl();
    currentMediaBlobUrl.current = "";
    currentMediaBlob.current = null;
    if (selectedChat) {
      dispatch(
        typingMessage({
          chat: selectedChat,
          sender: user?._id || "",
          message: "",
          type: "audio",
          action: "start",
        })
      );
    }

    setIsStopped(false);
    if (isRecording && !isPaused) {
      pauseRecording();
      setIsPaused(true);
    } else if (isRecording && isPaused) {
      resumeRecording();
      setIsPaused(false);
    } else {
      startRecording();
      setIsRecording(true);
      setTimer(0);
    }
  };

  const stopActions = () => {
    stopRecording();
    setIsStopped(true);
    setIsRecording(false);
    setIsPaused(false);
    setTimer(0);
    if (selectedChat) {
      dispatch(
        typingMessage({
          chat: selectedChat,
          sender: user?._id || "",
          message: "",
          type: "audio",
          action: "stop",
        })
      );
    }
  };

  const AudioBar = () => (
    <div className="border-t-[1px] border-t-gray-ddd flex relative items-center">
      <div className="bg-transparent flex-1 border-0 my-0 mx-[10px] p-[12px] h-[45px] resize-none text-[14px] outline-none" />
      {isRecording || (isStopped && currentMediaBlobUrl.current.length > 0) ? (
        <button
          type="button"
          onClick={async () => {
            stopActions();
            setIsStopped(false);
            clearBlobUrl();
            currentMediaBlobUrl.current = "";
            currentMediaBlob.current = null;
          }}
          className="las la-trash text-gray-text text-[24px] w-[40px] h-[40px] border-0"
          title="Cancelar áudio"
          aria-label="Cancelar áudio"
        />
      ) : null}
      {currentMediaBlobUrl.current && !isRecording && isStopped && (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <audio id="audio-file" controls preload="auto">
          <source src={currentMediaBlobUrl.current} type="audio/mpeg" />
        </audio>
      )}
      {isRecording && (
        <p className="text-[12px] text-gray-999">
          {new Date(timer * 1000).toISOString().slice(14, 19)}
        </p>
      )}
      {!isStopped && (
        <button
          type="button"
          onClick={handleMicClick}
          className={`${getIcon()} text-[24px] w-[40px] h-[40px] border-0`}
          title={getLabel()}
          aria-label={getLabel()}
        />
      )}
      {isRecording && !isStopped && (
        <button
          type="button"
          onClick={stopActions}
          className="las la-stop-circle text-red text-[24px] w-[40px] h-[40px] border-0"
          title="Parar áudio"
          aria-label="Parar áudio"
        />
      )}
      {isRecording || (isStopped && currentMediaBlob.current) ? (
        <button
          type="button"
          onClick={async () => {
            if (setSending) {
              setSending(true);
            }
            stopActions();
            setTimeout(async () => {
              if (extraActions && currentMediaBlob.current) {
                await extraActions("", currentMediaBlob.current);
              }
              setIsStopped(false);
              clearBlobUrl();
              currentMediaBlobUrl.current = "";
              currentMediaBlob.current = null;
            }, 1000);
          }}
          className="las la-paper-plane text-gray-text text-[24px] w-[40px] h-[40px] border-0"
          title="Enviar áudio"
          aria-label="Enviar áudio"
        />
      ) : null}
    </div>
  );

  return {
    AudioBar,
    status,
    handleMicClick,
    recording: isRecording || isStopped,
  };
};

AudioRecorder.defaultProps = {
  extraActions: undefined,
};

export default AudioRecorder;
