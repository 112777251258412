import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Sidebar } from "semantic-ui-react";
import { useAppDispatch, useAppSelector } from "../hooks/redux/hooks";
import LoadingDots from "../shared/components/LoadingDots.component";
import DividerInfoPages from "../shared/components/DividerInfoPages.component";
import HeaderPageInfo from "../shared/components/HeaderPageInfo.component";
import FooterPageInfo from "../shared/components/FooterPageInfo.component";
import Section from "../shared/components/Section.component";
import SectionElement from "../shared/components/SectionElement.component";
import {
  ExternalAppType,
  selectExternalapp,
  updateExternalapps,
  deleteExternalapps,
  reselectExternalappVtex,
  validateVtexAPI,
} from "../slices/externalapps.slice";
import SidebarComponent from "../shared/components/Sidebars/Sidebar.component";
// import CreateModal from "../shared/components/Modals/CreateModal.component";
import { getSettings } from "../slices/settings.slice";

const VtexPage = ({
  disable,
  setDisable,
  toggleSidebar,
  icon,
  title,
}: {
  toggleSidebar: boolean;
  disable: boolean;
  setDisable: React.Dispatch<React.SetStateAction<boolean>>;
  icon: string;
  title: string;
}) => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoadingExternalapps, selectedExternalAppVtex } = useAppSelector(
    (state) => state.externalapps
  );

  const [, setReloadForced] = useState<boolean>(true);
  // const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showReactiveModal, setShowReactiveModal] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    const getId = () => {
      if (params && typeof params._id !== "undefined") return params._id;
      return location.pathname.split("/").slice(-1)[0];
    };

    (async () => {
      await dispatch(
        selectExternalapp({
          _id: getId(),
        })
      );
      setReloadForced(false);
      await dispatch(getSettings({}));
    })();
  }, []);

  useEffect(() => {
    const handleClickScroll = () => {
      const element = document.getElementById("vtex-available-apis");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    };
    switch (location.hash) {
      case "#vtex-available-apis":
        handleClickScroll();
        break;
      default:
        break;
    }
  }, [location]);

  const goBack = () => {
    navigate("/settings/integration/vtex");
  };

  const goRemove = async () => {
    setDisable(true);
    setShowDeleteModal(false);
    if (selectedExternalAppVtex?._id) {
      const { payload } = await dispatch(
        deleteExternalapps([selectedExternalAppVtex._id])
      );
      setDisable(false);
      if (
        payload !== null &&
        typeof payload !== "undefined" &&
        typeof payload?.message !== "string"
      ) {
        navigate("/settings/integration/vtex");
      }
    }
  };

  const goReactive = async () => {
    setDisable(true);
    setShowReactiveModal(false);
    const _externalapp = {
      ...selectedExternalAppVtex,
      fields: {
        ...selectedExternalAppVtex.fields,
      },
      type: ExternalAppType.VTEX,
      deleted: false,
      active: true,
      deleted_by: null,
      deleted_at: "",
    };
    const { payload } = await dispatch(updateExternalapps({ _externalapp }));
    setDisable(false);
    if (typeof payload._id !== "undefined") {
      navigate(-1);
    }
  };

  const goSaveVtex = async () => {
    setDisable(true);
    const _externalapp = {
      ...selectedExternalAppVtex,
      _id: selectedExternalAppVtex?._id,
      type: ExternalAppType.VTEX,
      active: selectedExternalAppVtex?.active,
      fields: {
        ...selectedExternalAppVtex.fields,
        name: selectedExternalAppVtex?.fields?.name,
        appkey: selectedExternalAppVtex?.fields?.appkey,
        apptoken: selectedExternalAppVtex?.fields?.apptoken,
        accountid: selectedExternalAppVtex?.fields?.accountid,
      },
    };
    const { payload } = await dispatch(
      updateExternalapps({ _externalapp, _validate: true })
    );
    if (typeof payload?.error === "undefined") {
      dispatch(
        reselectExternalappVtex({
          ...selectedExternalAppVtex,
          updated_at: payload.updated_at,
          updated_by: payload.updated_by,
        })
      );

      const _currentExternalapp = selectedExternalAppVtex?._id || "";
      const action = await dispatch(
        validateVtexAPI({
          _params: {
            skip: 0,
            limit: 5,
            filter: "",
            externalappid: _currentExternalapp,
          },
          _validate: true,
        })
      );
      if (typeof action.payload.error === "undefined") {
        const _action = await dispatch(
          updateExternalapps({
            _externalapp: {
              ..._externalapp,
              fields: {
                ..._externalapp.fields,
                validate: true,
              },
            },
            _validate: true,
          })
        );
        if (
          selectedExternalAppVtex?.fields?.name &&
          selectedExternalAppVtex?.fields?.appkey &&
          selectedExternalAppVtex?.fields?.apptoken &&
          selectedExternalAppVtex?.fields?.accountid
        ) {
          dispatch(
            reselectExternalappVtex({
              ...selectedExternalAppVtex,
              fields: {
                ...selectedExternalAppVtex.fields,
                validate: true,
              },
              updated_at: _action.payload.updated_at,
              updated_by: _action.payload.updated_by,
            })
          );
        }
        navigate(`${location.pathname}#vtex-available-apis`);
      } else {
        dispatch(
          reselectExternalappVtex({
            ...selectedExternalAppVtex,
            fields: {
              ...selectedExternalAppVtex.fields,
              validate: false,
            },
          })
        );
      }
    }
    setDisable(false);
  };

  const goSave = async () => {
    setDisable(true);
    const _externalapp = {
      _id: selectedExternalAppVtex?._id,
      type: ExternalAppType.VTEX,
      active: selectedExternalAppVtex?.active,
      fields: {
        name: selectedExternalAppVtex?.fields?.name,
        appkey: selectedExternalAppVtex?.fields?.appkey,
        apptoken: selectedExternalAppVtex?.fields?.apptoken,
        accountid: selectedExternalAppVtex?.fields?.accountid,
        validate: selectedExternalAppVtex?.fields?.validate,
      },
    };
    const { payload } = await dispatch(updateExternalapps({ _externalapp }));
    dispatch(
      reselectExternalappVtex({
        ...selectedExternalAppVtex,
        updated_at: payload.updated_at,
        updated_by: payload.updated_by,
      })
    );
    setDisable(false);
  };

  if (isLoadingExternalapps)
    return (
      <LoadingDots
        className="flex justify-center items-center absolute top-0 bottom-0 right-0 left-0 h-screen"
        large
      />
    );
  return (
    <div className="container items-center my-auto mx-auto lg:px-44 mt-10 py-4 md:px-12 px-8">
      <SidebarComponent
        visible={visible}
        setVisible={setVisible}
        content={null}
      />
      <Sidebar.Pusher>
        <HeaderPageInfo
          created={selectedExternalAppVtex?.created_at}
          updated={selectedExternalAppVtex?.updated_at}
          deleted={selectedExternalAppVtex?.deleted_at}
          title={selectedExternalAppVtex?.fields?.name || "-"}
          imported={false}
          icon="las la-shopping-cart"
        />
        <Section
          icon="las la-info-circle"
          title="Informações da VTEX"
          description="Preencha os dados necessários para completar a integração"
          component={
            <SectionElement
              instance={selectedExternalAppVtex || null}
              type="vtex-info"
              save={goSaveVtex}
            />
          }
        />

        {selectedExternalAppVtex?.fields?.appkey?.length > 0 &&
        selectedExternalAppVtex?.fields?.apptoken?.length > 0 &&
        selectedExternalAppVtex?.fields?.accountid?.length > 0 &&
        selectedExternalAppVtex.fields.validate ? (
          <>
            <DividerInfoPages />
            <Section
              id="vtex-available-apis"
              icon="las la-code"
              title="Serviços disponíveis para consulta"
              description="Copie as URLs dos serviços e configure ações de automação através do bot"
              component={
                <SectionElement
                  setVisible={setVisible}
                  instance={selectedExternalAppVtex}
                  type="vtex-available-apis"
                  // setShowModal={setShowModal}
                />
              }
            />
          </>
        ) : null}
        <div className="mb-20" />
        <FooterPageInfo
          disabled={
            disable ||
            selectedExternalAppVtex.deleted ||
            typeof selectedExternalAppVtex?.fields?.name === "undefined" ||
            typeof selectedExternalAppVtex?.fields?.appkey === "undefined" ||
            typeof selectedExternalAppVtex?.fields?.apptoken === "undefined" ||
            typeof selectedExternalAppVtex?.fields?.accountid === "undefined" ||
            selectedExternalAppVtex?.fields?.name.length < 2 ||
            selectedExternalAppVtex?.fields?.appkey.length === 0 ||
            selectedExternalAppVtex?.fields?.apptoken.length === 0 ||
            selectedExternalAppVtex?.fields?.accountid.length === 0
          }
          deleted={selectedExternalAppVtex?.deleted}
          toggleSidebar={toggleSidebar}
          back={goBack}
          title={
            selectedExternalAppVtex?.deleted
              ? "Deseja reativar esta integração?"
              : "Deseja remover esta integração?"
          }
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          showReactiveModal={showReactiveModal}
          setShowReactiveModal={setShowReactiveModal}
          reactive={goReactive}
          remove={goRemove}
          save={goSave}
        />
        {/* MODALS */}
        {/* {showModal ? (
          <RelationModal
            title="Relacionar equipes"
            icon={icon}
            setShowModal={setShowModal}
          />
        ) : null} */}
      </Sidebar.Pusher>
    </div>
  );
};

export default VtexPage;
