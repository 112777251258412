import React from "react";
import { useTranslation } from "react-i18next";
import PasswordChecklist, { RuleNames } from "react-password-checklist";

const StrongPasswordChecklist = ({
  rules,
  minLength,
  value,
}: {
  rules: RuleNames[];
  minLength: number;
  value: string;
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <PasswordChecklist
        className="text-[9px] flex items-start flex-col justify-center"
        invalidColor="#DB2828"
        validColor="#2FC32F"
        iconSize={14}
        rtl={false}
        messages={{
          number: t("form.checklist-error.number") || "",
          minLength: t("form.checklist-error.minLength") || "",
          match: t("form.checklist-error.match") || "",
          specialChar: t("form.checklist-error.specialChar") || "",
          capital: t("form.checklist-error.capital") || "",
          notEmpty: t("form.checklist-error.notEmpty") || "",
        }}
        rules={rules}
        minLength={minLength}
        value={value}
      />
    </div>
  );
};
export default StrongPasswordChecklist;
