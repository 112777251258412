import React, { useEffect } from "react";
import { LoginTenantForm } from "../features/auth/components/Forms";
import AuthLayoutPage from "./AuthLayout.page";
import { useAppDispatch, useAppSelector } from "../hooks/redux/hooks";
import { UserType } from "../slices/users.slice";
import { refreshLogout } from "../slices/auth.slice";

const LoginTenantPage = ({ widgetType }: { widgetType?: UserType }) => {
  const { isLoadingAuth } = useAppSelector((state) => state.auth);
  const { isSuccess } = useAppSelector((state) => state.users);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(refreshLogout());
    localStorage.clear();
  }, []);

  const getTitlePage = () => {
    if (
      (!isLoadingAuth && widgetType !== UserType.CONTACT) ||
      (!isLoadingAuth && !isSuccess && widgetType === UserType.CONTACT)
    )
      return "Olá, seja bem-vindo!";
    return "Aguarde um instante!";
  };

  return (
    <AuthLayoutPage widgetType={widgetType}>
      <h2 className="my-4 text-center text-[24px] font-extrabold text-gray-900">
        {getTitlePage()}
      </h2>
      <LoginTenantForm widgetType={widgetType} />
    </AuthLayoutPage>
  );
};

LoginTenantPage.defaultProps = {
  widgetType: UserType.NONE,
};

export default LoginTenantPage;
