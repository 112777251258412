import { IFilterTag, ITag, TagType } from "../slices/tags.slice";
import instances from "./instances";

const tag = async ({ _id }: { _id: string }) => {
  try {
    const response = await instances.instanceTags.get(`/${_id}`);
    if (response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const update = async ({ _tag }: { _tag: ITag }) => {
  try {
    const data = _tag;
    const response = await instances.instanceTags.patch(`${_tag._id}`, data);
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const deleteTags = async ({ ids }: { ids: string[] }) => {
  try {
    const data = { id: ids };
    const response = await instances.instanceTags.delete("", { data });
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const create = async ({
  name,
  type,
  color,
}: {
  name: string;
  type: TagType;
  color?: string;
}) => {
  try {
    const data = {
      name,
      type,
      color: color || "#666666",
    };
    const response = await instances.instanceTags.post("", data);
    if (response && response.data && !response.data.message) {
      return response.data;
    }
    return response.data.message;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const search = async ({
  skip,
  limit,
  filter,
  deleted,
  allStatus,
}: IFilterTag) => {
  try {
    const _filters =
      typeof filter === "object" ? JSON.parse(JSON.stringify(filter)) : {};

    if (typeof filter === "string" && typeof _filters?.content === "undefined")
      _filters.content = filter;
    if (allStatus) _filters.active = false;
    if (deleted) _filters.deleted = true;

    const response = await instances.instanceTags.get("", {
      params: {
        skip: skip || 0,
        limit: limit || 10,
        filter: _filters,
        fields:
          "active,teams._id,teams.name,teams.active,color,type,created_at,created_by,deleted,name,tenant,updated_at,updated_by",
      },
    });
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const searchTagChat = async ({
  skip,
  limit,
  filter,
  deleted,
  allStatus,
}: IFilterTag) => {
  try {
    const _filters =
      typeof filter === "object" ? JSON.parse(JSON.stringify(filter)) : {};

    if (typeof filter === "string" && typeof _filters?.content === "undefined")
      _filters.content = filter;
    if (allStatus) _filters.active = false;
    if (deleted) _filters.deleted = true;

    const response = await instances.instanceTags.get("/chat", {
      params: {
        skip: skip || 0,
        limit: limit || 10,
        filter: _filters,
        fields:
          "active,teams._id,teams.name,teams.active,color,type,created_at,created_by,deleted,name,tenant,updated_at,updated_by",
      },
    });
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const tagsService = {
  tag,
  update,
  deleteTags,
  create,
  search,
  searchTagChat,
};

export default tagsService;
