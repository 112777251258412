/* eslint-disable import/no-duplicates */
import React from "react";
import { useNavigate } from "react-router-dom";
import { format, parseISO } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { useAppSelector } from "../../hooks/redux/hooks";
import NoAvatar from "./NoAvatar.component";
import Avatar from "./Avatar.component";

const RelatedInstance = ({
  type,
  instance,
  avatar,
}: {
  type: string;
  instance: any;
  avatar: boolean;
}) => {
  // const URL = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.auth);

  const getBorder = () => {
    if (instance?.deleted) return "border-agitalks-alpha30";
    if (instance?.active) return "border-green";
    return "border-red";
  };
  const getWidth = () => {
    if (type === "campaign") return "w-[195px]";
    return "w-[250px]";
  };

  const getBatches = () => {
    let batchesListFilter = "-";
    if (
      typeof instance?.campaignbatch !== "undefined" &&
      instance?.campaignbatch?.length > 0 &&
      typeof instance?.campaignbatch === "string"
    ) {
      batchesListFilter = format(
        parseISO(instance?.campaignbatch),
        "dd/MM/yyyy', às' HH:mm",
        {
          locale: ptBR,
        }
      );
    }
    return { batchesListFilter };
  };

  return (
    <div
      title={instance?.name}
      role="presentation"
      onClick={() => {
        if (instance?._id !== user?._id) {
          if (type === "campaign") {
            navigate(`/settings/${type}/${instance?._id}/batches`);
          } else {
            navigate(`/settings/${type}/${instance?._id}`);
          }
        } else {
          navigate("/settings/profile");
        }
      }}
      key={instance._id}
      className={`cursor-pointer mt-4 mr-4 flex items-center px-7 py-3 shadow rounded-md ${getWidth()} border-l-[6px] ${getBorder()}`}
    >
      <div className="flex items-center w-full">
        <div className="w-[32px]">
          <div className="w-[32px] h-[32px]">
            {avatar &&
            instance.avatar &&
            instance.avatar?.path &&
            instance.avatar?.name ? (
              <Avatar
                path={instance?.avatar?.path}
                name={instance?.avatar?.name}
                title={instance?.name}
              />
            ) : (
              <NoAvatar
                displaytext={
                  type === "campaign"
                    ? instance.campaign
                    : instance.name || "--"
                }
                elementClass="p-0"
                labelClass="text-[12px]"
              />
            )}
          </div>
        </div>
        <div className="flex flex-col w-[calc(100%-40px)] ml-[14px] text-left">
          <p
            title={type === "campaign" ? instance.campaign : instance.name}
            className="word-wrap-1 text-[14px] font-semibold"
          >{`${type === "campaign" ? instance.campaign : instance.name}`}</p>
          {avatar ? (
            <p
              title={instance.email || "-"}
              className="word-wrap-1 text-[12px] text-agitalks"
            >
              {instance.email || "-"}
            </p>
          ) : null}
          {type === "campaign" ? (
            <p
              title="Data do último envio da campanha recebido pelo contato"
              className="word-wrap-1 text-[11px] font-semibold text-agitalks"
            >
              {getBatches().batchesListFilter}
            </p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default RelatedInstance;
