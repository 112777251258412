import React, { useEffect } from "react";
import { Form } from "semantic-ui-react";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/hooks";
import { ActiveDropdown } from "../../../shared/components/Dropdowns";
import useTags from "../../../hooks/features/useTags";
import TagTypeDropdown from "../../../shared/components/Dropdowns/TagTypes.dropdown.component";
import { reselectLocalTag } from "../../../slices/tags.slice";

const Infos = () => {
  const { infos, tagCallback } = useTags({});
  const { selectedLocalTag } = useAppSelector((state) => state.tags);
  const dispatch = useAppDispatch();

  useEffect(() => {
    tagCallback();
  }, [tagCallback]);

  return (
    <Form>
      <Form.Group className="mt-4" widths="equal">
        <Form.Input
          autoComplete="off"
          readOnly={infos.readonly}
          className="w-full md:w-full"
          label="Nome"
          fluid
          placeholder="Nome"
          defaultValue={selectedLocalTag?.name}
          type="text"
          required
          onChange={(e, { value }) => {
            infos.onChange.name(e, { value });
          }}
        />
        <ActiveDropdown
          disabled={infos.active.disabled}
          className="w-full mt-6"
          fluid
          defaultValue={selectedLocalTag?.active}
          onChange={(e, { value }) => {
            infos.onChange.active(e, { value });
          }}
        />
      </Form.Group>
      <Form.Group widths="equal" className="w-[calc(50%+100px)] pl-2 gap-4">
        <Form.Group className="w-[calc(100%-85px)] mt-4" widths="equal">
          <TagTypeDropdown
            id="tag-type"
            label="Tipo"
            disabled
            fluid
            defaultValue={selectedLocalTag?.type}
            onChange={(e, { value }) => {
              infos.onChange.type(e, { value });
            }}
          />
        </Form.Group>
        <Form.Group widths="equal" className="w-[100px] px-2">
          <div>
            <label>Cor</label>
            <input
              className="w-[150px]"
              disabled={infos.active.disabled}
              placeholder="Selecione uma cor"
              value={selectedLocalTag?.color || "#666666"}
              type="color"
              onChange={(e) => {
                dispatch(
                  reselectLocalTag({
                    ...selectedLocalTag,
                    color: e.target.value,
                  })
                );
              }}
            />
          </div>
        </Form.Group>
      </Form.Group>
    </Form>
  );
};

export default Infos;
