import { IFilterCampaignBatches } from "../slices/campaigns.slice";
import instances from "./instances";

const searchBatchTargetInfo = async ({
  id,
  skip,
  limit,
  filter,
  deleted,
}: IFilterCampaignBatches) => {
  try {
    const _filters =
      typeof filter === "object" ? JSON.parse(JSON.stringify(filter)) : {};

    if (typeof filter === "string" && typeof _filters?.content === "undefined")
      _filters.content = filter;
    if (deleted) _filters.deleted = true;
    const response = await instances.instanceCampaignBatches.get(
      `/${id}/targetsinfo`,
      {
        params: {
          skip: skip || 0,
          limit: limit || 10,
          filter: _filters,
        },
      }
    );
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const update = async ({ _batch }: { _batch: any }) => {
  try {
    const data = _batch;
    const response = await instances.instanceCampaignBatches.patch(
      `${_batch._id}`,
      data
    );
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const deleteTargets = async ({
  campaignbatch,
  ids,
}: {
  campaignbatch: string;
  ids: string[];
}) => {
  try {
    const data = { campaignbatch, target: ids };
    const response = await instances.instanceCampaignBatches.delete("/target", {
      data,
    });
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const sendBatch = async (id: string) => {
  try {
    const data = { id };
    const response = await instances.instanceCampaignBatches.post(
      "/send",
      data
    );
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const startCampaign = async ({
  campaign,
  scheduledate,
  name,
}: {
  campaign: string;
  scheduledate: string;
  name: string;
}) => {
  try {
    const data = { campaign, scheduledate, name };
    const response = await instances.instanceCampaignBatches.post("", data);
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const campaignBatchesService = {
  searchBatchTargetInfo,
  update,
  deleteTargets,
  sendBatch,
  startCampaign,
};

export default campaignBatchesService;
