import React, { FormEvent, useEffect } from "react";
import { Divider, Form } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useInput from "../../../../hooks/useInput";
import { signin } from "../../../../slices/auth.slice";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux/hooks";
import LoadingDots from "../../../../shared/components/LoadingDots.component";
import TermsAndPolices from "../../../../shared/components/TermsAndPolices.component";
import { validateNameLength } from "../../../../shared/utils/validation/length";
import FormButton from "../../../../shared/components/Buttons/FormButton.component";
import ErrorLabel from "./ErrorLabel.component";
import AuthButton from "../../../../shared/components/Buttons/AuthButton.component";
import CommonError from "./CommonError.component";
import { UserType } from "../../../../slices/users.slice";

const LoginTenantForm = ({ widgetType }: { widgetType?: UserType }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    isLoadingAuth,
    loginMethods,
    isError,
    selectedLoginMethod,
    tenantID,
  } = useAppSelector((state) => state.auth);

  const {
    text: tenant,
    shouldDisplayError: tenantHasError,
    textChangeHandler: tenantChangeHandler,
    inputBlurHandler: tenantBlurHandler,
  } = useInput(validateNameLength);

  useEffect(() => {
    if (selectedLoginMethod) {
      navigate("/login");
    }
  }, [selectedLoginMethod]);

  const onSubmitHandler = async (e: FormEvent<HTMLFormElement>) => {
    const _tenant = tenant.toLowerCase().trim();
    e.preventDefault();
    if (
      loginMethods &&
      loginMethods.length > 0 &&
      tenantID &&
      _tenant.toLowerCase() === tenantID.toLowerCase()
    ) {
      navigate("/login");
    } else {
      if (tenantHasError) return;
      if (_tenant.length === 0) return;
      const { payload } = await dispatch(signin({ tenant: _tenant }));
      if (payload && typeof payload !== "undefined" && !payload.error) {
        if (payload.length === 0) {
          navigate(`/login`);
        }
      }
    }
  };

  if (isLoadingAuth)
    return <LoadingDots className="flex justify-center items-center" />;
  return (
    <Form
      className="px-16 bg-white h-full w-full rounded-xl flex flex-col"
      onSubmit={onSubmitHandler}
    >
      <Form.Input
        className="pt-2"
        // icon="building"
        // iconPosition="left"
        label={t("form.label.tenant")}
        value={tenant}
        required
        onChange={tenantChangeHandler}
        onBlur={tenantBlurHandler}
        error={tenantHasError}
        type="text"
        name="tenant"
        id="tenant"
        variant="outlined"
        placeholder={t("form.placeholder.tenant")}
        autoComplete="off"
      />
      <ErrorLabel error={tenantHasError} label={t("form.error.tenant")} />
      {isError ? <CommonError /> : null}

      <FormButton
        className={`flex flex-wrap justify-center ${isError ? "my-8" : "my-4"}`}
        disabled={tenant.length === 0 || tenantHasError || false}
        label={t("formbutton.label.next")}
      />

      {loginMethods && loginMethods.length > 0 ? (
        <>
          <Divider horizontal>
            <p className="text-xs">{t("form.login-with")}</p>
          </Divider>
          {loginMethods.map((method) => (
            <AuthButton key={method._id} method={method} />
          ))}
        </>
      ) : null}

      <TermsAndPolices widgetType={widgetType} />
    </Form>
  );
};

LoginTenantForm.defaultProps = {
  widgetType: UserType.NONE,
};

export default LoginTenantForm;
