/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable import/no-duplicates */
/* eslint-disable no-nested-ternary */
import React, { CSSProperties, useState } from "react";
import { formatRelative, parseISO } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { Link } from "react-router-dom";
import DropdownMenu from "./DropdownMenu.component";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/hooks";
import { Files, IFile } from "../../../shared/models/interfaces/file.interface";
import { iconType } from "./Sendbox/Thumbs.component";
import AudioPlayer from "./AudioPlayer.component";
import ParentMessage from "./ParentMessage.component";
import useImageOnLoad from "../../../hooks/useImageOnLoad";
import useFormatText from "../../../hooks/useFormatText";
import { MessageProps } from "../models/MessageProps.type";
import File from "../../../shared/components/Files/File.component";
import LinkButton from "../../../shared/components/Buttons/LinkButton.component";
import NoAvatar from "../../../shared/components/NoAvatar.component";
import Avatar from "../../../shared/components/Avatar.component";
import { MessageStatus } from "../../../slices/messages.slice";
import {
  ChatStatus,
  ChatType,
  checkingMessages,
} from "../../../slices/chats.slice";
import useSanitizeText from "../../../hooks/useSanitizeText";
import { DropdownMenuItemsProps } from "../../../shared/models/types/DropdownMenuProps.type";
import PopupComponent from "../../../shared/components/Popup.component";
// import useMakeUrlsClickable from "../../../hooks/useMakeUrlsClickable";

const Message = ({
  sender,
  message,
  dir,
  internal,
  setIsReplying,
  setMessageReplied,
  setGoReply,
  setParent,
}: MessageProps) => {
  const [showFiles, setShowFiles] = useState<boolean>(false);
  const URL = process.env.REACT_APP_API_BASE_URL;
  const { user } = useAppSelector((state) => state.auth);
  const { selectedChat, checkingChatMessages } = useAppSelector(
    (state) => state.chats
  );
  const { messages } = useAppSelector((state) => state.messages);
  const { externalAppAgidesk } = useAppSelector((state) => state.externalapps);
  const parent =
    messages.find((msg) => msg._id === message?.parent?._id) || message?.parent;
  const userAvatar = user?.avatar;

  const { handleImageOnLoad, css } = useImageOnLoad();
  const dispatch = useAppDispatch();

  const style: { [key: string]: CSSProperties } = {
    wrapImage: {
      position: "relative",
      width: "99%",
      minHeight: "125px",
      borderRadius: "8px",
      display: "flex",
      objectFit: "contain",
    },
    wrap: {
      position: "relative",
      width: "99%",
      borderRadius: "8px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      objectFit: "contain",
    },
    image: {
      position: "absolute",
      width: "99%",
      height: "99%",
      borderRadius: "8px",
      objectFit: "contain",
    },
    video: {
      width: "99%",
      height: "99%",
      borderRadius: "8px",
      objectFit: "contain",
    },
  };

  const typeTemplate = (file: IFile) => {
    const fileType = file.mimetype?.split("/")[0];
    let fileName = file.name || "";
    const arrFilename = fileName.split(".");
    const fileExt = arrFilename[arrFilename.length - 1];
    if (fileName.length > 14) {
      fileName = `${fileName.substring(0, 11)}...${fileExt}`;
    }
    if (typeof fileType !== "undefined") {
      switch (fileType) {
        case "image":
          return (
            <img
              onLoad={handleImageOnLoad}
              style={{ ...style.image, ...(css.fullSize as CSSProperties) }}
              src={`${URL}/api/files/${file?.path}/${file?.name}`}
              title={file.name}
              alt={file.name}
            />
          );
        case "video":
          return (
            <div
              className="w-full p-[10px] rounded-[8px]"
              key={file._id}
              title={file.name}
            >
              {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
              <video
                controls
                itemType={file.mimetype}
                src={`${URL}/api/files/${file.path}/${file.name}`}
                width="100%"
                height="100%"
              />
            </div>
          );
        case "audio":
          if (file.size) return <AudioPlayer file={file} />;
          return null;
        case "application":
          return (
            <div
              key={file._id}
              title={file.name}
              className="flex relative items-center justify-center flex-col w-full rounded-[8px]"
            >
              {iconType(file.mimetype || "", true)}
              <b className="text-[10px] p-[5px] text-ellipsis overflow-hidden text-black">
                {fileName}
              </b>
            </div>
          );
        default:
          return (
            <div
              key={file._id}
              title={file.name}
              className="flex relative items-center justify-center flex-col w-full rounded-[8px]"
            >
              {iconType(file.mimetype || "", true)}
              <b className="text-[10px] p-[5px] text-ellipsis overflow-hidden text-black">
                {fileName}
              </b>
            </div>
          );
      }
    }
    return null;
  };

  const messageTemplate = (files: Files, length: number) => {
    return (
      <div
        className={`relative max-w-full max-h-[600px] overflow-hidden ${
          length > 1 ? `grid c${length}` : ""
        }`}
      >
        {files.map((file) => (
          <div
            style={
              file.mimetype?.includes("image/")
                ? length > 1
                  ? style.wrapImage
                  : { ...style.wrapImage, height: "240px" }
                : file.mimetype?.includes("video/")
                ? length > 1
                  ? style.wrap
                  : { ...style.wrap, minHeight: "240px", maxHeight: "490px" }
                : {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "relative",
                    flexDirection: "column",
                    objectFit: "contain",
                  }
            }
            key={file._id}
          >
            <a
              href={`${URL}/api/files/${file.path}/${file.name}`}
              target="_blank"
              title={file.name}
              rel="noopener noreferrer"
              className="flex relative flex-col items-center justify-center w-[99%] max-h-[99%] rounded-[8px]"
            >
              {file.mimetype?.includes("image") ? (
                <img
                  style={{
                    ...style.image,
                    ...(css.thumbnail as CSSProperties),
                  }}
                  src={`${URL}/api/files/${file.path}/${file.name}`}
                  title={file.name}
                  alt={file.name}
                />
              ) : null}
              {typeTemplate(file)}
            </a>
          </div>
        ))}
      </div>
    );
  };

  const moreOptionsMenu = () => {
    const _items: DropdownMenuItemsProps[] = [
      {
        _id: 0,
        icon: <i className="las la-comment text-[16px] mr-[10px]" />,
        text: "Responder",
        description: null,
        onClick() {
          if (message) {
            setIsReplying(true);
            setMessageReplied(message);
          }
          window.setTimeout(() => {
            document.getElementById("message-textarea")?.focus();
          }, 0);
        },
      },
      // {
      //   _id: 2,
      //   icon: (
      //     <i className="las la-share s16" style={{ marginRight: 10 }} />
      //   ),
      //   text: "Encaminhar",
      //   description: null,
      // },
    ];
    const _checkMessages = {
      _id: 1,
      icon: <i className="las la-check s16" style={{ marginRight: 10 }} />,
      text: "Selecionar",
      description: null,
      onClick() {
        dispatch(checkingMessages({ ...checkingChatMessages, checking: true }));
      },
    };
    const _validatedExternalApps = externalAppAgidesk.filter(
      (_extApp) =>
        _extApp?.active === true &&
        _extApp?.fields?.enablechat === true &&
        _extApp?.fields?.validate === true &&
        !selectedChat?.groupchat
    );
    if (_validatedExternalApps.length > 0) {
      _items.push(_checkMessages);
    }

    return (
      <div className="rounded-full ml-[10px]">
        <DropdownMenu
          id="header-batch-container"
          icon={
            <button
              className="las la-ellipsis-h text-[24px] border-0 text-gray-text bg-none"
              title="Opções da mensagem"
              aria-label="Opções da mensagem"
              type="button"
            />
          }
          items={_items}
        />
      </div>
    );
  };

  const date = parseISO(message?.created_at || "");
  const formatRelativeLocale = (token: string) => {
    switch (token) {
      // case "lastWeek":
      //   return "eeee HH:mm";
      // case "yesterday":
      //   return "'Ontem' HH:mm";
      // case "today":
      //   return "HH:mm";
      default:
        return "dd/MM 'às' HH:mm";
      // default:
      //   return "HH:mm";
    }
  };

  const locale = {
    ...ptBR,
    formatRelative: (token: string) => formatRelativeLocale(token),
  };

  const internalBallon = () => {
    const getContent = () => {
      if (selectedChat?.groupchat && typeof message?.content !== "undefined") {
        let _msg = message?.content;
        // eslint-disable-next-line no-useless-escape
        const regex = /[^\[]+(?=\])/g;
        const matched = _msg.match(regex);

        if (typeof matched !== "undefined" && matched && matched.length > 0) {
          matched.forEach((i) => {
            const __user = selectedChat?.groupsettings?.find((u) =>
              typeof u.user?._id !== "undefined"
                ? u.user?._id === i
                : (u.user as any) === i
            );
            if (
              typeof __user?.user?.name !== "undefined" &&
              typeof __user?.user?._id !== "undefined"
            ) {
              const _value =
                __user?.user?._id === user?._id ? "você" : __user?.user?.name;
              _msg = _msg.replace(`[${i}]`, _value);
            }
          });
          const _startsCapital = _msg[3].toUpperCase();
          return _msg.replace(_msg[3], _startsCapital);
        }
        return message.content || "";
      }
      return message?.content || "";
    };
    return message?.content ? (
      <div className="bg-white py-[4px] px-[6px] rounded-[8px] my-[10px] shadow-md min-w-[300px] max-w-[70%] lg:max-w-[60%]">
        <div className="flex justify-center">
          <span
            className="px-[10px] py-[5px] inline-block whitespace-pre-line text-[14px]"
            dangerouslySetInnerHTML={{
              __html: useFormatText(
                message.content.length > 0 ? getContent() : ""
              ),
            }}
          />
        </div>
        {message?.files && message.files.length > 0 ? (
          <>
            <div className="flex justify-end px-[10px] mt-4">
              <LinkButton
                label={!showFiles ? "Exibir anexos" : "Esconder anexos"}
                onClick={() => setShowFiles((prevState) => !prevState)}
                extraClass="px- mb-2 text-[10px] font-semibold"
              />
            </div>
            {showFiles ? (
              <div className="px-[10px] mt-4 max-h-[200px] overflow-y-auto">
                <label className="text-[12px] -mb-[2px]">Anexos</label>
                {message.files.map((file, index) => (
                  <a
                    key={index.toString() + file.name}
                    href={`${URL}/api/files/${file.path}/${file.name}`}
                    target="_blank"
                    title={file.name}
                    rel="noopener noreferrer"
                  >
                    <File
                      message
                      file={file}
                      href={`${URL}/api/files/${file.path}/${file.name}`}
                    />
                  </a>
                ))}
              </div>
            ) : null}
          </>
        ) : null}
        <h2 className="text-[11px] text-gray-777 text-end">
          {message?.created_at
            ? formatRelative(date, new Date(), {
                locale,
              })
            : ""}
        </h2>
      </div>
    ) : null;
  };

  const getAvatarImage = () => {
    if (dir !== 1) {
      if (sender?.avatar && sender?.avatar?.path && sender?.avatar?.name) {
        return (
          <Avatar
            path={sender?.avatar?.path}
            name={sender?.avatar?.name}
            extraClass="mr-[10px]"
            title={sender?.name}
          />
          // <div className="mr-[10px] w-full h-full rounded-full flex items-center justify-center bg-gray-333 border-gray-333">
          //   <img
          //     className="avatar"
          //     src={`${URL}/api/files/${sender?.avatar.path}/${sender?.avatar.name}`}
          //     alt="Foto do perfil"
          //   />
          // </div>
        );
      }
      return (
        <NoAvatar
          displaytext={sender?.name || "--"}
          elementClass="p-1 mr-2"
          labelClass="text-[14px]"
        />
      );
    }
    if (userAvatar && userAvatar?.path && userAvatar?.name) {
      return (
        <Avatar
          path={userAvatar?.path}
          name={userAvatar?.name}
          extraClass="mr-[10px]"
          title="Foto do perfil"
        />
        // <div className="mr-[10px] w-full h-full rounded-full flex items-center justify-center bg-gray-333 border-gray-333">
        //   <img
        //     className="avatar"
        //     src={`${URL}/api/files/${userAvatar.path}/${userAvatar.name}`}
        //     alt="Foto do perfil"
        //   />
        // </div>
      );
    }
    return (
      <NoAvatar
        displaytext={user?.name || "--"}
        elementClass="p-1 mr-2"
        labelClass="text-[14px]"
      />
    );
  };

  const wppStatusIcon = {
    [MessageStatus.SENT]: "las la-check text-gray-777 !text-[14px]",
    [MessageStatus.DELIVERED]: "las la-check-double text-gray-777 !text-[14px]",
    [MessageStatus.READ]: "las la-check-double text-blue-read",
    [MessageStatus.WAIT]: "las la-clock text-gray-777 !text-[14px]",
    // [MessageStatus.FAILED]: "las la-ban text-red",
    [MessageStatus.FAILED]:
      "las la-exclamation-circle text-orange-400 !text-[14px]",
  };
  const wppStatusLink = {
    [MessageStatus.SENT]: "",
    [MessageStatus.DELIVERED]: "",
    [MessageStatus.READ]: "",
    [MessageStatus.WAIT]: "",
    [MessageStatus.FAILED]:
      // "https://developers.facebook.com/docs/whatsapp/cloud-api/support/error-codes/",
      "https://atendimento.agidesk.com/br/central-de-ajuda/1661",
  };

  const wppStatusTitle = {
    [MessageStatus.WAIT]: "Aguardando envio",
    [MessageStatus.SENT]: "Enviado",
    [MessageStatus.DELIVERED]: "Entregue",
    [MessageStatus.READ]: "Lido",
    // [MessageStatus.FAILED]: "Não pode ser enviado",
    [MessageStatus.FAILED]:
      typeof JSON.parse(message?.statusmessage || "{}")?.code !== "undefined"
        ? `Verifique o código de erro ${
            JSON.parse(message?.statusmessage || "{}")?.code
          }`
        : "Não pode ser entregue",
  };

  const getPopupComponent = () => {
    return (
      <PopupComponent
        className="flex"
        content={
          <span>
            {
              wppStatusTitle[
                typeof message?.status !== "undefined"
                  ? message.status
                  : MessageStatus.WAIT
              ]
            }
          </span>
        }
        label=""
        icon={
          wppStatusIcon[
            typeof message?.status !== "undefined"
              ? message.status
              : MessageStatus.WAIT
          ]
        }
      />
    );
  };

  const getWppStatusIcon = () => {
    if (
      typeof message?.status !== "undefined" &&
      message?.status === MessageStatus.FAILED &&
      typeof message?.statusmessage !== "undefined" &&
      message?.statusmessage.length > 0
    ) {
      return (
        <Link
          className="flex items-center justify-end"
          to={
            wppStatusLink[
              typeof message?.status !== "undefined"
                ? message.status
                : MessageStatus.WAIT
            ]
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          {typeof message?.status !== "undefined" &&
          message?.status === MessageStatus.FAILED ? (
            <span className="!text-[10px] !text-gray-333 hover:!text-gray-777">
              Mensagem não entregue.
            </span>
          ) : null}
          {getPopupComponent()}
        </Link>
      );
    }
    return (
      <div
        className="flex items-center justify-end"
        title={
          wppStatusTitle[
            typeof message?.status !== "undefined"
              ? message.status
              : MessageStatus.WAIT
          ]
        }
      >
        <i
          title={
            wppStatusTitle[
              typeof message?.status !== "undefined"
                ? message.status
                : MessageStatus.WAIT
            ]
          }
          className={`px-1 py-1 text-[13px] ${
            wppStatusIcon[
              typeof message?.status !== "undefined"
                ? message.status
                : MessageStatus.WAIT
            ]
          }`}
        />
      </div>
    );
  };

  const ballon = () => {
    return (
      <div
        onContextMenu={(e) => {
          e.stopPropagation();
        }}
        className="ballon relative"
      >
        <div className="flex">
          <div className="p-[5px] pb-[10px] flex-1 flex relative justify-between">
            <div className="flex items-center">
              <div className="h-[40px] w-[40px] mr-2">{getAvatarImage()}</div>
              <div className="relative flex flex-col max-w-[calc(100%-45px)]">
                <h2
                  className="font-semibold text-[14px] word-wrap-2"
                  style={{
                    color: dir !== 1 ? sender?.color : "",
                  }}
                >
                  {dir !== 1 ? sender?.name : user?.name}
                </h2>
                <h2 className="text-[11px] text-gray-777">
                  {message?.created_at
                    ? formatRelative(date, new Date(), {
                        locale,
                      })
                    : ""}
                </h2>
              </div>
            </div>
            {typeof selectedChat?.status !== "undefined" &&
            [ChatStatus.ACTIVE, ChatStatus.FINISH].includes(
              selectedChat?.status
            )
              ? moreOptionsMenu()
              : null}
          </div>
        </div>
        {parent ? (
          <ParentMessage
            color={sender?.color}
            message={parent}
            onClick={(e: React.MouseEvent<HTMLDivElement>) => {
              e.stopPropagation();
              setParent(parent);
              setGoReply(true);
            }}
          />
        ) : null}
        {message?.order === 1 &&
        message?.chat?.type === ChatType.WHATSAPP &&
        typeof message?.files !== "undefined" &&
        message.files.length > 0 ? (
          <div className="flex justify-center">
            <div className="min-w-[300px] max-w-[300px] rounded-[8px] py-[2px] px-0 my-[3px] mx-0 relative">
              {messageTemplate(message.files, message.files.length)}
            </div>
          </div>
        ) : null}
        {typeof message?.content !== "undefined" &&
        message.content.length > 0 ? (
          <span
            className="px-[10px] pb-[5px] pt-0 inline-block whitespace-pre-line text-[14px] break-break"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html:
                typeof message.commenttemplate !== "undefined" &&
                message.commenttemplate.length > 0
                  ? message.content
                  : useSanitizeText({
                      text: message.content,
                      preserveHTMLExceptions: ["app-externalapp-htmlmessage"],
                    }),
            }}
          />
        ) : null}
        {((message?.order === 1 && message?.chat?.type !== ChatType.WHATSAPP) ||
          (message?.order !== 1 && message?.chat?.type === ChatType.WHATSAPP) ||
          message?.chat?.type !== ChatType.WHATSAPP) &&
        typeof message?.files !== "undefined" &&
        message.files.length > 0 ? (
          <div className="min-w-[300px] max-w-[300px] rounded-[8px] py-[2px] px-0 my-[3px] mx-0 relative">
            {messageTemplate(message.files, message.files.length)}
          </div>
        ) : null}
        {dir === 1 && message?.chat?.type === ChatType.WHATSAPP
          ? getWppStatusIcon()
          : null}
      </div>
    );
  };

  return internal ? (
    <div id="internal-message" className="flex items-center justify-center">
      {internalBallon()}
    </div>
  ) : (
    <div
      id="bubble"
      className={`flex relative ${dir === 1 ? "mine" : "not-mine"}`}
    >
      {ballon()}
    </div>
  );
};

export default Message;
