import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../hooks/redux/hooks";
import { clearRecents } from "../../../../slices/chats.slice";
import CloseButton from "../../../../shared/components/Buttons/CloseButton.component";

const ListHeader = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    window.parent.postMessage("toggleRecent", "*");
    dispatch(clearRecents());
  };

  return (
    <div className="bg-white px-2 py-[12px] mb-[2px] rounded-tl-[8px] w-[200px] shadow-md">
      <div className="flex items-center justify-between">
        <div className="relative flex items-center">
          <button
            type="button"
            className="las la-history text-[24px] hover:text-black hover:cursor-auto"
            aria-label={t("recents.header-list") || ""}
          />
          <h2 className="text-[16px]">{t("recents.header-list")}</h2>
        </div>
        <CloseButton
          extraClass="text-[18px] mr-1"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleClose(e)}
        />
      </div>
    </div>
  );
};

export default memo(ListHeader);
