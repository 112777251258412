import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AsyncState } from "../shared/models/interfaces/asyncstate.interface";
import surveysService from "../services/surveys.service";
import { IFilterProps } from "./chats.slice";
import { IRatingData, ISurveyRating } from "./surveys.slice";

export interface IFilterSurveyRating {
  skip?: number;
  limit?: number;
  filter?: IFilterProps;
  deleted?: boolean;
  allStatus?: boolean;
}

export type SurveyRatings = ISurveyRating[];

interface SurveyRatingState extends AsyncState {
  isLoadingSurveyRatings: boolean;
  surveyRatings: ISurveyRating[];
  totalSurveyRatings: number;
}

const initialState: SurveyRatingState = {
  surveyRatings: [],
  isLoadingSurveyRatings: false,
  totalSurveyRatings: 0,
  isSuccess: false,
  isError: false,
};

export const getSearchSurveyRatings = createAsyncThunk(
  "survey/getSearchSurveyRatings",
  async (filterSurveyRating: IFilterSurveyRating, thunkAPI) => {
    try {
      return await surveysService.searchRatings(filterSurveyRating);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const selectSurveyRating = createAsyncThunk(
  "survey/selectSurveyRating",
  async ({ code }: { code: string }, thunkAPI) => {
    try {
      return await surveysService.surveyrating({ code });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateSurveyRating = createAsyncThunk(
  "surveyRating/update",
  async (_surveyrating: IRatingData, thunkAPI) => {
    try {
      return await surveysService.updateSurveyRating({ _surveyrating });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const surveyRatingsSlice = createSlice({
  name: "surveyratings",
  initialState,
  reducers: {
    logout() {
      return initialState;
    },
    getAllSurveyRatings(state, action: PayloadAction<ISurveyRating[]>) {
      state.surveyRatings = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSearchSurveyRatings.pending, (state) => {
        state.isLoadingSurveyRatings = true;
        // if (action.meta.arg.limit === 10) {
        // }
      })
      .addCase(getSearchSurveyRatings.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.surveyRatings = action.payload.results || [];
        state.totalSurveyRatings = action.payload.count;
        state.isLoadingSurveyRatings = false;
      })
      .addCase(getSearchSurveyRatings.rejected, (state) => {
        state.isError = true;
        state.surveyRatings = [];
        state.isLoadingSurveyRatings = false;
      });
  },
});

export default surveyRatingsSlice.reducer;
export const { logout, getAllSurveyRatings } = surveyRatingsSlice.actions;
