import React from "react";
import {
  IAcceptedFile,
  IAcceptedFiles,
} from "../../../../shared/models/interfaces/acceptedfile.interface";
import CloseButton from "../../../../shared/components/Buttons/CloseButton.component";

export const iconType = (type: string, size: boolean) => {
  let icon = "";
  let color = "";
  const fileType = type.split("/")[0];
  const fileTypeExtension = type.split("/")[1];
  switch (fileType) {
    case "application":
      if (fileTypeExtension === "pdf") {
        icon = "la-file-pdf";
        color = "text-red-thumbs";
        break;
      }
      if (
        [
          "vnd.oasis.opendocument.spreadsheet",
          "vnd.ms-excel",
          "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ].includes(fileTypeExtension)
      ) {
        icon = "la-file-excel";
        color = "text-green-thumbs";
        break;
      }
      if (
        [
          "msword",
          "vnd.openxmlformats-officedocument.wordprocessingml.document",
          "vnd.oasis.opendocument.text",
        ].includes(fileTypeExtension)
      ) {
        icon = "la-file-word";
        color = "text-blue-thumbs";
        break;
      }
      if (
        [
          "vnd.oasis.opendocument.presentation",
          "vnd.ms-powerpoint",
          "vnd.openxmlformats-officedocument.presentationml.presentation",
        ].includes(fileTypeExtension)
      ) {
        icon = "la-file-powerpoint";
        color = "text-red-thumbs";
        break;
      }
      if (
        ["gzip", "vnd.rar", "x-zip-compressed", "x-7z-compressed"].includes(
          fileTypeExtension
        )
      ) {
        icon = "la-file-archive";
        color = "text-black-thumbs";
        break;
      }
      if (fileTypeExtension === "rtf") {
        icon = "la-file-alt";
        color = "text-black";
        break;
      }
      icon = "la-file-alt";
      color = "text-black";
      break;
    case "text":
      if (fileTypeExtension === "csv") {
        icon = "la-file-csv";
        color = "text-green-thumbs";
      }
      if (fileTypeExtension === "html") {
        icon = "la-file-code";
        color = "text-agitalks";
      }
      if (fileTypeExtension === "text") {
        icon = "la-file-alt";
        color = "text-black";
      }
      icon = "la-file-alt";
      color = "text-black";
      break;
    default:
      icon = `la-file-${fileType}`;
      color = "text-agitalks";
      break;
  }
  return (
    <i
      className={`las ${icon} ${color} ${size ? "text-[40px]" : "text-[24px]"}`}
    />
  );
};

const Thumbs = ({
  thumbs,
  setThumbs,
}: {
  thumbs: IAcceptedFiles;
  setThumbs: React.Dispatch<React.SetStateAction<IAcceptedFiles>>;
}) => {
  const removeItem = (id: string) => {
    const noItemWithId = thumbs.filter((el) => el.preview !== id);
    setThumbs(noItemWithId);
  };

  const thumbTypes = (file: IAcceptedFile) => {
    const { type } = file;
    return (
      <div
        title={file.name}
        className="flex relative flex-col items-center justify-center h-[60px] w-full pt-[15px]"
      >
        {iconType(type, false)}
        <span className="text-[10px] w-full text-ellipsis overflow-x-hidden whitespace-nowrap">
          {file.name}
        </span>
      </div>
    );
  };

  return (
    <div className="flex overflow-x-auto">
      {thumbs.map((file, index) => {
        return (
          <div
            className="flex relative items-center justify-center w-[75px] p-[5px] shadow-md rounded-[8px] m-[5px] border"
            // eslint-disable-next-line react/no-array-index-key
            key={file.name + index}
          >
            <CloseButton
              onClick={() => removeItem(file.preview || "")}
              extraClass="flex absolute text-[14px] overflow-hidden transition-all z-[200] right-[5px] top-[5px] border-0 text-red"
            />
            {thumbTypes(file)}
          </div>
        );
      })}
    </div>
  );
};

export default Thumbs;
