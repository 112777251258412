import React from "react";
import LoadingDots from "../LoadingDots.component";

const LoadingGridCard = () => {
  return (
    <LoadingDots
      medium
      className="mx-auto p-4 flex justify-center items-center my-auto w-[150px] h-full"
    />
  );
};

export default LoadingGridCard;
