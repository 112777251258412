import { IOfficehour } from "../slices/officehours.slice";
import instances from "./instances";

const officehour = async ({ _id }: { _id: string }) => {
  try {
    const response = await instances.instanceOfficehours.get(`/${_id}`);
    if (response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const officehours = async () => {
  try {
    const response = await instances.instanceOfficehours.get(`/officehours`);
    if (response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const update = async ({ _officehour }: { _officehour: IOfficehour }) => {
  try {
    const data = _officehour;
    const response = await instances.instanceOfficehours.patch(
      `${_officehour._id}`,
      data
    );
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const deleteOfficehours = async ({ ids }: { ids: string[] }) => {
  try {
    const data = { id: ids };
    const response = await instances.instanceOfficehours.delete("", { data });
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const create = async ({ name }: { name: string }) => {
  try {
    const data = {
      name,
    };
    const response = await instances.instanceOfficehours.post("", data);
    if (response && response.data && !response.data.message) {
      return response.data;
    }
    return response.data.message;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const search = async ({
  skip,
  limit,
  filter,
  deleted,
  allStatus,
}: {
  skip?: number;
  limit?: number;
  filter: string;
  deleted: boolean;
  allStatus?: boolean;
}) => {
  try {
    const _filters =
      typeof filter === "object" ? JSON.parse(JSON.stringify(filter)) : {};

    if (typeof filter === "string" && typeof _filters?.content === "undefined")
      _filters.content = filter;
    if (allStatus) _filters.active = false;
    if (deleted) _filters.deleted = true;

    const response = await instances.instanceOfficehours.get("", {
      params: {
        skip: skip || 0,
        limit: limit || 10,
        filter: _filters,
        fields:
          "active,created_at,created_by,default,deleted,enablelink,name,officehours,teams._id,teams.name,teams.active,tenant,updated_at,updated_by",
      },
    });
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const officehoursService = {
  officehour,
  officehours,
  update,
  deleteOfficehours,
  create,
  search,
};

export default officehoursService;
