import React from "react";
import { Link } from "react-router-dom";
import LinkButton from "../shared/components/Buttons/LinkButton.component";

const OfficehoursLink = ({
  url,
  extraClass,
}: {
  url: string;
  extraClass?: string;
}) => {
  return (
    <Link
      to={url}
      target="_blank"
      // rel="noopener noreferrer"
      className={`flex items-center justify-center ${extraClass || ""}`}
    >
      <LinkButton
        color
        leftIcon="las la-calendar-check text-[12px] mr-2"
        label="Consulte nossos horários de atendimento"
        onClick={() => null}
        extraClass="text-[10px] self-center mb-2"
      />
    </Link>
  );
};

OfficehoursLink.defaultProps = {
  extraClass: "",
};

export default OfficehoursLink;
