import React from "react";
// import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux/hooks";
import {
  changeStatus,
  logout,
  refreshLogout,
  selectTenant,
  signin,
} from "../../slices/auth.slice";
import { clearRecents, selectHistoyContact } from "../../slices/chats.slice";
import { UserStatus } from "../../slices/users.slice";
import { authService } from "../../services";
import Button from "./Buttons/Button.component";

// const MainHeader = ({
//   type,
//   setShow,
//   showFilter,
//   setShowFilter,
//   filtersSelected,
// }: {
//   type: string;
//   setShow: React.Dispatch<React.SetStateAction<boolean>>;
//   showFilter?: boolean;
//   setShowFilter?: React.Dispatch<React.SetStateAction<boolean>>;
//   filtersSelected?: number;
// }) => {
const MainHeader = ({
  type,
  setShow,
  showHistory,
  setShowHistory,
  showFilter,
  setShowFilter,
}: {
  type: string;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  showHistory: boolean;
  setShowHistory: React.Dispatch<React.SetStateAction<boolean>>;
  showFilter: boolean;
  setShowFilter: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const dispatch = useAppDispatch();
  // const navigate = useNavigate();
  const { user, tenantID } = useAppSelector((state) => state.auth);
  const { recentChats, selectedHistoryContact } = useAppSelector(
    (state) => state.chats
  );

  const handleLogout = async () => {
    const _tenant = tenantID;
    if (recentChats.length > 0) window.parent.postMessage("toggleRecent", "*");
    await dispatch(
      changeStatus({ status: UserStatus.OFFLINE, origin: user?._id })
    );
    dispatch(clearRecents());
    if (!["inbox-widget", "contact-widget"].includes(type)) {
      await dispatch(logout());
      dispatch(refreshLogout());
      localStorage.clear();
    } else {
      if (type === "inbox-widget") {
        await dispatch(logout());
      }
      dispatch(refreshLogout());
      localStorage.clear();
      if (typeof _tenant !== "undefined" && _tenant.length > 0) {
        dispatch(selectTenant(_tenant));
        await dispatch(signin({ tenant: _tenant }));
      }
    }
  };

  const getLabel = () => {
    if (
      selectedHistoryContact !== null &&
      typeof selectedHistoryContact?.text !== "undefined" &&
      selectedHistoryContact?.text.length > 0
    ) {
      return "Limpar";
    }
    if (!showHistory && !showFilter) return "Histórico";
    return "Voltar";
  };

  const getIcon = () => {
    if (
      selectedHistoryContact !== null &&
      typeof selectedHistoryContact?.text !== "undefined" &&
      selectedHistoryContact?.text.length > 0
    ) {
      return "las la-eraser";
    }
    if (!showHistory && !showFilter) return "las la-history";
    return "las la-arrow-left";
  };

  const getOnClick = () => {
    if (
      selectedHistoryContact !== null &&
      typeof selectedHistoryContact?.text !== "undefined" &&
      selectedHistoryContact?.text.length > 0
    ) {
      dispatch(selectHistoyContact(null));
    } else if (showFilter) {
      setShowFilter(false);
    } else {
      setShowHistory((prevState) => !prevState);
    }
  };

  const getActionsComponent = () => {
    if (["inbox", "inbox-widget"].includes(type)) {
      if (
        selectedHistoryContact !== null &&
        typeof selectedHistoryContact?.text !== "undefined" &&
        selectedHistoryContact?.text.length > 0
      ) {
        return (
          <>
            <Button
              minWidth={false}
              inverted
              disabled={false}
              height="h-[28px]"
              hoverColor
              extraClass="mr-2 w-[115px]"
              label="Voltar"
              onClick={() => {
                dispatch(selectHistoyContact(null));
                setShowHistory((prevState) => !prevState);
                setShow(false);
              }}
              icon="las la-arrow-left"
            />
            <Button
              minWidth={false}
              inverted
              disabled={false}
              height="h-[28px]"
              hoverColor
              extraClass="mr-2 w-[115px]"
              label={getLabel()}
              onClick={getOnClick}
              icon={getIcon()}
            />
          </>
        );
      }
      return (
        <Button
          minWidth
          inverted
          disabled={false}
          height="h-[28px]"
          hoverColor
          extraClass="mr-2 w-[115px]"
          label={getLabel()}
          onClick={getOnClick}
          icon={getIcon()}
        />
      );
    }
    return (
      <Button
        minWidth={false}
        inverted
        disabled={false}
        height="h-[28px]"
        hoverColor
        extraClass="mr-2 w-[115px]"
        label="Voltar"
        onClick={() => {
          setShow(false);
        }}
        icon="las la-arrow-left"
      />
    );
  };

  return (
    <div className="rounded-none max-h-[70px] flex items-center">
      {["inbox", "inbox-widget"].includes(type) ? (
        <>{getActionsComponent()}</>
      ) : (
        <Button
          minWidth
          inverted
          disabled={false}
          height="h-[28px]"
          hoverColor
          extraClass="mr-2 !min-w-[115px]"
          label="Voltar"
          onClick={() => {
            setShow(false);
          }}
          icon="las la-arrow-left"
        />
      )}
      {["inbox-widget", "contact-widget"].includes(type) ? (
        <i
          role="presentation"
          onClick={handleLogout}
          className="cursor-pointer mr-2 text-red hover:text-red-alphaA0 las la-sign-out-alt text-[24px]"
          title="Sair"
          // aria-label="Sair"
        />
      ) : null}
    </div>
  );
};

// MainHeader.defaultProps = {
//   showFilter: false,
//   setShowFilter: () => null,
//   filtersSelected: 0,
// };

export default MainHeader;
