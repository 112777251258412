import React from "react";
import { IUser } from "../../../../../slices/users.slice";
import { Preview, TemplateVarControl } from "..";
import { ITemplate } from "../../../../../slices/messages.slice";
import { IAcceptedFiles } from "../../../../../shared/models/interfaces/acceptedfile.interface";
import { ICampaign } from "../../../../../slices/campaigns.slice";

const WhatsAppTemplate = ({
  campaign,
  contactChat,
  results,
  files,
  setFiles,
  setTemplateButtons,
}: {
  campaign?: ICampaign;
  contactChat?: IUser;
  results: ITemplate;
  files: IAcceptedFiles;
  setFiles: React.Dispatch<React.SetStateAction<IAcceptedFiles>>;
  setTemplateButtons: React.Dispatch<
    React.SetStateAction<{
      url: string;
      code: string;
    }>
  >;
}) => {
  const _file = files.length > 0 ? files[0]?.preview : "";

  return (
    <>
      <Preview
        campaign={campaign}
        file={files.length > 0 ? _file : undefined}
        results={results}
      />
      <TemplateVarControl
        campaign={campaign}
        contactChat={contactChat}
        results={results}
        files={files}
        setFiles={setFiles}
        setTemplateButtons={setTemplateButtons}
      />
    </>
  );
};

WhatsAppTemplate.defaultProps = {
  campaign: undefined,
  contactChat: undefined,
};

export default WhatsAppTemplate;
