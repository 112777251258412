import React, { FormEvent, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Divider, Form } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import useInput from "../../../../hooks/useInput";
import validateEmail from "../../../../shared/utils/validation/email";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux/hooks";
import { forgotPassword } from "../../../../slices/auth.slice";
import LoadingDots from "../../../../shared/components/LoadingDots.component";
import { validateNameLength } from "../../../../shared/utils/validation/length";
import FormButton from "../../../../shared/components/Buttons/FormButton.component";
import ErrorLabel from "./ErrorLabel.component";
import LinkButton from "../../../../shared/components/Buttons/LinkButton.component";
import CommonError from "./CommonError.component";

const RecoveryForm = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/chat";
  const dispatch = useAppDispatch();
  const {
    isLoadingAuth,
    isSuccess,
    isAuthenticated,
    isForgot,
    isErrorPass,
    tenantID,
  } = useAppSelector((state) => state.auth);

  const {
    text: tenant,
    shouldDisplayError: tenantHasError,
    textChangeHandler: tenantChangeHandler,
    inputBlurHandler: tenantBlurHandler,
  } = useInput(validateNameLength);
  const {
    text: email,
    shouldDisplayError: emailHasError,
    emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
  } = useInput(validateEmail);

  useEffect(() => {
    //
  }, [isSuccess, dispatch, email.length]);

  useEffect(() => {
    if (!isAuthenticated) return;
    navigate(from, { replace: true });
  }, [isAuthenticated]);

  const onSubmitHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if ((tenantHasError && !tenantID) || emailHasError) return;
    if ((tenant.length === 0 && !tenantID) || email.length === 0) return;
    const recoveryUser = {
      tenant: !tenantID
        ? tenant.toLowerCase().trim()
        : tenantID.toLowerCase().trim(),
      email: email.toLowerCase().trim(),
    };
    await dispatch(forgotPassword(recoveryUser));
  };

  const goToLogin = async () => {
    navigate("/login");
  };

  if (isLoadingAuth)
    return <LoadingDots className="flex justify-center items-center" />;
  if (!isForgot)
    return (
      <Form
        className="px-16 bg-white h-full rounded-xl flex flex-col"
        onSubmit={onSubmitHandler}
      >
        <Form.Input
          className="pt-2"
          // icon="building"
          // iconPosition="left"
          label={t("form.label.tenant")}
          value={tenantID || tenant}
          required
          readOnly={tenantID && tenantID.length > 0}
          onChange={!tenantID ? tenantChangeHandler : undefined}
          onBlur={!tenantID ? tenantBlurHandler : null}
          error={!tenantID ? tenantHasError : null}
          type="text"
          name="tenant"
          id="tenant"
          variant="outlined"
          placeholder={t("form.placeholder.tenant")}
          autoComplete="off"
        />
        <ErrorLabel error={tenantHasError} label={t("form.error.tenant")} />
        <Form.Input
          className="pt-2"
          // icon="at"
          // iconPosition="left"
          label={t("form.label.email")}
          value={email}
          required
          onChange={emailChangeHandler}
          onBlur={emailBlurHandler}
          error={emailHasError}
          type="email"
          name="email"
          id="email"
          variant="outlined"
          placeholder={t("form.placeholder.email")}
          autoComplete="new-email"
        />
        <ErrorLabel error={emailHasError} label={t("form.error.email")} />
        {isErrorPass ? <CommonError /> : null}
        <FormButton
          className="flex flex-wrap justify-center mb-4 mt-4"
          disabled={
            (tenant.length === 0 && !tenantID) ||
            email.length === 0 ||
            (tenantHasError && !tenantID) ||
            emailHasError ||
            false
          }
          label={t("formbutton.label.send")}
        />

        <Divider sx={{ marginTop: "24px", marginBottom: "24px" }} />

        <div className="text-center flex items-center justify-center">
          <LinkButton
            color
            label={t("linkbutton.label.login")}
            onClick={goToLogin}
          />
        </div>
      </Form>
    );
  return (
    <>
      <Form
        className="px-16 bg-white h-full items-center rounded-xl flex flex-col"
        onSubmit={goToLogin}
      >
        <FormButton
          className="flex flex-wrap justify-center mb-4 mt-4 w-[200px]"
          disabled={false}
          label={t("formbutton.label.back")}
        />
      </Form>
      <Divider sx={{ marginTop: "24px", marginBottom: "24px" }} />

      <div className="text-center flex items-center justify-center">
        <LinkButton
          color
          label={t("linkbutton.label.login")}
          onClick={goToLogin}
        />
      </div>
    </>
  );
};

export default RecoveryForm;
