import React from "react";
import { useTranslation } from "react-i18next";
import logoonly from "../../../assets/images/logo-only.png";
import { ILoginMethod, selectMethod } from "../../../slices/auth.slice";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/hooks";

const AuthButton = ({ method }: { method: ILoginMethod }) => {
  const { t } = useTranslation();
  const { selectedLoginMethod } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const goToAgidesk = async () => {
    dispatch(
      selectMethod(method._id === selectedLoginMethod?._id ? null : method)
    );
  };

  const _classDefault = `mb-2 justify-between w-full py-[6px] h-[36px] pr-6 cursor-pointer items-center inline-flex rounded-3xl hover:bg-agidesk-alphaA0`;
  const _classType = `pl-4 border border-agidesk-alphaC0 text-gray-900 hover:text-white`;

  return (
    <div
      key={method._id}
      title={t("authbutton.title-agidesk") || ""}
      className={`${_classDefault} ${_classType}`}
      role="presentation"
      onClick={goToAgidesk}
    >
      <div className="flex items-center w-full">
        <div className="w-[20px] h-[20px]">
          <img
            className="w-full h-full"
            src={logoonly}
            alt={t("authbutton.title-agidesk") || ""}
          />
        </div>
        <h2
          className="mx-2 w-[70%] text-justify text-[14px] font-extrabold truncate"
          title={method?.fields?.displaytitlelogin || method?.fields?.name}
        >
          {method?.fields?.displaytitlelogin || method?.fields?.name}
        </h2>
      </div>
      <div className="w-[5%] flex items-center">
        <i className="las la-arrow-right cursor-pointer" />
      </div>
    </div>
  );
};

export default AuthButton;
