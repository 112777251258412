/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import { Form } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import StrongPasswordChecklist from "../../../../shared/components/StrongPasswordChecklist.component";
import ShowHideButton from "../../../../shared/components/Buttons/ShowHideButton.component";

const Step2 = ({
  passInput,
  setPass,
  validPass,
  validatePass,
  passConfirmInput,
  setPassConfirm,
  validPassConfirm,
  validatePassConfirm,
}: {
  passInput: string;
  setPass: React.Dispatch<React.SetStateAction<string>>;
  validPass: boolean;
  validatePass: React.Dispatch<React.SetStateAction<boolean>>;
  passConfirmInput: string;
  setPassConfirm: React.Dispatch<React.SetStateAction<string>>;
  validPassConfirm: boolean;
  validatePassConfirm: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation();
  const regex =
    /(?=^.{8,}$)((?=.*\d)(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/g;

  const [showHide, setShowHide] = useState<{
    pass: boolean;
    confirmpass: boolean;
  }>({
    pass: false,
    confirmpass: false,
  });

  const handleShowHide = (
    e: React.MouseEvent<Element, MouseEvent>,
    type: "pass" | "confirmpass"
  ) => {
    e.preventDefault();
    setShowHide((prevState) => ({
      pass: type === "pass" ? !prevState.pass : prevState.pass,
      confirmpass:
        type === "confirmpass" ? !prevState.confirmpass : prevState.confirmpass,
    }));
  };

  return (
    <>
      <div className="pt-2 relative">
        <Form.Input
          label={t("form.label.password")}
          required
          value={passInput}
          onChange={(e) => {
            setPass(e.target.value.trim());
            validatePass(regex.test(e.target.value.trim()));
            validatePassConfirm(
              passConfirmInput.length > 0 && passConfirmInput === e.target.value
            );
          }}
          error={!validPass}
          type={showHide.pass ? "input" : "password"}
          name="password"
          id="password"
          variant="outlined"
          placeholder={t("form.placeholder.password")}
          autoComplete="new-password"
          action
        >
          <input />
          <ShowHideButton
            title={`${!showHide.pass ? "Mostrar" : "Esconder"} ${t(
              "form.label.password"
            ).toLowerCase()}`}
            hide={showHide.pass}
            onClick={(e) => handleShowHide(e, "pass")}
          />
        </Form.Input>
        {!validPass ? (
          <StrongPasswordChecklist
            rules={["minLength", "specialChar", "number", "capital"]}
            minLength={8}
            value={passInput || ""}
          />
        ) : null}
      </div>
      <div className="pt-2 relative">
        <Form.Input
          label={t("form.label.confirm-password")}
          required
          value={passConfirmInput}
          onChange={(e) => {
            setPassConfirm(e.target.value);
            validatePass(regex.test(passInput.trim()));
            validatePassConfirm(
              e.target.value.length > 0 && passInput === e.target.value
            );
          }}
          error={passConfirmInput.length > 0 && passInput !== passConfirmInput}
          type={showHide.confirmpass ? "input" : "password"}
          name="confirmPassword"
          id="confirmPassword"
          variant="outlined"
          placeholder={t("form.placeholder.confirm-password")}
          autoComplete="new-password"
          action
        >
          <input />
          <ShowHideButton
            title={`${!showHide.confirmpass ? "Mostrar" : "Esconder"} ${t(
              "form.label.confirm-password"
            ).toLowerCase()}`}
            hide={showHide.confirmpass}
            onClick={(e) => handleShowHide(e, "confirmpass")}
          />
        </Form.Input>
        {!validPassConfirm ? (
          <StrongPasswordChecklist
            rules={["match"]}
            minLength={8}
            value={passConfirmInput || ""}
          />
        ) : null}
      </div>
    </>
  );
};

export default Step2;
