/* eslint-disable import/no-duplicates */
import React from "react";
import { formatRelative, format, parseISO } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { ExternalAppType } from "../../slices/externalapps.slice";
import logoonly from "../../assets/images/logo-only-gray.png";
import ImportedIcon from "./Agidesk/Import/ImportedIcon.component";
import SpamIcon from "./Icons/Spam.component";

const HeaderPageInfo = ({
  created,
  updated,
  deleted,
  title,
  imported,
  spam,
  icon,
}: {
  created?: string | null;
  updated?: string | null;
  deleted?: string | null;
  title?: string;
  imported?: boolean;
  spam?: boolean;
  icon?: string;
}) => {
  const getDeletedOrUpdated = () => {
    if (deleted) {
      return (
        <p className="text-sm justify-end flex text-gray-999">
          <b>Removido:</b>
          <span className="first-letter:uppercase ml-1">
            {updated
              ? `${formatRelative(
                  parseISO(updated || Date.now().toString()),
                  new Date(),
                  {
                    locale: ptBR,
                  }
                )}`.replace(" às", ", às")
              : "-"}
          </span>
        </p>
      );
    }
    if (updated) {
      return (
        <p className="text-sm justify-end flex text-gray-999">
          <b>Atualizado:</b>
          <span className="first-letter:uppercase ml-1">
            {updated
              ? `${formatRelative(
                  parseISO(updated || Date.now().toString()),
                  new Date(),
                  {
                    locale: ptBR,
                  }
                )}`.replace(" às", ", às")
              : "-"}
          </span>
        </p>
      );
    }
    return null;
  };

  return (
    <div className="text-2xl font-medium items-center flex mb-5 pb-2 border-b-2 border-agitalks">
      {icon !== ExternalAppType.AGIDESK.toLowerCase() ? (
        <i className={`${icon} mr-4 text-4xl`} />
      ) : (
        <img className="mr-4 agidesk-logo" alt="Agidesk" src={logoonly} />
      )}
      <div className="flex justify-between w-full items-center">
        <div className="items-center flex max-w-[250px] md:max-w-[350px]">
          <p className="truncate" title={title}>
            {title}
          </p>
          {imported ? <ImportedIcon short /> : null}
          {spam ? <SpamIcon short /> : null}
        </div>
        <div>
          {created ? (
            <p className="text-sm text-end text-gray-999">
              <b>Criado em</b>{" "}
              {created
                ? format(parseISO(created), "dd/MM/yyyy', às' HH:mm", {
                    locale: ptBR,
                  })
                : "-"}
            </p>
          ) : null}
          {getDeletedOrUpdated()}
        </div>
      </div>
    </div>
  );
};

HeaderPageInfo.defaultProps = {
  created: undefined,
  updated: undefined,
  deleted: undefined,
  title: undefined,
  imported: undefined,
  spam: undefined,
  icon: undefined,
};

export default HeaderPageInfo;
