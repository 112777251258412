import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import ChatUsers from "../features/chats/components/ChatUsers.component";
import Inbox from "../features/chats/components/Inbox.component";
import { useAppDispatch, useAppSelector } from "../hooks/redux/hooks";
import {
  ChatStatus,
  getSearchChats,
  selectHistoyContact,
  selectSearchChats,
  selectTotalSearchChats,
} from "../slices/chats.slice";
import useWindowDimensions from "../hooks/useWindowDimensions";
import logoonly from "../assets/images/logo-only-gray.png";
import {
  ExternalAppType,
  getSearchExternalApps,
} from "../slices/externalapps.slice";
import { fetchToken } from "../firebase-config";
import { UserType } from "../slices/users.slice";
import ToggleSelectHeader from "../features/chats/components/ToggleHeader/ToggleSelectHeader.component";
import ChatHistory from "../features/chats/components/ChatHistory.component";
import Button from "../shared/components/Buttons/Button.component";
import { getSettings } from "../slices/settings.slice";
import useLGPD from "../hooks/useLGPD";
import { IFiltersDropdownInstances } from "../shared/components/@types/searchBarFilter.types";
import SearchbarFilters from "../shared/components/SearchbarFilters.component";
import { ChatPageProps } from "./@types/Chat.page.types";

const defaultFilters: IFiltersDropdownInstances = {
  campaigns: [],
  period: [],
  types: [],
  externalapps: [],
  status: [],
  teams: [],
  users: [],
  customers: [],
  contacts: [],
  content: [],
  groupchat: [],
  contacttags: [],
  tags: [],
};

const ChatPage = ({
  widgetType,
  toggleList,
  userToggleList,
  setToggleList,
  setUserToggleList,
  showHistory,
  setShowHistory,
  showFilter,
  setShowFilter,
  showUsersList,
  setShowUsersList,
}: ChatPageProps) => {
  const { isWidgetAgent, isWidgetContact } = useAppSelector(
    (state) => state.auth
  );
  const { selectedChat, backlogChats, mineChats } = useAppSelector(
    (state) => state.chats
  );
  const { externalAppAgidesk } = useAppSelector((state) => state.externalapps);

  const { showCode } = useLGPD();

  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { width } = useWindowDimensions();

  const [showWidgetAlert, setShowWidgetAlert] = useState<boolean>(false);
  const [showNoTeamsAlert, setShowNoTeamsAlert] = useState<boolean>(false);
  const [showNewGroupChat, setShowNewGroupChat] = useState<boolean>(false);

  const [isTokenFound, setIsTokenFound] = useState<boolean>(false);
  const [token, setToken] = useState<string>("");

  const [searchFiltersSelected, setSearchFiltersSelected] =
    useState<IFiltersDropdownInstances>(defaultFilters);
  const [searchFiltersOptions, setSearchFiltersOptions] =
    useState<IFiltersDropdownInstances>(defaultFilters);
  const [skipSearch, setSkipSearch] = useState<number>(0);

  const clearStoragedFilters = ({ _instance }: { _instance?: string }) => {
    if (typeof _instance === "undefined") {
      document
        .querySelectorAll(".dropdown-filters .clear")
        .forEach((el: any) => el.click());
      document
        .querySelectorAll(".dropdown-filters-users .clear")
        .forEach((el: any) => el.click());
      setSearchFiltersSelected(defaultFilters);
      setSearchFiltersOptions(defaultFilters);
      dispatch(selectSearchChats([]));
      dispatch(selectTotalSearchChats(0));
      // setRenderDataSearch([]);
    } else {
      const _defaultFilters: any = defaultFilters;
      document
        .querySelectorAll(".dropdown-filters-users .clear")
        .forEach((el: any) => el.click());
      setSearchFiltersSelected({
        ...searchFiltersSelected,
        [_instance]: _defaultFilters[_instance],
        // groupchat: [],
        campaignchat: undefined,
      });
      setSearchFiltersOptions({
        ...searchFiltersOptions,
        [_instance]: _defaultFilters[_instance],
        // users: defaultFilters.users,
        // groupchat: [],
        // campaignchat: false,
      });
    }
  };

  useEffect(() => {
    (async () => {
      if (widgetType !== UserType.CONTACT && !isWidgetContact) {
        await dispatch(getSettings({}));
      }
      await dispatch(
        getSearchChats({
          skip: 0,
          limit: 10,
          filter: {
            name: "",
            status: [ChatStatus.WAIT],
            grouptype: "backlog",
          },
          deleted: false,
          code: showCode,
        })
      );
      await dispatch(
        getSearchExternalApps({
          filter: {
            type: ExternalAppType.AGIDESK,
            "fields.validate": true,
            active: true,
            deleted: false,
          },
        })
      );
      if (
        widgetType !== UserType.AGENT &&
        widgetType !== UserType.CONTACT &&
        !isWidgetAgent &&
        !isWidgetContact
      ) {
        if (!isTokenFound || !token) {
          await fetchToken({ setToken, setIsTokenFound });
        }
      }
    })();
  }, []);

  useEffect(() => {
    setShowFilter(false);
    setShowHistory(false);
    dispatch(selectHistoyContact(null));
    setToggleList("mine");
    setUserToggleList("users");
  }, [showUsersList]);

  useEffect(() => {
    (async () => {
      if (params._id && !selectedChat?._id && externalAppAgidesk.length === 0) {
        navigate("/chat/");
      }
    })();
  }, [params._id]);

  useEffect(() => {
    (async () => {
      if (
        selectedChat?._id &&
        (location.pathname === "/chat" ||
          (location.pathname.length > 6 &&
            !location.pathname.includes(selectedChat._id)))
      )
        navigate(`/chat/${selectedChat._id}`);
    })();
  }, [selectedChat?._id]);

  const getListComponent = () => {
    if (showHistory)
      return (
        <ChatHistory
          widgetType={UserType.NONE}
          setShowFilter={setShowFilter}
          setShow={setShowUsersList}
        />
      );
    if (showUsersList)
      return (
        <ChatUsers
          showWidgetAlert={showWidgetAlert}
          setShowWidgetAlert={setShowWidgetAlert}
          showNoTeamsAlert={showNoTeamsAlert}
          setShowNoTeamsAlert={setShowNoTeamsAlert}
          showNewGroupChat={showNewGroupChat}
          setShowNewGroupChat={setShowNewGroupChat}
          widgetType={UserType.NONE}
          setShow={setShowUsersList}
          toggleList={userToggleList || "users"}
        />
      );
    if (showFilter)
      return (
        <SearchbarFilters
          searchInstanceName="chats"
          clearFilters={clearStoragedFilters}
          skipSearchResults={skipSearch}
          searchFiltersOptions={searchFiltersOptions}
          searchFiltersSelected={searchFiltersSelected}
          setSearchFiltersOptions={setSearchFiltersOptions}
          setSearchFiltersSelected={setSearchFiltersSelected}
          setShowFilter={setShowFilter}
          setToggleList={setToggleList}
          defaultFilters={defaultFilters}
        />
      );

    return (
      <>
        <Inbox
          clearFilters={clearStoragedFilters}
          searchFiltersSelected={searchFiltersSelected}
          searchFiltersOptions={searchFiltersOptions}
          widgetType={UserType.NONE}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          setShow={setShowUsersList}
          toggleList={toggleList}
          setToggleList={setToggleList}
          skipSearch={skipSearch}
          setSkipSearch={setSkipSearch}
        />
        <Button
          minWidth
          disabled={false}
          extraClass="absolute bottom-0 right-0 m-[16px] xl:w-1/4 w-1/3 min-w-fit"
          label="Nova conversa"
          onClick={() => setShowUsersList(true)}
          icon="las la-plus"
        />
      </>
    );
  };

  return (
    <div className="2xl:justify-center 2xl:px-11 2xl:py-8 flex mx-auto h-full w-full bg-gray-bench-alpha30">
      {/* <!-- SIDEBAR --> */}
      <div
        className={`pl-[10px] flex-container w-full sm:w-[395px] bg-background relative ${
          !selectedChat?._id ? "flex" : "!hidden md:!flex border-r"
        } 2xl:rounded-l-xl`}
      >
        {/* <!-- HEADER --> */}
        <div className="header">
          <ToggleSelectHeader
            widgetType={widgetType}
            setShow={setShowUsersList}
            showHistory={showHistory}
            setShowHistory={setShowHistory}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            setShowWidgetAlert={setShowWidgetAlert}
            setShowNoTeamsAlert={setShowNoTeamsAlert}
            setShowNewGroupChat={setShowNewGroupChat}
            type={showUsersList ? "contact" : "inbox"}
            toggleList={
              showUsersList ? userToggleList || "users" : toggleList || "mine"
            }
            setToggleList={showUsersList ? setUserToggleList : setToggleList}
            notificationsBacklog={
              !showUsersList
                ? backlogChats.reduce((accumulator, chat) => {
                    if (
                      chat?.notifications &&
                      chat?.status &&
                      ![
                        ChatStatus.CANCELED,
                        ChatStatus.SUSPEND,
                        ChatStatus.FINISH,
                      ].includes(chat.status)
                    ) {
                      return accumulator + 1;
                    }
                    return accumulator;
                  }, 0)
                : 0
            }
            notificationsMe={
              !showUsersList
                ? mineChats.reduce((accumulator, chat) => {
                    if (
                      chat?.notifications &&
                      chat?.status &&
                      ![ChatStatus.FINISH].includes(chat.status)
                    ) {
                      return accumulator + 1;
                    }
                    return accumulator;
                  }, 0)
                : 0
            }
            notificationsTeams={0}
          />
        </div>
        {/* <!-- CHATS/USERS/FILTER LIST --> */}
        {getListComponent()}
      </div>

      {/* <!-- CHAT --> */}
      <div
        className={`2xl:max-w-[923px] w-full flex-col overflow-y-auto overflow-x-hidden flex 2xl:rounded-r-xl flex-1 ${
          location.pathname.includes("/chat/") && width < 768
            ? "2xl:rounded-l-xl"
            : "min-w-[500px]:rounded-l-xl rounded-l-none"
        }`}
      >
        {selectedChat?._id &&
        location.pathname.includes("/chat/") &&
        location.pathname.length > 6 ? (
          <Outlet />
        ) : (
          <div className="bg-white flex-1 text-wrap px-20 relative flex flex-col text-3xl items-center justify-center">
            <img
              src={logoonly}
              alt="logo"
              className="w-12 flex items-center mb-4"
            />
            <span className="text-center text-[20px] text-gray-999">
              <h2 className="text-center font-semibold text-[24px] text-black">
                Agitalks
              </h2>
              <br />
              Escolha uma conversa para visualizar e interagir.
              <br /> Ou clique no botão <b>Nova conversa</b> para iniciar uma.
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatPage;
