import React from "react";

const LinkButton = ({
  onClick,
  disabled,
  label,
  icon,
  leftIcon,
  extraClass,
  color,
}: {
  onClick: (e?: any) => void;
  disabled?: boolean;
  label: string;
  icon?: string;
  leftIcon?: string;
  extraClass?: string;
  color?: boolean;
}) => {
  return (
    <button
      className={`${extraClass || "text-base"} border-none flex items-center ${
        disabled ? "text-gray-999" : "cursor-pointer"
      } ${
        color
          ? "text-blue-link hover:text-blue-link-hover"
          : "text-agitalks hover:text-agitalks-alphaC0"
      } no-underline`}
      type="button"
      disabled={disabled}
      onClick={onClick}
    >
      {leftIcon ? <i className={`${leftIcon} ml-2`} /> : null}
      <p>{label}</p>
      {icon ? <i className={`${icon} ml-2`} /> : null}
    </button>
  );
};

LinkButton.defaultProps = {
  disabled: false,
  leftIcon: undefined,
  icon: undefined,
  extraClass: undefined,
  color: undefined,
};

export default LinkButton;
