import React from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../hooks/redux/hooks";
import { AuthLayoutPage } from "../pages";
import Button from "../shared/components/Buttons/Button.component";
import { UserType } from "../slices/users.slice";
import Avatar from "../shared/components/Avatar.component";
import NoAvatar from "../shared/components/NoAvatar.component";
import useFormatText from "../hooks/useFormatText";
import OfficehoursLink from "./OfficehoursLink.component";
import useContactLogout from "../hooks/useContactLogout";

const Unavailable = ({
  widgetType,
  isOffline,
  setIsOffline,
}: {
  widgetType?: UserType;
  isOffline?: boolean;
  setIsOffline?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { selectedSettings } = useAppSelector((state) => state.settings);
  const { tenantID } = useAppSelector((state) => state.auth);
  const location = useLocation();
  const { logoutContact } = useContactLogout();
  const { t } = useTranslation();

  const getMessageToShow = () => {
    // const _message = selectedSettings?.offlinemessage?.message?.find(
    //   (_offlinemessage: IOfflinemessageType) =>
    //     _offlinemessage?.type === IOfflineType.WIDGET
    // );
    // if (isOffline && typeof _message?.content !== "undefined") {
    //   return _message?.content;
    // }
    if (isOffline) return t("form.warning.team");
    if (location.pathname === "/unavailableagents") {
      return (
        selectedSettings?.defaultbot?.userofflinemsg || "Não há agentes online"
      );
    }
    return (
      selectedSettings?.defaultbot?.unavailablemsg ||
      "Fora do horário de atendimento"
    );
  };

  return (
    <AuthLayoutPage widgetType={widgetType}>
      <div className="relative">
        <div className="flex flex-col items-center">
          <div className="w-24 h-24 mr-[10px]">
            {selectedSettings?.defaultbot?.avatar?.path &&
            selectedSettings?.defaultbot?.avatar?.name ? (
              <Avatar
                path={selectedSettings?.defaultbot.avatar?.path}
                name={selectedSettings?.defaultbot.avatar?.name}
                title="Agente virtual"
              />
            ) : (
              <NoAvatar
                displaytext={selectedSettings?.defaultbot?.name || "--"}
                elementClass="p-0"
                labelClass="text-[28px]"
              />
            )}
          </div>
          {selectedSettings?.defaultbot?.name ? (
            <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">
              {selectedSettings?.defaultbot?.name}
            </h5>
          ) : null}
          <span className="text-sm text-gray-500 dark:text-gray-400">
            Agente virtual
          </span>
        </div>
        <OfficehoursLink
          url={`${process.env.REACT_APP_FRONTEND_URL}/schedule/officehours/${tenantID}`}
          extraClass="mt-4"
        />
        <div className="mt-4 py-4 px-2 w-[340px] bg-white border border-gray-200 rounded-b-lg rounded-tr-lg shadow-md dark:bg-gray-800 dark:border-gray-700">
          <span
            className="text-gray-900 text-[18px]"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: useFormatText(getMessageToShow()),
            }}
          />
        </div>
      </div>
      {location.pathname === "/unavailableagents" ? (
        <Button
          minWidth
          extraClass="absolute bottom-0 right-0 m-[25px] xl:w-1/4 w-1/3"
          red
          label="Sair"
          onClick={async () => {
            if (setIsOffline) setIsOffline(false);
            logoutContact({ noStatus: true });
          }}
          icon="las la-times"
        />
      ) : null}
    </AuthLayoutPage>
  );
};

Unavailable.defaultProps = {
  widgetType: UserType.CONTACT,
  isOffline: false,
  setIsOffline: undefined,
};

export default Unavailable;
