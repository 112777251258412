import React, { useEffect, useState } from "react";
import { ICustomer } from "../../slices/customers.slice";
import { getSettings } from "../../slices/settings.slice";
import { ITeam } from "../../slices/teams.slice";
import { IUser } from "../../slices/users.slice";
import CreateModal from "./Modals/CreateModal.component";
import Grid from "./Tables/Grid.component";
import Table from "./Tables/Table.component";
import {
  IExternalAppAgidesk,
  IExternalAppBotmaker,
  IExternalAppVtex,
  IExternalAppWhatsApp,
} from "../../slices/externalapps.slice";
import { useAppDispatch } from "../../hooks/redux/hooks";
import { IOfficehour } from "../../slices/officehours.slice";
import { IHoliday } from "../../slices/holidays.slice";
import { IOfflinemessage } from "../../slices/offlinemessages.slice";
import { ISurvey } from "../../slices/surveys.slice";
import { ICampaign } from "../../slices/campaigns.slice";
import { ICommenttemplate } from "../../slices/commenttemplates.slice";
import { ITag } from "../../slices/tags.slice";

const Instances = ({
  keyword,
  setKeyword,
  isLoading,
  total,
  title,
  plus,
  icon,
  modalTitle,
  activePage,
  setActivePage,
  instancesPerPage,
  setInstancesPerPage,
  skip,
  setSkip,
  hasMore,
  setHasMore,
  renderData,
  renderList,
  limit,
  setLimit,
  showDeleted,
  setShowDeleted,
  showSpam,
  setShowSpam,
  showCards,
  setShowCards,
}: {
  keyword: string;
  setKeyword: React.Dispatch<React.SetStateAction<string>>;
  isLoading: boolean;
  renderList:
    | IExternalAppAgidesk[]
    | IExternalAppWhatsApp[]
    | IExternalAppBotmaker[]
    | IExternalAppVtex[]
    | IUser[]
    | ITeam[]
    | ICustomer[]
    | IOfficehour[]
    | IHoliday[]
    | ITag[]
    | IOfflinemessage[]
    | ISurvey[]
    | ICampaign[]
    | ICommenttemplate[];
  renderData:
    | IExternalAppAgidesk[]
    | IExternalAppWhatsApp[]
    | IExternalAppBotmaker[]
    | IExternalAppVtex[]
    | IUser[]
    | ITeam[]
    | ICustomer[]
    | IOfficehour[]
    | IHoliday[]
    | ITag[]
    | IOfflinemessage[]
    | ISurvey[]
    | ICampaign[]
    | ICommenttemplate[];
  total: number;
  title: string;
  plus: string;
  icon: string;
  modalTitle: string;
  activePage: number;
  setActivePage: React.Dispatch<React.SetStateAction<number>>;
  instancesPerPage: number;
  setInstancesPerPage: React.Dispatch<React.SetStateAction<number>>;
  skip: number;
  setSkip: React.Dispatch<React.SetStateAction<number>>;
  limit: number;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
  hasMore: boolean;
  setHasMore: React.Dispatch<React.SetStateAction<boolean>>;
  showDeleted: boolean;
  setShowDeleted: React.Dispatch<React.SetStateAction<boolean>>;
  showSpam: boolean;
  setShowSpam: React.Dispatch<React.SetStateAction<boolean>>;
  showCards: boolean;
  setShowCards: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      await dispatch(getSettings({}));
    })();
  }, [dispatch]);

  return (
    <>
      {showCards ? (
        <Grid
          keyword={keyword}
          isLoading={isLoading}
          setKeyword={setKeyword}
          showDeleted={showDeleted}
          setShowDeleted={setShowDeleted}
          showSpam={showSpam}
          setShowSpam={setShowSpam}
          hasMore={hasMore || false}
          setHasMore={setHasMore}
          setSkip={setSkip}
          setLimit={setLimit}
          total={total}
          list={renderData}
          title={title}
          icon={icon}
          setShowCards={() => {
            setSkip(0);
            setLimit(10);
            setActivePage(1);
            setInstancesPerPage(10);
            setShowCards(false);
          }}
          setShowModal={setShowModal}
        />
      ) : (
        <Table
          keyword={keyword}
          isLoading={isLoading}
          setKeyword={setKeyword}
          showDeleted={showDeleted}
          setShowDeleted={setShowDeleted}
          showSpam={showSpam}
          setShowSpam={setShowSpam}
          skip={skip}
          setSkip={setSkip}
          activePage={activePage}
          instancesPerPage={instancesPerPage}
          setActivePage={setActivePage}
          setInstancesPerPage={setInstancesPerPage}
          limit={limit}
          setLimit={setLimit}
          total={total}
          list={renderList}
          title={title}
          plus={plus}
          icon={icon}
          setShowCards={() => {
            setSkip(0);
            setLimit(30);
            setActivePage(1);
            setInstancesPerPage(10);
            setShowCards(true);
          }}
          setShowModal={setShowModal}
        />
      )}
      {/* MODALS */}
      {showModal ? (
        <CreateModal
          title={modalTitle}
          icon={icon}
          setShowModal={setShowModal}
          modal
        />
      ) : null}
    </>
  );
};

export default Instances;
