/* eslint-disable no-plusplus */
/* eslint-disable no-bitwise */
const hashCode = (str: string) => {
  // java String#hashCode
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
};

const intToRGB = (i: number) => {
  const c = (i & 0x00ffffff).toString(16).toUpperCase();

  return "00000".substring(0, 6 - c.length) + c;
};
const useSenderColor = (currentColor: string) => {
  let str = "";

  str = intToRGB(hashCode(currentColor));
  return `#${str}`;
};

export default useSenderColor;
