/* eslint-disable no-restricted-syntax */
/* eslint-disable no-case-declarations */
import React, { useEffect, useState } from "react";
import { Form } from "semantic-ui-react";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/hooks";
import {
  reselectTeam,
  updateTeam,
  UserSettings,
} from "../../../slices/teams.slice";
import { IUser } from "../../../slices/users.slice";
import ActionButtonsModal from "./ActionButtonsModal.component";
import removeDuplicates from "../../utils/removeDuplicates";
import Dropdown from "../Dropdown.component";

const ChatAgentModal = ({
  setShowModal,
  title,
  list,
}: {
  list: IUser[] | undefined;
  title: string;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const dispatch = useAppDispatch();
  const [results, setResults] = useState<
    string | number | boolean | (string | number | boolean)[] | undefined
  >([]);
  const { selectedTeam } = useAppSelector((state) => state.teams);
  const [disable, setDisable] = useState<boolean>(false);

  const [focusId, setFocusId] = useState<string>("");

  useEffect(() => {
    if (selectedTeam?.chatusers && selectedTeam?.chatusers.length > 0) {
      const _results = [];
      for (const _chatuser of selectedTeam.chatusers) {
        _results.push(_chatuser.user);
      }
      setResults(_results);
    }
  }, []);

  const getTypeUpdate = async () => {
    try {
      setDisable(true);
      if (selectedTeam) {
        let _chatusers: UserSettings[] = [];
        const _v:
          | string
          | number
          | boolean
          | (string | number | boolean)[]
          | undefined = results;
        if (typeof _v === "object" && list) {
          for (const _id of _v) {
            const _userToAdd = list.find((_user: IUser) => _user._id === _id);
            const _chatuser = selectedTeam?.chatusers?.find(
              (_chatU: UserSettings) => _chatU.user === _id
            );
            if (
              _chatuser &&
              _userToAdd &&
              _userToAdd?._id === _chatuser?.user
            ) {
              _chatusers.push({
                ..._chatuser,
                limit: null,
                activechats:
                  typeof _chatuser.activechats !== "undefined"
                    ? _chatuser.activechats
                    : 0,
                lastassigned:
                  typeof _chatuser.lastassigned !== "undefined"
                    ? _chatuser.lastassigned
                    : false,
              });
            } else {
              _chatusers.push({
                user: _userToAdd?._id || "",
                limit: null,
                activechats: 0,
                lastassigned: false,
              });
            }
          }
          _chatusers = removeDuplicates(_chatusers, "user");
          const { payload } = await dispatch(
            updateTeam({
              _team: {
                ...selectedTeam,
                officehour: selectedTeam?.officehour?._id,
                survey: selectedTeam?.survey?._id,
                chatusers: _chatusers,
              },
            })
          );
          if (typeof payload?._id !== "undefined") {
            dispatch(
              reselectTeam({
                ...selectedTeam,
                chatusers: _chatusers,
              })
            );
          }
          setShowModal(false);
        }
      }
      setDisable(false);
      setShowModal(false);
    } catch (error) {
      // console.log(error);
    }
  };

  const getType = () => {
    const optionsUsers: object[] = [];
    if (list) {
      list.map((user: IUser) =>
        optionsUsers.push({
          key: user._id,
          text: user.name,
          value: user._id,
          content: (
            <div className="flex">
              <h1 className="mr-2">{user?.name}</h1>
              {typeof user?.email !== "undefined" ? (
                <h1 className="text-gray-text">({user?.email})</h1>
              ) : null}
            </div>
          ),
        })
      );
    }
    let valuesUsers: string[] = [];
    if (selectedTeam?.chatusers) {
      selectedTeam?.chatusers.map((user: UserSettings) =>
        valuesUsers.push(user.user)
      );
      valuesUsers = removeDuplicates(valuesUsers);
    }
    return (
      <>
        <div className="mt-12" />
        <Dropdown
          id="dropdown-chat-agents"
          label="Agentes"
          fluid
          search
          selection
          multiple
          options={optionsUsers}
          defaultValue={valuesUsers}
          placeholder="Agentes"
          onFocus={() => {
            setFocusId("dropdown-chat-agents");
          }}
          onBlur={() => {
            setFocusId("");
          }}
          onChange={(e, { value }) => {
            setResults(value);
          }}
        />
        <div className="mb-12" />
      </>
    );
  };

  return (
    <div id="modal" className="fixed inset-0 z-[200]">
      <div
        role="presentation"
        className="fixed inset-0 w-full h-full bg-black opacity-80"
        onClick={() => {
          setShowModal(false);
        }}
      />
      <div className="flex items-center justify-center min-h-screen">
        <div className="relative bg-white flex flex-col justify-center rounded-md shadow-lg py-8 px-10 w-4/5 sm:w-2/4 md:max-w-[600px]">
          <h1 className="flex items-center justify-center font-semibold">
            <i className="las la-edit text-4xl" />
            <p className="text-2xl ml-3">{title}</p>
          </h1>
          <Form
            id="modal-relation"
            className="mx-auto inline-table w-[530px] overflow-y-scroll overflow-x-hidden px-4"
            // className={`mx-auto ${
            //   focusId !== "" ? "min-h-[250px]" : ""
            // } w-[530px] overflow-y-scroll overflow-x-hidden px-4`}
          >
            {getType()}
          </Form>

          <Form className="mx-auto w-[530px] px-4">
            <ActionButtonsModal
              cancelAction={() => setShowModal(false)}
              submitAction={() => getTypeUpdate()}
              disabled={disable}
            />
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ChatAgentModal;
