/* eslint-disable import/no-duplicates */
import React, { useEffect, useRef, useState } from "react";
import { createStaticRanges, DateRangePicker, Range } from "react-date-range";
import {
  format,
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
  // differenceInCalendarDays,
  startOfYear,
  endOfYear,
  addYears,
} from "date-fns";
import { pt } from "date-fns/locale";
import { Form } from "semantic-ui-react";
import ActionButtonsModal from "./Modals/ActionButtonsModal.component";
import { IFilterDashboard } from "../../slices/dashboard.slice";
import useWindowDimensions from "../../hooks/useWindowDimensions";

const CustomDateRangePicker = ({
  filter,
  setFilter,
  range,
  setRange,
}: {
  filter: IFilterDashboard;
  // setFilter: React.Dispatch<React.SetStateAction<IFilterDashboard>>;
  setFilter: (props: any) => void;
  range: Range;
  setRange: React.Dispatch<React.SetStateAction<Range>>;
}) => {
  const { width } = useWindowDimensions();
  const defineds = {
    startOfWeek: startOfWeek(new Date()),
    endOfWeek: endOfWeek(new Date()),
    startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
    endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
    startOfToday: startOfDay(new Date()),
    startOfLastSevenDay: startOfDay(addDays(new Date(), -7)),
    startOfLastThirtyDay: startOfDay(addDays(new Date(), -30)),
    startOfLastNintyDay: startOfDay(addDays(new Date(), -90)),
    endOfToday: endOfDay(new Date()),
    startOfYesterday: startOfDay(addDays(new Date(), -1)),
    endOfYesterday: endOfDay(addDays(new Date(), -1)),
    startOfMonth: startOfMonth(new Date()),
    endOfMonth: endOfMonth(new Date()),
    startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
    endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
    startOfYear: startOfYear(new Date()),
    endOfYear: endOfYear(new Date()),
    startOflastYear: startOfYear(addYears(new Date(), -1)),
    endOflastYear: endOfYear(addYears(new Date(), -1)),
  };

  const [disable, setDisable] = useState<boolean>(false);
  const staticRangeHandler = {
    range,
    isSelected(selectedRange: Range) {
      if (
        selectedRange.startDate &&
        selectedRange.endDate &&
        range.startDate &&
        range.endDate
      )
        return (
          isSameDay(selectedRange.startDate, range.startDate) &&
          isSameDay(selectedRange.endDate, range.endDate)
        );
      return false;
    },
  };

  // open close
  const [open, setOpen] = useState(false);

  // get the target element to toggle
  const refOne = useRef<null | HTMLDivElement>(null);

  const escFunction = (event: KeyboardEvent) => {
    if (event && event.key === "Escape") {
      event.preventDefault();
      event.stopPropagation();
      setOpen(false);
    }
  };

  const clickOutsideFunction = (event: MouseEvent) => {
    if (
      refOne.current &&
      !refOne.current.contains(event.target as HTMLElement)
    ) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", escFunction, true);
    document.addEventListener("click", clickOutsideFunction, true);

    return () => {
      document.removeEventListener("keydown", escFunction, true);
      document.removeEventListener("click", clickOutsideFunction, true);
    };
  }, []);

  const staticRanges = createStaticRanges([
    {
      isSelected() {
        if (range.startDate && range.endDate)
          return (
            isSameDay(range.startDate, defineds.startOfToday) &&
            isSameDay(range.endDate, defineds.endOfToday)
          );
        return false;
      },
      label: "Hoje",
      range: () => ({
        startDate: defineds.startOfToday,
        endDate: defineds.endOfToday,
      }),
    },
    {
      isSelected() {
        if (range.startDate && range.endDate)
          return (
            isSameDay(range.startDate, defineds.startOfYesterday) &&
            isSameDay(range.endDate, defineds.endOfYesterday)
          );
        return false;
      },
      label: "Ontem",
      range: () => ({
        startDate: defineds.startOfYesterday,
        endDate: defineds.endOfYesterday,
      }),
    },
    {
      isSelected() {
        if (range.startDate && range.endDate)
          return (
            isSameDay(range.startDate, defineds.startOfLastSevenDay) &&
            isSameDay(range.endDate, defineds.endOfToday)
          );
        return false;
      },
      label: "Últimos 7 dias",
      range: () => ({
        endDate: defineds.endOfToday,
        startDate: defineds.startOfLastSevenDay,
      }),
    },
    {
      isSelected() {
        if (range.startDate && range.endDate)
          return (
            isSameDay(range.startDate, defineds.startOfMonth) &&
            isSameDay(range.endDate, defineds.endOfMonth)
          );
        return false;
      },
      label: "Este mês",
      range: () => ({
        startDate: defineds.startOfMonth,
        endDate: defineds.endOfMonth,
      }),
    },
    {
      isSelected() {
        if (range.startDate && range.endDate)
          return (
            isSameDay(range.startDate, defineds.startOfLastNintyDay) &&
            isSameDay(range.endDate, defineds.endOfToday)
          );
        return false;
      },
      label: "Últimos 3 meses",
      range: () => ({
        startDate: defineds.startOfLastNintyDay,
        endDate: defineds.endOfToday,
      }),
    },
  ]);

  const getLabel = () => {
    if (!range.startDate || !range.endDate) return "";
    if (
      isSameDay(range.startDate, defineds.startOfToday) &&
      isSameDay(range.endDate, defineds.endOfToday)
    ) {
      return "Hoje";
    }
    if (
      isSameDay(range.startDate, defineds.startOfYesterday) &&
      isSameDay(range.endDate, defineds.endOfYesterday)
    ) {
      return "Ontem";
    }
    if (
      isSameDay(range.startDate, defineds.startOfLastSevenDay) &&
      isSameDay(range.endDate, defineds.endOfToday)
    ) {
      return "Últimos 7 dias";
    }
    if (
      isSameDay(range.startDate, defineds.startOfMonth) &&
      isSameDay(range.endDate, defineds.endOfMonth)
    ) {
      return "Este mês";
    }
    if (
      isSameDay(range.startDate, defineds.startOfLastNintyDay) &&
      isSameDay(range.endDate, defineds.endOfToday)
    ) {
      return "Últimos 3 meses";
    }
    return `${format(range.startDate, "dd/MM/yyyy")} - ${format(
      range.endDate,
      "dd/MM/yyyy"
    )}`;
  };

  return (
    // <div className="w-full md:w-full mx-2 group-hover:cursor-pointer">
    // <div className="grow basis-[150px] group-hover:cursor-pointer">
    <Form
      className={`${
        width > 500 ? "mt-0" : "mt-2"
      } lg:w-[235px] group-hover:cursor-pointer`}
    >
      <Form.Input
        label="Período"
        placeholder="Período"
        value={getLabel()}
        readOnly
        onClick={() => setOpen((prevState) => !prevState)}
      />

      <div ref={refOne}>
        {open && (
          <div id="modal" className="fixed inset-0 z-[200] overflow-y-auto">
            <div
              role="presentation"
              className="fixed inset-0 w-full h-full bg-black opacity-80"
              onClick={() => setOpen(false)}
            />
            <div className="flex items-center justify-center min-h-screen">
              <div className="relative flex-container bg-white flex flex-col justify-center rounded-md max-h-[675px] shadow-lg py-8 px-10 w-4/5 sm:w-2/4 md:max-w-[600px]">
                <h1 className="header flex items-center justify-center font-semibold">
                  <i className="las la-filter text-4xl" />
                  <p className="text-2xl ml-3">Filtrar período</p>
                </h1>
                <DateRangePicker
                  className="widget content overflow-y-scroll border my-3 flex flex-col w-full lg:flex-row max-w-[500px] self-center"
                  editableDateInputs
                  onChange={(item: any) => {
                    if (
                      !item ||
                      !item?.selection ||
                      (!item?.selection?.startDate && !item?.selection?.endDate)
                    ) {
                      setRange({
                        startDate: item?.selection?.startDate,
                        key: range.key,
                        endDate: item?.selection?.endDate,
                      });
                    } else if (
                      !item.selection?.startDate &&
                      item.selection.endDate
                    ) {
                      setRange({
                        startDate: range.startDate,
                        key: range.key,
                        endDate: item?.selection?.endDate,
                      });
                    } else if (
                      item?.selection?.startDate &&
                      !item?.selection?.endDate
                    ) {
                      setRange({
                        startDate: item.selection.startDate,
                        key: range.key,
                        endDate: range.endDate,
                      });
                    } else if (
                      item?.selection?.startDate &&
                      item?.selection?.endDate
                    ) {
                      setRange({
                        startDate: item.selection.startDate,
                        key: range.key,
                        endDate: item.selection.endDate,
                      });
                    }
                  }}
                  fixedHeight
                  moveRangeOnFirstSelection={false}
                  locale={pt}
                  dateDisplayFormat="dd/MM/yyyy"
                  startDatePlaceholder="Data inicial"
                  endDatePlaceholder="Data final"
                  ranges={[range]}
                  rangeColors={["#333333"]}
                  staticRanges={staticRanges.map((_range) => ({
                    ...staticRangeHandler,
                    ..._range,
                  }))}
                  months={1}
                  direction="horizontal"
                />
                <div className="footer">
                  <ActionButtonsModal
                    cancelAction={() => {
                      setOpen(false);
                      setDisable(false);
                    }}
                    submitIcon="las la-filter"
                    submitLabel="Filtrar"
                    submitAction={() => {
                      setFilter({
                        ...filter,
                        period: {
                          startdate: format(
                            range.startDate || new Date(),
                            "yyyy-MM-dd"
                          ),
                          enddate: format(
                            range.endDate || new Date(),
                            "yyyy-MM-dd"
                          ),
                        },
                      });
                      setOpen(false);
                    }}
                    disabled={disable}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Form>
  );
};

export default CustomDateRangePicker;
