import React, { useState } from "react";
import { Form } from "semantic-ui-react";
import { useAppSelector } from "../../../hooks/redux/hooks";
import useCampaigns from "../../../hooks/features/useCampaigns";
import DefaultModal from "./Default.modal";

const CampaignModal = ({
  setShowModal,
}: {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { footer } = useCampaigns();
  const { selectedLocalCampaign } = useAppSelector((state) => state.campaigns);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [name, setName] = useState<string>("");

  return (
    <DefaultModal
      header={{ title: "Iniciar campanha", icon: "las la-bullhorn" }}
      content={
        <Form.Input
          autoComplete="off"
          className="w-full md:w-full"
          label="Nome"
          fluid
          required
          defaultValue={name}
          onChange={(e, { value }) => {
            setName(value);
          }}
          error={
            typeof selectedLocalCampaign?.name === "undefined" ||
            selectedLocalCampaign?.name.length < 2
          }
          type="text"
          name="name"
          variant="outlined"
          placeholder="Nome"
        />
      }
      buttons={{
        cancelAction: () => {
          setShowModal(false);
          setDisabled(false);
        },
        disabled: disabled || name.length < 2,
        submitAction: async () => {
          setShowModal(false);
          await footer.start(setDisabled, name);
        },
      }}
    />
  );
};

export default CampaignModal;
