import React from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";

const ImportedIcon = ({ short }: { short?: boolean }) => {
  const { t } = useTranslation();
  return (
    <div className="flex ml-2 items-center">
      <Icon
        size="small"
        className="las la-check-circle green"
        title={t("imported.agidesk")}
      />
      {!short ? (
        <p className="text-[13px] hidden md:flex text-green">
          {t("imported.agidesk")}
        </p>
      ) : null}
    </div>
  );
};

ImportedIcon.defaultProps = {
  short: false,
};

export default ImportedIcon;
