import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { officehoursService } from "../services";
import { AsyncState } from "../shared/models/interfaces/asyncstate.interface";
import { ITeam } from "./teams.slice";
import { IUser } from "./users.slice";
import customToast from "../shared/utils/customToast";
import { IToastType } from "../shared/models/types/Toast.type";

export interface SettingOfficehours {
  _id?: string;
  active: boolean;
  weekday: string;
  start: string;
  finish: string;
}
export interface IOfficehour {
  _id?: string;
  tenant?: string;
  deleted?: boolean;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
  created_by?: IUser | null;
  updated_by?: IUser | null;
  deleted_by?: IUser | null;
  name?: string;
  teams?: ITeam[] | any;
  enablelink?: boolean;
  active?: boolean;
  officehours?: SettingOfficehours[];
  default?: boolean;
}

export type Officehours = IOfficehour[];

export interface INewOfficehour {
  name: string;
}
interface OfficehoursState extends AsyncState {
  isLoadingOfficehours: boolean;
  isLoadingDropdownOfficehours: boolean;
  isCreatingOfficehours: boolean;
  isRemoving: boolean;
  officehours: Officehours;
  totalOfficehours: number;
  selectedOfficehour: IOfficehour | null;
}

const initialState: OfficehoursState = {
  officehours: [],
  totalOfficehours: 0,
  selectedOfficehour: null,
  isRemoving: false,
  isLoadingOfficehours: false,
  isLoadingDropdownOfficehours: false,
  isCreatingOfficehours: false,
  isSuccess: false,
  isError: false,
};

export interface IFilterOfficehour {
  skip: number;
  limit: number;
  filter: string;
  deleted: boolean;
  allStatus?: boolean;
}

export const getSearchOfficehours = createAsyncThunk(
  "officehours/search",
  async (filterOfficehour: IFilterOfficehour, thunkAPI) => {
    try {
      return await officehoursService.search(filterOfficehour);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// export const getOfficehours = createAsyncThunk(
//   "officehours",
//   async (_groupLimit: IGroupLimit, thunkAPI) => {
//     try {
//       return await officehoursService.officehours(_groupLimit);
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error);
//     }
//   }
// );

export const selectOfficehour = createAsyncThunk(
  "officehours/selectOfficehour",
  async ({ _id }: { _id: string }, thunkAPI) => {
    try {
      return await officehoursService.officehour({ _id });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateOfficehour = createAsyncThunk(
  "officehours/update",
  async (
    { _officehour, noToast }: { _officehour: IOfficehour; noToast?: boolean },
    thunkAPI
  ) => {
    try {
      return await officehoursService.update({ _officehour });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteOfficehour = createAsyncThunk(
  "officehours/delete",
  async (ids: string[], thunkAPI) => {
    try {
      return await officehoursService.deleteOfficehours({ ids });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createOfficehour = createAsyncThunk(
  "officehours/create",
  async (newOfficehour: INewOfficehour, thunkAPI) => {
    try {
      return await officehoursService.create(newOfficehour);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const officehoursSlice = createSlice({
  name: "officehours",
  initialState,
  reducers: {
    logout() {
      return initialState;
    },
    selectOfficehours(state, action: PayloadAction<IOfficehour[]>) {
      state.officehours = action.payload || [];
    },
    selectTotalOfficehours(state, action: PayloadAction<number>) {
      state.totalOfficehours = action.payload || 0;
    },
    reselectOfficehour(state, action: PayloadAction<IOfficehour | null>) {
      state.selectedOfficehour = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // OFFICEHOURS
      .addCase(getSearchOfficehours.pending, (state, action) => {
        if (action.meta.arg.limit === 10) {
          state.isLoadingOfficehours = true;
        }
        state.isLoadingDropdownOfficehours = true;
      })
      .addCase(getSearchOfficehours.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.officehours = action.payload.results || [];
        // if (state.totalOfficehours <= action.payload.count)
        state.totalOfficehours = action.payload.count;
        state.isLoadingOfficehours = false;
        state.isLoadingDropdownOfficehours = false;
      })
      .addCase(getSearchOfficehours.rejected, (state) => {
        state.isError = true;
        state.officehours = [];
        state.isLoadingOfficehours = false;
        state.isLoadingDropdownOfficehours = false;
      })
      // SHOW CUSTOMER
      .addCase(selectOfficehour.pending, (state) => {
        state.isLoadingOfficehours = true;
      })
      .addCase(selectOfficehour.fulfilled, (state, action) => {
        state.isSuccess = true;
        if (action.payload?._id) {
          const _officehours: IOfficehour = action.payload;
          _officehours?.officehours?.forEach((officehour, index) => {
            officehour._id = index.toString();
            return officehour;
          });
          state.selectedOfficehour = _officehours;
        }
        state.isLoadingOfficehours = false;
      })
      .addCase(selectOfficehour.rejected, (state) => {
        state.isError = true;
        state.selectedOfficehour = null;
        state.isLoadingOfficehours = false;
      })
      // UPDATE CUSTOMER
      .addCase(updateOfficehour.pending, (/* state */) => {
        // state.isLoadingOfficehours = true;
      })
      .addCase(updateOfficehour.fulfilled, (state, action) => {
        if (
          action?.payload !== null &&
          typeof action?.payload?._id !== "undefined"
        ) {
          state.isSuccess = true;
          if (!action.meta.arg.noToast || action.meta.arg.noToast !== true) {
            customToast({
              type: IToastType.SUCCESS,
              message: `Alteração salva com sucesso!`,
            });
          }
        } else if (
          typeof action.payload?.message === "string" &&
          action.payload.message.includes("Error on delete default office hour")
        ) {
          if (!action.meta.arg.noToast || action.meta.arg.noToast !== true) {
            customToast({
              type: IToastType.WARNING,
              message: "Horário definido como padrão!",
            });
          }
        } else {
          if (!action.meta.arg.noToast || action.meta.arg.noToast !== true) {
            customToast({
              type: IToastType.ERROR,
              message: `Algo deu errado!`,
            });
          }
          state.isError = true;
        }
        state.isLoadingOfficehours = false;
      })
      .addCase(updateOfficehour.rejected, (state) => {
        state.isError = true;
        state.selectedOfficehour = null;
        state.isLoadingOfficehours = false;
      })
      .addCase(createOfficehour.pending, (state) => {
        state.isCreatingOfficehours = true;
      })
      .addCase(createOfficehour.fulfilled, (state, action) => {
        if (typeof action?.payload?._id !== "undefined") {
          state.isSuccess = true;
          customToast({
            type: IToastType.SUCCESS,
            message: `Horário criado com sucesso!`,
          });
          const newState = state.officehours;
          newState.push(action.payload);
          state.officehours = newState;
        } else {
          state.isError = true;
          if (
            typeof action?.payload === "string" &&
            action?.payload.includes("duplicate")
          ) {
            customToast({
              type: IToastType.ERROR,
              message: `Opa! Já existe um horário com esse nome!`,
            });
          } else {
            customToast({
              type: IToastType.ERROR,
              message: `Algo deu errado! Tente novamente.`,
            });
          }
        }
        state.isCreatingOfficehours = false;
      })
      .addCase(createOfficehour.rejected, (state) => {
        state.isError = true;
        state.isCreatingOfficehours = false;
      })
      .addCase(deleteOfficehour.pending, (state) => {
        state.isRemoving = true;
      })
      .addCase(deleteOfficehour.fulfilled, (state, action) => {
        if (
          action.payload !== null &&
          typeof action.payload !== "undefined" &&
          typeof action.payload?.message !== "string"
        ) {
          state.isSuccess = true;
          customToast({
            type: IToastType.SUCCESS,
            message: `Remoção realizada com sucesso!`,
          });
        } else if (
          typeof action.payload?.message === "string" &&
          action.payload.message.includes("Error on delete default office hour")
        ) {
          customToast({
            type: IToastType.WARNING,
            message: "Horário definido como padrão!",
          });
        } else {
          state.isError = true;
          customToast({
            type: IToastType.ERROR,
            message: `Algo deu errado!`,
          });
        }
        state.isRemoving = false;
      })
      .addCase(deleteOfficehour.rejected, (state) => {
        state.isError = true;
        state.isRemoving = false;
      });
  },
});

export default officehoursSlice.reducer;
export const {
  logout,
  reselectOfficehour,
  selectOfficehours,
  selectTotalOfficehours,
} = officehoursSlice.actions;
