/* eslint-disable prefer-regex-literals */
const useAbrevName = (name: string) => {
  const removeAccents = (text: string) => {
    let _text = text;
    _text = _text.toLowerCase().trim();
    _text = _text.replace(new RegExp("[ÁÀÂÃ]", "gi"), "a");
    _text = _text.replace(new RegExp("[ÉÈÊ]", "gi"), "e");
    _text = _text.replace(new RegExp("[ÍÌÎ]", "gi"), "i");
    _text = _text.replace(new RegExp("[ÓÒÔÕ]", "gi"), "o");
    _text = _text.replace(new RegExp("[ÚÙÛ]", "gi"), "u");
    _text = _text.replace(new RegExp("[Ç]", "gi"), "c");
    return _text;
  };

  const specialChar = /[^A-Za-zà-úÀ-Ú0-9 ]/gi;

  let _t = name?.replace(specialChar, "") || "";
  _t = removeAccents(_t);
  const _tsplited = _t.split(" ");
  const _first = _tsplited[0][0];
  const _second =
    _tsplited.length > 1 ? _tsplited[_tsplited.length - 1][0] : _tsplited[0][1];

  if (typeof _first !== "undefined") {
    _t = _first;
  }
  if (typeof _second !== "undefined") {
    _t = _t.concat(_second);
  }
  if (_t.length === 0) {
    _t = "--";
  }
  return _t.toUpperCase() === "CU" ? "CO" : _t.toUpperCase();
};

export default useAbrevName;
