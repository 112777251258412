import React from "react";
import { DropdownProps, SemanticWIDTHS } from "semantic-ui-react";
import Dropdown from "../Dropdown.component";
import { ddOptions } from "../../models/interfaces/dropdownOptions.interface";

const DaysDropdown = ({
  id,
  className,
  disabled,
  defaultValue,
  onChange,
  fluid,
  required,
  width,
}: {
  id?: string;
  className?: string;
  disabled?: boolean;
  defaultValue?: string;
  onChange?:
    | ((
        event: React.SyntheticEvent<HTMLElement, Event>,
        data: DropdownProps
      ) => void)
    | undefined;
  fluid?: boolean;
  required?: boolean;
  width?: SemanticWIDTHS;
}) => {
  const daysOptions: ddOptions[] = [
    {
      key: "-1",
      text: "Todos os dias",
      value: "-1",
    },
  ];
  daysOptions.push(
    ...Array.from({ length: 31 }, (_, i) => {
      const _opt = {
        key: i.toString(),
        text: `${i < 9 ? "0" : ""}${(i + 1).toString()}`,
        value: (i + 1).toString(),
      };
      return _opt;
    })
  );

  return (
    <Dropdown
      id={id || "dropdown-days"}
      className={className || "w-full md:w-full"}
      disabled={disabled || false}
      placeholder="Dia"
      label="Dia"
      fluid={fluid}
      width={width}
      required={required}
      selection
      search
      defaultValue={defaultValue}
      onChange={onChange}
      options={daysOptions}
    />
  );
};

DaysDropdown.defaultProps = {
  id: undefined,
  className: undefined,
  disabled: undefined,
  defaultValue: undefined,
  onChange: undefined,
  fluid: undefined,
  required: undefined,
  width: undefined,
};

export default DaysDropdown;
