/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import {
  Navigate,
  /* Outlet, */ Route,
  Routes,
  useNavigate,
} from "react-router-dom";

// import { Segment } from "semantic-ui-react";
import { PrivateRoute, PublicRoute } from ".";
import Conversation from "../features/chats/components/Conversation.component";
import { useAppSelector } from "../hooks/redux/hooks";
import {
  AuthLayoutPage,
  LoginPage,
  PrivacyPage,
  PublichoursPage,
} from "../pages";
import LoadingDots from "../shared/components/LoadingDots.component";
import { UserType } from "../slices/users.slice";
import Unavailable from "../widget/Unavailable.component";
import Button from "../shared/components/Buttons/Button.component";

const WidgetContactRoute = ({
  toggleOpenClose,
  widgetType,
  disable,
  setDisable,
  onClick,
}: {
  disable: boolean;
  setDisable: React.Dispatch<React.SetStateAction<boolean>>;
  toggleOpenClose: boolean;
  widgetType: UserType;
  onClick: () => Promise<void>;
}) => {
  const navigate = useNavigate();
  const { selectedSettings, isLoadingSettings } = useAppSelector(
    (state) => state.settings
  );
  const [toggleList, setToggleList] = useState<string>("mine");
  const [isOffline, setIsOffline] = useState<boolean>(false);

  useEffect(() => {
    //
  }, [isLoadingSettings]);

  if (isLoadingSettings) {
    return (
      <div
        className={`shadow-md bg-white top-0 bottom-0 right-0 h-full rounded-[3px] overflow-x-hidden fixed 
    ${!toggleOpenClose ? "hidden" : "flex flex-col w-full"}`}
      >
        <LoadingDots
          medium
          className="flex justify-center items-center flex-1"
        />
        ;
      </div>
    );
  }

  const errorScreen = () => {
    return (
      <AuthLayoutPage widgetType={widgetType}>
        <div className="flex flex-col items-center relative">
          <p className="text-[20px] font-semibold text-center">
            Ops! Algo inesperado aconteceu.
            <br />
            Tente novamente!
          </p>
          <Button
            minWidth
            extraClass="m-[25px]"
            label="Tentar novamente"
            onClick={() => {
              onClick();
              navigate("/login");
            }}
            icon="las la-arrow-right"
          />
        </div>
      </AuthLayoutPage>
    );
  };

  return (
    <div
      className={`shadow-md bg-white top-0 bottom-0 right-0 h-full rounded-[3px] overflow-x-hidden fixed 
      ${!toggleOpenClose ? "hidden" : "flex flex-col w-full"}`}
    >
      {widgetType === UserType.CONTACT && selectedSettings ? (
        selectedSettings?.isopen &&
        typeof selectedSettings?.defaultagent?._id !== "undefined" &&
        typeof selectedSettings?.defaultteam !== "undefined" &&
        selectedSettings.defaultteam.length > 0 ? (
          <Routes>
            <Route element={<PublicRoute widgetType={widgetType} />}>
              <Route
                path="/login"
                element={
                  <LoginPage
                    widgetType={widgetType}
                    isOffline={isOffline}
                    setIsOffline={setIsOffline}
                  />
                }
              />
              <Route
                path="/privacy"
                element={<PrivacyPage widgetType={widgetType} />}
              />
              <Route
                path="/schedule/officehours/:tenant"
                element={<PublichoursPage />}
              />
            </Route>
            <Route element={<PrivateRoute />}>
              <Route
                path="/"
                element={
                  <div className="overflow-x-hidden bg-white border-r-0 w-[93%] transition-all relative flex flex-col my-auto">
                    <h2 className="my-4 text-center text-[24px] font-extrabold text-gray-900">
                      Aguarde um instante!
                    </h2>
                    <LoadingDots className="flex items-center justify-center mt-2 mb-8" />
                  </div>
                }
              />
              <Route
                index
                path="/chat/:_id"
                element={
                  <Conversation
                    disable={disable}
                    setDisable={setDisable}
                    widgetType={widgetType}
                    toggleList={toggleList}
                    setToggleList={setToggleList}
                  />
                }
              />
              <Route
                path="/unavailableagents"
                element={
                  <Unavailable
                    widgetType={widgetType}
                    isOffline={isOffline}
                    setIsOffline={setIsOffline}
                  />
                }
              />
            </Route>
            <Route path="/*" element={<Navigate to="/login" />} />
            <Route path="/error" element={errorScreen()} />
          </Routes>
        ) : (
          <Unavailable
            widgetType={widgetType}
            isOffline={isOffline}
            setIsOffline={setIsOffline}
          />
        )
      ) : (
        errorScreen()
      )}
    </div>
  );
};

export default WidgetContactRoute;
