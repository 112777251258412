import React from "react";
import Button from "../Buttons/Button.component";

const ActionButtonsModal = ({
  cancelAction,
  submitAction,
  disabled,
  submitLabel,
  submitIcon,
  cancelLabel,
  cancelIcon,
  sidebar,
}: {
  cancelAction(): void;
  submitAction(): void;
  disabled?: boolean;
  submitLabel?: string;
  submitIcon?: string;
  cancelLabel?: string;
  cancelIcon?: string;
  sidebar?: boolean;
}) => {
  return (
    <div
      className={`flex mx-auto items-center ${
        sidebar ? "justify-between" : "justify-center"
      } w-full mt-2`}
    >
      <Button
        minWidth
        label={cancelLabel || "Cancelar"}
        icon={cancelIcon || "las la-arrow-left"}
        onClick={cancelAction}
        inverted
      />
      <div className="mr-4" />
      <Button
        minWidth
        label={submitLabel || "Confirmar"}
        icon={submitIcon || "las la-save"}
        onClick={submitAction}
        disabled={disabled}
      />
    </div>
  );
};

ActionButtonsModal.defaultProps = {
  submitLabel: undefined,
  submitIcon: undefined,
  cancelLabel: undefined,
  cancelIcon: undefined,
  disabled: false,
  sidebar: false,
};

export default ActionButtonsModal;
