// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useCallback } from "react";
import { useLocation, useParams } from "react-router-dom";

const useGetIdPage = () => {
  const params = useParams();
  const location = useLocation();

  const getId = useCallback(() => {
    if (params && typeof params._id !== "undefined") return params._id;
    return location.pathname.split("/").slice(-1)[0];
  }, []);

  return { getId };
};

export default useGetIdPage;
