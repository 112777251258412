import React from "react";
import { Form } from "semantic-ui-react";
import { useAppDispatch, useAppSelector } from "../../hooks/redux/hooks";
import {
  reselectSettings,
  SettingLgpd,
  updateSettings,
} from "../../slices/settings.slice";
import Toggle from "../../shared/components/Toggle.component";
import { RoleUser } from "../../slices/users.slice";

const LgpdSection = () => {
  const dispatch = useAppDispatch();
  const { selectedSettings } = useAppSelector((state) => state.settings);

  const saveLGPD = async (value: boolean) => {
    const _lgpd: SettingLgpd[] = [
      {
        active: value,
        roles: [RoleUser.ADMINISTRATOR],
        field: "code",
      },
    ];
    const { payload } = await dispatch(
      updateSettings({
        _settings: {
          ...selectedSettings,
          officehour: selectedSettings?.officehour?._id,
          offlinemessage: selectedSettings?.offlinemessage?._id,
          company: {
            ...selectedSettings?.company,
            avatar: selectedSettings?.company?.avatar?._id,
          },
          defaultbot: {
            ...selectedSettings?.defaultbot,
            avatar: selectedSettings?.defaultbot?.avatar?._id,
          },
          defaultagent: selectedSettings?.defaultagent?._id,
          defaultteam: selectedSettings?.defaultteam?.map((_t) => {
            if (typeof _t === "string") return _t;
            return _t._id;
          }),
          lgpd: _lgpd,
        },
      })
    );
    if (payload) {
      dispatch(
        reselectSettings({
          ...selectedSettings,
          lgpd: _lgpd,
        })
      );
    }
  };

  return (
    <Form>
      <Form.Group widths="equal">
        <Toggle
          containerClass="flex w-full ml-2"
          checked={
            typeof selectedSettings?.lgpd !== "undefined" &&
            selectedSettings?.lgpd.length > 0
              ? selectedSettings?.lgpd[0].active
              : false
          }
          mainClass="items-center flex"
          onChange={(_, { checked }) => {
            if (typeof checked !== "undefined") {
              saveLGPD(checked);
            }
          }}
          labelClass="ml-4"
          label="Exibir CPF apenas para administradores"
        />
      </Form.Group>
    </Form>
  );
};

export default LgpdSection;
