import React from "react";
import { DropdownProps, SemanticWIDTHS } from "semantic-ui-react";
import Dropdown from "../Dropdown.component";
import { ddOptions } from "../../models/interfaces/dropdownOptions.interface";

const YearsDropdown = ({
  id,
  className,
  disabled,
  defaultValue,
  onChange,
  fluid,
  required,
  width,
  upward,
}: {
  id?: string;
  className?: string;
  upward?: boolean;
  disabled?: boolean;
  defaultValue?: string;
  onChange?:
    | ((
        event: React.SyntheticEvent<HTMLElement, Event>,
        data: DropdownProps
      ) => void)
    | undefined;
  fluid?: boolean;
  required?: boolean;
  width?: SemanticWIDTHS;
}) => {
  const yearsOptions: ddOptions[] = [
    {
      key: "-1",
      text: "Todos os anos",
      value: "-1",
    },
  ];
  yearsOptions.push(
    ...Array.from({ length: 15 }, (_, i) => {
      const _opt = {
        key: i.toString(),
        text: (i + 2023).toString(),
        value: (i + 2023).toString(),
      };
      return _opt;
    })
  );

  return (
    <Dropdown
      id={id || "dropdown-years"}
      className={className || "w-full md:w-full"}
      disabled={disabled || false}
      placeholder="Ano"
      label="Ano"
      fluid={fluid}
      upward={upward}
      width={width}
      required={required}
      selection
      search
      defaultValue={defaultValue}
      onChange={onChange}
      options={yearsOptions}
    />
  );
};

YearsDropdown.defaultProps = {
  id: undefined,
  className: undefined,
  disabled: undefined,
  defaultValue: undefined,
  onChange: undefined,
  fluid: undefined,
  required: undefined,
  width: undefined,
  upward: undefined,
};

export default YearsDropdown;
