import { AuthTypes } from "../slices/settings.slice";
import instances from "./instances";

const create = async () => {
  try {
    const data = {
      authtypes: [
        {
          active: false,
          source: AuthTypes.AGIDESK,
        },
        {
          active: false,
          source: AuthTypes.WHATSAPP,
        },
      ],
      officehours: [
        {
          active: true,
          weekday: "monday",
          start: "08:00",
          finish: "18:00",
        },
        {
          active: true,
          weekday: "tuesday",
          start: "08:00",
          finish: "18:00",
        },
        {
          active: true,
          weekday: "wednesday",
          start: "08:00",
          finish: "18:00",
        },
        {
          active: true,
          weekday: "thursday",
          start: "08:00",
          finish: "18:00",
        },
        {
          active: true,
          weekday: "friday",
          start: "08:00",
          finish: "18:00",
        },
      ],
      defaultteam: [],
      inactivetime: "23:59",
    };
    const response = await instances.instanceSettings.post("", data);
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const settings = async () => {
  try {
    const response = await instances.instanceSettings.get("");
    if (response.data.length) return response.data[0];
    return await create();
  } catch (error) {
    return null;
  }
};

const settingsWidget = async () => {
  try {
    const response = await instances.instanceSettings.get("/widget");
    return response.data[0];
    // return await create();
  } catch (error) {
    return null;
  }
};

const update = async ({ _settings }: { _settings: any }) => {
  try {
    const data = _settings;
    // delete data.users;
    const response = await instances.instanceSettings.patch(
      `${_settings?._id}`,
      data
    );
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const settingsService = {
  settings,
  settingsWidget,
  update,
  create,
};

export default settingsService;
