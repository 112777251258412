import React, { ReactNode } from "react";
import { Segment } from "semantic-ui-react";
import logo from "../assets/images/logo.png";
import { UserType } from "../slices/users.slice";

const AuthLayoutPage = ({
  children,
  widgetType,
  _public,
}: {
  children: ReactNode;
  widgetType?: UserType;
  _public?: boolean;
}) => {
  if (widgetType !== UserType.NONE) {
    return (
      <div
        className={`${
          widgetType === UserType.CONTACT ? "items-center" : ""
        } flex justify-center w-[450px] h-full flex-1 relative shadow-md overflow-y-auto`}
      >
        <div
          className={`${
            widgetType === UserType.CONTACT
              ? "h-full"
              : "items-center h-[600px]"
          } w-full absolute flex justify-center left-0 right-0`}
        >
          {widgetType === UserType.CONTACT ? (
            <div className="flex flex-col justify-center items-center">
              <img alt="" className="w-40 mb-2" src={logo} />
              <main className="w-[350px] mb-4">{children}</main>
            </div>
          ) : (
            <Segment className="flex flex-col justify-center items-center">
              <img alt="" className="w-40 mb-2" src={logo} />
              <main className="w-[350px]">{children}</main>
            </Segment>
          )}
        </div>
      </div>
    );
  }
  return (
    <Segment className="absolute flex items-center justify-center left-0 right-0">
      <div className="flex w-full flex-col justify-center items-center">
        <img alt="" className="w-40 mb-2" src={logo} />
        <main className={_public ? "w-[90%]" : ""}>{children}</main>
      </div>
    </Segment>
  );
};

AuthLayoutPage.defaultProps = {
  widgetType: UserType.NONE,
  _public: false,
};

export default AuthLayoutPage;
