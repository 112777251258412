/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FormEvent, useEffect, useState } from "react";
import { Divider, Form } from "semantic-ui-react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useInput from "../../../../hooks/useInput";
import {
  validateNameLength,
  validatePasswordLength,
} from "../../../../shared/utils/validation/length";
import validateEmail from "../../../../shared/utils/validation/email";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux/hooks";
import {
  agidesklogin,
  refreshLogout,
  reset,
  selectMethod,
} from "../../../../slices/auth.slice";
import LoadingDots from "../../../../shared/components/LoadingDots.component";
import TermsAndPolices from "../../../../shared/components/TermsAndPolices.component";
import { ILoginAgideskUser, UserType } from "../../../../slices/users.slice";
import FormButton from "../../../../shared/components/Buttons/FormButton.component";
import ErrorLabel from "./ErrorLabel.component";
import LinkButton from "../../../../shared/components/Buttons/LinkButton.component";
import CommonError from "./CommonError.component";
import ShowHideButton from "../../../../shared/components/Buttons/ShowHideButton.component";

const LoginAgideskForm = ({ widgetType }: { widgetType?: UserType }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/chat";
  const dispatch = useAppDispatch();
  const {
    isLoadingAuth,
    isSuccess,
    isAuthenticated,
    isAgideskError,
    tenantID,
    externalapp,
  } = useAppSelector((state) => state.auth);

  const {
    text: tenant,
    shouldDisplayError: tenantHasError,
    textChangeHandler: tenantChangeHandler,
    inputBlurHandler: tenantBlurHandler,
    clearHandler: tenantClearHandler,
  } = useInput(validateNameLength);
  const {
    text: email,
    shouldDisplayError: emailHasError,
    emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    clearHandler: emailClearHandler,
  } = useInput(validateEmail);
  const {
    text: password,
    shouldDisplayError: passwordHasError,
    textChangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
    clearHandler: passwordClearHandler,
  } = useInput(validatePasswordLength);

  const clearForm = () => {
    tenantClearHandler();
    emailClearHandler();
    passwordClearHandler();
  };

  const [showHide, setShowHide] = useState<boolean>(false);

  useEffect(() => {
    if (isSuccess) {
      dispatch(reset());
      clearForm();
    }
  }, [isSuccess, dispatch]);

  useEffect(() => {
    if (!isAuthenticated) return;
    navigate(from, { replace: true });
  }, [isAuthenticated]);

  const onSubmitHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if ((tenantHasError && !tenantID) || emailHasError || passwordHasError)
      return;
    if (
      (tenant.length === 0 && !tenantID) ||
      email.length === 0 ||
      password.length <= 5
    )
      return;

    const loginUser: ILoginAgideskUser = {
      tenant: !tenantID
        ? tenant.toLowerCase().trim()
        : tenantID.toLowerCase().trim(),
      username: email,
      password,
      externalapp: externalapp || undefined,
    };

    if (externalapp && externalapp?.length > 0) {
      dispatch(agidesklogin(loginUser));
    }
  };

  const goToCompany = async () => {
    dispatch(refreshLogout());
    localStorage.clear();
    navigate("/company");
  };

  const goToLogin = async () => {
    dispatch(selectMethod(null));
  };

  const handleShowHide = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault();
    setShowHide((prevState) => !prevState);
  };

  if (isLoadingAuth)
    return <LoadingDots className="flex justify-center items-center" />;
  return (
    <Form
      className="px-16 bg-white h-full rounded-xl flex flex-col"
      onSubmit={onSubmitHandler}
    >
      <Form.Input
        className="pt-2"
        label={t("form.label.tenant-agidesk")}
        value={tenantID || tenant}
        required
        readOnly={tenantID && tenantID.length > 0}
        onChange={!tenantID ? tenantChangeHandler : undefined}
        onBlur={!tenantID ? tenantBlurHandler : null}
        error={!tenantID ? tenantHasError : null}
        type="text"
        name="tenant"
        id="tenant"
        variant="outlined"
        placeholder={t("form.placeholder.tenant")}
        autoComplete="off"
      />
      <ErrorLabel error={tenantHasError} label={t("form.error.tenant")} />
      <Form.Input
        className="pt-2"
        label={t("form.label.email")}
        value={email}
        required
        onChange={emailChangeHandler}
        onBlur={emailBlurHandler}
        error={emailHasError || isAgideskError}
        type="email"
        name="email"
        id="email"
        variant="outlined"
        placeholder={t("form.placeholder.email")}
        autoComplete="new-email"
      />
      <ErrorLabel error={emailHasError} label={t("form.error.email")} />
      <div className="pt-2">
        <Form.Input
          label={t("form.label.password")}
          value={password}
          required
          onChange={passwordChangeHandler}
          onBlur={passwordBlurHandler}
          error={passwordHasError || isAgideskError}
          type={showHide ? "input" : "password"}
          name="password"
          id="password"
          variant="outlined"
          placeholder={t("form.placeholder.password")}
          action
        >
          <input />
          <ShowHideButton
            title={`${!showHide ? "Mostrar" : "Esconder"} ${t(
              "form.label.password"
            ).toLowerCase()}`}
            hide={showHide}
            onClick={handleShowHide}
          />
        </Form.Input>
        <ErrorLabel error={passwordHasError} label={t("form.error.password")} />
      </div>
      {isAgideskError ? <CommonError /> : null}

      <FormButton
        className={`flex flex-wrap justify-center ${
          isAgideskError ? "mt-8" : "mt-4"
        }`}
        disabled={
          (!tenantID && tenant.length === 0) ||
          email.length === 0 ||
          password.length <= 5
        }
        label={t("formbutton.label.login")}
      />

      <TermsAndPolices widgetType={widgetType} />

      <Divider sx={{ marginTop: "24px", marginBottom: "24px" }} />

      <div className="text-center flex items-center justify-center">
        <LinkButton
          color
          label={
            widgetType === UserType.AGENT
              ? t("formbutton.label.back")
              : t("linkbutton.label.company")
          }
          onClick={widgetType === UserType.AGENT ? goToLogin : goToCompany}
        />
      </div>
    </Form>
  );
};

LoginAgideskForm.defaultProps = {
  widgetType: UserType.NONE,
};

export default LoginAgideskForm;
