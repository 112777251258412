import React, { useEffect, useState } from "react";
import { Segment } from "semantic-ui-react";
import { surveyBody } from "../shared/components/SurveyElements.component";
import { ISurveyRating } from "../slices/surveys.slice";
import { surveysService } from "../services";
// import LoadingDots from "../shared/components/LoadingDots.component";

const Surveyrating = () => {
  const [currentSurvey, setCurrentSurvey] = useState<ISurveyRating>();
  const [answered, setAnswered] = useState<boolean>(false);
  // const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const getCode = () => {
      const queryParameters = new URLSearchParams(window.location.search);
      const code = queryParameters.get("code");
      if (typeof code !== "undefined" && code !== null) return code;
      return undefined;
    };

    (async () => {
      const _code = getCode();
      if (typeof _code !== "undefined" && _code !== null) {
        const payload = await surveysService.surveyrating({
          code: encodeURIComponent(_code),
        });
        if (payload) {
          setCurrentSurvey({
            ...payload,
            code: _code,
          });
        }
      }
    })();
    // setIsLoading(false);
  }, [answered]);

  return (
    <div className="w-fit p-4">
      <Segment className="absolute flex items-center justify-center left-0 right-0">
        <div className="flex w-full flex-col justify-center items-center">
          <main>
            {/* {isLoading ? (
              <LoadingDots
                className="flex justify-center items-center"
                medium
              />
            ) : ( */}
            {surveyBody({ surveyRating: currentSurvey, setAnswered })}
            {/* )} */}
          </main>
        </div>
      </Segment>
    </div>
  );
};

export default Surveyrating;
