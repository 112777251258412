import {
  ICampaign,
  IFilterCampaignBatches,
  INewCampaign,
} from "../slices/campaigns.slice";
import instances from "./instances";

const search = async ({
  skip,
  limit,
  filter,
  deleted,
  allStatus,
}: {
  skip?: number;
  limit?: number;
  filter: string;
  deleted: boolean;
  allStatus?: boolean;
}) => {
  try {
    const _filters =
      typeof filter === "object" ? JSON.parse(JSON.stringify(filter)) : {};

    if (typeof filter === "string" && typeof _filters?.content === "undefined")
      _filters.content = filter;
    if (allStatus) _filters.active = false;
    if (deleted) _filters.deleted = true;

    const response = await instances.instanceCampaigns.get("", {
      params: {
        skip: skip || 0,
        limit: limit || 10,
        filter: _filters,
      },
    });
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const searchBatches = async ({
  skip,
  limit,
  filter,
  deleted,
  allStatus,
}: {
  skip?: number;
  limit?: number;
  filter: string;
  deleted: boolean;
  allStatus?: boolean;
}) => {
  try {
    const _filters =
      typeof filter === "object" ? JSON.parse(JSON.stringify(filter)) : {};

    if (deleted) _filters.deleted = true;
    const response = await instances.instanceCampaigns.get("/batchesinfo", {
      params: {
        skip: skip || 0,
        limit: limit || 10,
        filter: _filters,
      },
    });
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const campaign = async ({ _id }: { _id: string }) => {
  try {
    const response = await instances.instanceCampaigns.get(`/${_id}`);
    if (response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const campaigns = async () => {
  try {
    const response = await instances.instanceCampaigns.get(`/campaign`);
    if (response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const update = async ({ _campaign }: { _campaign: ICampaign }) => {
  try {
    if (typeof _campaign._id !== "undefined") {
      const data = _campaign;
      const response = await instances.instanceCampaigns.patch(
        `${_campaign._id}`,
        data
      );
      return response.data;
    }
    return null;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const deleteCampaigns = async ({ ids }: { ids: string[] }) => {
  try {
    const data = { id: ids };
    const response = await instances.instanceCampaigns.delete("", { data });
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const removeTargetsFromCampaign = async ({
  _id,
  target,
}: {
  _id: string;
  target: string[];
}) => {
  try {
    const data = { campaign: _id, target };
    const response = await instances.instanceCampaigns.delete("/target", {
      data,
    });
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const create = async (data: INewCampaign) => {
  try {
    const response = await instances.instanceCampaigns.post("", data);
    if (response && response.data && !response.data.message) {
      return response.data;
    }
    return response.data.message;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const getTargetsByCampaignId = async ({
  _id,
  skip,
  limit,
  filter,
}: {
  _id: string;
  skip?: number;
  limit?: number;
  filter: string;
}) => {
  try {
    const _filters =
      typeof filter === "object" ? JSON.parse(JSON.stringify(filter)) : {};

    if (typeof filter === "string" && typeof _filters?.content === "undefined")
      _filters.content = filter;

    const response = await instances.instanceCampaigns.get(`/${_id}/target`, {
      params: {
        skip: skip || 0,
        limit: limit || 10,
        filter: _filters,
      },
    });
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const addTargetsToCampaign = async ({
  _id,
  customer,
  tag,
  target,
  file,
}: {
  _id: string;
  customer?: string;
  tag?: string;
  target?: string;
  file?: File;
}) => {
  try {
    const formData = new FormData();
    formData.append("campaign", _id);
    if (typeof customer !== "undefined" && customer.length > 0) {
      formData.append("customer", customer);
    }
    if (typeof tag !== "undefined" && tag.length > 0) {
      formData.append("tag", tag);
    }
    if (typeof target !== "undefined") {
      formData.append("target", target);
    }
    if (typeof file !== "undefined") {
      formData.append("file", file);
    }
    const response = await instances.instanceCampaigns.post(
      `/target`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const campaignsService = {
  search,
  searchBatches,
  campaign,
  campaigns,
  update,
  create,
  deleteCampaigns,
  getTargetsByCampaignId,
  addTargetsToCampaign,
  removeTargetsFromCampaign,
};

export default campaignsService;
