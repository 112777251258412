import { ChatType, IChat } from "../../slices/chats.slice";
import { ITeam } from "../../slices/teams.slice";
import { IUser } from "../../slices/users.slice";

const allowedToOpenChat = (chat: IChat, user: IUser | null | undefined) => {
  const teamsCompare = (teams: ITeam[]) => {
    let foundTeam = false;
    // eslint-disable-next-line no-restricted-syntax
    for (const _team of teams) {
      if (user?.teams?.some((_t) => _t?._id === _team?._id)) {
        foundTeam = true;
      }
    }
    return foundTeam;
  };

  const conditions = {
    usersIncludeMe:
      typeof chat?.users !== "undefined" &&
      chat?.users?.some((_u) => _u?._id === user?._id) &&
      typeof chat?.transferred !== "undefined" &&
      !chat.transferred &&
      chat.type !== ChatType.INTERNAL,
    teamIncludeMyTeams:
      typeof chat?.team !== "undefined" &&
      typeof user?.teams !== "undefined" &&
      user?.teams?.some((_t) => _t?._id === chat?.team?._id) &&
      typeof chat?.transferred !== "undefined" &&
      !chat.transferred &&
      chat.type !== ChatType.INTERNAL,
    chatusersIncludeMe:
      typeof chat?.team?.chatusers !== "undefined" &&
      chat?.team?.chatusers?.some((_u) => _u?.user === user?._id) &&
      typeof chat?.transferred !== "undefined" &&
      !chat.transferred &&
      chat.type !== ChatType.INTERNAL,
    chatTransferredPassToMyTeams:
      typeof chat?.transferred !== "undefined" &&
      chat.transferred &&
      typeof chat?.transferredteams !== "undefined" &&
      teamsCompare(chat?.transferredteams) &&
      chat.type !== ChatType.INTERNAL,
  };
  return (
    conditions.usersIncludeMe ||
    conditions.teamIncludeMyTeams ||
    // (conditions.teamIncludeMyTeams && conditions.chatusersIncludeMe) ||
    conditions.chatTransferredPassToMyTeams
  );
};

export default allowedToOpenChat;
