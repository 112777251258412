/* eslint-disable no-nested-ternary */
import React from "react";
import { ICardItems } from "../../../slices/surveys.slice";

const StatisticCard = ({
  items,
  colspan
}: {
  // cols,
  items: ICardItems[];
  colspan?: number;
  // cols: number;
}) => {
  const getAvgValue = (item: ICardItems) => {
    let itemValue: string | number = item?.value || 0;
    let scoreStat: string | number = "";

    interface scoresObject {
      [key: string]: {
        [key: number]: string;
      };
    }

    if (["feelingavg", "qualityavg", "approvalavg"].includes(item.type)) {
      const scores: scoresObject = {
        approvalavg: {
          0: "Não aprova",
          1: "Não aprova",
          2: "Aprova",
          3: "Aprova",
          4: "Aprova",
        },
        feelingavg: {
          0: "Muito insatisfeito",
          1: "Insatisfeito",
          2: "Normal",
          3: "Satisfeito",
          4: "Muito satisfeito",
        },
        qualityavg: {
          0: "Péssimo",
          1: "Ruim",
          2: "Normal",
          3: "Bom",
          4: "Excelente",
        },
      };
      const score = scores[item.type] || {};
      scoreStat =
        typeof item.value !== "number" || item.count === 0
          ? "Nenhuma avaliação"
          : item.value <= 24
          ? score[0]
          : item.value <= 49
          ? score[1]
          : item.value <= 74
          ? score[2]
          : item.value <= 99
          ? score[3]
          : score[4];
    }

    if (item.percent) {
      itemValue = typeof itemValue !== "string" ? `${itemValue}%` : itemValue;
    }

    return {
      itemValue:
        itemValue === "N/A"
          ? 0
          : typeof itemValue !== "string"
          ? itemValue.toFixed(2)
          : itemValue,
      itemValueDesc: scoreStat,
    };
  };

  const getAvgColor = (item: ICardItems) => {
    let color = "text-black";
    switch (item.type) {
      case "qualityavg":
      case "feelingavg":
        color =
          item.value <= 24
            ? "text-red"
            : item.value <= 49
            ? "text-agidesk"
            : item.value <= 74
            ? "text-yellow"
            : item.value <= 99
            ? "text-surveyolive" // todo: change to surveyolive ???
            : "text-green";
        break;
      case "npsavg":
        color =
          item.value <= 6
            ? "text-red"
            : item.value <= 8
            ? "text-yellow"
            : "text-green";
        break;
      case "approvalavg":
        color =
          item.value < 50
            ? "text-red"
            : item.value < 80
            ? "text-yellow"
            : "text-green";
        break;
      default:
        break;
    }

    return item.count === 0 ? "text-green" : color; // TO DO: "text-gray-text" ???
  };

  const getAvgIcon = (item: ICardItems) => {
    let { icon } = item;
    switch (item.type) {
      case "feelingavg":
        icon =
          item.count === 0
            ? "las la-smile"
            : item.value <= 49
            ? "las la-frown"
            : item.value <= 74
            ? "las la-meh"
            : "las la-smile";
        break;
      case "approvalavg":
        icon =
          item.count === 0
            ? "las la-thumbs-up"
            : item.value <= 49
            ? "las la-thumbs-down"
            : icon;
        break;
      default:
        break;
    }
    return icon;
  };

  const columnVariants = {
    3: {colspan: 'md:col-span-3'},
    4: {colspan: 'md:col-span-4'},
  };
  const buildCard = (item: ICardItems) => {

    if (typeof item.divider !== "undefined" && item.divider) {
      // const colspan = `col-span-${cols}`;
      const columProps = typeof colspan !== 'undefined' ? columnVariants[colspan as keyof typeof columnVariants] : undefined;
      return (
        <div
          key={`statcard-${item.icon}`}
          className={`text-xl font-medium col-span-1 ${columProps?.colspan ? columProps.colspan : 'md:col-span-4'}`}
        >
          <h3 className="leading-6 text-gray-900 flex items-center">
            <i className={`${item.icon} mr-2 `} />
            <p>{`${item.title}`}</p>
          </h3>
        </div>
      );
    }

    const avgColor =
      typeof item.avg !== "undefined" && item.avg ? getAvgColor(item) : "";
    const { itemValue, itemValueDesc } =
      typeof item.avg !== "undefined" && item.avg
        ? getAvgValue(item)
        : { itemValue: item.value, itemValueDesc: "" };
    const itemIcon =
      typeof item.avg !== "undefined" && item.avg
        ? getAvgIcon(item)
        : item.icon;

    const itemColor =
      avgColor !== ""
        ? avgColor
        : typeof item.color !== "undefined"
        ? item.color
        : "text-black";
    const itemTitle =
      typeof item.avg !== "undefined" && item.avg
        ? `${item.title}`
        : item.title;
    const qtdLabel =
      (typeof item?.count !== "undefined" && item?.count > 1) ||
      item?.count === 0
        ? "avaliações"
        : "avaliação";
    const itemQtd =
      typeof item.avg !== "undefined" ? `${item?.count} ${qtdLabel}` : "";

    const defaultCard = `relative p-5 pt-1 border rounded-md h-[95px] shadow bg-white flex flex-wrap `;
    const collumnStyle = `${itemColor} font-semibold`;
    const avgPadding = `${typeof item.avg === "undefined" ? "pb-2" : ""}`;
    const titleStyle = `${avgPadding} font-semibold w-full text-left pt-1`;

    return (
      <div key={`statcard-${item.type}`} className={`${defaultCard}`}>
        <div className={`${titleStyle}`}>{itemTitle}</div>
        <div className={`${collumnStyle}`}>
          <i className={`${itemIcon || "lar la-paper-plane"} text-5xl`} />
          <div className={`${itemColor} text-center text-[11px]`}>
            {itemValueDesc}
          </div>
        </div>
        <div
          className={`${collumnStyle} absolute right-5 bottom-2 ${avgPadding}`}
        >
          <div className={`${itemColor} text-4xl text-right`}>{itemValue}</div>
          <div className="text-agitalks text-[11px] pt-2">{itemQtd}</div>
        </div>
      </div>
    );
  };

  const buildGroupCards = (_items: ICardItems[]) => {
    const fields: JSX.Element[] = [];
    if (typeof _items !== "undefined" && _items.length > 0) {
      Object.entries(items).forEach((item) => {
        const _item = item[1] as ICardItems;
        // const _element = buildCard(_item, _cols);
        const _element = buildCard(_item);
        fields.push(_element);
      });
    }
    return fields;
  };

  // const group = buildGroupCards(items, cols);
  return <>{buildGroupCards(items)}</>;
};

export default StatisticCard;
