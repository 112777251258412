import React from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks/redux/hooks";
import Avatar from "./Avatar.component";
import NoAvatar from "./NoAvatar.component";
import useUserIsAdmin from "../../hooks/useUserIsAdmin";

const CompanyProfile = ({
  toggleSidebar,
  navbar,
}: {
  toggleSidebar: boolean;
  navbar?: boolean;
}) => {
  const { isAdmin } = useUserIsAdmin();
  const navigate = useNavigate();
  const { user, tenantID } = useAppSelector((state) => state.auth);

  const companyName =
    typeof user?.company?.name !== "undefined" && user?.company?.name.length > 0
      ? user?.company?.name
      : tenantID;

  return (
    <div
      className={`${
        toggleSidebar || !navbar ? "pl-5" : ""
      } flex items-center w-full`}
    >
      <div
        role="presentation"
        title={companyName}
        onClick={(e) => {
          e.preventDefault();
          if (isAdmin) navigate("/settings/general");
        }}
        className={`w-10 h-12 flex justify-center items-center relative ${
          isAdmin ? "cursor-pointer" : ""
        }`}
      >
        <div className="w-[32px] h-[32px]">
          {user &&
          user?.company?.avatar &&
          user?.company?.avatar?.path &&
          user?.company?.avatar?.name ? (
            <Avatar
              path={user?.company?.avatar?.path}
              name={user?.company?.avatar?.name}
              title={companyName || "Imagem da empresa"}
            />
          ) : (
            <NoAvatar
              displaytext={companyName || "--"}
              elementClass="p-0"
              labelClass="text-[12px]"
            />
          )}
        </div>
      </div>
      {toggleSidebar || (!toggleSidebar && navbar) ? (
        <div className={`ml-2 ${navbar && !toggleSidebar ? "w-3/4" : "w-44"}`}>
          <p
            className="font-extrabold text-[12px] truncate uppercase"
            title={companyName}
          >
            {companyName}
          </p>
          {companyName?.toLowerCase() !== tenantID?.toLowerCase() ? (
            <p className="text-[11px] truncate lowercase" title={tenantID}>
              {tenantID}
            </p>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

CompanyProfile.defaultProps = {
  navbar: false,
};

export default CompanyProfile;
