import React from "react";
import useContacts from "../../../hooks/features/useContacts";
import { useAppSelector } from "../../../hooks/redux/hooks";
import RelatedInstance from "../../../shared/components/RelatedInstance.component";
import RelatedButtons from "../../../shared/components/RelatedButtons.component";
import RelationModal from "../../../shared/components/Modals/RelationModal.component";
import { SidebarComponent } from "../../../shared/components/Sidebars";
import CreateModal from "../../../shared/components/Modals/CreateModal.component";
// import CustomersModal from "../../../shared/components/Modals/CustomersModal.component";

const Customers = () => {
  const { customers } = useContacts({});
  const { selectedLocalContact } = useAppSelector((state) => state.users);
  return (
    <>
      <SidebarComponent
        visible={customers.sidebar.visible}
        setVisible={customers.sidebar.setVisible}
        content={
          <CreateModal
            title="Novo cliente"
            icon="las la-building"
            setShowModal={customers.modal.setShow}
            setVisible={customers.sidebar.setVisible}
          />
        }
      />
      <div className="flex flex-col w-full text-justify">
        <label>Clientes relacionados</label>
        <div
          id="sidemenu"
          className={`flex flex-wrap max-h-[500px] overflow-y-scroll pb-1 ${
            selectedLocalContact?.customers?.length ? "" : "justify-center"
          }`}
        >
          {selectedLocalContact?.customers?.length ? (
            selectedLocalContact?.customers?.map((_customer) => {
              if (typeof _customer._id !== "undefined") {
                return (
                  <RelatedInstance
                    key={`${_customer._id}customer`}
                    type="customer"
                    instance={_customer}
                    avatar={false}
                  />
                );
              }
              return null;
            })
          ) : (
            <div className="my-4 text-center">Nenhum cliente</div>
          )}
        </div>
      </div>
      {!selectedLocalContact?.deleted ? (
        <RelatedButtons
          onClickCreation={customers.relation.onClickCreation}
          onClickRelation={customers.relation.onClickRelation}
        />
      ) : null}
      {/* {customers.modal.show ? <CustomersModal /> : null} */}
      {customers.modal.show ? (
        <RelationModal
          title="Relacionar clientes"
          icon="las la-building"
          setShowModal={customers.modal.setShow}
        />
      ) : null}
    </>
  );
};

export default Customers;
