import React from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "semantic-ui-react";

const SpamIcon = ({ short }: { short?: boolean }) => {
  const { t } = useTranslation();
  return (
    <div className="flex ml-2 items-center">
      <Icon
        size="small"
        className="las la-exclamation-circle orange"
        title={t("spam.checked")}
      />
      {!short ? (
        <p className="text-[13px] hidden md:flex text-agidesk">
          {t("spam.checked")}
        </p>
      ) : null}
    </div>
  );
};

SpamIcon.defaultProps = {
  short: false,
};

export default SpamIcon;
