import { ChangeEvent, useReducer } from "react";
import { IAction } from "../shared/models/interfaces/action.interface";
import { ValidatorFn } from "../shared/utils/validation/models/ValidatorFn";
import {
  InputActionType,
  INPUT_ACTION_BLUR,
  INPUT_ACTION_CHANGE,
  INPUT_ACTION_CLEAR,
} from "./models/inputAction";
import { IInputState } from "./models/inputState.interface";

const initialInputState: IInputState = {
  text: "",
  hasBeenTouched: false,
};

const inputReducer = (state: IInputState, action: IAction<InputActionType>) => {
  const { type, value = "" } = action;
  switch (type) {
    case INPUT_ACTION_CHANGE:
      return { text: value, hasBeenTouched: state.hasBeenTouched };
    case INPUT_ACTION_BLUR:
      return { text: state.text, hasBeenTouched: true };
    case INPUT_ACTION_CLEAR:
      return { text: "", hasBeenTouched: false };
    default:
      return { ...state };
  }
};

const useInput = (validatorFn?: ValidatorFn) => {
  const [{ text, hasBeenTouched }, dispatch] = useReducer(
    inputReducer,
    initialInputState
  );

  let shouldDisplayError;
  if (validatorFn) {
    const isValid = validatorFn(text);

    shouldDisplayError = !isValid && hasBeenTouched;
  }

  const textChangeHandler = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch({ type: INPUT_ACTION_CHANGE, value: e.target.value });
  };

  const textAreaChangeHandler = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch({ type: INPUT_ACTION_CHANGE, value: e.target.value });
  };

  const emailChangeHandler = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch({
      type: INPUT_ACTION_CHANGE,
      value: e.target.value.trim().toLowerCase(),
    });
  };

  const phoneChangeHandler = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch({
      type: INPUT_ACTION_CHANGE,
      value: e.target.value?.replace(/[^0-9.]/g, ""),
    });
  };

  const inputBlurHandler = () => {
    dispatch({ type: INPUT_ACTION_BLUR });
  };

  const clearHandler = () => {
    dispatch({ type: INPUT_ACTION_CLEAR });
  };

  return {
    text,
    shouldDisplayError,
    textChangeHandler,
    textAreaChangeHandler,
    emailChangeHandler,
    phoneChangeHandler,
    inputBlurHandler,
    clearHandler,
  };
};

export default useInput;
