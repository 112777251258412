/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { onHoverStyled, onRating } from "../SurveyElements.component";
import { IRatingData, IRatingResultData } from "../../../slices/surveys.slice";

interface Approvals {
  id: number;
  label: string;
  value: number;
  color: string;
  icon: string;
}

const standardCss = "icon big thumbs outline";
const approvals: Approvals[] = [];
approvals.push(
  { id: 0, label: "Sim", value: 1, color: "green", icon: "up" },
  { id: 1, label: "Não", value: 0, color: "red", icon: "down" }
);

const ApprovalThumbComponent = ({
  rating,
  setRating,
}: {
  rating: IRatingData;
  setRating: React.Dispatch<React.SetStateAction<IRatingData>>;
}) => {
  const actionIn = (value: number) => {
    onHoverStyled({ value, type: "approval" }, rating);
  };
  const actionOut = (value: number) => {
    onHoverStyled({ value, type: "approval", action: "out" }, rating);
  };

  return (
    <div className="flex">
      {approvals.map((approval) => {
        return (
          <div
            role="presentation"
            key={approval.id}
            title={`Avaliar com: ${approval.label}`}
            id={`div-approvalthumb-icon-${approval.value}`}
            className="ratingoptions w-[100px] p-7 cursor-pointer"
            onClick={() => {
              onRating(
                { value: approval.value, type: "approval" },
                rating,
                setRating
              );
            }}
            onFocus={() => {
              actionIn(approval.value);
            }}
            onMouseOver={() => {
              actionIn(approval.value);
            }}
            onBlur={() => {
              actionOut(approval.value);
            }}
            onMouseOut={() => {
              actionOut(approval.value);
            }}
          >
            <i
              role="presentation"
              id={`thumb-icon-${approval.value}`}
              onClick={() => {
                onRating(
                  { value: approval.value, type: "approval" },
                  rating,
                  setRating
                );
              }}
              onFocus={() => {
                actionIn(approval.value);
              }}
              onMouseOver={() => {
                actionIn(approval.value);
              }}
              onBlur={() => {
                actionOut(approval.value);
              }}
              onMouseOut={() => {
                actionOut(approval.value);
              }}
              className={`${approval.icon} ${standardCss} ${approval.color}`}
              key={approval.value}
            />
            <label>{approval.label}</label>
          </div>
        );
      })}
    </div>
  );
};

export const singleApprovalItem = (resultData: IRatingResultData) => {
  const index = +resultData.value === 0 ? 1 : 0;

  const approval =
    typeof approvals[index] !== "undefined"
      ? approvals[index]
      : ({} as Approvals);

  if (typeof approval !== "undefined") {
    return (
      <>
        <i
          role="presentation"
          id={`approval-icon-${approval.value}`}
          className={`${approval.icon} ${standardCss} ${approval.color}`}
        />
        <label>{approval.label}</label>
      </>
    );
  }
  return "-";
};

export default ApprovalThumbComponent;
