const locales = [
  {
    name: "en",
    options: {
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      shortMonths: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      days: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      shortDays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      toolbar: {
        exportToSVG: "Download SVG",
        exportToPNG: "Download PNG",
        exportToCSV: "Download CSV",
        menu: "Menu",
        selection: "Selection",
        selectionZoom: "Selection Zoom",
        zoomIn: "Zoom In",
        zoomOut: "Zoom Out",
        pan: "Panning",
        reset: "Reset Zoom",
      },
    },
  },
  {
    name: "es",
    options: {
      months: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      shortMonths: [
        "Ene",
        "Feb",
        "Mar",
        "Abr",
        "May",
        "Jun",
        "Jul",
        "Ago",
        "Sep",
        "Oct",
        "Nov",
        "Dic",
      ],
      days: [
        "Domingo",
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
      ],
      shortDays: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
      toolbar: {
        exportToSVG: "Descargar SVG",
        exportToPNG: "Descargar PNG",
        exportToCSV: "Descargar CSV",
        menu: "Menu",
        selection: "Seleccionar",
        selectionZoom: "Seleccionar Zoom",
        zoomIn: "Aumentar",
        zoomOut: "Disminuir",
        pan: "Navegación",
        reset: "Reiniciar Zoom",
      },
    },
  },
  {
    name: "pt-br",
    options: {
      months: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      shortMonths: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],
      days: [
        "Domingo",
        "Segunda",
        "Terça",
        "Quarta",
        "Quinta",
        "Sexta",
        "Sábado",
      ],
      shortDays: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
      toolbar: {
        exportToSVG: "Baixar SVG",
        exportToPNG: "Baixar PNG",
        exportToCSV: "Baixar CSV",
        menu: "Menu",
        selection: "Selecionar",
        selectionZoom: "Selecionar Zoom",
        zoomIn: "Aumentar",
        zoomOut: "Diminuir",
        pan: "Navegação",
        reset: "Reiniciar Zoom",
      },
    },
  },
  {
    name: "pt",
    options: {
      months: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      shortMonths: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ag",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],
      days: [
        "Domingo",
        "Segunda-feira",
        "Terça-feira",
        "Quarta-feira",
        "Quinta-feira",
        "Sexta-feira",
        "Sábado",
      ],
      shortDays: ["Do", "Se", "Te", "Qa", "Qi", "Sx", "Sa"],
      toolbar: {
        exportToSVG: "Baixar SVG",
        exportToPNG: "Baixar PNG",
        exportToCSV: "Baixar CSV",
        menu: "Menu",
        selection: "Selecionar",
        selectionZoom: "Zoom: Selecionar",
        zoomIn: "Zoom: Aumentar",
        zoomOut: "Zoom: Diminuir",
        pan: "Deslocamento",
        reset: "Redefinir",
      },
    },
  },
];

export default locales;
