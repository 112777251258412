import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { holidaysService } from "../services";
import { AsyncState } from "../shared/models/interfaces/asyncstate.interface";
import { IUser } from "./users.slice";
import { ITeam } from "./teams.slice";
import customToast from "../shared/utils/customToast";
import { IToastType } from "../shared/models/types/Toast.type";

export interface IHoliday {
  _id?: string;
  tenant?: string;
  deleted?: boolean;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
  created_by?: IUser | null;
  updated_by?: IUser | null;
  deleted_by?: IUser | null;
  active?: boolean;
  name?: string;
  day?: string;
  month?: string;
  year?: string;
  teams?: ITeam[] | any;
}

export type Holidays = IHoliday[];

export interface INewHoliday {
  name: string;
  day: string;
  month: string;
  year: string;
  teams: string[];
  active: boolean;
  deleted: boolean;
}
interface HolidaysState extends AsyncState {
  isLoadingHolidays: boolean;
  isLoadingDropdownHolidays: boolean;
  isCreatingHolidays: boolean;
  isRemoving: boolean;
  holidays: Holidays;
  totalHolidays: number;
  selectedHoliday: IHoliday | null;
}

const initialState: HolidaysState = {
  holidays: [],
  totalHolidays: 0,
  selectedHoliday: null,
  isRemoving: false,
  isLoadingHolidays: false,
  isLoadingDropdownHolidays: false,
  isCreatingHolidays: false,
  isSuccess: false,
  isError: false,
};

export interface IFilterHoliday {
  skip: number;
  limit: number;
  filter: string;
  deleted: boolean;
  allStatus?: boolean;
}

export const getSearchHolidays = createAsyncThunk(
  "holidays/search",
  async (filterHoliday: IFilterHoliday, thunkAPI) => {
    try {
      return await holidaysService.search(filterHoliday);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// export const getHolidays = createAsyncThunk(
//   "holidays",
//   async (_groupLimit: IGroupLimit, thunkAPI) => {
//     try {
//       return await holidaysService.holidays(_groupLimit);
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error);
//     }
//   }
// );

export const selectHoliday = createAsyncThunk(
  "holidays/selectHoliday",
  async ({ _id }: { _id: string }, thunkAPI) => {
    try {
      return await holidaysService.holiday({ _id });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateHoliday = createAsyncThunk(
  "holidays/update",
  async (
    { _holiday, noToast }: { _holiday: IHoliday; noToast?: boolean },
    thunkAPI
  ) => {
    try {
      return await holidaysService.update({ _holiday });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteHoliday = createAsyncThunk(
  "holidays/delete",
  async (ids: string[], thunkAPI) => {
    try {
      return await holidaysService.deleteHolidays({ ids });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createHoliday = createAsyncThunk(
  "holidays/create",
  async (newHoliday: INewHoliday, thunkAPI) => {
    try {
      return await holidaysService.create(newHoliday);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const holidaysSlice = createSlice({
  name: "holidays",
  initialState,
  reducers: {
    logout() {
      return initialState;
    },
    selectHolidays(state, action: PayloadAction<IHoliday[]>) {
      state.holidays = action.payload || [];
    },
    selectTotalHolidays(state, action: PayloadAction<number>) {
      state.totalHolidays = action.payload || 0;
    },
    reselectHoliday(state, action: PayloadAction<IHoliday | null>) {
      state.selectedHoliday = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // HOLIDAYS
      .addCase(getSearchHolidays.pending, (state, action) => {
        if (action.meta.arg.limit === 10) {
          state.isLoadingHolidays = true;
        }
        state.isLoadingDropdownHolidays = true;
      })
      .addCase(getSearchHolidays.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.holidays = action.payload.results || [];
        // if (state.totalHolidays <= action.payload.count)
        state.totalHolidays = action.payload.count;
        state.isLoadingHolidays = false;
        state.isLoadingDropdownHolidays = false;
      })
      .addCase(getSearchHolidays.rejected, (state) => {
        state.isError = true;
        state.holidays = [];
        state.isLoadingHolidays = false;
        state.isLoadingDropdownHolidays = false;
      })
      // SHOW CUSTOMER
      .addCase(selectHoliday.pending, (state) => {
        state.isLoadingHolidays = true;
      })
      .addCase(selectHoliday.fulfilled, (state, action) => {
        state.isSuccess = true;
        if (action.payload?._id) {
          state.selectedHoliday = action.payload;
        }
        state.isLoadingHolidays = false;
      })
      .addCase(selectHoliday.rejected, (state) => {
        state.isError = true;
        state.selectedHoliday = null;
        state.isLoadingHolidays = false;
      })
      // UPDATE CUSTOMER
      .addCase(updateHoliday.pending, (/* state */) => {
        // state.isLoadingHolidays = true;
      })
      .addCase(updateHoliday.fulfilled, (state, action) => {
        if (
          action?.payload !== null &&
          typeof action?.payload?._id !== "undefined"
        ) {
          state.isSuccess = true;
          if (!action.meta.arg.noToast || action.meta.arg.noToast !== true) {
            customToast({
              type: IToastType.SUCCESS,
              message: `Alteração salva com sucesso!`,
            });
          }
        } else {
          if (!action.meta.arg.noToast || action.meta.arg.noToast !== true) {
            customToast({
              type: IToastType.ERROR,
              message: `Algo deu errado!`,
            });
          }
          state.isError = true;
        }
        state.isLoadingHolidays = false;
      })
      .addCase(updateHoliday.rejected, (state) => {
        state.isError = true;
        state.selectedHoliday = null;
        state.isLoadingHolidays = false;
      })
      .addCase(createHoliday.pending, (state) => {
        state.isCreatingHolidays = true;
      })
      .addCase(createHoliday.fulfilled, (state, action) => {
        if (typeof action?.payload?._id !== "undefined") {
          state.isSuccess = true;
          customToast({
            type: IToastType.SUCCESS,
            message: `Feriado criado com sucesso!`,
          });
          const newState = state.holidays;
          newState.push(action.payload);
          state.holidays = newState;
        } else {
          state.isError = true;
          if (
            typeof action?.payload === "string" &&
            action?.payload.includes("duplicate")
          ) {
            customToast({
              type: IToastType.ERROR,
              message: `Opa! Já existe um feriado com esse nome!`,
            });
          } else {
            customToast({
              type: IToastType.ERROR,
              message: `Algo deu errado! Tente novamente.`,
            });
          }
        }
        state.isCreatingHolidays = false;
      })
      .addCase(createHoliday.rejected, (state) => {
        state.isError = true;
        state.isCreatingHolidays = false;
      })
      .addCase(deleteHoliday.pending, (state) => {
        state.isRemoving = true;
      })
      .addCase(deleteHoliday.fulfilled, (state, action) => {
        if (
          action.payload !== null &&
          typeof action.payload !== "undefined" &&
          typeof action.payload?.message !== "string"
        ) {
          state.isSuccess = true;
          customToast({
            type: IToastType.SUCCESS,
            message: `Remoção realizada com sucesso!`,
          });
        } else {
          state.isError = true;
          customToast({
            type: IToastType.ERROR,
            message: `Algo deu errado!`,
          });
        }
        state.isRemoving = false;
      })
      .addCase(deleteHoliday.rejected, (state) => {
        state.isError = true;
        state.isRemoving = false;
      });
  },
});

export default holidaysSlice.reducer;
export const { logout, reselectHoliday, selectHolidays, selectTotalHolidays } =
  holidaysSlice.actions;
