const getDeviceInfo = () => {
  const nAgt = navigator.userAgent;
  let browserName = navigator.appName;
  let fullVersion = `${parseFloat(navigator.appVersion)}`;
  let majorVersion = parseInt(navigator.appVersion, 10);
  let nameOffset;
  let verOffset;
  let ix;
  try {
    if (nAgt.indexOf("Opera") !== -1) {
      verOffset = nAgt.indexOf("Opera");
      browserName = "Opera";
      fullVersion = nAgt.substring(verOffset + 6);
      if (nAgt.indexOf("Version") !== -1) {
        verOffset = nAgt.indexOf("Version");
        fullVersion = nAgt.substring(verOffset + 8);
      }
    } else if (nAgt.indexOf("Opr") !== -1) {
      verOffset = nAgt.indexOf("Opr");
      browserName = "Opera";
      fullVersion = nAgt.substring(verOffset + 4);
      if (nAgt.indexOf("Version") !== -1) {
        verOffset = nAgt.indexOf("Version");
        fullVersion = nAgt.substring(verOffset + 8);
      }
    } else if (nAgt.indexOf("Edg") !== -1) {
      verOffset = nAgt.indexOf("Edg");
      browserName = "Edge";
      fullVersion = nAgt.substring(verOffset + 4);
    } else if (nAgt.indexOf("MSIE") !== -1) {
      verOffset = nAgt.indexOf("MSIE");
      browserName = "Microsoft Internet Explorer";
      fullVersion = nAgt.substring(verOffset + 5);
    } else if (nAgt.indexOf("Chrome") !== -1) {
      verOffset = nAgt.indexOf("Chrome");
      browserName = "Chrome";
      fullVersion = nAgt.substring(verOffset + 7);
    } else if (nAgt.indexOf("Safari") !== -1) {
      verOffset = nAgt.indexOf("Safari");
      browserName = "Safari";
      fullVersion = nAgt.substring(verOffset + 7);
      if (nAgt.indexOf("Version") !== -1) {
        verOffset = nAgt.indexOf("Version");
        fullVersion = nAgt.substring(verOffset + 8);
      }
    } else if (nAgt.indexOf("Firefox") !== -1) {
      verOffset = nAgt.indexOf("Firefox");
      browserName = "Firefox";
      fullVersion = nAgt.substring(verOffset + 8);
    } else {
      nameOffset = nAgt.lastIndexOf(" ") + 1;
      verOffset = nAgt.lastIndexOf("/");
      if (nameOffset < verOffset) {
        browserName = nAgt.substring(nameOffset, verOffset);
        fullVersion = nAgt.substring(verOffset + 1);
        if (browserName.toLowerCase() === browserName.toUpperCase()) {
          browserName = navigator.appName;
        }
      }
    }

    if (fullVersion.indexOf(";") !== -1) {
      ix = fullVersion.indexOf(";");
      fullVersion = fullVersion.substring(0, ix);
    }
    if (fullVersion.indexOf(" ") !== -1) {
      ix = fullVersion.indexOf(" ");
      fullVersion = fullVersion.substring(0, ix);
    }
    majorVersion = parseInt(`${fullVersion}`, 10);
    if (Number.isNaN(majorVersion)) {
      fullVersion = `${parseFloat(navigator.appVersion)}`;
      majorVersion = parseInt(navigator.appVersion, 10);
    }
  } catch (e) {
    browserName = window.navigator.appCodeName;
    fullVersion = window.navigator.appVersion;
    majorVersion = parseInt(navigator.appVersion, 10);
  }
  return {
    browserName,
    fullVersion,
    majorVersion,
    appName: navigator.appName,
    userAgent: navigator.userAgent,
  };
};

export default getDeviceInfo;
