import React from "react";
import { Form } from "semantic-ui-react";
import { useAppSelector } from "../../../hooks/redux/hooks";
import useUserIsAdmin from "../../../hooks/useUserIsAdmin";
import useCampaigns from "../../../hooks/features/useCampaigns";
import {
  ActiveDropdown,
  CampaignTypesDropdown,
} from "../../../shared/components/Dropdowns";

const Infos = () => {
  const { field, dropdown } = useCampaigns();
  const { isAdmin } = useUserIsAdmin();
  const { selectedLocalCampaign } = useAppSelector((state) => state.campaigns);
  return (
    <Form>
      <Form.Group widths="equal">
        <Form.Input
          autoComplete="off"
          className="w-full md:w-full"
          label="Nome"
          fluid
          readOnly={selectedLocalCampaign?.deleted || !isAdmin}
          defaultValue={selectedLocalCampaign?.name}
          required
          onChange={(e, { value }) => {
            field.onChange.name(e, { value });
          }}
          error={
            typeof selectedLocalCampaign?.name === "undefined" ||
            selectedLocalCampaign?.name.length < 2
          }
          type="text"
          name="name"
          variant="outlined"
          placeholder="Nome completo"
        />
        <ActiveDropdown
          disabled={dropdown.disabled.active}
          className="w-full mt-6"
          fluid
          value={selectedLocalCampaign?.active}
          onChange={(e, { value }) => {
            dropdown.onChange.active(e, { value });
          }}
        />
      </Form.Group>
      <Form.Group widths="equal">
        {/* <Form.Input
          autoComplete="off"
          className="w-full md:w-full"
          label="Quantidade de mensagens disponíveis"
          fluid
          readOnly={selectedLocalCampaign?.deleted || !isAdmin}
          defaultValue={selectedLocalCampaign?.maxsend}
          required
          onChange={(e, { value }) => {
            field.onChange.maxsend(e, { value });
          }}
          error={
            typeof selectedLocalCampaign?.maxsend === "undefined" ||
            selectedLocalCampaign?.maxsend <= 0
          }
          type="text"
          name="maxsend"
          variant="outlined"
          placeholder="Quantidade de mensagens disponíveis"
        /> */}
        <CampaignTypesDropdown />
      </Form.Group>
    </Form>
  );
};

export default Infos;
