import React from "react";
import ActionButton from "./ActionButton.component";

const ShowHideButton = ({
  title,
  hide,
  onClick,
}: {
  title: string;
  hide: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}) => {
  return (
    <ActionButton type="showhide" title={title} onClick={onClick} hide={hide} />
  );
};

export default ShowHideButton;
