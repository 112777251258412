// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { useNavigate } from "react-router-dom";
import { changeStatus, refreshContactLogout } from "../slices/auth.slice";
import { UserStatus } from "../slices/users.slice";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { closeChat } from "../slices/chats.slice";
import { getSettings } from "../slices/settings.slice";

const useContactLogout = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();

  const logoutContact = async ({ noStatus }: { noStatus?: boolean }) => {
    if (noStatus) {
      dispatch(refreshContactLogout());
      navigate("/login");
      await dispatch(getSettings({ widget: "widget" }));
    } else {
      const { payload } = await dispatch(
        changeStatus({ status: UserStatus.OFFLINE, origin: user?._id })
      );
      if (typeof payload !== "undefined" && payload !== null) {
        dispatch(closeChat());
        dispatch(refreshContactLogout());
        navigate("/login");
        await dispatch(getSettings({ widget: "widget" }));
      }
    }
  };

  return { logoutContact };
};

export default useContactLogout;
