// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { RoleUser } from "../slices/users.slice";
import { useAppSelector } from "./redux/hooks";

const useUserIsAdmin = () => {
  const { user } = useAppSelector((state) => state.auth);

  const isAdmin = user?.roles?.includes(RoleUser.ADMINISTRATOR) || false;

  return { isAdmin };
};

export default useUserIsAdmin;
