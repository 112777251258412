import React from "react";
import { Sidebar } from "semantic-ui-react";
import LoadingDots from "../shared/components/LoadingDots.component";
import HeaderPageInfo from "../shared/components/HeaderPageInfo.component";
import FooterPageInfo from "../shared/components/FooterPageInfo.component";

type PageProps = {
  isLoading: boolean;
  header?: {
    icon?: string;
    title?: string;
    created?: string;
    updated?: string;
    deleted?: string;
    imported?: boolean;
    spam?: boolean;
  };
  footer?: {
    disabled?: boolean;
    deleted?: boolean;
    modaltitle?: string;
    toggleSidebar?: boolean;
    showDeleteModal?: boolean;
    setShowDeleteModal?: React.Dispatch<React.SetStateAction<boolean>>;
    showReactiveModal?: boolean;
    setShowReactiveModal?: React.Dispatch<React.SetStateAction<boolean>>;
    showSpamModal?: boolean;
    setShowSpamModal?: React.Dispatch<React.SetStateAction<boolean>>;
    back?: () => void;
    reactive?: () => void;
    remove?: () => void;
    save?: () => void;
    spamAction?: () => void;
  };
  content: React.ReactNode;
  modals?: React.ReactNode;
};

export type SectionPageProps = {
  id: string;
  icon: string;
  title: string;
  description: string;
};

const DefaultPage = ({
  isLoading,
  header,
  content,
  footer,
  modals,
}: PageProps) => {
  return isLoading ? (
    <LoadingDots centerPage large />
  ) : (
    <Sidebar.Pusher>
      {typeof header !== "undefined" ? (
        <HeaderPageInfo
          created={header?.created}
          updated={header?.updated}
          deleted={header?.deleted}
          title={header?.title}
          imported={header?.imported}
          spam={header.spam}
          icon={header?.icon}
        />
      ) : null}
      {content}
      <div className="mb-20" />
      {typeof footer !== "undefined" ? (
        <FooterPageInfo
          title={footer?.modaltitle}
          disabled={footer.disabled}
          deleted={footer?.deleted}
          spammed={header?.spam}
          toggleSidebar={footer?.toggleSidebar}
          showDeleteModal={footer?.showDeleteModal}
          setShowDeleteModal={footer?.setShowDeleteModal}
          showReactiveModal={footer?.showReactiveModal}
          setShowReactiveModal={footer?.setShowReactiveModal}
          showSpamModal={footer?.showSpamModal}
          setShowSpamModal={footer?.setShowSpamModal}
          reactive={footer?.reactive}
          remove={footer?.remove}
          save={footer?.save}
          back={footer?.back}
          spam={footer?.spamAction}
        />
      ) : null}
      {typeof modals !== "undefined" ? modals : null}
    </Sidebar.Pusher>
  );
};

DefaultPage.defaultProps = {
  header: undefined,
  footer: undefined,
  modals: undefined,
};

export default DefaultPage;
