import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Sidebar } from "semantic-ui-react";
import { useAppDispatch, useAppSelector } from "../hooks/redux/hooks";
import LoadingDots from "../shared/components/LoadingDots.component";
import HeaderPageInfo from "../shared/components/HeaderPageInfo.component";
import FooterPageInfo from "../shared/components/FooterPageInfo.component";
import Section from "../shared/components/Section.component";
import SectionElement from "../shared/components/SectionElement.component";
import PreviewModal from "../shared/components/Modals/PreviewContentModal.component";
import {
  selectSurvey,
  updateSurvey,
  reselectSurvey,
  ISurvey,
  deleteSurvey,
} from "../slices/surveys.slice";
import { ITeam } from "../slices/teams.slice";
import DividerInfoPages from "../shared/components/DividerInfoPages.component";
import RelationModal from "../shared/components/Modals/RelationModal.component";

const SurveyPage = ({
  disable,
  setDisable,
  toggleSidebar,
  icon,
  title,
}: {
  toggleSidebar: boolean;
  disable: boolean;
  setDisable: React.Dispatch<React.SetStateAction<boolean>>;
  icon: string;
  title: string;
}) => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoadingSurveys, selectedSurvey } = useAppSelector(
    (state) => state.surveys
  );

  const [, setReloadForced] = useState<boolean>(true);
  const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showReactiveModal, setShowReactiveModal] = useState<boolean>(false);
  const [showModalTeams, setShowModalTeams] = useState<boolean>(false);

  useEffect(() => {
    const getId = () => {
      if (params && typeof params._id !== "undefined") return params._id;
      return location.pathname.split("/").slice(-1)[0];
    };

    (async () => {
      await dispatch(
        selectSurvey({
          _id: getId(),
        })
      );
      setReloadForced(false);
    })();
  }, []);

  const goBack = () => {
    navigate("/settings/surveys");
  };

  const goRemove = async () => {
    setDisable(true);
    setShowDeleteModal(false);
    if (selectedSurvey?._id) {
      const { payload } = await dispatch(deleteSurvey([selectedSurvey._id]));
      setDisable(false);
      if (
        payload !== null &&
        typeof payload !== "undefined" &&
        typeof payload?.message !== "string"
      ) {
        navigate("/settings/surveys");
      }
    }
  };

  const goReactive = async () => {
    setDisable(true);
    setShowReactiveModal(false);
    const _survey = {
      ...selectedSurvey,
      teams: selectedSurvey?.teams?.map((_t: ITeam | string) => {
        if (typeof _t === "string") return _t;
        return _t._id;
      }),
      active: true,
      deleted: false,
      deleted_by: null,
      deleted_at: "",
    };
    const { payload } = await dispatch(
      updateSurvey({ _survey: _survey as ISurvey })
    );
    setDisable(false);
    if (typeof payload._id !== "undefined") {
      navigate(-1);
    }
  };

  const goSave = async () => {
    setDisable(true);
    const _survey = {
      ...selectedSurvey,
      _id: selectedSurvey?._id,
      name: selectedSurvey?.name,
      active: selectedSurvey?.active,
      content: selectedSurvey?.content,
      type: selectedSurvey?.type,
      teams: selectedSurvey?.teams?.map((_t: ITeam) => {
        if (typeof _t === "string") return _t;
        return _t._id;
      }),
      default: selectedSurvey?.default,
    } as ISurvey;
    const { payload } = await dispatch(updateSurvey({ _survey }));
    dispatch(
      reselectSurvey({
        ...selectedSurvey,
        updated_at: payload.updated_at,
        updated_by: payload.updated_by,
      })
    );
    setDisable(false);
  };

  if (isLoadingSurveys)
    return (
      <LoadingDots
        className="flex justify-center items-center absolute top-0 bottom-0 right-0 left-0 h-screen"
        large
      />
    );
  return (
    <div className="container items-center my-auto mx-auto lg:px-44 mt-10 py-4 md:px-12 px-8">
      <Sidebar.Pusher>
        <HeaderPageInfo
          created={selectedSurvey?.created_at}
          updated={selectedSurvey?.updated_at}
          deleted={selectedSurvey?.deleted_at}
          title={selectedSurvey?.name || ""}
          imported={false}
          icon={icon}
        />
        <Section
          id="survey-info"
          icon="las la-info-circle"
          title="Informações"
          description="Defina o envio de pesquisa de satisfação após o encerramento dos atendimentos"
          component={
            <SectionElement instance={selectedSurvey} type="survey-info" />
          }
        />

        <DividerInfoPages />
        <Section
          icon="las la-user-friends"
          title="Equipes"
          description={`Atendimentos finalizados pelas equipes selecionadas enviarão a pesquisa aos contatos após o encerramento\n\nUma equipe pode ser vinculada apenas a uma pesquisa de satisfação`}
          component={
            <SectionElement
              instance={selectedSurvey}
              type="survey-teams"
              setShowModal={setShowModalTeams}
            />
          }
        />
        <DividerInfoPages />
        <Section
          id="survey-content"
          icon="las la-smile"
          title="Pesquisa de satisfação"
          fullarea
          description="Configure a estrutura da pesquisa de satisfação"
          component={
            <SectionElement
              instance={selectedSurvey}
              type="survey-content"
              setShowPreviewModal={setShowPreviewModal}
            />
          }
        />

        {showPreviewModal ? (
          <PreviewModal
            type="survey"
            instance={{ survey: selectedSurvey }}
            setShowPreviewModal={setShowPreviewModal}
          />
        ) : null}
        <div className="mb-20" />
        <FooterPageInfo
          disabled={
            disable ||
            selectedSurvey?.deleted ||
            typeof selectedSurvey?.name === "undefined" ||
            typeof selectedSurvey?.type === "undefined" ||
            selectedSurvey?.content?.length === 0 ||
            selectedSurvey?.name?.length < 2
          }
          deleted={selectedSurvey?.deleted}
          toggleSidebar={toggleSidebar}
          back={goBack}
          title={
            selectedSurvey?.deleted
              ? "Deseja reativar esta pesquisa?"
              : "Deseja remover esta pesquisa?"
          }
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          showReactiveModal={showReactiveModal}
          setShowReactiveModal={setShowReactiveModal}
          reactive={goReactive}
          remove={goRemove}
          save={goSave}
        />
        {/* MODALS */}
        {showModalTeams ? (
          <RelationModal
            title="Relacionar equipes"
            icon="las la-user-friends"
            setShowModal={setShowModalTeams}
          />
        ) : null}
      </Sidebar.Pusher>
    </div>
  );
};

export default SurveyPage;
