const findInObject = (
  entireObj: any,
  keyToFind: string,
  valToFind: string | boolean
) => {
  let foundObj: any;
  JSON.stringify(entireObj, (_, nestedValue) => {
    if (nestedValue && nestedValue[keyToFind] === valToFind) {
      foundObj = nestedValue;
    }
    return nestedValue;
  });
  return foundObj;
};

export default findInObject;
