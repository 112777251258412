import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import LanguageDetector from "i18next-browser-languagedetector";
// import Backend from "i18next-http-backend";
import br from "./translations/br.json";
import en from "./translations/en.json";
import es from "./translations/es.json";
import pt from "./translations/pt.json";

i18n
  // .use(Backend)
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // debug: true,
    fallbackLng: "pt-BR",
    resources: {
      "pt-BR": br,
      en,
      es,
      pt,
    },
  });
export default i18n;
