import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/redux/hooks";
import DividerInfoPages from "../shared/components/DividerInfoPages.component";
import Section from "../shared/components/Section.component";
import DefaultPage from "./Default.page";
import useGetIdPage from "../hooks/useGetIdPage";
import useTags from "../hooks/features/useTags";
import Infos from "../features/tags/components/Infos.section";
import Teams from "../features/tags/components/Teams.section";
import {
  TagType,
  reselectLocalTag,
  reselectTag,
  selectTag,
} from "../slices/tags.slice";

const TagPage = ({
  disable,
  setDisable,
  toggleSidebar,
}: {
  toggleSidebar: boolean;
  disable: boolean;
  setDisable: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { getId } = useGetIdPage();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { isLoadingTags, selectedTag } = useAppSelector((state) => state.tags);
  const { sectionDetails, header, footer } = useTags({
    disable,
    setDisable,
    toggleSidebar,
  });

  useEffect(() => {
    const _id = getId();
    // debugger;
    if (
      location.pathname === `/settings/tags/chattag/${_id}` ||
      location.pathname === `/settings/tags/contacttag/${_id}`
    ) {
      (async () => {
        dispatch(reselectTag(null));
        dispatch(reselectLocalTag(null));
        await dispatch(selectTag({ _id }));
      })();
    }
  }, []);

  return (
    <div className="container items-center my-auto mx-auto lg:px-44 mt-10 py-4 md:px-12 px-4 sm:px-8">
      <DefaultPage
        isLoading={isLoadingTags && selectedTag === null}
        header={header}
        content={
          <>
            <Section
              icon={sectionDetails.infos.icon}
              title={sectionDetails.infos.title}
              description={sectionDetails.infos.description}
              component={<Infos />}
            />
            {selectedTag?.type === TagType.CHAT ? (
              <>
                <DividerInfoPages />
                <Section
                  icon={sectionDetails.team.icon}
                  title={sectionDetails.team.title}
                  description={sectionDetails.team.description}
                  component={<Teams />}
                />
              </>
            ) : null}
          </>
        }
        footer={footer}
      />
    </div>
  );
};

export default TagPage;
