import {
  ICommenttemplate,
  IFilterCommenttemplate,
} from "../slices/commenttemplates.slice";
import instances from "./instances";

const search = async ({
  skip,
  limit,
  filter,
  deleted,
  allStatus,
  teams,
}: IFilterCommenttemplate) => {
  try {
    const _filters =
      typeof filter === "object" ? JSON.parse(JSON.stringify(filter)) : {};

    if (typeof filter === "string" && typeof _filters?.content === "undefined")
      _filters.content = filter;
    if (allStatus) _filters.active = false;
    if (deleted) _filters.deleted = true;
    if (teams) _filters.teams = teams;

    const response = await instances.instanceCommenttemplates.get("", {
      params: {
        skip: skip || 0,
        limit: limit || 10,
        filter: _filters,
        fields:
          "name,active,content,created_at,created_by,default,deleted,updated_at,updated_by",
      },
    });
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const commenttemplate = async ({ _id }: { _id: string }) => {
  try {
    const response = await instances.instanceCommenttemplates.get(`/${_id}`);
    if (response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    // console.log(error);
    return null;
  }
};
// const commenttemplates = async () => {
//   try {
//     const response = await instances.instanceCommenttemplates.get(
//       `/commenttemplate`
//     );
//     if (response.data) {
//       return response.data;
//     }
//     return null;
//   } catch (error) {
//     // console.log(error);
//     return null;
//   }
// };

const update = async ({
  _commenttemplate,
}: {
  _commenttemplate: ICommenttemplate;
}) => {
  try {
    const data = _commenttemplate;
    const response = await instances.instanceCommenttemplates.patch(
      `${_commenttemplate._id}`,
      data
    );
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const deleteCommenttemplates = async ({ ids }: { ids: string[] }) => {
  try {
    const data = { id: ids };
    const response = await instances.instanceCommenttemplates.delete("", {
      data,
    });
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const create = async ({ name }: { name: string }) => {
  try {
    const data = {
      name,
    };
    const response = await instances.instanceCommenttemplates.post("", data);
    if (response && response.data && !response.data.message) {
      return response.data;
    }
    return response.data.message;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const commenttemplatesService = {
  search,
  commenttemplate,
  // commenttemplates,
  update,
  create,
  deleteCommenttemplates,
};

export default commenttemplatesService;
