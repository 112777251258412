import React from "react";

const CloseButton = ({
  extraClass,
  icon,
  title,
  onClick,
}: {
  extraClass?: string;
  icon?: string;
  title?: string;
  onClick?: any;
}) => {
  const _classDefault = `${
    !icon ? "las la-times" : icon
  } text-red cursor-pointer hover:text-red`;
  const className = `${extraClass} ${_classDefault}`;

  return (
    <button
      className={className}
      onClick={onClick}
      type="button"
      title={title || "Fechar"}
      aria-label={title || "Fechar"}
    />
  );
};

CloseButton.defaultProps = {
  extraClass: "",
  icon: "",
  title: "",
  onClick: undefined,
};

export default CloseButton;
