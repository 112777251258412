import React, { useCallback, useEffect, useState } from "react";
import RelatedInstance from "../../../shared/components/RelatedInstance.component";
import PopupComponent from "../../../shared/components/Popup.component";
import { usersService } from "../../../services";

const Campaigns = ({ _id }: { _id?: string }) => {
  const [campaignsList, setCampaignsList] = useState<
    {
      _id: string;
      campaign: string;
      campaignbatch: string[];
    }[]
  >([]);

  const campaignsCallback = useCallback(() => {
    (async () => {
      if (typeof _id !== "undefined") {
        const payload = await usersService.campaigns({ _id });
        if (typeof payload !== "undefined" && payload.length > 0) {
          setCampaignsList(payload);
        }
      }
    })();
  }, []);

  useEffect(() => {
    campaignsCallback();
  }, [campaignsCallback]);

  return (
    <div className="flex flex-col w-full text-justify mb-4">
      <PopupComponent
        className="flex items-center"
        label="Campanhas recebidas"
        content={
          <h1 className="font-semibold text-[12px]">
            Exibindo as 5 campanhas mais recentes
          </h1>
        }
      />
      <div
        id="sidemenu"
        className={`flex flex-wrap max-h-[500px] overflow-y-scroll pb-1 ${
          campaignsList?.length ? "" : "justify-center"
        }`}
      >
        {campaignsList?.length ? (
          campaignsList?.map((_campaign, index) => {
            if (typeof _campaign._id !== "undefined" && index <= 4) {
              return (
                <RelatedInstance
                  key={`${_campaign._id}campaign`}
                  type="campaign"
                  instance={_campaign}
                  avatar={false}
                />
              );
            }
            return null;
          })
        ) : (
          <div className="my-4 text-center">Nenhuma campanha</div>
        )}
      </div>
    </div>
  );
};

Campaigns.defaultProps = {
  _id: undefined,
};

export default Campaigns;
