const botAllowed = () => {
  // DEPLOY TEST //
  return true;
  // return (
  //   process.env.REACT_APP_ENABLE_BOT === "true" ||
  //   process.env.REACT_APP_FRONTEND_URL === "https://stg.agitalks.com" ||
  //   process.env.REACT_APP_FRONTEND_URL === "https://dev.agitalks.com"
  // );
};

export default botAllowed;
