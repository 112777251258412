// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { useAppSelector } from "./redux/hooks";
import useUserIsAdmin from "./useUserIsAdmin";

const useLGPD = () => {
  const { selectedSettings } = useAppSelector((state) => state.settings);
  const { isAdmin } = useUserIsAdmin();

  const showCode =
    typeof selectedSettings?.lgpd === "undefined" ||
    selectedSettings?.lgpd.length === 0 ||
    (typeof selectedSettings?.lgpd !== "undefined" &&
      selectedSettings?.lgpd.length > 0 &&
      (selectedSettings?.lgpd[0].active === false ||
        (selectedSettings?.lgpd[0].active === true && isAdmin)));

  return { showCode };
};

export default useLGPD;
