import React, { useCallback, useEffect } from "react";
import { Sidebar } from "semantic-ui-react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../hooks/redux/hooks";
import LoadingDots from "../LoadingDots.component";

const SidebarComponent = ({
  content,
  blockSidebar,
  visible,
  setVisible,
  setShowTickets,
  // setUpdateTickets,
  setChangePass,
  setShowFilter,
  setReloadForced,
  setLastUrl,
}: {
  content: JSX.Element | null;
  visible: boolean;
  blockSidebar?: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setChangePass?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowTickets?: React.Dispatch<
    React.SetStateAction<{
      show: boolean;
      update: boolean;
      newTicket: boolean;
    }>
  >;
  // setUpdateTickets?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowFilter?: React.Dispatch<React.SetStateAction<boolean>>;
  setReloadForced?: React.Dispatch<React.SetStateAction<boolean>>;
  setLastUrl?: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isCreatingContacts, isCreatingUsers } = useAppSelector(
    (state) => state.users
  );
  const { isUploading } = useAppSelector((state) => state.auth);
  const { isCreatingTeams } = useAppSelector((state) => state.teams);
  const { isCreatingCustomers } = useAppSelector((state) => state.customers);

  const escFunction = useCallback((event: KeyboardEvent) => {
    if (event && event.key === "Escape") {
      event.preventDefault();
      event.stopPropagation();
      if (!blockSidebar) {
        if (setReloadForced) setReloadForced(true);
        if (setChangePass) setChangePass(false);
        if (setShowFilter) setShowFilter(false);
        if (setShowTickets)
          setShowTickets({ newTicket: false, update: false, show: true });
        setVisible(false);
        window.location.hash = "";
        navigate(location.pathname);
        if (setLastUrl) setLastUrl("");
      }
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  useEffect(() => {
    if (location.hash === "") {
      setVisible(false);
    }
  }, [location.hash]);

  return (
    <Sidebar
      animation="overlay"
      direction="right"
      icon="labeled"
      // onHide={() => {
      //   if (!blockSidebar) {
      //     if (setReloadForced) setReloadForced(true);
      //     if (setChangePass) setChangePass(false);
      //     if (setShowFilter) setShowFilter(false);
      //     if (setShowTickets) setShowTickets(true);
      //     setVisible(false);
      //     window.location.hash = "";
      //     navigate(location.pathname);
      //     if (setLastUrl) setLastUrl("");
      //   }
      // }}
      visible={visible}
      width={window.innerWidth > 480 ? "very wide" : "wide"}
      className="bg-white w-[480px]"
    >
      {isCreatingContacts ||
      isCreatingUsers ||
      isCreatingCustomers ||
      isCreatingTeams ||
      isUploading ? (
        <LoadingDots
          className="flex justify-center items-center flex-1 h-full"
          large
        />
      ) : (
        content
      )}
    </Sidebar>
  );
};

SidebarComponent.defaultProps = {
  setReloadForced: () => null,
  setChangePass: () => null,
  setShowFilter: undefined,
  setShowTickets: undefined,
  // setUpdateTickets: undefined,
  setLastUrl: undefined,
  blockSidebar: undefined,
};

export default SidebarComponent;
