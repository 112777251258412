/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RoleUser } from "../../slices/users.slice";
import NoAvatar from "./NoAvatar.component";
import AgideskSVG from "./Agidesk/AgideskSVG.icon";
import Avatar from "./Avatar.component";
import Tag from "../../features/chats/components/Tag.component";
import { handleMaskRegex } from "../utils/handleMaskLength";
// import { useAppDispatch } from "../../hooks/redux/hooks";
// import { selectHoliday } from "../../slices/holidays.slice";

const CustomCard = ({
  element,
  url,
  newTab,
  icon,
  instance,
  settings,
  setLastUrl,
}: {
  // setShowModal,
  settings?: boolean;
  instance?: boolean;
  url: string;
  newTab?: boolean;
  icon?: string;
  // setShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
  element: {
    _id?: string;
    name: string;
    active?: boolean;
    customer?: string;
    team?: string;
    roles?: RoleUser[];
    email?: string;
    phone?: string;
    deleted?: boolean;
    default?: boolean;
    avatar?: {
      path?: string;
      name?: string;
    };
  };
  setLastUrl?: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { t } = useTranslation();
  // const URL = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const location = useLocation();
  // const dispatch = useAppDispatch();

  const getBorder = () => {
    if (!instance) return "";
    if (element?.deleted) return "border-l-4 border-l-agitalks-alpha30";
    if (element?.active) return "border-l-4 border-l-green";
    return "border-l-4 border-l-red";
  };

  const getImage = () => {
    if (icon) {
      if (icon === "agidesk") return <AgideskSVG />;
      return (
        <div className="w-full h-full rounded-full flex items-center justify-center bg-gray-333 border-gray-333">
          <i
            className={`text-white ${icon} text-[22px] self-center leading-none`}
          />
        </div>
      );
    }
    if (element?.avatar && element?.avatar?.path && element?.avatar?.name) {
      return (
        <Avatar
          path={element?.avatar?.path}
          name={element?.avatar?.name}
          title={element?.name}
        />
        // <div className="w-full h-full rounded-full flex items-center justify-center bg-gray-333 border-gray-333">
        //   <img
        //     className="shadow-sm avatar"
        //     alt={element?.avatar?.name}
        //     src={`${URL}/api/files/${element.avatar.path}/${element.avatar.name}`}
        //   />
        // </div>
      );
    }
    return (
      <NoAvatar
        displaytext={element?.name?.trim() || "--"}
        elementClass="p-0"
      />
    );
  };

  const getOnClick = async () => {
    // if (setShowModal && typeof element?._id !== "undefined") {
    //   await dispatch(selectHoliday({ _id: element?._id || "" }));
    //   setShowModal(true);
    // } else
    if (!newTab) navigate(`${url}`);
  };
  return (
    <div
      role="presentation"
      title={`${element.name} ${element?.default ? "(Padrão)" : ""}`}
      onClick={getOnClick}
      className={`h-[100px] w-full bg-white relative hover:bg-agitalks-alpha30 hover:border-2 ${
        instance ? "hover:border-l-4" : ""
      } hover:border-agitalks-alpha30 cursor-pointer flex items-center justify-between radius p-4 border-2 border-agitalks-alpha30 ${getBorder()} rounded-md`}
    >
      <div className="flex items-center w-full">
        <div className="w-[45px]">
          {newTab ? (
            <Link
              to={url}
              target="_blank"
              rel="noopener noreferrer"
              className="w-[45px] h-[45px] cursor-pointer justify-center flex items-center"
              title={element.name}
            >
              {getImage()}
            </Link>
          ) : (
            <div className="w-[45px] h-[45px] cursor-pointer justify-center flex items-center">
              {getImage()}
            </div>
          )}
        </div>
        <div
          className={`flex flex-col ${
            instance ? "text-[12px]" : "text-[18px]"
          } w-[calc(100%-45px)] ml-[14px] text-left`}
        >
          <h1
            className={`${
              settings ? "word-wrap-2" : "word-wrap-1"
            } text-[16px] font-semibold`}
          >
            {element.name}
          </h1>
          {element?.customer ? (
            <h1 className="word-wrap-1 text-agitalks font-semibold">
              {element.customer}
            </h1>
          ) : null}
          {element?.team ? (
            <h1 className="word-wrap-1 text-agitalks font-semibold">
              {element.team}
            </h1>
          ) : null}
          {element?.email ? (
            <h1 className="word-wrap-1 text-agitalks">{element?.email}</h1>
          ) : null}
          {element?.phone ? (
            <h1 className="word-wrap-1 text-agitalks">
              {handleMaskRegex(element?.phone) || t("no-phone")}
            </h1>
          ) : null}
        </div>
      </div>
      <div className="absolute top-3 right-3">
        {element?.default ? (
          // <h1 className="word-wrap-1 font-semibold text-agitalks">(Padrão)</h1>
          <Tag tagType="default" title="Padrão" />
        ) : null}
      </div>
      {setLastUrl ? (
        <i
          role="presentation"
          onClick={() => {
            setLastUrl(`${location.pathname}${location.hash}`);
            navigate(`${location.pathname}#infos`);
          }}
          className="las la-edit text-agitalks hover:text-agitalks-alphaC0 cursor-pointer"
        />
      ) : null}
    </div>
  );
};

CustomCard.defaultProps = {
  setLastUrl: undefined,
  instance: undefined,
  newTab: undefined,
  icon: undefined,
  settings: undefined,
  // setShowModal: undefined,
};

export default CustomCard;
