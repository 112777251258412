const favicon = ({ notifications }: { notifications: number }) => {
  return notifications > 0
    ? `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="940" height="788" viewBox="0 0 940 788" xml:space="preserve">
  <desc>Created with Fabric.js 3.5.0</desc>
  <defs>
  </defs>
  <rect x="0" y="0" width="100%" height="100%" fill="rgba(216,213,253,0)"/>
  <g transform="matrix(-1.7903 0 0 1.5028 469.9914 401.4094)" id="710586">
  <path style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; is-custom-font: none; font-file-url: none; fill: rgb(216,213,253); fill-rule: nonzero; opacity: 1;" vector-effect="non-scaling-stroke" transform=" translate(-255.09, -254.6399)" d="M 511.1 63.1 v 287.1 c 0 35.25 -28.75 63.1 -64 63.1 h -144 l -124.9 93.68 c -7.875 5.75 -19.12 0.0497 -19.12 -9.7 v -83.98 h -96 c -35.25 0 -64 -28.75 -64 -63.1 V 63.1 c 0 -35.25 28.75 -63.1 64 -63.1 h 384 C 483.2 0 511.1 28.75 511.1 63.1 z" stroke-linecap="round"/>
  </g>
  <g transform="matrix(1 0 0 1 442.1576 364.2946)" style="" id="807349">
      
      <text x="${
        notifications > 9 ? -300 : -150
      }" y="170" font-family="Lato" font-size="650" font-style="normal" font-weight="bold"  style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-dashoffset: 0; stroke-linejoin: miter; stroke-miterlimit: 4; is-custom-font: none; font-file-url: none; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1; white-space: pre;"
      >${notifications > 9 ? "9+" : notifications}</text>	
  
  </g>
  </svg>`
    : `<?xml version="1.0" encoding="utf-8"?>
  <!-- Generator: Adobe Illustrator 27.6.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 32 32" style="enable-background:new 0 0 32 32;" xml:space="preserve">
  <style type="text/css">
    .st0{fill:#FF5F00;}
  </style>
  <g>
    <path class="st0" d="M15.5,1.6c1.1-0.2,2.2-0.1,3.2,0.5c1.5,0.8,2.5,2.5,2.4,4.2c0,0.8-0.2,1.5-0.6,2.2c-3.1,6.4-6.3,12.9-9.4,19.3
      c-0.6,1.2-1.6,2.2-2.9,2.5c-1.3,0.4-2.7,0.2-3.8-0.6c-2-1.2-2.7-4-1.6-6.1c3-6.2,6-12.3,9-18.5c0.3-0.5,0.5-1.1,0.8-1.5
      C13.2,2.6,14.3,1.8,15.5,1.6z"/>
    <path class="st0" d="M21.4,14.4c1.4-0.2,2.8,0.3,3.8,1.3c0.5,0.5,0.8,1,1.1,1.6c1,2.2,2.1,4.4,3.1,6.6c0.4,0.9,0.5,1.8,0.4,2.8
      c-0.2,1.4-1.2,2.8-2.5,3.4c-1.8,1-4.3,0.4-5.6-1.2c-0.5-0.5-0.8-1.2-1.1-1.9c-0.9-2-1.9-4-2.8-6c-0.6-1.3-0.6-2.9,0.1-4.2
      C18.5,15.5,19.9,14.5,21.4,14.4z"/>
  </g>
  </svg>`;
};

export default favicon;
