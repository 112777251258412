/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import {
  IAgideskTeam,
  IAgideskUser,
} from "../../../../slices/externalapps.slice";
import useAbrevName from "../../../../hooks/useAbrevName";
import findInObject from "../../../utils/findInObject";
import Tag from "../../../../features/chats/components/Tag.component";
import TagArea from "../../../../features/chats/components/TagArea.component";

const User = ({
  teamuser,
  team,
  teamChecked,
  setTeamChecked,
  importData,
  setImportData,
}: {
  importData: IAgideskTeam[];
  setImportData: React.Dispatch<React.SetStateAction<IAgideskTeam[]>>;
  teamuser: IAgideskUser;
  team: IAgideskTeam;
  teamChecked: boolean;
  setTeamChecked: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const getState = () => {
    const _team = importData?.filter((_import) => _import.id === team.id)[0];
    if (
      typeof _team !== "undefined" &&
      typeof _team.users?.filter((_u) => _u.id === teamuser.id)[0] !==
        "undefined"
    ) {
      return true;
    }
    return false;
  };

  const [checked, setChecked] = useState(getState());

  useEffect(() => {
    if (!teamChecked) {
      setChecked(false);
    } else if (
      teamChecked &&
      team.users &&
      team.users.length > 0 &&
      typeof findInObject(team.users, "id", teamuser.id) !== "undefined"
    ) {
      setChecked(true);
    }
  }, [teamChecked]);

  const getIcon = () => {
    if (checked) {
      return "las la-check-square";
    }
    return "las la-stop";
  };

  return (
    <div
      title={teamuser.title}
      className={`px-3 flex w-full items-center justify-between rounded-lg mx-auto my-0 ${
        !teamuser.imported ? "h-12" : "h-[55px]"
      } cursor-pointer`}
      role="presentation"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div
        className="flex items-center"
        role="presentation"
        onClick={(e) => {
          e.stopPropagation();
          if (!teamChecked) {
            setTeamChecked(true);
            importData.push({
              id: team.id,
              title: team.title,
              users: [],
            });
          }
          const _importTeam = importData?.filter(
            (_team) => _team.id === team.id
          )[0];

          const _importData: IAgideskTeam[] = [];
          importData?.forEach((_team) => {
            if (_team.id !== team.id) _importData.push(_team);
          });

          if (
            !checked &&
            _importTeam.users &&
            typeof findInObject(_importTeam.users, "id", teamuser.id) ===
              "undefined"
          ) {
            _importTeam.users?.push({
              id: teamuser.id,
              title: teamuser.title,
              email: teamuser.email,
              cellphone: teamuser?.cellphone,
              phone: teamuser?.phone,
              admin: false,
              invite: teamuser?.new === true,
              new: teamuser?.new,
            });
            _importData.push(_importTeam);
            setImportData(_importData);
          } else {
            const _importTeamWithoutUser =
              _importTeam.users?.filter((_u) => _u.id !== teamuser.id) || [];
            _importTeam.users = _importTeamWithoutUser;
            setImportData(
              importData.map((_import) => {
                if (_import.id === _importTeam.id) {
                  return _importTeam;
                }
                return _import;
              })
            );
          }
          setChecked((prevState) => !prevState);
        }}
      >
        <i className={`${getIcon()} text-[20px] cursor-pointer`} />
        <div
          key={teamuser.id}
          className="flex items-center justify-start ml-2 cursor-pointer"
        >
          <div
            title={teamuser.title}
            className="w-8 h-8 bg-agitalks flex items-center justify-center rounded-full border border-agidesk shadow"
          >
            <p className="text-[10px] text-white">
              {useAbrevName(teamuser.title || "")}
            </p>
          </div>
          <div className="text-[12px] ml-2">
            <p className="font-semibold">{teamuser.title}</p>
            <p className="text-[11px] text-gray-999">{teamuser.email}</p>
            {teamuser.imported ? (
              <TagArea teamuser>
                <Tag tagType="teamuser" recents />
              </TagArea>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default User;
