import React from "react";
import { DropdownProps, SemanticWIDTHS } from "semantic-ui-react";
import Dropdown from "../Dropdown.component";
import { RoleUser } from "../../../slices/users.slice";

const RolesDropdown = ({
  id,
  className,
  disabled,
  defaultValue,
  onChange,
  width,
  fluid,
  error,
}: {
  id?: string;
  width?: SemanticWIDTHS;
  className?: string;
  disabled?: boolean;
  defaultValue?: RoleUser[];
  error?: boolean;
  onChange?:
    | ((
        event: React.SyntheticEvent<HTMLElement, Event>,
        data: DropdownProps
      ) => void)
    | undefined;
  fluid?: boolean;
}) => {
  return (
    <Dropdown
      id={id || "dropdown-roles"}
      className={className || "w-full md:w-full"}
      disabled={disabled || false}
      fluid={fluid}
      width={width}
      error={error}
      required
      placeholder="Perfis de acesso"
      label="Perfis de acesso"
      selection
      multiple
      defaultValue={defaultValue}
      onChange={onChange}
      options={[
        {
          key: RoleUser.ADMINISTRATOR,
          text: "Administrador",
          value: RoleUser.ADMINISTRATOR,
        },
        {
          key: RoleUser.AGENT,
          text: "Agente",
          value: RoleUser.AGENT,
        },
      ]}
    />
  );
};

RolesDropdown.defaultProps = {
  id: undefined,
  className: undefined,
  disabled: undefined,
  defaultValue: undefined,
  onChange: undefined,
  fluid: undefined,
  width: undefined,
  error: undefined,
};

export default RolesDropdown;
