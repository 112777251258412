import sanitizeHtml from "sanitize-html";
import useFormatText from "./useFormatText";
import stripTags from "../shared/utils/stripTags";

const useSanitizeText = ({
  text,
  stripTagsExceptions,
  preserveHTMLExceptions,
}: {
  text: string;
  stripTagsExceptions?: string[];
  preserveHTMLExceptions?: string[];
}) => {
  let stripTextTags = false;
  let preserveHtml = false;
  let formatText = text;

  const _stripTagsExceptions = stripTagsExceptions || [];
  const _preserveHTMLExceptions = preserveHTMLExceptions || [];

  if (typeof text !== "undefined" && text.length > 0) {
    if (
      typeof _stripTagsExceptions !== "undefined" &&
      _stripTagsExceptions.length > 0
    ) {
      stripTextTags = _stripTagsExceptions.some((exception) =>
        text.includes(exception)
      );
    }

    if (
      typeof _preserveHTMLExceptions !== "undefined" &&
      _preserveHTMLExceptions.length > 0
    ) {
      preserveHtml = _preserveHTMLExceptions.some((exception) =>
        text.includes(exception)
      );
    }

    // TODO: remove html tags from text
    if (stripTextTags) {
      formatText = stripTags({ value: formatText });
    }

    if (!preserveHtml) {
      // TODO: treats html tags as text
      formatText = sanitizeHtml(formatText, {
        allowedTags: ["*"],
        allowedAttributes: false,
        disallowedTagsMode: "recursiveEscape",
        nonBooleanAttributes: ["*"],
      });
    }

    const sanitizedText = useFormatText(formatText);
    return typeof sanitizedText !== "undefined" ? sanitizedText : formatText;
  }

  return text;
};

export default useSanitizeText;
