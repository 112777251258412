import React from "react";
import { Form } from "semantic-ui-react";
import { useAppSelector } from "../../hooks/redux/hooks";
import CopyButton from "./Buttons/CopyButton.component";

const WidgetInput = ({
  type,
  code,
  className,
}: {
  type: string;
  code?: string;
  className?: string;
}) => {
  const URL = process.env.REACT_APP_API_BASE_URL;
  const { user } = useAppSelector((state) => state.auth);

  const _id = type === "contact" ? "contactWidget" : "userWidget";
  const _label =
    type === "contact" ? "Widget para contatos" : "Widget para agentes";
  const _value =
    type === "contact"
      ? `<script src="${URL}/api/widget?widgettype=contact&tenant=${user?.tenant}&widgetcode=${code}" widgettype="contact" x-tenant-id="${user?.tenant}" env="" widgetcode="${code}"></script>`
      : `<script src="${URL}/api/widget?widgettype=agent&tenant=${user?.tenant}" widgettype="agent" x-tenant-id="${user?.tenant}" env=""></script>`;

  return (
    <Form.Input
      autoComplete="off"
      className={className}
      id={_id}
      label={_label}
      fluid
      value={_value}
      action
    >
      <input />
      <CopyButton _id={_id} />
    </Form.Input>
  );
};

WidgetInput.defaultProps = {
  code: "",
  className: "",
};

export default WidgetInput;
