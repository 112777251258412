import React from "react";
import customToast from "../../utils/customToast";
import { IToastType } from "../../models/types/Toast.type";
import ActionButton from "./ActionButton.component";

const CopyButton = ({ _id }: { _id: string }) => {
  const copyToClipboard = (str: string) => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText)
      return navigator.clipboard.writeText(str);
    return null;
  };

  return (
    <ActionButton
      type="copy"
      title="Copiar para a área de transferência"
      onClick={() => {
        const copyText = document.getElementById(_id) as HTMLInputElement;
        if (
          typeof copyText?.value !== "undefined" &&
          copyToClipboard(copyText.value)
        ) {
          customToast({
            message: "Copiado para área de transferência",
            type: IToastType.SUCCESS,
          });
        } else {
          customToast({
            message: "Não foi possível copiar",
            type: IToastType.ERROR,
          });
        }
      }}
    />
  );
};

export default CopyButton;
