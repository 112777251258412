import React from "react";

const TagArea = ({
  children,
  filter,
  subfilter,
  parentfilter,
  team,
  teamuser,
}: {
  children: React.ReactNode;
  filter?: boolean;
  subfilter?: boolean;
  parentfilter?: boolean;
  team?: boolean;
  teamuser?: boolean;
}) => {
  return (
    <div
      className={`py flex relative items-center flex-wrap 
      ${filter ? "mx-[15px] pb-[10px]" : ""}
      ${parentfilter ? "mx-[15px] mt-[-10px] pb-[5px]" : ""}
      ${team ? "mr-2 -mt-2" : ""}
      ${subfilter ? "mx-[15px] mt-[-3px] pb-[5px] " : ""}
    `}
    >
      {children}
    </div>
  );
};

TagArea.defaultProps = {
  filter: false,
  subfilter: false,
  team: false,
  teamuser: false,
  parentfilter: false,
};

export default TagArea;
