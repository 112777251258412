/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { CheckboxProps, Form } from "semantic-ui-react";

const Toggle = ({
  containerClass,
  mainClass,
  defaultChecked,
  onChange,
  labelClass,
  simple,
  label,
  readOnly,
  id,
  checked,
  disabled,
}: {
  containerClass: string;
  mainClass: string;
  defaultChecked?: boolean;
  simple?: boolean;
  onChange: (e: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => void;
  labelClass: string;
  label: string;
  readOnly?: boolean;
  id?: string;
  checked?: boolean;
  disabled?: boolean;
}) => {
  return (
    <div className={`flex items-center ${containerClass}`}>
      <Form.Checkbox
        id={id}
        checked={checked}
        readOnly={readOnly}
        className={`${mainClass}`}
        toggle={!simple}
        defaultChecked={defaultChecked}
        onChange={onChange}
        disabled={disabled}
      />
      <label className={`${labelClass}`}>{label}</label>
    </div>
  );
};

Toggle.defaultProps = {
  simple: false,
  checked: undefined,
  disabled: undefined,
  defaultChecked: undefined,
  readOnly: false,
  id: undefined,
};

export default Toggle;
