import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAppSelector } from "../hooks/redux/hooks";
import { updateTenant } from "../services/instances";

const PrivateRoute = () => {
  const { isAuthenticated, user, tenantID } = useAppSelector(
    (state) => state.auth
  );
  const location = useLocation();

  if (tenantID) {
    updateTenant(user?.tenant || "");
  } else if (isAuthenticated) {
    updateTenant(user?.tenant || "");
  }

  const getFormScreen = () => {
    if (tenantID && tenantID.length > 0) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
    return <Navigate to="/company" state={{ from: location }} replace />;
  };

  return isAuthenticated ? <Outlet /> : getFormScreen();
};

export default PrivateRoute;
