const stripTags = ({ value }: { value: string | undefined }) => {
  const div = document.createElement("div");
  if (value) {
    div.innerHTML = value;
  }
  const text = div.textContent || div.innerText || "";
  return text;
};

export default stripTags;
