import { teamsService } from "../../services";
import { IChat } from "../../slices/chats.slice";

const populateTeamToChat = async ({ chat }: { chat: IChat }) => {
  let _chat = chat;
  if (
    _chat !== null &&
    typeof _chat?.team !== "undefined" &&
    typeof _chat?.team?.name === "undefined" &&
    typeof _chat?.team?._id === "undefined"
  ) {
    const _team = await teamsService.team({
      _id: _chat.team as string,
    });
    if (_team !== null && typeof _team !== "undefined") {
      _chat = {
        ..._chat,
        team: _team,
      };
    }
    return _chat;
  }
  return _chat;
};

export default populateTeamToChat;
