import React from "react";
import { DropdownProps, SemanticWIDTHS } from "semantic-ui-react";
import Dropdown from "../Dropdown.component";

const WeekdaysDropdown = ({
  id,
  className,
  disabled,
  defaultValue,
  onChange,
  fluid,
  required,
  width,
}: {
  id?: string;
  className?: string;
  disabled?: boolean;
  defaultValue?: string;
  onChange?:
    | ((
        event: React.SyntheticEvent<HTMLElement, Event>,
        data: DropdownProps
      ) => void)
    | undefined;
  fluid?: boolean;
  required?: boolean;
  width?: SemanticWIDTHS;
}) => {
  const weekOptions = [
    {
      key: 0,
      text: "Segunda-feira",
      value: "monday",
    },
    {
      key: 1,
      text: "Terça-feira",
      value: "tuesday",
    },
    {
      key: 2,
      text: "Quarta-feira",
      value: "wednesday",
    },
    {
      key: 3,
      text: "Quinta-feira",
      value: "thursday",
    },
    {
      key: 4,
      text: "Sexta-feira",
      value: "friday",
    },
    {
      key: 5,
      text: "Sábado",
      value: "saturday",
    },
    {
      key: 6,
      text: "Domingo",
      value: "sunday",
    },
  ];
  return (
    <Dropdown
      id={id || "dropdown-relation-weekday"}
      className={className || "w-full md:w-full"}
      disabled={disabled || false}
      placeholder="Dia da semana"
      label="Dia da semana"
      fluid={fluid}
      width={width}
      required={required}
      clearable
      selection
      search
      defaultValue={defaultValue}
      onChange={onChange}
      options={weekOptions}
    />
  );
};

WeekdaysDropdown.defaultProps = {
  id: undefined,
  className: undefined,
  disabled: undefined,
  defaultValue: undefined,
  onChange: undefined,
  fluid: undefined,
  required: undefined,
  width: undefined,
};

export default WeekdaysDropdown;
