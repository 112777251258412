import React from "react";
import { IAcceptedFile } from "../../models/interfaces/acceptedfile.interface";
import bytesToSize from "../../utils/bytesToSize";
import CloseButton from "../Buttons/CloseButton.component";
import { IFile } from "../../models/interfaces/file.interface";

const File = ({
  file,
  remove,
  show,
  message,
  href,
  readOnly,
}: {
  file: IAcceptedFile | IFile;
  remove?: () => void;
  show?: () => void;
  message?: boolean;
  href?: string;
  readOnly?: boolean;
}) => {
  const getActionButton = () => {
    if (readOnly) return null;
    if (message) {
      return (
        <a
          href={href}
          target="_blank"
          title={file.name}
          rel="noopener noreferrer"
        >
          <CloseButton
            title="Visualizar"
            extraClass="!text-blue-link !hover:text-blue-link-alphaC0"
            icon="las la-external-link-alt"
            onClick={show}
          />
        </a>
      );
    }
    return <CloseButton title="Remover" icon="las la-trash" onClick={remove} />;
  };

  return (
    <div
      role="presentation"
      className={`w-full flex items-center justify-between py-1 ${
        message
          ? "text-agitalks hover:text-agitalks-alphaC0 hover:cursor-pointer"
          : ""
      }`}
    >
      <div className="flex items-center w-[65%]">
        <i className="las la-paperclip mr-2" />
        <h2
          className={`${message ? "text-[9px]" : "text-[13px]"} truncate`}
          title={file.name}
        >
          {file.name}
        </h2>
      </div>
      <div className="flex items-center">
        <h2
          className={`${
            message ? "text-[9px]" : "text-[13px] justify-end"
          } mr-2 flex w-full`}
        >
          ({bytesToSize({ bytes: file.size || 0 })})
        </h2>
        {getActionButton()}
      </div>
    </div>
  );
};

File.defaultProps = {
  message: false,
  href: undefined,
  show: () => null,
  remove: () => null,
  readOnly: false,
};

export default File;
