import React, { ChangeEvent, useCallback, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DropdownSearchInputProps } from "semantic-ui-react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
  RoleUser,
  deleteContact,
  reselectContact,
  reselectLocalContact,
  selectUser,
  spamContact,
  updateUser,
} from "../../slices/users.slice";
import { SectionPageProps } from "../../pages/Default.page";
import useUserIsAdmin from "../useUserIsAdmin";
import { uploadAvatar } from "../../slices/auth.slice";
import { IFile } from "../../shared/models/interfaces/file.interface";
import { ddOptions } from "../../shared/models/interfaces/dropdownOptions.interface";
import removeDuplicates from "../../shared/utils/removeDuplicates";
import { customersService } from "../../services";
import { ICustomer } from "../../slices/customers.slice";
import validateEmail from "../../shared/utils/validation/email";
import {
  inactiveContactChats,
  spamContactChats,
} from "../../slices/chats.slice";
import { validNumberLength } from "../../shared/utils/handleMaskLength";

const useContacts = ({
  disable,
  setDisable,
  toggleSidebar,
}: {
  toggleSidebar?: boolean;
  disable?: boolean;
  setDisable?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  interface sectionDetailsObject {
    [key: string]: SectionPageProps;
  }
  type onChangeEvent = React.SyntheticEvent<HTMLElement, Event>;
  type onChangeValue =
    | string
    | number
    | boolean
    | (string | number | boolean)[]
    | undefined;

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { selectedContact, selectedLocalContact } = useAppSelector(
    (state) => state.users
  );
  const { tenantID, user } = useAppSelector((state) => state.auth);

  const { isAdmin } = useUserIsAdmin();

  const tagsValue = useRef<string[]>([]);
  const customersValue = useRef<string[]>([]);
  const [tagsOptions, setTagsOptions] = useState<ddOptions[]>([]);
  const [customersOptions, setCustomersOptions] = useState<ddOptions[]>([]);
  const [focusId, setFocusId] = useState<string>("");
  const [isLoadingDropdown, setIsLoadingDropdown] = useState<boolean>(false);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showReactiveModal, setShowReactiveModal] = useState<boolean>(false);
  const [showSpamModal, setShowSpamModal] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);

  const contactCallback = useCallback(() => {
    if (
      selectedContact !== null &&
      typeof selectedContact?._id !== "undefined"
    ) {
      dispatch(reselectLocalContact(selectedContact));
    }
  }, [selectedContact]);

  const tagsCallback = useCallback(() => {
    const _options: ddOptions[] = tagsOptions;
    const _value: string[] = [];
    selectedLocalContact?.tags?.map((_tag) =>
      _options.push({
        key: _tag._id,
        text: _tag.name,
        value: _tag._id,
      })
    );
    if (selectedLocalContact?.tags && selectedLocalContact?.tags.length > 0) {
      selectedLocalContact?.tags.forEach((_tag) => {
        if (typeof _tag?._id !== "undefined") {
          _value.push(_tag._id);
        }
      });
    }
    const options = removeDuplicates(_options, "key");
    const defaultValue = removeDuplicates(_value);
    return { options, defaultValue };
  }, []);

  const customersCallback = useCallback(() => {
    const _options: ddOptions[] = customersOptions;
    const _value: string[] = [];
    selectedLocalContact?.customers?.map((_customer) =>
      _options.push({
        key: _customer._id,
        text: _customer.name,
        value: _customer._id,
      })
    );
    if (
      selectedLocalContact?.customers &&
      selectedLocalContact?.customers.length > 0
    ) {
      selectedLocalContact?.customers.forEach((_customer) => {
        if (typeof _customer?._id !== "undefined") {
          _value.push(_customer._id);
        }
      });
    }
    const options = removeDuplicates(_options, "key");
    const defaultValue = removeDuplicates(_value);
    return { options, defaultValue };
  }, []);

  const sectionDetails: sectionDetailsObject = {
    infos: {
      id: "contact-info",
      icon: "las la-info-circle",
      title: "Informações",
      description: "Edite as informações exibidas",
    },
    customer: {
      id: "contact-customer",
      icon: "las la-building",
      title: "Clientes vinculados ao contato",
      description: "Relacione os clientes desejados",
    },
    campaign: {
      id: "contact-campaign",
      icon: "las la-bullhorn",
      title: "Campanhas",
      description: `Visualize as campanhas recebidas pelo contato`,
    },
  };

  const header: {
    icon?: string;
    title?: string;
    created?: string;
    updated?: string;
    deleted?: string;
    imported?: boolean;
    spam?: boolean;
  } = {
    created: selectedContact?.created_at,
    updated: selectedContact?.updated_at,
    deleted: selectedContact?.deleted_at,
    title: selectedContact?.name,
    imported: !!selectedLocalContact?.externalcode,
    spam: !!selectedLocalContact?.spam,
    icon: "las la-address-book",
  };

  // const params = useParams();
  // const dispatch = useAppDispatch();

  // const [, setReloadForced] = useState<boolean>(true);

  const getTenant = () => {
    if (
      typeof selectedContact?.tenant !== "undefined" &&
      selectedContact?.tenant !== null
    ) {
      return selectedContact?.tenant;
    }
    if (
      typeof tenantID !== "undefined" &&
      tenantID !== null &&
      tenantID.length > 0
    ) {
      return tenantID;
    }
    if (
      typeof user?.tenant !== "undefined" &&
      user?.tenant !== null &&
      user?.tenant.length > 0
    ) {
      return user?.tenant;
    }
    return undefined;
  };

  const back = () => {
    navigate(-1);
  };

  const spamAction = async () => {
    if (setDisable) setDisable(true);
    setShowSpamModal(false);
    if (selectedContact?._id && typeof selectedContact?.spam !== "undefined") {
      const ids = [selectedContact._id];
      const { payload } = await dispatch(
        spamContact({ ids, spam: !selectedContact?.spam })
      );
      if (setDisable) setDisable(false);
      if (
        payload !== null &&
        typeof payload !== "undefined" &&
        typeof payload?.message !== "string"
      ) {
        if (typeof payload[0]?._id !== "undefined" && payload[0]?.spam) {
          await dispatch(spamContactChats(ids));
        }
        navigate(-1);
      }
    }
  };

  const remove = async () => {
    if (setDisable) setDisable(true);
    setShowDeleteModal(false);
    if (selectedContact?._id) {
      const { payload } = await dispatch(deleteContact([selectedContact._id]));
      if (setDisable) setDisable(false);
      if (
        payload !== null &&
        typeof payload !== "undefined" &&
        typeof payload?.message !== "string"
      ) {
        await dispatch(inactiveContactChats(payload));
        navigate(-1);
      }
    }
  };

  const reactive = async () => {
    if (setDisable) setDisable(true);
    setShowReactiveModal(false);
    const _contact = {
      ...selectedLocalContact,
      avatar: selectedLocalContact?.avatar?._id,
      teams: selectedLocalContact?.teams?.map((_t) => {
        if (typeof _t === "string") return _t;
        return _t._id;
      }),
      tags: selectedLocalContact?.tags?.map((_t) => {
        if (typeof _t === "string") return _t;
        return _t._id;
      }),
      customers: selectedLocalContact?.customers?.map((_t) => {
        if (typeof _t === "string") return _t;
        return _t._id;
      }),
      deleted: false,
      spam: false,
      active: true,
      deleted_by: null,
      deleted_at: "",
    };
    const { payload } = await dispatch(updateUser(_contact));
    if (setDisable) setDisable(false);
    if (typeof payload._id !== "undefined") {
      navigate(-1);
    }
  };

  const save = async () => {
    const _tenant = getTenant();
    if (_tenant === undefined || typeof _tenant === "undefined") return;
    if (setDisable) setDisable(true);
    const _contact = {
      ...selectedLocalContact,
      tenant: _tenant,
      avatar: selectedLocalContact?.avatar?._id,
      _id: selectedLocalContact?._id,
      name: selectedLocalContact?.name,
      code: selectedLocalContact?.code,
      email: selectedLocalContact?.email,
      active: selectedLocalContact?.active,
      phone: selectedLocalContact?.phone,
      spam: selectedLocalContact?.spam,
      teams: selectedLocalContact?.teams?.map((_t) => {
        if (typeof _t === "string") return _t;
        return _t._id;
      }),
      tags: selectedLocalContact?.tags?.map((_t) => {
        if (typeof _t === "string") return _t;
        return _t._id;
      }),
      customers: selectedLocalContact?.customers?.map((_t) => {
        if (typeof _t === "string") return _t;
        return _t._id;
      }),
    };
    const { payload } = await dispatch(updateUser(_contact));
    if (payload !== null && typeof payload?._id !== "undefined") {
      dispatch(
        reselectContact({
          ...selectedLocalContact,
          updated_at: payload.updated_at,
          updated_by: payload.updated_by,
        })
      );
      dispatch(
        reselectLocalContact({
          ...selectedLocalContact,
          updated_at: payload.updated_at,
          updated_by: payload.updated_by,
        })
      );
      if (typeof payload?.active !== "undefined" && payload?.active === false) {
        await dispatch(inactiveContactChats([payload._id]));
      }
    }
    if (setDisable) setDisable(false);
  };

  const updateContactData = ({
    field,
    value,
  }: {
    field: string;
    value:
      | undefined
      | null
      | string
      | boolean
      | number
      | string[]
      | {
          _id: string;
          name: string;
        }
      | {
          _id: string;
          name: string;
        }[];
  }) => {
    if (selectedLocalContact) {
      dispatch(
        reselectLocalContact({ ...selectedLocalContact, [field]: value })
      );
    } else {
      dispatch(reselectLocalContact(null));
    }
  };

  const saveAvatar = async (id: string | null) => {
    const action = await dispatch(
      updateUser({
        ...selectedLocalContact,
        teams: selectedLocalContact?.teams?.map((_t) => {
          if (typeof _t === "string") return _t;
          return _t._id;
        }),
        tags: selectedLocalContact?.tags?.map((_t) => {
          if (typeof _t === "string") return _t;
          return _t._id;
        }),
        customers: selectedLocalContact?.customers?.map((_t) => {
          if (typeof _t === "string") return _t;
          return _t._id;
        }),
        avatar: id,
      })
    );
    if (
      action?.payload !== null &&
      typeof action?.payload?._id !== "undefined"
    ) {
      const { payload } = await dispatch(
        selectUser({
          _id: selectedLocalContact?._id || "",
          isContact: true,
        })
      );
      if (payload !== null && typeof payload?._id !== "undefined") {
        dispatch({
          type: "chats/changeUserChat",
          payload,
        });
        dispatch({
          type: "messages/changeUserMessage",
          payload,
        });
        dispatch({
          type: "users/changeUser",
          payload,
        });
      }
    }
  };

  const infos = {
    tags: {
      defaultValue:
        tagsValue.current.length === 0
          ? tagsCallback().defaultValue
          : tagsValue.current,
      disabled: selectedLocalContact?.deleted || false,
      focusId,
      setFocusId,
      isLoadingDropdown,
      setIsLoadingDropdown,
      options: tagsOptions.length === 0 ? tagsCallback().options : tagsOptions,
      setOptions: setTagsOptions,
      onChange: updateContactData as ({
        field,
        value,
      }: {
        field: string;
        value: string[];
      }) => Promise<void>,
    },
    avatar: {
      onRemove:
        selectedLocalContact?.spam ||
        selectedLocalContact?.deleted ||
        (!isAdmin &&
          !location.pathname.includes("profile") &&
          !location.pathname.includes("contact"))
          ? undefined
          : async () => {
              dispatch(
                reselectLocalContact({
                  ...selectedLocalContact,
                  avatar: undefined,
                })
              );
              await saveAvatar(null);
            },
      onChange:
        selectedLocalContact?.spam ||
        selectedLocalContact?.deleted ||
        (!isAdmin &&
          !location.pathname.includes("profile") &&
          !location.pathname.includes("contact"))
          ? undefined
          : async (event: ChangeEvent<HTMLInputElement>) => {
              const input = event.target as HTMLInputElement;
              if (!input.files?.length) {
                return;
              }
              const _file = input.files[0];
              const { payload } = await dispatch(uploadAvatar([_file]));
              if (
                selectedLocalContact &&
                payload &&
                typeof payload !== "string"
              ) {
                const createdFile = payload as IFile[];
                dispatch(
                  reselectLocalContact({
                    ...selectedLocalContact,
                    avatar: createdFile[0],
                  })
                );
                if (typeof createdFile[0]?._id !== "undefined") {
                  await saveAvatar(createdFile[0]?._id);
                }
              }
            },
    },
    active: {
      disabled:
        selectedLocalContact?.spam ||
        selectedLocalContact?.deleted ||
        (!isAdmin && !location.pathname.includes("contact")),
    },
    readonly:
      selectedLocalContact?.spam ||
      selectedLocalContact?.deleted ||
      (!isAdmin && !location.pathname.includes("contact")),
    onChange: {
      name: async (e: onChangeEvent, { value }: { value: onChangeValue }) => {
        if (typeof value === "string") {
          updateContactData({
            field: "name",
            value,
          });
        }
      },
      active: async (e: onChangeEvent, { value }: { value: onChangeValue }) => {
        try {
          if (typeof value === "boolean") {
            updateContactData({
              field: "active",
              value,
            });
          }
        } catch (error) {
          // console.log(error);
        }
      },
      email: async (e: onChangeEvent, { value }: { value: onChangeValue }) => {
        if (
          selectedContact?.roles?.includes(RoleUser.CONTACT) &&
          typeof value === "string"
        ) {
          updateContactData({
            field: "email",
            value: value?.trim()?.toLowerCase(),
          });
        }
      },
      phone: async (value: string) => {
        // if (typeof e.target.value === "string") {
        updateContactData({
          field: "phone",
          value,
        });
        // }
      },
      code: async (e: React.ChangeEvent<HTMLInputElement>) => {
        if (typeof e.target.value === "string") {
          updateContactData({
            field: "code",
            value: e.target.value.replace(/\D+/g, ""),
          });
        }
      },
    },
  };

  const customers = {
    dropdown: {
      defaultValue:
        customersValue.current.length === 0
          ? customersCallback().defaultValue
          : customersValue.current,
      loading: isLoadingDropdown && focusId === "dropdown-relation-customers",
      setIsLoadingDropdown,
      setOptions: setCustomersOptions,
      onFocus: () => {
        setFocusId("dropdown-relation-customers");
      },
      onChange: (value: string[]) => {
        customersValue.current = removeDuplicates(value);
      },
      onBlur: () => {
        setFocusId("");
      },
      onOpen: async () => {
        setIsLoadingDropdown(true);
        const payload = await customersService.search({
          skip: 0,
          limit: 10,
          filter: "",
          deleted: false,
        });
        setIsLoadingDropdown(false);
        const _optionsCustomers: object[] = [];
        payload.results.map((_customer: ICustomer) =>
          _optionsCustomers.push({
            key: _customer._id,
            text: _customer?.name ? _customer?.name : _customer?.fullname,
            value: _customer._id,
          })
        );
        setCustomersOptions(_optionsCustomers);
      },
      onSearch: async (e: DropdownSearchInputProps) => {
        setIsLoadingDropdown(true);
        const payload = await customersService.search({
          skip: 0,
          limit: 10,
          filter: e.target.value,
          deleted: false,
        });
        setIsLoadingDropdown(false);
        const _optionsCustomers: ddOptions[] = customersOptions;
        payload.results.map((_customer: ICustomer) => {
          if (
            _optionsCustomers.filter(
              (_opCustomer) => _opCustomer.key === _customer._id
            )[0]
          ) {
            return false;
          }
          _optionsCustomers.push({
            key: _customer._id,
            text: _customer?.name ? _customer?.name : _customer?.fullname,
            value: _customer._id,
          });
          return true;
        });
        setCustomersOptions(_optionsCustomers);
      },
      options:
        customersOptions.length === 0
          ? customersCallback().options
          : customersOptions,
    },
    modal: {
      show: showModal,
      setShow: setShowModal,
      onCancel: () => {
        setShowModal(false);
        setVisible(false);
      },
      onCreate: async () => {
        setVisible(false);
      },
      onSave: async () => {
        setShowModal(false);
      },
      disabled:
        customersValue.current.length === 0 ||
        selectedLocalContact?.deleted ||
        false,
    },
    sidebar: {
      visible,
      setVisible,
    },
    relation: {
      onClickCreation: () => {
        if (location.pathname.includes("/settings/profile"))
          navigate("/settings/profile#customers");
        setVisible(true);
      },
      onClickRelation: () => {
        setShowModal(true);
      },
    },
  };

  const getModalTitle = () => {
    if (showSpamModal) {
      if (!selectedLocalContact?.spam) {
        return "Deseja marcar este contato como SPAM?";
      }
      return "Deseja remover este contato da lista de SPAM?";
    }
    if (showDeleteModal) {
      return "Deseja remover este contato?";
    }
    if (showReactiveModal) {
      return "Deseja reativar este contato?";
    }
    return "";
  };

  const footer = {
    disabled:
      disable ||
      selectedLocalContact?.deleted ||
      typeof selectedLocalContact?.phone === "undefined" ||
      (typeof selectedLocalContact?.code !== "undefined" &&
        selectedLocalContact?.code.length > 0 &&
        selectedLocalContact?.code.length < 11) ||
      typeof selectedLocalContact?.name === "undefined" ||
      !validateEmail(selectedLocalContact?.email || "") ||
      !validNumberLength({
        value: selectedLocalContact?.phone?.replace(/[^0-9.]/g, ""),
      }) ||
      // selectedLocalContact?.phone?.replace(/[^0-9.]/g, "")?.length < 12 ||
      selectedLocalContact?.name?.length < 2,
    deleted: selectedLocalContact?.deleted,
    spam: selectedLocalContact?.deleted,
    back,
    reactive,
    remove,
    save,
    spamAction,
    toggleSidebar: toggleSidebar || false,
    modaltitle: getModalTitle(),
    showDeleteModal,
    setShowDeleteModal,
    showReactiveModal,
    setShowReactiveModal,
    showSpamModal,
    setShowSpamModal,
  };

  return {
    customers,
    contactCallback,
    header,
    footer,
    infos,
    sectionDetails,
  };
};

export default useContacts;
