import React from "react";
import { IFile } from "../../../shared/models/interfaces/file.interface";

const AudioPlayer = ({ file }: { file: IFile }) => {
  const URL = process.env.REACT_APP_API_BASE_URL;
  const { _id, name, path } = file;

  return (
    <div
      role="presentation"
      key={_id}
      className="audioPlayer items-center justify-center flex"
      onClick={(e) => e.preventDefault()}
    >
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <audio
        controlsList="nodownload"
        controls
        preload="metadata"
        src={`${URL}/api/files/${path}/${name}`}
        title={name}
      />
    </div>
  );
};

export default AudioPlayer;
