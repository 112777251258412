/* eslint-disable no-restricted-syntax */
import {
  ExternalAppType,
  IAgideskNewTicket,
  IAgideskTeam,
  IAgideskTicketFilter,
  IExternalAppAgidesk,
  IExternalAppWhatsApp,
  IExternalAppBotmaker,
  INewExternalapp,
  IAgideskUpdateTicket,
} from "../slices/externalapps.slice";
import instances from "./instances";

const externalapps = async ({ _id }: { _id: string }) => {
  try {
    const response = await instances.instanceExternalapps.get(`/${_id}`);
    // console.log("data:", response.data);
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const uploadFile = async ({
  _files,
  id,
}: {
  _files: File[];
  id: string | undefined;
}): Promise<string[] | string | null> => {
  try {
    const formData = new FormData();
    for (const _file of _files) {
      formData.append("files", _file);
    }
    if (typeof id !== "undefined") formData.append("id", id);
    formData.append("type", ExternalAppType.AGIDESK);
    const response = await instances.instanceExternalapps.post(
      "/upload",
      formData
    );
    if (response && response.data && !response.data.message) {
      if (response.data.length > 0) {
        return response.data;
      }
      return null;
    }
    return response.data.message;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const searchAgideskTicket = async ({
  id,
  contacts,
  chat,
  skip,
  limit,
  filter,
  priority,
  follower,
}: IAgideskTicketFilter) => {
  try {
    const response = await instances.instanceExternalapps.get(`/ticket`, {
      params: {
        id,
        type: ExternalAppType.AGIDESK,
        contacts,
        chat,
        skip,
        limit,
        filter,
        priority: priority || undefined,
        follower: follower || undefined,
      },
    });
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const createAgideskTicket = async ({
  id,
  ticket,
}: {
  id: string | undefined;
  ticket: IAgideskNewTicket;
}) => {
  try {
    const data = {
      id,
      type: ExternalAppType.AGIDESK,
      ticket,
    };
    const response = await instances.instanceExternalapps.post(`/ticket`, data);
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const updateAgideskTicket = async ({
  id,
  ticket,
}: {
  id: string | undefined;
  ticket: IAgideskUpdateTicket;
}) => {
  try {
    const data = {
      id,
      type: ExternalAppType.AGIDESK,
      ticket,
    };
    const response = await instances.instanceExternalapps.post(
      `/ticket/comment`,
      data
    );
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const agideskImport = async ({
  data,
  externalappid,
}: {
  data: IAgideskTeam[];
  externalappid: string;
}) => {
  try {
    const _data = {
      externalappid,
      data,
    };
    const response = await instances.instanceAgidesk.post(`/import`, _data);
    if (response && typeof response.data !== "undefined" && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const searchAgideskTeamUsers = async ({
  externalappid,
  teamid,
}: {
  externalappid?: string;
  teamid: string;
}) => {
  try {
    const response = await instances.instanceAgidesk.get(
      `/teams/${teamid}/users`,
      {
        params: {
          skip: 0,
          // limit: 30,
          externalappid,
        },
      }
    );
    if (response && typeof response.data !== "undefined" && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    return null;
  }
};

const searchAgideskTeams = async ({
  externalappid,
  skip,
  limit,
  filter,
}: {
  externalappid?: string;
  skip?: number;
  limit?: number;
  filter: string;
}) => {
  try {
    const response = await instances.instanceAgidesk.get("/teams", {
      params: {
        skip,
        limit,
        externalappid,
        filter,
      },
    });
    if (response && typeof response.data !== "undefined" && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    return null;
  }
};

const searchAgideskServices = async ({
  externalappid,
  skip,
  limit,
  filter,
  main,
}: {
  externalappid?: string;
  skip?: number;
  limit?: number;
  main?: boolean;
  filter: string;
}) => {
  try {
    const response = await instances.instanceAgidesk.get("/services", {
      params: {
        skip,
        limit,
        main,
        externalappid,
        filter,
      },
    });
    if (response && typeof response.data !== "undefined" && response.data) {
      return response.data;
    }
    return response?.data;
  } catch (error) {
    return null;
  }
};

const searchBotmakerChannels = async ({
  externalappid,
  skip,
  limit,
  filter,
  main,
}: {
  externalappid?: string;
  skip?: number;
  limit?: number;
  main?: boolean;
  filter: string;
}) => {
  try {
    const response = await instances.instanceBotmaker.get("/channels", {
      params: {
        skip,
        limit,
        main,
        externalappid,
        filter,
      },
    });
    if (response && typeof response.data !== "undefined" && response.data) {
      return response.data;
    }
    return response?.data;
  } catch (error) {
    return null;
  }
};

const validateVtexAPI = async ({
  externalappid,
}: {
  externalappid: string;
}) => {
  try {
    const response = await instances.instanceVtex.get("/validate", {
      params: {
        externalappid,
      },
    });
    if (response && typeof response.data !== "undefined" && response.data) {
      return response.data;
    }
    return response?.data;
  } catch (error) {
    return null;
  }
};

const searchExternalApps = async ({
  skip,
  limit,
  filter,
}: {
  skip?: number;
  limit?: number;
  filter: object;
}) => {
  try {
    const response = await instances.instanceExternalapps.get("", {
      params: {
        skip,
        limit,
        filter: JSON.stringify(filter),
      },
    });
    if (response && typeof response.data !== "undefined" && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    return null;
  }
};

const searchWhatsAppExternalAppsToChat = async ({
  teams,
}: {
  teams: string[];
}) => {
  try {
    const _types = ["WHATSAPP"];
    const response = await instances.instanceExternalapps.get("/chats", {
      params: {
        types: _types,
        teams,
      },
    });
    if (response && typeof response.data !== "undefined" && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    return null;
  }
};
const searchExternalAppsToChat = async ({
  teams,
  types,
}: {
  teams: string[];
  types: ExternalAppType[];
}) => {
  try {
    const response = await instances.instanceExternalapps.get("/chats", {
      params: {
        types,
        teams,
      },
    });
    if (response && typeof response.data !== "undefined" && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    return null;
  }
};

const deleteExternalApps = async ({ ids }: { ids: string[] }) => {
  try {
    const data = { id: ids };
    const response = await instances.instanceExternalapps.delete("", { data });
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const createExternalApps = async ({
  _externalapp,
}: {
  _externalapp: INewExternalapp;
}) => {
  try {
    const data = _externalapp;
    const response = await instances.instanceExternalapps.post("", data);
    if (response && typeof response.data !== "undefined" && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    return null;
  }
};

const update = async ({
  _externalapp,
}: {
  _externalapp:
    | IExternalAppAgidesk
    | IExternalAppWhatsApp
    | IExternalAppBotmaker;
}) => {
  try {
    const data = _externalapp;
    const response = await instances.instanceExternalapps.patch(
      `${data._id}`,
      data
    );
    if (response && typeof response.data !== "undefined" && response.data) {
      return response.data;
    }
    return response.data;
  } catch (error) {
    return null;
  }
};

const types = async () => {
  try {
    const response = await instances.instanceExternalapps.get("/types");
    if (response && typeof response.data !== "undefined" && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    return null;
  }
};

const externalappsService = {
  uploadFile,
  createAgideskTicket,
  updateAgideskTicket,
  searchAgideskTicket,
  searchBotmakerChannels,
  searchAgideskServices,
  searchAgideskTeams,
  searchAgideskTeamUsers,
  agideskImport,
  externalapps,
  deleteExternalApps,
  update,
  createExternalApps,
  searchWhatsAppExternalAppsToChat,
  searchExternalAppsToChat,
  searchExternalApps,
  types,
  validateVtexAPI,
};

export default externalappsService;
