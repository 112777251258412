/* eslint-disable no-nested-ternary */
import React from "react";
import { PasswordForm } from "../features/auth/components/Forms";
import AuthLayoutPage from "./AuthLayout.page";
import { useAppSelector } from "../hooks/redux/hooks";
import { UserType } from "../slices/users.slice";

const PasswordPage = ({ widgetType }: { widgetType?: UserType }) => {
  const { isLoadingAuth } = useAppSelector((state) => state.auth);

  return (
    <AuthLayoutPage widgetType={widgetType}>
      {!isLoadingAuth ? (
        <h2 className="my-4 text-center text-[24px] font-extrabold text-gray-900">
          Vamos lá!
          <br />
          Informe sua nova senha!
        </h2>
      ) : (
        <h2 className="my-4 text-center text-[24px] font-extrabold text-gray-900">
          Sua senha está sendo alterada!
        </h2>
      )}
      <PasswordForm widgetType={widgetType} />
    </AuthLayoutPage>
  );
};

PasswordPage.defaultProps = {
  widgetType: UserType.NONE,
};

export default PasswordPage;
