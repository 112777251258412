/* eslint-disable react/no-danger */
import React, { useEffect, useState } from "react";
import ReactInputMask from "react-input-mask";
import { useTranslation } from "react-i18next";
import { IUser, RoleUser, UserStatus } from "../../slices/users.slice";
import handleMaskLength from "../utils/handleMaskLength";
import NoAvatar from "./NoAvatar.component";
import Avatar from "./Avatar.component";
// import Button from "./Buttons/Button.component";
import DropdownMenu from "../../features/messages/components/DropdownMenu.component";
import Tag from "../../features/chats/components/Tag.component";
import { useAppSelector } from "../../hooks/redux/hooks";

const UserCard = ({
  item,
  sessionAdmin,
  admin,
  removed,
  viaAgitalks,
  viaWhatsApp,
  toggleList,
  groupShowDetailsUser,
  groupHandleAdmin,
  groupDeleteUser,
  groupAddUser,
}: {
  item: IUser;
  sessionAdmin?: boolean;
  admin?: boolean;
  removed?: boolean;
  viaAgitalks?: () => void;
  viaWhatsApp?: () => void;
  groupShowDetailsUser?: () => void;
  groupHandleAdmin?: () => void;
  groupDeleteUser?: () => void;
  groupAddUser?: () => void;
  toggleList?: string;
}) => {
  const { t } = useTranslation();
  const { user } = useAppSelector((state) => state.auth);
  const { selectedChat } = useAppSelector((state) => state.chats);
  // const URL = process.env.REACT_APP_API_BASE_URL;
  const { name, email, phone, status, roles, customers, teams } = item;
  const avatar = item?.avatar;
  const [_status, setStatus] = useState(status);
  const [_name, setName] = useState(name);
  const [_phone, setPhone] = useState(phone);
  const [_avatar, setAvatar] = useState(avatar);
  const [_customer, setCustomer] = useState(
    typeof customers !== "undefined" &&
      customers?.length > 0 &&
      typeof customers[0]?.name !== "undefined"
      ? `${customers[0]?.name}${
          customers.length > 1 ? ` (e mais ${customers.length - 1})` : ""
        }`
      : undefined
  );
  const [_team, setTeam] = useState(
    typeof teams !== "undefined" &&
      teams?.length > 0 &&
      typeof teams[0]?.name !== "undefined"
      ? `${teams[0]?.name}${
          teams.length > 1 ? ` (e mais ${teams.length - 1})` : ""
        }`
      : undefined
  );

  const hasToggle = typeof toggleList !== "undefined";

  useEffect(() => {
    setStatus(item.status);
    setName(item.name);
    setPhone(item.phone);
    setAvatar(item.avatar);
    setCustomer(
      typeof customers !== "undefined" &&
        customers?.length > 0 &&
        typeof customers[0]?.name !== "undefined"
        ? `${customers[0]?.name}${
            customers.length > 1 ? ` (e mais ${customers.length - 1})` : ""
          }`
        : undefined
    );
    setTeam(
      typeof teams !== "undefined" &&
        teams?.length > 0 &&
        typeof teams[0]?.name !== "undefined"
        ? `${teams[0]?.name}${
            teams.length > 1 ? ` (e mais ${teams.length - 1})` : ""
          }`
        : undefined
    );
  }, [item]);

  const getOnClick = () => {
    if (hasToggle) {
      if (toggleList !== "contacts" && viaAgitalks) {
        viaAgitalks();
      } else if (viaWhatsApp) viaWhatsApp();
    }
  };

  const getHoverCss = () => {
    if (hasToggle) {
      return "hover:bg-agitalks-alpha30 hover:rounded-xl";
    }
    if (item._id !== user?._id) {
      if (removed) {
        return "hover:bg-red-alpha20 hover:rounded-md";
      }
      return "hover:bg-agitalks-alpha30 hover:rounded-md";
    }
    return "hover:cursor-default";
    // if (item.status === UserStatus.ONLINE) {
    //   return "hover:border-green hover:border-l-[6px] hover:border hover:rounded-xl";
    // }
    // return "hover:border-red hover:border-l-[6px] hover:border hover:rounded-xl";
  };

  const getTag = () => {
    if (removed) return <Tag tagType="default" title="Removido" />;
    if (item._id === selectedChat?.created_by)
      return (
        <>
          <Tag tagType="default" title="Admin" />
          <Tag tagType="default" title="Criou o grupo" />
        </>
      );
    if (admin) return <Tag tagType="default" title="Admin" />;
    return null;
  };

  const moreOptionsMenu = () => {
    const getItems = () => {
      if (item._id === selectedChat?.created_by) {
        return [
          {
            _id: 0,
            icon: <i className="las la-info-circle text-[14px] mr-2" />,
            text: <span className="text-[12px]">Detalhes do agente</span>,
            description: null,
            onClick() {
              if (groupShowDetailsUser) groupShowDetailsUser();
            },
          },
        ];
      }
      if (sessionAdmin && removed) {
        return [
          {
            _id: 0,
            icon: <i className="las la-info-circle text-[14px] mr-2" />,
            text: <span className="text-[12px]">Detalhes do agente</span>,
            description: null,
            onClick() {
              if (groupShowDetailsUser) groupShowDetailsUser();
            },
          },
          {
            _id: 1,
            icon: <i className="las la-plus-circle text-[14px] mr-2" />,
            text: (
              <span className="text-[12px]">Adicionar novamente ao grupo</span>
            ),
            description: null,
            onClick() {
              if (groupAddUser) groupAddUser();
            },
          },
        ];
      }
      if (sessionAdmin) {
        return [
          {
            _id: 0,
            icon: <i className="las la-info-circle text-[14px] mr-2" />,
            text: <span className="text-[12px]">Detalhes do agente</span>,
            description: null,
            onClick() {
              if (groupShowDetailsUser) groupShowDetailsUser();
            },
          },
          {
            _id: 1,
            icon: admin ? (
              <i className="las la-user-minus text-[14px] text-red mr-2" />
            ) : (
              <i className="las la-user-check text-[14px] mr-2" />
            ),
            text: admin ? (
              <span className="text-[12px] text-red">
                Remover da lista de admins
              </span>
            ) : (
              <span className="text-[12px]">Adicionar à lista de admin</span>
            ),
            description: null,
            onClick() {
              if (groupHandleAdmin) groupHandleAdmin();
            },
          },
          {
            _id: 2,
            icon: (
              <i className="las la-minus-circle text-red text-[14px] mr-2" />
            ),
            text: (
              <span className="text-red text-[12px]">Remover do grupo</span>
            ),
            description: null,
            onClick() {
              if (groupDeleteUser) groupDeleteUser();
            },
          },
        ];
      }
      return [
        {
          _id: 0,
          icon: <i className="las la-info-circle text-[14px] mr-2" />,
          text: <span className="text-[12px]">Detalhes do agente</span>,
          description: null,
          onClick() {
            if (groupShowDetailsUser) groupShowDetailsUser();
          },
        },
      ];
    };
    return (
      <div className="rounded-full flex flex-col items-end justify-end overflow-visible">
        {item?._id !== user?._id ? (
          <DropdownMenu
            id="groupchat"
            icon={
              <button
                className="las la-ellipsis-h mr-2 text-[24px] border-0 text-gray-text bg-none"
                title={`Ações para ${item?.name}`}
                aria-label={`Ações para ${item?.name}`}
                type="button"
              />
            }
            items={getItems()}
          />
        ) : (
          <span className="text-gray-text mr-2 mt-1 text-[12px]">(Você)</span>
        )}
        {getTag()}
      </div>
    );
  };

  return (
    <div
      role="presentation"
      onClick={getOnClick}
      className={`${
        hasToggle ? "flex relative" : "flex relative items-start"
      } max-h-[250px] p-2 pl-0 border-l-[6px] border-l-transparent border border-transparent cursor-pointer ${
        removed ? "bg-red-alpha20" : ""
      } ${getHoverCss()}`}
    >
      {/* AVATAR */}
      <div className={`${hasToggle ? "mx-2" : "ml-2 mr-4"}`}>
        <div
          className={`relative ${
            hasToggle ? "w-[40px] h-[40px]" : "w-[35px] h-[35px]"
          }`}
        >
          {_avatar && _avatar?.path && _avatar?.name ? (
            <Avatar path={_avatar?.path} name={_avatar?.name} title={_name} />
          ) : (
            <NoAvatar
              displaytext={_name || "--"}
              elementClass="p-0"
              labelClass={`${hasToggle ? "text-[14px]" : "text-[13px]"}`}
            />
          )}
          {roles?.includes(RoleUser.AGENT) && !selectedChat?.groupchat ? (
            <div
              title={t("card.status") || ""}
              className={`flex absolute ${
                hasToggle ? "w-[12px] h-[12px]" : "w-[10px] h-[10px]"
              } ${
                _status === UserStatus.ONLINE ? "bg-green" : "bg-red"
              } rounded-full bottom-0 right-0`}
            />
          ) : null}
        </div>
      </div>
      {/* USER INFO */}
      <div
        className={`flex relative flex-1 flex-col ${
          hasToggle ? "ml-1 max-w-[80%]" : ""
        }`}
      >
        <span
          className={`font-semibold max-w-full ${
            hasToggle ? "text-[14px]" : "text-[13px]"
          } word-wrap-2`}
          dangerouslySetInnerHTML={{
            __html: `<b>${_name}</b>`,
          }}
        />
        {_customer ? (
          <div className="items-center flex max-w-full">
            <i
              className={`mr-1 las la-building ${
                hasToggle ? "text-[13px]" : "text-[12px]"
              }`}
            />
            <h2
              className={`${
                hasToggle ? "text-[13px]" : "text-[12px] w-[70%]"
              } font-semibold text-ellipsis text-gray-777 word-wrap-1`}
            >
              {_customer}
            </h2>
          </div>
        ) : null}
        {_team ? (
          <div className="items-center flex max-w-full">
            <i
              className={`mr-1 las la-user-friends ${
                hasToggle ? "text-[13px]" : "text-[12px]"
              }`}
            />
            <h2
              className={`${
                hasToggle ? "text-[13px]" : "text-[12px] w-[70%]"
              } font-semibold text-gray-777 word-wrap-1`}
            >
              {_team}
            </h2>
          </div>
        ) : null}
        <div className="items-center flex max-w-full">
          <i
            className={`mr-1 las la-at ${
              hasToggle ? "text-[13px]" : "text-[12px]"
            }`}
          />
          <h2
            className={`${
              hasToggle ? "text-[13px]" : "text-[12px] w-[70%]"
            } text-gray-777 word-wrap-1`}
          >
            {email}
          </h2>
        </div>
        <div className="items-center flex max-w-full">
          <i
            className={`mr-1 lab la-whatsapp ${
              hasToggle ? "text-[13px]" : "text-[12px]"
            }`}
          />
          <h2
            className={`${
              hasToggle ? "text-[13px]" : "text-[12px] w-[70%]"
            } text-gray-777 word-wrap-1`}
          >
            {_phone ? (
              <ReactInputMask
                maskPlaceholder={null}
                readOnly
                style={{
                  background: "transparent",
                  cursor: hasToggle ? "pointer" : "default",
                }}
                mask={handleMaskLength({ value: _phone })}
                value={_phone}
              />
            ) : (
              t("no-phone")
            )}
          </h2>
        </div>
      </div>
      {/* ACTIONS */}
      {!hasToggle
        ? moreOptionsMenu()
        : // <div className="flex flex-col">
          //   <Button
          //     extraClass="px-1"
          //     height="28px"
          //     width="60px"
          //     minWidth={false}
          //     green
          //     inverted
          //     label="Tornar admin"
          //     icon="las la-user-check"
          //   />
          //   <Button
          //     height="28px"
          //     width="60px"
          //     extraClass="mt-2 px-1"
          //     minWidth={false}
          //     red
          //     inverted
          //     label="Remover"
          //     icon="las la-minus-circle"
          //   />
          // </div>
          null}
    </div>
  );
};

UserCard.defaultProps = {
  viaAgitalks: undefined,
  viaWhatsApp: undefined,
  toggleList: undefined,
  groupShowDetailsUser: undefined,
  groupHandleAdmin: undefined,
  groupDeleteUser: undefined,
  groupAddUser: undefined,
  sessionAdmin: undefined,
  admin: undefined,
  removed: undefined,
};

export default UserCard;
