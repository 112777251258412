import getDeviceInfo from "../shared/utils/getDeviceInfo";
import instances from "./instances";

const { browserName, appName, userAgent } = getDeviceInfo();
const device = `${browserName}|${userAgent}|${appName}`;

const enable = async ({ token }: { token: string }) => {
  try {
    const data = { notification_token: token, device_type: device };
    const response = await instances.instanceNotification.patch(
      "/push/enable",
      data
    );
    return response?.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const disable = async () => {
  try {
    const data = { device_type: device };
    const response = await instances.instanceNotification.patch(
      "/push/disable",
      data
    );
    return response?.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};
const send = async ({
  body,
  link,
  title,
  users,
}: {
  body: string;
  link?: string;
  title: string;
  users?: string[];
}) => {
  try {
    const URL = process.env.REACT_APP_FRONTEND_URL;
    const data = {
      title,
      body,
      link: link || "https://agitalks.com",
      icon: `${URL}/logo.png`,
      users,
    };
    const response = await instances.instanceNotification.post("/send", data);
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const notificationService = {
  enable,
  disable,
  send,
};

export default notificationService;
