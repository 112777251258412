const preserveFormats = ["_", "*", "~", "--", "```"];
const escapeUrlsText = (text: string) => {
  const urlPattern =
    /(?:https?|ftp):\/\/[^\s<]+|[\w.-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}/gm;
  let _text = text;
  preserveFormats.map((separator, index) => {
    _text = _text.replace(new RegExp(urlPattern), (match) => {
      const escapeTag = `escapeTag${index}`;
      const regexp = new RegExp(`\\${separator}((.*?)?)\\${separator}`, "gmi");
      return regexp.test(match)
        ? match.replace(regexp, `<${escapeTag}>$1</${escapeTag}>`)
        : match;
    });
  });
  return _text;
};
const sanitizeUrlsText = (text: string) => {
  let _text = text;
  preserveFormats.map((separator, index) => {
    const escapeTag = `escapeTag${index}`;
    const regexp = new RegExp(`<${escapeTag}>((.*?)?)</${escapeTag}>`, "gmi");
    _text = _text.replace(regexp, `${separator}$1${separator}`);
  });
  return _text;
};
const useFormatText = (text: string, commenttemplate?: boolean) => {
  const breakTag = "<br>";
  let _text = text;

  if (typeof commenttemplate !== "undefined" && commenttemplate) {
    _text = _text
      .replace(/<strong>((.*?)?)<\/strong>/gm, "*$1*")
      .replace(/<b>((.*?)?)<\/b>/gm, "*$1*")
      .replace(/<i>((.*?)?)<\/i>/gm, "_$1_")
      .replace(/<em>((.*?)?)<\/em>/gm, "_$1_")
      .replace(/<s>((.*?)?)<\/s>/gm, "~$1~")
      .replace(/<img .*?>/g, "");
  } else {
    _text = escapeUrlsText(_text)
      .replace(/\*((.*?)?)\*/gm, "<b>$1</b>")
      .replace(/_((.*?)?)_/gm, "<i>$1</i>")
      .replace(/~((.*?)?)~/gm, "<s>$1</s>")
      .replace(/```((.*?)?)```/gm, "<u>$1</u>")
      .replace(/--((.*?)?)--/gm, "<tt>$1</tt>")
      .replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, `$1${breakTag}$2`);
  }

  _text = sanitizeUrlsText(_text);
  return _text.indexOf("externalapplink") === -1
    ? _text
        .replace(/&amp;/gm, "&")
        .replace(
          /(http[s]?:\/\/[^\s<]+)/g,
          '<a class="message-link" href="$&" target="_blank" title="$&" rel="noopener noreferrer">$&</a>'
        )
    : _text;
};

export default useFormatText;
