/* eslint-disable no-nested-ternary */
import React from "react";
import { HeaderGroup } from "react-table";
import { Table } from "semantic-ui-react";

const TableHeader = ({
  headerGroups,
  className,
  selection,
}: {
  headerGroups: HeaderGroup<object>[];
  className: string;
  selection?: boolean;
}) => {
  return (
    <Table.Header className={`p-0 m-0 ${className}`}>
      {headerGroups.map((headerGroup) => (
        <Table.Row
          {...headerGroup.getHeaderGroupProps({
            className,
          })}
          className={className}
        >
          {headerGroup.headers.map((column) => {
            if (!selection && column.id === "selection") return false;

            const _width = [
              "name",
              "agideskteam",
              "teams",
              "customers",
            ].includes(column.id)
              ? 4
              : ["title", "user.name", "target", "statusmessage"].includes(
                  column.id
                )
              ? 3
              : ["chat.users.name", "phone"].includes(column.id)
              ? 2
              : 1;
            return (
              <Table.HeaderCell
                width={
                  !["selection", "expander"].includes(column.id) ? _width : 1
                }
                {...column.getHeaderProps(
                  column.getSortByToggleProps({
                    title: !["selection", "expander"].includes(column.id)
                      ? column.render("Header")?.toString()
                      : column.id === "selection"
                      ? "Selecionar todos"
                      : column.id === "expander"
                      ? "Expandir todos"
                      : "",
                  })
                )}
              >
                <div
                  title={
                    !["selection", "expander"].includes(column.id)
                      ? column.render("Header")?.toString()
                      : column.id === "selection"
                      ? "Selecionar todos"
                      : column.id === "expander"
                      ? "Expandir todos"
                      : ""
                  }
                  className={`flex justify-between items-center ${
                    ["selection", "expander"].includes(column.id)
                      ? className
                      : ""
                  }`}
                >
                  <div
                    className={`flex justify-center ${
                      !["selection", "expander"].includes(column.id)
                        ? "mx-auto"
                        : "mx-auto"
                    }`}
                  >
                    <div className="text-[14px] cursor-pointer">
                      {column.render("Header")}
                    </div>
                  </div>
                  {!["selection", "expander"].includes(column.id) ? (
                    column.isSorted ? (
                      column.isSortedDesc ? (
                        <i className="las la-sort-down" />
                      ) : (
                        <i className="las la-sort-up" />
                      )
                    ) : (
                      <i className="las la-sort" />
                    )
                  ) : null}
                </div>
              </Table.HeaderCell>
            );
          })}
        </Table.Row>
      ))}
    </Table.Header>
  );
};

TableHeader.defaultProps = {
  selection: true,
};

export default TableHeader;
