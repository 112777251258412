/* eslint-disable no-shadow */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AsyncState } from "../shared/models/interfaces/asyncstate.interface";
import { ITeam } from "./teams.slice";
import { IChat } from "./chats.slice";
import { IUser } from "./users.slice";
import surveysService from "../services/surveys.service";
import customToast from "../shared/utils/customToast";
import { IToastType } from "../shared/models/types/Toast.type";

export enum SurveyType {
  APPROVAL = "APPROVAL",
  FEELING = "FEELING",
  NPS = "NPS",
  QUALITY = "QUALITY",
}

export interface ICardItems {
  title: string;
  type: string;
  value: number;
  icon?: string;
  color?: string;
  avg?: boolean;
  count?: number;
  percent?: boolean;
  total?: number;
  divider?: boolean;
  extraclass?: string;
}

export interface ISurvey {
  default?: boolean;
  active?: boolean;
  content?: string;
  type?: string;
  _id?: string;
  name?: string;
  teams?: ITeam[];
  created_at?: string;
  updated_at?: string;
  deleted?: boolean;
  deleted_at?: string;
  created_by?: IUser | null;
  updated_by?: IUser | null;
  deleted_by?: IUser | null;
  sendsurvey?: boolean;
}

export interface IRatingSelected {
  value: number;
  type: string;
  action?: string;
}

export interface IRatingResultData {
  id?: string | null;
  value: string | number;
  type: string;
}

export interface IRatingData {
  code?: string;
  result?: IRatingResultData[];
  rating?: string;
  user?: string;
  status?: number;
  finished_at?: Date;
}

export interface ISurveyStats {
  sended: number;
  answered: number;
  pending: number;
  expired: number;
  npsavg: number;
  approvalavg: number;
  qualityavg: number;
  feelingavg: number;
}

export interface ISurveyRating {
  _id: string;
  survey: ISurvey;
  chat: IChat;
  status: number;
  result: IRatingResultData[];
  expire_at: string;
  message?: string;
  user?: string;
  rating?: string;
  code?: string;

  finished_by?: IUser;
  finished_at: string;
  updated_by?: IUser;
  updated_at?: string;
  created_by?: IUser;
  created_at?: string;
  deleted_by?: IUser;
  deleted_at?: string;
  tenant?: string;
}

export interface IFilterSurvey {
  skip: number;
  limit: number;
  filter: string;
  deleted: boolean;
  allStatus?: boolean;
}

export interface INewSurvey {
  name: string;
  team?: ITeam[] | [];
  deleted?: boolean;
  active?: boolean;
  content?: string;
}

export type Surveys = ISurvey[];

interface SurveysState extends AsyncState {
  isLoadingSurveys: boolean;
  isLoadingDropdownSurveys: boolean;
  isCreatingSurveys: boolean;
  isRemoving: boolean;
  surveys: ISurvey[];
  totalSurveys: number;
  selectedSurveys: ISurvey | null;
  selectedSurvey: ISurvey | null;
  selectedLocalSurvey: ISurvey | null;
}

const initialState: SurveysState = {
  surveys: [],
  isLoadingSurveys: false,
  isRemoving: false,
  isLoadingDropdownSurveys: false,
  isCreatingSurveys: false,
  totalSurveys: 0,
  selectedSurveys: null,
  selectedSurvey: null,
  selectedLocalSurvey: null,
  isSuccess: false,
  isError: false,
};

export const getSearchSurveys = createAsyncThunk(
  "surveys/search",
  async (filterSurvey: IFilterSurvey, thunkAPI) => {
    try {
      return await surveysService.search(filterSurvey);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

// export const getSurveys = createAsyncThunk(
//   "surveys",
//   async (_groupLimit: IGroupLimit, thunkAPI) => {
//     try {
//       return await surveysService.surveys(_groupLimit);
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error);
//     }
//   }
// );

export const selectSurvey = createAsyncThunk(
  "surveys/selectSurvey",
  async ({ _id }: { _id: string }, thunkAPI) => {
    try {
      return await surveysService.survey({ _id });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateSurvey = createAsyncThunk(
  "surveys/update",
  async (
    { _survey, noToast }: { _survey: ISurvey; noToast?: boolean },
    thunkAPI
  ) => {
    try {
      return await surveysService.update({ _survey });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteSurvey = createAsyncThunk(
  "surveys/delete",
  async (ids: string[], thunkAPI) => {
    try {
      return await surveysService.deleteSurveys({ ids });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createSurvey = createAsyncThunk(
  "surveys/create",
  async (newSurvey: INewSurvey, thunkAPI) => {
    try {
      return await surveysService.create(newSurvey);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const surveysSlice = createSlice({
  name: "surveys",
  initialState,
  reducers: {
    logout() {
      return initialState;
    },
    reselectSurveys(state, action: PayloadAction<ISurvey | null>) {
      state.selectedSurveys = action.payload;
    },
    reselectSurvey(state, action: PayloadAction<ISurvey | null>) {
      state.selectedSurvey = action.payload;
    },
    reselectLocalSurvey(state, action: PayloadAction<ISurvey | null>) {
      state.selectedLocalSurvey = action.payload;
    },
    selectSurveys(state, action: PayloadAction<ISurvey[]>) {
      state.surveys = action.payload || [];
    },
    selectTotalSurveys(state, action: PayloadAction<number>) {
      state.totalSurveys = action.payload || 0;
    },
  },
  extraReducers: (builder) => {
    builder
      // OFFICEHOURS
      .addCase(getSearchSurveys.pending, (state, action) => {
        if (action.meta.arg.limit === 10) {
          state.isLoadingSurveys = true;
        }
        state.isLoadingDropdownSurveys = true;
      })
      .addCase(getSearchSurveys.fulfilled, (state, action) => {
        state.isSuccess = true;
        state.surveys = action.payload.results || [];
        // if (state.totalSurveys <= action.payload.count)
        state.totalSurveys = action.payload.count;
        state.isLoadingSurveys = false;
        state.isLoadingDropdownSurveys = false;
      })
      .addCase(getSearchSurveys.rejected, (state) => {
        state.isError = true;
        state.surveys = [];
        state.isLoadingSurveys = false;
        state.isLoadingDropdownSurveys = false;
      })
      // SHOW CUSTOMER
      .addCase(selectSurvey.pending, (state) => {
        state.isLoadingSurveys = true;
      })
      .addCase(selectSurvey.fulfilled, (state, action) => {
        state.isSuccess = true;
        if (action.payload?._id) {
          state.selectedSurvey = action.payload;
          state.selectedLocalSurvey = action.payload;
        }
        state.isLoadingSurveys = false;
      })
      .addCase(selectSurvey.rejected, (state) => {
        state.isError = true;
        state.selectedSurveys = null;
        state.isLoadingSurveys = false;
      })
      // UPDATE CUSTOMER
      .addCase(updateSurvey.pending, (/* state */) => {
        // state.isLoadingSurveys = true;
      })
      .addCase(updateSurvey.fulfilled, (state, action) => {
        if (
          action?.payload !== null &&
          typeof action?.payload?._id !== "undefined"
        ) {
          state.isSuccess = true;
          if (!action.meta.arg.noToast || action.meta.arg.noToast !== true) {
            customToast({
              type: IToastType.SUCCESS,
              message: `Alteração salva com sucesso!`,
            });
          }
          // } else if (
          //   typeof action.payload?.message === "string" &&
          //   action.payload.message.includes("Error on delete default office hour")
          // ) {
          //   if (!action.meta.arg.noToast || action.meta.arg.noToast !== true) {
          //     customToast({
          //       type: IToastType.WARNING,
          //       message: "Horário definido como padrão!",
          //     });
          //   }
        } else {
          if (!action.meta.arg.noToast || action.meta.arg.noToast !== true) {
            customToast({
              type: IToastType.ERROR,
              message: `Algo deu errado!`,
            });
          }
          state.isError = true;
        }
        state.isLoadingSurveys = false;
      })
      .addCase(updateSurvey.rejected, (state) => {
        state.isError = true;
        state.selectedSurveys = null;
        state.isLoadingSurveys = false;
      })
      .addCase(createSurvey.pending, (state) => {
        state.isCreatingSurveys = true;
      })
      .addCase(createSurvey.fulfilled, (state, action) => {
        if (typeof action?.payload?._id !== "undefined") {
          state.isSuccess = true;
          customToast({
            type: IToastType.SUCCESS,
            message: `Pesquisa criada com sucesso!`,
          });
          const newState = state.surveys;
          newState.push(action.payload);
          state.surveys = newState;
        } else {
          state.isError = true;
          if (
            typeof action?.payload === "string" &&
            action?.payload.includes("duplicate")
          ) {
            customToast({
              type: IToastType.ERROR,
              message: `Opa! Já existe uma pesquisa com esse nome!`,
            });
          } else {
            customToast({
              type: IToastType.ERROR,
              message: `Algo deu errado! Tente novamente.`,
            });
          }
        }
        state.isCreatingSurveys = false;
      })
      .addCase(createSurvey.rejected, (state) => {
        state.isError = true;
        state.isCreatingSurveys = false;
      })
      .addCase(deleteSurvey.pending, (state) => {
        state.isRemoving = true;
      })
      .addCase(deleteSurvey.fulfilled, (state, action) => {
        if (
          action.payload !== null &&
          typeof action.payload !== "undefined" &&
          typeof action.payload?.message !== "string"
        ) {
          state.isSuccess = true;
          customToast({
            type: IToastType.SUCCESS,
            message: `Remoção realizada com sucesso!`,
          });
          // } else if (
          //   typeof action.payload?.message === "string" &&
          //   action.payload.message.includes("Error on delete default office hour")
          // ) {
          //   customToast({
          //     type: IToastType.WARNING,
          //     message: "Horário definido como padrão!",
          //   });
        } else {
          state.isError = true;
          customToast({
            type: IToastType.ERROR,
            message: `Algo deu errado!`,
          });
        }
        state.isRemoving = false;
      })
      .addCase(deleteSurvey.rejected, (state) => {
        state.isError = true;
        state.isRemoving = false;
      });
  },
});

export default surveysSlice.reducer;
export const {
  logout,
  reselectSurveys,
  reselectSurvey,
  reselectLocalSurvey,
  selectSurveys,
  selectTotalSurveys,
} = surveysSlice.actions;
