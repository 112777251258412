import React from "react";
import { useAppSelector } from "../../../hooks/redux/hooks";
import useFormatText from "../../../hooks/useFormatText";
import { IMessage } from "../../../slices/messages.slice";
import stripTags from "../../../shared/utils/stripTags";

const ParentMessage = ({
  color,
  message,
  onClick,
}: {
  color?: string;
  message: IMessage;
  onClick: React.MouseEventHandler<HTMLDivElement>;
}) => {
  const { files, sender } = message;
  const { user } = useAppSelector((state) => state.auth);
  const isYou =
    typeof sender === "string"
      ? user?._id === sender
      : user?._id === sender._id;
  const lastFile = files.length ? files[files.length - 1] : null;
  const isPhoto = lastFile?.mimetype?.includes("image");

  const getValue = () => {
    const _content =
      typeof message?.content !== "undefined" && message.content
        ? stripTags({ value: message.content })
        : null;

    if (_content) {
      return _content.length < 50
        ? _content
        : `${_content.substring(0, 50)}...`;
    }
    if (lastFile && isPhoto) {
      return "Foto";
    }
    return lastFile?.name || "";
  };

  return (
    <div
      role="presentation"
      onClick={onClick}
      className={`flex relative w-full border p-[5px] border-l-[5px] ${
        isYou ? "border-l-green" : "border-l-[gray]"
      } bg-black-alpha07 rounded-[8px] mb-[10px] cursor-pointer`}
    >
      <span className="flex relative flex-col text-[12px]">
        <b
          style={{
            color: isYou ? "" : color,
          }}
        >
          {isYou ? "Você" : sender.name}
        </b>
        <span className="flex items-center">
          {lastFile ? (
            <i
              className={`las ${
                isPhoto ? "la-camera" : "la-file-alt"
              } text-[14px] mr-[2px]"`}
            />
          ) : null}
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: useFormatText(
                stripTags({
                  value: getValue(),
                })
              ),
            }}
          />
        </span>
      </span>
    </div>
  );
};

ParentMessage.defaultProps = {
  color: "",
};

export default ParentMessage;
