import React, { useEffect, useState } from "react";
import { Form } from "semantic-ui-react";
import RtfEditor from "../../../shared/components/RtfEditor.component";
import PreviewModal from "../../../shared/components/Modals/PreviewContentModal.component";
import Button from "../../../shared/components/Buttons/Button.component";
import useCommenttemplates from "../../../hooks/features/useCommenttemplates";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/hooks";
import FilesArea from "../../../shared/components/Files/FilesArea.component";
import { Files, IFile } from "../../../shared/models/interfaces/file.interface";
import { iconType } from "../../messages/components/Sendbox/Thumbs.component";
import LinkButton from "../../../shared/components/Buttons/LinkButton.component";
import CloseButton from "../../../shared/components/Buttons/CloseButton.component";
import { reselectTemplateFiles } from "../../../slices/messages.slice";
import { reselectLocalCommenttemplate } from "../../../slices/commenttemplates.slice";

const Content = () => {
  const URL = process.env.REACT_APP_API_BASE_URL;
  const { content } = useCommenttemplates({});
  const { selectedLocalCommenttemplate } = useAppSelector(
    (state) => state.commenttemplates
  );
  const dispatch = useAppDispatch();
  const [showFiles, setShowFiles] = useState<boolean>(false);

  useEffect(() => {
    // console.log(content.files);
  }, [content.files]);

  const removeFile = (index: number) => {
    let _files = selectedLocalCommenttemplate?.files;
    _files = _files?.slice();
    _files?.splice(index, 1);
    dispatch(
      reselectTemplateFiles({
        files: _files as any,
        currentChanged: true,
      })
    );
    dispatch(
      reselectLocalCommenttemplate({
        ...selectedLocalCommenttemplate,
        files: _files,
      })
    );
  };

  const typeTemplate = (file: IFile, index: number) => {
    const fileType = file.mimetype?.split("/")[0];
    let fileName = file.name || "";
    const arrFilename = fileName.split(".");
    const fileExt = arrFilename[arrFilename.length - 1];
    if (fileName.length > 14) {
      fileName = `${fileName.substring(0, 11)}...${fileExt}`;
    }
    if (typeof fileType !== "undefined") {
      switch (fileType) {
        default:
          return (
            <div
              key={file._id}
              title={file.name}
              className="flex relative items-center justify-center flex-col w-full rounded-[8px] mt-2"
            >
              {iconType(file.mimetype || "", true)}
              <CloseButton
                extraClass="absolute top-0 right-1 z-[2]"
                title="Remover anexo"
                onClick={(e: React.MouseEvent<Element, MouseEvent>) => {
                  e.preventDefault();
                  e.stopPropagation();
                  removeFile(index);
                }}
              />
              <b className="text-[10px] p-[5px] text-ellipsis overflow-hidden text-black">
                {fileName}
              </b>
            </div>
          );
      }
    }
    return null;
  };

  const messageTemplate = (files: Files) => {
    return (
      <Form.Group
        id="content-files"
        className="!mx-[1px] overflow-x-scroll relative flex items-start justify-start border !rounded-[4px] p-2 border-gray-200"
      >
        {files.map((file, index) => (
          <div
            className="px-3 flex relative flex-col max-w-[150px]"
            key={file._id}
          >
            <a
              href={`${URL}/api/files/${file.path}/${file.name}`}
              target="_blank"
              title={file.name}
              rel="noopener noreferrer"
            >
              {typeTemplate(file, index)}
            </a>
          </div>
        ))}
      </Form.Group>
    );
  };

  const sectionFiles = () => {
    return (
      <>
        {typeof selectedLocalCommenttemplate?.files !== "undefined" &&
        selectedLocalCommenttemplate?.files.length > 0 ? (
          <div className="self rounded-[8px] my-4 relative">
            <label>Anexos</label>
            {messageTemplate(selectedLocalCommenttemplate?.files as Files)}
          </div>
        ) : null}
        <FilesArea
          hideLabel
          files={content.files}
          setFiles={content.setFiles}
        />
      </>
    );
  };

  return (
    <>
      <Form>
        <RtfEditor
          initialValue={content.initialValue}
          disabled={content.disabled}
          height="400"
          id={content.id}
          label={content.label}
          onChange={(e, value) => {
            content.onChange({
              field: "content",
              value: value.getContent(),
            });
          }}
        />
        <div className="flex w-full my-4 justify-end">
          <Button
            minWidth
            disabled={content.disabled}
            label="Pré-visualizar"
            onClick={() => {
              content.setShowPreviewModal(true);
            }}
          />
        </div>
        <LinkButton
          label={`
            ${!showFiles ? "Exibir anexos" : "Esconder anexos"}
            ${
              typeof selectedLocalCommenttemplate?.files !== "undefined" &&
              selectedLocalCommenttemplate?.files.length > 0
                ? `(${selectedLocalCommenttemplate?.files.length})`
                : ""
            }
            `}
          onClick={() => setShowFiles((prevState) => !prevState)}
          extraClass="place-self-end mb-2 text-blue-link"
        />
        {showFiles ? sectionFiles() : null}
      </Form>
      {content.showPreviewModal ? (
        <PreviewModal
          type="commenttemplate" // TODO
          instance={{
            commenttemplate: selectedLocalCommenttemplate,
            files: content?.files,
            setFiles: content?.setFiles,
          }}
          setShowPreviewModal={content.setShowPreviewModal}
        />
      ) : null}
    </>
  );
};
export default Content;
