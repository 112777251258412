import React from "react";

const AgideskSVG = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="14.000000pt"
      height="14.000000pt"
      viewBox="0 0 14.000000 14.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,14.000000) scale(0.001367,-0.001367)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M5112 9699 c-289 -28 -552 -172 -723 -398 -63 -83 -135 -227 -727
-1441 -264 -542 -719 -1475 -1012 -2075 -293 -599 -543 -1117 -555 -1151 -98
-256 -77 -571 54 -839 106 -215 275 -379 496 -481 249 -115 587 -117 858 -4
161 67 349 224 449 375 26 39 258 505 516 1035 258 531 712 1460 1007 2065
690 1412 719 1473 750 1580 38 133 52 272 38 397 -67 589 -564 994 -1151 937z"
        />
        <path
          d="M6285 6830 c-86 -14 -188 -46 -281 -90 -468 -218 -704 -745 -558
-1246 34 -120 749 -1661 830 -1792 98 -156 264 -301 440 -383 185 -87 443
-121 637 -85 397 75 705 355 818 746 41 140 43 400 3 557 -22 90 -42 135 -269
618 -108 231 -271 580 -362 775 -91 196 -189 393 -220 440 -64 98 -190 228
-282 290 -92 62 -217 118 -329 147 -109 28 -319 39 -427 23z"
        />
        <path
          d="M4094 2432 c-107 -51 -136 -186 -58 -272 110 -122 304 -49 304 114 0
128 -130 212 -246 158z"
        />
        <path
          d="M5220 2195 c0 -140 -3 -255 -7 -255 -5 0 -17 7 -28 16 -61 46 -245
68 -354 43 -123 -28 -259 -137 -314 -251 -62 -129 -74 -312 -28 -440 98 -280
404 -411 669 -288 l62 29 0 -25 0 -24 160 0 160 0 0 725 0 725 -160 0 -160 0
0 -255z m-141 -515 c66 -33 121 -118 121 -186 0 -70 -58 -156 -126 -189 -40
-19 -115 -22 -156 -6 -41 15 -100 72 -119 116 -45 99 0 212 106 268 43 23 127
21 174 -3z"
        />
        <path
          d="M7770 1725 l0 -725 160 0 160 0 0 192 0 192 117 -192 117 -192 184 0
c154 0 183 2 179 14 -3 8 -76 124 -162 257 l-157 243 18 31 c10 16 78 121 151
233 73 111 133 205 133 207 0 3 -80 5 -177 5 l-178 0 -112 -182 -113 -182 0
412 0 412 -160 0 -160 0 0 -725z"
        />
        <path
          d="M1884 1990 c-162 -52 -282 -184 -325 -360 -20 -83 -16 -222 9 -306
49 -161 172 -286 322 -328 151 -41 257 -28 413 52 4 2 7 -8 7 -22 l0 -26 160
0 160 0 0 495 0 495 -160 0 -160 0 0 -29 0 -30 -45 26 c-100 56 -264 70 -381
33z m285 -313 c24 -12 57 -42 75 -66 28 -39 31 -50 31 -115 0 -62 -4 -78 -27
-114 -82 -124 -257 -126 -343 -4 -27 39 -30 52 -30 117 0 65 3 78 30 116 60
85 175 114 264 66z"
        />
        <path
          d="M3098 1991 c-111 -36 -193 -97 -253 -187 -160 -241 -102 -589 124
-733 141 -91 345 -104 493 -33 l56 28 -5 -36 c-13 -94 -50 -150 -118 -181 -84
-39 -220 -16 -296 49 l-41 35 -124 -61 c-68 -34 -124 -68 -124 -75 0 -21 52
-88 102 -133 95 -83 228 -126 388 -126 250 0 425 113 508 328 l27 69 3 528 3
527 -160 0 -161 0 0 -30 0 -30 -32 20 c-96 59 -274 78 -390 41z m296 -321 c20
-14 49 -43 64 -64 23 -34 27 -49 27 -112 0 -64 -3 -77 -30 -115 -59 -83 -175
-115 -262 -71 -162 84 -152 310 18 382 43 18 143 7 183 -20z"
        />
        <path
          d="M6056 1995 c-233 -66 -386 -265 -386 -500 1 -227 129 -414 334 -485
199 -68 423 -37 571 80 48 38 115 119 115 140 0 23 -266 142 -279 126 -6 -7
-34 -26 -63 -41 -95 -52 -218 -35 -287 39 l-24 26 346 0 346 0 7 38 c4 20 2
78 -4 127 -25 200 -160 369 -347 436 -77 27 -256 35 -329 14z m227 -311 c35
-18 87 -63 87 -76 0 -4 -75 -8 -166 -8 l-167 0 25 32 c50 64 149 88 221 52z"
        />
        <path
          d="M7075 1990 c-159 -53 -249 -189 -231 -350 18 -152 101 -226 314 -276
137 -32 139 -32 157 -50 37 -37 -2 -84 -70 -84 -58 0 -100 22 -121 61 -18 36
-5 36 -211 -2 -110 -21 -113 -22 -113 -48 1 -60 63 -154 130 -196 124 -78 356
-93 516 -34 42 16 75 38 114 78 71 71 95 138 88 245 -6 84 -30 138 -84 186
-51 44 -96 63 -254 105 -74 20 -140 41 -147 47 -37 30 3 88 62 88 32 0 95 -33
95 -50 0 -5 8 -10 18 -10 26 0 246 51 255 60 16 14 -43 118 -90 159 -68 61
-138 84 -263 88 -85 2 -117 -1 -165 -17z"
        />
        <path d="M4010 1495 l0 -495 160 0 160 0 0 495 0 495 -160 0 -160 0 0 -495z" />
      </g>
    </svg>
  );
};

export default AgideskSVG;
