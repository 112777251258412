/* eslint-disable no-nested-ternary */
import React from "react";
import { useAppSelector } from "../../../../hooks/redux/hooks";
import Team from "./Team.component";
import { IAgideskTeam } from "../../../../slices/externalapps.slice";

const TeamsUsers = ({
  importData,
  setImportData,
}: {
  importData: IAgideskTeam[];
  setImportData: React.Dispatch<React.SetStateAction<IAgideskTeam[]>>;
}) => {
  const { selectedExternalAppAgidesk } = useAppSelector(
    (state) => state.externalapps
  );

  if (
    !selectedExternalAppAgidesk?.agideskTeams ||
    selectedExternalAppAgidesk?.agideskTeams.length === 0
  )
    return (
      <div className="flex w-full justify-center p-6">Nenhum resultado</div>
    );
  return (
    <div>
      {selectedExternalAppAgidesk?.agideskTeams?.map((team) => (
        <Team
          key={team.id}
          team={team}
          importData={importData}
          setImportData={setImportData}
        />
      ))}
    </div>
  );
};

export default TeamsUsers;
