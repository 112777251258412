import {
  IFinishChatBotmaker,
  INewMessageBotmaker,
} from "../slices/messages.slice";
import instances from "./instances";

const createBotmaker = async (newMessage: INewMessageBotmaker) => {
  try {
    const response = await instances.instanceBotmaker.post("/send", newMessage);
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const finishBotmaker = async (data: IFinishChatBotmaker) => {
  try {
    const response = await instances.instanceBotmaker.post("/finish", data);
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const templateWpp = async ({ externalappid }: { externalappid: string }) => {
  try {
    const response = await instances.instanceBotmaker.get("/templates", {
      params: {
        externalappid: externalappid.length > 0 ? externalappid : undefined,
      },
    });
    if (response?.status && [200, 201].includes(response.status)) {
      return response.data;
    }
    return response?.data || null;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const botmakerService = {
  createBotmaker,
  finishBotmaker,
  templateWpp,
};

export default botmakerService;
