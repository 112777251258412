/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { onHoverStyled, onRating } from "../SurveyElements.component";
import { IRatingData, IRatingResultData } from "../../../slices/surveys.slice";

const standardCss = "icon big outline pr-7";

interface Qualities {
  id: number;
  label: string;
  value: number;
  color: string;
  icon: string;
}

const qualities: Qualities[] = [
  { id: 0, label: "Péssimo", value: 0, color: "yellow", icon: "star" },
  { id: 1, label: "Ruim", value: 1, color: "yellow", icon: "star" },
  { id: 2, label: "Normal", value: 2, color: "yellow", icon: "star" },
  { id: 3, label: "Bom", value: 3, color: "yellow", icon: "star" },
  { id: 4, label: "Excelente", value: 4, color: "yellow", icon: "star" },
];

const QualityComponent = ({
  rating,
  setRating,
}: {
  rating: IRatingData;
  setRating: React.Dispatch<React.SetStateAction<IRatingData>>;
}) => {
  const actionIn = (value: number) => {
    onHoverStyled({ value, type: "quality" }, rating);
  };
  const actionOut = (value: number) => {
    onHoverStyled({ value, type: "quality", action: "out" }, rating);
  };

  const renderItems = qualities.map((quality) => (
    <div
      role="presentation"
      key={quality.id}
      id={`div-quality-icon-${quality.value}`}
      title={`Avaliar com: ${quality.label}`}
      className="ratingoptions w-[100px] cursor-pointer"
      onClick={() => {
        onRating({ value: quality.value, type: "quality" }, rating, setRating);
      }}
      onFocus={() => {
        actionIn(quality.value);
      }}
      onMouseOver={() => {
        actionIn(quality.value);
      }}
      onBlur={() => {
        actionOut(quality.value);
      }}
      onMouseOut={() => {
        actionOut(quality.value);
      }}
    >
      <i
        role="presentation"
        id={`quality-icon-${quality.value}`}
        onClick={() => {
          onRating(
            { value: quality.value, type: "quality" },
            rating,
            setRating
          );
        }}
        onFocus={() => {
          actionIn(quality.value);
        }}
        onMouseOver={() => {
          actionIn(quality.value);
        }}
        onBlur={() => {
          actionOut(quality.value);
        }}
        onMouseOut={() => {
          actionOut(quality.value);
        }}
        className={`${quality.icon} ${standardCss} ${quality.color}`}
      />
      <label>{quality.label}</label>
    </div>
  ));

  return <div className="mt-5 flex">{renderItems}</div>;
};

export const singleQualityItem = (resultData: IRatingResultData) => {
  const index = resultData.value as number;
  const quality =
    typeof qualities[index] !== "undefined"
      ? qualities[index]
      : ({} as Qualities);

  if (typeof quality !== "undefined") {
    return (
      <>
        <i
          role="presentation"
          id={`quality-icon-${quality.value}`}
          className={`${quality.icon} ${standardCss} ${quality.color}`}
        />
        <label>{quality.label}</label>
      </>
    );
  }
  return "-";
};

export default QualityComponent;
