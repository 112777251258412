const parseBlurTime = (time: string) => {
  let _time = time;
  switch (time.length) {
    case 1: {
      _time += "0:00";
      break;
    }
    case 2: {
      _time += ":00";
      break;
    }
    case 3: {
      _time += "00";
      break;
    }
    case 4: {
      _time += "0";
      break;
    }
    default:
      break;
  }
  return _time;
};

export default parseBlurTime;
