import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import CloseButton from "../Buttons/CloseButton.component";
import ImportedIcon from "../Agidesk/Import/ImportedIcon.component";
import AgideskSVG from "../Agidesk/AgideskSVG.icon";
import { useAppSelector } from "../../../hooks/redux/hooks";
import SpamIcon from "../Icons/Spam.component";

const Header = ({
  icon,
  title,
  imported,
  spam,
  setVisible,
  _id,
}: {
  icon: string;
  title: string;
  imported?: boolean;
  _id?: string;
  spam?: boolean;
  setVisible?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { isWidgetAgent } = useAppSelector((state) => state.auth);
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div className="text-2xl font-medium items-center flex mb-5 pb-2 border-b-2 border-agitalks">
      {icon !== "agidesk" ? (
        <i className={`${icon} mr-4 text-3xl`} />
      ) : (
        <div className="mr-4">
          <AgideskSVG />
        </div>
      )}
      <div className="flex justify-between w-full items-center">
        <div className="items-center flex w-[calc(100%-90px)]  relative">
          <p className="truncate" title={title}>
            {title}
          </p>
          {imported ? <ImportedIcon short /> : null}
          {spam ? <SpamIcon short /> : null}
          {typeof _id !== "undefined" ? (
            <button
              type="button"
              className="ml-2 flex items-center"
              title={`Ir para ${title}`}
              onClick={() => {
                if (setVisible) setVisible(false);
                navigate(location.pathname);
                if (icon === "las la-user") {
                  navigate(`/settings/user/${_id}`);
                } else {
                  navigate(`/settings/contact/${_id}`);
                }
              }}
            >
              <Icon
                size="small"
                className="las la-external-link-alt hover:text-blue-link"
                title={`Ir para ${title}`}
              />
            </button>
          ) : null}
        </div>
        <div className={`absolute ${isWidgetAgent ? "right-10" : "right-2"}`}>
          <CloseButton
            extraClass="text-[24px] h-[40px] border-0"
            onClick={() => {
              if (setVisible) setVisible(false);
              navigate(location.pathname);
            }}
          />
        </div>
      </div>
    </div>
  );
};

Header.defaultProps = {
  imported: false,
  spam: false,
  setVisible: undefined,
  _id: undefined,
};

export default Header;
