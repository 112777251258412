import React from "react";
import { Icon, Pagination, PaginationProps } from "semantic-ui-react";

const PaginationComponent = ({
  totalPages,
  activePage,
  onPageChange,
  table,
}: {
  totalPages: number;
  activePage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    data: PaginationProps
  ) => void;
  table?: boolean;
}) => {
  return (
    <Pagination
      style={
        table
          ? {
              border: "none",
              background: "transparent",
              margin: "4px 0",
            }
          : {
              border: "none",
              background: "transparent",
            }
      }
      pointing
      secondary
      ellipsisItem={{
        content: <Icon name="ellipsis horizontal" />,
        icon: true,
      }}
      firstItem={null}
      lastItem={null}
      prevItem={{
        content: <i className="las la-angle-left m-0 p-0" />,
        icon: true,
      }}
      nextItem={{
        content: <i className="las la-angle-right m-0 p-0" />,
        icon: true,
      }}
      totalPages={totalPages}
      activePage={activePage}
      onPageChange={onPageChange}
    />
  );
};

PaginationComponent.defaultProps = {
  table: false,
};

export default PaginationComponent;
