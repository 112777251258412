/* eslint-disable no-nested-ternary */
import React from "react";
import { RecoveryForm } from "../features/auth/components/Forms";
import AuthLayoutPage from "./AuthLayout.page";
import { useAppSelector } from "../hooks/redux/hooks";
import { UserType } from "../slices/users.slice";

const RecoveryPage = ({ widgetType }: { widgetType?: UserType }) => {
  const { isLoadingAuth, isForgot } = useAppSelector((state) => state.auth);

  const getMessage = () => {
    if (isForgot)
      return (
        <h2 className="my-4 text-center text-[20px] font-extrabold text-gray-900">
          Enviamos um link para o e-mail informado.
          <br />
          Acesse o link recebido e crie uma nova senha.
        </h2>
      );
    if (isLoadingAuth)
      return (
        <h2 className="my-4 text-center text-[24px] font-extrabold text-gray-900">
          Seu e-mail está sendo verificado!
        </h2>
      );
    return (
      <h2 className="my-4 text-center text-[24px] font-extrabold text-gray-900">
        Esqueceu a senha?
        <br />
        <br />
        Informe seu e-mail de acesso!
      </h2>
    );
  };

  return (
    <AuthLayoutPage widgetType={widgetType}>
      {getMessage()}
      <RecoveryForm />
    </AuthLayoutPage>
  );
};

RecoveryPage.defaultProps = {
  widgetType: UserType.NONE,
};

export default RecoveryPage;
