/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-case-declarations */
/* eslint-disable react/no-children-prop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { Form } from "semantic-ui-react";
import { ReactMultiEmail } from "react-multi-email";
import PopupComponent from "../../../shared/components/Popup.component";
import Toggle from "../../../shared/components/Toggle.component";
import { useAppSelector } from "../../../hooks/redux/hooks";
import useUserIsAdmin from "../../../hooks/useUserIsAdmin";
import useCampaigns from "../../../hooks/features/useCampaigns";
// import ErrorLabel from "../../auth/components/Forms/ErrorLabel.component";

const Email = () => {
  const { email } = useCampaigns();
  const { isAdmin } = useUserIsAdmin();
  const { selectedLocalCampaign } = useAppSelector((state) => state.campaigns);

  const getLabelComponent = (
    _email: string,
    index: number,
    removeEmail: (_index: number, isDisabled?: boolean | undefined) => void
  ) => {
    return (
      <div data-tag key={index}>
        <div data-tag-item>{_email}</div>
        <span
          data-tag-handle
          role="presentation"
          onClick={() => removeEmail(index)}
        >
          ×
        </span>
      </div>
    );
  };

  return (
    <Form>
      <Form.Group widths="equal">
        <Toggle
          containerClass="flex w-full"
          checked={email.checked}
          mainClass={`items-center flex ${email.checked ? "" : "mb-4"}`}
          onChange={(_, { checked }) => {
            email.onChange({ checked: checked || false });
          }}
          labelClass="ml-4"
          label="Habilitar envio de e-mails"
        />
      </Form.Group>
      {email.checked ? (
        <Form.Group>
          <div className="w-full">
            <PopupComponent
              className="flex items-center mb-1"
              label="E-mails"
              required
              content={
                <h1 className="font-semibold text-[12px]">
                  Apenas e-mails válidos e separados por ponto-e-vírgula ou
                  enter
                </h1>
              }
            />
            <Form.Input
              className="w-full md:w-full"
              readOnly={selectedLocalCampaign?.deleted || !isAdmin}
              // error={email.error}
              children={
                <div className="w-full">
                  <ReactMultiEmail
                    placeholder="E-mails"
                    // style={{
                    //   borderColor: email.error ? "#DB2828" : undefined,
                    // }}
                    getLabel={(_email, index, removeEmail) =>
                      getLabelComponent(_email, index, removeEmail)
                    }
                    autoComplete="off"
                    delimiter=";"
                    emails={email.emails?.split(";") || []}
                    onChange={(_emails: string[]) => {
                      email.onUpdate({ emails: _emails });
                    }}
                  />
                  {/* {email.emails?.length === 0 ? (
                    <div className="mt-4">
                      <ErrorLabel error label="Campo obrigatório" />
                    </div>
                  ) : null} */}
                </div>
              }
              autoComplete="off"
            />
          </div>
        </Form.Group>
      ) : null}
    </Form>
  );
};

export default Email;
