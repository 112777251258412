import React from "react";
import { Form } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { validateNameLength } from "../../../../shared/utils/validation/length";

const Step1 = ({
  nameInput,
  setName,
  validName,
  validateName,
  tenantInput,
  emailInput,
}: {
  nameInput: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  validName: boolean;
  validateName: React.Dispatch<React.SetStateAction<boolean>>;
  tenantInput: string;
  emailInput: string;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Form.Input
        // icon="building"
        // iconPosition="left"
        label={t("form.label.tenant")}
        placeholder={t("form.placeholder.tenant")}
        required
        readOnly
        value={tenantInput}
        autoComplete="off"
      />
      <Form.Input
        autoComplete="off"
        // icon="user"
        // iconPosition="left"
        label={t("form.label.name")}
        placeholder={t("form.placeholder.name")}
        required
        value={nameInput}
        onChange={(e) => {
          setName(e.target.value);
          validateName(validateNameLength(e.target.value.trim()));
        }}
        error={
          validName
            ? false
            : {
                content: t("form.error.name"),
                pointing: "above",
              }
        }
      />
      <Form.Input
        // icon="at"
        // iconPosition="left"
        label={t("form.label.email")}
        placeholder={t("form.placeholder.email")}
        required
        readOnly
        value={emailInput}
        autoComplete="off"
      />
    </>
  );
};

export default Step1;
