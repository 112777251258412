import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../../../hooks/redux/hooks";
import LoadingDots from "../../../../../../shared/components/LoadingDots.component";
import TicketCard from "./TicketCard.component";
import { IAgideskTicket } from "../../../../../../slices/externalapps.slice";

const LastTicket = ({ tickets }: { tickets: IAgideskTicket[] }) => {
  const { t } = useTranslation();
  const { isLoadingExternalapps, selectedExternalAppAgidesk } = useAppSelector(
    (state) => state.externalapps
  );

  const getTickets = () => {
    return (
      <ul className="relative overflow-x-hidden w-[395px]">
        {!tickets || tickets.length === 0 ? (
          <div className="flex w-full justify-center p-6">
            {t("no-result-message")}
          </div>
        ) : (
          tickets?.map((ticket) => {
            return (
              <li key={ticket?.id}>
                <Link
                  to={`${selectedExternalAppAgidesk?.fields?.url}/br/painel/atendimento/${ticket?.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TicketCard ticket={ticket} />
                </Link>
              </li>
            );
          })
        )}
      </ul>
    );
  };

  return (
    <div className="flex-col flex justify-center items-center">
      {isLoadingExternalapps ? (
        <div className="bg-white relative overflow-x-hidden w-[395px] h-[300px]">
          <LoadingDots className="flex flex-1 h-full justify-center items-center" />
        </div>
      ) : (
        getTickets()
      )}
    </div>
  );
};

export default LastTicket;
