import { twMerge } from "tailwind-merge";
import { ButtonProps } from "../@types/button.types";

const Button = (props: ButtonProps) => {
  const getHoverbg = () => {
    if (props.color) return `hover:bg-[${props.color}C0]`;
    if (props.red) return "hover:bg-red-alphaC0";
    if (props.orange) return "hover:bg-agidesk-alphaC0";
    if (props.green) return "hover:bg-green-alphaC0";
    return "hover:bg-agitalks-alphaC0";
  };

  const getBg = () => {
    if (props.color) return `bg-[${props.color}]`;
    if (props.red) return "bg-red";
    if (props.orange) return "bg-agidesk";
    if (props.green) return "bg-green";
    return "bg-agitalks";
  };

  const getInverted = () => {
    if (props.color) return `text-[${props.color}] border-[${props.color}]`;
    if (props.orange) return "text-agidesk border-agidesk";
    if (props.red) return "text-red border-red";
    if (props.green) return "text-green border-green";
    return "text-agitalks border-agitalks";
  };

  const _classHeight = `${
    typeof props.height !== "undefined" ? props.height : "h-[36px]"
  }`;
  const _classWidth = `${
    typeof props.width !== "undefined" ? props.width : ""
  }`;
  const _classMinWidth = `${props.minWidth ? "min-w-[125px]" : ""}`;
  const _classWidget = `${!props.widget ? "" : "mr-2"}`;
  const _classDefault = `py-[4px] px-4 cursor-pointer items-center inline-flex justify-center rounded-3xl border shadow-sm ${getHoverbg()}`;
  const _classDisable =
    "disabled:cursor-default disabled:text-black-alpha55 disabled:bg-disable disabled:border-disable";
  const _classType = !props.inverted
    ? `text-white ${getBg()}`
    : `bg-white hover:text-white ${getInverted()} ${
        props.hoverColor ? `hover:border-footer` : "hover:border-white"
      }`;

  const className = twMerge(
    _classWidth,
    _classHeight,
    _classMinWidth,
    _classWidget,
    _classDefault,
    _classDisable,
    _classType,
    props.extraClass
  );

  return (
    <button
      type="button"
      title={props.title || props.label}
      disabled={props.disabled}
      onClick={props.onClick}
      className={className}
    >
      {props.icon ? (
        <i
          className={`${props.icon} ${!props.minWidth ? "text-md" : "text-lg"}`}
        />
      ) : null}
      {props.label ? (
        <p
          className={`${
            !props.minWidth && !props.width
              ? "hidden md:flex md:items-center md:ml-2"
              : "ml-2"
          } text-[12px] font-semibold`}
        >
          {props.label}
        </p>
      ) : null}
    </button>
  );
};

Button.defaultProps = {
  icon: "",
  widget: false,
  disabled: false,
  inverted: false,
  extraClass: "",
  onClick: undefined,
  label: "",
  color: "",
  red: false,
  orange: false,
  green: false,
  hoverColor: false,
  height: "h-[36px]",
  width: "",
  title: undefined,
};

export default Button;
