import React from "react";
import { DropdownProps, SemanticWIDTHS } from "semantic-ui-react";
import Dropdown from "../Dropdown.component";
import { CompanySegment } from "../../../slices/settings.slice";

const SegmentDropdown = ({
  id,
  disabled,
  defaultValue,
  onChange,
  width,
  fluid,
}: {
  id?: string;
  width?: SemanticWIDTHS;
  disabled?: boolean;
  defaultValue?: CompanySegment;
  onChange?:
    | ((
        event: React.SyntheticEvent<HTMLElement, Event>,
        data: DropdownProps
      ) => void)
    | undefined;
  fluid?: boolean;
}) => {
  return (
    <Dropdown
      id={id || "dropdown-segment"}
      disabled={disabled || false}
      fluid={fluid}
      width={width}
      placeholder="Segmento"
      label="Segmento"
      selection
      defaultValue={defaultValue}
      onChange={onChange}
      options={[
        {
          key: 0,
          text: "Negócio",
          value: CompanySegment.BUSINESS,
        },
        {
          key: 1,
          text: "Indústria",
          value: CompanySegment.INDUSTRY,
        },
        {
          key: 2,
          text: "Serviços",
          value: CompanySegment.SERVICE,
        },
      ]}
    />
  );
};

SegmentDropdown.defaultProps = {
  id: undefined,
  disabled: undefined,
  defaultValue: undefined,
  onChange: undefined,
  fluid: undefined,
  width: undefined,
};

export default SegmentDropdown;
