import { useCallback, useRef } from "react";

const useOnScreen = ({
  isLoading,
  hasMore,
  setSkip,
  grid,
}: {
  isLoading: boolean;
  hasMore: boolean;
  setSkip: React.Dispatch<React.SetStateAction<number>>;
  grid?: boolean;
}) => {
  const observer = useRef<IntersectionObserver | null>(null);
  const listRef = useCallback(
    (node: HTMLLIElement) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        // console.log(entries[0].isIntersecting, hasMore);
        if (entries[0].isIntersecting && hasMore) {
          const _skip = grid ? 30 : 10;
          setSkip((prevState) => prevState + _skip);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore]
  );

  return [listRef];
};

export default useOnScreen;
