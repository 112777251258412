import React, { useState } from "react";
import { surveyBody } from "../SurveyElements.component";
import Button from "../Buttons/Button.component";
import { ICampaignBatch } from "../../../slices/campaignbatches.slice";
import {
  Preview,
  WhatsAppTemplate,
} from "../../../features/messages/components/WhatsApp";
import { IAcceptedFiles } from "../../models/interfaces/acceptedfile.interface";

const PreviewModal = ({
  setShowPreviewModal,
  instance,
  type,
}: {
  setShowPreviewModal: React.Dispatch<React.SetStateAction<boolean>>;
  type: "survey" | "campaignbatch" | "commenttemplate";
  instance: any;
}) => {
  const campaignbatchpreview = (campaignbatch: ICampaignBatch) => {
    const [files, setFiles] = useState<IAcceptedFiles>([]);
    const [templateButtons, setTemplateButtons] = useState({
      url: "",
      code: "",
    });
    const selectedTemplate = {
      name: "templates",
      language: "pt-br",
      components: [
        {
          type: "BODY",
          text: campaignbatch.content,
        },
      ],
    };
    return (
      <WhatsAppTemplate
        contactChat={undefined}
        results={selectedTemplate}
        files={files}
        setFiles={setFiles}
        setTemplateButtons={setTemplateButtons}
      />
    );
  };

  const modalContent = () => {
    let _content = null;
    let title: string | undefined = "Pré-visualização";
    switch (type) {
      case "survey":
        _content = surveyBody({
          surveyRating: instance,
          preview: true,
        });
        break;
      case "commenttemplate":
        _content = (
          <div className="gap-2 mb-2">
            <Preview commenttemplate={instance.commenttemplate} />
          </div>
        );
        break;
      case "campaignbatch":
        title = undefined;
        _content = campaignbatchpreview(instance as ICampaignBatch);
        break;
      default:
        break;
    }

    return (
      <div className="relative bg-white rounded-md flex flex-col justify-center max-w-full overflow-y-auto max-h-[80%] shadow-lg py-8 px-10 w-[530px]">
        {typeof title !== "undefined" ? (
          <h1 className="word-wrap-1 pb-2 text-agitalks font-semibold">
            {title}
          </h1>
        ) : null}

        {_content}
        <div className="flex mx-auto items-center justify-between w-full mt-2">
          <Button
            minWidth
            label="Voltar"
            icon="las la-arrow-left"
            onClick={() => setShowPreviewModal(false)}
            inverted
            extraClass="mt-5"
          />
        </div>
      </div>
    );
  };

  return (
    <div id="modal" className="fixed inset-0 z-[200] overflow-y-auto w-full">
      <div
        role="presentation"
        className="fixed inset-0 w-full h-full bg-black opacity-80"
        onClick={() => setShowPreviewModal(false)}
      />
      <div className="flex items-center justify-center min-h-screen">
        {modalContent()}
      </div>
    </div>
  );
};

export default PreviewModal;
