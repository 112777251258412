import React, { useCallback, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DropdownSearchInputProps } from "semantic-ui-react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { SectionPageProps } from "../../pages/Default.page";
import useUserIsAdmin from "../useUserIsAdmin";
import { ddOptions } from "../../shared/models/interfaces/dropdownOptions.interface";
import removeDuplicates from "../../shared/utils/removeDuplicates";
import { teamsService } from "../../services";
import { ITeam } from "../../slices/teams.slice";
import {
  deleteTag,
  reselectTag,
  reselectLocalTag,
  updateTag,
  TagType,
} from "../../slices/tags.slice";

const useTags = ({
  disable,
  setDisable,
  toggleSidebar,
}: {
  toggleSidebar?: boolean;
  disable?: boolean;
  setDisable?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  interface sectionDetailsObject {
    [key: string]: SectionPageProps;
  }
  type onChangeEvent = React.SyntheticEvent<HTMLElement, Event>;
  type onChangeValue =
    | string
    | number
    | boolean
    | (string | number | boolean)[]
    | undefined;

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { selectedTag, selectedLocalTag } = useAppSelector(
    (state) => state.tags
  );
  const { isAdmin } = useUserIsAdmin();

  const teamsValue = useRef<string[]>([]);
  const [teamsOptions, setTeamsOptions] = useState<ddOptions[]>([]);
  const [focusId, setFocusId] = useState<string>("");
  const [isLoadingDropdown, setIsLoadingDropdown] = useState<boolean>(false);

  const [showModal, setShowModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showReactiveModal, setShowReactiveModal] = useState<boolean>(false);

  const tagCallback = useCallback(() => {
    if (selectedTag !== null && typeof selectedTag?._id !== "undefined") {
      dispatch(reselectLocalTag(selectedTag));
    }
  }, [selectedTag]);

  const teamsCallback = useCallback(() => {
    const _options: ddOptions[] = teamsOptions;
    const _value: string[] = [];
    selectedLocalTag?.teams?.forEach((_team: any) => {
      if (_team.active)
        _options.push({
          key: _team._id,
          text: _team.name,
          value: _team._id,
        });
    });
    if (selectedLocalTag?.teams && selectedLocalTag?.teams.length > 0) {
      selectedLocalTag?.teams.forEach((_team: any) => {
        if (typeof _team?._id !== "undefined") {
          _value.push(_team._id);
        }
      });
    }
    const options = removeDuplicates(_options, "key");
    const defaultValue = removeDuplicates(_value);
    return { options, defaultValue };
  }, []);

  const sectionDetails: sectionDetailsObject = {
    infos: {
      id: "tag-info",
      icon: "las la-info-circle",
      title: "Informações",
      description: "Edite as informações exibidas",
    },
    team: {
      id: "tag-team",
      icon: "las la-user-friends",
      title: "Equipes vinculadas ao marcador",
      description: "Relacione as equipes desejadas",
    },
  };

  const header: {
    icon?: string;
    title?: string;
    created?: string;
    updated?: string;
    deleted?: string;
  } = {
    created: selectedTag?.created_at,
    updated: selectedTag?.updated_at,
    deleted: selectedTag?.deleted_at,
    title: selectedTag?.name,
    icon:
      selectedTag?.type === TagType.CHAT ? "las la-tags" : "las la-user-tag",
  };

  const back = () => {
    navigate(-1);
  };

  const remove = async () => {
    if (setDisable) setDisable(true);
    setShowDeleteModal(false);
    if (selectedTag?._id) {
      const { payload } = await dispatch(deleteTag([selectedTag._id]));
      if (setDisable) setDisable(false);
      if (
        payload !== null &&
        typeof payload !== "undefined" &&
        typeof payload?.message !== "string"
      ) {
        navigate(-1);
      }
    }
  };

  const reactive = async () => {
    if (setDisable) setDisable(true);
    setShowReactiveModal(false);
    const _tag = {
      ...selectedLocalTag,
      teams: selectedLocalTag?.teams?.map((_t) => {
        if (typeof _t === "string") return _t;
        return _t._id;
      }),
      deleted: false,
      active: true,
      deleted_by: null,
      deleted_at: "",
    };
    const { payload } = await dispatch(updateTag({ _tag }));
    if (setDisable) setDisable(false);
    if (typeof payload._id !== "undefined") {
      navigate(-1);
    }
  };

  const save = async () => {
    if (setDisable) setDisable(true);
    const _tag = {
      ...selectedLocalTag,
      _id: selectedLocalTag?._id,
      name: selectedLocalTag?.name,
      color: selectedLocalTag?.color,
      global: selectedLocalTag?.global,
      active: selectedLocalTag?.active,
      type: selectedLocalTag?.type,
      teams: !selectedLocalTag?.global
        ? selectedLocalTag?.teams?.map((_t) => {
            if (typeof _t === "string") return _t;
            return _t._id;
          })
        : [],
    };
    const { payload } = await dispatch(updateTag({ _tag }));
    if (payload !== null && typeof payload?._id !== "undefined") {
      dispatch(
        reselectTag({
          ...selectedLocalTag,
          updated_at: payload.updated_at,
          updated_by: payload.updated_by,
        })
      );
      dispatch(
        reselectLocalTag({
          ...selectedLocalTag,
          updated_at: payload.updated_at,
          updated_by: payload.updated_by,
        })
      );
    }
    if (setDisable) setDisable(false);
  };

  const updateTagData = ({
    field,
    value,
  }: {
    field: string;
    value:
      | undefined
      | null
      | string
      | boolean
      | number
      | string[]
      | {
          _id: string;
          name: string;
        }
      | {
          _id: string;
          name: string;
        }[];
  }) => {
    if (selectedLocalTag) {
      if (field === "global" && value === true) {
        dispatch(
          reselectLocalTag({ ...selectedLocalTag, [field]: value, teams: [] })
        );
      } else {
        dispatch(reselectLocalTag({ ...selectedLocalTag, [field]: value }));
      }
    } else {
      dispatch(reselectLocalTag(null));
    }
  };

  const infos = {
    active: {
      disabled:
        selectedLocalTag?.deleted ||
        (!isAdmin && !location.pathname.includes("tag")),
    },
    readonly:
      selectedLocalTag?.deleted ||
      (!isAdmin && !location.pathname.includes("tag")),
    onChange: {
      name: async (e: onChangeEvent, { value }: { value: onChangeValue }) => {
        if (typeof value === "string") {
          updateTagData({
            field: "name",
            value,
          });
        }
      },
      active: async (e: onChangeEvent, { value }: { value: onChangeValue }) => {
        if (typeof value === "boolean") {
          updateTagData({
            field: "active",
            value,
          });
        }
      },
      type: async (e: onChangeEvent, { value }: { value: onChangeValue }) => {
        if (typeof value === "string") {
          updateTagData({
            field: "type",
            value,
          });
        }
      },
    },
  };

  const teams = {
    readOnly: !isAdmin,
    dropdown: {
      defaultValue:
        teamsValue.current.length === 0
          ? teamsCallback().defaultValue
          : teamsValue.current,
      loading: isLoadingDropdown && focusId === "dropdown-relation-teams",
      setIsLoadingDropdown,
      setOptions: setTeamsOptions,
      onFocus: () => {
        setFocusId("dropdown-relation-teams");
      },
      onChange: (value: string[]) => {
        teamsValue.current = removeDuplicates(value);
      },
      onBlur: () => {
        setFocusId("");
      },
      onOpen: async () => {
        setIsLoadingDropdown(true);
        const payload = await teamsService.search({
          skip: 0,
          limit: 10,
          filter: "",
          deleted: false,
        });
        setIsLoadingDropdown(false);
        const _optionsTeams: object[] = [];
        payload.results.map((_team: ITeam) =>
          _optionsTeams.push({
            key: _team._id,
            text: _team?.name,
            value: _team._id,
          })
        );
        setTeamsOptions(_optionsTeams);
      },
      onSearch: async (e: DropdownSearchInputProps) => {
        setIsLoadingDropdown(true);
        const payload = await teamsService.search({
          skip: 0,
          limit: 10,
          filter: e.target.value,
          deleted: false,
        });
        setIsLoadingDropdown(false);
        const _optionsTeams: ddOptions[] = teamsOptions;
        payload.results.map((_team: ITeam) => {
          if (_optionsTeams.filter((_opTeam) => _opTeam.key === _team._id)[0]) {
            return false;
          }
          _optionsTeams.push({
            key: _team._id,
            text: _team?.name,
            value: _team._id,
          });
          return true;
        });
        setTeamsOptions(_optionsTeams);
      },
      options:
        teamsOptions.length === 0 ? teamsCallback().options : teamsOptions,
    },
    onChange: async ({ checked }: { checked: boolean }) => {
      updateTagData({
        field: "global",
        value: !checked,
      });
    },
    modal: {
      show: showModal,
      setShow: setShowModal,
      onCancel: () => {
        setShowModal(false);
      },
      onSave: async () => {
        setShowModal(false);
      },
      disabled:
        teamsValue.current.length === 0 || selectedTag?.deleted || false,
    },
    relation: {
      onClickRelation: () => {
        setShowModal(true);
      },
    },
  };

  const getModalTitle = () => {
    if (showDeleteModal) {
      return "Deseja remover este marcador?";
    }
    if (showReactiveModal) {
      return "Deseja reativar este marcador?";
    }
    return "";
  };

  const footer = {
    disabled:
      disable ||
      selectedLocalTag?.deleted ||
      (!selectedLocalTag?.global && selectedLocalTag?.teams?.length === 0) ||
      typeof selectedLocalTag?.name === "undefined" ||
      selectedLocalTag?.name?.length < 2,
    deleted: selectedLocalTag?.deleted,
    back,
    reactive,
    remove,
    save,
    toggleSidebar: toggleSidebar || false,
    modaltitle: getModalTitle(),
    showDeleteModal,
    setShowDeleteModal,
    showReactiveModal,
    setShowReactiveModal,
  };

  return {
    teams,
    tagCallback,
    header,
    footer,
    infos,
    sectionDetails,
  };
};

export default useTags;
