/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FormEvent, useEffect, useState } from "react";
import { Divider, Form } from "semantic-ui-react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useInput from "../../../../hooks/useInput";
import validateEmail from "../../../../shared/utils/validation/email";
import validatePassword from "../../../../shared/utils/validation/password";
import { changeStatus, login, reset } from "../../../../slices/auth.slice";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux/hooks";
import LoadingDots from "../../../../shared/components/LoadingDots.component";
import TermsAndPolices from "../../../../shared/components/TermsAndPolices.component";
import {
  ILoginUser,
  UserStatus,
  UserType,
} from "../../../../slices/users.slice";
import FormButton from "../../../../shared/components/Buttons/FormButton.component";
import ErrorLabel from "./ErrorLabel.component";
import LinkButton from "../../../../shared/components/Buttons/LinkButton.component";
import CommonError from "./CommonError.component";
import AuthButton from "../../../../shared/components/Buttons/AuthButton.component";
import ShowHideButton from "../../../../shared/components/Buttons/ShowHideButton.component";

const LoginAgentForm = ({ widgetType }: { widgetType?: UserType }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/chat";
  const dispatch = useAppDispatch();
  const {
    isLoadingAuth,
    isSuccess,
    isAuthenticated,
    isError,
    tenantID,
    loginMethods,
  } = useAppSelector((state) => state.auth);

  const {
    text: email,
    shouldDisplayError: emailHasError,
    emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    clearHandler: emailClearHandler,
  } = useInput(validateEmail);
  const {
    text: password,
    shouldDisplayError: passwordHasError,
    textChangeHandler: passwordChangeHandler,
    inputBlurHandler: passwordBlurHandler,
    clearHandler: passwordClearHandler,
  } = useInput(validatePassword);

  const clearForm = () => {
    emailClearHandler();
    passwordClearHandler();
  };

  const [showHide, setShowHide] = useState<boolean>(false);

  // const [data, setData] = useState(null);

  useEffect(() => {
    if (isSuccess) {
      dispatch(reset());
      clearForm();
    }
  }, [isSuccess, dispatch]);

  useEffect(() => {
    if (!isAuthenticated) return;
    navigate(from, { replace: true });
  }, [isAuthenticated]);

  const onSubmitHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!tenantID || emailHasError || passwordHasError) return;
    if (!tenantID || email.length === 0 || password.length === 0) return;

    const loginUser: ILoginUser = {
      tenant: tenantID.toLowerCase().trim(),
      email: email.toLowerCase().trim(),
      password: password.trim(),
      widget: "agent",
    };

    const { payload } = await dispatch(login(loginUser));
    if (payload && typeof payload !== "string") {
      await dispatch(changeStatus({ status: UserStatus.ONLINE }));
    }
  };

  const goToRecovery = async () => {
    navigate("/recovery");
  };

  const handleShowHide = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault();
    setShowHide((prevState) => !prevState);
  };

  if (isLoadingAuth)
    return <LoadingDots className="flex justify-center items-center" />;
  return (
    <Form
      className="px-16 bg-white h-full w-full rounded-xl flex flex-col"
      onSubmit={onSubmitHandler}
    >
      {/* <Form.Input
        className="pt-2"
        icon="building"
        iconPosition="left"
        label={t("form.label.tenant")}
        value={tenantID || tenant}
        required
        readOnly={tenantID && tenantID.length > 0}
        onChange={!tenantID ? tenantChangeHandler : undefined}
        onBlur={!tenantID ? tenantBlurHandler : null}
        error={!tenantID ? tenantHasError : null}
        type="text"
        name="tenant"
        id="tenant"
        variant="outlined"
        placeholder={t("form.placeholder.tenant")}
        autoComplete="off"
      />
      <ErrorLabel error={tenantHasError} label={t("form.error.tenant")} /> */}
      <Form.Input
        className="pt-2"
        // icon="at"
        // iconPosition="left"
        label={t("form.label.email")}
        value={email}
        required
        onChange={emailChangeHandler}
        onBlur={emailBlurHandler}
        error={emailHasError}
        type="email"
        name="email"
        id="email"
        variant="outlined"
        placeholder={t("form.placeholder.email")}
      />
      <ErrorLabel error={emailHasError} label={t("form.error.email")} />
      <Form.Input
        label={t("form.label.password")}
        value={password}
        required
        onChange={passwordChangeHandler}
        onBlur={passwordBlurHandler}
        error={passwordHasError}
        type={showHide ? "input" : "password"}
        className="pt-2"
        name="password"
        id="password"
        variant="outlined"
        placeholder={t("form.placeholder.password")}
        action
      >
        <input />
        <ShowHideButton
          title={`${!showHide ? "Mostrar" : "Esconder"} ${t(
            "form.label.password"
          ).toLowerCase()}`}
          hide={showHide}
          onClick={handleShowHide}
        />
      </Form.Input>
      {passwordHasError ? (
        <ErrorLabel error={emailHasError} label={t("form.error.password")} />
      ) : null}
      <div className="flex justify-end mt-1">
        <LinkButton
          color
          label={t("linkbutton.label.recovery")}
          onClick={goToRecovery}
        />
      </div>
      {isError ? <CommonError /> : null}

      <FormButton
        className={`flex flex-wrap justify-center ${isError ? "mt-8" : "mt-4"}`}
        disabled={
          tenantID?.length === 0 ||
          email.length === 0 ||
          password.length === 0 ||
          !tenantID ||
          emailHasError ||
          passwordHasError ||
          false
        }
        label={t("formbutton.label.login")}
      />

      {loginMethods && loginMethods.length > 0 ? (
        <>
          <Divider horizontal>
            <p className="text-xs">{t("form.login-with")}</p>
          </Divider>
          {loginMethods.map((method) => (
            <AuthButton key={method._id} method={method} />
          ))}
        </>
      ) : null}

      <TermsAndPolices widgetType={widgetType} />

      {/* <Divider sx={{ marginTop: "24px", marginBottom: "24px" }} /> */}

      {/* <div className="text-center flex items-center justify-center">
        <LinkButton
          color
          label={t("linkbutton.label.company")}
          onClick={goToCompany}
        />
      </div> */}
    </Form>
  );
};

LoginAgentForm.defaultProps = {
  widgetType: UserType.AGENT,
};

export default LoginAgentForm;
