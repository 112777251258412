import React, { useCallback, useEffect } from "react";
import { ReplyProps } from "../../models/ReplyProps.type";
import ParentMessage from "../ParentMessage.component";

const Reply = ({
  setIsReplying,
  setMessageReplied,
  messageReplied,
}: ReplyProps) => {
  const escFunction = useCallback((event: KeyboardEvent) => {
    if (event && event.key === "Escape") {
      event.preventDefault();
      event.stopPropagation();
      setIsReplying(false);
      setMessageReplied(null);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <div
      role="presentation"
      title="Ir até a mensagem"
      className="flex relative w-[calc(100%-35px)] ml-[10px]"
    >
      {messageReplied ? (
        <ParentMessage
          message={messageReplied}
          onClick={() => {
            // setIsReplying(false);
            // setMessageReplied(null);
            setTimeout(() => {
              document
                .getElementById(messageReplied._id)
                ?.scrollIntoView({ behavior: "smooth" });
            }, 0);
          }}
        />
      ) : null}
      <div
        role="presentation"
        title="Clique para remover ou pressione ESC"
        onClick={() => {
          setIsReplying(false);
          setMessageReplied(null);
        }}
        className="w-[16px] h-[16px] flex absolute transition-all bottom-[35px] right-[10px] z-10 cursor-pointer rounded-full justify-center bg-white border-[1px] border-red-status text-red-status items-center"
      >
        <i className="las la-times text-[10px]" />
      </div>
    </div>
  );
};

export default Reply;
