import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, Link } from "react-router-dom";
import LinkButton from "./Buttons/LinkButton.component";
import { UserType } from "../../slices/users.slice";

const TermsAndPolices = ({ widgetType }: { widgetType?: UserType }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return widgetType === UserType.NONE ? (
    <div className="text-center mt-4 text-[10px] justify-center flex flex-col items-center">
      <p>{t("linkbutton.terms.firstline")}</p>
      <LinkButton
        color
        label={t("linkbutton.terms.secondline")}
        onClick={() => navigate("/privacy")}
        extraClass="text-[10px]"
      />
    </div>
  ) : (
    <Link
      to="https://agitalks.com/privacy"
      target="_blank"
      title={t("linkbutton.terms.secondline") || ""}
    >
      <div className="text-center mt-4 text-[10px] justify-center flex flex-col items-center">
        <p className="text-black hover:text-black">
          {t("linkbutton.terms.firstline")}
        </p>
        <LinkButton
          color
          label={t("linkbutton.terms.secondline")}
          onClick={() => null}
          extraClass="text-[10px]"
        />
      </div>
    </Link>
  );
};

TermsAndPolices.defaultProps = {
  widgetType: UserType.NONE,
};

export default TermsAndPolices;
