import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/redux/hooks";
import {
  reselectContact,
  reselectLocalContact,
  selectUser,
} from "../slices/users.slice";
import DividerInfoPages from "../shared/components/DividerInfoPages.component";
import Section from "../shared/components/Section.component";
import DefaultPage from "./Default.page";
import useGetIdPage from "../hooks/useGetIdPage";
import useContacts from "../hooks/features/useContacts";
import Infos from "../features/contacts/components/Infos.section";
import Customers from "../features/contacts/components/Customers.section";
import Campaigns from "../features/contacts/components/Campaigns.section";

const ContactPage = ({
  disable,
  setDisable,
  toggleSidebar,
}: {
  toggleSidebar: boolean;
  disable: boolean;
  setDisable: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { getId } = useGetIdPage();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { isLoadingContacts, selectedContact } = useAppSelector(
    (state) => state.users
  );
  const { sectionDetails, header, footer } = useContacts({
    disable,
    setDisable,
    toggleSidebar,
  });

  useEffect(() => {
    const _id = getId();
    if (location.pathname === `/settings/contact/${_id}`) {
      (async () => {
        dispatch(reselectContact(null));
        dispatch(reselectLocalContact(null));
        await dispatch(selectUser({ _id, isContact: true }));
      })();
    }
  }, []);

  return (
    <div className="container items-center my-auto mx-auto lg:px-44 mt-10 py-4 md:px-12 px-4 sm:px-8">
      <DefaultPage
        isLoading={isLoadingContacts && selectedContact === null}
        header={header}
        content={
          <>
            <Section
              icon={sectionDetails.infos.icon}
              title={sectionDetails.infos.title}
              description={sectionDetails.infos.description}
              component={<Infos />}
            />
            <DividerInfoPages />
            <Section
              icon={sectionDetails.customer.icon}
              title={sectionDetails.customer.title}
              description={sectionDetails.customer.description}
              component={<Customers />}
            />
            <DividerInfoPages />
            <Section
              icon={sectionDetails.campaign.icon}
              title={sectionDetails.campaign.title}
              description={sectionDetails.campaign.description}
              component={<Campaigns _id={selectedContact?._id} />}
            />
          </>
        }
        footer={footer}
      />
    </div>
  );
};

export default ContactPage;
