import React, { useState } from "react";
import { DropdownSearchInputProps } from "semantic-ui-react";
import Dropdown from "../Dropdown.component";
import customToast from "../../utils/customToast";
import { IToastType } from "../../models/types/Toast.type";
import {
  IFilterTag,
  INewTag,
  TagType,
  createTag,
} from "../../../slices/tags.slice";
import removeDuplicates from "../../utils/removeDuplicates";
import { ddOptions } from "../../models/interfaces/dropdownOptions.interface";
import { useAppDispatch } from "../../../hooks/redux/hooks";
import { tagsService } from "../../../services";

const ContactTagsDropdown = ({
  options,
  setOptions,
  disabled,
  isLoadingDropdown,
  setIsLoadingDropdown,
  focusId,
  setFocusId,
  defaultValue,
  handleOnChangeInfo,
  allowAdditions,
}: {
  options: ddOptions[];
  setOptions: React.Dispatch<React.SetStateAction<ddOptions[]>>;
  disabled: boolean;
  isLoadingDropdown: boolean;
  setIsLoadingDropdown: React.Dispatch<React.SetStateAction<boolean>>;
  focusId: string;
  setFocusId: React.Dispatch<React.SetStateAction<string>>;
  defaultValue: string[];
  handleOnChangeInfo?: ({
    field,
    value,
  }: {
    field: string;
    value: string[];
  }) => Promise<void>;
  allowAdditions: boolean;
}) => {
  const dispatch = useAppDispatch();
  const [results, setResults] = useState(defaultValue);

  const handleTagOptions = (value: ddOptions[]) => {
    const _optionsTags: ddOptions[] = [];

    if (typeof value !== undefined && value.length > 0) {
      value.forEach((_s: ddOptions) => {
        const _tag = options?.filter(
          (_opt: ddOptions) => _opt.key === _s.key
        )[0];

        if (typeof _tag === "undefined") _optionsTags.push(_s);
      });

      if (_optionsTags.length > 0) {
        const _new = [..._optionsTags, ...options];
        setOptions(removeDuplicates(_new, "key"));
      }
    }
  };

  const handleTagCreation = async (value: string) => {
    const _newTag: INewTag = {
      name: value,
      type: TagType.CONTACT,
    };
    const action = await dispatch(createTag(_newTag));

    if (
      action.payload !== null &&
      typeof action?.payload?._id !== "undefined"
    ) {
      const _new = {
        text: value as string,
        key: action.payload._id as string,
        value: value as string,
      };
      handleTagOptions([_new]);
      setOptions((prevState) => [...prevState, _new]);
      setResults((prevState) => [...prevState, action.payload._id]);
      if (typeof handleOnChangeInfo !== "undefined") {
        handleOnChangeInfo({
          field: "tags",
          value: [...results, action.payload._id],
        });
      }
    }
  };

  return (
    <Dropdown
      id="dropdown-contact-tags"
      allowAdditions={allowAdditions}
      additionLabel={
        allowAdditions ? "Criar e relacionar o marcador:" : undefined
      }
      disabled={disabled}
      loading={isLoadingDropdown && focusId === "dropdown-contact-tags"}
      className="mt-6 w-full"
      label="Marcadores de contato"
      placeholder="Marcadores de contato"
      fluid
      search
      selection
      multiple
      options={options}
      defaultValue={defaultValue}
      onAddItem={
        allowAdditions
          ? async (e, { value }) => {
              const _value = value as string;
              if (typeof _value === "string" && _value.length > 30) {
                customToast({
                  type: IToastType.WARNING,
                  message:
                    "Atenção: Utilize até 30 caracteres para criação de tags!",
                });
                e.preventDefault();
                e.stopPropagation();
              }
              await handleTagCreation(_value);
            }
          : undefined
      }
      onChange={async (e, { value }) => {
        const target = e.target as HTMLInputElement;
        const _newItem = target.className.indexOf("search") !== -1;
        if (_newItem) {
          e.preventDefault();
          e.stopPropagation();
        }
        const _value: string[] = [];
        if (value && typeof value === "object" && value.length > 0) {
          value.forEach((_s) => {
            const _tag = options?.filter(
              (_opt) => _opt.key === _s || _opt.value === _s
            )[0];
            if (typeof _tag !== "undefined") _value.push(_tag.key as string);
          });
        }
        setResults(_value);
        if (typeof handleOnChangeInfo !== "undefined") {
          handleOnChangeInfo({
            field: "tags",
            value: _value,
          });
        }
      }}
      onSearchChange={async (e: DropdownSearchInputProps) => {
        setIsLoadingDropdown(true);
        const payload = await tagsService.search({
          skip: 0,
          limit: 10,
          filter: {
            content: e.target.value,
            type: [TagType.CONTACT],
            active: true,
          },
        } as IFilterTag);
        setIsLoadingDropdown(false);
        const _optionsTags: ddOptions[] = options;
        payload?.results.forEach((tag: { _id: string; name: string }) => {
          if (!_optionsTags.map((_optag) => _optag.key).includes(tag._id))
            _optionsTags.push({
              key: tag._id,
              text: tag.name,
              value: tag._id,
            });
        });
        handleTagOptions(_optionsTags);
      }}
      onOpen={async () => {
        setIsLoadingDropdown(true);
        const payload = await tagsService.search({
          skip: 0,
          limit: 10,
          filter: {
            content: "",
            type: [TagType.CONTACT],
            active: true,
          },
        });
        setIsLoadingDropdown(false);
        const _optionsTags: ddOptions[] = options;
        payload?.results.forEach((tag: { _id: string; name: string }) => {
          if (!_optionsTags.map((_optag) => _optag.key).includes(tag._id))
            _optionsTags.push({
              key: tag._id,
              text: tag.name,
              value: tag._id,
            });
        });
        handleTagOptions(_optionsTags);
      }}
      onFocus={() => {
        setFocusId("dropdown-contact-tags");
      }}
      onBlur={() => {
        if (typeof handleOnChangeInfo !== "undefined") {
          handleOnChangeInfo({
            field: "tags",
            value: results,
          });
        }
        setFocusId("");
      }}
    />
  );
};

ContactTagsDropdown.defaultProps = {
  handleOnChangeInfo: undefined,
};

export default ContactTagsDropdown;
