import { IHoliday, INewHoliday } from "../slices/holidays.slice";
import instances from "./instances";

const holiday = async ({ _id }: { _id: string }) => {
  try {
    const response = await instances.instanceHolidays.get(`/${_id}`);
    if (response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const update = async ({ _holiday }: { _holiday: IHoliday }) => {
  try {
    const data = _holiday;
    const response = await instances.instanceHolidays.patch(
      `${_holiday._id}`,
      data
    );
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const deleteHolidays = async ({ ids }: { ids: string[] }) => {
  try {
    const data = { id: ids };
    const response = await instances.instanceHolidays.delete("", { data });
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const create = async (data: INewHoliday) => {
  try {
    const response = await instances.instanceHolidays.post("", data);
    if (response && response.data && !response.data.message) {
      return response.data;
    }
    return response.data.message;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const search = async ({
  skip,
  limit,
  filter,
  deleted,
  allStatus,
}: {
  skip?: number;
  limit?: number;
  filter: string;
  deleted: boolean;
  allStatus?: boolean;
}) => {
  try {
    const _filters =
      typeof filter === "object" ? JSON.parse(JSON.stringify(filter)) : {};

    if (typeof filter === "string" && typeof _filters?.content === "undefined")
      _filters.content = filter;
    if (allStatus) _filters.active = false;
    if (deleted) _filters.deleted = true;

    const response = await instances.instanceHolidays.get("", {
      params: {
        skip: skip || 0,
        limit: limit || 10,
        filter: _filters,
        fields:
          "active,created_at,created_by,default,deleted,enablelink,name,holidays,teams._id,teams.name,teams.active,tenant,updated_at,updated_by",
      },
    });
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const holidaysService = {
  holiday,
  update,
  deleteHolidays,
  create,
  search,
};

export default holidaysService;
