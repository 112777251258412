import React from "react";
import Dropdown from "../Dropdown.component";

const CampaignTypesDropdown = () => {
  return (
    <Dropdown
      id="dropdown-campaigntype"
      className="w-full md:w-full"
      disabled
      fluid
      selection
      placeholder="Tipo da campanha"
      label="Tipo da campanha"
      value="WHATSAPP"
      options={[
        {
          key: 0,
          text: "WhatsApp",
          value: "WHATSAPP",
        },
      ]}
    />
  );
};

export default CampaignTypesDropdown;
