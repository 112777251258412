/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
import React from "react";
import { onHoverStyled, onRating } from "../SurveyElements.component";
import { IRatingData, IRatingResultData } from "../../../slices/surveys.slice";

const boxesDefaultCss =
  "rounded-[4px] text-neutral-100 m-1 w-[35px] h-[35px] flex justify-center items-center hover:cursor-pointer";
const detractorsCss = "bg-rose-500 hover:bg-rose-300";
const neutralsCss = "bg-amber-400  hover:bg-amber-200";
const promotersCss = "bg-emerald-500 hover:bg-emerald-300";

const NpsComponent = ({
  rating,
  setRating,
}: {
  rating: IRatingData;
  setRating: React.Dispatch<React.SetStateAction<IRatingData>>;
}) => {
  const cols = [];

  const actionIn = (value: number) => {
    onHoverStyled({ value, type: "nps" }, rating);
  };
  const actionOut = (value: number) => {
    onHoverStyled({ value, type: "nps", action: "out" }, rating);
  };

  for (let i = 0; i <= 10; i++) {
    const colorCss =
      i <= 6 ? detractorsCss : i <= 8 ? neutralsCss : promotersCss;
    cols.push(
      <div
        role="presentation"
        key={`nps-item-${i}`}
        title={`Avaliar com: ${i}`}
        id={`nps-item-${i}`}
        // className="ml-3 w-[45px] h-[45px] cursor-pointer justify-center flex items-center"
        className={`${boxesDefaultCss} ${colorCss}`}
        onClick={() => {
          onRating({ value: i, type: "nps" }, rating, setRating);
        }}
        onFocus={() => {
          actionIn(i);
        }}
        onMouseOver={() => {
          actionIn(i);
        }}
        onBlur={() => {
          actionOut(i);
        }}
        onMouseOut={() => {
          actionOut(i);
        }}
      >
        {/* <div
          role="presentation"
          id={`div-nps-icon-${i}`}
          key={`div-nps-icon-${i}`}
          className="ratingoptions"
          onClick={() => {
            onRating({ value: i, type: "nps" }, rating, setRating);
          }}
          onFocus={() => {
            actionIn(i);
          }}
          onMouseOver={() => {
            actionIn(i);
          }}
          onBlur={() => {
            actionOut(i);
          }}
          onMouseOut={() => {
            actionOut(i);
          }}
        >
          <div
            key={`div-nps-icon-${i}`}
            className={`${boxesDefaultCss} ${colorCss}`}
          >
            {i}
          </div>
        </div> */}
        {i}
      </div>
    );
  }

  return (
    <div
      className={`grid ${
        window.innerWidth > 480 ? "grid-cols-11" : "grid-cols-6"
      } gap-3`}
    >
      {cols}
    </div>
  );
};

export const singleNpsItem = (resultData: IRatingResultData) => {
  const i = resultData.value as number;
  const colorCss = i <= 6 ? detractorsCss : i <= 8 ? neutralsCss : promotersCss;
  return (
    <div
      role="presentation"
      id={`div-nps-icon-${i}`}
      key={`div-nps-icon-${i}`}
      className="ratingoptions"
    >
      <div
        key={`div-nps-icon-${i}`}
        className={`${boxesDefaultCss} ${colorCss}`}
      >
        {i}
      </div>
    </div>
  );
};

export default NpsComponent;
