import React from "react";
import { DropdownProps, SemanticWIDTHS } from "semantic-ui-react";
import Dropdown from "../Dropdown.component";

const ActiveDropdown = ({
  id,
  className,
  disabled,
  defaultValue,
  value,
  onChange,
  width,
  fluid,
}: {
  id?: string;
  width?: SemanticWIDTHS;
  className?: string;
  disabled?: boolean;
  defaultValue?: boolean;
  value?: boolean;
  onChange?:
    | ((
        event: React.SyntheticEvent<HTMLElement, Event>,
        data: DropdownProps
      ) => void)
    | undefined;
  fluid?: boolean;
}) => {
  return (
    <Dropdown
      id={id || "dropdown-status"}
      className={className || "w-full md:w-full"}
      disabled={disabled || false}
      placeholder="false"
      fluid={fluid}
      width={width}
      label="Situação"
      selection
      defaultValue={typeof value === "undefined" ? defaultValue : undefined}
      value={typeof defaultValue === "undefined" ? value : undefined}
      onChange={onChange}
      options={[
        {
          key: 0,
          text: "Ativo",
          value: true,
          // icon: "right floated small circle green icon",
        },
        {
          key: 1,
          text: "Inativo",
          value: false,
          // icon: "right floated small circle red icon",
        },
      ]}
    />
  );
};

ActiveDropdown.defaultProps = {
  id: undefined,
  className: undefined,
  disabled: undefined,
  defaultValue: undefined,
  value: undefined,
  onChange: undefined,
  fluid: undefined,
  width: undefined,
};

export default ActiveDropdown;
