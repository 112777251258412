/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import { DropdownSearchInputProps } from "semantic-ui-react";
import { useAppSelector, useAppDispatch } from "../../../hooks/redux/hooks";
import {
  ExternalAppType,
  IAgideskService,
  reselectExternalappAgidesk,
  updateExternalapps,
} from "../../../slices/externalapps.slice";
import PopupComponent from "../Popup.component";
import Button from "../Buttons/Button.component";
import Dropdown from "../Dropdown.component";
import { ddOptions } from "../../models/interfaces/dropdownOptions.interface";
import { externalappsService, teamsService } from "../../../services";
import useUserIsAdmin from "../../../hooks/useUserIsAdmin";

const TeamServiceRelation = () => {
  const { isAdmin } = useUserIsAdmin();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const { selectedExternalAppAgidesk } = useAppSelector(
    (state) => state.externalapps
  );
  type dataProps = {
    team: { _id: string; name: string };
    service: IAgideskService;
  };

  const [relationValue, setRelationValue] = useState<dataProps>({
    team: {
      _id: "",
      name: "",
    },
    service: {
      id: "",
      title: "",
    },
  });
  const [focusId, setFocusId] = useState<string>("");
  const [isLoadingDropdown, setIsLoadingDropdown] = useState<boolean>(false);

  const TEAMS_LENGTH = user?.teams?.length || 0;

  const [data, setData] = useState<dataProps[]>(
    selectedExternalAppAgidesk?.fields?.ticketfinishteam || []
  );

  const [optionsTeams, setOptionsTeams] = useState<ddOptions[]>([]);
  const [optionsServices, setOptionsServices] = useState<ddOptions[]>([]);

  const [showTeamRelation, setShowTeamRelation] = useState(
    (typeof selectedExternalAppAgidesk?.fields?.ticketfinishteam !==
      "undefined" &&
      selectedExternalAppAgidesk.fields.ticketfinishteam.length > 0) ||
      data.length > 0
  );

  const updateExternalAppWithRelation = async ({
    relations,
  }: {
    relations: dataProps[];
  }) => {
    const _externalapp = {
      ...selectedExternalAppAgidesk,
      type: ExternalAppType.AGIDESK,
      fields: {
        ...selectedExternalAppAgidesk.fields,
        ticketfinishteam: relations?.map((rel) => {
          const _r = {
            ...rel,
            team: rel?.team?._id,
          };
          return _r;
        }),
      },
    };
    const { payload } = await dispatch(updateExternalapps({ _externalapp }));
    dispatch(
      reselectExternalappAgidesk({
        ...selectedExternalAppAgidesk,
        fields: {
          ...selectedExternalAppAgidesk.fields,
          ticketfinishteam: relations,
        },
        updated_at: payload.updated_at,
        updated_by: payload.updated_by,
      })
    );
  };

  const handleClick = async () => {
    let exists = false;
    const _data = data.map((_relation) => {
      if (_relation?.team?._id === relationValue?.team?._id) {
        exists = true;
        return relationValue;
      }
      return _relation;
    });
    if (exists) {
      setData(_data);
    } else {
      setData([..._data, relationValue]);
    }
    setOptionsServices([]);
    setOptionsTeams([]);
    setRelationValue({
      team: {
        _id: "",
        name: "",
      },
      service: {
        id: "",
        title: "",
      },
    });
    await updateExternalAppWithRelation({
      relations: exists ? _data : [..._data, relationValue],
    });
  };

  const handleDelete = async (i: number) => {
    const deleteVal = [...data];
    deleteVal.splice(i, 1);
    setData(deleteVal);
    await updateExternalAppWithRelation({
      relations: deleteVal,
    });
  };

  return (
    <div key="team-service-relation" className="mx-2">
      {TEAMS_LENGTH > 0 ? (
        <PopupComponent
          className="flex items-center"
          icon={
            showTeamRelation
              ? "las la-angle-up"
              : "las la-angle-down pb-[0.3px]"
          }
          classLabel="!font-semibold"
          label={
            showTeamRelation
              ? "Fechar serviços por equipes"
              : "Relacionar serviços por equipes"
          }
          content={
            <h1 className="font-semibold text-[12px]">{`Clique para escolher um serviço específico para cada equipe.\nEquipes sem serviço específico usarão o serviço padrão.`}</h1>
          }
          onClick={() => setShowTeamRelation((prevState) => !prevState)}
        />
      ) : null}

      {showTeamRelation ? (
        <div className="flex flex-col gap-y-2 border rounded-[4px] p-4 mt-2 mb-4 w-full">
          <div className="sm:flex sm:!flex-row flex-col place-items-baseline gap-4 w-full">
            <Dropdown
              id="dropdown-agidesk-team-relation"
              className="mt-2 items-center w-full"
              disabled={selectedExternalAppAgidesk?.deleted || !isAdmin}
              loading={
                isLoadingDropdown &&
                focusId === "dropdown-agidesk-team-relation"
              }
              label="Equipe"
              placeholder="Equipe"
              required
              value={
                typeof relationValue?.team?._id !== "undefined" &&
                relationValue?.team?._id.length > 0
                  ? relationValue?.team?._id
                  : ""
              }
              fluid
              search
              selection
              options={optionsTeams}
              onFocus={() => {
                setFocusId("dropdown-agidesk-team-relation");
              }}
              onBlur={() => {
                setFocusId("");
              }}
              onChange={(e, { value }) => {
                const _team = optionsTeams?.filter(
                  (_opt) => _opt?.key === value
                )[0];
                if (typeof value === "string") {
                  setRelationValue({
                    ...relationValue,
                    team: {
                      _id: _team?.key || "",
                      name: _team?.text || "",
                    },
                  });
                }
              }}
              onSearchChange={async (e: DropdownSearchInputProps) => {
                setIsLoadingDropdown(true);
                const payload = await teamsService.search({
                  skip: 0,
                  limit: 10,
                  filter: e.target.value,
                  deleted: false,
                });
                setIsLoadingDropdown(false);
                const _optionsTeams: ddOptions[] = [];
                const _payload =
                  typeof payload?.results !== "undefined"
                    ? payload?.results
                    : [];
                _payload?.forEach((team: { _id: string; name: string }) => {
                  if (
                    !_optionsTeams
                      .map((_opteam) => _opteam.key)
                      .includes(team._id)
                  )
                    _optionsTeams.push({
                      key: team._id,
                      text: team.name,
                      value: team._id,
                    });
                });
                setOptionsTeams(_optionsTeams);
              }}
              onOpen={async () => {
                setIsLoadingDropdown(true);
                const payload = await teamsService.search({
                  skip: 0,
                  limit: 10,
                  filter: "",
                  deleted: false,
                });
                setIsLoadingDropdown(false);
                const _optionsTeams: ddOptions[] = [];
                const _payload =
                  typeof payload?.results !== "undefined"
                    ? payload?.results
                    : [];
                _payload?.forEach((team: { _id: string; name: string }) => {
                  if (
                    !_optionsTeams
                      .map((_opteam) => _opteam.key)
                      .includes(team._id)
                  )
                    _optionsTeams.push({
                      key: team._id,
                      text: team.name,
                      value: team._id,
                    });
                });
                setOptionsTeams(_optionsTeams);
              }}
            />
            <Dropdown
              id="dropdown-agidesk-service-relation"
              className="mt-2 items-center w-full"
              disabled={selectedExternalAppAgidesk?.deleted || !isAdmin}
              loading={
                isLoadingDropdown &&
                focusId === "dropdown-agidesk-service-relation"
              }
              label="Serviço"
              placeholder="Serviço"
              required
              value={
                typeof relationValue?.service?.id !== "undefined" &&
                relationValue?.service?.id.length > 0
                  ? relationValue?.service?.id
                  : ""
              }
              fluid
              search
              selection
              options={optionsServices}
              onFocus={() => {
                setFocusId("dropdown-agidesk-service-relation");
              }}
              onBlur={() => {
                setFocusId("");
              }}
              onChange={(e, { value }) => {
                const _service = optionsServices?.filter(
                  (_opt) => _opt?.key === value
                )[0];
                if (typeof _service?.key !== "undefined") {
                  setRelationValue({
                    ...relationValue,
                    service: {
                      id: _service?.key || "",
                      title: _service?.text || "",
                    },
                  });
                }
              }}
              onSearchChange={async (e: DropdownSearchInputProps) => {
                setIsLoadingDropdown(true);
                const payload = await externalappsService.searchAgideskServices(
                  {
                    skip: 0,
                    limit: 10,
                    filter: e.target.value,
                    externalappid: selectedExternalAppAgidesk._id,
                    main: true,
                  }
                );
                setIsLoadingDropdown(false);
                const _optionsInstance: ddOptions[] = optionsServices;
                payload?.map((service: { id: string; fullservice: string }) => {
                  if (
                    _optionsInstance?.filter(
                      (_opservice) => _opservice.key === service.id
                    )[0]
                  ) {
                    return false;
                  }
                  _optionsInstance.push({
                    key: service.id,
                    text: service.fullservice,
                    value: service.id,
                  });
                  return true;
                });
                setOptionsServices(_optionsInstance);
              }}
              onOpen={async () => {
                setIsLoadingDropdown(true);
                const payload = await externalappsService.searchAgideskServices(
                  {
                    skip: 0,
                    limit: 10,
                    filter: "",
                    externalappid: selectedExternalAppAgidesk._id,
                    main: true,
                  }
                );
                setIsLoadingDropdown(false);
                const _optionsServices: ddOptions[] = [];
                payload?.forEach(
                  (service: { id: string; fullservice: string }) => {
                    if (
                      !_optionsServices
                        .map((_opservice) => _opservice.key)
                        .includes(service.id)
                    )
                      _optionsServices.push({
                        key: service.id,
                        text: service.fullservice,
                        value: service.id,
                      });
                  }
                );
                setOptionsServices(_optionsServices);
              }}
            />
          </div>
          <div className="my-2 flex justify-end">
            <Button
              disabled={
                relationValue?.team?._id?.length === 0 ||
                relationValue?.service?.id?.length === 0
              }
              minWidth
              icon="las la-plus"
              label="Adicionar"
              title="Adicionar relação"
              onClick={handleClick}
            />
          </div>

          <label>Relações entre equipes e serviços</label>
          {data.length > 0 && typeof data[0].service !== "undefined" ? (
            <div
              className={`flex flex-wrap ${
                data?.length ? "" : "justify-center"
              }`}
            >
              {data.map((val: dataProps, i: number) => {
                // const _team = user?.teams?.find((_t) => _t._id === val?.team);
                const _service = {
                  _id: val?.service?.id,
                  name: val?.service?.title,
                };
                const _team = {
                  _id: val?.team?._id,
                  name: val?.team?.name,
                };
                return (
                  <div
                    key={`${_team?._id}-${_service._id}`}
                    className="cursor-pointer mr-4 flex items-center px-5 py-3 shadow rounded-md mt-1"
                  >
                    <h2 className="text-[12px] font-semibold">{_team?.name}</h2>
                    <i
                      title="Relação"
                      className="flex items-center las la-link px-2"
                    />
                    <h2 className="text-[12px] font-semibold">
                      {_service?.name}
                    </h2>
                    <i
                      title="Remover relação"
                      className="pl-2 flex items-center text-red las text-[16pxX] la-times-circle cursor-pointer hover:text-red-alphaC0"
                      role="presentation"
                      onClick={() => handleDelete(i)}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="mt-5 text-center">Nenhuma relação criada</div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default TeamServiceRelation;
