/* eslint-disable react/no-danger */
/* eslint-disable no-nested-ternary */
import React from "react";
// import Draggable from "react-draggable";
import ActionButtonsModal from "./ActionButtonsModal.component";
import { UserType } from "../../../slices/users.slice";
import { useAppSelector } from "../../../hooks/redux/hooks";

const ConfirmationModal = ({
  setShowModal,
  widgetType,
  title,
  action,
  disabled,
  content,
  submitLabel,
  submitIcon,
  cancelLabel,
  cancelIcon,
  cancelAction,
}: {
  submitLabel?: string;
  submitIcon?: string;
  cancelLabel?: string;
  cancelIcon?: string;
  action(): void;
  cancelAction?(): void;
  title: string;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  widgetType?: UserType;
  content?: string;
  disabled: boolean;
}) => {
  const { recentChats } = useAppSelector((state) => state.chats);

  const _cancelAction = () => {
    setShowModal(false);
  };

  return (
    <div id="modal" className="fixed inset-0 z-[200] overflow-y-auto mx-auto">
      <div
        role="presentation"
        className="fixed inset-0 w-full h-full bg-black opacity-80"
        onClick={
          typeof cancelAction !== "undefined" ? cancelAction : _cancelAction
        }
      />
      <div className="flex items-center justify-center min-h-screen">
        {/* <Draggable bounds="parent"> */}
        <div
          className={`relative ${
            widgetType === UserType.NONE
              ? "w-full m-4"
              : widgetType === UserType.AGENT && recentChats.length > 0
              ? "ml-[200px] w-[386px]"
              : "w-4/5"
          } max-w-xl bg-white rounded-md shadow-lg py-8 px-10`}
        >
          <h1 className="flex items-center justify-center font-semibold">
            <i className="las la-exclamation-circle text-3xl" />
            <span
              className="text-xl ml-3"
              dangerouslySetInnerHTML={{
                __html: title,
              }}
            />
          </h1>
          <div className="mx-auto mt-6">
            {content ? (
              <div className="mb-6 text-justify">
                <span
                  dangerouslySetInnerHTML={{
                    __html: content,
                  }}
                />
              </div>
            ) : null}
            <ActionButtonsModal
              cancelAction={
                typeof cancelAction !== "undefined"
                  ? cancelAction
                  : _cancelAction
              }
              submitAction={action}
              disabled={disabled}
              submitIcon={submitIcon}
              submitLabel={submitLabel}
              cancelLabel={cancelLabel}
              cancelIcon={cancelIcon}
            />
          </div>
        </div>
        {/* </Draggable> */}
      </div>
    </div>
  );
};

ConfirmationModal.defaultProps = {
  widgetType: UserType.NONE,
  content: "",
  submitLabel: undefined,
  submitIcon: undefined,
  cancelLabel: undefined,
  cancelIcon: undefined,
  cancelAction: undefined,
};

export default ConfirmationModal;
