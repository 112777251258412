import React from "react";
import { useTranslation } from "react-i18next";
import { Label } from "semantic-ui-react";

const CommonError = () => {
  const { t } = useTranslation();
  return (
    <div className="mt-3">
      <Label basic prompt className="red">
        {t("form.error.common")}
      </Label>
    </div>
  );
};

export default CommonError;
