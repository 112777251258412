/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-children-prop */
import React, { useCallback, useEffect, useRef } from "react";
import ReactInputMask from "react-input-mask";
import { Form } from "semantic-ui-react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../hooks/redux/hooks";
import { IUser } from "../../../../../slices/users.slice";
import {
  ITemplate,
  ITemplateVarsArray,
  Parameter,
  reselectTemplateButtons,
  reselectTemplateFiles,
  reselectTemplateMessage,
  reselectTemplateParameters,
} from "../../../../../slices/messages.slice";
import handleMaskLength from "../../../../../shared/utils/handleMaskLength";
import FilesArea from "../../../../../shared/components/Files/FilesArea.component";
import { IAcceptedFiles } from "../../../../../shared/models/interfaces/acceptedfile.interface";
import { ICampaign } from "../../../../../slices/campaigns.slice";

const VariableControl = ({
  campaign,
  contactChat,
  results,
  files,
  setFiles,
  setTemplateButtons,
}: {
  campaign?: ICampaign;
  contactChat?: IUser;
  results: ITemplate;
  files: IAcceptedFiles;
  setFiles: React.Dispatch<React.SetStateAction<IAcceptedFiles>>;
  setTemplateButtons: React.Dispatch<
    React.SetStateAction<{
      url: string;
      code: string;
    }>
  >;
}) => {
  const { user } = useAppSelector((state) => state.auth);
  const {
    selectedTemplate,
    selectedTemplateParameters,
    selectedTemplateButtons,
  } = useAppSelector((state) => state.messages);
  const dispatch = useAppDispatch();

  const replaceVars = (
    index: string,
    _value: string,
    onLoad?: boolean,
    buttons?: boolean
  ) => {
    if (selectedTemplateParameters && !onLoad) {
      for (
        let gIndex = 0;
        gIndex < selectedTemplateParameters.length;
        gIndex++
      ) {
        const groupParameters = selectedTemplateParameters[gIndex];
        for (
          let pIndex = 0;
          pIndex < groupParameters.parameters.length;
          pIndex++
        ) {
          const parameter = groupParameters.parameters[pIndex];
          if (parameter.key === index) {
            dispatch(
              reselectTemplateParameters({
                gIndex,
                pIndex,
                newValue: _value,
              })
            );
            if (typeof buttons === "undefined" || !buttons) {
              dispatch(
                reselectTemplateMessage({
                  index:
                    parameter.text && parameter.text.length > 0
                      ? `</>${parameter.text}</>`
                      : `${index}`,
                  newValue: _value.length > 0 ? _value : index,
                })
              );
            }
          }
        }
      }
    }
    if (typeof buttons === "undefined" || !buttons) {
      const isBody = index.search("B");
      const element =
        isBody !== -1
          ? document.getElementsByClassName("preview_body")
          : document.getElementsByClassName("preview_header");
      const content = element[0].innerHTML;
      const find = content.search(`${index}`);
      let replaceContent = "";
      const spanId =
        isBody !== -1 ? index.replace("B", "S") : index.replace("H", "SH");
      if (find !== -1 && content) {
        // ON LOAD
        replaceContent = content
          ? content?.replaceAll(index, `<span id="${spanId}">${_value}</span>`)
          : "";
        element[0].innerHTML = replaceContent;
      } else {
        const spanelement = document.querySelectorAll(`[id="${spanId}"]`);
        if (typeof spanelement !== "undefined" && spanelement != null) {
          if (typeof spanelement !== "undefined" && spanelement.length > 0) {
            for (let indexEl2 = 0; indexEl2 < spanelement.length; indexEl2++) {
              const el = spanelement[indexEl2];
              el.innerHTML = _value === "" ? index : _value;
            }
          }
        }
      }
    }
  };

  const defaultVars = [
    "contact.name",
    "contact.tenant",
    "contact.phone",
    "contact.email",
    "${_contact_name}",
    "${_contact_phone}",
    "${_contact_email}",
    // "user.name",
    // "user.email",
  ];

  const getDefaultValue = (_var: string, index?: string) => {
    try {
      if (
        index &&
        typeof campaign?._id !== "undefined" &&
        typeof campaign?.templatevars !== "undefined" &&
        typeof campaign?.template !== "undefined" &&
        campaign?.templatevars &&
        selectedTemplate?.id === campaign.template
      ) {
        const _templatevars: ITemplateVarsArray = JSON.parse(
          campaign.templatevars
        );
        let _value = "";
        if (
          typeof _templatevars !== "undefined" &&
          _templatevars &&
          _templatevars.length > 0
        ) {
          _templatevars.forEach((_templatevar) => {
            if (_templatevar.key === index) {
              _value = _templatevar.text as string;
            }
          });
        }
        return _value;
      }
      switch (_var) {
        case "${_contact_name}":
        case "contact.name":
          return contactChat?.name || "";
        case "contact.tenant":
          return contactChat?.tenant || "";
        case "${_contact_phone}":
        case "contact.phone":
          return contactChat?.phone || "";
        case "${_contact_email}":
        case "contact.email":
          return contactChat?.email || "";
        case "user.name":
          return user?.name || "";
        case "user.email":
          return user?.email || "";
        default:
          return "";
      }
    } catch (error) {
      // console.log(error);
      return "";
    }
  };

  const getDefaultValueButtons = (_parameter?: Parameter) => {
    try {
      if (
        typeof _parameter?.key !== "undefined" &&
        typeof campaign?._id !== "undefined" &&
        typeof campaign?.templatevars !== "undefined" &&
        typeof campaign?.template !== "undefined" &&
        campaign?.templatevars &&
        selectedTemplate?.id === campaign.template
      ) {
        const _templatevars = JSON.parse(campaign.templatevars);
        let _value = "";
        if (
          typeof _templatevars !== "undefined" &&
          _templatevars &&
          _templatevars.length > 0
        ) {
          for (const _templatevar of _templatevars) {
            if (_templatevar.key === _parameter?.key) {
              if (typeof _parameter?.coupon_code !== "undefined") {
                _value = _templatevar.coupon_code;
              } else {
                _value = _templatevar.text;
              }
            }
          }
        }
        return _value;
      }
      if (typeof _parameter?.coupon_code !== "undefined")
        return _parameter?.coupon_code;
      return undefined;
    } catch (error) {
      // console.log(error);
      return "";
    }
  };

  const onLoad = () => {
    let _value = "";
    if (
      results?.components[0]?.text &&
      results?.components[0].type === "HEADER" &&
      results?.components[0]?.example?.header_text
    ) {
      results?.components[0].example.header_text[0].forEach((_var, index) => {
        _value = getDefaultValue(_var, `{{H-${index + 1}}}`);
        if (_value && selectedTemplateParameters) {
          replaceVars(`{{H-${index + 1}}}`, _value, true);
          for (
            let gIndex = 0;
            gIndex < selectedTemplateParameters.length;
            gIndex++
          ) {
            const groupParameters = selectedTemplateParameters[gIndex];
            if (groupParameters.type === "HEADER") {
              for (
                let pIndex = 0;
                pIndex < groupParameters.parameters.length;
                pIndex++
              ) {
                const parameter = groupParameters.parameters[pIndex];
                if (parameter.text === `{{H-${index + 1}}}`) {
                  dispatch(
                    reselectTemplateParameters({
                      gIndex,
                      pIndex,
                      newValue: _value,
                    })
                  );
                  dispatch(
                    reselectTemplateMessage({
                      index: `</>{{H-${index + 1}}}</>`,
                      newValue: _value,
                    })
                  );
                } else if (
                  typeof campaign !== "undefined" &&
                  parameter.text === `{{${_var}}}`
                ) {
                  dispatch(
                    reselectTemplateMessage({
                      index: `{{H-${index + 1}}}`,
                      newValue: parameter.text,
                    })
                  );
                }
              }
            }
          }
        }
      });
    }
    if (
      results?.components[1]?.text &&
      results?.components[1].type === "BODY" &&
      results?.components[1]?.example?.body_text
    ) {
      results?.components[1].example.body_text[0].forEach((_var, index) => {
        _value = getDefaultValue(_var, `{{B-${index + 1}}}`);
        if (_value && selectedTemplateParameters) {
          replaceVars(`{{B-${index + 1}}}`, _value, true);
          for (
            let gIndex = 0;
            gIndex < selectedTemplateParameters.length;
            gIndex++
          ) {
            const groupParameters = selectedTemplateParameters[gIndex];
            if (groupParameters.type === "BODY") {
              for (
                let pIndex = 0;
                pIndex < groupParameters.parameters.length;
                pIndex++
              ) {
                const parameter = groupParameters.parameters[pIndex];
                if (parameter.text === `{{B-${index + 1}}}`) {
                  dispatch(
                    reselectTemplateParameters({
                      gIndex,
                      pIndex,
                      newValue: _value,
                    })
                  );
                  dispatch(
                    reselectTemplateMessage({
                      index: `</>{{B-${index + 1}}}</>`,
                      newValue: _value,
                    })
                  );
                } else if (
                  typeof campaign !== "undefined" &&
                  parameter.text === `{{${_var}}}`
                ) {
                  dispatch(
                    reselectTemplateMessage({
                      index: `</>{{B-${index + 1}}}</>`,
                      newValue: parameter.text,
                    })
                  );
                }
              }
            }
          }
        }
      });
    }
    if (
      results?.components[0]?.text &&
      results?.components[0].type === "BODY" &&
      results?.components[0]?.example?.body_text
    ) {
      results?.components[0].example.body_text[0].forEach((_var, index) => {
        _value = getDefaultValue(_var, `{{B-${index + 1}}}`);
        if (_value && selectedTemplateParameters) {
          replaceVars(`{{B-${index + 1}}}`, _value, true);
          for (
            let gIndex = 0;
            gIndex < selectedTemplateParameters.length;
            gIndex++
          ) {
            const groupParameters = selectedTemplateParameters[gIndex];
            if (groupParameters.type === "BODY") {
              for (
                let pIndex = 0;
                pIndex < groupParameters.parameters.length;
                pIndex++
              ) {
                const parameter = groupParameters.parameters[pIndex];
                if (parameter.text === `{{B-${index + 1}}}`) {
                  dispatch(
                    reselectTemplateParameters({
                      gIndex,
                      pIndex,
                      newValue: _value,
                    })
                  );
                  dispatch(
                    reselectTemplateMessage({
                      index: `{{B-${index + 1}}}`,
                      newValue: _value,
                    })
                  );
                } else if (
                  typeof campaign !== "undefined" &&
                  parameter.text === `{{${_var}}}`
                ) {
                  dispatch(
                    reselectTemplateMessage({
                      index: `{{B-${index + 1}}}`,
                      newValue: parameter.text,
                    })
                  );
                }
              }
            }
          }
        }
      });
    }
  };

  useEffect(() => {
    setFiles([]);
    dispatch(reselectTemplateFiles({ files: [] }));
    if (
      typeof campaign?._id !== "undefined" &&
      typeof campaign?.templatevars !== "undefined" &&
      campaign?.templatevars &&
      campaign?.templatevars.length > 0 &&
      selectedTemplate?.id === campaign.template
    ) {
      const _campaignVars = JSON.parse(campaign?.templatevars);
      for (const _templatevar of _campaignVars) {
        if (
          _templatevar.key === "{{FILE-1}}" &&
          _templatevar.type === "file" &&
          typeof _templatevar.file !== "undefined"
        ) {
          const { file, url } = _templatevar;
          const _file = new File([file], file.name, {
            type: file.type || file.mimetype,
            lastModified: file.lastModified,
          });
          dispatch(
            reselectTemplateFiles({
              files: [
                Object.assign(_file, {
                  preview: url,
                }),
              ],
            })
          );
          setFiles([
            Object.assign(_file, {
              preview: url,
            }),
          ]);
        }
      }
    }
    onLoad();
  }, [selectedTemplate?.id]);

  useEffect(() => {
    const _getValue = () => {
      if (files.length > 0 && typeof files[0]?.preview !== "undefined") {
        return files[0]?.preview;
      }
      if (
        typeof results?.components[0]?.example?.header_handle !== "undefined"
      ) {
        return results?.components[0].example.header_handle[0];
      }
      return undefined;
    };
    const _file = _getValue();
    const _type =
      typeof results?.components[0]?.format !== "undefined"
        ? results?.components[0]?.format
        : undefined;
    if (typeof _file !== "undefined" && typeof _type !== "undefined") {
      dispatch(
        reselectTemplateParameters({
          gIndex: 0,
          pIndex: 0,
          file: true,
          newValue: _file,
          type: _type,
        })
      );
    }
  }, [files]);

  const buildButtonVars = ({ _parameter }: { _parameter?: Parameter }) => {
    return (
      <Form.Group
        key={`${_parameter?.key} - ${_parameter?.type}-group`}
        widths="equal"
      >
        <span className="flex items-center cursor-normal w-full md:w-full ml-2 max-w-[300px]">
          {typeof _parameter?.url !== "undefined"
            ? _parameter?.url
            : `${_parameter?.key} - ${_parameter?.type}`}
        </span>
        <Form.Input
          autoComplete="off"
          className="w-full md:w-full"
          placeholder={_parameter?.key}
          fluid
          required
          defaultValue={getDefaultValueButtons(_parameter)}
          onChange={(e) => {
            if (typeof _parameter?.coupon_code !== "undefined") {
              dispatch(
                reselectTemplateButtons({
                  ...selectedTemplateButtons,
                  code: e.target.value,
                })
              );
              setTemplateButtons((prevState) => ({
                ...prevState,
                code: e.target.value,
              }));
            } else if (typeof _parameter?.url !== "undefined") {
              dispatch(
                reselectTemplateButtons({
                  ...selectedTemplateButtons,
                  url: e.target.value,
                })
              );
              setTemplateButtons((prevState) => ({
                ...prevState,
                url: e.target.value,
              }));
            }
            replaceVars(_parameter?.key || "", e.target.value, false, true);
          }}
        />
      </Form.Group>
    );
  };

  const getButtonVars = () => {
    const jsx = [];
    if (typeof results?.components !== "undefined") {
      for (const index in Object.keys(results.components)) {
        if (Object.prototype.hasOwnProperty.call(results.components, index)) {
          const element = results?.components[index];
          if (
            element.type === "button" &&
            typeof element.parameters !== "undefined" &&
            (typeof element.parameters[0].coupon_code !== "undefined" ||
              typeof element.parameters[0].text !== "undefined")
          ) {
            jsx.push(
              buildButtonVars({
                _parameter: element.parameters[0],
              })
            );
          }
        }
      }

      if (jsx.length > 0) {
        jsx.unshift(<label>Variáveis (botões)</label>);
      }
    }
    return jsx;
  };

  const buildFields = (type: string, _var?: string, index?: string) => (
    <Form.Group key={`${index} - ${_var}-group`} widths="equal">
      {type === "TEXT" &&
      typeof _var !== "undefined" &&
      typeof index !== "undefined" ? (
        <>
          <span className="flex items-center cursor-normal w-full md:w-full ml-2 max-w-[300px]">
            {`${index} - ${_var}`}
          </span>
          {!_var.includes("phone") || typeof campaign?._id !== "undefined" ? (
            <Form.Input
              autoComplete="off"
              className="w-full md:w-full"
              placeholder={index}
              fluid
              required
              disabled={
                typeof campaign?._id !== "undefined" &&
                defaultVars.includes(_var)
              }
              readOnly={
                typeof campaign?._id !== "undefined" &&
                defaultVars.includes(_var)
              }
              defaultValue={
                typeof campaign?._id !== "undefined" &&
                defaultVars.includes(_var)
                  ? "Informação do contato"
                  : getDefaultValue(_var, index)
              }
              onChange={(e) => {
                replaceVars(index, e.target.value);
              }}
            />
          ) : (
            <Form.Input
              autoComplete="off"
              className="w-full md:w-full"
              key={`${index} - ${_var}-field`}
              fluid
              disabled={
                typeof campaign?._id !== "undefined" &&
                defaultVars.includes(_var)
              }
              readOnly={
                typeof campaign?._id !== "undefined" &&
                defaultVars.includes(_var)
              }
              children={
                <ReactInputMask
                  mask={handleMaskLength({
                    value: getDefaultValue(_var, index),
                  })}
                  maskPlaceholder={null}
                  placeholder={index}
                  type="phone"
                  required
                  disabled={
                    typeof campaign?._id !== "undefined" &&
                    defaultVars.includes(_var)
                  }
                  readOnly={
                    typeof campaign?._id !== "undefined" &&
                    defaultVars.includes(_var)
                  }
                  defaultValue={
                    typeof campaign?._id !== "undefined" &&
                    defaultVars.includes(_var)
                      ? "Informação do contato"
                      : getDefaultValue(_var, index)
                  }
                  onChange={(e) => {
                    replaceVars(index, e.target.value);
                  }}
                />
              }
            />
          )}
        </>
      ) : (
        <Form.Field
          required
          className="flex items-center cursor-normal w-full md:w-full"
        >
          <FilesArea
            extraClass="w-full"
            single
            hideLabel
            type={type}
            files={files}
            setFiles={setFiles}
          />
        </Form.Field>
      )}
    </Form.Group>
  );

  const getLabelType = (type: string) => {
    if (type === "IMAGE") return "Imagem";
    if (type === "VIDEO") return "Vídeo";
    return "Documento";
  };

  const _index = useRef(3);

  const getComponentIndex = useCallback(() => {
    if (typeof results?.components !== "undefined") {
      for (const index in Object.keys(results.components)) {
        if (Object.prototype.hasOwnProperty.call(results.components, index)) {
          const element = results?.components[index];
          if (element.type === "BUTTONS") {
            _index.current = +index;
          }
        }
      }
    }
  }, [results]);

  useEffect(() => {
    getComponentIndex();
  }, [getComponentIndex]);

  const buildButtonsArea = () => {
    let _build = false;
    if (
      results?.components[_index.current]?.buttons &&
      results?.components[_index.current].type === "BUTTONS"
    ) {
      results?.components[_index.current]?.buttons?.forEach((_button) => {
        if (
          (_button?.type === "URL" &&
            typeof _button?.example !== "undefined" &&
            _button?.url?.includes("{{1}}")) ||
          (_button?.type === "COPY_CODE" &&
            typeof _button?.example !== "undefined")
        ) {
          _build = true;
        }
      });
    }
    return _build ? (
      <Form.Field
        required
        key={results?.components[_index.current].type}
        className="pt-[5px]"
      >
        {/* <label>Variáveis (botões)</label> */}
        {getButtonVars()}
      </Form.Field>
    ) : null;
  };

  return (
    <>
      {/* HEADER - FILES */}
      {typeof results?.components[0]?.format !== "undefined" &&
      ["IMAGE", "VIDEO", "DOCUMENT"].includes(results?.components[0]?.format) &&
      results?.components[0].type === "HEADER" &&
      typeof results?.components[0]?.example?.header_handle !== "undefined" ? (
        <div key={results?.components[0].type} className="pt-[5px]">
          <div className="flex">
            <label>
              {`${getLabelType(results?.components[0]?.format)} - (cabeçalho)`}
            </label>
            <label className="!text-red ml-1 mb-1">*</label>
          </div>
          {buildFields(
            typeof results?.components[0]?.format !== "undefined"
              ? results?.components[0].format
              : "TEXT"
          )}
        </div>
      ) : null}
      {/* HEADER - TEXT */}
      {results?.components[0]?.text &&
      results?.components[0].type === "HEADER" &&
      results?.components[0]?.example?.header_text ? (
        <Form.Field
          required
          key={results?.components[0].type}
          className="pt-[5px]"
        >
          <label>Variáveis (cabeçalho)</label>
          {results?.components[0].example.header_text[0].map((_var, index) =>
            buildFields("TEXT", _var, `{{H-${index + 1}}}`)
          )}
        </Form.Field>
      ) : null}
      {/* HEADER + BODY */}
      {results?.components[1]?.text &&
      results?.components[1].type === "BODY" &&
      results?.components[1]?.example?.body_text ? (
        <Form.Field
          required
          key={results?.components[1].type}
          className="pt-[5px]"
        >
          <label>Variáveis (corpo)</label>
          {results?.components[1].example.body_text[0].map((_var, index) =>
            buildFields("TEXT", _var, `{{B-${index + 1}}}`)
          )}
        </Form.Field>
      ) : null}
      {/* ONLY BODY */}
      {results?.components[0]?.text &&
      results?.components[0].type === "BODY" &&
      results?.components[0]?.example?.body_text ? (
        <Form.Field
          required
          key={results?.components[0].type}
          className="pt-[5px]"
        >
          <label>Variáveis (corpo)</label>
          {results?.components[0].example.body_text[0].map((_var, index) =>
            buildFields("TEXT", _var, `{{B-${index + 1}}}`)
          )}
        </Form.Field>
      ) : null}
      {/* BUTTONS */}
      {buildButtonsArea()}
    </>
  );
};

VariableControl.defaultProps = {
  campaign: undefined,
  contactChat: undefined,
};

export default VariableControl;
