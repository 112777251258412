/* eslint-disable react/no-danger */
import React, { useEffect, useState } from "react";
import { UserType } from "../slices/users.slice";
import AuthLayoutPage from "./AuthLayout.page";
import privacy from "../assets/html/privacy";
import Button from "../shared/components/Buttons/Button.component";

const Privacy = ({ widgetType }: { widgetType?: UserType }) => {
  const [showButton, setshowButton] = useState(false);

  useEffect(() => {
    const handleScrollButtonVisibility = () => {
      setshowButton(window.scrollY > 300);
    };

    window.addEventListener("scroll", handleScrollButtonVisibility);
    return () => {
      window.removeEventListener("scroll", handleScrollButtonVisibility);
    };
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="max-w-[1000px] w-full p-4 m-4">
      <AuthLayoutPage widgetType={widgetType}>
        <div className="mx-4">
          {showButton ? (
            <Button
              minWidth={false}
              onClick={handleScrollToTop}
              extraClass="py-6 px-6 border-[2px] rounded-full flex fixed right-[1%] lg:right-[2%] transition-all z-10 bottom-[30px] min-w-[50px] w-[50px] h-[50px] opacity-100"
              icon="las la-angle-up text-[22px]"
            />
          ) : null}
          <span
            dangerouslySetInnerHTML={{
              __html: privacy,
            }}
          />
        </div>
      </AuthLayoutPage>
    </div>
  );
};

Privacy.defaultProps = {
  widgetType: UserType.NONE,
};

export default Privacy;
