import React from "react";
import { useTranslation } from "react-i18next";

const ToggleUsers = ({
  toggleList,
  onClickUsers,
  onClickContacts,
}: {
  toggleList: string;
  onClickUsers: () => void;
  onClickContacts: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <div className="cursor-pointer flex items-center ml-2">
      <div className="flex items-center">
        <div
          role="presentation"
          className={`group h-[28px] w-[58px] border border-gray-a6 rounded-tl-[20px] rounded-bl-[20px] px-[2px] py-[6px] relative text-center flex items-center
            hover:border-gray-bench hover:bg-gray-bench
            ${
              toggleList === "contacts"
                ? "bg-gray-bench border-gray-bench"
                : "border-gray-bench border-r-0"
            }
            `}
          onClick={onClickContacts}
        >
          <div
            className={`border-none mx-auto flex self-center active:text-white  group-active:text-white group-hover:text-white group-disabled:text-white ${
              toggleList === "contacts" ? "text-white" : "text-gray-text"
            }
              `}
            title={t("toggle-selector.contacts") || ""}
          >
            <i className="las la-address-book text-[18px] hover:cursor-auto" />
          </div>
        </div>
        <div
          role="presentation"
          onClick={onClickUsers}
          className={`group h-[28px] w-[58px] border rounded-tr-[20px] rounded-br-[20px] px-[2px] py-[6px] relative text-center flex items-center
            hover:border-gray-bench hover:bg-gray-bench
            ${
              toggleList === "users"
                ? "bg-gray-bench border-gray-bench"
                : "border-gray-bench border-l-0"
            }
            `}
        >
          <div
            className={`border-none mx-auto flex self-center  active:text-white  group-active:text-white group-hover:text-white ${
              toggleList === "users" ? "text-white" : "text-gray-text"
            }
              `}
            title={t("toggle-selector.users") || ""}
          >
            <i className="las la-user text-[18px] hover:cursor-auto" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ToggleUsers;
