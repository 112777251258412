import React from "react";
import useAbrevName from "../../hooks/useAbrevName";

const NoAvatar = ({
  displaytext,
  elementClass,
  labelClass,
  colorClass,
}: {
  displaytext: string;
  elementClass?: string;
  labelClass?: string;
  colorClass?: string;
}) => {
  return (
    <div
      className={`${
        elementClass || "p-4"
      } w-full h-full flex items-center justify-center`}
    >
      <div
        title={displaytext}
        className={`w-full h-full flex items-center justify-center rounded-full border shadow ${
          colorClass || "bg-gray-333 border-gray-333"
        }`}
      >
        <p className={`${labelClass || "text-[18px]"} text-white leading-none`}>
          {useAbrevName(displaytext) || "--"}
        </p>
      </div>
    </div>
  );
};

NoAvatar.defaultProps = {
  elementClass: "",
  labelClass: "",
  colorClass: "",
};

export default NoAvatar;
