/* eslint-disable no-case-declarations */
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IUser } from "../../../../../slices/users.slice";
import { Header } from "../../../../../shared/components/Sidebars";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../hooks/redux/hooks";
import SectionElement from "../../../../../shared/components/SectionElement.component";
import PopupComponent from "../../../../../shared/components/Popup.component";
import UserCard from "../../../../../shared/components/UserCard.component";
import ActionButtonsModal from "../../../../../shared/components/Modals/ActionButtonsModal.component";
import {
  ChatAdmin,
  ChatGroupRole,
  IChat,
  changeChat,
  chatFirst,
  // changeChat,
  reselectChat,
} from "../../../../../slices/chats.slice";
import { chatsService } from "../../../../../services";
import customToast from "../../../../../shared/utils/customToast";
import { IToastType } from "../../../../../shared/models/types/Toast.type";
import Button from "../../../../../shared/components/Buttons/Button.component";
import ConfirmationModal from "../../../../../shared/components/Modals/ConfirmationModal.component";
import Toggle from "../../../../../shared/components/Toggle.component";
import SearchBox from "../../../../../shared/components/SearchBox.component";
import useSendInternalMessage from "../../../../../hooks/useSendInternalMessage";
import {
  IMessage,
  InternalMessageType,
} from "../../../../../slices/messages.slice";

const ChatGroupDetails = ({
  disable,
  setDisable,
  setChatContact,
  setVisible,
  setLastUrl,
  setBlockSidebar,
  setShowModal,
  setShowDeleteModal,
  goBack,
}: {
  disable: boolean;
  setDisable: React.Dispatch<React.SetStateAction<boolean>>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
  setChatContact: React.Dispatch<React.SetStateAction<IUser | undefined>>;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setBlockSidebar: React.Dispatch<React.SetStateAction<boolean>>;
  setLastUrl: React.Dispatch<React.SetStateAction<string>>;
  goBack: () => void;
}) => {
  const { t } = useTranslation();
  const { sendInternalMessage } = useSendInternalMessage();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const { selectedChat } = useAppSelector((state) => state.chats);

  const [currentChat, setChat] = useState<IChat | null>(selectedChat);
  const [removeUsersModal, setRemoveUsersModal] = useState<boolean>(false);
  const [addUsersModal, setAddUsersModal] = useState<boolean>(false);
  const [keyword, setKeyword] = useState<string>("");
  const [oldUsers, setOldUsers] = useState<boolean>(false);
  const [removeUser, setRemoveUser] = useState<IUser | undefined>();
  const [addUser, setAddUser] = useState<IUser | undefined>();

  const _isSessionAdminCheck = selectedChat?.groupsettings
    ?.filter((_groupuser) =>
      typeof _groupuser.user?._id !== "undefined"
        ? _groupuser.user?._id === user?._id
        : _groupuser.user === user?._id
    )[0]
    ?.role?.includes(ChatGroupRole.ADMIN);
  const _isActiveUserGroup: string[] = [];
  selectedChat?.groupsettings?.forEach((_groupuser) => {
    if (_groupuser.active === true)
      _isActiveUserGroup.push(
        typeof _groupuser.user?._id !== "undefined"
          ? (_groupuser.user?._id as string)
          : (_groupuser.user as any)
      );
  });

  const getFiltered = () => {
    // console.log(selectedChat?.users);
    // console.log(selectedChat?.groupsettings?.map((_gs) => _gs.user));
    const _users = oldUsers
      ? selectedChat?.groupsettings?.map((_gs) => _gs.user as IUser)
      : selectedChat?.users;
    if (keyword.length > 0) {
      if (!oldUsers) {
        return _users?.filter(
          (_user) =>
            _user?._id &&
            (_user.name?.toLowerCase()?.includes(keyword.toLowerCase()) ||
              _user.email?.toLowerCase()?.includes(keyword.toLowerCase()) ||
              _user.phone?.toLowerCase()?.includes(keyword.toLowerCase())) &&
            _isActiveUserGroup.includes(_user._id)
        );
      }
      return _users?.filter(
        (_user) =>
          _user.name?.toLowerCase()?.includes(keyword.toLowerCase()) ||
          _user.email?.toLowerCase()?.includes(keyword.toLowerCase()) ||
          _user.phone?.toLowerCase()?.includes(keyword.toLowerCase())
      );
    }
    if (!oldUsers) {
      return _users?.filter(
        (_user) => _user?._id && _isActiveUserGroup.includes(_user._id)
      );
    }
    return _users;
  };

  const filteredUsers = getFiltered() || [];

  const goSave = async () => {
    setChat(currentChat);
    setDisable(true);
    let _message: IMessage | undefined | null;
    if (currentChat && typeof currentChat?._id !== "undefined") {
      const _chat = await chatsService.updateGroup({
        _chat: {
          _id: currentChat._id,
          avatar: currentChat.avatar?._id,
          name: currentChat.name,
          users: currentChat.users?.map((_u) => _u._id as string),
          groupsettings: currentChat.groupsettings,
        },
      });
      _message = await sendInternalMessage({
        type: InternalMessageType.EDIT_GROUP,
        message: {
          chat: currentChat,
          content: `<b>[${user?._id}]</b> alterou o nome do grupo para <b>${currentChat.name}</b>.`,
        },
      });
      if (
        typeof _message !== "undefined" &&
        _message &&
        typeof _message.chat !== "undefined"
      ) {
        dispatch(
          chatFirst({
            chat: {
              ..._message.chat,
              ..._chat,
            },
            type: "mine",
          })
        );
        dispatch(
          reselectChat({
            chat: {
              ..._chat,
              lastmessage: {
                ..._message,
                chat: {
                  ..._message.chat,
                  ..._chat,
                },
              },
              updated_at: _chat.updated_at,
              updated_by: _chat.updated_by,
            },
          })
        );
        dispatch(
          changeChat({
            chat: {
              ..._chat,
              lastmessage: {
                ..._message,
                chat: {
                  ..._message.chat,
                  ..._chat,
                },
              },
              updated_at: _chat.updated_at,
              updated_by: _chat.updated_by,
            },
          })
        );
        customToast({
          message: "Alteração salva com sucesso!",
          type: IToastType.SUCCESS,
        });
      } else {
        customToast({
          message: "Algo deu errado!",
          type: IToastType.ERROR,
        });
      }
    }
    setDisable(false);
  };

  const goDetailsUser = (_choiceUser: IUser) => {
    if (_choiceUser._id !== user?._id) {
      setChatContact(_choiceUser);
      setLastUrl(`${location.pathname}${location.hash}`);
      navigate(`${location.pathname}#infos`);
    }
  };

  const includedInGroup =
    selectedChat?.groupchat &&
    selectedChat?.groupsettings
      ?.filter((_gs) => _gs.active === true)
      ?.map((_u) =>
        typeof _u.user?._id !== "undefined" ? _u.user?._id : _u.user
      )
      .includes(user?._id);
  const userInGroup = selectedChat?.groupsettings?.find((_gs) =>
    typeof _gs.user?._id !== "undefined"
      ? _gs.user?._id === user?._id
      : _gs.user === user?._id
  );

  const _groupHandleActions = (type: string, _choiceUser: IUser) => {
    const _updateChat = async (
      _groupsettings: ChatAdmin[] | undefined,
      _message?: IMessage | undefined | null
    ) => {
      if (
        typeof selectedChat?._id !== "undefined" &&
        typeof _groupsettings !== "undefined"
      ) {
        const _chat = await chatsService.updateGroup({
          _chat: {
            _id: selectedChat?._id,
            avatar: selectedChat.avatar?._id,
            name: selectedChat.name,
            users: selectedChat.users
              ?.filter((_user) => _user?._id !== _choiceUser?._id)
              .map((_u) => _u._id as string),
            groupsettings: _groupsettings,
          },
        });

        setRemoveUsersModal(false);
        setAddUsersModal(false);
        if (typeof _chat !== "undefined" && _chat !== null) {
          if (typeof _message !== "undefined" && _message) {
            const _newMsg: IMessage = {
              ..._message,
              chat: _chat,
            };
            dispatch(
              chatFirst({
                chat: {
                  ..._chat,
                  lastmessage: _newMsg,
                  updated_at: _chat.updated_at,
                  updated_by: _chat.updated_by,
                },
                type: "mine",
              })
            );
            dispatch(
              reselectChat({
                chat: {
                  ..._chat,
                  lastmessage: _newMsg,
                  updated_at: _chat.updated_at,
                  updated_by: _chat.updated_by,
                },
              })
            );
            dispatch(
              changeChat({
                chat: {
                  ..._chat,
                  lastmessage: _newMsg,
                  updated_at: _chat.updated_at,
                  updated_by: _chat.updated_by,
                },
              })
            );
          } else {
            customToast({
              message: "Alteração salva com sucesso!",
              type: IToastType.SUCCESS,
            });
            return _chat;
          }
          customToast({
            message: "Alteração salva com sucesso!",
            type: IToastType.SUCCESS,
          });
        } else {
          customToast({
            message: "Algo deu errado!",
            type: IToastType.ERROR,
          });
        }
        return _chat;
      }
      return null;
    };

    if (selectedChat?.created_by === _choiceUser?._id) {
      customToast({
        message: "O criador do grupo deve ser um administrador do grupo.",
        type: IToastType.WARNING,
      });
    } else {
      const _removeGroup = selectedChat?.groupsettings?.filter((_g) =>
        typeof _g.user?._id !== "undefined"
          ? _g.user?._id !== _choiceUser._id
          : _g.user !== _choiceUser._id
      );

      let _message: IMessage | undefined | null;
      selectedChat?.groupsettings?.forEach(async (_group) => {
        if (
          typeof _group.user?._id !== "undefined"
            ? _group.user?._id === _choiceUser._id
            : _group.user === _choiceUser._id
        ) {
          switch (type) {
            case "admin":
              if (_group.role?.includes(ChatGroupRole.ADMIN)) {
                const _pushGroup = {
                  ..._group,
                  role: [ChatGroupRole.READER],
                };
                _removeGroup?.push(_pushGroup);
                _message = await sendInternalMessage({
                  type: InternalMessageType.REMOVE_ADM_GROUP,
                  message: {
                    chat: selectedChat,
                    content: `<b>[${user?._id}]</b> removeu <b>[${_choiceUser._id}]</b> da lista de administradores.`,
                  },
                });
              } else {
                const _pushGroup = {
                  ..._group,
                  role: [ChatGroupRole.ADMIN],
                };
                _removeGroup?.push(_pushGroup);
                _message = await sendInternalMessage({
                  type: InternalMessageType.ADD_ADM_GROUP,
                  message: {
                    chat: selectedChat,
                    content: `<b>[${user?._id}]</b> adicionou <b>[${_choiceUser._id}]</b> à lista de administradores.`,
                  },
                });
              }
              await _updateChat(_removeGroup, _message);
              break;
            case "delete":
              const _pushDeletedGroup = {
                ..._group,
                active: false,
                role: [ChatGroupRole.READER],
              };
              _removeGroup?.push(_pushDeletedGroup);
              _message = await sendInternalMessage({
                type: InternalMessageType.REMOVE_FROM_GROUP,
                message: {
                  chat: selectedChat,
                  content: `<b>[${user?._id}]</b> removeu <b>[${_choiceUser._id}]</b>.`,
                },
              });
              await _updateChat(_removeGroup, _message);
              break;
            case "add":
              const _pushAddedGroup = {
                ..._group,
                active: true,
                role: [ChatGroupRole.READER],
              };
              _removeGroup?.push(_pushAddedGroup as ChatAdmin);
              // eslint-disable-next-line no-case-declarations
              const _chat = await _updateChat(_removeGroup);
              _message = await sendInternalMessage({
                type: InternalMessageType.ADD_TO_GROUP,
                message: {
                  chat: _chat,
                  content: `<b>[${user?._id}]</b> adicionou <b>[${_choiceUser._id}]</b>.`,
                },
              });
              if (typeof _message !== "undefined" && _message) {
                dispatch(
                  chatFirst({
                    chat: {
                      ..._chat,
                      lastmessage: {
                        ..._message,
                        chat: {
                          ..._message.chat,
                          ..._chat,
                        },
                      },
                      updated_at: _chat.updated_at,
                      updated_by: _chat.updated_by,
                    },
                    type: "mine",
                  })
                );
                dispatch(
                  reselectChat({
                    chat: {
                      ..._chat,
                      lastmessage: {
                        ..._message,
                        chat: {
                          ..._message.chat,
                          ..._chat,
                        },
                      },
                      updated_at: _chat.updated_at,
                      updated_by: _chat.updated_by,
                    },
                  })
                );
                dispatch(
                  changeChat({
                    chat: {
                      ..._chat,
                      lastmessage: {
                        ..._message,
                        chat: {
                          ..._message.chat,
                          ..._chat,
                        },
                      },
                      updated_at: _chat.updated_at,
                      updated_by: _chat.updated_by,
                    },
                  })
                );
              }
              break;
            default:
              break;
          }
        }
      });
    }
  };

  return (
    <div className="flex-container p-3">
      <div className="header">
        <Header
          icon="las la-users"
          title={
            !selectedChat?.groupchat ||
            (selectedChat?.groupchat && includedInGroup)
              ? selectedChat?.name || ""
              : userInGroup?.chatname || ""
          }
          setVisible={setVisible}
        />
      </div>
      <div id="sidemenu" className="px-4 content overflow-y-scroll">
        <SectionElement
          instance={selectedChat}
          setChat={setChat}
          type="groupchat-info"
        />
        <PopupComponent
          className="flex items-center mt-4 mb-1"
          label="Participantes"
          content={
            <h1 className="font-semibold text-[12px]">
              Apenas os adminstradores do grupo podem gerenciar os participantes
            </h1>
          }
        />
        {typeof selectedChat?.groupsettings?.find(
          (_gs) => _gs.active === false
        ) !== "undefined" ? (
          <Toggle
            containerClass="flex w-full my-2"
            defaultChecked={oldUsers}
            readOnly={false}
            mainClass=""
            labelClass="ml-4 mb-2"
            onChange={(_, { checked }) => {
              setOldUsers(checked as boolean);
            }}
            label="Exibir participantes anteriores"
          />
        ) : null}
        <SearchBox
          _escFunction={() => {
            setKeyword("");
          }}
          listFilter={(e: React.ChangeEvent<HTMLInputElement>) => {
            setKeyword(e.target.value);
          }}
          keyword={keyword}
          extraClass="w-full my-2"
          extraClassInner="rounded-md"
          placeholder="Pesquisar participantes..."
        />
        {filteredUsers.length > 0 ? (
          // eslint-disable-next-line jsx-a11y/label-has-associated-control
          <label className="text-[12px] p-1">
            {filteredUsers.length}{" "}
            {filteredUsers.length === 1
              ? `participante ${keyword.length > 0 ? "encontrado" : ""}`
              : `participantes ${keyword.length > 0 ? "encontrados" : ""}`}
          </label>
        ) : null}
        {typeof selectedChat?.users !== "undefined" &&
        filteredUsers.length > 0 ? (
          <ul
            className={`pr-[2px] relative overflow-x-hidden ${
              window.innerWidth > 480 ? "max-w-[420px]" : "max-w-[395px]"
            }`}
          >
            {[...filteredUsers]
              ?.sort((a: IUser, b: IUser) => {
                if (
                  typeof a?.name !== "undefined" &&
                  typeof b?.name !== "undefined"
                ) {
                  return a.name.localeCompare(b.name);
                }
                return 0;
              })
              ?.map((_user: IUser) => {
                const _isAdminCheck = selectedChat.groupsettings
                  ?.filter((_groupuser) =>
                    typeof _groupuser.user?._id !== "undefined"
                      ? _groupuser.user?._id === _user._id
                      : _groupuser.user === _user._id
                  )[0]
                  ?.role?.includes(ChatGroupRole.ADMIN);
                const _isRemovedCheck =
                  selectedChat?.groupsettings?.filter((_groupuser) =>
                    typeof _groupuser.user?._id !== "undefined"
                      ? _groupuser.user?._id === _user._id
                      : _groupuser.user === _user._id
                  )[0]?.active === false;

                return (
                  <li
                    className="border rounded-md mb-2 relative"
                    aria-hidden
                    key={_user?._id}
                    // onClick={() => goDetailsUser(_user)}
                  >
                    <UserCard
                      item={_user}
                      admin={_isAdminCheck}
                      removed={_isRemovedCheck}
                      sessionAdmin={_isSessionAdminCheck}
                      groupShowDetailsUser={() => goDetailsUser(_user)}
                      groupHandleAdmin={() => {
                        _groupHandleActions("admin", _user);
                      }}
                      groupDeleteUser={() => {
                        setRemoveUsersModal(true);
                        setRemoveUser(_user);
                      }}
                      groupAddUser={() => {
                        setAddUsersModal(true);
                        setAddUser(_user);
                      }}
                    />
                    {removeUsersModal ? (
                      <ConfirmationModal
                        disabled={false}
                        action={() => {
                          if (typeof removeUser?._id !== "undefined") {
                            _groupHandleActions("delete", removeUser);
                          }
                        }}
                        setShowModal={setRemoveUsersModal}
                        title="Deseja remover este participante?"
                      />
                    ) : null}
                    {addUsersModal ? (
                      <ConfirmationModal
                        disabled={false}
                        action={() => {
                          if (typeof addUser?._id !== "undefined") {
                            _groupHandleActions("add", addUser);
                          }
                        }}
                        setShowModal={setAddUsersModal}
                        title="Deseja adicionar este participante?"
                      />
                    ) : null}
                  </li>
                );
              })}
          </ul>
        ) : (
          <div className="flex relative mt-[20px]">
            <h2 className="my-0 mx-auto mt-[50px]">
              Nenhum resultado encontrado
            </h2>
          </div>
        )}
        {_isSessionAdminCheck ? (
          <>
            <Button
              minWidth
              width="w-full"
              extraClass="my-2"
              inverted
              icon="las la-user-cog"
              label="Gerenciar participantes"
              onClick={() => {
                setShowModal(true);
                setBlockSidebar(true);
              }}
            />
            <Button
              minWidth
              width="w-full"
              inverted
              red
              icon="las la-minus-circle"
              label="Excluir grupo"
              onClick={() => {
                setShowDeleteModal(true);
                setBlockSidebar(true);
              }}
            />
          </>
        ) : null}
      </div>
      <div className="footer mx-4">
        <ActionButtonsModal
          sidebar
          disabled={
            !_isSessionAdminCheck ||
            selectedChat?.name === currentChat?.name ||
            disable ||
            false
          }
          cancelAction={goBack}
          submitIcon="las la-save"
          submitLabel={t("formbutton.label.save") || ""}
          submitAction={goSave}
        />
      </div>
    </div>
  );
};
export default ChatGroupDetails;
