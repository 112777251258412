// import React, { useEffect } from "react";
import React from "react";
import {
  LoginAgentForm,
  LoginAgideskForm,
  LoginContactForm,
  LoginForm,
} from "../features/auth/components/Forms";
import AuthLayoutPage from "./AuthLayout.page";
// import { useAppDispatch, useAppSelector } from "../hooks/redux/hooks";
import { useAppSelector } from "../hooks/redux/hooks";
import { UserType } from "../slices/users.slice";
// import { signin } from "../slices/auth.slice";
import LoadingDots from "../shared/components/LoadingDots.component";

const LoginPage = ({
  widgetType,
  isOffline,
  setIsOffline,
}: {
  widgetType?: UserType;
  isOffline?: boolean;
  setIsOffline?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { isLoadingAuth } = useAppSelector((state) => state.auth);
  const { isSuccess } = useAppSelector((state) => state.users);
  const { selectedLoginMethod, isAuthenticated } = useAppSelector(
    (state) => state.auth
  );

  const getTitlePage = () => {
    if (selectedLoginMethod)
      return (
        selectedLoginMethod?.fields?.displaytitlelogin ||
        selectedLoginMethod?.fields?.name
      );
    if (
      (!isLoadingAuth && widgetType !== UserType.CONTACT) ||
      (!isLoadingAuth && !isSuccess && widgetType === UserType.CONTACT)
    )
      return "Olá, seja bem-vindo!";
    return "Aguarde um instante!";
  };

  const getContent = () => {
    if (
      (widgetType === UserType.CONTACT && isAuthenticated) ||
      (widgetType !== UserType.CONTACT && isLoadingAuth)
    ) {
      return <LoadingDots className="flex justify-center items-center" />;
    }
    if (widgetType === UserType.CONTACT) {
      return (
        <LoginContactForm
          widgetType={widgetType}
          isOffline={isOffline}
          setIsOffline={setIsOffline}
        />
      );
    }
    if (!selectedLoginMethod) {
      if (widgetType === UserType.AGENT) {
        return <LoginAgentForm widgetType={widgetType} />;
      }
      return <LoginForm widgetType={widgetType} />;
    }
    return <LoginAgideskForm widgetType={widgetType} />;
  };

  return (
    <AuthLayoutPage widgetType={widgetType}>
      <h2 className="mb-2 text-center text-[20px] font-semibold">
        {getTitlePage()}
      </h2>
      {getContent()}
    </AuthLayoutPage>
  );
};

LoginPage.defaultProps = {
  widgetType: UserType.NONE,
  isOffline: false,
  setIsOffline: undefined,
};

export default LoginPage;
