import { externalappsService } from "../../services";
import { IChat } from "../../slices/chats.slice";
import populateTeamToChat from "./populateTeamToChat";

const populateExternalAppToChat = async ({ chat }: { chat: IChat }) => {
  let _chat = await populateTeamToChat({
    chat,
  });
  if (
    _chat !== null &&
    typeof _chat?.externalapp !== "undefined" &&
    typeof _chat?.externalapp?.type === "undefined"
  ) {
    const _externalapp = await externalappsService.externalapps({
      _id:
        typeof _chat.externalapp === "string"
          ? _chat.externalapp
          : _chat?.externalapp?._id,
    });
    if (_externalapp !== null && typeof _externalapp !== "undefined") {
      _chat = {
        ..._chat,
        externalapp: _externalapp,
      };
    }
    return _chat;
  }
  return _chat;
};

export default populateExternalAppToChat;
