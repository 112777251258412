import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import bgvideo from "../assets/videos/share.mp4";
import { UserType } from "../slices/users.slice";
import { useAppSelector } from "../hooks/redux/hooks";

const PublicRoute = ({ widgetType }: { widgetType?: UserType }) => {
  const { isAuthenticated, user } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (
      isAuthenticated &&
      user &&
      !location.pathname.includes("/privacy") &&
      !location.pathname.includes("/schedule") &&
      !location.pathname.includes("/surveyrating")
    ) {
      navigate("/");
    }
  }, []);

  return !widgetType || (widgetType && widgetType.length === 0) ? (
    <div className="flex justify-center items-center relative h-full w-full bg-black-alphaB3 overflow-y-auto">
      <video
        src={bgvideo}
        loop
        controls={false}
        muted
        autoPlay
        className="w-full h-full fixed top-0 left-0 object-cover"
      />
      <Outlet />
    </div>
  ) : (
    <Outlet />
  );
};

PublicRoute.defaultProps = {
  widgetType: UserType.NONE,
};

export default PublicRoute;
