import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/redux/hooks";
import useGetIdPage from "../hooks/useGetIdPage";
import Section from "../shared/components/Section.component";
import useCampaigns from "../hooks/features/useCampaigns";
import DividerInfoPages from "../shared/components/DividerInfoPages.component";
import DefaultPage from "./Default.page";
import {
  reselectCampaign,
  reselectLocalCampaign,
  selectCampaign,
} from "../slices/campaigns.slice";
import {
  Chat,
  Infos,
  Target,
  Template,
  Email,
} from "../features/campaigns/components/section";
import { getSettings } from "../slices/settings.slice";
import { selectTemplate } from "../slices/messages.slice";

const CampaignPage = () => {
  const { getId } = useGetIdPage();
  const { sectionDetails, template } = useCampaigns();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { isLoadingCampaigns, selectedLocalCampaign } = useAppSelector(
    (state) => state.campaigns
  );

  useEffect(() => {
    dispatch(reselectCampaign(null));
    dispatch(reselectLocalCampaign(null));
    dispatch(selectTemplate({ template: null }));
    const _id = getId();
    if (location.pathname === `/settings/campaign/${_id}`) {
      (async () => {
        await dispatch(getSettings({}));
        const { payload } = await dispatch(selectCampaign({ _id }));
        if (typeof payload?._id !== "undefined") {
          if (
            typeof payload?.template !== "undefined" &&
            template.templates.length > 0
          ) {
            const _selected =
              template.templates.find(
                (_temp) => _temp.id === payload?.template
              ) || null;
            dispatch(
              selectTemplate({
                template: _selected,
                campaignMode: true,
                campaignVars: payload?.templatevars,
              })
            );
          }
          dispatch(reselectLocalCampaign(payload));
        }
      })();
    }
  }, []);

  return (
    <DefaultPage
      isLoading={isLoadingCampaigns || selectedLocalCampaign === null}
      content={
        <>
          <Section
            id={sectionDetails.infos.id}
            icon={sectionDetails.infos.icon}
            title={sectionDetails.infos.title}
            description={sectionDetails.infos.description}
            component={<Infos />}
          />
          <DividerInfoPages />
          <Section
            id={sectionDetails.template.id}
            icon={sectionDetails.template.icon}
            title={sectionDetails.template.title}
            description={sectionDetails.template.description}
            component={<Template />}
          />
          <DividerInfoPages />
          <Section
            id={sectionDetails.team.id}
            icon={sectionDetails.team.icon}
            title={sectionDetails.team.title}
            description={sectionDetails.team.description}
            component={<Chat />}
          />
          <DividerInfoPages />
          <Section
            id={sectionDetails.email.id}
            icon={sectionDetails.email.icon}
            title={sectionDetails.email.title}
            description={sectionDetails.email.description}
            component={<Email />}
          />
          <DividerInfoPages />
          <Section
            id={sectionDetails.targets.id}
            icon={sectionDetails.targets.icon}
            title={sectionDetails.targets.title}
            description={sectionDetails.targets.description}
            component={<Target />}
          />
        </>
      }
    />
  );
};

export default CampaignPage;
