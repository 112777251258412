import React from "react";
import { Dropdown, Divider } from "semantic-ui-react";
import { DropdownMenuProps } from "../../../shared/models/types/DropdownMenuProps.type";
import AgideskSVG from "../../../shared/components/Agidesk/AgideskSVG.icon";

const DropdownMenu = ({
  icon,
  items,
  top,
  id,
}: DropdownMenuProps): JSX.Element => {
  return (
    <Dropdown
      id={id}
      key={id}
      direction={top ? "right" : "left"}
      text=""
      icon={icon}
    >
      <Dropdown.Menu id={id} key={id}>
        {items &&
          items.map((item) => (
            <React.Fragment key={item._id}>
              {item.content && <Dropdown.Header content={item.content} />}
              {!item.icon ? (
                <div
                  role="presentation"
                  className="flex items-center p-2 hover:bg-gray-100 cursor-pointer"
                  onClick={item.onClick}
                >
                  <div className="ml-2">
                    <AgideskSVG />
                  </div>
                  <Dropdown.Item
                    className="ml-3 hover:bg-gray-100"
                    text={item.text}
                    description={item.description}
                  />
                </div>
              ) : (
                <Dropdown.Item
                  className="flex items-center pt-2 px-2 cursor-pointer hover:bg-gray-100"
                  icon={item.icon}
                  text={item.text}
                  description={item.description}
                  onClick={item.onClick}
                />
              )}

              {item.hasDiv && <Divider />}
            </React.Fragment>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownMenu;
