/* eslint-disable react/no-children-prop */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-case-declarations */
/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from "react";
import { DropdownSearchInputProps, Form } from "semantic-ui-react";
import { useLocation, useNavigate } from "react-router-dom";
import ReactInputMask from "react-input-mask";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux/hooks";
import {
  ICustomer,
  reselectCustomer,
  updateCustomer,
  updateCustomerContacts,
} from "../../../slices/customers.slice";
import {
  ChatRoutingType,
  ITeam,
  reselectTeam,
  updateTeam,
  updateTeamUsers,
} from "../../../slices/teams.slice";
import {
  IUser,
  UserType,
  selectUser,
  updateUser,
} from "../../../slices/users.slice";
import instances from "../../../services/instances";
import ActionButtonsModal from "./ActionButtonsModal.component";
import { update, userdata } from "../../../slices/auth.slice";
import { cellObject } from "../Tables/TableBody.component";
import { getSettings, updateSettings } from "../../../slices/settings.slice";
import {
  ExampleComponent,
  IMessage,
  INewMessage,
  INewMessageExternalappWpp,
  ITemplate,
  ITemplateVariables,
  InternalMessageType,
  MessageType,
  addMessage,
  createMessage,
  getExternalappTemplatesWpp,
  selectTemplate,
} from "../../../slices/messages.slice";
import {
  Preview,
  WhatsAppTemplate,
} from "../../../features/messages/components/WhatsApp";
import {
  ChatAdmin,
  ChatGroupRole,
  ChatStatus,
  ChatType,
  IChat,
  changeChat,
  createChat,
  reselectChat,
  selectChat,
} from "../../../slices/chats.slice";
import {
  IAgideskTeam,
  IAgideskUser,
  agideskImport,
  selectExternalapp,
  updateExternalapps,
} from "../../../slices/externalapps.slice";
import LinkButton from "../Buttons/LinkButton.component";
import UserAdmin from "../Agidesk/Import/UserAdmin.component";
import findInObject from "../../utils/findInObject";
import { ddOptions } from "../../models/interfaces/dropdownOptions.interface";
import ErrorLabel from "../../../features/auth/components/Forms/ErrorLabel.component";
import Dropdown from "../Dropdown.component";
import {
  authService,
  chatsService,
  commenttemplatesService,
  customersService,
  externalappsService,
  messagesService,
  tagsService,
  teamsService,
  usersService,
  whatsappService,
} from "../../../services";
import removeDuplicates from "../../utils/removeDuplicates";
import LoadingDots from "../LoadingDots.component";
import {
  SettingOfficehours,
  reselectOfficehour,
  selectOfficehour,
  updateOfficehour,
} from "../../../slices/officehours.slice";
import { selectHoliday, updateHoliday } from "../../../slices/holidays.slice";
import parseBlurTime from "../../utils/parseBlurTime";
import { ActiveDropdown, WeekdaysDropdown } from "../Dropdowns";
import PopupComponent from "../Popup.component";
import useSendInternalMessage from "../../../hooks/useSendInternalMessage";
import customToast from "../../utils/customToast";
import { IToastType } from "../../models/types/Toast.type";
import { selectSurvey, updateSurvey } from "../../../slices/surveys.slice";
import useUserIsAdmin from "../../../hooks/useUserIsAdmin";
import { IAcceptedFiles } from "../../models/interfaces/acceptedfile.interface";
import { Files } from "../../models/interfaces/file.interface";
import {
  ICommenttemplate,
  selectCommenttemplate,
  updateCommenttemplate,
} from "../../../slices/commenttemplates.slice";
import {
  ITag,
  TagType,
  selectTag,
  updateTag,
} from "../../../slices/tags.slice";
import useReplaceVarsText from "../../../hooks/useReplaceVarsText";

const RelationModal = ({
  setShowModal,
  setBlockSidebar,
  setShow,
  contactChat,
  title,
  icon,
  selectedRow,
  importList,
  setImportList,
  setShowImport,
  validateOlderWhatsAppChats,
  widgetType,
  sendCommenttemplate,
}: {
  title: string;
  icon: string;
  validateOlderWhatsAppChats?: ({
    _user,
    _externalapp,
  }: {
    _user: IUser | undefined;
    _externalapp?: string;
  }) => Promise<boolean | undefined>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRow?: cellObject;
  contactChat?: IUser;
  setShow?: React.Dispatch<React.SetStateAction<boolean>>;
  setBlockSidebar?: React.Dispatch<React.SetStateAction<boolean>>;
  importList?: IAgideskTeam[];
  setImportList?: React.Dispatch<React.SetStateAction<IAgideskTeam[]>>;
  setShowImport?: React.Dispatch<React.SetStateAction<boolean>>;
  widgetType?: UserType;
  sendCommenttemplate?: (
    commenttemplate?: string,
    _content?: string,
    _filesId?: string[],
    _filesExternalapps?: IAcceptedFiles
  ) => Promise<void>;
}) => {
  const { isAdmin } = useUserIsAdmin();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { sendInternalMessage } = useSendInternalMessage();
  const { replaceVarsContent } = useReplaceVarsText();

  const { selectedUser, selectedContact } = useAppSelector(
    (state) => state.users
  );
  const { user } = useAppSelector((state) => state.auth);
  const { recentChats, selectedChat } = useAppSelector((state) => state.chats);
  const { selectedTeam } = useAppSelector((state) => state.teams);
  const { selectedCustomer } = useAppSelector((state) => state.customers);
  const { selectedOfficehour } = useAppSelector((state) => state.officehours);
  const { selectedHoliday } = useAppSelector((state) => state.holidays);
  const { selectedSettings } = useAppSelector((state) => state.settings);
  const { selectedSurvey } = useAppSelector((state) => state.surveys);
  const { selectedCommenttemplate } = useAppSelector(
    (state) => state.commenttemplates
  );
  const { selectedTag } = useAppSelector((state) => state.tags);
  const {
    selectedExternalAppWhatsApp,
    selectedExternalAppAgidesk,
    selectedExternalAppBotmaker,
    externalAppWhatsApp,
    externalAppBotmaker,
  } = useAppSelector((state) => state.externalapps);

  const getDefaultValueNumber = () => {
    if (
      typeof user?.profile?.externalapp !== "undefined" &&
      user?.profile?.externalapp !== ""
    ) {
      return user?.profile?.externalapp;
    }
    if (
      externalAppWhatsApp.length === 1 &&
      typeof externalAppWhatsApp[0]?._id !== "undefined" &&
      externalAppWhatsApp[0]._id
    ) {
      return externalAppWhatsApp[0]._id;
    }
    if (
      externalAppBotmaker.length === 1 &&
      typeof externalAppBotmaker[0]?._id !== "undefined" &&
      externalAppBotmaker[0]._id
    ) {
      return externalAppBotmaker[0]._id;
    }
    return "";
  };

  const [results, setResults] = useState<any[]>([]);
  const [instanceOptions, setInstanceOptions] = useState<ddOptions[]>([]);
  const [optionsTemplates, setOptionsTemplates] = useState<ddOptions[]>([]);
  const [optionsCommentTemplates, setOptionsCommentTemplates] = useState<
    ddOptions[]
  >([]);
  const [optionsTeams, setOptionsTeams] = useState<ddOptions[]>([]);
  const [defaultValueNumber, setDefaultValueNumber] = useState<string>(
    getDefaultValueNumber()
  );
  const [defaultValueTemplate, setDefaultValueTemplate] = useState<string>("");
  const [defaultValueTeam, setDefaultValueTeam] = useState<string>(
    optionsTeams.length === 1 && typeof optionsTeams[0].value !== "undefined"
      ? optionsTeams[0].value
      : ""
  );

  const [focusId, setFocusId] = useState<string>("");
  const [disable, setDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingDropdown, setIsLoadingDropdown] = useState(false);
  const [files, setFiles] = useState<IAcceptedFiles>([]);
  const [templateButtons, setTemplateButtons] = useState({
    url: "",
    code: "",
  });

  const [_commenttemplates, setCommenttemplates] = useState<ICommenttemplate[]>(
    []
  );
  const [_commenttemplateId, setCommenttemplate] = useState<string>("");

  const {
    whatsappTemplates,
    selectedTemplate,
    selectedTemplateParameters,
    selectedTemplateMessage,
  } = useAppSelector((state) => state.messages);

  useEffect(() => {
    const _results = [];
    const handleExternalApp =
      location.pathname.includes("whatsapp") ||
      location.pathname.includes("agibot");
    const selectedExternalAppObj = location.pathname.includes("agibot")
      ? selectedExternalAppBotmaker
      : selectedExternalAppWhatsApp;
    if (importList && icon === "las la-users-cog") {
      for (const _team of importList) {
        _results.push(_team);
      }
    } else if (
      typeof selectedExternalAppObj !== "undefined" &&
      selectedExternalAppObj?.fields?.defaultteam &&
      selectedExternalAppObj?.fields?.defaultteam.length > 0 &&
      handleExternalApp
    ) {
      for (const _team of selectedExternalAppObj.fields.defaultteam) {
        _results.push(_team?._id || "");
      }
    } else if (selectedRow) {
      _results.push(selectedRow as cellObject);
    } else if (
      !selectedRow &&
      selectedSettings?.defaultteam &&
      selectedSettings?.defaultteam.length > 0 &&
      icon === "las la-user-friends" &&
      location.pathname.includes("general")
    ) {
      for (const _team of selectedSettings.defaultteam) {
        _results.push(_team?._id || "");
      }
    } else if (
      selectedTeam?.users &&
      selectedTeam?.users.length > 0 &&
      location.pathname.includes("team")
    ) {
      for (const _user of selectedTeam.users) {
        _results.push(_user?._id || "");
      }
    } else if (
      selectedCustomer?.contacts &&
      selectedCustomer?.contacts.length > 0 &&
      location.pathname.includes("customer")
    ) {
      for (const _contact of selectedCustomer.contacts) {
        _results.push(_contact?._id || "");
      }
    } else if (
      selectedChat?.users &&
      selectedChat?.users.length > 0 &&
      icon === "las la-user-cog" &&
      location.hash.includes("group")
    ) {
      const _isActiveUserGroup: string[] = [];
      selectedChat?.groupsettings?.forEach((_groupuser: ChatAdmin) => {
        if (_groupuser.active === true)
          _isActiveUserGroup.push(
            typeof _groupuser.user?._id !== "undefined"
              ? (_groupuser.user?._id as string)
              : (_groupuser.user as any)
          );
      });
      for (const _user of selectedChat.users) {
        if (
          typeof _user?._id !== "undefined" &&
          _user?._id !== selectedChat?.created_by &&
          _user?._id !== user?._id &&
          _isActiveUserGroup.includes(_user._id)
        ) {
          _results.push(_user?._id || "");
        }
      }
    } else if (
      selectedOfficehour?.teams &&
      selectedOfficehour?.teams.length > 0 &&
      location.pathname.includes("officehour")
    ) {
      for (const _team of selectedOfficehour.teams) {
        _results.push(_team?._id || "");
      }
    } else if (
      selectedHoliday?.teams &&
      selectedHoliday?.teams.length > 0 &&
      location.pathname.includes("holiday")
    ) {
      for (const _team of selectedHoliday.teams) {
        _results.push(_team?._id || "");
      }
    } else if (
      selectedSurvey?.teams &&
      selectedSurvey?.teams.length > 0 &&
      location.pathname.includes("survey")
    ) {
      for (const _team of selectedSurvey.teams) {
        _results.push(_team?._id || "");
      }
    } else if (
      selectedCommenttemplate?.teams &&
      selectedCommenttemplate?.teams.length > 0 &&
      location.pathname.includes("commenttemplate")
    ) {
      for (const _team of selectedCommenttemplate.teams) {
        _results.push(_team?._id || "");
      }
    } else if (
      selectedTag?.teams &&
      selectedTag?.teams.length > 0 &&
      location.pathname.includes("tag")
    ) {
      for (const _team of selectedTag.teams) {
        _results.push((_team as ITeam)?._id || "");
      }
    } else if (
      selectedContact?.customers &&
      selectedContact?.customers.length > 0 &&
      location.pathname.includes("contact")
    ) {
      for (const _customer of selectedContact.customers) {
        _results.push(_customer?._id || "");
      }
    } else if (
      selectedUser?.teams &&
      selectedUser?.teams.length > 0 &&
      location.pathname.includes("user")
    ) {
      for (const _team of selectedUser.teams) {
        _results.push(_team?._id || "");
      }
    } else if (user?.teams && location.pathname.includes("profile")) {
      for (const _team of user.teams) {
        _results.push(_team?._id || "");
      }
    }
    //  else if (user?.customers) {
    //   for (const _customer of user.customers) {
    //     _results.push(_customer?._id || "");
    //   }
    // }
    setResults(_results);
  }, []);

  useEffect(() => {
    if (icon === "lab la-whatsapp") {
      setOptionsTeams([]);
      setDefaultValueTeam("");
      const _optionsTeams: ddOptions[] = [];
      (async () => {
        if (
          (typeof user?.profile?.externalapp === "undefined" ||
            user?.profile?.externalapp === "") &&
          defaultValueNumber !== ""
        ) {
          await usersService.profile({
            externalapp: defaultValueNumber,
            defaulttemplate: "",
          });
        }
        const { payload } = await dispatch(
          selectExternalapp({
            _id: defaultValueNumber,
          })
        );
        payload?.fields?.defaultteam?.forEach(
          (_team: { _id: string; name: string }) => {
            if (
              typeof findInObject(user?.teams, "_id", _team._id) !== "undefined"
            ) {
              _optionsTeams.push({
                key: _team._id,
                text: _team.name,
                value: _team._id,
              });
            }
          }
        );
        setOptionsTeams(_optionsTeams);
        if (
          _optionsTeams.length === 1 &&
          typeof _optionsTeams[0].value !== "undefined"
        ) {
          // console.log(_optionsTeams[0].value);
          setDefaultValueTeam(_optionsTeams[0].value);
        }
        if (_optionsTeams.length > 0) {
          setIsLoading(false);
        }
      })();
    }
  }, [defaultValueNumber]);

  useEffect(() => {
    if (icon === "las la-comment") {
      setOptionsTeams([]);
      setDefaultValueTeam("");
      const _optionsTeams: ddOptions[] = [];
      user?.teams?.forEach((_team) => {
        if (_team.active)
          _optionsTeams.push({
            key: _team._id,
            text: _team.name,
            value: _team._id,
          });
      });
      setOptionsTeams(_optionsTeams);
      if (
        _optionsTeams.length === 1 &&
        typeof _optionsTeams[0].value !== "undefined"
      ) {
        // console.log(_optionsTeams[0].value);
        setDefaultValueTeam(_optionsTeams[0].value);
      }
      if (_optionsTeams.length > 0) {
        setIsLoading(false);
      }
    }
  }, []);

  const adminGetTeams = async (e?: DropdownSearchInputProps) => {
    const _value = (e?.target?.value as string) ?? "";
    setIsLoadingDropdown(true);
    const payload = await teamsService.search({
      skip: 0,
      limit: 30,
      filter: _value,
      deleted: false,
    });
    setIsLoadingDropdown(false);
    const _optionsTeams: ddOptions[] = optionsTeams;
    payload.results.map((_team: ITeam) => {
      if (_optionsTeams.filter((_opTeam) => _opTeam.key === _team._id)[0]) {
        return false;
      }
      _optionsTeams.push({
        key: _team._id,
        text: _team.name,
        value: _team._id,
      });
      return true;
    });
    setOptionsTeams(_optionsTeams);
  };

  const updatingTeam = async (id: string) => {
    if (selectedTeam) {
      const _users = await instances.instanceUsers.get(`/team/${id}`);
      if (selectedTeam.chatusers) {
        const _chatusers: string[] = [];
        for (const _chatuser of selectedTeam.chatusers) {
          _chatusers.push(_chatuser.user);
        }
        const intersect = _users.data.filter((_user: IUser) =>
          _chatusers.some((_cuser: string) => _user._id === _cuser)
        );
        const _newChatUsers = intersect.map((item: IUser) => {
          return {
            user: item._id,
          };
        });
        const _team = {
          _id: selectedTeam?._id,
          active: selectedTeam?.active,
          assignedbacklog: selectedTeam?.assignedbacklog,
          assignedtype: selectedTeam?.assignedtype,
          backlogsize: selectedTeam?.backlogsize,
          chatlimit: selectedTeam?.chatlimit,
          chatlimitsize: selectedTeam?.chatlimitsize,
          chatusers: _newChatUsers,
          name: selectedTeam?.name,
          routingtype: ChatRoutingType.ROUNDROBIN,
          officehour: selectedTeam?.officehour?._id,
          survey: selectedTeam?.survey?._id,
          // routingtype: selectedTeam?.routingtype,
        };
        const { payload } = await dispatch(
          updateTeam({ _team, noToast: true })
        );
        dispatch(
          reselectTeam({
            ...selectedTeam,
            users: _users.data,
            chatusers: _newChatUsers,
            updated_at: payload.updated_at,
            updated_by: payload.updated_by,
          })
        );
      }
    }
  };

  const updatingCustomer = async (id: string) => {
    if (selectedCustomer) {
      const _customerContacts = await instances.instanceUsers.get(
        `/customer/${id}`
      );
      const _customer = {
        _id: selectedCustomer?._id,
        name: selectedCustomer?.name,
        active: selectedCustomer?.active,
        fullname: selectedCustomer?.fullname,
        code: selectedCustomer?.code,
        reference: selectedCustomer?.reference,
      };
      const { payload } = await dispatch(
        updateCustomer({ _customer, noToast: true })
      );
      dispatch(
        reselectCustomer({
          ...selectedCustomer,
          contacts: _customerContacts.data,
          updated_at: payload.updated_at,
          updated_by: payload.updated_by,
        })
      );
    }
  };

  const getTypeDisable = () => {
    switch (icon) {
      case "lab la-whatsapp":
        if (typeof selectedTemplate?.components !== "undefined") {
          let buttonDisable = false;
          for (const key in Object.keys(selectedTemplate.components)) {
            if (
              Object.prototype.hasOwnProperty.call(
                selectedTemplate?.components,
                key
              )
            ) {
              const element = selectedTemplate?.components[key];
              if (
                (element.type === "button" &&
                  element.sub_type === "url" &&
                  templateButtons.url.length === 0) ||
                (element.type === "button" &&
                  element.sub_type === "copy_code" &&
                  templateButtons.code.length === 0)
              ) {
                buttonDisable = true;
              }
            }
          }
          return (
            buttonDisable ||
            selectedTemplateMessage.length === 0 ||
            selectedTemplateMessage.includes("{{H-") ||
            selectedTemplateMessage.includes("{{B-") ||
            defaultValueTeam.length === 0 ||
            (typeof selectedTemplate?.components[0]?.example?.header_handle !==
              "undefined" &&
              files.length === 0)
          );
        }
        return true;
      case "las la-comment":
        return (
          typeof _commenttemplateId === "undefined" ||
          _commenttemplateId.length <= 0
        );
      case "las la-building":
      case "las la-address-book":
      case "profile-teams":
      case "profile-customers":
      case "las la-user-cog":
      case "las la-tags":
        return false;
      case "las la-calendar":
      case "las la-user-friends":
      case "las la-user":
      default:
        return (
          !isAdmin ||
          (location.pathname.includes("whatsapp") && results.length === 0)
        );
    }
  };

  const getTypeUpdate = async ({ after }: { after?: boolean }) => {
    try {
      switch (icon) {
        case "las la-comment":
          if (_commenttemplateId && _commenttemplates.length > 0) {
            const _content = _commenttemplates.find(
              (_temp) => _temp._id === _commenttemplateId
            )?.content;
            const _filesId = _commenttemplates
              .find((_temp) => _temp._id === _commenttemplateId)
              ?.files?.map((_file: any) => _file._id);
            const _filesExternalapps = _commenttemplates.find(
              (_temp) => _temp._id === _commenttemplateId
            )?.files;
            if (
              typeof _content !== "undefined" &&
              typeof sendCommenttemplate !== "undefined"
            ) {
              await sendCommenttemplate(
                _commenttemplateId,
                replaceVarsContent(_content),
                _filesId,
                _filesExternalapps as IAcceptedFiles
              );
            }
          }
          setDisable(false);
          setShowModal(false);
          break;
        case "las la-users-cog":
          if (importList) {
            const _data = results as IAgideskTeam[];
            const { payload } = await dispatch(
              agideskImport({
                data: after ? importList : _data,
                externalappid: selectedExternalAppAgidesk?._id || "",
              })
            );
            if (payload !== null && setImportList && setShowImport) {
              setImportList([]);
              navigate(location.pathname);
              setShowImport(false);
            }
          }
          setDisable(false);
          setShowModal(false);
          break;
        case "las la-calendar":
          if (selectedOfficehour) {
            const _officehours: SettingOfficehours[] | undefined =
              selectedOfficehour?.officehours;
            const _sendValue: SettingOfficehours[] = [];
            _officehours?.forEach((officehour: SettingOfficehours) => {
              if (officehour._id === results[0]._id) {
                _sendValue.push(results[0]);
              } else {
                _sendValue.push(officehour);
              }
            });
            const _officehour = {
              ...selectedOfficehour,
              _id: selectedOfficehour?._id,
              teams: selectedOfficehour?.teams?.map((_t: ITeam | string) => {
                if (typeof _t === "string") return _t;
                return _t._id;
              }),
              officehours: _sendValue,
            };
            const { payload } = await dispatch(
              updateOfficehour({ _officehour })
            );
            if (payload !== null) {
              dispatch(
                reselectOfficehour({
                  ...selectedOfficehour,
                  officehours: _sendValue,
                  updated_at: payload.updated_at,
                  updated_by: payload.updated_by,
                })
              );
            }
          }
          setDisable(false);
          setShowModal(false);
          break;

        case "las la-user-friends":
          if (location.pathname.includes("general")) {
            const _settings = {
              _id: selectedSettings?._id,
              authtypes: selectedSettings?.authtypes,
              defaultagent: selectedSettings?.defaultagent?._id,
              defaultbot: {
                name: selectedSettings?.defaultbot?.name,
                welcomemsg: selectedSettings?.defaultbot?.welcomemsg,
                unavailablemsg: selectedSettings?.defaultbot?.unavailablemsg,
                userofflinemsg: selectedSettings?.defaultbot?.userofflinemsg,
              },
              defaultteam: results,
              widget: selectedSettings?.widget,
              officehour: selectedSettings?.officehour?._id,
              isopen: selectedSettings?.isopen,
            };
            const { payload } = await dispatch(updateSettings({ _settings }));
            if (payload !== null) {
              dispatch(getSettings({}));
            }
          } else if (location.pathname.includes("officehour")) {
            const _officehour = {
              ...selectedOfficehour,
              _id: selectedOfficehour?._id,
              teams: results,
            };
            const { payload } = await dispatch(
              updateOfficehour({ _officehour })
            );
            if (typeof payload?._id !== "undefined") {
              await dispatch(
                selectOfficehour({
                  _id: payload._id,
                })
              );
            }
          } else if (location.pathname.includes("survey")) {
            if (selectedSurvey) {
              const _survey = {
                ...selectedSurvey,
                _id: selectedSurvey?._id,
                teams: results,
              };
              const { payload } = await dispatch(updateSurvey({ _survey }));
              if (typeof payload?._id !== "undefined") {
                await dispatch(
                  selectSurvey({
                    _id: payload._id,
                  })
                );
              }
            }
            setDisable(false);
            setShowModal(false);
          } else if (location.pathname.includes("commenttemplate")) {
            if (selectedCommenttemplate) {
              const _commenttemplate = {
                ...selectedCommenttemplate,
                content: selectedCommenttemplate?.content,
                _id: selectedCommenttemplate?._id,
                teams: results,
              };
              const { payload } = await dispatch(
                updateCommenttemplate({ _commenttemplate })
              );
              if (typeof payload?._id !== "undefined") {
                await dispatch(
                  selectCommenttemplate({
                    _id: payload._id,
                  })
                );
              }
            }
            setDisable(false);
            setShowModal(false);
          } else if (location.pathname.includes("tag")) {
            if (selectedTag) {
              const _tag = {
                ...selectedTag,
                _id: selectedTag?._id,
                global: false,
                teams: results,
              };
              const { payload } = await dispatch(updateTag({ _tag }));
              if (typeof payload?._id !== "undefined") {
                await dispatch(
                  selectTag({
                    _id: payload._id,
                  })
                );
              }
            }
            setDisable(false);
            setShowModal(false);
          } else if (location.pathname.includes("holiday")) {
            const _holiday = {
              ...selectedHoliday,
              _id: selectedHoliday?._id,
              teams: results,
            };
            const { payload } = await dispatch(updateHoliday({ _holiday }));
            if (typeof payload?._id !== "undefined") {
              await dispatch(
                selectHoliday({
                  _id: payload._id,
                })
              );
            }
          } else if (
            location.pathname.includes("whatsapp") ||
            location.pathname.includes("agibot")
          ) {
            const externalappObjData = location.pathname.includes("whatsapp")
              ? selectedExternalAppWhatsApp
              : selectedExternalAppBotmaker;
            if (externalappObjData) {
              await dispatch(
                updateExternalapps({
                  _externalapp: {
                    ...externalappObjData,
                    fields: {
                      ...externalappObjData.fields,
                      offlinemessage:
                        selectedExternalAppWhatsApp?.fields?.offlinemessage
                          ?._id,
                      defaultteam: results,
                    },
                  },
                })
              );
              await dispatch(
                selectExternalapp({
                  _id: externalappObjData?._id || "",
                })
              );
            }
          } else if (selectedUser) {
            await dispatch(
              updateUser({
                ...selectedUser,
                _id: selectedUser._id,
                avatar: selectedUser?.avatar?._id,
                teams: results,
                customers: selectedUser?.customers?.map((_t) => {
                  if (typeof _t === "string") return _t;
                  return _t._id;
                }),
                tags: selectedUser?.tags?.map((_t) => {
                  if (typeof _t === "string") return _t;
                  return _t._id;
                }),
              })
            );
            await dispatch(selectUser({ _id: selectedUser?._id || "" }));
          }
          setDisable(false);
          setShowModal(false);
          break;
        case "las la-building":
          if (selectedContact) {
            await dispatch(
              updateUser({
                ...selectedContact,
                _id: selectedContact._id,
                avatar: selectedContact?.avatar?._id,
                customers: results,
                teams: selectedContact?.teams?.map((_t) => {
                  if (typeof _t === "string") return _t;
                  return _t._id;
                }),
                tags: selectedContact?.tags?.map((_t) => {
                  if (typeof _t === "string") return _t;
                  return _t._id;
                }),
              })
            );
            await dispatch(
              selectUser({
                _id: selectedContact?._id || "",
                isContact: true,
              })
            );
          }
          setDisable(false);
          setShowModal(false);
          break;
        case "las la-user":
          if (selectedTeam) {
            const { payload } = await dispatch(
              updateTeamUsers({
                _team: selectedTeam?._id || "",
                users: results,
              })
            );
            if (payload._id) {
              await updatingTeam(payload._id);
            }
            setDisable(false);
            setShowModal(false);
          }
          setDisable(false);
          setShowModal(false);
          break;
        case "las la-user-cog":
          if (selectedChat) {
            const _usersWithoutCreator = [...results];
            const _users = selectedChat.users
              ? selectedChat.users.map((_u) => _u._id)
              : [];
            const _groupsettingsADDED: string[] = [];
            const _groupsettingsREACTIVED: string[] = [];
            const _groupsettingsMAINTAINED: string[] = [];
            const _groupsettingsDELETED: string[] = [];
            const _groupsettings: ChatAdmin[] = [];
            const _currentGroupsettings: ChatAdmin[] | undefined =
              selectedChat.groupsettings;

            const getNewMembers = () => {
              _usersWithoutCreator.forEach(async (_user) => {
                if (
                  typeof selectedChat?.groupsettings?.find(
                    (chat) => chat?.user?._id === _user && chat?.active === true
                  ) === "undefined"
                ) {
                  _groupsettingsADDED.push(_user);
                  _groupsettings.push({
                    active: true,
                    role: [ChatGroupRole.READER],
                    user: _user,
                    chatavatar: null,
                    chatname: null,
                  });
                } else {
                  let _updateChatAdmin: ChatAdmin | undefined =
                    _currentGroupsettings?.find((_gs) =>
                      typeof _gs.user?._id !== "undefined"
                        ? _gs.user?._id === _user
                        : _gs.user === _user
                    );
                  if (_updateChatAdmin) {
                    if (_updateChatAdmin.active === false) {
                      _updateChatAdmin = {
                        ..._updateChatAdmin,
                        active: true,
                      };
                      _groupsettingsREACTIVED.push(_user);
                    } else {
                      _groupsettingsMAINTAINED.push(_user);
                    }
                    _groupsettings.push(_updateChatAdmin);
                  }
                }
              });
            };

            const getDeletedMembers = () => {
              _currentGroupsettings?.forEach((_gs: ChatAdmin) => {
                if (typeof _gs?.user !== "undefined") {
                  const _gsUser =
                    typeof _gs.user?._id !== "undefined"
                      ? _gs.user?._id
                      : _gs.user;
                  if (
                    !_usersWithoutCreator.includes(_gsUser) &&
                    _gs.active === true &&
                    _gsUser !== selectedChat?.created_by &&
                    _gsUser !== user?._id
                  ) {
                    _groupsettingsDELETED.push(_gsUser as string);
                    _groupsettings.push({
                      active: false,
                      role: [ChatGroupRole.READER],
                      user: _gsUser as any,
                      chatavatar: selectedChat?.avatar || null,
                      chatname: selectedChat?.name || null,
                    });
                  }
                }
              });
            };

            const getResultMembers = async () => {
              let _messageDELETED: IMessage | undefined | null;
              let _messageADDED: IMessage | undefined | null;
              _currentGroupsettings?.forEach((_gs: ChatAdmin) => {
                if (typeof _gs?.user !== "undefined") {
                  const _gsUser =
                    typeof _gs.user?._id !== "undefined"
                      ? _gs.user?._id
                      : _gs.user;
                  if (
                    !_usersWithoutCreator.includes(_gsUser) &&
                    (_gs.active === false ||
                      _gsUser === selectedChat.created_by ||
                      (typeof _gs.role !== "undefined" &&
                        _gs?.role.includes(ChatGroupRole.ADMIN) &&
                        _gs.user._id === user?._id))
                  ) {
                    _groupsettings.push(_gs);
                  }
                }
              });

              const _removeAction = async () => {
                if (_groupsettingsDELETED.length > 0) {
                  for await (const _deleted of _groupsettingsDELETED) {
                    _messageDELETED = await sendInternalMessage({
                      type: InternalMessageType.REMOVE_FROM_GROUP,
                      message: {
                        chat: selectedChat,
                        content: `<b>[${user?._id}]</b> removeu <b>[${_deleted}]</b>.`,
                      },
                    });
                  }
                  if (
                    _messageDELETED &&
                    typeof _messageDELETED !== "undefined"
                  ) {
                    const _chat = await chatsService.updateGroup({
                      _chat: {
                        _id: selectedChat?._id || "",
                        avatar: selectedChat?.avatar?._id,
                        name: selectedChat?.name,
                        users: [
                          ..._users,
                          ..._groupsettingsADDED,
                          ..._groupsettingsREACTIVED,
                        ].filter(
                          (_u) =>
                            ![..._groupsettingsDELETED]?.includes(_u as string)
                        ) as string[],
                        groupsettings: _groupsettings,
                      },
                    });
                    if (typeof _chat !== "undefined" && _chat !== null) {
                      dispatch(
                        reselectChat({
                          chat: {
                            ..._chat,
                            lastmessage: _messageDELETED,
                            updated_at: _chat.updated_at,
                            updated_by: _chat.updated_by,
                          },
                        })
                      );
                      dispatch(
                        changeChat({
                          chat: {
                            ..._chat,
                            lastmessage: _messageDELETED,
                            updated_at: _chat.updated_at,
                            updated_by: _chat.updated_by,
                          },
                        })
                      );
                      customToast({
                        message: "Remoção realizada com sucesso!",
                        type: IToastType.SUCCESS,
                      });
                    } else {
                      customToast({
                        message:
                          "Algo deu errado ao remover algum participante!",
                        type: IToastType.ERROR,
                      });
                    }
                  }
                }
              };

              const _addAction = async () => {
                if (
                  [..._groupsettingsADDED, ..._groupsettingsREACTIVED].length >
                  0
                ) {
                  const _chat = await chatsService.updateGroup({
                    _chat: {
                      _id: selectedChat?._id || "",
                      avatar: selectedChat?.avatar?._id,
                      name: selectedChat?.name,
                      users: [
                        ..._users,
                        ..._groupsettingsADDED,
                        ..._groupsettingsREACTIVED,
                      ] as string[],
                      groupsettings: _groupsettings,
                    },
                  });
                  if (typeof _chat !== "undefined" && _chat !== null) {
                    customToast({
                      message: "Participantes adicionados com sucesso!",
                      type: IToastType.SUCCESS,
                    });
                    for await (const _added of [
                      ..._groupsettingsADDED,
                      ..._groupsettingsREACTIVED,
                    ]) {
                      let _alreadyAddedUser = null;
                      if (typeof selectedChat?.users !== "undefined") {
                        _alreadyAddedUser = selectedChat?.users.find(
                          (_temp) => _temp._id === _added
                        );
                      }
                      if (!_alreadyAddedUser) {
                        _messageADDED = await sendInternalMessage({
                          type: InternalMessageType.ADD_TO_GROUP,
                          message: {
                            chat: _chat,
                            content: `<b>[${user?._id}]</b> adicionou <b>[${_added}]</b>.`,
                          },
                        });
                      }
                    }
                    if (typeof _messageADDED !== "undefined" && _messageADDED) {
                      dispatch(
                        reselectChat({
                          chat: {
                            ..._chat,
                            lastmessage: _messageADDED,
                            updated_at: _chat.updated_at,
                            updated_by: _chat.updated_by,
                          },
                        })
                      );
                      dispatch(
                        changeChat({
                          chat: {
                            ..._chat,
                            lastmessage: _messageADDED,
                            updated_at: _chat.updated_at,
                            updated_by: _chat.updated_by,
                          },
                        })
                      );
                    }
                  } else {
                    customToast({
                      message:
                        "Algo deu errado ao adicionar novos participantes!",
                      type: IToastType.ERROR,
                    });
                  }
                }
              };

              await _removeAction();
              await _addAction();
            };

            getDeletedMembers();
            getNewMembers();
            await getResultMembers();

            // setDisable(false);
            // setShowModal(false);
          }
          setDisable(false);
          setShowModal(false);
          break;
        case "las la-address-book":
          if (selectedCustomer) {
            const { payload } = await dispatch(
              updateCustomerContacts({
                _customer: selectedCustomer?._id || "",
                contacts: results,
              })
            );
            if (payload._id) {
              await updatingCustomer(payload._id);
            }
            setDisable(false);
            setShowModal(false);
          }
          setDisable(false);
          setShowModal(false);
          break;
        case "lab la-whatsapp":
          try {
            let _buzy;
            setDisable(true);
            if (validateOlderWhatsAppChats && contactChat) {
              // TODO: Confirm user profile value ???
              _buzy = await validateOlderWhatsAppChats({
                _user: contactChat,
                _externalapp: user?.profile?.externalapp || undefined,
              });
            }
            if (typeof _buzy !== "undefined" && _buzy !== false) {
              setDefaultValueTemplate("");
              setOptionsTemplates([]);
              dispatch(selectTemplate({ template: null }));
              break;
            }
            if (
              !selectedTemplate ||
              !selectedTemplateMessage ||
              (typeof selectedTemplate?.components[0]?.example
                ?.header_handle !== "undefined" &&
                files.length === 0)
            ) {
              setDisable(false);
              setShowModal(false);
            }

            const getMessageError = (_files: string) => {
              if (_files.toLowerCase().includes("unsupported")) {
                return t("toast.error.files.unsupported");
              }
              if (_files.toLowerCase().includes("too large"))
                return t("toast.error.files.too-large");
              return t("toast.error.files.upload");
            };

            if (
              typeof contactChat?._id !== "undefined" &&
              typeof user?._id !== "undefined" &&
              setShow
            ) {
              setShow(false);
              setShowModal(false);
              const _externalApp =
                typeof defaultValueNumber !== "undefined"
                  ? await externalappsService.externalapps({
                      _id: defaultValueNumber as string,
                    })
                  : undefined;
              const _chatType =
                typeof _externalApp !== "undefined" &&
                typeof _externalApp.type !== "undefined"
                  ? _externalApp.type
                  : ChatType.WHATSAPP;

              const externalcodephone =
                typeof selectedTemplate?.phone !== "undefined" &&
                _chatType === ChatType.BOTMAKER
                  ? selectedTemplate?.phone
                  : undefined;

              const { payload } = await dispatch(
                createChat({
                  users: [contactChat._id, user?._id],
                  status: ChatStatus.WAITREPLY,
                  type: _chatType,
                  team: defaultValueTeam,
                  externalapp: user.profile?.externalapp || defaultValueNumber,
                  externalcodephone:
                    typeof externalcodephone !== "undefined" &&
                    externalcodephone
                      ? externalcodephone
                      : undefined,
                })
              );
              const chat = payload as IChat;
              if (typeof chat?._id !== "undefined" && chat !== null) {
                let _files: Files | string | null = [];
                let fileIds: string[] = [];
                if (files.length > 0) {
                  if (chat.type === ChatType.WHATSAPP) {
                    // _files = await authService.uploadFile(files, true);
                    _files = await authService.uploadFileWhatsApp({
                      _files: files,
                      externalapp:
                        chat?.externalapp?._id ||
                        chat?.externalapp ||
                        defaultValueNumber,
                    });
                  } else {
                    _files = await authService.uploadFile(
                      files,
                      true,
                      undefined,
                      chat?.type === ChatType.BOTMAKER
                    );
                  }
                }
                if (typeof _files === "string") {
                  customToast({
                    type: IToastType.ERROR,
                    message: getMessageError(_files),
                  });
                  return;
                }

                if (
                  typeof _files !== "undefined" &&
                  _files !== null &&
                  _files?.length > 0
                ) {
                  fileIds = _files.map((file) => file?._id || "");
                }

                let wppTemplateVariables: ITemplateVariables = {};
                const section: string[] = ["body_text", "header_text"];
                for (const item of section) {
                  const _template =
                    typeof selectedTemplate?.components[0]?.example !==
                      "undefined" &&
                    typeof selectedTemplate?.components[0]?.example[
                      item as keyof ExampleComponent
                    ] !== "undefined"
                      ? selectedTemplate?.components[0]?.example[
                          item as keyof ExampleComponent
                        ]
                      : typeof selectedTemplate?.components[1]?.example !==
                          "undefined" &&
                        typeof selectedTemplate?.components[1]?.example[
                          item as keyof ExampleComponent
                        ] !== "undefined"
                      ? selectedTemplate?.components[1]?.example[
                          item as keyof ExampleComponent
                        ]
                      : null;
                  const componentVariables =
                    typeof _template !== "undefined" ? _template : null;

                  if (
                    typeof componentVariables !== "undefined" &&
                    componentVariables
                  ) {
                    wppTemplateVariables = {
                      ...wppTemplateVariables,
                      [item]: componentVariables,
                    };
                  }
                }

                const _parameters = selectedTemplateParameters?.map((_obj) => {
                  return {
                    type:
                      _obj.type.toLowerCase() === "buttons"
                        ? "button"
                        : _obj.type.toLowerCase(),
                    sub_type: _obj.sub_type,
                    index: _obj.index,
                    parameters: _obj.parameters.map((_p) => {
                      if (_p.type === "image") {
                        if (
                          typeof _files !== "undefined" &&
                          _files !== null &&
                          _files?.length > 0
                        ) {
                          let _parameter = {};
                          if (chat.type === ChatType.BOTMAKER) {
                            _parameter = {
                              id: (_files as Files)[0]?._id,
                              filepath: (_files as Files)[0]?.path || undefined,
                              filename:
                                `${(_files as Files)[0]?.name}` ||
                                `imagem.${(_files as Files)[0]?.extension}` ||
                                undefined,
                            };
                          } else {
                            _parameter = {
                              id: (_files as Files)[0]?.referenceid,
                            };
                          }

                          return { type: _p.type, image: _parameter };
                        }
                        return { type: _p.type, image: _p.image };
                      }
                      if (_p.type === "video") {
                        if (
                          typeof _files !== "undefined" &&
                          _files !== null &&
                          _files?.length > 0
                        ) {
                          let _parameter = {};
                          if (chat.type === ChatType.BOTMAKER) {
                            _parameter = {
                              id: (_files as Files)[0]?._id,
                              filepath: (_files as Files)[0]?.path || undefined,
                              filename:
                                `${(_files as Files)[0]?.name}` ||
                                `video.${(_files as Files)[0]?.extension}` ||
                                undefined,
                            };
                          } else {
                            _parameter = {
                              id: (_files as Files)[0]?.referenceid,
                            };
                          }
                          return { type: _p.type, video: _parameter };
                        }
                        return { type: _p.type, video: _p.video };
                      }
                      if (_p.type === "document") {
                        if (
                          typeof _files !== "undefined" &&
                          _files !== null &&
                          _files?.length > 0
                        ) {
                          let _parameter = {};
                          if (chat.type === ChatType.BOTMAKER) {
                            _parameter = {
                              id: (_files as Files)[0]?._id,
                              filepath: (_files as Files)[0]?.path || "",
                              filename:
                                `${(_files as Files)[0]?.name}` ||
                                "arquivo.pdf",
                            };
                          } else {
                            _parameter = {
                              id: (_files as Files)[0]?.referenceid,
                              filename:
                                `${(_files as Files)[0]?.name}` ||
                                "arquivo.pdf",
                            };
                          }
                          return { type: _p.type, document: _parameter };
                        }
                        return { type: _p.type, document: _p.document };
                      }
                      if (_p.type === "coupon_code") {
                        return {
                          type: _p.type,
                          coupon_code: templateButtons.code,
                        };
                      }
                      if (_p.type === "url") {
                        return {
                          type: _p.type,
                          text: templateButtons.url,
                        };
                      }
                      return { type: _p.type, text: _p.text };
                    }),
                  };
                });

                const newMessage: INewMessage = {
                  chat,
                  content: selectedTemplateMessage
                    .replaceAll("</>", "")
                    .replaceAll("undefined", ""),
                  files: fileIds || [],
                  parent: null,
                  referenceid: "",
                  origin: user?._id,
                };
                const _newInternalMessage: INewMessage = {
                  chat,
                  content: "Aguardando retorno do contato",
                  files: [],
                  parent: null,
                  referenceid: "",
                  origin: user?._id,
                  internal: true,
                  read: true,
                  type: MessageType.USER,
                };
                const actionMessage = await messagesService.create(newMessage);
                if (
                  typeof actionMessage._id !== "undefined" &&
                  actionMessage !== null &&
                  selectedTemplate
                ) {
                  actionMessage.chat = chat;
                  dispatch(addMessage(actionMessage));
                  const _newMsg: IMessage = actionMessage;
                  const newMessageWpp: INewMessageExternalappWpp = {
                    chat_id: newMessage.chat._id,
                    message_id: _newMsg._id,
                    messaging_product: "whatsapp",
                    recipient_type: "individual",
                    to: `${contactChat?.phone}` || "",
                    contact: contactChat,
                    team: defaultValueTeam,
                    type: "template",
                    externalapp:
                      _newMsg?.chat?.externalapp?._id ||
                      _newMsg?.chat?.externalapp ||
                      undefined,
                    template: {
                      name: selectedTemplate.name,
                      phone: selectedTemplate.phone,
                      language: selectedTemplate.language,
                      components: _parameters || [],
                      variables:
                        actionMessage?.chat?.type === ChatType.BOTMAKER
                          ? wppTemplateVariables
                          : undefined,
                    },
                  };
                  const actionMessageWpp = await whatsappService.createWpp(
                    newMessageWpp
                  );
                  if (
                    typeof actionMessageWpp.message_id !== "undefined" &&
                    actionMessageWpp !== null
                  ) {
                    const action = await dispatch(
                      createMessage(_newInternalMessage)
                    );
                    if (
                      action.payload !== null &&
                      typeof action.payload._id !== "undefined"
                    ) {
                      setDisable(false);
                      setShowModal(false);
                      if (selectedTemplate !== null) {
                        dispatch(selectTemplate({ template: null }));
                      }
                      navigate(`/chat/${chat._id}`);
                    }
                  }
                }
              }
            }
          } catch (error) {
            // console.log(error);
          }
          break;
        case "profile-teams":
          if (user) {
            await dispatch(
              update({
                _updateUser: {
                  ...user,
                  avatar: user?.avatar?._id,
                  _id: user._id,
                  teams: results,
                },
              })
            );
            await dispatch(userdata());
          }
          setDisable(false);
          setShowModal(false);
          break;
        case "las la-tags":
          if (typeof selectedChat?._id !== "undefined") {
            const updatedChat = await chatsService.addTagsToChat({
              _id: selectedChat._id,
              tags: results,
            });
            if (updatedChat && typeof updatedChat?._id !== "undefined") {
              if (typeof updatedChat?._id !== "undefined") {
                const { payload } = await dispatch(
                  selectChat({ _id: updatedChat._id })
                );
                if (typeof payload?._id !== "undefined") {
                  dispatch(
                    reselectChat({
                      chat: {
                        ...payload,
                      },
                      changedTags: true,
                    })
                  );
                  dispatch(
                    changeChat({
                      chat: {
                        ...payload,
                      },
                      changedTags: true,
                    })
                  );
                }
                customToast({
                  message: "Atualização de marcadores realizada com sucesso!",
                  type: IToastType.SUCCESS,
                });
              } else {
                customToast({
                  message: "Algo deu errado com os marcadores!",
                  type: IToastType.ERROR,
                });
              }
            }
          }
          setDisable(false);
          setShowModal(false);
          break;
        // case "profile-customers":
        //   if (user) {
        //     await dispatch(
        //       update({ _updateUser: { _id: user._id, customers: results } })
        //     );
        //     await dispatch(userdata());
        //   }
        //   setDisable(false);
        //   setShowModal(false);
        // break;
        default:
          setDisable(false);
          setShowModal(false);
          break;
      }
      setDisable(false);
    } catch (error) {
      setDisable(false);
      // console.log(error);
    }
  };

  const getType = () => {
    switch (icon) {
      case "las la-users-cog":
        const saveAllAdmin = (checked: boolean) => {
          const _newList: IAgideskTeam[] = [];
          for (const _team of results) {
            if (_team.users && _team.users.length > 0) {
              const _newTeam = {
                ..._team,
                users: [] as IAgideskUser[],
              };
              for (const _user of _team.users) {
                const _newUser: IAgideskUser = {
                  ..._user,
                  admin: checked,
                };
                _newTeam.users.push(_newUser);
              }
              _newList.push(_newTeam);
            } else {
              _newList.push(_team);
            }
          }
          setResults(_newList);
        };

        const saveAllInvite = (checked: boolean) => {
          const _newList: IAgideskTeam[] = [];
          for (const _team of results) {
            if (_team.users && _team.users.length > 0) {
              const _newTeam = {
                ..._team,
                users: [] as IAgideskUser[],
              };
              for (const _user of _team.users) {
                const _newUser: IAgideskUser = {
                  ..._user,
                  invite: _user.new === true ? checked : false,
                };
                _newTeam.users.push(_newUser);
              }
              _newList.push(_newTeam);
            } else {
              _newList.push(_team);
            }
          }
          setResults(_newList);
        };

        const agideskUsers = [];
        const _users = [];
        if (results) {
          for (const _team of results) {
            if (_team?.users && _team?.users.length > 0) {
              for (const _teamuser of _team.users) {
                if (agideskUsers[_teamuser.id]) {
                  agideskUsers[_teamuser.id].teams += ` - ${_team.title}`;
                } else {
                  agideskUsers[_teamuser.id] = {
                    id: _teamuser.id,
                    title: _teamuser.title,
                    teams: _team.title,
                    admin: _teamuser.admin,
                    invite: _teamuser.invite,
                    new: _teamuser.new,
                  };
                }
              }
            }
          }
          for (const _user of Object.values(agideskUsers)) {
            _users.push(_user);
          }
          return (
            <div className="my-8">
              <div className="flex items-center justify-end px-3">
                <div className="flex items-center justify-start w-1/2">
                  <p className="flex items-center justify-center font-semibold text-agitalks text-[12px] pr-2 ">
                    Total de agentes:
                  </p>
                  <p className="text-red-700 flex items-center justify-center font-semibold text-[12px]">
                    {`${_users.length}`}
                  </p>
                </div>
                <div className="flex items-center justify-around w-1/2">
                  <LinkButton
                    disabled={
                      typeof findInObject(results, "admin", false) ===
                      "undefined"
                    }
                    label="(Selecionar todos)"
                    extraClass="font-semibold text-[12px] flex justify-center w-1/2"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      saveAllAdmin(true);
                    }}
                  />
                  <LinkButton
                    disabled={
                      typeof findInObject(results, "invite", false) ===
                      "undefined"
                    }
                    label="(Selecionar todos)"
                    extraClass="font-semibold text-[12px] flex justify-center w-1/2"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      saveAllInvite(true);
                    }}
                  />
                </div>
              </div>
              <div className="flex items-center justify-end px-3">
                <div className="flex items-center justify-around w-1/2">
                  <LinkButton
                    disabled={
                      typeof findInObject(results, "admin", true) ===
                      "undefined"
                    }
                    label="(Limpar todos)"
                    extraClass="font-semibold text-[12px] flex justify-center w-1/2"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      saveAllAdmin(false);
                    }}
                  />
                  <LinkButton
                    disabled={
                      typeof findInObject(results, "invite", true) ===
                      "undefined"
                    }
                    label="(Limpar todos)"
                    extraClass="font-semibold text-[12px] flex justify-center w-1/2"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      saveAllInvite(false);
                    }}
                  />
                </div>
              </div>
              <div className="flex items-center justify-between px-3 my-3">
                <div className="flex items-center justify-around w-1/2">
                  <p className="font-semibold text-[13px]">Agentes e equipes</p>
                </div>
                <div className="flex items-center justify-around w-1/2">
                  <p className="font-semibold text-center text-[13px] mr-3">
                    Tornar
                    <br />
                    administrador
                  </p>
                  <p className="font-semibold text-center text-[13px]">
                    Convite por
                    <br />
                    e-mail
                  </p>
                </div>
              </div>
              <div className="overflow-y-auto max-h-[300px]">
                {_users
                  ?.sort((a, b) => a.title.localeCompare(b.title))
                  ?.map((_user) => {
                    return (
                      <UserAdmin
                        key={_user.id}
                        teamuser={_user}
                        results={results}
                        setResults={setResults}
                      />
                    );
                  })}
              </div>
            </div>
          );
        }
        return null;
      case "las la-calendar":
        return (
          <>
            <div className="mt-12" />
            <Form.Group>
              <ActiveDropdown
                className="pt-4"
                id="dropdown-relation-status"
                width="8"
                fluid
                defaultValue={selectedRow?.active}
                onChange={(e, { value }) => {
                  const _oldValue = {
                    _id: results[0]?._id,
                    active: value,
                    weekday: results[0]?.weekday,
                    start: results[0]?.start,
                    finish: results[0]?.finish,
                  };
                  setResults([_oldValue]);
                }}
              />
              <WeekdaysDropdown
                className="pt-4"
                fluid
                required
                width={8}
                defaultValue={selectedRow?.weekday}
                onChange={(e, { value }) => {
                  const _value = value as string;
                  const _oldValue = {
                    _id: results[0]?._id,
                    active: results[0]?.active,
                    weekday: _value,
                    start: results[0]?.start,
                    finish: results[0]?.finish,
                  };
                  setResults([_oldValue]);
                }}
              />
            </Form.Group>
            <div className="mb-4" />
            <Form.Group>
              <Form.Input
                autoComplete="off"
                width="8"
                label="Hora inicial"
                required
                children={
                  <ReactInputMask
                    maskPlaceholder={null}
                    placeholder="Hora inicial"
                    required
                    mask={[
                      /[0-2]/,
                      results[0]?.start[0] === "2" ? /[0-3]/ : /[0-9]/,
                      ":",
                      /[0-5]/,
                      /[0-9]/,
                    ]}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const _oldValue = {
                        _id: results[0]?._id,
                        active: results[0]?.active,
                        weekday: results[0]?.weekday,
                        start: e.target.value.replaceAll("_", "0"),
                        finish: results[0]?.finish,
                      };
                      setResults([_oldValue]);
                    }}
                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const _oldValue = {
                        _id: results[0]?._id,
                        active: results[0]?.active,
                        weekday: results[0]?.weekday,
                        start: parseBlurTime(e.target.value),
                        finish: results[0]?.finish,
                      };
                      setResults([_oldValue]);
                    }}
                    value={
                      results[0]?.start?.length > 0
                        ? results[0]?.start
                        : selectedRow?.start
                    }
                  />
                }
              />
              <Form.Input
                // className="pt-4"
                autoComplete="off"
                width="8"
                label="Hora final"
                required
                children={
                  <ReactInputMask
                    maskPlaceholder={null}
                    placeholder="Hora final"
                    required
                    mask={[
                      /[0-2]/,
                      results[0]?.finish[0] === "2" ? /[0-3]/ : /[0-9]/,
                      ":",
                      /[0-5]/,
                      /[0-9]/,
                    ]}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const _oldValue = {
                        _id: results[0]?._id,
                        active: results[0]?.active,
                        weekday: results[0]?.weekday,
                        start: results[0]?.start,
                        finish: e.target.value.replaceAll("_", "0"),
                      };
                      setResults([_oldValue]);
                    }}
                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const _oldValue = {
                        _id: results[0]?._id,
                        active: results[0]?.active,
                        weekday: results[0]?.weekday,
                        start: results[0]?.start,
                        finish: parseBlurTime(e.target.value),
                      };
                      setResults([_oldValue]);
                    }}
                    value={
                      results[0]?.finish?.length > 0
                        ? results[0]?.finish
                        : selectedRow?.finish
                    }
                  />
                }
              />
            </Form.Group>
            <div className="mb-12" />
          </>
        );
      case "lab la-whatsapp":
        const optionsExternalapp: ddOptions[] = [];

        externalAppWhatsApp.map((externalapp) =>
          optionsExternalapp.push({
            key: externalapp._id,
            text: externalapp.fields.name,
            value: externalapp._id,
          })
        );

        externalAppBotmaker.map((externalapp) =>
          optionsExternalapp.push({
            key: externalapp._id,
            text: externalapp.fields.name,
            value: externalapp._id,
          })
        );

        if (typeof selectedTemplate?.components !== "undefined") {
          for (const key in Object.keys(selectedTemplate.components)) {
            if (
              Object.prototype.hasOwnProperty.call(
                selectedTemplate?.components,
                key
              )
            ) {
              const element = selectedTemplate?.components[key];
              if (
                element.type === "button" &&
                element.sub_type === "copy_code" &&
                templateButtons.code.length === 0 &&
                typeof element?.parameters !== "undefined" &&
                typeof element?.parameters[0]?.coupon_code !== "undefined"
              ) {
                const _value = element.parameters[0].coupon_code;
                setTemplateButtons((prevState) => ({
                  ...prevState,
                  code: _value,
                }));
              }
            }
          }
        }

        return (
          <>
            <div className="mt-8" />
            <Dropdown
              id="dropdown-relation-whatsapp-profile"
              fluid
              required
              search
              selection
              disabled={optionsExternalapp.length === 1}
              defaultValue={
                optionsExternalapp.length === 1
                  ? optionsExternalapp[0].value
                  : defaultValueNumber
              }
              label="Número utilizado"
              placeholder="Número utilizado"
              onChange={async (e, { value }) => {
                setDefaultValueNumber(value as string);
                setDefaultValueTemplate("");
                setOptionsTemplates([]);
                dispatch(selectTemplate({ template: null }));
                const payload = await usersService.profile({
                  externalapp: value as string,
                  defaulttemplate: "",
                });
                const _payload = payload as IUser;
                if (
                  _payload &&
                  _payload !== null &&
                  typeof _payload._id !== "undefined"
                ) {
                  await dispatch(userdata());
                  const _value = value?.toString();
                  if (validateOlderWhatsAppChats && _value && contactChat)
                    validateOlderWhatsAppChats({
                      _user: contactChat,
                      _externalapp: _value,
                    });
                }
              }}
              options={optionsExternalapp}
            />
            {typeof defaultValueNumber !== "undefined" && defaultValueNumber ? (
              <Dropdown
                id="dropdown-relation-whatsapp-teams"
                required
                fluid
                search
                selection
                options={optionsTeams}
                disabled={optionsTeams.length === 1}
                value={
                  optionsTeams.length === 1
                    ? optionsTeams[0].value
                    : defaultValueTeam
                }
                label="Equipe"
                placeholder="Equipe"
                onFocus={() => {
                  setFocusId("dropdown-relation-whatsapp-teams");
                }}
                onBlur={() => {
                  setFocusId("");
                }}
                onChange={(e, { value }) => {
                  setDefaultValueTeam(value as string);
                }}
              />
            ) : null}
            {typeof defaultValueNumber !== "undefined" && defaultValueNumber ? (
              <Dropdown
                id="dropdown-relation-whatsapp-templates"
                loading={
                  isLoadingDropdown &&
                  focusId === "dropdown-relation-whatsapp-templates"
                }
                label="Templates"
                fluid
                search
                required
                selection
                options={optionsTemplates}
                defaultValue={defaultValueTemplate}
                placeholder="Templates"
                onOpen={async () => {
                  setIsLoadingDropdown(true);
                  const { payload }: any = await dispatch(
                    getExternalappTemplatesWpp({
                      _validate: false,
                      externalappid: defaultValueNumber,
                    })
                  );
                  // const { payload } = await dispatch(
                  //   getTemplatesWpp({
                  //     _validate: false,
                  //     externalappid: defaultValueNumber,
                  //   })
                  // );
                  setIsLoadingDropdown(false);
                  const _optionsTemplates: ddOptions[] = [];
                  if (payload) {
                    payload?.forEach((template: ITemplate) => {
                      _optionsTemplates.push({
                        key: template.id,
                        text: template.name,
                        value: template.id,
                      });
                    });
                    setOptionsTemplates(_optionsTemplates);
                  } else {
                    setOptionsTemplates([]);
                  }
                }}
                onFocus={() => {
                  setFocusId("dropdown-relation-whatsapp-templates");
                }}
                onBlur={() => {
                  setFocusId("");
                }}
                onChange={(e, { value }) => {
                  try {
                    const _selected =
                      whatsappTemplates.find((_temp) => _temp.id === value) ||
                      null;
                    if (_selected && value) {
                      dispatch(selectTemplate({ template: null }));
                      setDefaultValueTemplate(_selected?.name?.toString());
                      dispatch(selectTemplate({ template: _selected }));
                    }
                  } catch (error) {
                    // console.log(error);
                  }
                }}
              />
            ) : null}
            {selectedTemplate && contactChat ? (
              <WhatsAppTemplate
                contactChat={contactChat}
                results={selectedTemplate}
                files={files}
                setFiles={setFiles}
                setTemplateButtons={setTemplateButtons}
              />
            ) : null}
            <div className="mb-8" />
          </>
        );
      case "las la-comment":
        return (
          <>
            <div className="mt-8" />
            <Dropdown
              id="dropdown-relation-commenttemplates-teams"
              // required
              clearable
              fluid
              search
              selection
              options={optionsTeams}
              disabled={optionsTeams.length === 1}
              value={
                optionsTeams.length === 1
                  ? optionsTeams[0].value
                  : defaultValueTeam
              }
              label="Equipe"
              placeholder="Equipe"
              onFocus={() => {
                setFocusId("dropdown-relation-commenttemplates-teams");
              }}
              onOpen={
                !isAdmin
                  ? undefined
                  : async () => {
                      await adminGetTeams();
                    }
              }
              onSearchChange={
                !isAdmin
                  ? undefined
                  : async (e: DropdownSearchInputProps) => {
                      await adminGetTeams(e);
                    }
              }
              onBlur={() => {
                setFocusId("");
              }}
              onChange={(e, { value }) => {
                setDefaultValueTeam(value as string);
                setCommenttemplate("");
                setCommenttemplates([]);
                setOptionsCommentTemplates([]);
              }}
            />
            {/* {typeof defaultValueTeam !== "undefined" && defaultValueTeam ? ( */}
            <Dropdown
              id="dropdown-relation-commenttemplates"
              loading={
                isLoadingDropdown &&
                focusId === "dropdown-relation-commenttemplates"
              }
              label="Modelos de resposta"
              fluid
              search
              required
              selection
              options={optionsCommentTemplates}
              value={_commenttemplateId}
              placeholder="Modelos de resposta"
              onOpen={async () => {
                setIsLoadingDropdown(true);
                const payload = await commenttemplatesService.search({
                  skip: 0,
                  limit: 10,
                  filter: "",
                  teams: !isAdmin
                    ? defaultValueTeam.length > 0
                      ? [defaultValueTeam]
                      : undefined
                    : defaultValueTeam.length > 0
                    ? [defaultValueTeam]
                    : undefined,
                  deleted: false,
                });
                setIsLoadingDropdown(false);
                const _optionsCommenttemplates: ddOptions[] = [];
                setCommenttemplates(payload.results);
                payload.results.forEach(
                  (_commenttemplate: ICommenttemplate) => {
                    if (
                      (typeof _commenttemplate?.content !== "undefined" &&
                        _commenttemplate.content) ||
                      (typeof _commenttemplate?.files !== "undefined" &&
                        _commenttemplate.files.length > 0)
                    ) {
                      _optionsCommenttemplates.push({
                        key: _commenttemplate._id,
                        text: _commenttemplate.name,
                        value: _commenttemplate._id,
                      });
                    }
                  }
                );
                setOptionsCommentTemplates(_optionsCommenttemplates);
              }}
              onFocus={() => {
                setFocusId("dropdown-relation-commenttemplates");
              }}
              onBlur={() => {
                setFocusId("");
              }}
              onSearchChange={async (e: DropdownSearchInputProps) => {
                setIsLoadingDropdown(true);
                const payload = await commenttemplatesService.search({
                  skip: 0,
                  limit: 10,
                  filter: e.target.value,
                  deleted: false,
                  teams: !isAdmin
                    ? defaultValueTeam.length > 0
                      ? [defaultValueTeam]
                      : undefined
                    : defaultValueTeam.length > 0
                    ? [defaultValueTeam]
                    : undefined,
                });
                setIsLoadingDropdown(false);
                const _optionsCommenttemplates: ddOptions[] = instanceOptions;
                payload.results.forEach(
                  (_commenttemplate: ICommenttemplate) => {
                    if (
                      (typeof _commenttemplate?.content !== "undefined" &&
                        _commenttemplate.content) ||
                      (typeof _commenttemplate?.files !== "undefined" &&
                        _commenttemplate.files.length > 0) ||
                      _optionsCommenttemplates.filter(
                        (_opCommenttemplate) =>
                          _opCommenttemplate.key !== _commenttemplate._id
                      )[0]
                    ) {
                      _optionsCommenttemplates.push({
                        key: _commenttemplate._id,
                        text: _commenttemplate.name,
                        value: _commenttemplate._id,
                      });
                    }
                  }
                );
                setOptionsCommentTemplates(_optionsCommenttemplates);
              }}
              onChange={(e, { value }) => {
                try {
                  const _value = value as string;
                  if (typeof _value !== "undefined" && _value) {
                    setCommenttemplate(_value);
                  }
                } catch (error) {
                  // console.log(error);
                }
              }}
            />
            {/* ) : null} */}
            {_commenttemplateId ? (
              <Preview
                commenttemplate={
                  (_commenttemplates.find(
                    (_temp) => _temp._id === _commenttemplateId
                  ) as ICommenttemplate) ?? null
                }
              />
            ) : null}
            <div className="mb-8" />
          </>
        );
      case "las la-tags":
        const valuesTags: string[] = [];
        if (selectedChat?.tags && typeof selectedChat.tags !== "undefined") {
          selectedChat.tags.forEach((tag: ITag) => {
            valuesTags.push(tag?._id || "");
            instanceOptions.push({
              key: tag._id,
              text: tag.name,
              value: tag._id,
            });
          });
        }
        return (
          <>
            <div className="mt-8" />
            <Dropdown
              fluid
              selection
              id="dropdown-relation-chat-tags"
              label="Marcadores de conversa"
              placeholder="Marcadores de conversa"
              loading={
                isLoadingDropdown && focusId === "dropdown-relation-chat-tags"
              }
              search
              multiple
              defaultValue={valuesTags}
              options={instanceOptions}
              onOpen={async () => {
                setIsLoadingDropdown(true);
                const payload = await tagsService.searchTagChat({
                  skip: 0,
                  limit: 10,
                  filter: {
                    type: [TagType.CHAT],
                    teams:
                      typeof selectedChat?.team?._id !== "undefined"
                        ? [selectedChat?.team?._id]
                        : [],
                    groupchat:
                      typeof selectedChat?.groupchat !== "undefined"
                        ? selectedChat?.groupchat
                        : false,
                  },
                  deleted: false,
                });
                setIsLoadingDropdown(false);
                const _optionsTags: ddOptions[] = [];
                payload.results.map((_tag: ITag) =>
                  _optionsTags.push({
                    key: _tag._id,
                    text: _tag.name,
                    value: _tag._id,
                  })
                );
                setInstanceOptions(_optionsTags);
              }}
              onFocus={() => {
                setFocusId("dropdown-relation-chat-tags");
              }}
              onBlur={() => {
                setFocusId("");
              }}
              onSearchChange={async (e: DropdownSearchInputProps) => {
                setIsLoadingDropdown(true);
                const payload = await tagsService.searchTagChat({
                  skip: 0,
                  limit: 10,
                  filter: {
                    content: e.target.value,
                    type: [TagType.CHAT],
                    teams:
                      typeof selectedChat?.team?._id !== "undefined"
                        ? [selectedChat?.team?._id]
                        : [],
                    groupchat:
                      typeof selectedChat?.groupchat !== "undefined"
                        ? selectedChat?.groupchat
                        : false,
                  },
                  deleted: false,
                });
                setIsLoadingDropdown(false);
                const _optionsTags: ddOptions[] = instanceOptions;
                payload.results.map((_tag: ITag) => {
                  if (
                    _optionsTags.filter((_opTag) => _opTag.key === _tag._id)[0]
                  ) {
                    return false;
                  }
                  _optionsTags.push({
                    key: _tag._id,
                    text: _tag.name,
                    value: _tag._id,
                  });
                  return true;
                });
                setInstanceOptions(_optionsTags);
              }}
              onChange={(e, { value }) => {
                const _value = value as string[];
                setResults(removeDuplicates(_value));
              }}
            />
            <div className="mb-8" />
          </>
        );
      case "las la-user-friends":
        const valueTeams: string[] = [];

        if (location.pathname.includes("general")) {
          if (selectedSettings?.defaultteam) {
            selectedSettings?.defaultteam.forEach((team: ITeam) => {
              valueTeams.push(team?._id || "");
              instanceOptions.push({
                key: team._id,
                text: team.name,
                value: team._id,
              });
            });
          }
        } else if (location.pathname.includes("officehour")) {
          if (selectedOfficehour?.teams) {
            selectedOfficehour?.teams.forEach((team: ITeam) => {
              valueTeams.push(team?._id || "");
              instanceOptions.push({
                key: team._id,
                text: team.name,
                value: team._id,
              });
            });
          }
        } else if (location.pathname.includes("holiday")) {
          if (selectedHoliday?.teams) {
            selectedHoliday?.teams.forEach((team: ITeam) => {
              valueTeams.push(team?._id || "");
              instanceOptions.push({
                key: team._id,
                text: team.name,
                value: team._id,
              });
            });
          }
        } else if (location.pathname.includes("survey")) {
          if (selectedSurvey?.teams) {
            selectedSurvey?.teams.forEach((team: ITeam) => {
              valueTeams.push(team?._id || "");
              instanceOptions.push({
                key: team._id,
                text: team.name,
                value: team._id,
              });
            });
          }
        } else if (location.pathname.includes("commenttemplate")) {
          if (selectedCommenttemplate?.teams) {
            selectedCommenttemplate?.teams.forEach((team: ITeam) => {
              valueTeams.push(team?._id || "");
              instanceOptions.push({
                key: team._id,
                text: team.name,
                value: team._id,
              });
            });
          }
        } else if (location.pathname.includes("tag")) {
          if (selectedTag?.teams) {
            selectedTag?.teams.forEach((team: any) => {
              valueTeams.push(team?._id || "");
              instanceOptions.push({
                key: team._id,
                text: team.name,
                value: team._id,
              });
            });
          }
        } else if (
          location.pathname.includes("whatsapp") ||
          location.pathname.includes("agibot")
        ) {
          const selectedExternalAppObj = location.pathname.includes("whatsapp")
            ? selectedExternalAppWhatsApp
            : selectedExternalAppBotmaker;
          if (selectedExternalAppObj?.fields?.defaultteam) {
            selectedExternalAppObj?.fields?.defaultteam.forEach(
              (team: ITeam) => {
                valueTeams.push(team?._id || "");
                instanceOptions.push({
                  key: team._id,
                  text: team.name,
                  value: team._id,
                });
              }
            );
          }
        } else if (selectedUser?.teams) {
          selectedUser?.teams.forEach((team: ITeam) => {
            valueTeams.push(team?._id || "");
            instanceOptions.push({
              key: team._id,
              text: team.name,
              value: team._id,
            });
          });
        }

        return (
          <>
            <div className="mt-12" />
            <Dropdown
              required={location.pathname.includes("whatsapp")}
              fluid
              search
              selection
              id="dropdown-relation-teams"
              label="Equipes"
              placeholder="Equipes"
              loading={
                isLoadingDropdown && focusId === "dropdown-relation-teams"
              }
              multiple
              defaultValue={valueTeams}
              options={instanceOptions}
              onOpen={async () => {
                setIsLoadingDropdown(true);
                const payload = await teamsService.search({
                  skip: 0,
                  limit: 10 + valueTeams.length,
                  filter: "",
                  deleted: false,
                  withChatusers:
                    !location.pathname.includes("/user") &&
                    !location.pathname.includes("/chattag") &&
                    !location.pathname.includes("/contacttag") &&
                    !location.pathname.includes("/survey")
                      ? true
                      : undefined,
                });
                setIsLoadingDropdown(false);
                const _optionsTeams: ddOptions[] = [];
                payload.results.map((_team: ITeam) =>
                  _optionsTeams.push({
                    key: _team._id,
                    text: _team.name,
                    value: _team._id,
                  })
                );
                setInstanceOptions(_optionsTeams);
              }}
              onFocus={() => {
                setFocusId("dropdown-relation-teams");
              }}
              onBlur={() => {
                setFocusId("");
              }}
              onSearchChange={async (e: DropdownSearchInputProps) => {
                setIsLoadingDropdown(true);
                const payload = await teamsService.search({
                  skip: 0,
                  limit: 10,
                  filter: e.target.value,
                  deleted: false,
                  withChatusers:
                    !location.pathname.includes("/user") &&
                    !location.pathname.includes("/chattag") &&
                    !location.pathname.includes("/contacttag") &&
                    !location.pathname.includes("/survey")
                      ? true
                      : undefined,
                });
                setIsLoadingDropdown(false);
                const _optionsTeams: ddOptions[] = instanceOptions;
                payload.results.map((_team: ITeam) => {
                  if (
                    _optionsTeams.filter(
                      (_opTeam) => _opTeam.key === _team._id
                    )[0]
                  ) {
                    return false;
                  }
                  _optionsTeams.push({
                    key: _team._id,
                    text: _team.name,
                    value: _team._id,
                  });
                  return true;
                });
                setInstanceOptions(_optionsTeams);
              }}
              onChange={(e, { value }) => {
                const _value = value as string[];
                setResults(removeDuplicates(_value));
              }}
            />
            <ErrorLabel
              label="Este campo não pode ficar em branco."
              error={
                location.pathname.includes("whatsapp") && results.length === 0
              }
            />
            <div className="mb-12" />
          </>
        );
      case "las la-building":
        const valuesCustomers: string[] = [];
        if (selectedContact?.customers) {
          selectedContact?.customers.forEach((_customer: ICustomer) => {
            valuesCustomers.push(_customer?._id || "");
            instanceOptions.push({
              key: _customer._id,
              text: _customer?.name ? _customer?.name : _customer?.fullname,
              value: _customer._id,
            });
          });
        }
        return (
          <>
            <div className="mt-12" />
            <Dropdown
              fluid
              selection
              id="dropdown-relation-customers"
              label="Clientes"
              placeholder="Clientes"
              loading={
                isLoadingDropdown && focusId === "dropdown-relation-customers"
              }
              search
              multiple
              defaultValue={valuesCustomers}
              options={instanceOptions}
              onOpen={async () => {
                setIsLoadingDropdown(true);
                const payload = await customersService.search({
                  skip: 0,
                  limit: 10,
                  filter: "",
                  deleted: false,
                });
                setIsLoadingDropdown(false);
                const _optionsCustomers: object[] = [];
                payload.results.map((_customer: ICustomer) =>
                  _optionsCustomers.push({
                    key: _customer._id,
                    text: _customer?.name
                      ? _customer?.name
                      : _customer?.fullname,
                    value: _customer._id,
                  })
                );
                setInstanceOptions(_optionsCustomers);
              }}
              onFocus={() => {
                setFocusId("dropdown-relation-customers");
              }}
              onBlur={() => {
                setFocusId("");
              }}
              onSearchChange={async (e: DropdownSearchInputProps) => {
                setIsLoadingDropdown(true);
                const payload = await customersService.search({
                  skip: 0,
                  limit: 10,
                  filter: e.target.value,
                  deleted: false,
                });
                setIsLoadingDropdown(false);
                const _optionsCustomers: ddOptions[] = instanceOptions;
                payload.results.map((_customer: ICustomer) => {
                  if (
                    _optionsCustomers.filter(
                      (_opCustomer) => _opCustomer.key === _customer._id
                    )[0]
                  ) {
                    return false;
                  }
                  _optionsCustomers.push({
                    key: _customer._id,
                    text: _customer?.name
                      ? _customer?.name
                      : _customer?.fullname,
                    value: _customer._id,
                  });
                  return true;
                });
                setInstanceOptions(_optionsCustomers);
              }}
              onChange={(e, { value }) => {
                const _value = value as string[];
                setResults(removeDuplicates(_value));
              }}
            />
            <div className="mb-12" />
          </>
        );
      case "las la-user-cog":
        const valuesGroupUsers: string[] = [];
        if (selectedChat?.users) {
          const _isActiveUserGroup: string[] = [];
          selectedChat?.groupsettings?.forEach((_groupuser) => {
            if (_groupuser.active === true)
              _isActiveUserGroup.push(_groupuser.user?._id as string);
          });
          selectedChat?.users.forEach((_user: IUser) => {
            if (
              typeof _user?._id !== "undefined" &&
              _user?._id !== selectedChat.created_by &&
              _user?._id !== user?._id &&
              _isActiveUserGroup.includes(_user._id)
            ) {
              valuesGroupUsers.push(_user?._id || "");
              instanceOptions.push({
                key: _user._id,
                text: _user.name,
                value: _user._id,
                content: (
                  <div className="flex">
                    <h1 className="mr-2">{_user?.name}</h1>
                    {typeof _user?.email !== "undefined" ? (
                      <h1 className="text-gray-text">({_user?.email})</h1>
                    ) : null}
                  </div>
                ),
              });
            }
          });
        }
        return (
          <>
            <div className="mt-12" />
            <PopupComponent
              className="flex items-center mb-1"
              label="Participantes"
              required
              content={
                <h1 className="font-semibold text-[12px]">
                  O criador do grupo e você não aparecerão aqui
                </h1>
              }
            />
            <Dropdown
              fluid
              selection
              id="dropdown-relation-users"
              placeholder="Participantes"
              loading={
                isLoadingDropdown && focusId === "dropdown-relation-users"
              }
              search
              multiple
              defaultValue={valuesGroupUsers}
              options={instanceOptions}
              onOpen={async () => {
                setIsLoadingDropdown(true);
                const payload = await usersService.searchAgents({
                  skip: 0,
                  limit: 10,
                  filter: "",
                  deleted: false,
                });
                setIsLoadingDropdown(false);
                const _optionsUsers: object[] = [];
                payload.results
                  .filter(
                    (_user: IUser) =>
                      _user?._id !== selectedChat?.created_by &&
                      _user?._id !== user?._id
                  )
                  .map((_user: IUser) =>
                    _optionsUsers.push({
                      key: _user._id,
                      text: _user.name,
                      value: _user._id,
                      disabled: _user?._id === selectedChat?.created_by,
                      content: (
                        <div className="flex">
                          <h1 className="mr-2">{_user?.name}</h1>
                          {typeof _user?.email !== "undefined" ? (
                            <h1 className="text-gray-text">({_user?.email})</h1>
                          ) : null}
                        </div>
                      ),
                    })
                  );
                setInstanceOptions(_optionsUsers);
              }}
              onFocus={() => {
                setFocusId("dropdown-relation-users");
              }}
              onBlur={() => {
                setFocusId("");
              }}
              onSearchChange={async (e: DropdownSearchInputProps) => {
                setIsLoadingDropdown(true);
                const payload = await usersService.searchAgents({
                  skip: 0,
                  limit: 10,
                  filter: e.target.value,
                  deleted: false,
                });
                setIsLoadingDropdown(false);
                const _optionsUsers: ddOptions[] = instanceOptions;
                payload.results.map((_user: IUser) => {
                  if (
                    _optionsUsers.filter(
                      (_opUser) => _opUser.key === _user._id
                    )[0] ||
                    _user?._id === selectedChat?.created_by
                  ) {
                    return false;
                  }
                  _optionsUsers.push({
                    key: _user._id,
                    text: _user.name,
                    value: _user._id,
                    disabled: _user?._id === selectedChat?.created_by,
                    content: (
                      <div className="flex">
                        <h1 className="mr-2">{_user?.name}</h1>
                        {typeof _user?.email !== "undefined" ? (
                          <h1 className="text-gray-text">({_user?.email})</h1>
                        ) : null}
                      </div>
                    ),
                  });
                  return true;
                });
                setInstanceOptions(_optionsUsers);
              }}
              onChange={(e, { value }) => {
                const _value = value as string[];
                setResults(removeDuplicates(_value));
              }}
            />
            <div className="mb-12" />
          </>
        );
      case "las la-user":
        const valuesUsers: string[] = [];
        if (selectedTeam?.users) {
          selectedTeam?.users.forEach((_user: IUser) => {
            valuesUsers.push(_user?._id || "");
            instanceOptions.push({
              key: _user._id,
              text: _user.name,
              value: _user._id,
              content: (
                <div className="flex">
                  <h1 className="mr-2">{_user?.name}</h1>
                  {typeof _user?.email !== "undefined" ? (
                    <h1 className="text-gray-text">({_user?.email})</h1>
                  ) : null}
                </div>
              ),
            });
          });
        }
        return (
          <>
            <div className="mt-12" />
            <Dropdown
              fluid
              selection
              id="dropdown-relation-users"
              label="Agentes"
              placeholder="Agentes"
              loading={
                isLoadingDropdown && focusId === "dropdown-relation-users"
              }
              search
              multiple
              defaultValue={valuesUsers}
              options={instanceOptions}
              onOpen={async () => {
                setIsLoadingDropdown(true);
                const payload = await usersService.searchAgents({
                  skip: 0,
                  limit: 10,
                  filter: "",
                  deleted: false,
                });
                setIsLoadingDropdown(false);
                const _optionsUsers: object[] = [];
                payload.results.map((_user: IUser) =>
                  _optionsUsers.push({
                    key: _user._id,
                    text: _user.name,
                    value: _user._id,
                    content: (
                      <div className="flex">
                        <h1 className="mr-2">{_user?.name}</h1>
                        {typeof _user?.email !== "undefined" ? (
                          <h1 className="text-gray-text">({_user?.email})</h1>
                        ) : null}
                      </div>
                    ),
                  })
                );
                setInstanceOptions(_optionsUsers);
              }}
              onFocus={() => {
                setFocusId("dropdown-relation-users");
              }}
              onBlur={() => {
                setFocusId("");
              }}
              onSearchChange={async (e: DropdownSearchInputProps) => {
                setIsLoadingDropdown(true);
                const payload = await usersService.searchAgents({
                  skip: 0,
                  limit: 10,
                  filter: e.target.value,
                  deleted: false,
                });
                setIsLoadingDropdown(false);
                const _optionsUsers: ddOptions[] = instanceOptions;
                payload.results.map((_user: IUser) => {
                  if (
                    _optionsUsers.filter(
                      (_opUser) => _opUser.key === _user._id
                    )[0]
                  ) {
                    return false;
                  }
                  _optionsUsers.push({
                    key: _user._id,
                    text: _user.name,
                    value: _user._id,
                    content: (
                      <div className="flex">
                        <h1 className="mr-2">{_user?.name}</h1>
                        {typeof _user?.email !== "undefined" ? (
                          <h1 className="text-gray-text">({_user?.email})</h1>
                        ) : null}
                      </div>
                    ),
                  });
                  return true;
                });
                setInstanceOptions(_optionsUsers);
              }}
              onChange={(e, { value }) => {
                const _value = value as string[];
                setResults(removeDuplicates(_value));
              }}
            />
            <div className="mb-12" />
          </>
        );
      case "las la-address-book":
        const valuesContacts: string[] = [];
        if (selectedCustomer?.contacts) {
          selectedCustomer?.contacts.forEach((_contact: IUser) => {
            valuesContacts.push(_contact?._id || "");
            instanceOptions.push({
              key: _contact._id,
              text: _contact.name,
              value: _contact._id,
              content: (
                <div className="flex">
                  <h1 className="mr-2">{_contact?.name}</h1>
                  {typeof _contact?.email !== "undefined" ? (
                    <h1 className="text-gray-text">({_contact?.email})</h1>
                  ) : null}
                </div>
              ),
            });
          });
        }
        return (
          <>
            <div className="mt-12" />
            <Dropdown
              fluid
              selection
              id="dropdown-relation-contacts"
              label="Contatos"
              placeholder="Contatos"
              loading={
                isLoadingDropdown && focusId === "dropdown-relation-contacts"
              }
              search
              multiple
              defaultValue={valuesContacts}
              options={instanceOptions}
              onOpen={async () => {
                setIsLoadingDropdown(true);
                const payload = await usersService.searchContacts({
                  skip: 0,
                  limit: 10,
                  filter: "",
                  deleted: false,
                  spam: false,
                });
                setIsLoadingDropdown(false);
                const _optionsContacts: object[] = [];
                payload.results.map((_contact: IUser) =>
                  _optionsContacts.push({
                    key: _contact._id,
                    text: _contact.name,
                    value: _contact._id,
                    content: (
                      <div className="flex">
                        <h1 className="mr-2">{_contact?.name}</h1>
                        {typeof _contact?.email !== "undefined" ? (
                          <h1 className="text-gray-text">
                            ({_contact?.email})
                          </h1>
                        ) : null}
                      </div>
                    ),
                  })
                );
                setInstanceOptions(_optionsContacts);
              }}
              onFocus={() => {
                setFocusId("dropdown-relation-contacts");
              }}
              onBlur={() => {
                setFocusId("");
              }}
              onSearchChange={async (e: DropdownSearchInputProps) => {
                setIsLoadingDropdown(true);
                const payload = await usersService.searchContacts({
                  skip: 0,
                  limit: 10,
                  filter: e.target.value,
                  deleted: false,
                  spam: false,
                });
                setIsLoadingDropdown(false);
                const _optionsContacts: ddOptions[] = instanceOptions;
                payload.results.map((_contact: IUser) => {
                  if (
                    _optionsContacts.filter(
                      (_opContact) => _opContact.key === _contact._id
                    )[0]
                  ) {
                    return false;
                  }
                  _optionsContacts.push({
                    key: _contact._id,
                    text: _contact.name,
                    value: _contact._id,
                    content: (
                      <div className="flex">
                        <h1 className="mr-2">{_contact?.name}</h1>
                        {typeof _contact?.email !== "undefined" ? (
                          <h1 className="text-gray-text">
                            ({_contact?.email})
                          </h1>
                        ) : null}
                      </div>
                    ),
                  });
                  return true;
                });
                setInstanceOptions(_optionsContacts);
              }}
              onChange={(e, { value }) => {
                const _value = value as string[];
                setResults(removeDuplicates(_value));
              }}
            />
            <div className="mb-12" />
          </>
        );
      case "profile-teams":
        const valuesTeamsMe: string[] = [];
        if (user?.teams) {
          user.teams.forEach((team: ITeam) => {
            valuesTeamsMe.push(team?._id || "");
            instanceOptions.push({
              key: team._id,
              text: team.name,
              value: team._id,
            });
          });
        }
        return (
          <>
            <div className="mt-12" />
            <Dropdown
              fluid
              selection
              id="dropdown-relation-profile-teams"
              label="Equipes"
              placeholder="Equipes"
              loading={
                isLoadingDropdown &&
                focusId === "dropdown-relation-profile-teams"
              }
              search
              multiple
              defaultValue={valuesTeamsMe}
              options={instanceOptions}
              onOpen={async () => {
                setIsLoadingDropdown(true);
                const payload = await teamsService.search({
                  skip: 0,
                  limit: 10,
                  filter: "",
                  deleted: false,
                });
                setIsLoadingDropdown(false);
                const _optionsTeams: ddOptions[] = [];
                payload.results.map((_team: ITeam) =>
                  _optionsTeams.push({
                    key: _team._id,
                    text: _team.name,
                    value: _team._id,
                  })
                );
                setInstanceOptions(_optionsTeams);
              }}
              onFocus={() => {
                setFocusId("dropdown-relation-profile-teams");
              }}
              onBlur={() => {
                setFocusId("");
              }}
              onSearchChange={async (e: DropdownSearchInputProps) => {
                setIsLoadingDropdown(true);
                const payload = await teamsService.search({
                  skip: 0,
                  limit: 10,
                  filter: e.target.value,
                  deleted: false,
                });
                setIsLoadingDropdown(false);
                const _optionsTeams: ddOptions[] = instanceOptions;
                payload.results.map((_team: ITeam) => {
                  if (
                    _optionsTeams.filter(
                      (_opTeam) => _opTeam.key === _team._id
                    )[0]
                  ) {
                    return false;
                  }
                  _optionsTeams.push({
                    key: _team._id,
                    text: _team.name,
                    value: _team._id,
                  });
                  return true;
                });
                setInstanceOptions(_optionsTeams);
              }}
              onChange={(e, { value }) => {
                const _value = value as string[];
                setResults(removeDuplicates(_value));
              }}
            />
            <div className="mb-12" />
          </>
        );
      // case "profile-customers":
      //   const optionsCustomersMe: ddOptions[] = [];
      //   customers.map((customer) =>
      //     optionsCustomersMe.push({
      //       key: customer._id,
      //       text: customer.name,
      //       value: customer._id,
      //     })
      //   );
      //   const valueCustomersMe: string[] = [];
      //   if (user?.customers) {
      //     user?.customers.map((customer) =>
      //       valueCustomersMe.push(customer?._id || "")
      //     );
      //   }
      //   return (
      //     <>
      //       <div className="mt-12" />
      //       <Dropdown
      //         noResultsMessage="Nenhum resultado"
      //         label="Clientes"
      //         fluid
      //         search
      //         selection
      //         multiple
      //         options={optionsCustomersMe}
      //         defaultValue={valueCustomersMe}
      //         placeholder="Clientes"
      //         onChange={(e, { value }) => {
      //           const _value = value as string[];
      //           setResults(_value);
      //         }}
      //       />
      //       <div className="mb-12" />
      //     </>
      //   );
      default:
        return null;
    }
  };

  return (
    <div id="modal" className="fixed inset-0 z-[200]">
      <div
        role="presentation"
        className="fixed inset-0 w-full h-full bg-black opacity-80"
        onClick={() => {
          if (setBlockSidebar) setBlockSidebar(true);
          setShowModal(false);
          if (selectedTemplate !== null) {
            dispatch(selectTemplate({ template: null }));
          }
        }}
      />
      <div className="flex items-center justify-center min-h-screen">
        {/* <Draggable bounds="parent"> */}
        {/* <div className="relative w-1/2 sm:w-[450px] max-w-[600px] bg-white overflow-y-auto min-h-[250px] max-h-[875px] rounded-md shadow-lg py-8 px-10"> */}
        {/* <div className="relative bg-white rounded-md w-2/4 max-w-[600px] overflow-y-auto max-h-[675px] shadow-lg py-8 px-10"> */}
        <div
          className={`relative flex-container bg-white flex flex-col justify-center rounded-md max-h-full shadow-lg py-8 px-10 ${
            widgetType === UserType.NONE
              ? "w-4/5 sm:w-2/4 md:max-w-[600px]"
              : widgetType === UserType.AGENT && recentChats.length > 0
              ? "ml-[200px] w-[386px]"
              : "w-[95%]"
          }`}
        >
          {isLoading && icon === "lab la-whatsapp" ? (
            <LoadingDots
              medium
              className="flex h-[345px] justify-center items-center"
            />
          ) : (
            <>
              <h1 className="header flex items-center justify-center font-semibold">
                <i
                  className={`${
                    [
                      "las la-calendar",
                      "lab la-whatsapp",
                      "las la-users-cog",
                      "las la-user-cog",
                      "las la-comment",
                      "las la-tags",
                    ].includes(icon)
                      ? icon
                      : "las la-link"
                  } text-4xl`}
                />
                <p className="text-2xl ml-3">{title}</p>
              </h1>
              <Form
                id="modal-relation"
                className={`content overflow-y-scroll mx-auto w-[530px] max-h-[500px] overflow-x-hidden px-4 ${
                  icon === "lab la-whatsapp" ? "" : "inline-table"
                }`}
                // className={`content overflow-y-scroll mx-auto ${
                //   focusId !== "" ? "min-h-[250px]" : ""
                // } w-[530px] overflow-x-hidden px-4`}
              >
                {getType()}
              </Form>
              <Form className="footer mx-auto w-[530px] px-4">
                <ActionButtonsModal
                  cancelAction={() => {
                    if (setBlockSidebar) setBlockSidebar(true);
                    setShowModal(false);
                    if (selectedTemplate !== null) {
                      dispatch(selectTemplate({ template: null }));
                    }
                  }}
                  disabled={getTypeDisable() || disable}
                  submitAction={async () => {
                    setDisable(true);
                    await getTypeUpdate({});
                  }}
                />
                {icon === "las la-users-cog" ? (
                  <div className="flex justify-center mt-4 -mb-4 mr-3">
                    <LinkButton
                      label="Fazer isto depois"
                      disabled={getTypeDisable() || disable}
                      onClick={async () => {
                        await getTypeUpdate({ after: true });
                      }}
                      icon="las la-arrow-right"
                    />
                  </div>
                ) : null}
              </Form>
            </>
          )}
        </div>
        {/* </Draggable> */}
      </div>
    </div>
  );
};

RelationModal.defaultProps = {
  selectedRow: undefined,
  contactChat: undefined,
  setShow: undefined,
  setBlockSidebar: undefined,
  importList: [],
  validateOlderWhatsAppChats: undefined,
  setImportList: undefined,
  setShowImport: undefined,
  widgetType: UserType.NONE,
  sendCommenttemplate: undefined,
};

export default RelationModal;
