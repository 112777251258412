/* eslint-disable react/no-children-prop */
import React, { useEffect } from "react";
import { Form } from "semantic-ui-react";
import InputMask from "react-input-mask";
import { useAppSelector } from "../../../hooks/redux/hooks";
import {
  ActiveDropdown,
  ContactTagsDropdown,
} from "../../../shared/components/Dropdowns";
import FormAvatar from "../../../shared/components/FormAvatar.component";
import useContacts from "../../../hooks/features/useContacts";
// import PhoneMaskInput from "../../../shared/components/PhoneMaskInput.component";
import useLGPD from "../../../hooks/useLGPD";
import PhoneFlagInput from "../../../shared/components/PhoneFlagInput.component";

const Infos = () => {
  const { infos, contactCallback } = useContacts({});
  const { selectedLocalContact } = useAppSelector((state) => state.users);
  const { showCode } = useLGPD();

  useEffect(() => {
    contactCallback();
  }, [contactCallback]);

  return (
    <>
      <FormAvatar
        instance={selectedLocalContact}
        onRemove={infos.avatar.onRemove}
        onChange={infos.avatar.onChange}
      />
      <Form>
        <Form.Group className="mt-4" widths="equal">
          <Form.Input
            autoComplete="off"
            readOnly={infos.readonly}
            className="w-full md:w-full"
            label="Nome"
            fluid
            placeholder="Nome"
            defaultValue={selectedLocalContact?.name}
            type="text"
            required
            onChange={(e, { value }) => {
              infos.onChange.name(e, { value });
            }}
          />
          <ActiveDropdown
            disabled={infos.active.disabled}
            className="w-full mt-6"
            fluid
            defaultValue={selectedLocalContact?.active}
            onChange={(e, { value }) => {
              infos.onChange.active(e, { value });
            }}
          />
        </Form.Group>
        {!showCode ? (
          <Form.Group widths="equal">
            <Form.Input
              autoComplete="new-email"
              className="w-full md:w-full"
              label="E-mail"
              placeholder="E-mail"
              required
              value={selectedLocalContact?.email?.trim()?.toLowerCase()}
              type="email"
              onChange={(e, { value }) => {
                infos.onChange.email(e, { value });
              }}
            />
            <PhoneFlagInput
              className="w-full md:w-full"
              key="phone"
              readOnly={infos.readonly}
              required
              defaultValue={selectedLocalContact?.phone}
              onChange={(value: string) => {
                infos.onChange.phone(value);
              }}
            />
            {/* <PhoneMaskInput
              className="w-full md:w-full"
              key="phone"
              readOnly={infos.readonly}
              required
              defaultValue={selectedLocalContact?.phone}
              onChange={(e) => {
                infos.onChange.phone(e);
              }}
            /> */}
          </Form.Group>
        ) : (
          <>
            <Form.Group widths="equal">
              <Form.Input
                autoComplete="new-email"
                className="w-full md:w-full"
                label="E-mail"
                placeholder="E-mail"
                required
                value={selectedLocalContact?.email?.trim()?.toLowerCase()}
                type="email"
                onChange={(e, { value }) => {
                  infos.onChange.email(e, { value });
                }}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <PhoneFlagInput
                className="w-full md:w-full"
                key="phone"
                readOnly={infos.readonly}
                required
                defaultValue={selectedLocalContact?.phone}
                onChange={(value: string) => {
                  infos.onChange.phone(value);
                }}
              />
              {/* <PhoneMaskInput
                className="w-full md:w-full"
                key="phone"
                readOnly={infos.readonly}
                required
                defaultValue={selectedLocalContact?.phone}
                onChange={(e) => {
                  infos.onChange.phone(e);
                }}
              /> */}
              <Form.Input
                className="w-full md:w-full"
                readOnly={infos.readonly}
                label="CPF"
                children={
                  <InputMask
                    min={11}
                    max={11}
                    mask="999.999.999-99"
                    readOnly={infos.readonly}
                    placeholder="CPF"
                    defaultValue={selectedLocalContact?.code}
                    onChange={(e) => {
                      infos.onChange.code(e);
                    }}
                    maskPlaceholder={null}
                  />
                }
                autoComplete="off"
              />
            </Form.Group>
          </>
        )}
        <Form.Group widths="equal">
          <ContactTagsDropdown
            allowAdditions
            defaultValue={infos.tags.defaultValue}
            disabled={infos.tags.disabled}
            focusId={infos.tags.focusId}
            setFocusId={infos.tags.setFocusId}
            isLoadingDropdown={infos.tags.isLoadingDropdown}
            setIsLoadingDropdown={infos.tags.setIsLoadingDropdown}
            options={infos.tags.options}
            setOptions={infos.tags.setOptions}
            handleOnChangeInfo={infos.tags.onChange}
          />
        </Form.Group>
      </Form>
    </>
  );
};

export default Infos;
