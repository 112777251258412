/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import {
  IAgideskTeam,
  IAgideskUser,
  getAgideskTeamUsers,
} from "../../../../slices/externalapps.slice";
import User from "./User.component";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux/hooks";
import Tag from "../../../../features/chats/components/Tag.component";
import TagArea from "../../../../features/chats/components/TagArea.component";

const Team = ({
  team,
  importData,
  setImportData,
}: {
  importData: IAgideskTeam[];
  setImportData: React.Dispatch<React.SetStateAction<IAgideskTeam[]>>;
  team: IAgideskTeam;
}) => {
  const [checked, setChecked] = useState(false);
  const [expand, setExpand] = useState(false);
  const dispatch = useAppDispatch();
  const { selectedExternalAppAgidesk } = useAppSelector(
    (state) => state.externalapps
  );

  const isChecked = () =>
    typeof importData.find((_team) => _team.id === team.id)?.id !== "undefined";

  const getIcon = () => {
    if (
      typeof importData.find((_team) => _team.id === team.id)?.id !==
        "undefined" ||
      checked
    ) {
      return "las la-check-square";
    }
    return "las la-stop";
  };

  const getUsersComponent = () => {
    if (!expand) return null;
    if (expand && team.users && team.users.length > 0) {
      return (
        <div className="grid md:grid-cols-2 mt-3">
          {team.users.map((user) => (
            <User
              key={user.id}
              teamuser={user}
              teamChecked={checked}
              setTeamChecked={setChecked}
              team={{
                ...team,
                users:
                  importData.filter((_import) => _import.id === team.id)[0]
                    ?.users || [],
              }}
              importData={importData}
              setImportData={setImportData}
            />
          ))}
        </div>
      );
    }
    return (
      <div className="flex w-full justify-center p-6">Nenhum resultado</div>
    );
  };

  return (
    <>
      <div
        title={team.title}
        className="px-3 flex w-full items-center justify-between mt-4 border-agitalks-alphaA0 rounded-lg mx-auto my-0 h-12 cursor-pointer shadow-md border hover:border-agitalks-alphaA0"
        role="presentation"
        onClick={async (e) => {
          e.preventDefault();
          if (
            !expand &&
            (typeof importData.filter(
              (_import) => _import.id === team.id
            )[0] === "undefined" ||
              (importData.filter((_import) => _import.id === team.id)[0] &&
                importData.filter((_import) => _import.id === team.id)[0].users
                  ?.length === 0))
          ) {
            await dispatch(
              getAgideskTeamUsers({
                externalappid: selectedExternalAppAgidesk?._id || "",
                teamid: team.id,
              })
            );
          }
          setExpand((prevState) => !prevState);
        }}
      >
        <div className="flex items-center">
          <i
            role="presentation"
            onClick={async (e) => {
              e.stopPropagation();
              if (isChecked()) {
                setChecked(false);
                setImportData(
                  importData.filter((_import) => _import.id !== team.id)
                );
                // setExpand(false);
              } else {
                setChecked(true);
                let _action: IAgideskUser[] = [];
                if (!expand) {
                  const { payload } = await dispatch(
                    getAgideskTeamUsers({
                      externalappid: selectedExternalAppAgidesk?._id || "",
                      teamid: team.id,
                    })
                  );
                  _action = payload.results;
                } else {
                  _action = team.users || [];
                }
                setImportData((prevState) => [
                  ...prevState,
                  {
                    id: team.id,
                    title: team.title,
                    users: _action.map((_user: IAgideskUser) => {
                      const _newUser = {
                        ..._user,
                        admin: false,
                        invite: _user.new === true,
                        new: _user.new,
                      };
                      return _newUser;
                    }),
                  },
                ]);
                if (!expand) setExpand(true);
              }
            }}
            className={`${getIcon()} text-[20px] cursor-pointer`}
          />
          <div
            key={team.id}
            className="flex items-center justify-start ml-2 cursor-pointer"
          >
            <p className="font-semibold text-[12px]">{team.title}</p>
          </div>
        </div>
        <div
          role="presentation"
          onClick={async (e) => {
            e.stopPropagation();
            if (
              !expand &&
              (typeof importData.filter(
                (_import) => _import.id === team.id
              )[0] === "undefined" ||
                (importData.filter((_import) => _import.id === team.id)[0] &&
                  importData.filter((_import) => _import.id === team.id)[0]
                    .users?.length === 0))
            ) {
              await dispatch(
                getAgideskTeamUsers({
                  externalappid: selectedExternalAppAgidesk?._id || "",
                  teamid: team.id,
                })
              );
            }
            setExpand((prevState) => !prevState);
          }}
          className="flex items-center"
        >
          {team.imported ? (
            <TagArea team>
              <Tag tagType="team" />
            </TagArea>
          ) : null}
          <i
            className={`las ${
              expand ? "la-angle-up" : "la-angle-down"
            } text-[20px]"`}
          />
        </div>
      </div>
      {getUsersComponent()}
    </>
  );
};

export default Team;
