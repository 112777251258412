/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import { ChatStatus, ChatType } from "../../../slices/chats.slice";
import { useAppSelector } from "../../../hooks/redux/hooks";
import botAllowed from "../../../shared/utils/botAllowed";
import { ICampaign } from "../../../slices/campaigns.slice";
import { ITag } from "../../../slices/tags.slice";
import { handleMaskRegex } from "../../../shared/utils/handleMaskLength";

const Tag = ({
  tagType,
  status,
  type,
  externalapp,
  campaign,
  transferred,
  groupchat,
  offline,
  recents,
  header,
  title,
  customTag,
  onClick,
}: {
  customTag?: ITag;
  tagType?: string;
  status?: ChatStatus;
  type?: ChatType;
  campaign?: ICampaign;
  externalapp?: any;
  transferred?: boolean;
  groupchat?: boolean;
  offline?: boolean;
  title?: string;
  recents?: boolean;
  header?: boolean;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}) => {
  const { selectedChat, mineChats } = useAppSelector((state) => state.chats);

  const [tag, setTag] = useState<{
    color: string;
    title: string;
    externalapptitle?: string;
    icon?: string;
    onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  }>({
    color: "",
    title: "",
    externalapptitle: "",
    icon: undefined,
    onClick: undefined,
  });

  const statusTag = {
    [ChatStatus.FINISH]: {
      color: "border-green text-green",
      title: "Encerrado",
      icon: undefined,
    },
    [ChatStatus.SUSPEND]: {
      color: "border-gray-999 text-gray-999",
      title: "Cancelado pelo contato",
      icon: undefined,
    },
    [ChatStatus.CANCELED]: {
      color: "border-gray-999 text-gray-999",
      title: "Cancelado",
      icon: undefined,
    },
    [ChatStatus.WAIT]: {
      color: "border-yellow text-yellow",
      title: "Na fila",
      icon: undefined,
    },
    [ChatStatus.WAITREPLY]: {
      color: "border-yellow text-yellow",
      title: "Aguardando contato",
      icon: undefined,
    },
    [ChatStatus.WAITCAMPAIGN]: {
      color: "border-yellow text-yellow",
      title: "Aguardando contato",
      icon: undefined,
    },
    [ChatStatus.ACTIVE]: {
      color: "border-agidesk text-agidesk",
      title: "Em atendimento",
      icon: undefined,
    },
  };

  const getHoverMode = () => {
    if (type === ChatType.WHATSAPP || externalapp?.type === "WHATSAPP") {
      return "hover:border-green-whatsapp hover:bg-green-whatsapp hover:text-white";
    }
    if (type === ChatType.BOTMAKER || externalapp?.type === "BOTMAKER") {
      return "hover:border-[#9800B5] hover:bg-[#9800B5] hover:text-white";
    }
    return "hover:border-agitalks hover:bg-agitalks hover:text-white";
  };

  const typeTag = {
    [ChatType.EXTERNAL]: {
      color: "border-agitalks text-agitalks",
      title: "Externo",
      icon: "las la-headset",
    },
    [ChatType.INTERNAL]: {
      color: "border-agitalks text-agitalks",
      title: "Interno",
      icon: "las la-headset",
    },
    [ChatType.WHATSAPP]: {
      color: "border-green-whatsapp text-green-whatsapp",
      title: "WhatsApp",
      icon: "lab la-whatsapp",
    },
    [ChatType.BOTMAKER]: {
      color: "border-bot text-bot",
      title: "Agibot",
      icon: "las la-robot",
    },
  };

  const buildTag = () => {
    switch (tagType) {
      case "team":
        setTag({
          color: "border-green text-green",
          title: "Já importada",
        });
        break;
      case "teamuser":
        setTag({
          color: "border-green text-green",
          title: "Já importado nesta equipe",
        });
        break;
      case "chatstatus":
        if (status) setTag(statusTag[status]);
        break;
      case "chattype":
        if (type) {
          if (!botAllowed() && type === ChatType.BOTMAKER) {
            break;
          }
          setTag(typeTag[type]);
        }
        break;
      case "transferred":
        if (transferred)
          setTag({
            color: "border-agitalks text-agitalks",
            title: "Transferido",
            icon: "las la-share",
          });
        break;
      case "groupchat":
        if (groupchat)
          setTag({
            color: "border-agitalks text-agitalks",
            title: "Grupo",
            icon: "las la-users",
          });
        break;
      case "offline":
        if (offline)
          setTag({
            color: "border-agitalks text-agitalks",
            title: "Fora de horário",
            icon: "las la-hourglass-end",
          });
        break;
      case "campaign":
        if (campaign)
          setTag({
            color: "border-green-whatsapp text-green-whatsapp",
            title:
              typeof campaign?.name !== "undefined"
                ? campaign.name
                : "Campanha",
            icon: "las la-bullhorn",
          });
        break;
      case "externalapp":
        if (externalapp) {
          let externalappparams:
            | {
                icon: string;
                defaultColors: string;
                selectedColor: string;
              }
            | undefined;

          switch (externalapp.type) {
            case "WHATSAPP":
              externalappparams = {
                icon: "lab la-whatsapp",
                defaultColors: "border-green-whatsapp text-green-whatsapp",
                selectedColor: `text-white bg-green-whatsapp border-green-whatsapp ${getHoverMode()}`,
              };
              break;
            case "BOTMAKER":
              externalappparams = {
                icon: "las la-robot",
                defaultColors: "border-bot text-bot",
                selectedColor: `text-white bg-bot border-bot ${getHoverMode()}`,
              };
              break;
            default:
              break;
          }

          setTag({
            color: externalappparams?.defaultColors as string,
            title: externalapp?.fields?.name,
            externalapptitle:
              typeof externalapp?.fields?.displayphone !== "undefined"
                ? handleMaskRegex(externalapp?.fields?.displayphone)
                : undefined,
            icon: externalappparams?.icon as string,
          });
        }
        break;
      case "custom":
        if (typeof customTag?._id !== "undefined") {
          setTag({
            color:
              typeof customTag?.color !== "undefined" &&
              customTag?.color.length > 0
                ? `${customTag?.color}`
                : "border-agitalks text-agitalks",
            title: customTag.name as string,
            onClick: undefined,
          });
        }
        break;
      case "add-custom":
        setTag({
          color: "border-agitalks text-agitalks",
          title: "Adicionar marcador",
          icon: "las la-plus-circle",
          onClick,
        });
        break;
      default:
        if (typeof title !== "undefined") {
          setTag({
            color: "border-agitalks text-agitalks",
            title,
            onClick: undefined,
          });
        }
        break;
    }
  };

  useEffect(() => {
    buildTag();
  }, [selectedChat, mineChats]);

  if (!tagType || !tag?.title) return null;
  return (
    <div
      className={`max-w-[90px] mr-2 flex items-center relative py-[2px] px-[7px] rounded-2xl font-semibold border
      ${
        typeof customTag?._id === "undefined" || !customTag?.color
          ? tag.color
          : ""
      } ${recents ? "text-[8px]" : ""} ${
        header ? "my-1 !max-w-[175px]" : "mt-[5px]"
      }
    `}
      style={
        typeof customTag?._id === "undefined" || !customTag?.color
          ? undefined
          : {
              color: tag.color,
              borderColor: tag.color,
            }
      }
      title={tag.externalapptitle || tag.title}
      role="presentation"
      onClick={tag.onClick}
    >
      {tag.icon ? (
        <i
          className={`${tag.icon} mr-1 ${
            recents ? "text-[8px]" : "text-[9px]"
          }`}
        />
      ) : null}
      <h2
        className={`whitespace-nowrap text-ellipsis overflow-hidden text-center ${
          recents ? "text-[8px]" : "text-[9px]"
        }`}
      >
        {tag.title}
      </h2>
    </div>
  );
};

Tag.defaultProps = {
  tagType: undefined,
  type: undefined,
  status: undefined,
  externalapp: undefined,
  transferred: false,
  groupchat: false,
  offline: false,
  recents: false,
  header: false,
  title: undefined,
  campaign: undefined,
  customTag: undefined,
  onClick: undefined,
};

export default Tag;
