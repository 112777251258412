import { IFilterSurveyRating } from "../slices/surveyRatings.slice";
import { ISurvey } from "../slices/surveys.slice";
import instances from "./instances";

const search = async ({
  skip,
  limit,
  filter,
  deleted,
  allStatus,
}: {
  skip?: number;
  limit?: number;
  filter: string;
  deleted: boolean;
  allStatus?: boolean;
}) => {
  try {
    const _filters =
      typeof filter === "object" ? JSON.parse(JSON.stringify(filter)) : {};

    if (typeof filter === "string" && typeof _filters?.content === "undefined")
      _filters.content = filter;
    if (allStatus) _filters.active = false;
    if (deleted) _filters.deleted = true;

    const response = await instances.instanceSurveys.get("", {
      params: {
        skip: skip || 0,
        limit: limit || 10,
        filter: _filters,
        fields:
          "name,active,created_at,created_by,default,deleted,updated_at,updated_by",
      },
    });
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const surveyrating = async ({ code }: { code: string }) => {
  try {
    const response = await instances.instanceSurveys.get(
      `/rating/?code=${code}`
    );
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const searchRatings = async (filter: IFilterSurveyRating) => {
  try {
    const response = await instances.instanceSurveys.get(`/ratings`, {
      params: filter,
    });

    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const survey = async ({ _id }: { _id: string }) => {
  try {
    const response = await instances.instanceSurveys.get(`/${_id}`);
    if (response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    // console.log(error);
    return null;
  }
};
const surveys = async () => {
  try {
    const response = await instances.instanceSurveys.get(`/survey`);
    if (response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const update = async ({ _survey }: { _survey: ISurvey }) => {
  try {
    const data = _survey;
    const response = await instances.instanceSurveys.patch(
      `${_survey._id}`,
      data
    );
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const deleteSurveys = async ({ ids }: { ids: string[] }) => {
  try {
    const data = { id: ids };
    const response = await instances.instanceSurveys.delete("", { data });
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const create = async ({ name }: { name: string }) => {
  try {
    const data = {
      name,
    };
    const response = await instances.instanceSurveys.post("", data);
    if (response && response.data && !response.data.message) {
      return response.data;
    }
    return response.data.message;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const updateSurveyRating = async ({
  _surveyrating,
}: {
  _surveyrating: any;
}) => {
  try {
    const data = _surveyrating;
    const response = await instances.instanceSurveys.post("/rating", data);
    return response.data;
  } catch (error) {
    // console.log(error);
    return null;
  }
};

const surveysService = {
  surveyrating,
  updateSurveyRating,
  searchRatings,
  search,
  survey,
  surveys,
  update,
  create,
  deleteSurveys,
};

export default surveysService;
