import React from "react";
import { DropdownProps, SemanticWIDTHS } from "semantic-ui-react";
import Dropdown from "../Dropdown.component";
import { AssignedType } from "../../../slices/teams.slice";

const AssignedTypeDropdown = ({
  id,
  className,
  disabled,
  defaultValue,
  onChange,
  width,
  fluid,
}: {
  id?: string;
  width?: SemanticWIDTHS;
  className?: string;
  disabled?: boolean;
  defaultValue?: AssignedType;
  onChange?:
    | ((
        event: React.SyntheticEvent<HTMLElement, Event>,
        data: DropdownProps
      ) => void)
    | undefined;
  fluid?: boolean;
}) => {
  return (
    <Dropdown
      id={id || "dropdown-assignedtype"}
      className={className || "w-full md:w-full"}
      disabled={disabled || false}
      fluid={fluid}
      width={width}
      search
      selection
      placeholder="Comportamento de novos chats"
      label="Comportamento de novos chats"
      defaultValue={defaultValue}
      onChange={onChange}
      options={[
        {
          key: 0,
          text: "Distribuição automática para agente disponível",
          value: AssignedType.AGENT,
        },
        {
          key: 1,
          text: "Adicionar à fila de espera",
          value: AssignedType.BACKLOG,
        },
      ]}
    />
  );
};

AssignedTypeDropdown.defaultProps = {
  id: undefined,
  className: undefined,
  disabled: undefined,
  defaultValue: undefined,
  onChange: undefined,
  fluid: undefined,
  width: undefined,
};

export default AssignedTypeDropdown;
