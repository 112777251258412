import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import { EventHandler } from "@tinymce/tinymce-react/lib/cjs/main/ts/Events";
import { useLocation } from "react-router-dom";

const RtfEditor = ({
  id,
  initialValue,
  disabled,
  height,
  externalCss,
  label,
  onChange,
}: {
  id: string;
  initialValue?: string;
  height?: string;
  externalCss?: string;
  label?: string;
  disabled?: boolean;
  onChange?: EventHandler<unknown> | undefined;
}) => {
  const location = useLocation();
  const toolbar = `${
    location.pathname.includes("/commenttemplate")
      ? "formatselect | bold italic strikethrough | removeformat | agitalksVars"
      : "formatselect | bold italic underline strikethrough | forecolor backcolor blockquote |  link image media |  alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat"
  }`;

  return (
    <div id="rtf-editor-area" className={`${externalCss} flex-1 pb-5 `}>
      <div className="field required">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label>{label}</label>
      </div>
      <div className="-my-[8px]">
        <Editor
          id={`${id}-rtf-editor`}
          tinymceScriptSrc={`${process.env.PUBLIC_URL}/tinymce/tinymce.min.js`}
          value={initialValue}
          disabled={disabled}
          init={{
            setup: (editor) => {
              editor.ui.registry.addMenuButton("agitalksVars", {
                text: "Variáveis do sistema",
                fetch: (callback) => {
                  const items: {
                    type: "menuitem";
                    text: string;
                    onAction(): void;
                  }[] = [
                    {
                      type: "menuitem",
                      text: "Nome do contato {{contact.name}}",
                      onAction: () => editor.insertContent("{{contact.name}}"),
                    },
                    {
                      type: "menuitem",
                      text: "Nome do cliente {{contact.customers}}",
                      onAction: () =>
                        editor.insertContent("{{contact.customers}}"),
                    },
                    {
                      type: "menuitem",
                      text: "Telefone do contato {{contact.phone}}",
                      onAction: () => editor.insertContent("{{contact.phone}}"),
                    },
                    {
                      type: "menuitem",
                      text: "Nome do agente {{user.name}}",
                      onAction: () => editor.insertContent("{{user.name}}"),
                    },
                    {
                      type: "menuitem",
                      text: "E-mail do agente {{user.email}}",
                      onAction: () => editor.insertContent("{{user.email}}"),
                    },
                    {
                      type: "menuitem",
                      text: "Equipe {{chat.team.name}}",
                      onAction: () =>
                        editor.insertContent("{{chat.team.name}}"),
                    },
                    {
                      type: "menuitem",
                      text: "Empresa {{company.name}}",
                      onAction: () => editor.insertContent("{{company.name}}"),
                    },
                  ];
                  callback(items);
                },
              });
            },
            promotion: false,
            branding: false,
            height,
            language: "pt_BR",
            language_url: "/editor/langs/pt_BR.js",
            menubar: false,
            menu: {
              file: {
                title: "File",
                items: "newdocument restoredraft print | preview ",
              },
              edit: {
                title: "Edit",
                items:
                  "undo redo | cut copy paste pastetext | selectall | searchreplace",
              },
              view: {
                title: "View",
                items:
                  "code | visualaid visualchars visualblocks | spellchecker | preview fullscreen",
              },
              insert: {
                title: "Insert",
                items:
                  "image link media inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime",
              },
              format: {
                title: "Format",
                items:
                  "bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align lineheight | forecolor backcolor | removeformat",
              },
              tools: {
                title: "Tools",
                items: "spellchecker spellcheckerlanguage | code wordcount",
              },
              table: {
                title: "Table",
                items: "inserttable | cell row column | tableprops deletetable",
              },
              help: { title: "Help", items: "help" },
            },
            plugins: [
              "preview",
              "searchreplace",
              "autolink",
              "directionality",
              "visualblocks",
              "visualchars",
              "fullscreen",
              "image",
              "link",
              "table",
              "charmap",
              "pagebreak",
              "nonbreaking",
              "anchor",
              "insertdatetime",
              "advlist",
              "lists",
            ],

            toolbar,
            image_advtab: true,
          }}
          onKeyUp={onChange}
          onBlur={onChange}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

RtfEditor.defaultProps = {
  externalCss: "",
  disabled: false,
  initialValue: undefined,
  label: "",
  onChange: "",
  height: "450px",
};

export default RtfEditor;
