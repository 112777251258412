import React, { useCallback, useEffect } from "react";
import {
  NavLink,
  Outlet,
  matchPath,
  useLocation,
  useParams,
} from "react-router-dom";
import { Tab } from "semantic-ui-react";
import FooterPageInfo from "../shared/components/FooterPageInfo.component";
import { useAppSelector } from "../hooks/redux/hooks";
import useCampaigns from "../hooks/features/useCampaigns";

const InstancesPage = ({
  disable,
  setDisable,
  toggleSidebar,
}: {
  toggleSidebar?: boolean;
  disable?: boolean;
  setDisable?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const location = useLocation();
  const params = useParams();

  const { selectedLocalCampaign } = useAppSelector((state) => state.campaigns);
  const { footer } = useCampaigns();

  useEffect(() => {
    const _menuItems = document.querySelectorAll(".item [href]");
    _menuItems.forEach((item) => {
      const _item = item as HTMLAnchorElement;
      if (_item?.href && !_item.href.includes(window.location.href)) {
        _item.classList.remove("active");
      }
    });
  }, [location.pathname]);

  const renderTab = () => (
    <Tab.Pane
      style={{
        background: "transparent",
        border: "none",
        margin: 0,
      }}
    >
      <Outlet />
    </Tab.Pane>
  );

  const getId = useCallback(() => {
    if (params && typeof params._id !== "undefined") return params._id;
    return location.pathname.split("/").slice(-1)[0];
  }, []);

  const getPanes = () => {
    if (location.pathname.includes("settings/campaign")) {
      return [
        {
          menuItem: {
            as: NavLink,
            key: "campaign-data",
            to: `/settings/campaign/${getId()}`,
            icon: <i className="las la-bullhorn text-2xl mr-1" />,
            content: (
              <span className="text-lg text-gray-800 font-semibold">
                Dados da campanha
              </span>
            ),
          },
          render: () => renderTab(),
        },
        {
          menuItem: {
            as: NavLink,
            key: "campaign-batch",
            to: `/settings/campaign/${getId()}/batches`,
            icon: <i className="las la-paper-plane text-2xl mr-1" />,
            content: (
              <span className="text-lg text-gray-800 font-semibold">
                Lotes da campanha
              </span>
            ),
          },
          render: () => renderTab(),
        },
      ];
    }
    if (location.pathname.includes("settings/survey")) {
      return [
        {
          menuItem: {
            as: NavLink,
            key: "/settings/surveys",
            to: "/settings/surveys",
            icon: <i className="las la-smile text-2xl mr-1" />,
            content: (
              <span className="text-lg text-gray-800 font-semibold">
                Pesquisas de satisfação
              </span>
            ),
          },
          render: () => renderTab(),
        },
        {
          menuItem: {
            as: NavLink,
            key: "/settings/surveyresults",
            to: "/settings/surveyresults",
            icon: <i className="las la-trophy text-2xl mr-1" />,
            content: (
              <span className="text-lg text-gray-800 font-semibold">
                Indicadores de satisfação
              </span>
            ),
          },
          render: () => renderTab(),
        },
      ];
    }
    if (location.pathname.includes("settings/tags")) {
      return [
        {
          menuItem: {
            as: NavLink,
            key: "/settings/tags/chattags",
            to: "/settings/tags/chattags",
            icon: <i className="las la-tags text-2xl mr-1" />,
            content: (
              <span className="text-lg text-gray-800 font-semibold">
                Marcadores de conversa
              </span>
            ),
          },
          render: () => renderTab(),
        },
        {
          menuItem: {
            as: NavLink,
            key: "/settings/tags/contacttags",
            to: "/settings/tags/contacttags",
            icon: <i className="las la-user-tag text-2xl mr-1" />,
            content: (
              <span className="text-lg text-gray-800 font-semibold">
                Marcadores de contato
              </span>
            ),
          },
          render: () => renderTab(),
        },
      ];
    }
    if (
      location.pathname.includes("settings/officehour") ||
      location.pathname.includes("settings/holiday") ||
      location.pathname.includes("settings/offlinemessage")
    ) {
      return [
        {
          menuItem: {
            as: NavLink,
            key: "/settings/officehours",
            to: "/settings/officehours",
            icon: <i className="las la-calendar-check text-2xl mr-1" />,
            content: (
              <span className="text-lg text-gray-800 font-semibold">
                Horários de atendimento
              </span>
            ),
          },
          render: () => renderTab(),
        },
        {
          menuItem: {
            as: NavLink,
            key: "/settings/holidays",
            to: "/settings/holidays",
            icon: <i className="las la-calendar-times text-2xl mr-1" />,
            content: (
              <span className="text-lg text-gray-800 font-semibold">
                Feriados
              </span>
            ),
          },
          render: () => renderTab(),
        },
        {
          menuItem: {
            as: NavLink,
            key: "/settings/offlinemessages",
            to: "/settings/offlinemessages",
            icon: <i className="las la-hourglass-end text-2xl mr-1" />,
            content: (
              <span className="text-lg text-gray-800 font-semibold">
                Mensagens fora de horário
              </span>
            ),
          },
          render: () => renderTab(),
        },
      ];
    }
    return [
      {
        menuItem: {
          as: NavLink,
          key: "/settings/users",
          to: "/settings/users",
          icon: <i className="las la-user text-2xl mr-1" />,
          content: (
            <span className="text-lg text-gray-800 font-semibold">Agentes</span>
          ),
        },
        render: () => renderTab(),
      },
      {
        menuItem: {
          as: NavLink,
          key: "/settings/teams",
          to: "/settings/teams",
          icon: <i className="las la-user-friends text-2xl mr-1" />,
          content: (
            <span className="text-lg text-gray-800 font-semibold">Equipes</span>
          ),
        },
        render: () => renderTab(),
      },
      {
        menuItem: {
          as: NavLink,
          key: "/settings/contacts",
          to: "/settings/contacts",
          icon: <i className="las la-address-book text-2xl mr-1" />,
          content: (
            <span className="text-lg text-gray-800 font-semibold">
              Contatos
            </span>
          ),
        },
        render: () => renderTab(),
      },
      {
        menuItem: {
          as: NavLink,
          key: "/settings/customers",
          to: "/settings/customers",
          icon: <i className="las la-building text-2xl mr-1" />,
          content: (
            <span className="text-lg text-gray-800 font-semibold">
              Clientes
            </span>
          ),
        },
        render: () => renderTab(),
      },
    ];
  };

  const defaultActiveIndex = getPanes().findIndex((pane) => {
    return !!matchPath(location.pathname, pane.menuItem.to);
  });

  return (
    <div className="container items-center my-auto mx-auto lg:px-44 mt-7 py-4 md:px-12 sm:px-8">
      <Tab
        renderActiveOnly
        activeIndex={defaultActiveIndex}
        panes={getPanes()}
        menu={{
          fluid: true,
          secondary: true,
          pointing: true,
          widths: getPanes().length,
        }}
      />
      {location.pathname.includes("campaign/") &&
      typeof toggleSidebar !== "undefined" &&
      typeof disable !== "undefined" &&
      typeof setDisable !== "undefined" ? (
        <FooterPageInfo
          disabled={disable || footer.disabled}
          deleted={selectedLocalCampaign?.deleted}
          toggleSidebar={toggleSidebar}
          back={footer.back}
          title={footer.title}
          showDeleteModal={footer.showDeleteModal}
          setShowDeleteModal={footer.setShowDeleteModal}
          showReactiveModal={footer.showReactiveModal}
          setShowReactiveModal={footer.setShowReactiveModal}
          showStartModal={footer.showStartModal}
          setShowStartModal={footer.setShowStartModal}
          reactive={() => footer.reactive(setDisable)}
          remove={() => footer.remove(setDisable)}
          save={() => footer.save(setDisable)}
        />
      ) : null}
    </div>
  );
};

InstancesPage.defaultProps = {
  disable: false,
  toggleSidebar: false,
  setDisable: undefined,
};

export default InstancesPage;
