import React, { useEffect } from "react";
import { Form } from "semantic-ui-react";
import { useAppSelector } from "../../../hooks/redux/hooks";
import { ActiveDropdown } from "../../../shared/components/Dropdowns";
import useCommenttemplates from "../../../hooks/features/useCommenttemplates";

const Infos = () => {
  const { infos, commenttemplateCallback } = useCommenttemplates({});
  const { selectedLocalCommenttemplate } = useAppSelector(
    (state) => state.commenttemplates
  );

  useEffect(() => {
    commenttemplateCallback();
  }, [commenttemplateCallback]);

  return (
    <Form>
      <Form.Group className="mt-4" widths="equal">
        <Form.Input
          autoComplete="off"
          readOnly={infos.readonly}
          className="w-full md:w-full"
          label="Nome"
          fluid
          placeholder="Nome"
          defaultValue={selectedLocalCommenttemplate?.name}
          type="text"
          required
          onChange={(e, { value }) => {
            infos.onChange.name(e, { value });
          }}
        />
        <ActiveDropdown
          disabled={infos.active.disabled}
          className="w-full mt-6"
          fluid
          value={selectedLocalCommenttemplate?.active}
          onChange={(e, { value }) => {
            infos.onChange.active(e, { value });
          }}
        />
      </Form.Group>
    </Form>
  );
};

export default Infos;
